/**
 * @fileoverview Sports Ticket Sidebar View
 * @author pkamat 
 */
define('modules/sports/sports-tickets',[
    'jquery',
    'underscore',
    'pubsub',
    'baseview',
],
function(
    $,
    _,
    PubSub,
    BaseView
) {
    'use strict';

    var SportsTicketsView = BaseView.extend({

            events: {
                'click .sports-tickets-submit': 'triggerOmniture'
            },

            triggerOmniture: function(event) {
                PubSub.trigger('uotrack', 'TicketCityModuleExit');
            },
            
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);
                if (this.$el.width() >= 240) {
                    this.$('.sports-tickets-sidebar-content').removeClass('sports-tickets-sidebar-content-override');
                } else {
                    this.$('.sports-tickets-sidebar-content').addClass('sports-tickets-sidebar-content-override');
                }
                window.onresize = function (e) {
                    if (this.$('.sports-tickets-sidebar-content').parent().width() >= 240) {
                        this.$('.sports-tickets-sidebar-content').removeClass('sports-tickets-sidebar-content-override');
                    } else {
                        this.$('.sports-tickets-sidebar-content').addClass('sports-tickets-sidebar-content-override');
                    }
                };
            }
        });

        return SportsTicketsView;
    }
);
