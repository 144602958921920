/**
 * @fileoverview USAT-Network view.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('apps/usat-network',[
        'base-app',
        'state',
        'pubsub'
    ],
    function(
        BaseApp,
        StateManager,
        PubSub
        )
    {
        var USATNetworkApp = BaseApp.extend({
            events: {
                'click .footer-back-to-top' : 'backToTop',
                'click .usatnci-module-link' : 'setOmnitureTags'
            },
            afterPageReveal: function() {
                // stop refresh timer
                StateManager.stopRefreshTimer();

                // updates eVar99 with LDSN
                PubSub.trigger('group_page_view', 'LDSN');
                // updates eVar66
                PubSub.trigger('product_name', 'USA TODAY LANDING PAGE');
            },

            beforeAppRemove: function() {
                StateManager.startRefreshTimer();
            },

            backToTop: function() {
              window.scrollTo(0, 0);
            },
            setOmnitureTags : function(event) {
                PubSub.trigger('uotrack', event.currentTarget.dataset.tracking);
            }
        });

        return USATNetworkApp;
    }
);

