define('modules/videos/pluto/pluto-wrapper',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'modules/stories/taboola-recommendations',
    'managers/autoplay',
    'pubsub',
    'modules/videos/pluto/pluto-video',
    'modules/videos/pluto/pluto-scroll',
    'modules/videos/pluto/pluto-360',
    'modules/videos/pluto/pluto-sp',
    'modules/videos/pluto/iris-recommendation',
    'admanager',
    'utils'
],
function (
    $,
    _,
    BaseView,
    StateManager,
    TaboolaOutbrain,
    AutoPlayManager,
    PubSub,
    PlutoVideo,
    PlutoScroll,
    Pluto360,
    PlutoSP,
    Iris,
    AdManager,
    Utils
) {

    "use strict";

    var PlutoWrapper = BaseView.extend(
        {
            initialize: function(options) {
                options = $.extend({
                    isRecommended: true,
                    initializeScroll: true
                }, options);
                BaseView.prototype.initialize.call(this, options);

                if (this.$('.js-pluto-360-container').length > 0) {
                    this.subviews.pluto360 = new Pluto360({
                        el: this.$('.js-pluto-360-container'),
                        siteDisplayName: Utils.getNested(window.site_vars, 'display_name'),
                        twitterAccount: Utils.getNested(window.site_vars, 'twitter', 'default_account')
                    });
                    return;
                } else {
                    _.bindAll(this, 'getNextRecommendation', 'onAdEnd', 'onAdPlay', 'onAdVolumeChange',
                        'onEnded', 'onFullScreenChange', 'onPause', 'onVideoPlay', 'onVolumeChange',
                        'playNextRecommendation', 'playRecommendedVideo', 'startAutoPlay', 'onPlayback');

                    this.sv = {};

                    this.sv.video = this.subviews.plutovideo = new PlutoVideo({
                        adUnit: AdManager.buildAdUnit('preroll_video'),
                        el: this.$el,
                        getNextVideo: this.getNextRecommendation,
                        isRecommended: this.options.isRecommended,
                        pageInfo: StateManager.getActivePageInfo(),
                        playNextVideo: this.playNextRecommendation,
                        onAdEnd: this.onAdEnd,
                        onAdPlay: this.onAdPlay,
                        onAdVolumeChange: this.onAdVolumeChange,
                        onEnded: this.onEnded,
                        onFullScreenChange: this.onFullScreenChange,
                        onGalleryPlay: this.options.onGalleryPlay,
                        onPause: this.onPause,
                        onPlayback: this.onPlayback,
                        onVideoPlay: this.onVideoPlay,
                        onVolumeChange: this.onVolumeChange,
                        siteDisplayName: Utils.getNested(window.site_vars, 'display_name'),
                        twitterAccount: Utils.getNested(window.site_vars, 'twitter', 'default_account'),
                        videoStart: this.options.videoStart,
                        isFirstPlaylistVideoMuted: this.options.isFirstPlaylistVideoMuted
                    });


                    if (this.options.initializeScroll && this.sv.video.placement.scrollingplayer) {
                        if (Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'docked')) {
                            this.sv.video.initPromise.done(_.bind(function() {
                                this.sv.sp = this.subviews.sp = new PlutoSP({
                                    el: this.$el,
                                    plutoVideo: this.sv.video
                                });
                            }, this));
                        } else {
                            this.sv.video.initPromise.done(_.bind(function(videoPlayer) {
                                this.sv.scroll = this.subviews.plutoScroll = new PlutoScroll({
                                    el: this.$el,
                                    placementData: this.sv.video.placement,
                                    plutoVideo: this.sv.video,
                                    videoData: this.sv.video.video,
                                    videoPlayer: videoPlayer
                                });
                            }, this));
                        }
                    }

                    this.pubSub = {
                        'pause:videos': this.sv.video.onPause
                    };
                    PubSub.attach(this.pubSub, this);

                    // ipad does not allow auto play, so why trigger this
                    if (this.sv.video.placement.autoplay && !this.isApple) {
                        AutoPlayManager.register('pluto-video', this.startAutoPlay);
                    }

                    this.irisEnabled = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'enabled');
                    this.irisSectionEnabled = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'enable_by_section');
                    this.irisSection = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'section');
                    this.pageSection = StateManager.getActivePageInfo().section_name;
                    this.texttype = StateManager.getActivePageInfo().texttype;
                    this.showIrisRecommendation = this.irisEnabled || (this.irisSectionEnabled && this.pageSection === this.irisSection && (this.texttype == "blog" || this.texttype == "story"));
                }
            },

            onEnded: function(){
                StateManager.startRefreshTimer();
                if (this.options.onEnded) {
                   this.options.onEnded();
                }
            },

            onVolumeChange: function(muted) {
                if (this.options.onVolumeChange) {
                    this.options.onVolumeChange(muted);
                } else if (this.sv.scroll) {
                    this.sv.scroll.onVolumeChange(muted);
                }
            },

            onAdVolumeChange: function(isMuted) {
                if (this.options.onAdVolumeChange) {
                    this.options.onAdVolumeChange(isMuted);
                }else if (this.sv.scroll) {
                    this.sv.scroll.onVolumeChange(isMuted);
                }
            },

            onAdPlay: function() {
                this.onPlayback();
                if (this.sv.iris) this.sv.iris.onAdPlay();
                if (this.options.onAdPlay) {
                    this.options.onAdPlay();
                }else if (this.sv.scroll) {
                    this.sv.scroll.onAdPlay();
                }
                if (this.sv.sp) this.sv.sp.showAdText();
            },

            onPlayback: function() {
                if (this.showIrisRecommendation && !this.sv.iris && this.sv.video.video.type !== 'video-playlist') {
                    this.sv.iris = this.subviews.iris = new Iris({
                        id: this.sv.video.vjsPlayer.id(),
                        placementName: this.sv.video.placement.name,
                        playRecommendedVideo: this.playRecommendedVideo,
                        resetVideoState: this.sv.video.resetVideoState,
                        videoId: this.sv.video.video.id,
                        vjsPlayer: this.sv.video.vjsPlayer
                    });
                }
                StateManager.stopRefreshTimer();
            },

            onAdEnd: function() {
                if (this.options.onAdEnd) {
                    this.options.onAdEnd();
                } else if (this.sv.scroll) {
                    this.sv.scroll.onAdEnd();
                }
                if (this.sv.iris) this.sv.iris.onAdEnd();
                if (this.sv.sp) this.sv.sp.updateTitle(this.sv.video.getTitle());
            },

            onPause: function() {
                if (this.options.onPause) {
                    this.options.onPause();
                } else if (this.sv.scroll) {
                    this.sv.scroll.onPause();
                }
            },

            onVideoPlay: function() {
                this.onPlayback();
                if (this.options.onVideoPlay){
                    this.options.onVideoPlay();
                } else if (this.sv.scroll) {
                    this.sv.scroll.onVideoPlay();
                }
                if (this.sv.sp) this.sv.sp.updateTitle(this.sv.video.getTitle());
            },

            playVideo:function() {
                this.sv.video.playVideo();
            },

            startAutoPlay: function () {
                this.sv.video.startAutoPlay();
                return true;
            },

            /**
            * Make an api call to taboola to get next recommended video
             */
            getNextRecommendation: function () {
                if (!this.showIrisRecommendation) {
                    if (!this.sv.recommend) {
                        this.sv.recommend = this.subviews.TaboolaOutbrain = new TaboolaOutbrain({
                            'playRecommendedVideo': this.playRecommendedVideo,
                            "isContinuousEndslate": true
                        });
                    }
                    this.sv.recommend.renderEndslate(this.sv.video.video.url, this.sv.video.video.id);
                }
            },

            /**
            * Refresh the video obj with new recommendation
            */
            playRecommendedVideo:function(videoresponse){
                this.updateSimpleTarget(videoresponse.description);
                // Player Volume state needs to be maintained on continuousplay hence we check volume status before resetting it, to replicate it later.
                if (!this.sv.video.vjsPlayer.muted()) {
                    this.sv.video.playerVolume = this.sv.video.vjsPlayer.volume();
                } else {
                    this.sv.video.playerVolume = 0;
                }
                this.sv.video.vjsPlayer.reset();
                this.sv.video.changeVideo(videoresponse);
                this.$el.parent().find('.video-desc').html(videoresponse.description+"<span class="+"credit"+">"+videoresponse.credit+"</span>");

                if (this.sv.scroll) {
                    this.sv.scroll.updateVideoBarTitle(videoresponse.title);
                }
            },

            updateSimpleTarget: function(description) {
                var categories = Utils.getNested(window.site_vars, 'ADS', 'simple_categories');

                if(categories) {
                    window.simpleExclusion = window.simpleExclusion || [];
                    window.simpleTarget = window.simpleTarget || [];

                    _.each(categories, function(category) {
                        var keywords = category.keywords,
                            cat_type = category.type,
                            tag = category.tag;
                        _.each(keywords, function(keyword) {
                            if (description.indexOf(keyword) !== -1) {
                                if (cat_type == "exclude") {
                                    window.simpleExclusion.push(tag);
                                } else if (cat_type == "target") {
                                    window.simpleTarget.push(tag);
                                }
                            }
                        });
                    });

                    PubSub.trigger('simpletarget:update');
                }
            },

            /**
             *  Play the recommended next video
             */
            playNextRecommendation: function() {
                if (this.sv.recommend && !$.isEmptyObject(this.sv.recommend.videoResponse)) {
                    this.sv.recommend.playNextRecommendation(this.sv.video.placement.mediatemplate);
                } else {
                    this.subviews.plutovideo.resetVideoState();
                }
            },

            onFullScreenChange: function() {
                if (this.options.onFullScreenChange) {
                    this.options.onFullScreenChange();
                } else if (this.sv.scroll) {
                    this.sv.scroll.onFullScreenChange();
                }
            },


            /**
             * Destroys the view
             */
            destroy: function () {
                StateManager.startRefreshTimer();
                this.sv = {};
                BaseView.prototype.destroy.apply(this, arguments);
            }
        });

    /**
     * Return view class.
     */
    return PlutoWrapper;
});

