define('modules/global/breaking-news',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'utils',
    'state',
    'modules/global/barrier',
    'modules/global/livevideo',
    'easing',
    'animatecolors'
],
function($, _, BaseView, PubSub, Utils, StateManager, Barrier, LiveVideo) {
    'use strict';

    var BreakingNews = BaseView.extend({

        events: {
            'click .bnm-breaking-link': 'onClickBreakingLink',
            'click .bnm-close-btn': 'onClickCloseBreakingBtn'
        },

        breakingColors: {
            breaking: {
                normal: 'rgb(247,0,0)',
                highlight: 'rgb(191,49,49)'
            },
            live: {
                normal: 'rgb(0,170,255)',
                highlight: 'rgb(0,145,218)'
            },
            developing: {
                normal: 'rgb(0,170,255)',
                highlight: 'rgb(0,145,218)'
            }
        },
        breakingBarHeight: 0,

        initialize: function(options) {

            options = $.extend({
                slideSpeed: 200,
                onBreakingNewsChange: null
            }, options);

            _.bindAll(this, 'updateBreakingBar', 'checkBreakingNewsBarrier');

            // Breaking News Bar
            // Set breaking bar's initial lastUpdate to the lastUpdate of when the bar was manually closed
            this.lastUpdate = localStorage.getItem('breakingLastUpdateClosed');
            this.updateBreakingBar();
            // Breaking news poll.
            this.breakingBarPoll = setInterval(this.updateBreakingBar, 91000);

            this.barrier = new Barrier();

            BaseView.prototype.initialize.call(this, options);
        },

        destroy: function() {
            clearTimeout(this.initBreakingBar);
            clearInterval(this.breakingBarPoll);
            BaseView.prototype.destroy.apply(this, arguments);
        },

        getHeight: function() {
            return this.breakingBarHeight;
        },

        /**
         * Click handler for close button in breaking news bar.
         * @param {Event} e Click event object.
         */
        onClickCloseBreakingBtn: function(e) {
            this.closeBreakingBar(true);
            PubSub.trigger('uotrack', 'breakingnewsbarexit');
        },

        /**
         * Breaking news click handler.
         * @param {Event} e Hover event.
         */
        onClickBreakingLink: function(e) {
            var href = $(e.currentTarget).attr('href');
            if (href.indexOf('livevideoassetid') > 0) {
                if (typeof this.currentlivevideo != 'undefined'){
                    this.currentlivevideo.close();
                }
                var live = new LiveVideo();
                live.loadLiveVideo(href.split('#')[1].split('=')[1]);
                this.currentlivevideo = live;
                e.preventDefault();
            }
        },

        /**
         * Close breaking news bar.
         * @param {Boolean} [clickClose] True if closing from from click, not automatically during poll.
         */
        closeBreakingBar: function(clickClose) {
            PubSub.trigger('breakingbar:closing', this.options.slideSpeed);
            if (!this.breakingBarHeight) {
                return false;
            }
            if (clickClose) {
                localStorage.setItem('breakingLastUpdateClosed', this.lastUpdate);
            }
            this.breakingBarHeight = 0;
            if (this.options.onBreakingNewsChange) {
                this.options.onBreakingNewsChange(false);
            }
            return this.$el.animate({height: 0}, this.options.slideSpeed, _.bind(function() {
                    this.$el.css('background-color', '');
                    this.$el.hide();
                    PubSub.trigger('breakingbar:after:close');
                }, this));
        },

        /**
         * Open breaking news bar.
         */
        openBreakingBar: function() {
            PubSub.trigger('breakingbar:opening', this.options.slideSpeed);
            this.$el.show();
            var newBreakingBarHeight = this.$('.bnm-bar').outerHeight();
            if (newBreakingBarHeight != this.breakingBarHeight) {
                this.breakingBarHeight = newBreakingBarHeight;
                if (this.options.onBreakingNewsChange) {
                    this.options.onBreakingNewsChange(true);
                }
                this.animate(this.$el, 'height', this.breakingBarHeight, this.options.slideSpeed).done(this.onCompleteOpenBreakingBar);
            }
        },

        /**
         * Update breaking bar after opening it.
         */
        onCompleteOpenBreakingBar: function() {
            PubSub.trigger('breakingbar:after:open');
        },

        /**
         * Update breaking news bar.
         */
        updateBreakingBar: function() {
            if (this.busyCheckingBreakingNews) return;
            this.busyCheckingBreakingNews = true;
            StateManager.persistentFetchHtml('/services/breaking-news/nav-bar/', { headers: { 'x-no-nav': 'true' } })
                .done(this.checkBreakingNewsBarrier);
        },

        addToBarrier: function(promise, timeout, optional) {
            this.barrier.add(promise, timeout, optional);
        },

        /**
         * Checks to see if there is currently a barrier in place for the breaking news
         * @param {jQuery} breakingHtml HTML response text from XHR.
         */
        checkBreakingNewsBarrier: function(breakingHtml) {
            var breaking = breakingHtml.find('.bnm-bar');
            if (!breaking.length) {
                // nothing to update, we aren't checking anymore
                this.busyCheckingBreakingNews = false;
                return;
            }
            this.barrier.wait().done(_.bind(function() {
                this.parseBreakingNews(breaking);
            },this));
        },

        /**
         * Parse breaking news response and append/show/hide.
         * @param {jQuery} breaking HTML.
         */
        parseBreakingNews: function(breaking) {
            try {
                var breakingType = breaking.data('type'),
                    lastBreakingUpdate = breaking.data('lastUpdate'),
                    pageInfo = StateManager.getActivePageInfo();

                //check to suppress breaking news on branded content pages
                if(pageInfo && pageInfo.sponsor_story) {
                    this.closeBreakingBar();
                } else if (this.lastUpdate != lastBreakingUpdate) {
                    // do we need to update the breaking bar?
                    if (this.lastUpdate != lastBreakingUpdate && !pageInfo.sponsor_story) {
                        // replace contents with new
                        this.$el.html(breaking).stop();
                        this.lastUpdate = lastBreakingUpdate;

                        var breakingAlreadyOpen = (this.breakingBarHeight > 0);

                        // make sure breaking bar is visible
                        this.openBreakingBar();

                        if (breakingAlreadyOpen) {
                            // breaking bar already open, so flash to highlight change
                            var highlightColor = this.breakingColors[breakingType].highlight;
                            var normalColor = this.breakingColors[breakingType].normal;
                            this.$('.bnm-bar')
                                .animate({'backgroundColor': highlightColor})
                                .animate({'backgroundColor': normalColor});
                        }
                    }
                }
            } catch (e) {
                // if there's a problem get rid of the breaking bar
                this.closeBreakingBar();
            } finally {
                // no matter what, we're done updating the breaking news bar
                this.busyCheckingBreakingNews = false;
            }
        }
    });
    return BreakingNews;
});

