/**
 * @fileoverview CareerBuilderSearch
 * @author ttrayhan@gannett.com (Tim Trayhan)
 */
define('modules/career-builder-search/career-builder-search',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function(
    $,
    _,
    BaseView,
    Utils
    ) {
    "use strict";
    /**
     * View class.
     */
    var CareerBuilderSearch = BaseView.extend({

        events: {
            'click .cba-search-btn' : 'seachCareerBuilder'
        },

        /**
         * Initialize view.
        */
        initialize: function() {
            BaseView.prototype.initialize.call(this);
            this.$form = this.$(".seach-form");
            this.$init_url =  this.$form.attr("action");
        },

        /**
         * searchCareerBuilder - Takes the location and keyword values from the search form, slugifies them, and sends them along to
                Career Builder as a url they are expecting.
        */

        seachCareerBuilder : function(event) {
            var loc = this.slugify(this.$('.cba-search-location').val());
            var url = this.$init_url;
            var keyword = this.slugify(this.$('.cba-search-keyword').val());

            if(loc !== ''){
                url += '/' + loc;
            }
            if(keyword !== ''){
                url += '/keyword/' + keyword;
            }

            url += '?' + this.$('.form-param-input').val();

            this.$form.attr("action", url);
        },

        slugify: function(str){
            return str.toString().trim().toLowerCase().replace(/(\s+|[^\w\-]|\-\-+)/g, '-');
        }
    });

    /**
     * Return view class.
     */
    return CareerBuilderSearch;
}
);

