define('managers/migrationmanager',[],
    function() {
        "use strict";
        /**
         * Migration manager keeps track of any migrations we've run on the end user, if it's missing any, will run the function.
         * It's up to the function to handle whatever scenario they need, whether it's initial setup, deleting bad data, or
         * migrating to a new format
         * @exports managers/migrationmanager
         * @author jmerrifiel@gannett.com (Jay Merrifield)
         */
        var MigrationManager = function() {
            this.siteInfo = JSON.parse(window.localStorage.getItem('wfm_siteinfo'));
            if (!this.siteInfo) {
                this.siteInfo = this._initialSiteSetup();
                this._saveSiteInfo();
            }
        };
        MigrationManager.prototype = {
            /**
             * register your function to be called when a user upgrades from a version older than the version string
             * passed in to a matching or newer version
             * @param {String} key - version string identifying whether your migration has run
             * @param {Function} fn - function to call when user upgrades to/past the version passed in
             */
            registerMigration: function(key, fn) {
                if (!this.siteInfo.m[key]) {
                    try {
                        fn();
                        this.siteInfo.m[key] = 1;
                        this._saveSiteInfo();
                    } catch (ex) {
                        console.error('Migration bombed', (ex.stack || ex.stacktrace || ex.message));
                    }
                }
            },

            _saveSiteInfo: function() {
                try {
                    window.localStorage.setItem('wfm_siteinfo', JSON.stringify(this.siteInfo));
                } catch (ex) {
                    // catch Safari incognito errors
                    console.error('Failed saving siteInfo', (ex.stack || ex.stacktrace || ex.message));
                }
            },

            _initialSiteSetup: function() {
                return {
                    v: 1,
                    m: {}
                };
            }
        };
        return new MigrationManager();
    }
);
