/**
 * @fileoverview Rentals 2x1 view module.
 * @author rcopley@gannett.com (Ryan Copley)
 */
define('modules/rentals-local-rentals',[
        'jquery',
        'baseview',
        'state',
        'adPosition'
    ],
    function(
        $,
        BaseView,
        StateManager,
        AdPosition
    ) {

        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var RentalsLocalRentals = BaseView.extend({
            layoutType : "",
            renderedAd : false,
            renderAd : function (){
                if (!this.renderedAd){
                    this.renderedAd = true;
                    var el = this.$('.js-rlr-google-ad')[0];
                    if (el){
                        this.subviews.adPositionOne = this.createAdPosition(el);
                    }
                }
            },
            destroyAd : function () {
                if (this.renderedAd){
                    this.subviews.adPositionOne.destroy();
                    this.renderedAd = false;
                }
            },
            isSidebarModule : function () {
                return (this.layoutType === 'sidebar');
            },
            isPrimaryWellModule : function () {
                return (this.layoutType === 'primary');
            },
            createAdPosition: function (el){
                return new AdPosition({
                    el: $(el),
                    adSizes: [300,250],
                    adPlacement: $(el).data('adtag')
                });
            },
            initialize: function(options) {
                BaseView.prototype.initialize.call(this);
                this.layoutType = options.layoutType;
            },
            handleCardSizeChange: function(cardInfo) {
                if (this.isSidebarModule()){
                    if (cardInfo.sidebarAds === true){
                        this.$(".js-rentals-sidebar-ad").removeClass("rlr-hide-ad");
                        this.renderAd();
                    }else{
                        this.$(".js-rentals-sidebar-ad").addClass("rlr-hide-ad");   
                        this.destroyAd();
                    }
                }else if (this.isPrimaryWellModule()){
                    if (cardInfo.sidebarOpen === true){
                        this.destroyAd();                        
                    }else{
                        this.renderAd();
                    }
                }
                if (cardInfo.sidebarOpen === true){
                    if (this.layoutType === "primary"){
                        this.$(".js-rlr-wrap").addClass("rlr-dynamic-height");
                    }
                    this.$(".js-rlr-locals-wrap").removeClass("rlr-dynamic-margin").addClass("rlr-dynamic-border");
                    this.$(".js-rlr-wrap-primary").removeClass("rlr-top-border");
                }else{
                    if (this.layoutType === "primary"){
                        this.$(".js-rlr-wrap").removeClass("rlr-dynamic-height");
                    }
                    this.$(".js-rlr-locals-wrap").addClass("rlr-dynamic-margin").removeClass("rlr-dynamic-border");
                    this.$(".js-rlr-wrap-primary").addClass("rlr-top-border");
                }
            },
            onCardWidthChange: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            },
            renderCardInfo: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            }
        });

        /**
         * Return view class.
         */
        return RentalsLocalRentals;
    }
);

