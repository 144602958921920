define('form/input-field',[
    'jquery',
    'underscore',
    'form/base-form-element'
],
    function(
        $,
        _,
        BaseFormElement
        ) {
        "use strict";
        var InputField = BaseFormElement.extend(
        /**
         * @lends form/input-field.prototype
         */
        {

            events: {
                'focus':'onInputFocus',
                'blur':'onInputBlur',
                'keyup': 'onKeyPress'
            },

            /**
             * This callback is called when the value of the input field changes
             * @callback form/input-field~onValueChange
             * @param {String} value - The new value the input field has changed to
             */

            /**
             * @classdesc Manages Input Fields placeholders and clear buttons
             * @author Mark Kennedy <mdkennedy@gannett.com>
             * @constructs form/input-field
             * @extends form/base-form-element
             * @param {Object} options backbone options object
             *     @param {jQuery|Element|String} options.el - element or string selector to attach to
             *     @param {form/input-field~onValueChange} [options.onValueChange] - callback each time the value of the input field changes
             */
            initialize: function(options) {

                options = $.extend({
                    onValueChange: null
                }, options);

                BaseFormElement.prototype.initialize.call(this, options);

                // we are checking whether this $el is an input tag, if not, we are assuming it is a textarea tag
                this.isInputField = (this.$el.prop("tagName") || '').toLowerCase() === 'input' ? true : false;
                this.hasPlaceHolderNativeSupport = Modernizr.input.placeholder ? true : false;
                this.placeholder = this.$el.attr('placeholder');

                this.setup();
            },

            setup: function() {
                // handle placeholder
                if (this.placeholder) {
                    this.showPlaceHolder();
                }
                // setup clear button
                if (this.$el.data('clearable') && this.isInputField) {
                    this._setupClearButton();
                }
            },

            /**
             * Shows the placeholder.
             */
            showPlaceHolder: function(){
                if (!this.hasPlaceHolderNativeSupport) {
                    this.setInputValue(this.placeholder);
                    this.$el.addClass('ui-text-input-placeholder');
                }
            },

            /**
             * Clears the placeholder.
             */
            clearPlaceHolder: function(){
                if (!this.hasPlaceHolderNativeSupport) {
                    this.clearInputValue();
                    this.$el.removeClass('ui-text-input-placeholder');
                }
            },

            /**
             * Gets the current value of the input field.
             * @returns {String}
             */
            getInputValue: function(){
                var inputValue = this.$el.val();
                if (inputValue === this.placeholder) {
                    inputValue = '';
                }
                return inputValue;
            },

            /**
             * Clears the input field's value.
             */
            clearInputValue: function(){
                var oldValue = this.getInputValue();
                this.$el.val('');
                // field is only technically 'cleared' out if a value was previously in it
                if (oldValue) {
                    this._triggerValueChange();
                }
            },

            /**
             * Sets the value of the input field.
             * @param {String} value The new value
             */
            setInputValue: function(value){
                this.$el.val(value);
                this._triggerValueChange();
            },

            /**
             * Sets up clear button.
             * @private
             */
            _setupClearButton: function(){
                this.clearButton = $('<div class="ui-text-input-clear-btn" style="display:none"></div>');
                this.$el.parent().append(this.clearButton);
                this.clearButton.on('mousedown.' + this.cid, _.bind(function(e){
                    this.onClearButtonClick(e);
                }, this));
            },

            /**
             * Handles focus events on an input field.
             */
            onInputFocus: function(e) {
                var inputValue = this.getInputValue();
                if (!inputValue) {
                    this.clearPlaceHolder();
                } else {
                    if (this.clearButton){
                        this.clearButton.show();
                    }
                }
            },

            /**
             * Handles blur events on an input field.
             * @param {Event} e blur event
             */
            onInputBlur: function(e) {
                if (!this.getInputValue()) {
                    this.reset();
                }
                if (this.clearButton){
                    this.clearButton.hide();
                }
            },

            /**
             * Announces that the input field value has changed.
             * @private
             */
            _triggerValueChange: function() {
                var value = this.getInputValue();
                if (this.clearButton) {
                    if (value) {
                        this.clearButton.show();
                    } else {
                        this.clearButton.hide();
                    }
                }
                if (this.options.onValueChange) {
                    this.options.onValueChange(value);
                }
            },

            /**
             * Resets input field
             * @deprecated since version 0.14.0
             */
            resetInput: function() {
                this.reset();
            },

            /**
             * Resets input field
             */
            reset: function() {
                this.clearInputValue();

                if (this.placeholder) {
                    this.showPlaceHolder();
                }

                if (this.clearButton) {
                    this.clearButton.hide();
                }

            },

            /**
             * Handler for typing.
             */
            onKeyPress: function() {
                // we need to produce a slight delay to get the item pressed via the input field
                clearTimeout(this.keyPressDelay);
                this.keyPressDelay = setTimeout(_.bind(function() {
                    this._triggerValueChange();
                },this), 1);
            },

            /**
             * Gets element type.
             * @returns {String}
             */
            getType: function(){
                return "input";
            },

            /**
             * Handler for clicking the clear icon
             */
            onClearButtonClick: function(e) {
                e.preventDefault();
                this.clearInputValue();
                this.clearButton.hide();
                this.$el.focus();
            },

            destroy: function(removeEl){
                if (this.clearButton) {
                    this.clearButton.off('.' + this.cid);
                    this.clearButton.remove();
                }
                BaseFormElement.prototype.destroy.call(this, removeEl);
            }

        });

        /**
         * Return view class.
         */
        return InputField;
    }
);
