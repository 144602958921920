/**
 * @fileoverview Google Survey module view.
 * @author Nick Blakey <nblakey@gannett.com>
 */
define('modules/stories/google-survey',[
    'jquery',
    'underscore',
    'utils',
    'state',
    'baseview',
    'user-manager',
    'managers/cachemanager',
    'pubsub',
    'admanager',
    'managers/autoplay',
    'cookie'
],
function (
    $,
    _,
    Utils,
    StateManager,
    BaseView,
    UserManager,
    CacheManager,
    PubSub,
    AdManager,
    AutoPlayManager
) {
    var GoogleSurveyView = BaseView.extend({

        initialize: function(options) {
            var surveyOptions = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'GOOGLECONSUMERSURVEY') || {},
                userAccount = UserManager.getAccount('firefly'),
                pageURL = this._getLocation(),
                surveyCompletePromise = $.Deferred(),
                pageInfo = StateManager.getActivePageInfo(),
                adBlockCookie = $.cookie(surveyOptions.ad_block_cookie);

            if (!surveyOptions.ENABLED || !this.surveyChecksPass(surveyOptions) || pageInfo.sponsored_series ||
                $('.p402_premium').find('.companion-story-gallery').length !== 0 ||
                AdManager.isAdFreeExperience() )
            {
                PubSub.trigger('fireflyout:open');
                return false;
            }

            /**
            * Callback methods to be used by Google
            **/

            //User has completed a survey and the access period has not expired - usually expires in 24 hours
            window.gcsOnSurveyComplete = function() {
                var localStorageGS = {
                    'date': new Date(),
                    'reliefPeriod': Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'GOOGLECONSUMERSURVEY', 'relief_period') ||  (24 * 60 * 60 * 1000),
                    'isSet': function() {
                        if (localStorage.getItem('google-survey-complete-datetime')) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    'isExpired': function(date) {
                        return (this.getSavedExpireTime() < this.date.getTime())? true : false;
                    },
                    'createNewExpireTime': function() {
                        return (parseInt(this.reliefPeriod, 10) + this.date.getTime());
                    },
                    'setExpirationTime': function(setExpireTime) {
                        localStorage.setItem('google-survey-complete-datetime', setExpireTime);
                    },
                    'getSavedExpireTime': function() {
                       return parseInt(localStorage.getItem('google-survey-complete-datetime'), 10);
                    }
                };

                if(localStorageGS.isSet() && !localStorageGS.isExpired()) {
                    PubSub.trigger('fireflyout:open');
                } else {
                    var expirationTime = localStorageGS.createNewExpireTime();
                    localStorageGS.setExpirationTime(expirationTime);
                }

                //PubSub.trigger('gcs:complete')
                surveyCompletePromise.resolve();
                window.scrollTo(0, 1);
            };


            //We have requested a survey, but Google will not deliver one
            window.gcsNoSurveyDelivered = function() {
                PubSub.trigger('fireflyout:open');
                surveyCompletePromise.resolve();
            };

            BaseView.prototype.initialize.call(this, options);
            this.setAlternativeActionResult(pageURL);
            
            AdManager.getActiveSlotStatistics().done(_.bind(function(info) {
                AutoPlayManager.register('google-survey', _.bind(function(){
                    if (surveyOptions.gravity_override && _.findWhere(info.high_impact_info, {'adType': 'gravity'})) {
                        surveyCompletePromise.resolve();
                        return surveyCompletePromise;
                    } else {
                        if(adBlockCookie !== 'true') {
                            if(userAccount) {
                                userAccount.getUserInfo().always(_.bind(function(userInfo) {
                                    if(!userInfo || userInfo === "Not logged in." || surveyOptions.need_market_access && !userInfo.hasMarketAccess) {
                                        this.loadGoogleConsumerSurvey(surveyOptions, pageURL, surveyCompletePromise);
                                    } else {
                                        surveyCompletePromise.resolve();
                                    }
                                }, this));
                            } else {
                                this.loadGoogleConsumerSurvey(surveyOptions, pageURL, surveyCompletePromise);
                            }
                        } else {
                            surveyCompletePromise.resolve();
                        }
                    return surveyCompletePromise;
                    }
                }, this));
            }, this));
        },

        surveyChecksPass: function(surveyOptions) {
            var completedAAU = parseInt(this.getAlternativeActionResult(), 10),
                articleCount = this.getEligibleArticleCount(),
                date = new Date();

            /**
             * Certain markets want to have the ability to show a Google Survey on the third survey-eligible page, rather than the first.
             * If the number of articles isn't specified in the config, show on 1st article. If it is in config, check against number
             * of eligible articles visited.
             **/

            if(!surveyOptions.show_on_article_number || articleCount >= surveyOptions.show_on_article_number) {
                //If the user hasn't completed an AAU
                if(!completedAAU) {
                    return true;
                }
                //If the user has completed an AAU, but the relief period has expired
                else if(date.getTime() > completedAAU + surveyOptions.relief_period) {
                    return true;
                }
            }
            else {
                articleCount++;
                CacheManager.setValue('gcs-eligible-page-count', articleCount);
            }
            return false;
        },

        /**
         * Request that a Google Consumer Survey be displayed on this page.
         */
        loadGoogleConsumerSurvey: function(pageOptions, articleURL) {
            if(pageOptions.publisher_id && articleURL) {
                var contentId = 'everything',
                    surveyURL = '//survey.g.doubleclick.net/survey';

                return StateManager.fetchData(surveyURL, {
                    dataType: 'script',
                    data: {
                        site: '_'+pageOptions.publisher_id,
                        url: articleURL,
                        cid: contentId,
                        after: '1'
                    }
                });
            } else {
                return $.Deferred().reject();
            }
        },

        setAlternativeActionResult: function(pageURL) {
            if(pageURL) {
                if(pageURL.indexOf('#gcs-aau-success') > -1) {
                    var date = new Date();
                    localStorage['gcs-aau-fulfilled'] = date.getTime();
                    window.location.hash = "";
                }
            }
        },

        getAlternativeActionResult: function() {
            return localStorage.getItem('gcs-aau-fulfilled');
        },

        getEligibleArticleCount: function() {
            return CacheManager.getValue('gcs-eligible-page-count', 1);
        },

        _getLocation: function() {
            return window.location.href;
        },

        destroy: function() {
            /**
            * Fix related to GDPWEB-3938. Elided text is unblocked when user navigates through assets without initiating
            * a page load and scrolls on that asset. In order to prevent that, we must destroy the existing survey iframe
            * and all related t402 items (which Google dynamically loads on the page). For some reason, a page scroll
            * following the deletion of the iframe container is the only known way to consistently achieve the desired action.
            **/

            $('.t402-prompt-iframe-container').remove();
            window.scrollTo(0, 1);

            //Delete window variables/methods we created for Google Survey callbacks
            delete window.gcsOnSurveyComplete;
            delete window.gcsNoSurveyDelivered;


            BaseView.prototype.destroy.call(this);
        }
    });

    return GoogleSurveyView;
});

