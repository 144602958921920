/**
 * @fileoverview Series Ribbon module
 * @author Ryan Copley <rcopley@gannett.com> / David Burke <dburke2@gannett.com>
 */
define('modules/series-ribbon/series-ribbon',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'pubsub',
    'state',
    'managers/requestmanager',
    'site-manager',
    'adPosition'
],
function (
    $,
    _,
    BaseView,
    Utils,
    PubSub,
    StateManager,
    RequestManager,
    SiteManager,
    AdPosition
) {
    /**
     * View class.
     */
    var SeriesRibbon = BaseView.extend({

        // Events.
        events: {
            'click .srb-stb-prev-img': 'onPrevClick',
            'click .srb-stb-next-img': 'onNextClick'
        },

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);
            _.bindAll(
                this,
                'updateDom',
                'renderServerTemplate'
            );

            this.pubSub = $.extend({
                'breakingbar:after:open': this.onBreakingNewsOpen,
                'breakingbar:after:close': this.onBreakingNewsClose,
                'header:fixed': this.onHeaderFixed,
                'header:unfixed': this.onHeaderUnfixed
            }, this.pubSub);
            PubSub.attach(this.pubSub, this);

            this.bnm_bar_height = 0;
            this.fixed_header = false;
            this.article_count = 1;
            this.item_width = 0;
            this.current_position = 0;
            this.prev_button_width = 0;
            this.next_button_width = 0;

            this.options = $.extend(true, {
                count: 12
            }, options);
            this.loadModule();
        },

        onBreakingNewsOpen: function() {
            this.bnm_bar_height = $('.bnm-bar').outerHeight();

            //the newsreel module might not be rendered yet so only adjust the newsreel position if it exists and its in the fixed state.
            if (this.srb_stb && this.fixed_header) {
                this.adjustNewsreelPosition();
            }
        },

        onBreakingNewsClose: function() {
            this.bnm_bar_height = 0;

            this.adjustNewsreelPosition();
        },

        onHeaderFixed: function() {
            this.fixed_header = true;

            if (this.srb_stb) {
                this.setFixedNewsreel();
            }

            PubSub.trigger('newsreel:fixed');
        },

        onHeaderUnfixed: function() {
            this.fixed_header = false;

            this.srb_stb.removeClass('srb-stb-fixed');

            PubSub.trigger('newsreel:unfixed');
        },

        setFixedNewsreel: function() {
            this.srb_stb.addClass('srb-stb-fixed');

            this.adjustNewsreelPosition();
        },

        onPrevClick: function() {
            //since the window size can change between button clicks, we'll need to recalcualte the items per page
            var items_per_page = this.getItemsPerPage();

            if (this.current_position > 0) {
                var offsetLeft = 0;

                this.current_position -= items_per_page;

                if (this.current_position > 0) {
                    offsetLeft = this.srb_stb_asset[this.current_position].offsetLeft + 20 - this.prev_button_width;
                }
                else if (this.current_position < 0) {
                    this.current_position = 0;
                }

                this.$('.srb-stb-assets-wrapper').animate({
                    scrollLeft: offsetLeft
                }, 500, 'swing');
            } else {
                //scroll the other direction when there aren't any articles left
                this.current_position = this.article_count - this.getItemsPerPage();
                this.$('.srb-stb-assets-wrapper').animate({
                    scrollLeft: this.srb_stb_asset[this.current_position].offsetLeft + 20 - this.prev_button_width
                }, 500, 'swing');
            }
        },

        onNextClick: function() {
            //since the window size can change between button clicks, we'll need to recalcualte the items per page
            var items_per_page = this.getItemsPerPage();
            var remaining_articles = this.article_count - (this.current_position + items_per_page);

            if (remaining_articles > 0) {
                this.current_position += (remaining_articles > items_per_page) ? items_per_page : remaining_articles;

                this.$('.srb-stb-assets-wrapper').animate({
                    scrollLeft: this.srb_stb_asset[this.current_position].offsetLeft + 20 - this.prev_button_width
                }, 500, 'swing');
            } else {
                //scroll the other direction when there aren't any articles left
                this.$('.srb-stb-assets-wrapper').animate({
                    scrollLeft: 0
                }, 500, 'swing');
                this.current_position = 0;
            }
        },

        getItemsPerPage: function() {
            var available_width = this.srb_stb_assets_nav.outerWidth() - this.prev_button_width - this.next_button_width;
            return Math.floor(available_width / this.item_width);
        },

        adjustNewsreelPosition: function() {
            this.srb_stb.css({
                'top': (40 + this.bnm_bar_height) + 'px'
            });
        },

        /**
         * Fetch module contents based on  and handle response.
         */
        loadModule: function() {
            var options = this.options;
            var assetId = Utils.getNested(StateManager.getActivePageInfo(), 'assetid');
            var params = _.pick(options, ['exclude','metric','count','ssts','days','assetType']);
            RequestManager.fetchHtml('/modules/series-ribbon/' + assetId + '?' + $.param(params))
            .done(_.bind(function(html) {
                this.renderServerTemplate(html);

                this.srb_stb_asset = this.$('.srb-stb-asset');
                this.article_count = this.srb_stb_asset.length;

                if (this.article_count > 0) {
                    this.srb_stb = this.$('.srb-stb');
                    this.srb_stb_assets_nav = this.srb_stb.find('.srb-stb-assets-nav');
                    this.srb_stb_prev_img = this.srb_stb_assets_nav.find('.srb-stb-prev-img');
                    this.srb_stb_next_img = this.srb_stb_assets_nav.find('.srb-stb-next-img');
                    this.prev_button_width = this.srb_stb_assets_nav.find('.srb-stb-fade-left').outerWidth();
                    this.next_button_width = this.srb_stb_assets_nav.find('.srb-stb-fade-right').outerWidth();
                    this.item_width = this.srb_stb_asset.first().outerWidth();

                    //set the modules container's height so all content is pushed down when the module uses a fixed position
                    this.$el.css({
                        'height': this.srb_stb.outerHeight()
                    });

                    //set the module to the same state the header is currently in.
                    this.header = SiteManager.getHeader();
                    if (this.header.state.fixed) {
                        this.onHeaderFixed();
                    }



                    this.subviews.ad = new AdPosition({
                        el: $(".js-srb-ad"),
                        adSizes: [220,90],
                        adPlacement: "native-ribbon_tile"
                    });

                    if (this.article_count < 6) {
                        this.srb_stb_prev_img.css({
                            "display" : "none"
                        });
                        this.srb_stb_next_img.css({
                            "display" : "none"
                        });

                    }
                }
            }, this));
        },

        /**
         * Process (add click handlers, etc) server-rendered HTML, then attach to DOM.
         */
        renderServerTemplate: function(htmlString) {
            var processedHtml = this.processHtmlString(htmlString);
            this.updateDom(processedHtml);
        },

        /**
         * Convert html string to DOM nodes, then attach tracking to links and return nodes.
         */
        processHtmlString: function(html) {
            var nodes = _.isString(html) ? $.parseHTML(html) : html;
            return nodes;
        },

        /**
         * Append rendered feed html to module container, set module title, and log ab test impression.
         */
        updateDom: function(contentHtml) {
            this.$el.append(contentHtml);
        },


    });

    /**
     * Return view class.
     */
    return SeriesRibbon;
});

