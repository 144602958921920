/**
 * @fileoverview Current view module.
 * @author mbriede@gannett.com (Matthew Briede)
 */
define('modules/weather/weather-current',[
    'jquery',
    'underscore',
    'baseview',
    'adPosition'
],
function(
    $,
    _,
    BaseView,
    AdPosition
) {
    "use strict";
    /**
     * View class.
     */

    var weatherCurrentModule = BaseView.extend({

        classes: {
            poster: 'poster-ad-primary'
        },

        initialize: function(options){
            this.$posterAd = this.$('.' + this.classes.poster);
            BaseView.prototype.initialize.call(this, options);
        },

        /**
         * Triggered from card.js
         *
         * @param {Object} currentCardInfo provides state information about the card
         */
        renderCardInfo: function(currentCardInfo) {
            if (!currentCardInfo.sidebarOpen) {
                this.show();
                this._buildPosterAd();
            }
            this.currentCardInfo = currentCardInfo;
        },

        _buildPosterAd: function(){
            if (this.$posterAd.length && !this.subviews.posterAd) {
                this.subviews.posterAd = new AdPosition({
                    el: this.$posterAd,
                    adSizes: 'mediumrectangle',
                    adPlacement: 'poster_front',
                    adType: 'poster'
                });
            }
        },

        /**
         * Triggered from card.js
         *
         * @param {Object} currentCardInfo provides state information about the card
         */
        onCardWidthChange: function(currentCardInfo) {
            if (currentCardInfo.sidebarOpen === this.currentCardInfo.sidebarOpen) {
                return;
            }
            this.currentCardInfo = currentCardInfo;
            if (currentCardInfo.sidebarOpen) {
                if (this.subviews.posterAd) {
                    this.subviews.posterAd.hide();
                }
            } else {
                if (this.subviews.posterAd) {
                    this.subviews.posterAd.show();
                } else {
                    this._buildPosterAd();
                }
            }
        }
    });

    /**
     * Return view class.
     */
    return weatherCurrentModule;
});

