/**
 * @fileoverview Placester Apartments
 * @author mguirat@gannett.com (Marouan BEN GUIRAT)
 */
define('modules/placester/placester-search',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'pubsub'
],
function(
    $,
    _,
    BaseView,
    Utils,
    PubSub
    ) {
    "use strict";
    /**
     * View class.
     */
    var Placester = BaseView.extend({
        events: {
        //DO NOT CHANGE THE EVENT FOCUS
        'focus .psfm-hf-form-price-input' : 'inputFocus',
        'click .psfm-hf-form-label' : 'generalDropdown',
        'click .psfm-hf-form-price-options .psfm-hf-form-option' : 'priceClick',
        'change .psfm-hf-form-price-input' : 'inputChange',
        'click .psfm-hf-filter-option' : 'criteriaClick',
        'click .psfm-hf-btn' : 'searchPlacester'
        },

        /**
         * Initialize view.
         */
        initialize: function(options) {
            this.actionPlacester = ["",""];
            this.$priceFilterPane = this.$('.psfm-hf-price-filter-pane');
            this.$priceOptions = this.$('.psfm-hf-form-price-options');
            this.$priceLabel = this.$('.psfm-hf-form-price-label');
            this.$container = this.$('.psfm-hf-filter-pane');
            this.$actionUrl = this.$(".seach-form").attr("action");
            this.$actionBtn = this.$(".psfm-hf-btn");

            $('body').on('mouseup.'+ this.cid, _.bind(function(event){
                var button = this.$('.psfm-hf-form-label'),
                    criteriaOption = this.$(".psfm-hf-form-option"),
                    inputPrice = this.$(".psfm-hf-form-price-input"),
                    areaInput = this.$(".psfm-hf-input-area");

                if(!button.is(event.target) && !criteriaOption.is(event.target) &&
                   !inputPrice.is(event.target) && !areaInput.is(event.target) && this.$container.is(":visible")){
                    if (!button.is($(event.target).parent())){
                        this.$container.toggleClass("psfm-closed", this.$container.is(":visible"));
                    }
                }
            },this));

            // updates eVar99 with LDSN
            PubSub.trigger('group_page_view', 'LDSN');
            // updates eVar66
            PubSub.trigger('product_name', 'Homes');
        },

        searchPlacester: function() {
            var area = this.$(".psfm-hf-input-area").val().toLowerCase();

            if (area.match(/(\d{5}(?:-\d{4})?)/)){
                // Matches if a zip/zip+4 code is provided in the area query, such as 10001, 10001-2334.
                // We check for this first as this is the most authoritative information that can be provided.
                // Zip code is more specific than just city/state.
                this.actionPlacester[0] = "zip=";
                this.actionPlacester[1] = area.match(/(\d{5}(?:-\d{4})?)/)[1];
            }else if (area.trim().match(/^([^,]+),\s*(\w{2})$/)){
                // Matches a city and two letter state, such as "New York, NY" and uses just the city
                var areaArray = area.trim().match(/^([^,]+),\s*(\w{2})$/);
                this.actionPlacester[0] = "locality=";
                this.actionPlacester[1] = encodeURIComponent((areaArray[1].charAt(0).toUpperCase() + areaArray[1].slice(1)));
            }else if( this.isInt(area) ){
                // Matches if the only information provided is a zip code.
                this.actionPlacester[0] = "zip=";
                this.actionPlacester[1] = area;
            }else if (area.length === 0) {
                // Used if there is no input query.  Other search parameters are still possible.
                this.actionPlacester[0] = "text_search=";
                this.actionPlacester[1] = "";
            }else {
                // Fallback.  If query does not match anything, perform a generic search on Placester.
                this.actionPlacester[0] = "text_search=";
                this.actionPlacester[1] = encodeURIComponent(area);
            }

            var attributeSearch = _.reduce(this.actionPlacester, function(memo, num){ return memo + num; });
            var addons = 'view=gallery&purchase_types=buy&';
            this.$actionBtn.attr("href", this.$actionUrl + "?" + addons + attributeSearch);

        },

        changeSearchAttribute: function(valueSelected, attribute) {
            if (!isNaN(valueSelected)){
                switch(attribute) {
                    case 'bed':
                        this.actionPlacester[2] = "&min_beds="+valueSelected;
                        break;
                    case 'bath':
                        this.actionPlacester[3] = "&min_baths="+valueSelected;
                        break;
                    case 'min_price':
                        this.actionPlacester[4] = "&min_price="+valueSelected;
                        break;
                    case 'max_price':
                        this.actionPlacester[5] = "&max_price="+valueSelected;
                        break;
                }
            }
        },

        generalDropdown: function(event) {
            if (!this.$container.not(".psfm-closed").is(event.currentTarget.nextElementSibling)){
                this.$container.not(".psfm-closed").addClass("psfm-closed");
            }
            $(event.currentTarget.nextElementSibling).toggleClass("psfm-closed");
            if ($(event.currentTarget).hasClass("psfm-hf-form-price-label")) {
                if(!this.$(".psfm-hf-form-dropdown-menu[data-price='max']").hasClass("psfm-closed")){
                    this.$(".psfm-hf-form-payment-min").focus();
                }
            }
        },

        inputFocus: function(event) {
            var targetLink      = $(event.currentTarget),
                priceTuple      = targetLink.attr("placeholder").toLowerCase();

            if(!this.$(".psfm-hf-form-dropdown-menu[data-price='"+priceTuple+"']").parent().is(":visible")){
                this.$priceOptions.toggleClass("psfm-closed");
            }
        },

        priceClick: function(event) {
            var targetLink     = $(event.currentTarget),
                item           = targetLink.parent(),
                parentItem     = item.parent(),
                priceTuple     = parentItem.data("price"),
                priceSelected  = item.data("value").replace(/,/g, "");

            this.priceLabelDisplay(priceTuple, priceSelected);
            this.changeSearchAttribute(priceSelected, priceTuple + "_price");
            this.$(".psfm-hf-form-payment-"+priceTuple).val(item.data("value"));

            if ($(".psfm-hf-form-payment-min").val().length > 0 && $(".psfm-hf-form-payment-max").val().length > 0) {
                this.$container.toggleClass("psfm-closed", this.$container.is(":visible"));
            }

        },

        criteriaClick: function(event) {
            var targetLink    = $(event.currentTarget),
                item          = targetLink.parent(),
                parentItem    = item.parent(),
                criteria      = parentItem.data("criteria");

            $(".psfm-hf-"+ criteria +"-select").html(item.text());
            this.$container.toggleClass("psfm-closed", this.$container.is(":visible"));
            this.changeSearchAttribute(item.data("value"), criteria);
        },

        inputChange: function(event) {
            var targetLink      = $(event.currentTarget),
                price           = targetLink.val().replace(/,/g, ""),
                priceTuple      = targetLink.attr("placeholder").toLowerCase();

            this.priceLabelDisplay(priceTuple, price);
            this.changeSearchAttribute(price, priceTuple + "_price");
        },

        priceLabelDisplay: function (priceTuple, price){
            var priceDisplay = "";
            this.$(".psfm-hf-form-price").data(priceTuple, price);

            if(this.$(".psfm-hf-form-price").data("min") !== 0 && this.$(".psfm-hf-form-price").data("max") !== 0){
                this.$(".psfm-hf-form-price-prepend, .psfm-hf-form-price-append").hide();
                priceDisplay = this.priceConvert($(".psfm-hf-form-price").data("min")) + " - " + this.priceConvert($(".psfm-hf-form-price").data("max"));
            }else{
                priceDisplay = this.priceConvert($(".psfm-hf-form-price").data(priceTuple));
                if($(".psfm-hf-form-price").data("min") === 0){
                    $(".psfm-hf-form-price-prepend").html("Up to ");
                }else{
                    $(".psfm-hf-form-price-append").html(" +");
                }
            }

            $(".psfm-hf-form-price").html(priceDisplay);
        },

        priceConvert:function(price){
            var priceDisplay = "$";
            if(price >= 1000) {
                priceDisplay += price.match(/^(\d.*)\d{3}$/)[1] + "K";
            } else {
                priceDisplay += price;
            }
            return priceDisplay;
        },

        isInt: function(value){
            if (isNaN(value)) {
                return false;
            }
            var valueFloat = parseFloat(value);
            return (valueFloat | 0) === valueFloat;
        },

        destroy: function(removeEl){
            $('body').off('.'+ this.cid);
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });

    /**
     * Return view class.
     */
    return Placester;
}
);

