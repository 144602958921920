define('modules/videos/video-playlist-init',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'modules/videos/video-gallery',
    'modules/videos/pluto/pluto-video-gallery',
    'modules/global/video-carousel',
    'modules/videos/uw/uw-wrapper'
],
function(
    $,
    _,
    BaseView,
    Utils,
    VideoGallery,
    PlutoVideoGallery,
    VideoCarousel,
    UwWrapper
){
    /**
     *  View Class
     */
    var VideoPlaylistWrapper = BaseView.extend({
        /**
         *  Initialize view
         */
        initialize: function(options) {
            this.pubSub = {
                'entered:view:videoCarousel': this.onEnterView
            };
            BaseView.prototype.initialize.call(this, options);
            if (!(Utils.flag('ll_video_carousel') && this.$('.js-pluto-video-carousel')[0])) {
                this.initSubviews();
            }
        },

        onEnterView: function(el) {
            if ($.contains(this.el, el)) {
                this.initSubviews();
            }
        },

        initSubviews: function() {
            if (this.$el.hasClass('uw-playlist') || this.$('.uw-playlist-wrap').length) {
                this.subviews.videoview = new UwWrapper({el: this.$el});
            } else if (this.$el.hasClass('pluto-video-gallery')) {
                this.subviews.videoplaylist = new PlutoVideoGallery({el: this.$el});
            } else if (this.$el.hasClass('video-gallery')){
                this.subviews.videoplaylist = new VideoGallery({el: this.$el});
            } else if (this.$('.js-pluto-video-carousel')[0]) {
                this.subviews.videocarousel = new PlutoVideoGallery({
                    activeSelector: '.video-carousel-item-wrap.active',
                    el: this.$('.js-pluto-video-carousel')[0],
                    frontCarousel: true,
                    initializeThumbs: false,
                    slidesContainerSelector: '.video-carousel-series',
                    slidesSelector: '.video-carousel-item-wrap',
                    viewportSelector: '.video-carousel-viewport'
                });
            } else if (this.$('.js-carousel')[0]){
                this.subviews.videocarousel = new VideoCarousel({el: this.$el});
            }
        }
    });

    return VideoPlaylistWrapper;
});

