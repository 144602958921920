/**
 * @fileoverview Photo gallery module for Smart Topic Pages
 * @author Kevin Foster <kefoster@gannett.com>
 */
define('modules/topics/topics-photo-gallery',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'pubsub',
    'modules/scroller/horizontal-scroll',
    'modules/scroller/vertical-scroll',
    'site-manager',
    'state',
    'meteredAdPosition'
],
function (
    $,
    _,
    BaseView,
    Utils,
    PubSub,
    HorizontalScroll,
    VerticalScroll,
    SiteManager,
    StateManager,
    MeteredAdPosition
) {

    /**
     * View class.
     */
        var TopicPhotoGallery = BaseView.extend({

        events: {
            'click .tpapgm-gallery-nav-next': '_onClickNext',
            'click .tpapgm-gallery-nav-previous': '_onClickPrev',
            'click .tpapgm-collage-link': '_onClickMosaic',
            'click .tpapgm-gallery-slide-link': '_onClickSlide',
            'click .tpapgm-gallery-nav-close': '_onClickClose',
            'click .tpapgm-gallery-nav-fullscreen': '_onClickFullScreen',
            'click .tpapgm-gallery-thumbs-item-link': '_onClickThumb'
        },
        selectors: {
            slides: '.tpapgm-gallery-slide:not(.tpapgm-gallery-slide-partner-ad)',
            slideImage: '.tpapgm-gallery-slide-image',
            thumb: '.tpapgm-gallery-thumbs-item'
        },

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {
            _.bindAll(this, '_handleKeyPress', '_onClickThumb', 'refreshScrollbar');

            options = $.extend({
                enableGalleryAds: true,
                debounceSpeed: 0,
                captionsEnabled: true,
                thumbsLocation: 'top',
                thumbWidth: 80,
                thumbHeight: 45
            }, options);

            this.$doc = Utils.get('document');
            this.$body = Utils.get('body');
            this.$win = Utils.get('win');

            this.$moduleWrap = this.$('.tpapgm-module-wrap');
            this.$gallery = this.$('.tpapgm-gallery-wrap');
            this.$collageWrap = this.$('.tpapgm-collage-wrap');
            this.$slidesWrap = this.$('.tpapgm-gallery-slides-wrap');
            this.$slidesItemsWrap = this.$('.tpapgm-gallery-slides-items-wrap');
            this.$arrowNext = this.$('.tpapgm-gallery-nav-next');
            this.$arrowPrev = this.$('.tpapgm-gallery-nav-previous');
            this.$closeBtn = this.$('.tpapgm-gallery-nav-close');
            this.$caption = this.$('.tpapgm-gallery-caption');
            this.totalPhotos = this.$gallery.data('total-photos');
            this.activeClass = "tpapgm-gallery-slide-active";
            this.loadingClass = "tpapgm-gallery-slide-loading";
            this.fullscreenClass = "tpapgm-gallery-wrap-fullscreen";
            this.fetchingImages = false;
            this.$thumbsWrap = this.$('.tpapgm-gallery-thumbs-wrap');
            this.$thumbsList = this.$('.tpapgm-gallery-thumbs-list');
            this.thumbTemplate = this.$('.tpapgm-thumb-template').html();
            this.thumbActiveClass = "tpapgm-gallery-thumbs-item-active";
            this.slidesCount = this.$(this.selectors.slides).length;
            this.activeIndex = 1;
            this.adActiveClass = "slide-partner-active";

            this.arrowDebounce = _.debounce(function(index) {
                this.goToSlide(index);
            }, options.debounceSpeed, true);

            // Call base class initialize
            BaseView.prototype.initialize.call(this, options);

            this._bindKeys();

            if(options.enableGalleryAds) {
                this._initializeAds();
            }

        },

        /**
         * Initializes metered ad position
         * @private
         */
        _initializeAds: function() {
            var pageInfo = StateManager.getActivePageInfo();
            this.$adSlide = this.$('.tpapgm-gallery-slide-partner-ad');
            this.$adContents = this.$('.tpapgm-gallery-slide-partner-ad .partner-placement');
            this.showingAd = false;
            this.subviews.ad = new MeteredAdPosition({
               el: this.$adContents,
               adPlacement: 'transition_gallery',
               adSizes: ['elastic'],
               meterThreshold: 'transition_gallery',
               rateMeterId: 'topic_' + pageInfo.topic
            });
        },

        /**
         * Asks the gallery whether the next slide should be an ad
         * @return {Boolean}
         */
        shouldShowAd: function(){
            return !this.showingAd && this.subviews.ad && this.subviews.ad.shouldShowAd();
        },

        setAdDimensions: function() {
            this.subviews.ad.resizeAd(this.$adSlide.width(), this.$adSlide.height());
        },

        _updateSlidesCount: function() {
            this.slidesCount = this.$(this.selectors.slides).length;
            return this.slidesCount;
        },

        _getActiveSlide: function() {
            return this.$(this.selectors.slides).eq(this.activeIndex);
        },

        /**
         * Sets the active slide and returns the jQuery object of the newly selected slide
         * @return {Object}
         * @private
         */
        _setActiveSlide: function(index) {
            this.activeIndex = index;
            var $activeSlide = this._getActiveSlide();
            $activeSlide.addClass(this.activeClass);
            return $activeSlide;
        },

        openGallery: function(index) {
            this._setActiveSlide(index);
            this._updateSlide();
            this.lazyLoadPhotos();
            this.$collageWrap.hide();
            this.$gallery.show();
            this._bindKeys();
            this.loadThumbs();
            this._trackSlideChange();

            //set ad dimensions
            if(this.subviews.ad) {
                this.subviews.ad.setInitialDimensions(this.$adSlide.width(), this.$adSlide.height());
            }
        },

        openFullScreen: function() {
            PubSub.trigger('topicgallery:open');
            this.$gallery.addClass(this.fullscreenClass);
            this.$body.css('overflow', 'hidden');
            this.lazyLoadPhotos();
            SiteManager.getHeader().setClosedFixed(true);
            this.refreshScrollbar();
            this.fetchImages();
            this.$win.on('resize.' + this.cid, _.throttle(this.refreshScrollbar, 2000, {leading: false, trailing: false}));

            //set ad dimensions
            if(this.subviews.ad) {
                this.setAdDimensions();
            }
        },

        refreshScrollbar: function() {
            this.subviews.scrollbar.refresh();
        },

        lazyLoadPhotos: function() {
            // lazy loads the current photo as well as the previous two and next two photos
            var $activeSlide = this._getActiveSlide(),
                $images = $activeSlide.find('img'),
                $prevImages = $activeSlide.prevAll("*:lt(2)").find('img'),
                $nextImages = $activeSlide.nextAll("*:lt(2)").find('img'),
                dataSrc = (this._isFullScreen()) ? 'data-src-large' : 'data-src',
                loadingClass = this.loadingClass;

            this.$(this.selectors.slides + '.' + loadingClass).removeClass(loadingClass);
            $images = $images.add($prevImages).add($nextImages);
            var onSuccess = function(el) {
                $(el).closest('.tpapgm-gallery-slide').removeClass(loadingClass);
            };
            if(Utils.lazyLoadImage($images, dataSrc, true, null, onSuccess)){
                $images.each(function(idx, el){
                    $(el).closest('.tpapgm-gallery-slide').addClass(loadingClass);
                });
            }
        },

        loadThumbs: function() {
            var $slides = this.$(this.selectors.slides),
                thumbTemplate = _.template(this.thumbTemplate),
                thumbsPos = this.$thumbsWrap.data('pos'),
                thumbsCount = this.$thumbsList.find(this.selectors.thumb).length;

            for (var i = thumbsCount; i < this.slidesCount; i++) {
                var $img = $slides.eq(i).find(this.selectors.slideImage),
                    html = thumbTemplate({
                        active: (i == this.activeIndex) ? this.thumbActiveClass : '',
                        thumbSrc: $img.data('thumb-src'),
                        alt: $img.data('caption'),
                        current: i + 1,
                        total: this.totalPhotos
                    });
                this.$thumbsList.append(html);
            }

            // Initialize or refresh the scroller
            if(this.subviews.scrollbar) {
                this.refreshScrollbar();
            } else {
                if(thumbsPos == 'top' || thumbsPos == 'bottom') {
                    this.subviews.scrollbar = new HorizontalScroll({
                        el: this.$thumbsWrap,
                        color: 'light',
                        draggerPaddingLeft: 4,
                        draggerPaddingRight: 4
                    });
                } else {
                    this.subviews.scrollbar = new VerticalScroll({
                        el: this.$thumbsWrap,
                        color: 'light',
                        draggerPaddingTop: 10,
                        draggerPaddingBottom: 0
                    });
                }
            }
            this.subviews.scrollbar.scrollToElement(this.$('.' + this.thumbActiveClass), true, 0, 'linear');
        },

        closeGallery: function() {
            PubSub.trigger('topicgallery:close');
            if(this._isFullScreen()) {
                this.$body.css('overflow', 'auto');
                this.$gallery.removeClass(this.fullscreenClass);
                this.lazyLoadPhotos();
                SiteManager.getHeader().restoreLastState();
                this.refreshScrollbar();
                this.$win.off('.' + this.cid);

                //set ad dimensions
                if(this.subviews.ad) {
                    this.setAdDimensions();
                }
            }
        },

        nextSlide: function() {
            if((this.activeIndex + 1) < this.slidesCount) {
                this.arrowDebounce(this.activeIndex + 1);
            }
        },

        previousSlide: function() {
            if(this.activeIndex !== 0) {
                this.arrowDebounce(this.activeIndex - 1);
            }
        },

        goToSlide: function(index) {
            var $slides = this.$(this.selectors.slides),
                $outSlide = this._getActiveSlide(),
                $inSlide = $slides.eq(index),
                showAd = this.shouldShowAd();

            // if we're showing an ad, stop and hide it
            if(this.showingAd) {
                this.subviews.ad.stopAd();
                this.subviews.ad.refreshPosition();
                this.showingAd = false;
                this.$adSlide.hide();
                this.$gallery.removeClass(this.adActiveClass);
            } else {
                // handle outgoing slide
                $outSlide.removeClass(this.activeClass);
                $outSlide.hide();
            }

            // show an ad if we need to
            if(showAd) {
                this.showingAd = true;
                this.subviews.ad.show();
                this.subviews.ad.playAd();
                this.$gallery.addClass(this.adActiveClass);
                this.$adSlide.show();
            } else {
                // handle incoming slide
                $inSlide.show();
                this._setActiveSlide(index);
                this._updateSlide();
                this._selectThumb(this.activeIndex);
                this.lazyLoadPhotos();
                // create page view tracking when showing a slide that isn't an ad
                this._trackSlideChange();
            }

            // fetch more images if nearing the end of the rotation
            if(index >= ($slides.length - 10) && !this.fetchingImages) {
                this.fetchImages();
            }
        },

        _selectThumb: function(index) {
            var $thumb = this.$thumbsList.find(this.selectors.thumb).eq(index);
            $thumb.addClass(this.thumbActiveClass).siblings().removeClass(this.thumbActiveClass);
            this.subviews.scrollbar.scrollToElement($thumb, true, 400, 'ease-out');
        },

        _updateArrows: function() {
            if(this.activeIndex === 0) {
                this.$arrowPrev.hide();
            } else {
                this.$arrowPrev.show();
            }
            if(this.activeIndex < (this.slidesCount - 1)) {
                this.$arrowNext.show();
            } else {
                this.$arrowNext.hide();
            }
        },

        _updateCaption: function() {
            var $activeImage = this._getActiveSlide().find(this.selectors.slideImage),
                html = $activeImage.data('caption') + ' <span class="tpapgm-gallery-captions-credit">' + $activeImage.data('credit') + '</span>';
            this.$caption.html(html);
        },

        _updateSlide: function() {
            this._updateArrows();
            if(this.options.captionsEnabled) {
                this._updateCaption();
            }
        },

        _trackSlideChange: function() {
           // gallery info object for page-view tracking
            var galleryInfObj = {
                'gallery_id': StateManager.getActivePageInfo().topic,
                'gallery_title': this.$gallery.attr('data-title'),
                'gallery_index': this.activeIndex,
                'slide_id': this._getActiveSlide().find('img').attr('data-id'),
                'ssts': this.$gallery.attr('data-ssts'),
                'cst': this.$gallery.attr('data-cst'),
                'contenttype': StateManager.getActivePageInfo().contenttype + '-gallery',
                'pathName': this.$gallery.attr('data-gallery-path')
            };
            PubSub.trigger('slide:change', galleryInfObj);
            //  COMSCORE custom tracking request
            StateManager.fetchData("/comscorepvcandidate.xml", {cache: false}, false, true, false);
        },

        fetchImages: function() {
            this.fetchingImages = true;
            var $lastSlide = this.$(this.selectors.slides).last();
            if($lastSlide.attr('data-more-photos-path')) {
                var url = '/topic/ajax/photos/?path=' + encodeURIComponent($lastSlide.data('more-photos-path'));
                StateManager.fetchHtml(url).done(_.bind(function(html){
                    html.appendTo(this.$slidesWrap);
                    this._updateSlidesCount();
                    this._updateSlide();
                    this.loadThumbs();
                    $lastSlide.removeAttr('data-more-photos-path');
                    this.fetchingImages = false;
                }, this));
            }
        },

        _isFullScreen: function() {
            return this.$gallery.hasClass(this.fullscreenClass);
        },

        _onClickNext: function(e) {
            this.nextSlide();
        },

        _onClickPrev: function(e) {
            this.previousSlide();
        },

        _onClickSlide: function(e) {
            if($(e.currentTarget).closest('.tpapgm-gallery-slide-partner-ad').length) {
                return false;
            }
            this.nextSlide();
        },

        _onClickMosaic: function(e) {
            this.openGallery($(e.currentTarget).index());
        },

        _onClickClose: function(e) {
            this.closeGallery();
        },

        _onClickFullScreen: function(e) {
            this.openFullScreen();
        },

        _onClickThumb: function(e) {
            this.arrowDebounce($(e.currentTarget).closest(this.selectors.thumb).index());
        },

        _bindKeys: function() {
            if(this.$gallery.is(':visible')) {
                this.$doc.on('keydown.' + this.cid, this._handleKeyPress);
            }
        },

        _handleKeyPress: function(e) {
            // left arrow
            if (e.keyCode === 37) {
                if(this.$arrowPrev.is(':visible')) {
                    this.$arrowPrev.trigger('click');
                    return false;
                }
            }
            // right arrow
            if (e.keyCode === 39) {
                if(this.$arrowNext.is(':visible')) {
                    this.$arrowNext.trigger('click');
                    return false;
                }
            }
            // escape
            if (e.keyCode === 27) {
                if(this._isFullScreen()) {
                    this.$closeBtn.trigger('click');
                    return false;
                }
            }
            return true;
        },

        destroy: function(removeEl) {
            this.$doc.off('.' + this.cid);
            this.$win.off('.' + this.cid);
            BaseView.prototype.destroy.apply(this, arguments);
        }
    });
    /**
     * Return view class.
     */
    return TopicPhotoGallery;
});

