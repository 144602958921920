/**
 * @fileoverview Generic element handling videos for live-video news events.
 */
define('modules/live-video-sidebar/live-video-sidebar',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'modules/global/livevideo'
],
function(
    $,
     _,
     BaseView,
     StateManager,
     LiveVideo
) {
    "use strict";

    var LiveVideos = BaseView.extend({

        events: {
            'click .lvsm-link': 'onClickLiveVideoLink'
        },

        initialize: function(options) {
            this.liveVideosRailPoll = StateManager.recurringFetchHtml('/videos/services/live-video-sidebar/', null, 91000, _.bind(function(liveVideoContent) {
                this.$el.html(liveVideoContent);
            }, this));
            BaseView.prototype.initialize.call(this, options);
        },

        destroy: function() {
            clearInterval(this.liveVideosRailPoll);
            BaseView.prototype.destroy.apply(this, arguments);
        },

        /**
         * Live-video click handler.
         * @param {Event} e Hover event.
         */
        onClickLiveVideoLink: function(e) {
            var id = $(e.currentTarget).data('video-id');
            if (id) {
                if (this.subviews.live) {
                    this.subviews.live.destroy(true);
                }
                this.subviews.live = new LiveVideo();
                this.subviews.live.loadLiveVideo(id);
                e.preventDefault();
            }
        }
    });

    return LiveVideos;
});

