define('adPosition',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'state',
    'admanager',
    'managers/cachemanager',
    'pubsub',
    'adLogger'
],
function(
    $,
    _,
    BaseView,
    Utils,
    StateManager,
    AdManager,
    CacheManager,
    PubSub,
    AdLogger
) {
    'use strict';
    /**
     * HighImpactAdData delivered from dfp that is used to render custom ads
     * @typedef HighImpactAdData
     * @property {String} adType - the type of ad that was delivered, ex: pushdown
     * @property {Object} dfp - dfp ids
     * @property {String} script - url to load a require script containing custom ad behavior
     * @property {String} stylesheet - url to a stylesheet to load for the ad
     * @property {String} viewUrl - dfp tracking url generated for out of page ads
     */
    var AdPosition = BaseView.extend(
    /**
     * @lends partner/ad-position.prototype
     */
    {

        $head: $('head'),

        /**
         * This callback is called immediately after an ad has been delivered, but not rendered, giving the ad position
         * time to set up the dom with the correct classes necessary to support the ad
         * @callback partner/ad-position~beforeAdRender
         * @param {HighImpactAdData} [adData] dfp high impact ad data
         * @param {String} [adType] normalized ad type that was delivered (without any special characters, pushdown+ => pushdown)
         */
        /**
         * This callback is called when an ad has been delivered and successfully created/rendered
         * @callback partner/ad-position~onAdReady
         * @param {HighImpactAdData} [adData] dfp high impact ad data
         * @param {String} [adType] normalized ad type that was delivered (without any special characters, pushdown+ => pushdown)
         */
        /**
         * This callback is called when no ad has been delivered
         * @callback partner/ad-position~onNoAd
         */
        /**
         * @classdesc Generic Ad Position on the page, used as a base class for more specialized ad positions
         * @author Jay Merrifield <jmerrifiel@gannett.com>
         * @constructs partner/ad-position
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector to attach to
         *     @param {Array.<String>} [options.adSizes] - ad size targeting for this position, only used for in slot types
         *     @param {String} options.adPlacement - name for this placement in the scheduling system (ie: high_impact)
         *     @param {String} options.subSection - sub section targeting for dfp (sports/nfl/etc)
         *     @param {Number} options.adWidth - width of the container the ad must fit in
         *     @param {Number} options.adHeight - height of the container the ad must fit in
         *     @param {String} [options.slotType=in] - in vs out of page types,
         *                                  in pages ads use adSizes for targeting and will render immediately when delivered,
         *                                  out of page are meant for overlays or galleries where the ad is preloaded or metered
         *      @param {partner/ad-position~beforeAdRender} [options.beforeAdRender] - callback when an ad is delivered, but before it's created/rendered
         *      @param {partner/ad-position~onNoAd} [options.onNoAd] - callback when an no ad is delivered
         *      @param {partner/ad-position~onAdReady} [options.onAdReady] - callback when an ad is delivered/created/rendered. Skipping
         *                                                              this option will automatically show and play the ad immediately.
         *                                                              If this option is provided, it's up to you to show and play the ad
         */
        initialize: function(options) {
            options = $.extend({
                // required options
                adSizes: [],
                adPlacement: null, // name for this placement in the scheduling system (ie: high_impact)
                slotType: 'in',
                adWidth: null,
                adHeight: null,
                subSection: StateManager.getActivePageInfo().cst,

                // optional options
                onSetup: null,
                onAdReady: null,
                onNoAd: null,
                onMessage: null,
                beforeAdRender: null,
                lazy: false,
                deferred: null
            }, options);

            this._debounceAdReady = _.debounce(this._adReady, 30);

            var el = this.$el;
            if(this.$el.length === 0) {
                AdLogger.logWarn('AdPosition:: No valid el found for ' + options.adPlacement);
                return;
            }
            if (!this.$el.prop('id')) {
                this.$el.prop('id', _.uniqueId('ad-position-'));
            }

            // call base class initialize
            BaseView.prototype.initialize.call(this, options);

            // we set this up after initialization so we have a place to put them for destroy if we get a partnerView
            this.tracked = false;
            this.adState = 'pending';

            // clear out any possible width/height left over from a destroyed ad, let it vanish
            this.$el.css({width: '', height: '', display: ''});

            /* Comscore Monetization is a project to decide how we should place our ads
             * we have Comscore monitor "ad zones" for visibility
             * an "ad zone" is a rectangle where we might display an ad in the future
             * an ad zone is any element in the DOM with class partner-placement and attribute data-monetization-id and attribute data-monetization-sizes
             * potential future: maybe use a new system for monetization size names
             */
            var partnerPlacement = this.$el.closest('.partner-placement'),
                adSizes = this.options.adSizes;
            adSizes = (_.isArray(adSizes)) ? adSizes.join(',') : adSizes;

            partnerPlacement.attr('data-monetization-id', this.options.adPlacement.replace(/\/.*/, ''));
            partnerPlacement.attr('data-monetization-sizes', adSizes);

            if (options.slotType == 'in'){
                this.refreshPosition();
            }
        },

        /**
         * Changes the current targeting, will only apply on the next ad requested
         * @param {Object.<String, String>} targeting
         */
        setTargeting: function(targeting) {
            this.options.targeting = targeting;
            if (typeof Utils.getNested(this, 'subviews', 'adSlot', 'setTargeting') === 'function') {
                this.subviews.adSlot.setTargeting(this.options.targeting);
            }
        },

        /**
         * Destroys the current ad placement, and refreshes the ad slot
         */
        refreshPosition: function() {
            this.adState = 'pending';
            if (this.subviews.adSlot && this.adSlotPlacement !== this.getAdPlacement()) {
                // we can't update the ad slot placement without destroying and rebuilding the ad slot
                this.destroyAdPlacement();
                this.destroyAdSlot();
            }
            if (!this.prefetchAd() && typeof Utils.getNested(this, 'subviews', 'adSlot', 'refresh') === 'function') {
                this.destroyAdPlacement();
                this.subviews.adSlot.refresh();
            }
        },

        /**
         * Triggers creation of the ad slot if one doesn't already exist
         * @return {Boolean} whether or not a slot was created
         */
        prefetchAd: function() {
            var options = this.options;
            if (!this.subviews.adSlot) {
                this.adSlotPlacement = this.getAdPlacement();
                this.subviews.adSlot = {}; 
                AdManager.getAdSlot(options.adPlacement, this.adSlotPlacement, options.targeting, options.exclusion, options.adSizes, this, options.slotType);
                return true;
            }
            return false;
        },

        /**
         * Callback from the ad framework telling us an ad has been delivered
         * @param {HighImpactAdData} [adData] dfp high impact ad data
         * @param {String} [adType] normalized ad type that was delivered (without any special characters, pushdown+ => pushdown)
         */
        onRender: function(adData, adType, adSize) {
            if (this.options.beforeAdRender) {
                this.options.beforeAdRender(adData, adType);
            }
            if (!adType && !adData) {
                // IAB ads are rendered by google itself, nothing to do
                this._adReady(adData, adType, adSize);
            } else {
                this._renderHighImpact(adData, adType);
            }
        },

        /**
         * Renders the passed in high impact ad in this ad position
         * @param {HighImpactAdData} adData dfp high impact ad data
         * @param {String} adType normalized ad type that was delivered (without any special characters, pushdown+ => pushdown)
         * @private
         */
        _renderHighImpact: function(adData, adType){
            this.adType = adType;
            this.adData = adData;
            AdLogger.logInfo('AdPosition HighImpact Ad recieved with type: ' + adType, adData);
            this._renderCustomAd(adData);
        },

        /**
         * Function called by the ad slot when no ad is delivered
         */
        onEmpty: function(){
            this.adState = 'noad';
            if (typeof this.options.onNoAd === 'function') {
                this.options.onNoAd();
            }
        },

        /**
         * Function called by the ad slot when a message is returned
         */
        onMessage: function(data){
            if (typeof this.options.onMessage === 'function') {
                this.options.onMessage(data);
            }
        },

        /**
         * returns 'pending', 'hasad', or 'noad' depending on whether an ad is in flight, was delivered an ad
         * or was not delivered an ad
         * @returns {String}
         */
        getAdState: function(){
            return this.adState;
        },

        /**
         * build and trigger a high impact ad
         * @param {HighImpactAdData} adData dfp high impact ad data
         * @private
         */
        _renderCustomAd: function(adData){
            if (!adData.dfp || !adData.dfp.ecid) {
                AdLogger.logError('invalid ad returned, no dfp/ecid found', adData);
                return;
            }
            if (adData.html) {
                this.$adEl = $($.parseHTML(adData.html, document, true)); // keep script tags for ads?
                this.$el.append(this.$adEl);
            }
            if (adData.stylesheet) {
                this.$stylesheet = $('<link rel="stylesheet" data-ad-stylesheet="' + adData.dfp.ecid + '" href="' + adData.stylesheet + '">');
                this.$head.append(this.$stylesheet);
            }
            if (adData.script) {
                adData.el = this.$el;
                Utils.requireSingleUseScript(adData.script).done(_.bind(function(PartnerView) {
                    var pubSubKey = this._getPubSubKey();
                    if (this.destroyed){
                        return;
                    }
                    adData.initialWidth = this.options.adWidth;
                    adData.initialHeight = this.options.adHeight;
                    // remember the pubSub so we can unregister it on destruction
                    PubSub.on(pubSubKey, this._debounceAdReady, this);
                    try {
                        this.subviews.partnerView = new PartnerView(adData);
                    } catch (ex) {
                        AdLogger.logError('AdSlot(' + this.options.adUnit + '): ad delivered threw exception on creation', (ex.stack || ex.stacktrace || ex.message));
                    }
                }, this));
            } else {
                this._adReady();
            }
        },

        _getPubSubKey: function(){
            return 'partner:' + this.adData.dfp.ecid + ':ready';
        },

        /**
         * Destroys the ad element. If an ad was rendered, it'll keep it's shape to avoid UI jumps.
         * This function will also remove data-monetization-id and and data-monetization-sizes attributes
         * @param {Boolean} [removeEl]
         */
        destroy: function(removeEl) {
            // put in place a placeholder so we don't shift the page around
            if (this.adState === 'hasad'){
                this.$el.css({width: this.getWidth(), height: this.getHeight()});
            }

            this.destroyAdPlacement();
            this.destroyAdSlot();
            // clean up monetization info
            var partnerPlacement = this.$el.closest('.partner-placement');
            partnerPlacement.removeAttr('data-monetization-id');
            partnerPlacement.removeAttr('data-monetization-sizes');
            partnerPlacement.removeClass('partner-placement-visible');
            this.destroyed = true;
            BaseView.prototype.destroy.call(this, removeEl);
        },

        /**
         * Destroys the ad slot if one exists
         */
        destroyAdSlot: function(){
            if (typeof Utils.getNested(this, 'subviews', 'adSlot', 'destroy') === 'function') {
                this.subviews.adSlot.destroy();
                this.subviews.adSlot = null;
            }
        },

        /**
         * Destroys the current ad, and removes the html and resources it was using
         */
        destroyAdPlacement: function(){
            this.tracked = false;
            if (this.subviews && this.subviews.partnerView) {
                this.stopAd();
                this._dispatchToPartner('destroy', false);
                this.subviews.partnerView = null;
            }
            if (this.adData && this.adData.script) {
                PubSub.off(this._getPubSubKey(), this._debounceAdReady, this);
            }
            this.adType = this.adData = null;
            if (this.$adEl) {
                this.$adEl.remove();
                this.$adEl = null;
            } else if (this.$el.hasClass('partner-billboard-ad')) {
                //clean up messy iab expanding/billboard ads
                var selectors = Utils.getNested(window.site_vars, 'ADS', 'cleanup_selectors');
                $(selectors).remove();
            }

            if (this.$stylesheet) {
                this.$stylesheet.remove();
                this.$stylesheet = null;
            }
        },

        /**
         * Gets the AdPlacement, which is a union of [this.options.adPlacement, '/', this.options.subSection]
         * @returns {String}
         */
        getAdPlacement: function() {
            var adPlacement = this.options.adPlacement;
            if (this.options.subSection) {
                adPlacement += '/' + this.options.subSection;
            }
            return adPlacement;
        },

        /**
         * Signifies that the ad is ready, default behavior is to show the ad and trigger play,
         * is overridable by specifying onAdReady in the options
         * @private
         */
        _adReady: function(adData, adType, adSize) {
            AdLogger.logInfo('AdPosition(' + this.getAdPlacement() + ') ' + this.adType + ' ready');

            if (this.adData && this.adData.script) {
                // once a high impact ad is delivered, turn off pubsub key to avoid accidental cross chatter
                PubSub.off(this._getPubSubKey(), this._debounceAdReady, this);
            }

            this.adState = 'hasad';
            if (!this.options.onAdReady) {
                if (this.options.slotType === 'in') {
                    if(!this.adType) {
                        //display iab/third party ads immediately
                        this.show();
                        if(this.$el && this.$el.height() > 1) {
                            this.$el.addClass('partner-placement-visible');
                        }
                    } else {
                        //high impact framework takes care of showing the correct ad div
                        this.playAd(); // playAd calls trackAd()
                    }
                }
            } else {
                // by registering onAdReady, you are taking control of the viewing of this ad.
                this.options.onAdReady(this.adData, this.adType, adSize);
                this.$el.addClass('partner-placement-visible');
            }
        },

        /**
         * Function that specifies if the ad is delivered and ready to be shown
         * @returns {Boolean}
         */
        isAdReady: function(){
            var adReady = this.adState === 'hasad';
            if (adReady && Utils.getNested(this.adData, 'adType') === 'blank') {
                this.trackAd();
                return false;
            }
            return adReady;
        },

        /**
         * Get current height of the el
         * @returns {Number}
         */
        getHeight: function() {
            return this.$el.outerHeight();
        },

        /**
         * Get current width of the el
         * @returns {Number}
         */
        getWidth: function() {
            return this.$el.outerWidth();
        },

        /**
         * Set the initial height and width
         * @param {Number} width - initial width of the ad container
         * @param {Number} height - initial height of the ad container
         */
        setInitialDimensions: function(width, height) {
            this.options.adWidth = width;
            this.options.adHeight = height;
        },

        /**
         * Shows the ad and fires off any tracking pixels
         * @deprecated
         */
        showAd: function(width, height) {
            this.$el.show();
            this._dispatchToPartner('onShow', width, height);
            this.trackAd();
        },

        /**
         * Tells the high impact ad to resize itself
         * @param {Number} width - new width of the container the ad needs to fit it
         * @param {Number} height - new height of the container the ad needs to fit it
         */
        resizeAd: function(width, height) {
            this.options.adWidth = width;
            this.options.adHeight = height;
            this._dispatchToPartner('onResize', width, height);
        },

        /**
         * Tells the high impact ad to stop animating
         */
        stopAd: function() {
            this._dispatchToPartner('onStopToClose');
        },

        /**
         * Tells the high impact ad to stop animating
         */
        pauseAd: function() {
            this._dispatchToPartner('onPause');
        },

        /**
         * Tells the high impact ad to resume animating
         */
        resumeAd: function() {
            this._dispatchToPartner('onResume');
        },

        /**
         * Helper function to send messages to the ad object
         * @param {String} funcName name of the function to call on the partner
         * @private
         */
        _dispatchToPartner: function(funcName) {
            var funcArgs, partnerView = this.subviews.partnerView;
            if (!partnerView) {
                return;
            }
            if (partnerView[funcName]) {
                funcArgs = Array.prototype.slice.call(arguments, 1);
                AdLogger.logInfo.apply(AdLogger, ['AdPosition(' + this.getAdPlacement() + ') ' + funcName].concat());
                try {
                    partnerView[funcName].apply(partnerView, funcArgs);
                } catch (ex) {
                    AdLogger.logError('Ad threw an exception in function ' + funcName, (ex.stack || ex.stacktrace || ex.message));
                }
            } else {
                AdLogger.logWarn('Ad does not implement ' + funcName);
            }
        },

        /**
         * Triggers tracking pixels for this ad
         * @param {jQuery} [trackDom] optional alternative id if you want to track a dom element other than the ad itself
         */
        trackAd: function(trackDom) {
            var trackDomId;
            if (this.tracked || !this.adData) {
                return;
            }
            this.tracked = true;
            if (this.adData.viewUrl) {
                // adData.viewUrl is in the format:
                // http://pubads.g.doubleclick.net/pagead/adview?ai=Bqas7at8GUda8K8-_6gH97oDwD62Qwu4CAAAAEAEg_bLvGTgAWLWo2YI9YMmGgIDspIAQugEJZ2ZwX2ltYWdlyAEJwAIC4AIA6gIoNzA3MC91c2F0b2RheS90cmFuc2l0aW9uX2dhbGxlcnkvd2VhdGhlcvgCgtIekAPQBZgDpAOoAwHQBJBO4AQBoAYf&sigh=_3Sxed4-ABo&template_id=10016309&adurl=
                // where the last parameter is a redirect url for an image
                var trackingPixel = $('<img src="' + this.adData.viewUrl + encodeURIComponent(window.site_static_url + 'images/pixels/pixel-transparent.png') + '" style="display:none;">');
                if (this.$adEl){
                    this.$adEl.append(trackingPixel);
                } else {
                    this.$adEl = trackingPixel;
                    this.$el.append(trackingPixel);
                }
                AdLogger.logInfo('Fired Tracking Pixel', this.adData.viewUrl);
            }
        },

        /**
         * Tells the ad to play, will also show the ad and fire tracking pixels if not already done
         */
        playAd: function() {
            this.trackAd();
            this._dispatchToPartner('onPlay');
        }
    });
    return AdPosition;
});

