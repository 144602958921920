/**
 * @fileoverview featured-newsletter-signup
 * @author acrain@gannett.com (Andy Crain)
 */

define('apps/featured-newsletter',[
        'jquery',
        'underscore',
        'state',
        'base-app',
        'pubsub',
        'utils'
    ],
    function(
        $,
        _,
        StateManager,
        BaseApp,
        PubSub,
        Utils
    ) {

        /**
         * View class.
         */
        var FeaturedNewsletterSignup = BaseApp.extend({

            // Events.
            events: {
                'submit .js-fnls-form': 'onFormSubmit'
            },

            validEmail: function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            afterPageReveal: function() {
                Utils.get('body').addClass("fnls-body");
                this.originalViewportContent = $('meta[name=viewport]').attr('content');
                $('meta[name=viewport]').attr('content', 'width=device-width, initial-scale=1.0');

                // updates eVar99 with LDSN
                PubSub.trigger('group_page_view', 'LDSN');
                // updates eVar66
                PubSub.trigger('product_name', 'Millennial Newsletter');
            },

            beforeAppRemove: function() {
                Utils.get('body').removeClass("fnls-body");
                $('meta[name=viewport]').attr('content', this.originalViewportContent);
            },

            onFormSubmit : function (e) {
                e.preventDefault();

                var $newsletterForm = $(e.currentTarget),
                    newsletterId = $newsletterForm.data("newsletter-id"),

                    emailValue = $newsletterForm.find('.js-fnls-email').val(),
                    emailConfirmValue = $newsletterForm.find('.js-fnls-email-confirm').val(),

                    $messageSuccess = $newsletterForm.find('.js-fnls-status-success'),
                    $messageError = $newsletterForm.find('.js-fnls-status-error'),
                    $messageUpdating = $newsletterForm.find('.js-fnls-status-updating'),

                    $fieldset = $newsletterForm.find('.js-fnls-fieldset')
                    ;

                // validate
                if(emailValue != emailConfirmValue || !emailValue || !this.validEmail(emailValue) ) {

                    if (emailValue != emailConfirmValue) {
                        $messageError.html("Email addresses do not match.");
                    }
                    if (!emailValue || !this.validEmail(emailValue)) {
                        $messageError.html("Please enter a valid email address.");
                    }
                    $messageError.show().siblings().hide();

                } else {
                    $messageUpdating.show().siblings().hide();

                    var newsletter_choices_json = JSON.stringify(
                        [
                            {
                                id: newsletterId,
                                signup: true
                            }
                        ]
                    );

                    StateManager.fetchData(null,
                        {
                            type: "POST",
                            url: $newsletterForm.attr('action') + emailValue + '/' + $newsletterForm.data("business-unit") + '/',
                            data: {
                                newsletter_choices: newsletter_choices_json
                            }
                        }
                    ).done(_.bind(function(response) {
                            if (response.success === true) {
                                if($.trim($messageSuccess.html()) === '') {
                                    $messageSuccess.html(response.message);
                                }
                                $fieldset.hide();
                                $messageSuccess.show().siblings().hide();

                                var reference = Utils.getUrlParam("refer") || "organic";

                                //Handle Omniture tracking
                                var prop41 = "featured-newsletter-signup|" + newsletterId + "|"+ reference;
                                PubSub.trigger('uotrack', prop41);
                                PubSub.trigger('utagtrack', prop41);
                                var fbid = Utils.getNested(window.site_vars.featured_newsletter, $newsletterForm.data("newsletter-slug"), "fbid");
                                if (fbid !== 0){
                                    PubSub.trigger('newslettertrack', fbid);
                                }
                            } else {
                                $messageError.html(response.message);
                                $messageError.show().siblings().hide();
                            }

                    }, this));
                }
            }
        });

        /**
         * Return view class.
         */
        return FeaturedNewsletterSignup;
    }
);

