/**
 * @fileoverview Rentals Lite Search view module.
 * @author tgreese@gannett.com (https://github.com/tommy-boy)
 */
define('modules/rentalslite-search',[
        'jquery',
        'baseview',
        'state',
        'managers/routemanager',
        'form/base-form',
        'pubsub'
    ],
    function(
        $,
        BaseView,
        StateManager,
        RouteManager,
        FormView,
        PubSub
    ) {
        /**
         * View class.
         */
        var RentalsLiteSearch = BaseView.extend({
            events: {
                'click .js-rls-search-btn': 'aptAffWidget',
                'submit .js-rls-form': 'aptAffWidget'
            },
            initialize : function(options){
                BaseView.prototype.initialize.call(this, options);

                this.form = this.$('.js-rls-form');

                this.subviews.carSearchForm = new FormView({
                    el: this.form,
                    formOptions: [{
                        el: this.$('.rls-form-state')
                    },
                    {
                        el: this.$('.rls-form-minrent')
                    },
                    {
                        el: this.$('.rls-form-maxrent')
                    }]
                });

                // updates eVar99 with LDSN
                PubSub.trigger('group_page_view', 'LDSN');
                // updates eVar66
                PubSub.trigger('product_name', 'Rentals Front');
            },
            urlForBedsBaths: function(url,beds,baths) {
                if (!isNaN(beds)) {
                    if (beds === 0){
                        url += 'studio' + '-beds';
                    }else{
                        url += beds + '-beds';
                    }
                }
                if (!isNaN(beds) && !isNaN(baths)) {
                    url += '-' + baths + '-baths';
                } else if (!isNaN(baths)) {
                    url += baths + '-baths';
                }
                return url;
            },
            urlForRent: function(url,rentMin,rentMax) {
                if (!isNaN(rentMin) && !isNaN(rentMax)) {
                    url += '-' + rentMin + '-to-' + rentMax;
                } else if (!isNaN(rentMin)) {
                    url += '-over-' + rentMin;
                } else if (!isNaN(rentMax)) {
                    url += '-under-' + rentMax;
                }
                return url;
            },
            urlForPets: function(url,pets) {
                if (pets !== "pets") {
                    if (pets !== 'all') {
                        url += '-pet-friendly-' + pets;
                    } else {
                        url += '-pet-friendly';
                    }
                }
                return url;
            },
            aptAffWidget: function (event) {
                var cityZip = this.$('.js-rls-form-city').val(),
                    state = this.$('.js-rls-form-state').val(),
                    url = 'https://www.apartments.com/';
                    if (!cityZip) {
                        $('.js-rls-form-city').focus();
                    } else {
                        if (state === "0") {
                            $('.js-rls-form-state').focus();
                        } else {
                            url += cityZip + '-' + state + '/';
                            url = this.urlForBedsBaths(url,
                                parseInt(this.$('.js-rls-form-bedrooms').val()),
                                parseInt(this.$('.js-rls-form-bathrooms').val()));
                            url = this.urlForRent(url,
                                parseInt(this.$('.js-rls-form-minrent').val()),
                                parseInt(this.$('.js-rls-form-maxrent').val()));
                            url = this.urlForPets(url,this.$('.js-rls-form-pets').val());
                            RouteManager.goTo(url);
                        }
                    }
                return false;
            }
         });
        /*
         * Return view class.
         */
        return RentalsLiteSearch;
    }
);

