/**
 * @fileoverview Newsletter Signup
 * @author mbriede@gannett.com (Matthew Briede)
 */
define('modules/newsletter-signup/newsletter-signup',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'user-manager',
    'pubsub',
    'managers/cachemanager'
],
    function(
        $,
        _,
        BaseView,
        StateManager,
        UserManager,
        PubSub,
        CacheManager
        ) {
        "use strict";
        /**
         * View class.
         */
        var NewsletterSignup = BaseView.extend({

            validEmail: function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            // View element
            el: '.newsletter-signup-container',

            events: {
                'submit': 'onFormSubmit'
            },

            /**
             * Initialize view.
             */
            initialize: function(options) {
                // caching selectors
                this.$nlTitle = this.$('.newsletter-signup-title');
                this.$nlForm = this.$('.newsletter-signup-form');
                this.$nlFormAction = this.$nlForm.attr('action');
                this.$emailField = this.$('.newsletter-signup-email');
                this.$emailFormMsg = this.$('.newsletter-signup-msg');
                this.$emailFormMsgTxt = this.$('.newsletter-signup-message-text');
                this.$customSuccessMessage = this.$emailFormMsg.data("success-message");
                this.$nlCheckboxes = this.$('.newsletter-signup-form .newsletters input');
                this.$businessUnitCode = this.$nlForm.data("business-unit");
                this.$nlSubmit = this.$('.newsletter-signup-submit-update');
                this.$nlTagline = this.$('.newsletter-signup-tagline');
                this.$newsletterNeedLgn = this.$('.newsletter-signup-need-login');
                this.$newsletterOptLgn = this.$('.newsletter-signup-option-login');
                this.$newsletterLogIn = this.$('.newsletter-signup-logged-in');
                this.$moreNewsletter = this.$('.more-newsletters');

                this.userAccount = UserManager.getAccount('firefly');
                if (this.userAccount) {
                    this.userAccount.getUserInfo().done(_.bind(function(userInfo) {
                        if(userInfo.email) {
                            this.isLoggedIn = true;
                            this.$currentUserEmail = userInfo.email;
                            this.$emailField.val(this.$currentUserEmail);
                            this.$nlTagline.html("Hello, <strong>" + userInfo.welcomeName + "</strong>. Please select any new newsletters you would like to receive.");
                            this.$newsletterLogIn.removeClass('hidden');
                            this.$moreNewsletter.removeClass('hidden');
                            this.newsletterLocal = CacheManager.getValue(this.$currentUserEmail + '_newsletter', null, 1);

                            if(this.newsletterLocal) {
                                $.each(this.newsletterLocal.subscriptions, function(i,listCode) {
                                     if ($('#newsletter-' + listCode)) {
                                            $('#newsletter-' + listCode).prop('checked', true);
                                        }
                                });
                            } else {
                                CacheManager.clearValue(this.$currentUserEmail + '_newsletter'); // clear if null or not found
                                StateManager.fetchData(null,
                                    {
                                        url: ('/services/gemsapi/getuser/' + userInfo.email + '/' + this.$businessUnitCode)
                                    }
                                ).done(function(response) {
                                    if (response.success === true && response.subscriptions) {
                                        $.each(response.subscriptions, function(i, listCode) {
                                            if ($('#newsletter-' + listCode)) {
                                                $('#newsletter-' + listCode).prop('checked', true);
                                            }
                                        });
                                    }
                                });
                            }
                        } else {
                        this.showNewsletterOptionAbTest();
                        }
                    }, this)).fail(_.bind(function() {
                        if (!this.isLoggedIn) {
                          this.showNewsletterOptionAbTest();
                        }
                    }, this));
                }
            },

            // AB testing for showing newsletter
            showNewsletterOptionAbTest: function() {
                if (!this.options.registrationEnabled) {
                    this.$newsletterOptLgn.removeClass('hidden');
                } else {
                    this.$newsletterNeedLgn.removeClass('hidden');
                }
                this.$moreNewsletter.removeClass('hidden');
            },

            setSubscriptionsLocal: function(email, subscriptions) {
                var localStorageObject = {
                                        'subscriptions': subscriptions
                                        };
                CacheManager.setValue(email + '_newsletter', localStorageObject, 10);
            },

            onFormSubmit: function(e) {
                // prevent the form's default action
                e.preventDefault();
                //validation
                if(this.$emailField.val() != this.$emailField.val() || !this.validEmail(this.$emailField.val()) ) {
                    this.$emailFormMsg.removeClass("updating").removeClass("success").addClass('fail');
                    if (!this.$emailField.val() || !this.validEmail(this.$emailField.val())) {
                        this.$emailFormMsgTxt.html("Please enter a valid email address.");
                    }
                    this.$emailFormMsg.show();
                } else {
                    this.$emailFormMsg.hide();
                    // get selected newsletters
                    var newsletter_choices = [];
                    this.$nlCheckboxes.each(function(index, newsletter) {
                        newsletter_choices.push(
                            {
                                id: $(newsletter).data("newsletter-id"),
                                signup: $(newsletter).is(':checked')
                            }
                        );
                    });

                    var newsletter_choices_json = JSON.stringify(newsletter_choices);
                    this.$emailFormMsg.removeClass("fail").removeClass("success").addClass("updating");
                    this.$emailFormMsgTxt.html("Updating subscriptions.");
                    this.$emailFormMsg.show();

                    var logged_in = 0;
                    if (this.userAccount) {
                        this.userAccount.getUserInfo().always(_.bind(function(userInfo) {
                            if (userInfo.email) {
                                logged_in = 1;
                            }
                        }, this));
                    }
                    StateManager.fetchData(null,
                        {
                            type: "POST",
                            url: this.$nlFormAction + this.$emailField.val() + '/' + this.$businessUnitCode + '/',
                            data: {
                                logged_in: logged_in,
                                newsletter_choices: newsletter_choices_json
                            }
                        }
                    ).done(_.bind(function(response) {
                            this.$emailFormMsg.removeClass('updating');
                            if (response.success === true) {
                                this.$emailFormMsg.removeClass('fail').addClass('success');

                                //Is this a sidebar module
                                var inSidebar = this.$el.hasClass('newsletter-signup-sidebar');
                                if (inSidebar) {
                                  this.$nlForm.addClass('hidden');
                                  this.$nlTitle.addClass('hidden');
                                }
                                //Handle Omniture tracking
                                var prop41 = "newsletter-signup|front|" + this.$nlSubmit.data('frontname') + "|choices|";
                                var choices_array = [];
                                this.$nlCheckboxes.each(function(index, newsletter) {
                                    if ($(newsletter).is(':checked')) {
                                        choices_array.push($(newsletter).data("newsletter-id"));
                                    }
                                });

                                this.setSubscriptionsLocal(this.$currentUserEmail, choices_array);
                                var choices = choices_array.join("|");
                                prop41 += choices;
                                PubSub.trigger('uotrack', prop41);
                            } else {
                                this.$emailFormMsg.removeClass("success").addClass('fail');
                            }

                            if (response.success && this.$customSuccessMessage) {
                                this.$customSuccessMessage = this.$customSuccessMessage.split("||").map(function(text) {
                                    return "<span class=\"newsletter-signup-custom-success\">" + text + "</span>";
                                }).join("");
                                response.message = this.$customSuccessMessage;
                            }
                            this.$emailFormMsgTxt.html(response.message);
                    }, this));
                }
            }
        });

        /**
         * Return view class.
         */
        return NewsletterSignup;
    }
);

