define('partner/pushdown-ad-article',[
    'jquery',
    'partner/pushdown-ad'
],
function(
    $,
    PushdownBase
) {
    'use strict';
    var PushdownArticleView = PushdownBase.extend(
    /**
     * @lends partner/pushdown-ad.prototype
     */
    {

        /**
         * @classdesc Pushdown ad type, this is a {@link partner/ad-position}
         * @constructs partner/pushdown-ad
         * @author Jay Merrifield <jmerrifiel@gannett.com>
         * @author Chad Shryock <cdshryock@gannett.com>
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector that contains both the pushdown and leave behind divs
         */
        initialize: function(options) {
            options = $.extend({
                adClasses: '',
                animations: {
                    vertical: {
                        duration: 350,
                        easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)'
                    }
                },
                ratio: {},
                cardInfo : {}
            }, options);

            // call base class initialize
            PushdownBase.prototype.initialize.call(this, options);
            this._createAd(['pushdown'], ['pushdown'], options.cardInfo);
        }

    });
    return PushdownArticleView;

});

