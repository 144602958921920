define('third_party_integrations/sourcepoint/sourcepoint-utils',[
    'jquery',
    'underscore',
    'utils',
    'user-manager',
    'admanager'
],
function(
    $,
    _,
    Utils,
    UserManager,
    AdManager
){
    'use strict';
    /**
     * SourcePoint Utils
     * @exports soucepoint-utils
     * @author Jordan Manwaring <jmanwaring@gannett.com>
     */

    // Sourcepoint is turned off, exit
    if(!window._sp_) return;

    var SoucePointUtils = function() {
        _.bindAll(this, 'spCallback', 'spChoiceCallback', 'buildProp41');
        this.whitelist = 'n/a';
        this.recovery = 'n/a';
        this.msg_id = 'n/a';
        this.cmpgn_id = 'n/a';

        window._sp_.config = window._sp_.config || {};
        window._sp_.config.mms_client_data_callback = this.spCallback;
        window._sp_.config.mms_choice_selected_callback = this.spChoiceCallback;

        // Pass user login status
        window._sp_.mms = window._sp_.mms || {};
        window._sp_.mms.cmd = window._sp_.mms.cmd || [];
        window._sp_.mms.cmd.push(_.bind(function () {
            window._sp_.mms.setTargeting('userLoggedIn', this.userLoggedIn());
            window._sp_.mms.setTargeting('adFreeExperience', AdManager.isAdFreeExperience());
        }, this));
    };
    SoucePointUtils.prototype = {
        spCallback: function(data) {
            if(!data.msgID) {
                console.info('no message');
            } else {

                // Kill BounceExchange
                window.bxHalt = true;
                if (typeof window.bxHide === 'function') {
                    window.bxHide();
                }

                // Omniture analytics
                var info = data.info || {},
                    optins = data.o || {},
                    cookie = Utils.getNested(window.site_vars, 'ADS', 'adblock_cookie');
                this.whitelist = optins.wl ? 'whitelist-y' : 'whitelist-n';
                this.recovery = optins.oir ? 'recovery-y' : 'recovery-n';
                this.msg_id = info.msg_id;
                this.cmpgn_id = info.cmpgn_id;
                var prop41 = this.buildProp41() + '|impression';
                window.utag.link({clickName: prop41, clickPage: location.href, linkTrackVars: 'prop41,prop64'});
                window._sp_.displayed_message = true;

                // Drop a cookie so we can track recovered revenue
                if(cookie) {
                    $.cookie(cookie, true, {expires: 365});
                }

                // Pause uw-video on page
                $('.snow-iframe').each(function (i, el) {
                    el.contentWindow.postMessage( 'uw-pause' , '*');
                });
            }
        },

        spChoiceCallback: function (choiceID) {
            // Omniture analytics
            var choices = {
                1: 'whitelist',
                2: 'opt-in-recovery',
                3: 'alt-ad'
            },
                choice = choices[choiceID] || choiceID;

            if (choice === 'whitelist') {
                this.whitelist = 'whitelist-y';
            } else if (choice === 'opt-in-recovery') {
                this.recovery = 'recovery-y';
            }

            var prop41 = this.buildProp41() + '|choice-' + choice;
            window.utag.link({clickName: prop41, clickPage: location.href, linkTrackVars: 'prop41,prop64'});
        },

        buildProp41: function () {
            return 'adblockmessage|sp-' + this.cmpgn_id + '-' + this.msg_id + '|' + this.whitelist + '|' + this.recovery;
        },

        userLoggedIn: function () {
            return UserManager.getLoginStatus() !== 'loggedOut';
        }
    };
    return new SoucePointUtils();
});

