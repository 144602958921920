/**
 * @fileoverview Sports Headlines module view.
 * @author Chad Shryock <cdshryock@gannett.com>
 * @author Kyle Baker <kybaker@usatoday.com>
 */
define('modules/sports/sports-headline-grid',[
    'jquery',
    'underscore',
    'backbone',
    'state',
    'pubsub',
    'utils',
    'baseview',
    'ui/button-toggle',
    'modules/fronts/headline-grid',
    'modules/scroller/vertical-scroll'
],
function(
    $,
    _,
    Backbone,
    StateManager,
    PubSub,
    Utils,
    BaseView,
    ButtonToggle,
    HeadlineView,
    VerticalScroll
) {
    'use strict';
    /**
     * View class.
     */
    var SportsHeadlineView = HeadlineView.extend({
        
        el: '.sp-filter-bar-wrap',
        
        events: function() {
            return $.extend(HeadlineView.prototype.events, {
                'click .sp-filter-link': 'getFilteredContent',
                'mouseenter .sp-filter-dropdown': 'onHoverFilterDropdown',
                'mouseleave .sp-filter-dropdown': 'onHoverFilterDropdown'
            });
        },
        /**
         * Initialize view.
         * @param {Object} options View options passed in during init.
         */
        initialize: function(options) {
            HeadlineView.prototype.initialize.call(this, options);
        },
        /**
         * Fetch content based on the dropdown filter selection
         * @param {Event} e View click event.
         */
        getFilteredContent: function(e) {
            this.$contentFilterId = $(e.currentTarget);
            var filterChoiceText = this.$contentFilterId.text();
            var url = this.$contentFilterId.data('url');
            
            this.$('.headline-collection-container').addClass('sp-loading-details');
            this.$('.headline-collection-container').html("");
            this.$('.sp-filter-btn').html( filterChoiceText );
            
            StateManager.fetchHtml(url).done(_.bind(function(html){
                this.$('.headline-collection-container').html(html);
                this.$('.headline-collection-container').removeClass('sp-loading-details');
            }, this));
            
            var heattrack = this.$contentFilterId.data('ht') + filterChoiceText;
            if (heattrack)
                PubSub.trigger('uotrack', heattrack);
        },
        /**
         * Shows the next block of headlines.
         * @param {Event} e View click event.
         */
        showMore: function(e) {
            e.preventDefault();
            if ($(e.target).hasClass('inactive'))
                return false;
            
            this.$button = this.$('.button-add-content');
            this.$button.addClass('loading').text(this.$button.attr('data-loading'));
            var $nextPage = this.$('.headline-page.active:last').next();
            var ditemnum = $nextPage.attr("data-itemnum");
            var ditemperpage = $nextPage.attr("data-items-per-page");
            var count = Math.round(ditemnum/ditemperpage);
            var $collection = $('.headline-collection');
            var dataSrc;
            
            if($collection.hasClass('listview')){
                dataSrc = "data-list-src";
            } else {
                dataSrc = "data-grid-src";
            }
            Utils.lazyLoadImage($nextPage.find('img'), dataSrc, true);

            PubSub.trigger('uotrack', Utils.getSectionPath(window.location.pathname) + 'morestories' + count);
            this.transitionMore($nextPage);
            
            return true;
        },
        /**
         * Hover handler for filter dropdowns from modules/sports/sports-filters.js
         * @param {Event} e Hover event.
         */
        onHoverFilterDropdown: function(e) {
            if (e.type === 'mouseenter') {
                var $dropdown = $(e.currentTarget);
                var $scrollWrap = $dropdown.find('.sp-filter-scroll-wrap');
                this.subviews.vscroll = new VerticalScroll({
                    el: $scrollWrap,
                    padding: 6
                });
            } else {
                if(this.subviews.vscroll) this.subviews.vscroll.destroy();
            }
        }
    });
    /**
     * Return view class.
     */
    return SportsHeadlineView;
});
