/**
* @fileoverview Line Graph (interactive) view.
* @author Stan Wilson Jr
(copy this file from web-interactive)
*/
define('modules/sports/line-chart',[
    'jquery', 
    'underscore', 
    'baseview',
    'pubsub', 
    'utils',
    //'modules/interactives/chart-style-assets',
    'libs/flot/jquery.flot'
],
function (
    $,
    _,
    BaseView,
    PubSub,
    Utils,
    ChartStyles
) {

    var LineGraphView = BaseView.extend({

        events: {
            'plothover .chart-area': 'lazyLineHover'
        },

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function (options) {
            this.createLine('.graph');
        },
       
        createLine: function () {

            var _this = this,
                _thisEl = _this.$el,
                data = [],
                colorId = _thisEl.data('section'),
                colorOverride = _thisEl.data('colors') || '',
                colorList = colorOverride.split("|"),
                xAxisOverride = _thisEl.data('xaxis') || '',
                colors = (colorOverride) ? colorList : ChartStyles.color[colorId];

            _this.tickLabels = _this.createTicks( xAxisOverride );

            // Loop through the bullets in the legend and override the colors 
            _thisEl.find('.chart-area-legend ul li').each(function (index) {
                    $(this).css("background-color", colors[index]);
            });

            // Loop through each row
            _thisEl.find(".data tr").each(function (index) {
                var row = _thisEl.find("tr:eq(" + index + ")"),

                    //find the label, which will always bet he first td
                    label = row.find("td:eq(0)").text(),

                    //find each td.coord within each row and split it up into an x and y axis
                    innerData = [];

                row.find("td.coord").each(function () {
                    var coordsSrc = ($(this).text()).split(',');
                    innerData.push([coordsSrc[0], coordsSrc[1]]);
                });

                var lineColor = colors[index];

                data.push({
                    label: label,
                    data: innerData,
                    color: colors[index],
                    points: {
                        fillColor: colors[index]
                    }
                });
            });

            var graph = _thisEl.find(".graph");
            if (graph.length){
                var p = $.plot( graph, data, {
                    legend: {
                        show: false,
                        noColumns: 1
                    },
                    xaxis: {

                        ticks: _this.tickLabels,
                        autoscaleMargin: 0.03,
                        position: "bottom",
                        borderColor: "rgb(237,236,236)"

                    },
                    yaxis: {
                        position: "left",
                        labelHeight: 25,
                        borderColor: "rgb(237,236,236)"
                    },
                    lines: {
                        show: true
                    },
                    points: {
                        show: true,
                        radius: 2,
                        fill: true
                    },
                    grid: {
                        borderWidth: 1,
                        borderColor: "rgb(237,236,236)",
                        labelMargin: 5,
                        axisMargin: 0,
                        clickable: true,
                        hoverable: true,
                        autoHighlight: true
                    }
                });
            }
        },

        lazyLineHover: function(){
            if (!this.lazyLineDebounce){
                this.lazyLineDebounce = _.debounce(this.lineHover, 10);
            }
            this.lazyLineDebounce.apply(this, arguments);
        },

        createTicks: function (tickData) {
            var labelArray = tickData.split("|"),
                newArray = [];
            $.each(labelArray, function (index, label) {
                newArray.push([index, label]);
            });
            return newArray;
        },

        lineHover: function (event, pos, obj) {
            var chartArea = this.$('.chart-area');
            if (!obj) { 
               chartArea.find(".chart-hover").hide();
               return;
            } else {
               chartArea.find(".chart-hover").show();
            }
            var templ = " <div class='{{pointerClass}}'></div>{{label}}<h4 class='weather'>{{body}}</h4>";

            var chartHover = chartArea.find(".chart-hover"),
                prefix = chartArea.data("prefix"),
                suffix = chartArea.data("suffix"),  
                yOffset = chartArea.offset().top,
                xOffset = chartArea.offset().left,
                pointerClass="",
                hoverCss="",
                top = yOffset + (chartArea.height() / 2),
                middleLeft =  xOffset + (chartArea.width() / 3),
                middleRight = xOffset + (chartArea.width() / 3) * 2,
                bottom = yOffset + ((chartArea.height() / 3) * 2),
                hoverClass = "",
                chartHoverHeight = chartHover.height(),
                chartHoverWidth = chartHover.width();

            if (pos.pageX < middleLeft) {
                hoverClass = "middleleft-hover";
                pointerClass = "pointer-left";
                hoverCss = {
                    top: pos.pageY - (chartHoverHeight / 2) - yOffset,
                    left: pos.pageX - xOffset
                };
            } else if (pos.pageX > middleRight) {
                hoverClass = "middleright-hover";
                pointerClass = "pointer-right";
                hoverCss = {
                    top: pos.pageY - (chartHoverHeight / 2) - yOffset,
                    left: pos.pageX - chartHoverWidth - xOffset
                };
            } else if (pos.pageY < top) {
                hoverClass = "top-hover";
                pointerClass = "pointer-top";
                hoverCss = {
                    top: pos.pageY - yOffset,
                    left: pos.pageX - (chartHoverWidth / 2) - xOffset
                };
            } else if (pos.pageY > top) {
                hoverClass = "bottom-hover";
                pointerClass = "pointer-bottom";
                hoverCss = {
                    top: pos.pageY - chartHoverHeight - yOffset,
                    left: pos.pageX - (chartHoverWidth / 2) - xOffset
                };
            } else  {
                chartHover.hide();
            }

            var bodyTxt = prefix + "" + Utils.addCommas(obj.datapoint[1]) + " " + suffix;
            var labelTxt = (obj.series.label !== "")? "<h3>" + obj.series.label + "</h3>" : "<h3 class=\"empty\"></h3>";
            //Comment out this pointerClass because still couldnt figure out the correct equation for the pointer. It is off on Finance Graph
            //var content = templ.replace("{{pointerClass}}", pointerClass).replace("{{label}}", labelTxt).replace("{{body}}", bodyTxt);
            var content = templ.replace("{{pointerClass}}", '').replace("{{label}}", labelTxt).replace("{{body}}", bodyTxt);
            chartHover.css(hoverCss).html(content).attr("class", hoverClass + " chart-hover");
        }
    });
   

    /**
     * Return view class.
     */
    return LineGraphView;
});

