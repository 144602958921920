/**
 * @fileoverview Live Sports Stats Module
 * @author cbron@gannett.com (Caleb Bron)
 */

define('modules/live-sports-stats/live-sports-stats',[
        'jquery',
        'underscore',
        'baseview',
        'ui/generic-paginator'
    ],
    function(
        $,
        _,
        BaseView,
        GenericPaginatorView
    ) {
        "use strict";
        /**
         * View class.
         */

        var LiveSportsStats = BaseView.extend({
            /**
            * Events
            */
            events: {
                'click .lssm-sub' : 'switchPanel'
            },

            /**
             * Initialize view.
             */
            initialize: function() {

                BaseView.prototype.initialize.call(this);

                this.main_menu         = this.$('.lssm-menu-wrap .lssm-main');
                this.sub_menu          = this.$('.lssm-menu-wrap .lssm-sub-listitem');
                this.sports            = this.$('div.lssm-sport');
                this.panels            = this.$('div.lssm-panel');
                this.sport_wrappers    = this.$(".lssm-gameon");
                this.env               = document.location.hostname;
                this.sport             = this.$('.lssm-wrap').data('default-sport');
                this.panel             = this.$('.lssm-wrap').data('default-panel');

                // If the default sport doesn't exist, use the first available sport
                if (this.sport_wrappers.filter(".lssm-" + this.sport).length < 1) {
                    this.sport = this.sport_wrappers.children(".lssm-event-id").data("sport");
                }

                _.bindAll(this, 'checkGameOnPanels', 'fetchData', 'updateMenu');

                this.updateMenu();
                this.checkGameOnPanels();
                this.lssmGameOnPoll = setInterval(this.checkGameOnPanels, 10000); //10 seconds
                this.showSelectedPanel();
            },

            /**
             * If only one sport, hide the main menu and show the sub menu instead of using hover
             * I tried to do this with css but its harder than it looks, no class selectors
            */
            updateMenu: function(){
                if (this.sub_menu.length == 1){
                    this.sub_menu.height('40');
                    this.main_menu.height('0');
                }
             },

            switchPanel: function(e){
                var panel = this.$(e.currentTarget).data('panel');
                var sport = this.$(e.currentTarget).parent().data('sport');
                if (sport != this.sport || panel != this.panel) {
                    this.$('div.'+this.sport).hide();
                    this.$('div.'+sport).show();
                    this.sport = sport;
                    this.panel = panel;
                }
                this.showSelectedPanel();
             },

             showSelectedPanel: function() {
                this.$('.lssm-panel').hide();
                this.$('.lssm-sport').hide();
                this.$('.lssm-sport.'+this.sport).show();
                this.sports.filter("."+this.sport).children(".lssm-"+this.panel).show();
             },


            /**
             * Update all game-on panels with live data
            */
            checkGameOnPanels: function() {
                if (this.checkingLiveScores) return;
                this.checkingLiveScores = true;
                $.each(this.$(".lssm-event-id"), _.bind(function(index, item) {
                    this.fetchData($(item).data('sport'), $(item).data('team'));
                }, this));
            },

            /**
             * Fetch Data from the api for each sport
             * Different endpoints for dev and prod
             * @param {String} sport sport name, 'mlb', 'nhl'
             * @param {String} team 'reds'
            */
            fetchData: function(sport, team) {
                $.ajax({
                    type: 'POST',
                    url: '/gameon/partials/' + sport + '/' + team + '/gameon/',
                    success: function(html){
                        this.injectHtml(sport, html);
                    }.bind(this),
                    error: function(){
                    }.bind(this)
                });
                this.checkingLiveScores = false;
            },

            /*
             * Given the partial's html, add into live page
             * @param {JSON} partials
            */
            injectHtml: function(sport, html){
                if (html){
                    var el = this.$(".lssm-panel.lssm-gameon.lssm-"+sport);
                    el.replaceWith(html);
                }
                this.showSelectedPanel();
            },

        });

        /**
         * Return view class.
         */
        return LiveSportsStats;
    }
);

