/* global define, console */
define('fwinfo',[
    'jquery',
    'underscore',
    'cookie'
],
function(
    $,
    _
){
    'use strict';
    /**
     * Global Session Storage Util
     * Allows for storing session-based values, including objects, that persist through opening links in new tabs.  This
     * leverages localStorage and mimics the behavior of session cookies.  If cookies are disabled, we fall back to
     * sessionStorage.
     * @exports fwinfo
     * @author Kevin Foster <kefoster@gannett.com>
     */
    var FwInfo = function() {
        this.initialize();
    };

    FwInfo.prototype = {

        initialize: function() {
            this.useLocalStorage = navigator.cookieEnabled && window.localStorage;
            if (this.useLocalStorage && !$.cookie('hasFwInfo')) {
                window.localStorage.removeItem('fwInfo');
                $.cookie('hasFwInfo', 'true', { path: '/' });
            }
        },

        setPersistentSessionValue: function(key, value) {
            var fwInfo = this._getFwInfo();
            fwInfo[key] = value;
            this._setFwInfo(fwInfo);
        },

        getPersistentSessionValue: function(key, defaultValue) {
            var fwInfo = this._getFwInfo();
            return fwInfo[key] || defaultValue;
        },

        getAndClearPersistentSessionValue: function(key) {
            var fwInfo = this._getFwInfo(),
                value = fwInfo[key];
            this._setFwInfo(_.omit(fwInfo, key));
            return value;
        },

        _setFwInfo: function(fwInfo) {
            if (_.isObject(fwInfo)) {
                try {
                    fwInfo = JSON.stringify(fwInfo);
                    if (this.useLocalStorage) {
                        window.localStorage.setItem('fwInfo', fwInfo);
                    } else {
                        window.sessionStorage.setItem('fwInfo', fwInfo);
                    }
                } catch (ex) {
                    console.error('Unable to set fwInfo in localStorage', (ex.stack || ex.stacktrace || ex.message));
                }
            }
        },

        _getFwInfo: function() {
            var fwInfo;
            try {
                fwInfo = JSON.parse((this.useLocalStorage) ? window.localStorage.getItem('fwInfo') : window.sessionStorage.getItem('fwInfo'));
            } catch (ex) {
                console.error('Unable to fetch fwInfo from localStorage', (ex.stack || ex.stacktrace || ex.message));
            }
            return fwInfo || {};
        },

        /** deprecated - use setPersistentSessionValue instead **/
        setsessionvalue: function(key, value) {
            this.setPersistentSessionValue(key, value);
        },

        /** deprecated - use getPersistentSessionValue instead **/
        getsessionvalue: function(key) {
            return this.getPersistentSessionValue(key);
        },

        /** deprecated - use getAndClearPersistentSessionValue instead **/
        getandclearsessionvalue: function(key) {
            return this.getAndClearPersistentSessionValue(key);
        }

    };

    return new FwInfo();
});

