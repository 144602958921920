/**
 * @fileoverview Adding support for Piano integration into Autoplay manager 
 */
define('modules/stories/piano',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'managers/autoplay',
    'state'
],
function(
    $,
    _,
    BaseView,
    Utils,
    AutoPlayManager,
    StateManager
) {


        /**
         * View class.
         */
        var Piano = BaseView.extend({
            
            initialize: function(options) {
                this.pianoEnabled = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'PIANO', 'enabled');
                if (this.pianoEnabled && !this.isVideo()) {
                    this.pianoAutoplayPromise = $.Deferred();
                    _.bindAll(this, 'autoplay', 'isVideo');
                    AutoPlayManager.register('piano', this.autoplay);

                    // listen for piano to be completed
                    window.pianoComplete = _.bind(function() {
                        this.pianoAutoplayPromise.resolve();
                    }, this);
                }

            },

            isVideo: function() {
                var texttype = StateManager.getActivePageInfo().texttype;
                return (['video', 'video-playlist', 'brightcove-video-playlist'].indexOf(texttype) !== -1);
            },

            autoplay: function() {
                return this.pianoAutoplayPromise;
            },

            destroy: function() {
                delete window.pianoComplete;
                BaseView.prototype.destroy.call(this);
            }
        });

        return Piano;
});

