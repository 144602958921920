/**
 * @fileoverview Storefront group view module.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('modules/storefront-group',[
    'jquery',
    'underscore',
    'baseview',
    'ui/generic-paginator'
],
function(
    $,
    _,
    BaseView,
    GenericPaginatorView
) {
        var StorefrontGroupView = BaseView.extend({
            initialize: function() {
                BaseView.prototype.initialize.call(this);

                this.items_container = this.$('.sfm-items');
                this.viewport = this.items_container.parent();
                this.pageIndex = 0;

                this.subviews.paginator = new GenericPaginatorView({
                    el: this.$('.paginator'),
                    onGoTo: this.goTo
                });
            },

            goTo: function(pageIndex) {
                var offsetBy = this.viewport.outerWidth();
                var targetOffset = offsetBy * pageIndex * -1;

                this.animate(this.items_container, 'left', targetOffset + 'px', 450, 'ease-in-out');

                this.pageIndex = pageIndex;
            }

        });

        return StorefrontGroupView;
    }
);
