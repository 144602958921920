define('modules/global/iframe-auto-height',[
    'jquery',
    'underscore',
    'utils',
    'baseview'
],
function(
    $,
    _,
    Utils,
    BaseView
 )
    {
        'use strict';
        var IframeView = BaseView.extend(
        /**
         * @lends modules/global/iframe-auto-height.prototype
         */
        {
            /**
             * @classdesc receives window.postMessage events and handles iframe resize and pageLoad notifications
             * @constructs modules/global/iframe-auto-height
             * @author Robert Huhn <rhuhn@usatoday.com>
             * @param {Object} options backbone options object
             *     @param {jQuery|Element|String} options.el - element or string selector that contains the iframe we're sizing
             *     @param {String} [options.frameOrigin=http://usatoday30.usatoday.com] - the origin of the postMessage we're listening to
             */
            initialize: function(options) {
                options = $.extend({
                    frameOrigin: 'http://usatoday30.usatoday.com'
                }, options);
                this.win = Utils.get('win');
                this.$scrollEl = Utils.get('scrollEl');
                this.iframeHeight = this.$el.height();
                _.bindAll(this, 'onReceiveMessage');

                // Call base class initialize
                BaseView.prototype.initialize.call(this, options);

                // Listen for messages.
                this.win.on('message.' + this.cid, this.onReceiveMessage);
            },

            /**
             * Frame message (window.postMessage) handler.
             * @param {Object} e Message event object.
             * @private
             */
            onReceiveMessage: function(e) {
                if (e.originalEvent.origin !== this.options.frameOrigin) {
                    return;
                }
                var data = e.originalEvent.data + '';
                var pageLoad = data.match('_pageLoad');
                if (pageLoad) {
                    data = data.replace(/(\d*)_pageLoad/gi, '$1');
                    this.$scrollEl.scrollTop(0);
                }
                if (data !== this.iframeHeight) {
                    this.$el.css('height', data);
                    this.iframeHeight = data;
                }
            },

            /**
             * Unregisters window.postMessage event listener, releases resources
             */
            destroy: function() {
                this.win.off('.' + this.cid);
                BaseView.prototype.destroy.apply(this, arguments);
            }
        });

        /**
         * Return view class.
         */
        return IframeView;
    }
);

