/**
 * @fileoverview Bar Chart (interactive) view.
 * @author Stan Wilson Jr, William Broza
 */
define('modules/interactives/bar-chart',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'modules/interactives/interactive-base',
    'libs/flot/jquery.flot.orderBars',
    'libs/flot/jquery.flot.usat.style'
],
    function($, _, BaseView, Utils, InteractiveBase) {
        "use strict";

        /**
         * View class.
         */
        var BarChartView = InteractiveBase.extend({

            initialize: function(options) {
                this.orientation = this.$el.data('orientation');
                InteractiveBase.prototype.initialize.call(this, options);
            },

            _isMultiBar: function (){
                return this.datasetItems.length > 1;
            },

            getChartColors: function() {
                if (this.orientation === 'horizontal' && this._isMultiBar()) {
                    return (this.$el.data('colors') || '').split('|').slice(0, this.datasetItems.length).reverse();
                }
                return (this.$el.data('colors') || '').split('|');
            },

            getFlotData: function(dataset) {
                return _.map(dataset, function(item, index, dataset) {
                    var color =  this.mainColor;
                    if (dataset.length > 1) {
                        color = this.chartColors[index % this.chartColors.length];
                    }
                    return {
                        label: item.label || '',
                        color: color,
                        bars: {
                            borderWidth: 30,
                            fillColor: color
                        },
                        data: _.map(item.value, function(value) {
                            return value.split(',');
                        })
                    };
                }, this);
            },

            _getHoverBody: function(obj) {
                return [this.getHoverPrefix(),
                    Utils.addCommas(Utils.addCommas(obj.datapoint[this.orientation === 'horizontal' ? 0 : 1])),
                    this.getHoverSuffix()].join(' ');
            },

            /**
             * Builds the options flot requires for the bar graph.
             * Accomodates both vertical and horizontal orientations.
             * @return {Object} flotOptions The flot options required for the bar graph.
             */
            getFlotOptions: function() {
                var barsPerUnit = this.datasetItems.length + ((this.datasetItems.length > 1 &&
                        Utils.getNested(this.datasetItems, '0', 'value', 'length') > 6) ? 4 :
                        this.datasetItems.length > 1 ? 2 : 1),
                    flotOptions = InteractiveBase.prototype.getFlotOptions.apply(this, arguments),
                    barWidth = 1 / barsPerUnit;
                flotOptions.colors = this.chartColors;
                flotOptions.series = {
                    bars: {
                        show: true,
                        barWidth: barWidth,
                        order: 'integer'
                    },
                    stack: false
                };
                flotOptions.series.bars.align = 'center';
                flotOptions.grid.borderWidth = 1;
                flotOptions.grid.labelMargin = 5;
                flotOptions.grid.axisMargin = 0;
                flotOptions.grid.autoHighlight = true;
                flotOptions.grid.borderColor = 'rgb(237,236,236)';

                // Deal with orientation-specific settings.
                if (this.orientation === 'horizontal') {
                    flotOptions.xaxis = {
                        position: 'bottom',
                        autoscaleMargin: 0.02,
                        tickFormatter: this.numberWithCommas
                    };
                    flotOptions.yaxis = {
                        ticks: this._createTicks(this.chartData.datasetLabels || ''),
                        position: 'left',
                        tickLength: 0,
                        autoscaleMargin: 0.02
                    };
                    flotOptions.bars = {
                        horizontal: true
                    };
                } else {
                    flotOptions.xaxis = {
                        autoscaleMargin: 0.02,
                        ticks: this._createTicks(this.chartData.datasetLabels || ''),
                        position: 'bottom',
                        tickLength: 0
                    };
                    flotOptions.yaxis = {
                        position: 'left',
                        labelWidth: 40,
                        tickFormatter: this.numberWithCommas
                    };
                }
                return flotOptions;
            },

            _getHoverLabel: function(obj) {
                if (this._isMultiBar()) {
                    return obj.series.label;
                } else {
                    return '';
                }
            }
        });

        /**
         * Return view class.
         */
        return BarChartView;
    });

