/**
 * @fileoverview Monster contact form
 */
define('apps/monster-contact',[
    'jquery',
    'underscore',
    'base-app',
    'state',
    'pubsub'
],
function(
    $,
    _,
    BaseApp,
    StateManager,
    PubSub
    ) {

    var MonsterContact = BaseApp.extend({
        afterPageReveal: function () {
            // updates eVar99 with LDSN
            PubSub.trigger('group_page_view', 'LDSN');
            // updates eVar66
            PubSub.trigger('product_name', 'Monster');
            StateManager.stopRefreshTimer();
        },
        beforeAppRemove: function() {
            StateManager.startRefreshTimer();
        }
    });
    return MonsterContact;
}
);

