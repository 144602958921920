/**
 * @fileoverview Insider view module.
 * @author rcopley@gannett.com (Ryan Copley)
 */
define('modules/insider-drawer',[
    'jquery',
    'underscore',
    'baseview',
    'user-manager'
],
function(
    $,
    _, 
    BaseView,
    UserManager
) {

        /**
         * View class.
         */
         //Used to restore the meta viewport when we destroy
        var InsiderDrawerView = BaseView.extend({

            // Events.
            events: {
                'click .insider-menu-button-open': 'openMenu',
                'click .insider-menu-button-close': 'closeMenu'
            },
            /**
             * Initialize view.
             */
            initialize: function() {

                this.mobileMenu = this.$(".fwinsider-fwi-mobile-menu");
                // call base class initialize
                BaseView.prototype.initialize.call(this);

                var userAccount = UserManager.getAccount('firefly');
                if (userAccount) {
                    userAccount.getUserInfo().always(_.bind(function (userInfo) {
                        if (userInfo && userInfo.hasMarketAccess === true) {
                            this.$(".fwinsider-member-id-hide").removeClass("fwinsider-member-id-hide");
                        }
                    }, this));
                }

            },
            
            openMenu: function(event) {
                this.mobileMenu.css("display", "inline");
            },

            closeMenu: function(event) {
                this.mobileMenu.css("display", "none");
            }
        });

        /**
         * Return view class.
         */
        return InsiderDrawerView;
    }
);
