/**
 * @fileoverview Sports STN Promo View
 * @author pkamat 
 */
define('modules/sports-stn-promo/sports-stn-promo',[
    'jquery',
    'underscore',
    'baseview',
],
function(
    $,
    _,
    BaseView
) {
    var SportsSTNPromoView = BaseView.extend({

            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);
                $(document).ready( function() {
                    //Only clicking story pages should stop the player
                    $('a[href*="story/sports"]').click(function() {
                        /* jshint ignore:start */
                        if (typeof s2nStopPlayer != 'undefined' && $.isFunction(s2nStopPlayer)) {
                            console.log('s2nStopPlayer exists');
                            s2nStopPlayer();// jshint ignore:line
                        }
                        /* jshint ignore:end */
                    });
                });
            }
        });

        return SportsSTNPromoView;
    }
);

