/**
 * @fileoverview Lottery Widget
 * @author mbriede@gannett.com (Matthew Briede)
 */
define('modules/lottery-widget/lottery-widget',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'adPosition'
],
    function(
        $,
        _,
        BaseView,
        StateManager,
        AdPosition
        ) {
        "use strict";
        /**
         * View class.
         */
        var LotteryWidget = BaseView.extend({

            // View element
            el: '.lottery-ad-container',

            /**
             * Initialize view.
             */
            initialize: function(options) {

                options = $.extend({
                    //what is the adPlacement for lottery?
                    adPlacement: 'sponsor_logo_lottery', 
                    adSizes: [300,50]
                }, options);

                this.options = options;
                this.$sponsorshipAd = this.$('.lottery-ad');

                BaseView.prototype.initialize.call(this, options);

                this._buildSponsorshipAd();
            },

            _buildSponsorshipAd: function(){
                if (this.$sponsorshipAd.length && !this.subviews.sponsorshipAd) {
                    this.subviews.sponsorshipAd = new AdPosition({
                        el: this.$sponsorshipAd,
                        adSizes: this.options.adSizes,
                        adPlacement: this.options.adPlacement,
                        adType: 'sponsor_logo',
                        pageInfo: StateManager.getActivePageInfo()
                    });
                }
            }

        });

        /**
         * Return view class.
         */
        return LotteryWidget;
    }
);
