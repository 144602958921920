define('modules/videos/pluto/pluto-endslate',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {
    'use strict';
    var PlutoEndslate = BaseView.extend({
        el: '.js-pluto-endslate',

        visible: false,

        initialize: function(options){
            options = $.extend({
                contentUrl: '/videos/get_endslate/'
            }, options);
            BaseView.prototype.initialize.call(this, options);

            this.setupEndslate();
        },

        /**
         * Show the endslate
         */
        show: function() {
            this.visible = true;
            this.$el.show();
        },

        /**
         * Close the endslate
         */
        close: function() {
            this.visible = false;
            this.$el.hide();
        },

        /**
         * Get data to fill endslate with
         */
        setupEndslate: function() {
            $.get(this.options.contentUrl, _.bind(this.fillEndslate, this));
        },

        /**
         * Add links and images to endslate
         * @param {Object} data to fill endslate with
         */
        fillEndslate: function(data) {
            if (data) {
                try {
                    this.$$('.js-360-endslate-link').each(function(i) {
                        if (data[i]) {
                            $(this).find('.js-360-endslate-anchor').attr({
                                href: data[i].url
                            });
                            $(this).find('.js-360-endslate-img').attr({
                                src: data[i].image,
                                alt: data[i].title
                            });
                            $(this).find('.js-360-endslate-title').html(data[i].title);
                        }
                    });
                } catch(exception) {
                // TODO: handle this
                }

            }
        }
    });

    return PlutoEndslate;
});
