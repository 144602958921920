define('modules/global/userUtil',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function(
    $,
    _,
    BaseView,
    Utils
) {
    'use strict';

    var userUtilView = BaseView.extend({

        /**
         * Initialize the view.
         */

        initialize: function() {
            this.deferred = null;
            this.PersonalizedLinkFactory = null;
            this.UserFactory = null;
            this.ClientHelpers = null;

            // call base class initialize
            BaseView.prototype.initialize.call(this);
        },

        getUser: function() {
            if (!this.deferred) {
                this.deferred = $.Deferred();
                var config = {'gupasURLTemplate': 'https://user-stage.{domain-name}/{uaid}/{path}'},
                    userFactory = null,
                    domainName = Utils.getNested(window.site_vars, 'base_url') || '',
                    uaid = Utils.getNested(window.site_vars, 'gupUAID') || '';

                this.poll(
                    this.checkFactories,
                    function() {
                        this.setFactories();
                        if (Utils.getNested(window.site_vars, 'site_env') === 'prod' || Utils.getNested(window.site_vars, 'site_env') === 'production') {
                            // use production user endpoints
                            userFactory = new this.UserFactory();
                        } else {
                            // use stage user endpoints
                            userFactory = new this.UserFactory(config);
                        }
                        // This check was added for tests, which were trying to run the following function without site_vars
                        if (domainName && uaid && userFactory && this.PersonalizedLinkFactory) {
                            userFactory.fetch(domainName, uaid, function(user){
                                var featurePersonalizedLink = new this.PersonalizedLinkFactory(user);
                                var personalizedLinkData = {};
                                personalizedLinkData.forGuid = this.PersonalizedLinkFactory.getGuidFromUrl(window.location);
                                personalizedLinkData.forGuidSession = this.PersonalizedLinkFactory.getSessionGuidFromUrl(window.location);

                                featurePersonalizedLink.doFeature(window.location, domainName, uaid);

                                var insightsData = user.data.response.insights || {};

                                this.deferred.resolve(user.data, personalizedLinkData, insightsData);
                            }.bind(this));
                        } else {
                            // This should only print out in tests or whenever site_vars is empty
                            console.warn("UserUtil: No domain name and/or uaid found");
                            return this.deferred.reject();
                        }
                    }.bind(this),
                    function() {
                        console.warn("UserUtil: No factories found");
                        return this.deferred.reject();
                    }.bind(this)
                );
            }
            return this.deferred;
        },

        poll: function(fn, callback, errback, timeout, interval) {
            var endTime = Number(new Date()) + (timeout || 2000);
            interval = interval || 100;

            (function p() {
                    // When the condition is met, call the callback.
                    if(fn()) {
                        callback();
                    } else if (Number(new Date()) < endTime) {
                        // The condition isn't met. Wait and call it again.
                        setTimeout(p, interval);
                    } else {
                        // Too much time. Call the error callback.
                        errback(new Error('timed out for ' + fn + ': ' + arguments));
                    }
            })();
        },

        checkFactories: function(){
            if ('UserFactory' in window && 'PersonalizedLinkFactory' in window && 'ClientHelpers' in window) {
                return true;
            }
            return false;
        },

        setFactories: function(){
            if ('UserFactory' in window && 'PersonalizedLinkFactory' in window && 'ClientHelpers' in window) {
                this.UserFactory = window.UserFactory;
                this.PersonalizedLinkFactory = window.PersonalizedLinkFactory;
                this.ClientHelpers = window.ClientHelpers;
            }
        }
    });

    return userUtilView;

});

