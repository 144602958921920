/**
 * @fileoverview Assigned Content stories module view.
 * @author Craig Johnson
 */
define('modules/stories/assigned-content',[
    'jquery',
    'underscore',
    'baseview',
    'form/dropdown',
    'ui/generic-paginator'
], function ($, _, BaseView, FormDropdown, GenericPaginatorView) {

    var AssignedContentNav = BaseView.extend({
    
        initialize: function(options) {

            _.bindAll(this, 'goTo', 'onFilterDropdownSelect');

            this.subviews = {};

            this.$itemsContainer = this.$('.acnsb-items-container');
            this.$items = this.$('.acnsb-item');
            this.$pageElements = this.$('.paginator-indicator-target');

            this.subviews.dropdown = new FormDropdown({
                el: this.$('.acnsb-dropdown'),
                onSelect: this.onFilterDropdownSelect
            });
        
            this.subviews.paginator = new GenericPaginatorView({
                el: this.$('.paginator'),
                onGoTo: this.goTo
            });

            // call base class initialize
            BaseView.prototype.initialize.call(this, options);

        },
        
        onFilterDropdownSelect: function (selectedValue) {

            var visibleCount, bulletCount;
        
            this.$pageElements.eq(0).trigger('click');

            this.$items.show();
        
            if (selectedValue != 'all') {
                this.$items.not('.'+selectedValue).hide();
            }
            
            // adjust number of pagination bullets to match number of visble items
            visibleCount = this.$items.filter(':visible').length;
            
            if (visibleCount <= 8) {
                this.$('.acnsb-container').addClass('nopagination');
            } else {
                this.$('.acnsb-container').removeClass('nopagination');
                bulletCount = Math.floor( visibleCount / 8 );
                this.$pageElements.show().filter(':gt('+bulletCount+')').hide();
            }
        }, 
        
        goTo: function (pageNumber) {
            this.animate(this.$itemsContainer, 'top', (-pageNumber * 270) + 'px', 450, 'ease-in-out');
        }
                
        
    });

    return AssignedContentNav;

});

