/**
 * @fileoverview story_series header view module.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('modules/story-series-header',[
    'jquery',
    'baseview',
    'state',
    'adPosition',
    'pubsub'
],
function(
    $,
    BaseView,
    StateManager,
    AdPosition,
    PubSub
) {
        var StorySeriesHeader = BaseView.extend({
            // Events.
            events: {
                'click .ssa-header-mobile-menu': 'toggleMobileMenu',
                'click .ssa-header-mobile-nav-background': 'toggleMobileMenu'
            },

            /**
             * Initialize view.
             */
            initialize: function() {
                BaseView.prototype.initialize.call(this);

                this.$sponsorshipAd = this.$('.ssa-header-sponsor-ad');
                this.buildSponsorshipAd();

                // updates eVar99 with LDSN
                PubSub.trigger('group_page_view', 'LDSN');
                // updates eVar66
                PubSub.trigger('product_name', 'Series');
            },

            /**
             * Initializes the ad as soon as page is loaded
            */
            buildSponsorshipAd: function(){
                if (this.$sponsorshipAd.length && !this.subviews.sponsorshipAd) {
                    this.subviews.sponsorshipAd = new AdPosition({
                        el: this.$sponsorshipAd,
                        adSizes: [100,30],
                        adPlacement: 'sponsor_logo',
                        adType: 'sponsor_logo',
                        pageInfo: StateManager.getActivePageInfo()
                    });
                }
            },

            toggleMobileMenu: function() {
                var menu_wrap = this.$(".ssa-header-mobile-nav-wrapper");
                var menu_back = this.$(".ssa-header-mobile-nav-background");
                var menu = this.$(".ssa-header-mobile-nav");
                var menu_hidden = menu_wrap.hasClass('ssa-hidden');

                if (menu_hidden) {
                    menu_wrap.removeClass('ssa-hidden');
                    menu_back.animate({opacity: 1.0});
                    menu.animate({left: 0}, {
                        duration: 300, queue: false, easing: 'easeOutQuad'
                    });
                } else {
                    menu_back.animate({opacity: 0.0});
                    menu.animate({left: -250}, {
                        duration: 300, queue: false, easing: 'easeOutQuad',
                        complete: function () {
                            menu_wrap.addClass('ssa-hidden');
                        }
                    });
                }


            }
        });



        return StorySeriesHeader;
    }
);

