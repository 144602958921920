/**
 * @fileoverview 2016 Elections Results Map module.
 * @author bbagley@gannett.com (Brendan Bagley)
 * @author joreed@gannett.com (Jon Reed)
 */
define('modules/fronts/election-results-map',[
        'jquery',
        'underscore',
        'baseview',
        'state',
        'managers/routemanager'
    ],
    function(
        $,
        _,
        BaseView,
        StateManager,
        RouteManager
    ) {
        /**
         * View class for the 2016 Elections Results Map.
         */
        var ElectionsResultsMap = BaseView.extend({

            // View element.
            el: '.elections-results-map',

            /**
             * Initialize view.
             * @param {Object} options View options passed in during init.
             */
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);
                _.bindAll(this, "_handleMouseOver", "_handleMouseMove", "_handleMouseOut");
                this.loadData();
                this.tooltip = this.$("#map-tooltip")[0];
                this.tooltipOffset = {
                    x: 5,
                    y: 15
                };
                var states = this.$("svg#map a");
                if (states) {
                    for (var i = 0; i < states.length; i++) {
                        var s = states[i];
                        s.addEventListener("mouseover", this._handleMouseOver, false);
                        s.addEventListener("mousemove", this._handleMouseMove, false);
                        s.addEventListener("mouseout", this._handleMouseOut, false);
                    }
                }
            },


            loadData: function() {
                var stateTemp = $('use');
                this.stateMapping = [];
                for (var i = 0; i < stateTemp.length; i++) {
                    this.stateMapping.push(stateTemp[i].id);
                }
                var url = "https://sslapi.gannett-cdn.com/elections/v1/2016-election/results/p/national?apiKey=57646bc6bca4811fea00000158f0635f9879400a6bbc296c010e0778";
                StateManager.fetchData(url, {
                    dataType: 'jsonp'
                }).done(_.bind(function(data) {
                    if (!data) {
                        return;
                    }
                    this.races = data.races;
                }, this));
            },
            _handleMouseOver: function(e) {
                var stateName = e.target.id.replace('_', ' ').replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
                var stateData = this.races[this.stateMapping.indexOf(e.target.id)].reportingUnits[0];
                this.tooltip.style.display = "block";
                if (stateData) {
                    this.tooltip.innerHTML =
                        "<div class='stateName'>" + stateName + " (" + stateData.electTotal + ")</div>" +
                        "<div class='reporting'>" + stateData.precinctsReportingPct + "% reporting</div>";
                } else {
                    this.tooltip.innerHTML = stateName;
                }
            },

            _handleMouseMove: function(e) {
                this.tooltip.style.left = e.clientX + this.tooltipOffset.x + "px";
                this.tooltip.style.top = e.clientY + this.tooltipOffset.y + "px";
            },

            _handleMouseOut: function() {
                this.tooltip.style.display = "none";
            }
        });

        /**
         * Return view class.
         */
        return ElectionsResultsMap;
    }
);

