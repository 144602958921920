define('partner/high-impact-base',[
    'jquery',
    'underscore',
    'baseview',
    'adPosition',
    'partner/leavebehind'
],
function(
    $,
    _,
    BaseView,
    AdPosition,
    LeaveBehind
) {
    'use strict';
    var HighImpactBase = BaseView.extend({
        /**
         * Initialize High Impact ad unit
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector
         */
        initialize: function(options) {
            _.bindAll(this, 'onAdReady', 'showAd', 'beforeAdRender');
            options = $.extend({
                leaveBehindEl: $('.partner-leavebehind'),
                frameBehavior: {
                    clearBgColor: true,
                    hideframe: true,
                    hidebrand: true
                }
            }, options);

            BaseView.prototype.initialize.call(this, options);
        },

        /**
         * This function triggers the AdPosition to register and request an ad
         * @param {Array<String>} adSizeNames - high impact ad size names
         * @param {String|Array<String>} adTypes - high impact ad type name used to identify this ad position
         * @param {Object} currentCardInfo current viewport sizing information
         * @param {Number} currentCardInfo.cardWidth The width the ad can fit inside of
         * @private
         */
        _createAd: function(adSizeNames, adTypes, currentCardInfo) {
            var options = $.extend({
                    adPlacement: 'high_impact',
                    adSizes: adSizeNames,
                    adType: adTypes,
                    onAdReady: this.onAdReady,
                    beforeAdRender: this.beforeAdRender,
                    legacyHighImpact: true
                }, this.options);

            this.subviews.adPosition = new AdPosition(options);
            this.currentCardInfo = currentCardInfo;
        },

        /**
         * @param {Object} currentCardInfo current viewport sizing information
         *     @param {Number} currentCardInfo.cardWidth The width the ad can fit inside of
         * @param {Boolean} [isCardBumped=false] - Used for specifying if the leave behind is compact or not
         */
        renderCardInfo: function(currentCardInfo, isCardBumped){
            this.currentCardInfo = currentCardInfo;
            this.isCardBumped = isCardBumped;
        },

        /**
         * Called when an ad has been delivered but hasn't been fetched/created
         * @param {HighImpactAdData} adData dfp high impact ad data
         */
        beforeAdRender: function(adData) {
            this.adData = adData;

            if ((adData.leaveBehindImage || ((adData.frameBackground || adData.frameColor) && adData.frameSliver)) && adData.leaveBehindText) {
                this.subviews.adLeaveBehind = new LeaveBehind({
                    el: this.options.leaveBehindEl,
                    cards: this.options.cards,
                    onShowAd: this.showAd,
                    isCompact: this.isCardBumped,
                    isNative: this.options.isNative,
                    maxDisplayed: parseInt(adData.maxDisplayed, 10) || this.options.maxDisplayed,
                    behavior: this.options.frameBehavior || {}
                });
            }
        },

        onAdReady: function(adData) {
            var slot = this.options.slot;
            //need to have the ad slot visible on the page in order for DFP to track visibile impressions
            if (slot) {
                slot.addClass('show-slot');
            }

            if (this.subviews.adLeaveBehind) {
                this.subviews.adLeaveBehind.render(this.subviews.adPosition, adData);
            } else {
                this.showAd();
            }
        },

        showAd: function() {
            this.showingAd = true;
            this.subviews.adPosition.show();
        }
    });
    return HighImpactBase;
});
