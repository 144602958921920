
/**
 * @fileoverview More dropdown in the global navigation.
 * @author ekallevig@gannett.com (Erik Kallevig)
 *
 */

define('modules/header/site-nav-more-module',[
    'underscore',
    'modules/header/site-nav-dropdown'
],
function(
    _,
    SiteNavDropdown
) {

"use strict";
var MoreDropdownView = SiteNavDropdown.extend({

    initialize: function(options) {
        this.$navDropdown = this.$('.site-nav-dropdown');
        this.$navDropdownList = this.$('.site-nav-more-dropdown-list');
        this.$navDropdownItems = this.$('.site-nav-more-dropdown-item');
        SiteNavDropdown.prototype.initialize.call(this, options);
    },

    convertColumnLayout: function(layout) {
        var oldLayout = layout == '1col' ? '2col' : '1col';
        this.$navDropdown.removeClass('site-nav-more-dropdown-' + oldLayout)
            .addClass('site-nav-more-dropdown-' + layout);
        this.$navDropdownList.removeClass('site-nav-more-dropdown-list-' + oldLayout)
            .addClass('site-nav-more-dropdown-list-' + layout);
        this.$navDropdownItems.removeClass('site-nav-more-dropdown-item-' + oldLayout)
            .addClass('site-nav-more-dropdown-item-' + layout);
    }

});


return MoreDropdownView;

});

