/**
 * @fileoverview Carousel Companion
 */
define('modules/carousel/companion',[
    'jquery',
    'underscore',
    'baseview',
    'modules/partner/companion-ad',
    'utils'
    ],
    function(
        $,
        _,
        BaseView,
        CompanionAd,
        Utils
    ) {
        'use strict';

        /**
         * View class.
         */
        var CarouselCompanion = BaseView.extend({
            /** @const
             * @private */
            PROGRESSIVE_GALLERY_REFRESH_RATE: Utils.getNested(window.site_vars, 'ADS', 'progressive_gallery_refresh_rate'),
            PROGRESSIVE_GALLERY_REFRESH_MIN_MS: Utils.getNested(window.site_vars, 'ADS', 'progressive_gallery_refresh_min_ms') || 0,

            refreshCount: 0,

            /**
             * Initialize view.
             * @param {Object} options View options passed in during init.
             */
            initialize: function(options) {
                
                BaseView.prototype.initialize.call(this, options);
                
                this.slides = options.slides;
                this.currentInfo = this.$('.pag-current-info');
                
                this.subviews.ad = new CompanionAd({
                    el: this.$('.pag-ad'),
                    adPlacement: 'poster_gallery_companion'
                });
                
            },
            
            /**
             * Event listener that will update the title/caption and refresh the ad
             * @param {Number} index slide index to go to
             */
            goToSlide: function(index) {
                if (++this.refreshCount % this.PROGRESSIVE_GALLERY_REFRESH_RATE === 0) {
                    var timeDiff = new Date().getTime() - this.subviews.ad.refreshTime;
                    if (timeDiff > this.PROGRESSIVE_GALLERY_REFRESH_MIN_MS) {
                        this.subviews.ad.refresh();
                    }
                }
                var slide = this.slides.eq(index);
                var html = slide.find('.pag-photo-info').html();
                
                this.currentInfo.html(html);
            }

        });


        /**
         * Return view class.
         */
        return CarouselCompanion;
    }
);

