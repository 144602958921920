/* global define, console */
define('base-app',['jquery',
    'underscore',
    'baseview',
    'state',
    'managers/trafficcop',
    'site-manager',
    'utils',
    'pubsub',
    'managers/resourcemanager',
    'managers/routemanager',
    'abtest-manager',
    'third_party_integrations/sourcepoint/sourcepoint-utils'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    TrafficCop,
    SiteManager,
    Utils,
    PubSub,
    ResourceManager,
    RouteManager,
    AbTestManager
) {
    'use strict';
    /**
     * @event page:load
     * @desc This event is fired on each page load including ajax loads once the page has fully initialized.
     * Is given a .pageInfo object
     * @type {PageInfo}
     */
    var BaseApp = BaseView.extend(
        /**
         * @lends base-app.prototype
         */
        {
            currentPath: '',

            /**
             * @classdesc Base App providing basic function like revealApp, removeApp, changePage
             * An app is a stateful container that could persist between url changes. When an app is active, it
             * owns all the interactions of the markup on the page. A page is a stateless child of an app, and
             * corresponds with a single unique path that will be destroyed when changing to a new path.
             * Examples:<br/>
             *     App: Cards, Page: GenericSection<br/>
             *     App: Overlay, Page: Story
             * @requires state
             * @author Jay Merrifield <jmerrifiel@gannett.com>
             * @constructs base-app
             * @param {Object} options statemanager provided backbone options object
             *      @param {jQuery|Element|String} options.el element or string selector to attach to
             */
            initialize: function(options){
                this._animId = 0;
                this._fixedOffset = null;
                this.$top = Utils.get('scrollEl');
                this.appRevealed = false;
                this.pubSub = $.extend({
                    'header:fixed': this._onHeaderFixed,
                    'header:unfixed': this._onHeaderUnfixed
                }, this.pubSub);
                // call base class initialize
                BaseView.prototype.initialize.call(this, options);
            },

            /**
             * This returns true or false depending on whether the app has successfully revealed itself
             * @return {Boolean}
             */
            isRevealed: function(){
                return this.appRevealed;
            },

            /**
             * This is a function that will decide how to animate the reveal of this app
             * @param {String} fromUrl path of the view going away
             * @param {String} toUrl path of the view we're going to
             * @param {Deferred} [requestPromise] optional Promise object that will resolve the ajax request is complete
             * @param {Deferred} [resourcePromise] optional Promise object that will resolve when the module request is complete
             * @param {Boolean} [paused] variable specifying if the view should load up in a paused state
             * @return {Deferred} promise object that will resolve when reveal is complete
             * @private
             */
            revealApp: function(fromUrl, toUrl, requestPromise, resourcePromise, paused) {
                // so we are going to find ourselves in a couple of scenarios here,
                // 1: requestPromise is null, cause it's an initial render, very little to do
                // 2: requestPromise has resolved with the html we want to render, animate directly to html
                // 3: requestPromise isn't ready, but we have an Reveal App Loader, animate in the loader, cross fade to final content!
                var requestInfo = this._buildRequestInfo(fromUrl, toUrl, requestPromise, resourcePromise, paused);
                if (!requestPromise) {
                    // scenario 1, initial load, yippy!
                    return this._handleInitialReveal(requestInfo);
                } else if (requestPromise.state() === 'rejected') {
                    return requestPromise;
                } else {
                    // handle scenarios 2 through 3
                    return this._handleAsyncReveal(requestPromise, requestInfo);
                }
            },

            /**
             * Will construct a container object used to represent all the state variables for this request
             * @param {String} fromUrl path of the view going away
             * @param {String} toUrl path of the view we're going to
             * @param {Deferred} requestPromise Promise object that will resolve the ajax request is complete
             * @param {Deferred} resourcePromise Promise object that will resolve when the module request is complete
             * @param {Boolean} [paused] variable specifying if the view should load up in a paused state
             * @returns {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}}
             * @private
             */
            _buildRequestInfo: function(fromUrl, toUrl, requestPromise, resourcePromise, paused) {
                return {
                    fromUrl: fromUrl,
                    toUrl: toUrl,
                    animId: ++this._animId,
                    resourcePromise: resourcePromise || $.Deferred().resolve(),
                    requestPromise: requestPromise || $.Deferred().resolve(),
                    modulePromise: $.Deferred(),
                    paused: paused
                };
            },

            /**
             * Handles initial page load reveal, which has no animations, merely initialization
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @return {Deferred} promise object that will resolve when reveal is complete
             * @private
             */
            _handleInitialReveal: function(requestInfo) {
                // scenario 1, initial load, yippy!
                var el = $('.site-header').next();
                if (!el.length) {
                    // handle standalone pages with no header
                    el = Utils.get('body');
                }
                this.setElement(el);
                var pageInfo = this._setPageInfo(el, requestInfo);
                return this._isCorrectApp(pageInfo.jsUrl).then(_.bind(function(){
                    if (this.$el.hasClass('js-ab-app-el')) {
                        this.$el.removeClass('js-ab-app-el');
                        this.$('.js-ab-content-el').removeClass('js-ab-content-el');
                        this.$('.js-ab-loader').remove();
                    }
                    return this._triggerAfterPageReveal(requestInfo);
                }, this));
            },

            _handleAsyncReveal: function(requestPromise, requestInfo) {
                var tempLoader;
                // we have a request which will eventually contain new html
                if (requestPromise.state() === 'resolved') {
                    // scenario 2, we have html to render immediately, go go captain planet
                    return requestPromise.then(_.bind(function(htmlFrag) {
                        return this._triggerBeforePageReveal(htmlFrag, requestInfo).then(_.bind(function(){
                            this._updateState(requestInfo);
                            return this._revealAppMarkup(htmlFrag, requestInfo).then(_.bind(function() {
                                return this._finishReveal(htmlFrag, requestInfo);
                            }, this));
                        }, this));
                    }, this));
                } else {
                    tempLoader = this.getRevealAppLoader(requestInfo.toUrl);
                    if (!(tempLoader instanceof $)) {
                        tempLoader = $(tempLoader);
                    }
                    // scenario 3, render reveal app loader
                    return this._revealAppMarkup(tempLoader, requestInfo).then(_.bind(function() {
                        this.afterLoaderReveal();
                        return requestPromise.then(_.bind(function(htmlFrag) {
                            return this._triggerBeforePageReveal(htmlFrag, requestInfo).then(_.bind(function(){
                                this._updateState(requestInfo);
                                return this._handleRevealLoaderContentSwap(htmlFrag, requestInfo).then(_.bind(function() {
                                    this.setElement(htmlFrag, false);
                                    return this._finishReveal(htmlFrag, requestInfo);
                                }, this));
                            }, this));
                        }, this));
                    }, this));
                }
            },

            _updateState: function(requestInfo) {
                if (!requestInfo.paused) {
                    StateManager.updateState();
                }
            },

            /**
             * Helper function that animates in the html markup on the page for new app reveals
             * @param {jQuery} htmlFrag jQuery object representing either the actual content to load or a temporary loader to reveal
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @return {Deferred} promise object that will resolve when reveal animation is complete
             * @private
             */
            _revealAppMarkup: function(htmlFrag, requestInfo) {
                this._insertAppMarkup(htmlFrag, requestInfo.paused);
                var animationPromise = this._safeCall('animateRevealApp', requestInfo.fromUrl, requestInfo.toUrl, requestInfo.paused);
                // incase the animateRevealApp crashes
                if (animationPromise) {
                    return TrafficCop.addAnimation(animationPromise);
                } else {
                    return $.Deferred().resolve();
                }
            },

            /**
             * Swpas the content between the temporary loader and the actual content during a reveal
             * @param {jQuery} htmlFrag jQuery object representing either the actual content to load
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @return {Deferred} promise object that will resolve when reveal animation is complete
             * @private
             */
            _handleRevealLoaderContentSwap: function(htmlFrag, requestInfo) {
                if (requestInfo.animId !== this._animId) {
                    // this is the scenario where in the middle of the reveal we have a remove
                    return $.Deferred().reject();
                }
                this.beforeLoaderRemove();
                return this.swapContent(this.$el, htmlFrag, requestInfo.paused, this.getHash(requestInfo.toUrl));
            },

            /**
             *
             * @param {jQuery} htmlFrag jQuery object representing either the actual content
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @returns {Deferred} promise object that will resolve when the entire reveal is complete
             * @private
             */
            _finishReveal: function(htmlFrag, requestInfo){
                if (requestInfo.animId !== this._animId) {
                    return $.Deferred().reject();
                }
                if (requestInfo.paused) {
                    this.setFixedPosition(this.$el.offset().top);
                }
                return this._triggerAfterPageReveal(requestInfo);
            },

            _fetchPageModules: function(pageInfo, requestInfo) {
                if (requestInfo.paused) {
                    requestInfo.modulePromise.resolve();
                } else {
                    // wait until the resource promise is done since it may load JS that we need for the page modules
                    requestInfo.resourcePromise.done(function() {
                        ResourceManager.fetchPageModules(pageInfo.js_modules).done(requestInfo.modulePromise.resolve);
                    });
                }
            },

            /**
             * inserts the app  markup in a hidden & undelegated state
             * @param {jQuery} htmlFrag the dom to be loaded
             * @param {Boolean} paused specifying whether the markup is being loaded in a paused/preloaded state
             * @private
             */
            _insertAppMarkup: function(htmlFrag, paused){
                var header = $('.site-header');
                this.setElement(htmlFrag, false);
                if (!htmlFrag.hasClass('js-ab-app-el')) {
                    htmlFrag.hide();
                    this.$el.insertAfter(header);
                }
                if (paused) {
                    this.setFixedPosition(header.outerHeight());
                }
            },

            /**
             * Triggers an internal page transition
             * @param {String} fromUrl path of the view going away
             * @param {String} toUrl path of the view we're going to
             * @param {Deferred} requestPromise Promise object that will resolve the ajax request is complete
             * @param {Deferred} resourcePromise Promise object that will resolve when the module request is complete
             * @param {Boolean} [paused] variable specifying if the view should load up in a paused state
             * @return {Deferred} promise object that will resolve when changePage is complete
             * @private
             */
            changePage: function(fromUrl, toUrl, requestPromise, resourcePromise, paused){
                var requestInfo = this._buildRequestInfo(fromUrl, toUrl, requestPromise, resourcePromise, paused);
                // destroy existing modules so new ones can be instantiated
                // this function is up for debate, when does this get called? who's responsible for calling it?
                this.destroyModules();

                if (!requestPromise) {
                    PubSub.trigger('page:beforeReveal', Utils.get('body'));
                    return this._handleChangePageNoRequest(requestInfo);
                } else {
                    return this._handleChangePageRequest(requestPromise, requestInfo);
                }
            },

            /**
             * This is used to essentually reinit the js on the page after an overlay is closed
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @return {Deferred} promise object that will resolve when the init is complete
             * @private
             */
            _handleChangePageNoRequest: function(requestInfo) {
                this._fetchPageModules(this.pageInfo, requestInfo);
                return this._triggerAfterPageReveal(requestInfo);
            },

            /**
             * Handle any preData/postData transitions and events related to changing a page for an app
             * @param {Deferred} requestPromise Promise object that will resolve the ajax request is complete
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @return {Deferred} promise object that will resolve when changePage is complete
             * @private
             */
            _handleChangePageRequest: function(requestPromise, requestInfo) {
                var animationPromise = this.animateChangePagePreData(requestInfo.fromUrl, requestInfo.toUrl);
                if (!animationPromise) {
                    // no animationPromise, hope there's a generic loader
                    this._triggerGenericLoader(requestPromise);
                }
                requestPromise = requestPromise.then(_.bind(function(htmlFrag) {
                    return this._triggerBeforePageReveal(htmlFrag, requestInfo).then(function(){
                        // on success, return original htmlFrag, otherwise echo the failure up
                        return htmlFrag;
                    });
                }, this));
                // requestPromise needs to be first in the $.when() because it contains the failure message from above
                return $.when(requestPromise, animationPromise).then(_.bind(function(htmlFrag){
                    if (!requestInfo.paused) {
                        StateManager.updateState();
                    }
                    return this._finishChangePage(htmlFrag, requestInfo);
                }, this));
            },

            /**
             * Sets up the overridable global loader for the request in progress
             * @param {Deferred} requestPromise promise representing the ajax request
             * @private
             */
            _triggerGenericLoader: function(requestPromise){
                if (requestPromise.state() === 'pending'){
                    this.activateLoader();
                    requestPromise.always(_.bind(function(){
                        this.deactivateLoader();
                    }, this));
                }
            },
            /**
             * Completes an internal page transition, triggering the appropriate events after animation is done
             * @param {jQuery} htmlFrag the actual content to be loaded
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @return {Deferred} promise that will resolve when the transition is complete
             * @private
             */
            _finishChangePage: function(htmlFrag, requestInfo) {
                if (requestInfo.animId !== this._animId) {
                    return $.Deferred().reject();
                }
                var animationPromise = this.animateChangePagePostData(requestInfo.fromUrl, requestInfo.toUrl, htmlFrag, requestInfo.paused) || $.Deferred().resolve();
                if (requestInfo.paused) {
                    this.setFixedPosition(this.$el.offset().top);
                }
                return animationPromise.then(_.bind(function() {
                    return this._triggerAfterPageReveal(requestInfo);
                }, this));
            },
            updateLayoutFlag: function() {
                if (StateManager.getPreloadedApp()) {
                    return;
                }
                var body = Utils.get('body'),
                    currentPageLayout= body.attr('data-layout') || '',
                    newPageLayout = this.pageInfo.layout_type || '';
                if (currentPageLayout !== newPageLayout) {
                    if (currentPageLayout) {
                        body.removeClass(currentPageLayout + '-layout');
                    }
                    if (newPageLayout) {
                        body.addClass(newPageLayout + '-layout');
                    }
                    body.attr('data-layout', newPageLayout);
                }
            },
            swapContent: function(fadeOut, fadeIn, immediate, hashTag) {
                if (Utils.getNested(window.site_vars, 'flags', 'disable_swap_fade') || this.isSafari5 || this.isApple || immediate) {
                    // safari and apple can't deal with the fade, so we just snap them into place
                    fadeIn.insertAfter(fadeOut);
                    fadeOut.remove();
                    return $.Deferred().resolve();
                } else {
                    return this._crossFade(fadeOut, fadeIn, hashTag);
                }
            },
            _crossFade: function(fadeOut, fadeIn, hashTag) {
                var scrollPosition = parseInt(fadeOut.css('top'), 10) || fadeOut.offset().top || 0;
                scrollPosition -= Utils.getScrollPosition();
                fadeIn.css({'z-index': 100, 'overflow': 'hidden'});
                fadeOut.css({position: 'absolute', 'z-index': 101, opacity: 1, top: scrollPosition});
                fadeIn.insertAfter(fadeOut);
                if (!hashTag || !this._scrollToHashTag(fadeIn, hashTag)) {
                    SiteManager.scrollTop(0);
                }
                return this.animate(fadeOut, 'opacity', 0, this.options.animations.fadeIn.duration).always(function() {
                    fadeIn.css({'z-index': '', 'overflow': ''});
                    fadeOut.remove();
                });
            },
            _scrollToHashTag: function(el, hashTag) {
                var offset = this._getOffset(el, hashTag);
                if (offset.top) { // why scrollTop(0) twice?
                    this.$top.scrollTop(offset.top - this.$el.offset().top);
                    return true;
                }
            },
            _getOffset: function(el, hashTag) {
                try {
                    return el.find('a[name=' + hashTag + ']').offset() || {};
                } catch (ex) {
                    console.warn('Invalid hashtag', hashTag);
                }
                return {};
            },
            pause: function() {
                this.destroyModules(false, true);
            },
            getHash: function(url) {
                var idx = url.indexOf('#');
                if (idx !== -1) {
                    return url.substring(idx + 1);
                }
                return null;
            },
            /**
             * This waits for the resource promise to be completed and calls the afterPageReveal event with the
             * correct ViewClass. This also delegates Events if the fromUrl is not null
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred, modulePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @return {Deferred} jquery promise object
             * @private
             */
            _triggerAfterPageReveal: function(requestInfo) {
                return $.when(requestInfo.resourcePromise, requestInfo.modulePromise).done(_.bind(function(siteConfigModules, prestoModules) {
                    if (requestInfo.animId !== this._animId) {
                        return;
                    }
                    if (!requestInfo.paused) {
                        PubSub.trigger('page:beforeLoad', this.pageInfo);
                        this._initPage(requestInfo.fromUrl !== null, siteConfigModules, prestoModules);
                    }
                    this._safeCall('afterPageReveal', requestInfo.fromUrl, requestInfo.toUrl, requestInfo.paused, siteConfigModules && siteConfigModules[0]);
                    this.appRevealed = true;
                    this.currentPath = requestInfo.toUrl;
                    if (!requestInfo.paused) {
                        this.trackPageLoad(this.pageInfo);
                    }
                    // Refresh Gidgits
                    if (window.GidgitsJS && window.GidgitsJS.refresh) {
                        window.GidgitsJS.refresh();
                    }
                }, this));
            },
            _safeCall: function(funcName){
                try {
                    return this[funcName].apply(this, Array.prototype.slice.call(arguments, 1));
                } catch (ex) {
                    console.error('View threw an exception on ' + funcName, (ex.stack || ex.stacktrace || ex.message));
                }
            },
            _initPage: function(ajaxLoad, siteConfigModules, prestoModules){
                if (ajaxLoad){
                    this.delegateEvents();
                    this._updatePageTitle();
                    this.updateLayoutFlag();
                }
                if (siteConfigModules && siteConfigModules.length > 1) {
                    // load resources first, then presto modules
                    this._initModules(siteConfigModules[1].concat(prestoModules || []));
                } else {
                    this._initModules(prestoModules);
                }
            },
            /**
             * Fires callback to beforePageReveal
             * @param {jQuery} htmlFrag object with the html fragment we're rendering
             * @param {{fromUrl: String, toUrl: String, animId: Number, paused: Boolean, resourcePromise: Deferred}} requestInfo an object that represents the fromUrl, toUrl, resourcePromise, animId, and paused state
             * @returns {Deferred} resolves if we can proceed to the next step, fails if we should abort
             * @private
             */
            _triggerBeforePageReveal: function(htmlFrag, requestInfo) {
                this._safeCall('beforePageReveal', requestInfo.fromUrl, requestInfo.toUrl, htmlFrag, requestInfo.paused);
                PubSub.trigger('page:beforeReveal', htmlFrag);
                var pageInfo = this._setPageInfo(htmlFrag, requestInfo);
                return this._isCorrectApp(pageInfo.jsUrl);
            },
            /**
             * Verifies if the passed in url is correct for the app we're on
             * @param {String} url
             * @returns {Deferred} resolves if it's correct, rejects if it's incorrect, will reject with a reason string and a RouteInfo object
             * @private
             */
            _isCorrectApp: function(url){
                var self = this;
                return $.Deferred(function(defer) {
                    if (url) {
                        var altInfo = RouteManager.getInfo(url);
                        if (altInfo && altInfo.app.AppClass.prototype !== Object.getPrototypeOf(self)) {
                            defer.reject('InvalidApp', altInfo);
                            return;
                        }
                    }
                    defer.resolve();
                });
            },

            _setPageInfo: function(htmlFrag, requestInfo) {
                var pageInfo;
                if (requestInfo.fromUrl || requestInfo.paused) {
                    // inactive load or ajax load, pass fragment
                    pageInfo = Utils.parsePageInfo(htmlFrag);
                } else {
                    // initial page load fetch from StateManager
                    pageInfo = StateManager.getActivePageInfo();
                }

                this.verifyPageInfo(pageInfo, requestInfo);
                this._fetchPageModules(pageInfo, requestInfo);
                if (requestInfo.requestPromise.ajaxPromise) {
                    pageInfo.headerAbVariant = requestInfo.requestPromise.ajaxPromise.getResponseHeader('X-AbVariant');
                }
                if (!requestInfo.paused) {
                    PubSub.trigger('activePageInfo:set', pageInfo);
                }
                PubSub.trigger('pageInfo:set', pageInfo);
                this.pageInfo = pageInfo;
                return pageInfo;
            },

            /**
             * Given a page info object, make certain it has default values if values are missing
             */
            verifyPageInfo: function(pageInfo, requestInfo){
                pageInfo.templatetype = pageInfo.templatetype || '';
                pageInfo.ssts = pageInfo.ssts || 'bugpages';
                pageInfo.cst = pageInfo.aws || pageInfo.cst || 'undefined';
                pageInfo.routePath = pageInfo.routePath || requestInfo.toUrl;
                return pageInfo;
            },

            /**
             * Update the current title from the pageInfo object
             * @private
             */
            _updatePageTitle: function(){
                // reset the old pageInfo object
                // this is set to broken so if setPage fails, we have a record of it being bad
                if('None' === (this.pageInfo.seotitle || 'None')) {
                    //Site_config guard
                    var display_name = Utils.getNested(window.site_vars, 'display_name');

                    if(display_name) {
                        document.title = display_name;
                    }
                } else {
                    document.title = this.pageInfo.seotitle;
                }
            },
            /**
             * This is a function that will decide how to animate the removal of this app
             * @param {String} fromUrl path of where we're transitioning from
             * @param {String} toUrl path of the view we're going to
             * @private
             * @return {Deferred} promise object that will resolve when removal is complete
             */
            removeApp: function(fromUrl, toUrl){
                this._animId++;
                this._safeCall('beforeAppRemove', fromUrl, toUrl);
                this.destroyModules(false);
                return this._triggerAnimateRemoveApp(fromUrl, toUrl).always(_.bind(function() {
                    this._safeCall('afterAppRemove', fromUrl, toUrl);
                    this._safeDestroy();
                }, this));
            },
            _triggerAnimateRemoveApp: function(fromUrl, toUrl){
                var animationPromise = this._safeCall('animateRemoveApp', fromUrl, toUrl);
                if (animationPromise) {
                    return TrafficCop.addAnimation(animationPromise);
                } else {
                    return $.Deferred().resolve();
                }
            },
            /**
             * Destroys the current app, and removes the markup, will never throw an exception
             * @private
             */
            _safeDestroy: function(){
                try{
                    this.destroy(true);
                }catch(ex){
                    console.error('View threw an exception on destroy: ', (ex.stack || ex.stacktrace || ex.message));
                    this.$el.remove();
                }
            },

            getWindowOffset: function(){
                if (this.el) {
                    return this.el.getBoundingClientRect();
                }
                return {top: 0, left: 0};
            },

            /**************************************************************
             * Action functions (animation, fixed positioning)
             **************************************************************/

            /**
             * Overridable function that can change how the current app animates in
             * @param {String} fromUrl path we're animating from
             * @param {String} toUrl path we're animating to
             * @param {Boolean} paused specifying if the web load is a preload or not
             * @return {Deferred} jQuery promise object that will resolve when reveal animation is complete
             */
            animateRevealApp: function(fromUrl, toUrl, paused){
                if (this.isApple && paused){
                    return $.Deferred().resolve();
                }else{
                    return this.show(true);
                }
            },

            /**
             * Overridable function that can change how the current view animates out
             * @param {String} fromUrl path we're animating from
             * @param {String} toUrl path we're animating to
             * @return {Deferred} Promise object that will resolve when removal animation is complete
             */
            animateRemoveApp: function(fromUrl, toUrl){
                return this.hide(true);
            },

            /**
             * animates page to page within an app
             * @param {String} fromUrl path we're leaving
             * @param {String} toUrl path we're about to go to
             * @return {Deferred|Null} a promise object that resolves when the animation is complete
             */
            animateChangePagePreData: function(fromUrl, toUrl){
                // no op, defaults to no animation
                return null;
            },

            /**
             * Overridable, Given html fragment, animates it into place
             * @param {String} fromUrl
             * @param {String} toUrl
             * @param {jQuery} htmlFrag
             * @param {Boolean} paused
             * @return {Deferred} promise object that will resolve when the animation is done
             */
            animateChangePagePostData: function(fromUrl, toUrl, htmlFrag, paused){
                var promise = this.swapContent(this.$el, htmlFrag, paused, this.getHash(toUrl));
                this.setElement(htmlFrag, false);
                return promise;
            },
            /**
             * Sets the app into a fixed position so an overlay can be placed on top of it
             * @param {Number} offset current scroll position
             * @param {Boolean} [partialCover] specifies whether the overlay is fully covering this view
             */
            setFixedPosition: function(offset, partialCover){
                if (!partialCover && (this.isSafari5 || this.isApple)){
                    this.$el.hide();
                } else {
                    this._header = SiteManager.getHeader();
                    if (!this._header) {
                        this._fixedOffset = offset;
                    } else if (this._header.isFixed()) {
                        if (this._header.isOpen()) {
                            this._fixedOffset = offset - this._header.getFixedThreshold();
                        } else {
                            this._fixedOffset = offset;
                        }
                        this.$el.css({position: 'fixed', 'top': offset});
                    } else {
                        this._fixedOffset = offset = this.$el.offset().top;
                        this.$el.css({position: 'absolute', 'top': offset});
                    }
                }
            },
            /**
             * Clears the fixed positioning of app
             * @param {Boolean} partialCover specifies whether the overlay is fully covering this view
             */
            clearFixedPosition: function(partialCover) {
                this._fixedOffset = null;
                if (!partialCover && (this.isSafari5 || this.isApple)) {
                    this.$el.show();
                } else {
                    this.$el.css({position: '', 'top': ''});
                }
            },
            _onHeaderFixed: function() {
                if (this._header && this._fixedOffset !== null) {
                    this._fixedOffset -= this._header.getFixedThreshold();
                    this.$el.css({
                        'position': 'fixed',
                        'top': this._fixedOffset
                    });
                }
            },
            _onHeaderUnfixed: function() {
                if (this._header && this._fixedOffset !== null) {
                    this._fixedOffset += this._header.getFixedThreshold();
                    this.$el.css({
                        'position': 'absolute',
                        'top': this._fixedOffset
                    });
                }
            },
            /**
             * Triggers a generic app level loader
             */
            activateLoader: function() {
            },
            /**
             * Turns off a generic app level loader
             */
            deactivateLoader: function() {
            },

            /**************************************************************
             * Event Callbacks
             **************************************************************/

            /**
             * Gets called before the transition and removal of the current app.
             * This only gets called when the current transition will trigger a removal of this app
             * @param {String} fromUrl path we're leaving
             * @param {String} toUrl path we're about to go to
             */
            beforeAppRemove: function(fromUrl, toUrl){
                //no op
            },
            /**
             * Gets called after the transition and before the removal/destruction of the current app.
             * @param {String} fromUrl path we're leaving
             * @param {String} toUrl path we're about to go to
             */
            afterAppRemove: function(fromUrl, toUrl){
                //no op
            },
            /**
             * Called before new content is animated in or revealed.
             * This can be called when this view is created, or when transitioning within the view
             * @param {String} fromUrl path we're leaving
             * @param {String} toUrl path we're about to go to
             * @param {jQuery} htmlFrag that's about to be placed on the page
             * @param {Boolean} paused variable specifying if the view should load up in a paused state
             */
            beforePageReveal: function(fromUrl, toUrl, htmlFrag, paused){
                //no op
            },
            /**
             * Called after new content has been successfully animated in or revealed.
             * This can be called when this view is created, or when transitioning within the view
             * @param {String} fromUrl path we're leaving
             * @param {String} toUrl path we're about to go to
             * @param {Boolean} paused variable specifying if the view should load up in a paused state
             * @param {Function} [PageClass] optional view class, requested by overriding getPageRequirements()
             */
            afterPageReveal: function(fromUrl, toUrl, paused, PageClass){
                if (PageClass){
                    this.subviews.page = new PageClass({
                        el: this.$el
                    });
                }
            },
            /**
             * Gets the html for a temporary app reveal loader
             * @param {String} toUrl path we're going to
             */
            getRevealAppLoader: function(toUrl){
                return '<section class="ui-loading dark-medium ui-app-loader"></section>';
            },
            /**
             * Called when an overlay is about to be removed (closed) on top of this app. Added
             * @param {String} toUrl param so that the section nav could be updated properly when overlay is
             * closed.
             */
            beforeOverlayRemove: function(toUrl){

            },
            /**
             * Called when an overlay has finished animating in (opening) over this app
             * @param {Number} offset Scroll offset top of this view
             */
            afterOverlayReveal: function(offset){

            },
            /**
             * Called after a "loader" has been appended before content has been fetched.
             */
            afterLoaderReveal: function() {

            },
            /**
             * Called before a "loader" is removed (right before content is inserted).
             */
            beforeLoaderRemove: function() {

            },
            /**
             * Overridable analytics callback on new page requests. This gets called immediately after beforePageReveal event
             */
            parsePageInfo: function(htmlFrag) {
                return StateManager.parsePageInfo(htmlFrag);
            },
            getClientInfo: function() {
                return {};
            },
            /**
             * @fires page:load
             * @param {PageInfo} pageInfo
             */
            trackPageLoad: function(pageInfo) {
                // stash the pageInfo object in case it changes before we are allowed to fire page:load
                var disableTrafficCopDeferBeforePageLoad = Utils.getNested(window.site_vars, 'flags', 'traffic_cop_defer');
                if (disableTrafficCopDeferBeforePageLoad) {
                    PubSub.trigger('page:load', pageInfo);
                } else {
                    _.defer(function() {
                        TrafficCop.getAnimationCompletion().done(function() {
                            // don't fire this until all animations are complete
                            PubSub.trigger('page:load', pageInfo);
                        });
                    });
                }
            },
            /**
             * Given a list of modules which contain a module name, optional selector, position, layoutType, and options, will construct them
             * @param {Array.<Object>} modules Page modules
             * @return {Deferred} resolves when the modules are fetched and inited
             */
            buildModules: function(modules) {
                return ResourceManager.fetchPageModules(modules).done(_.bind(this._initModules, this));
            },

            /**
             * Given a list of modules which contain the require ModuleClass, and options, will construct them
             * @param {Array.<Object>} modules
             * @private
             */
            _initModules: function(modules) {
                if (this.destroyed) {
                    return;
                }
                _.each(modules, this._initModule, this);
            },
            /**
             * Given a module object, will construct it with the correct l and layoutType and assign it to the subviews with a unique key
             * @param {Object} moduleConfig
             *      @param {String} moduleConfig.name module name
             *      @param {String} moduleConfig.layoutType module layout type/bucket designation, e.g. "NAV MODULES"
             *      @param {String} moduleConfig.position unique module key
             *      @param {String} moduleConfig.selector DOM selector
             *      @param {Object} moduleConfig.options module options from either *-siteconfig.json or Presto module config (js_options)
             * @private
             */
            _initModule: function(moduleConfig) {
                var selector = moduleConfig.selector,
                    instance = this.seenModules[moduleConfig.name] || 0;
                if (moduleConfig.position) {
                    selector = '#module-position-' + moduleConfig.position + selector + ',#module-position-' + moduleConfig.position + ' ' + selector;
                }
                this.$(selector).each(_.bind(function(idx, el) {
                    // TODO: This is a temporary solution to prevent doubling of page views on story galleries.  This
                    // TODO: can be removed once the companion stories module is refactored.
                    if (el.className.indexOf('companion-galleries') !== -1) {
                        return false;
                    }
                    try {
                        this.subviews[moduleConfig.name + instance] = new moduleConfig.ModuleClass(this._getModuleOptions(el, moduleConfig));
                    } catch (ex) {
                        console.error('failed loading module ' + moduleConfig.name, (ex.stack || ex.stacktrace || ex.message));
                    }
                    instance = instance + 1;
                }, this));
                this.seenModules[moduleConfig.name] = instance;
            },
            _getModuleOptions: function(el, moduleConfig) {
                var siteConfigOptionsId = $(el).attr('data-options-id') || 'default',
                    siteConfigModuleOptions = _.findWhere(Utils.getNested(window.site_vars, 'moduleOptions'), {
                        moduleName: moduleConfig.name
                    }),
                    siteConfigModuleOptionSet = _.find(Utils.getNested(siteConfigModuleOptions, 'optionSets'),
                        function(optionSet) {
                            return optionSet.id === siteConfigOptionsId;
                        }) || {},
                    abTest = AbTestManager.getUserTestByTarget(moduleConfig.name),
                    abTestOptions = Utils.getNested(abTest, 'userVariant', 'options') || {},
                    options = _.extend({}, siteConfigModuleOptionSet, moduleConfig.options, abTestOptions);
                if (abTest) {
                    options.abTest = abTest;
                }
                options.el = el;
                options.layoutType = moduleConfig.layoutType;
                return options;
            }
        });
    return BaseApp;
});

