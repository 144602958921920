define('modules/videos/brightcove-video',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'utils',
    'admanager',
    'managers/cachemanager',
    'pubsub',
    'modules/videos/video-transcript',
    'adLogger',
    'modules/stories/taboola-recommendations',
    'managers/autoplay'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    Utils, AdManager,
    CacheManager,
    PubSub, VideoTranscript,
    AdLogger,
    TaboolaOutbrain,
    AutoPlayManager
) {
    "use strict";
    var BrightcoveVideo = BaseView.extend({
        /**
         * @lends videos/brightcove-video.prototype
         */
        events: {
            'click .ui-video-still-image-active, .ui-video-play-btn-active': 'onClickVideoStill'
        },

        /**
         * This callback is called when the video status changes
         * @callback videos/brightcove-video~onStatusChange
         * @param e The new status event
         */

        /**
         * This callback is called continuously as video progresses
         * @callback videos/brightcove-video~onProgressChange
         * @param e The progress event
         */

        /**
         * @classdesc Provides functionality for all brightcove videos
         * @author Mark Kennedy <mdkennedy@gannett.com>
         * @constructs videos/brightcove-video
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string
         *     @param {Object} [options.video] - The object containing the video data
         *         @param {number} [options.video.id] - the experience id of the video
         *         @param {string} [options.video.url] - the video url
         *         @param {string} [options.video.cst] - the cst value (used for analytics)
         *         @param {string} [options.video.ssts_string] - the ssts value (used for analytics)
         *     @param {Object} [options.player] - The object containing the player data
         *         @param {number|string} options.player.id - the id of the player (required)
         *         @param {number|string} [options.player.width] - the width of the player
         *         @param {number|string} [options.player.height] - the height of the player
         *         @param {string} [options.player.player.name] - a human-readable identifier of the type of player
         *         @param {boolean} [options.player.autoplay] - true if video should load and play immediately upon init (must be used with the videoId option)
         *     @param {Object} [options.playerParams] - a key/value map of custom params to add to the video DOM object element on creation
         *     @param {videos/brightcove-video~onStatusChange} [options.onStatusChange] - callback that is fired when video status changes
         *     @param {videos/brightcove-video~onProgressChange} [options.onProgressChange] - callback that is fired many many times as video progresses (beware)
         *     @param {videos/brightcove-video~onClickPlay} [options.onClickPlay] - callback that is fired when a user clicks on the video still image or the play button
         *     @param {string} [options.customContainerClass] - a custom container class to add to the video element
         */
        initialize: function (options) {
            options = $.extend({
                video: this.$('.ui-video-data').eq(0).html(),
                player: this.$('.ui-video-player-data').eq(0).html(),
                brightcovePlayerParams: null,
                onStatusChange: null,
                onProgressChange: null,
                onClickPlay: null,
                customContainerClass: ''
            }, options);
            _.bindAll(this, 'onVideoStatusChange', 'onVideoProgressChange','seekVideo', 'onVideoError','destroy','playRecommendedVideo','startAutoPlay', 'updateURL');
            this.pubSub = {
                'pause:videos': this.onPauseVideo,
                'page:load': this.onPageLoad
            };
            BaseView.prototype.initialize.call(this, options);
            this.$videoStill = this.$('.ui-video-still-image');
            this.$playBtn = this.$('.ui-video-play-btn');
            this.isVideoStillActiveClassPreExisting = this.$videoStill.hasClass('ui-video-still-image-active');
            this.isPlayBtnActiveClassPreExisting = this.$playBtn.hasClass('ui-video-play-btn-active');

            // setup brightcove callback container
            if (!window.BCCallbacks) {
                window.BCCallbacks = {};
            }

            this.video = this._buildVideoData();
            this.player = this._buildPlayerData();

        },

        playerKey: 1,

        /**
         * Builds the video data and sets up some defaults if needed.
         * @returns {Object}
         * @private
         */
        _buildVideoData: function () {
            var data = this.options.video || {};
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }
            return data;
        },

        startAutoPlay: function () {
            this.playVideo();
            return true;
        },

        /**
         * playVideo - Plays the video for a given brightcove reference ID.
        */
        playVideo: function() {
            var videoData = this.getVideoData() || {},
                vidId = videoData.id,
                video_brightcoveid = videoData.brightcove_id;
            if (vidId || video_brightcoveid) {
                this.hideStill();
                this.loadPlayer().then(function (player){
                    player.getIsPlaying(function(playing){
                        if (!playing) {
                            if(vidId) {
                                player.loadVideoByReferenceID(vidId);
                            } else if (video_brightcoveid) {
                                player.loadVideoByID(video_brightcoveid);
                            }
                        } else {
                            player.pause(false);
                        }
                    });
                });
            } else {
                console.warn('cannot autoplay video: no video id was specified!');
            }
        },

        /**
         * Builds the player data and sets up some defaults if needed.
         * @returns {Object}
         * @private
         */
        _buildPlayerData: function() {
            var customData = this.options.player || {};
            if (typeof customData === 'string') {
                customData = $.parseJSON(customData);
            }
            return $.extend({width: 480, height: 270}, customData);
        },

        /**
         * Gets the current video data.
         * @returns {object}
         */
        getVideoData: function() {
            return this.video;
        },

        /**
         * Gets the current video player data.
         * @returns {object}
         */
        getPlayerData: function() {
            return this.player;
        },

        /**
         * When the page that the video player instance is on has loaded.
         */
        onPageLoad: function() {
            if (this.getPlayerData().autoplay && !this.destroyed) {
                AutoPlayManager.register('brightcove-video', this.startAutoPlay);
            }
        },

        /**
         * Loads required brightcove scripts.
         * @returns {Deferred} Returns a promise that resolves when scripts are loaded
         * @private
         */
        _loadScript: function() {
            if (!this._loadScriptPromise) {
                this._loadScriptPromise = $.Deferred(function(defer) {
                    require(['https://sadmin.brightcove.com/js/BrightcoveExperiences.js'], function () {
                        if (typeof window.brightcove !== 'undefined') {
                            defer.resolve();
                        } else {
                            console.warn('Error loading Brightcove');
                            defer.reject();
                        }
                    });
                });
            }
            return this._loadScriptPromise.promise();
        },

        /**
         * Loads the video player.
         * @returns {Deferred} Returns a promise that resolves with brightcove's VideoPlayerModule obj when done
         */
        loadPlayer: function() {
            return this._loadScript().then(_.bind(function () {
                if (!this._loadPlayerPromise) {
                    var playerKey = this.getPlayerKey();
                    if (this.video.transcript_available) {
                        this.subviews.videotranscript = new VideoTranscript({
                            el: this.$el.siblings('.ui-video-controls'),
                            seek: this.seekVideo,
                            video: this.video,
                            player: this.player
                        });
                    }
                    this._loadPlayerPromise = this._createPlayer(playerKey).then(_.bind(function (player) {
                        this.setupPlayer(player, playerKey);
                        this.$el.addClass('video-player-loaded');
                        this._player = player;
                        return player;
                    }, this));
                }
                return this._loadPlayerPromise;
            }, this));
        },

        /**
         * Sets up a player for interaction.
         * @param {Object} player - Brightcove Player object
         * @param {string} playerKey - The player's unique identifier
         */
        setupPlayer: function(player, playerKey) {
            this.setupEventListeners(player);
            // setup share button click
            this.addPlayerCallback(playerKey, 'onClickShareButton', _.bind(function () {
                this.onClickShareButton();
            }, this));
            this.addPlayerCallback(playerKey, 'onClickSearchButton', _.bind(function (e) {
                this.onClickSearchButton(e);
            }, this));

        },

        _neonEnabled: function() {
            return Utils.getNested(window.site_vars, 'flags', 'neon') && window.NeonPlayerTracker;
        },

        /**
         * Creates the video player.
         * @param {string} playerKey - Unique identifier for the player
         * @returns {Deferred} Returns a promise that resolves with the player once instantiated and ready for interaction
         * @private
         */
        _createPlayer: function(playerKey) {
            this._videoObjEl = this._buildVideoObjectHtml(playerKey);
            return $.Deferred(_.bind(function(defer){
                if (this.getPlayerData().id) {
                    this.addPlayerCallback(playerKey, this.getPlayerReadyCallbackKey(), _.bind(function (event) {
                        var experience = window.brightcove.api.getExperience(playerKey),
                            player = experience.getModule(window.brightcove.api.modules.APIModules.VIDEO_PLAYER);
                        this._triggerNeon('onTemplateReady', event);
                        defer.resolve(player);
                    }, this));
                    this.addPlayerCallback(playerKey, this.getPlayerLoadCallbackKey(), _.bind(function () {
                        var experience = window.brightcove.api.getExperience(playerKey);
                        this._triggerNeon('onTemplateLoad', experience.id);
                    }, this));
                    this.addPlayerCallback(playerKey, this.getPlayerErrorCallbackKey(), function (error) {
                        var userFacingErrorMsg = error.errorType;
                        // do not reject promise for errors that are benign and wont break things
                        if (error.code === 4) {
                            // player has no content in it, which is okay
                            userFacingErrorMsg = 'error creating video player because of ' + error.errorType;
                        } else {
                            console.warn('video player error: ' + userFacingErrorMsg);
                            defer.reject(error);
                        }
                    });
                    window.brightcove.createExperiencesPostLoad = function() {};
                    window.brightcove.createExperience(this._videoObjEl, this.$el[0], true);
                } else {
                    console.warn('error creating video player: no video player id was specified!');
                    defer.reject();
                }
            }, this));
        },

        _triggerNeon: function(functionName, experience) {
            if (this._neonEnabled() && _.isFunction(window.NeonPlayerTracker[functionName])) {
                window.NeonPlayerTracker[functionName](experience);
            }
        },

        /**
         * When the share button inside of the video player is clicked.
         */
        updateURL: function(e, url){
            if (e.hasAttribute('href') && e.href != '#') {
                var currentLink = e.href.split('?url=');
                var newLink = currentLink[0] + '?url=' + url + currentLink[1].match(/&.*$/);
                try {
                    e.href = newLink;
                } catch (err) {
                    console.log(e.href + '\n' + err + '\nfailed video url');
                }
            }
        },
        onClickShareButton: function() {
             //change to video urls
            var sidebarMessageText = $('.email-message');
            var share_url = this.video.short_url;
            _.each(
                $("[data-share-method]"), function(e){
                    this.updateURL(e, share_url);
                }, this
            );
            _.each(
                $("[data-module-name]"), function(e){
                    this.updateURL(e, share_url);
                }, this
            );
            sidebarMessageText.val(sidebarMessageText.html().split(/https:\/\/.+\//)[0] + share_url);

            // click sidebar
            StateManager.getActiveApp().$('.util-bar-btn-email').click();
        },

        onClickSearchButton: function(e){
            this.subviews.videotranscript.togglesearch(e);
        },

        /**
         * When a video is requested to be paused.
         */
        onPauseVideo: function () {
            if (this._player) {
                this._player.pause();
            }
        },

        /**
         * Adds a callback function to the global window object to be invoked later.
         * @param {string} playerKey - The player's unique identifier
         * @param {string} method - The name of the method to add
         * @param {Function} callback - The function to call when the method is called
         */
        addPlayerCallback: function(playerKey, method, callback) {
            if (!window.BCCallbacks[playerKey]) {
                window.BCCallbacks[playerKey] = {};
            }
            if (!window.BCCallbacks[playerKey][method]) {
                window.BCCallbacks[playerKey][method] = {};
            }
            window.BCCallbacks[playerKey][method] = function () {
                callback.apply(this, arguments);
            };
        },

        /**
         * Sets up the event listeners for a video player.
         * @param {Object} player - An instance of Brightcove's VideoPlayerModule
         */
        setupEventListeners: function(player) {
            this.eventListeners = {
                BEGIN: this.onVideoStatusChange,
                COMPLETE: this.onVideoStatusChange,
                PLAY: this.onVideoStatusChange,
                STOP: this.onVideoStatusChange,
                SEEK_NOTIFY: this.onVideoStatusChange,
                PROGRESS: this.onVideoProgressChange,
                ERROR: this.onVideoError,
                CHANGE: this.onVideoProgressChange
            };
            this.mediaEventApi = window.brightcove.api.events.MediaEvent;
            _.each(this.eventListeners, function(method, constant) {
                player.addEventListener(this.mediaEventApi[constant], method);
            }, this);
        },

        /**
         * Removes event listeners for a video player module.
         * @param {Object} player - An instance of Brightcove's VideoPlayerModule
         */
        removeEventListeners: function(player) {
            _.each(this.eventListeners, function (method, constant) {
                player.removeEventListener(this.mediaEventApi[constant], method);
            }, this);
        },

        /**
         * When a status for a video player changes.
         * @param {Object} e The event
         *     @param {string} e.type - event types (i.e. mediaComplete, mediaBegin, mediaPlay, mediaChange, mediaStop)
         *     @param {number} e.position - the position of the playhead on the video in seconds
         *     @param {number} e.duration - the duration of the video in seconds
         *     @param {Object} e.media - the brightcove media object that fired the event
         */
        onVideoStatusChange: function(e) {
            // mediaBegin is called only once when the video has loaded,
            // and is therefore the only way to detect when a video has loaded
            if (e.type === 'mediaBegin') {
                var eventName = 'video:load';
                StateManager.stopRefreshTimer();
                PubSub.trigger(eventName, this.getVideoEventAnalyticsData(e, eventName));
            } else if (e.type === 'mediaComplete') {
                if (this.player.gannett_continuous_endslate) {
                    if (this.shouldIncludeRecommendation() && !this.options.overrideGetRecommendations) {
                        if (this.subviews.TaboolaOutbrain && !$.isEmptyObject(this.subviews.TaboolaOutbrain.videoResponse)) {
                            this.subviews.TaboolaOutbrain.playNextRecommendation(this.player.mediatemplate);
                        }
                        else {
                            this.reset();
                        }
                    }
                }
            }
            else {
                PubSub.trigger('uotrack', e.type);
            }
            if( this.subviews.videotranscript && this.subviews.videotranscript.isTranscriptAvailable()){
                this.subviews.videotranscript.onStatusChange(e);
            }
            if (this.options.onStatusChange) {
                this.options.onStatusChange(e);
            }
        },

        /**
         * Function fired as video playhead progresses.
         * @param {Object} e - The progress event
         */
        onVideoProgressChange: function(e) {
            this.trackVideoProgressEvent(e);
            if( this.subviews.videotranscript && this.subviews.videotranscript.isTranscriptAvailable()){
                this.subviews.videotranscript.progressHighlight(e);
            }
            if (this.options.onProgressChange) {
                this.options.onProgressChange(e);
            }
        },
        /**
         * Tracks video error events
         */
        onVideoError: function(e) {
            PubSub.trigger('uotrack', 'videoError:'+ this.player.name + ':' + (e.errorType || e.code));
        },

        /**
         * Tracks a video progress event.
         * @param {Object} e The event object to evaluate
         */
        trackVideoProgressEvent: function (e) {
            var dur = e.duration,
                pos = e.position,
                percentage = Math.round(pos / dur * 100),
                url = this.video.url || e.media.customFields.gdp_canonical_url;
            if (!this.fiftypercent && percentage >= 50 && percentage < 95) {
                PubSub.trigger('vitrack', {percentage: "50%", video_url: url});
                this.fiftypercent = true;
            } else if (!this.ninetyfivepercent && percentage >= 95 && percentage < 99) {
                PubSub.trigger('vitrack', {percentage: "95%", video_url: url});
                this.ninetyfivepercent = true;
                if (this.options.overrideGetRecommendations) {
                    if (this.player.gannett_continuous_endslate) {
                        this.options.overrideGetRecommendations(e);
                    }
                }
                else {
                    if (this.shouldIncludeRecommendation() && !this.subviews.TaboolaOutbrain) {
                        this.subviews.TaboolaOutbrain = new TaboolaOutbrain({
                            'playRecommendedVideo': this.playRecommendedVideo,
                            "isContinuousEndslate": true
                        });
                        this.subviews.TaboolaOutbrain.renderEndslate(this.video.url, this.video.brightcove_id);
                    }
                }
            }
        },

        /*
        * Refresh the video obj with Taboola's new recommendation
        */

        playRecommendedVideo:function(videoresponse){
            this.destroy();
            this.subviews.nextVideo = new BrightcoveVideo({
                video: videoresponse,
                el: this.$el
            });
            this.subviews.nextVideo.playVideo();
            this.$el.parent().find('.video-desc').html(videoresponse.description+"<span class="+"credit"+">"+videoresponse.credit+"</span>");
        },

        /**
         * Builds an object HTML element to use for video instantiation.
         * @param {string} playerKey - A unique identifier for the player
         * @returns {HTMLElement} The object element
         * @private
         */
        _buildVideoObjectHtml: function(playerKey) {
            var obj = document.createElement('object'),
                playerData = this.getPlayerData(),
                videoData = this.getVideoData();
            obj.setAttribute('id', playerKey);
            obj.className = 'brightcove-video-object ' + this.options.customContainerClass;
            obj.setAttribute('style', 'width: ' + playerData.width + 'px; height: ' + playerData.height + 'px;');
            var params = $.extend({}, this.getDefaultParams(playerKey), this.options.brightcovePlayerParams);
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    var paramEl = document.createElement('param');
                    paramEl.name = key;
                    paramEl.value = params[key];
                    obj.appendChild(paramEl);
                }
            }
            if (videoData && (videoData.id || videoData.brightcove_id)){
                var paramVidPlayerEl = document.createElement('param');
                paramVidPlayerEl.name = '@videoPlayer';
                if (videoData.id){
                    paramVidPlayerEl.value =  'ref:' + videoData.id;
                }
                else {
                    paramVidPlayerEl.value =  videoData.brightcove_id;
                }
                obj.appendChild(paramVidPlayerEl);
            }
            return obj;
        },

        /**
         * Gets the default set of video params for html.
         * @param {string} playerKey - A unique identifier for the player
         * @returns {Object} Returns set of params
         */
        getDefaultParams: function(playerKey) {
            var videoData = this.getVideoData(),
                playerData = this.getPlayerData(),
                videoparams ={};

            videoparams =  {
                bgcolor: '#FFFFFF',
                playerID: playerData.id,
                width: playerData.width,
                height: playerData.height,
                autoStart: false, // we dont ever want the video to begin playing auto, we're handling it programmatically
                isVid: true,
                isUI: true,
                wmode: 'transparent',
                htmlFallback: true,
                dynamicStreaming: true,
                includeAPI: true,
                usat_hasEmbed: true,
                templateReadyHandler: 'BCCallbacks.' + playerKey + '.' + this.getPlayerReadyCallbackKey(),
                templateLoadHandler: 'BCCallbacks.' + playerKey + '.' + this.getPlayerLoadCallbackKey(),
                templateErrorHandler: 'BCCallbacks.' + playerKey + '.' + this.getPlayerErrorCallbackKey(),
                adServerURL: this._buildAdServerParam(),
                usat_linkurl: videoData.url || "",
                SSTSCode: (videoData.ssts_string || "")+'/'
            };
            if (!this.player.hidesearchcontrol && this.subviews.videotranscript && this.subviews.videotranscript.isTranscriptAvailable()){
                if (this.player.flashsearchcontrolOn){
                    videoparams.usat_searchToggled = "true";
                }
                else {
                    videoparams.usat_searchToggled = "false";
                }
                videoparams.usat_searchFunction = "function(e){setTimeout(BCCallbacks." + playerKey + ".onClickSearchButton(e), 1)}";
            }
            if(this.video.sharecontrol) {
                this.player.overridesharecontrol = false;
            }
            if (this.player.overridesharecontrol) {
                videoparams.usat_shareFunction = "function(){setTimeout(BCCallbacks." + playerKey + ".onClickShareButton(), 1)}";
            }

            return videoparams;
        },

        /**
         * Builds Ad server params for video.
         * @returns {string}
         * @private
         */
        _buildAdServerParam: function () {
            var aam = (($.cookie('aamusat') || '').match(/[0-9]+/g) || []).join(','),
                aid = $.cookie('aam_uuid'),
                cust_params = "",
                pageInfo = StateManager.getActivePageInfo(),
                videoData = this.getVideoData();
            if (pageInfo.series) {
                cust_params += "&series=" + encodeURIComponent(pageInfo.series);
            }
            if (pageInfo.topic) {
                cust_params += "&topic=" + encodeURIComponent(pageInfo.topic);
            }
            if (pageInfo.basePageType) {
                cust_params += "&pagetype=" + encodeURIComponent(pageInfo.basePageType);
            }
            cust_params += "&aam=" + aam + "&u=" + aid;

            var adServerURL = 'https://pubads.g.doubleclick.net/gampad/ads?env=vp&gdfp_req=1&impl=s&output=xml_vast2&iu=' +
                AdManager.buildAdUnit('preroll_video') + '/' +
                videoData.cst + '&sz=920x508&unviewed_position_start=1&cust_params=contentid%3D' +
                videoData.id + encodeURIComponent(cust_params) + '&cmsid=12768&url=""';

            AdLogger.logInfo('Built ad call for video, ' + videoData.id + '.', pageInfo, videoData);
            AdLogger.logInfo('Video Ad call: ' + adServerURL);

            return adServerURL;
        },

        /**
         * Gets analytics data for a video event.
         * @param {Object} e - The brightcove media (brightcove.api.data.Media)) event
         * @param {string} [name] - A custom event name
         */
        getVideoEventAnalyticsData: function (e, name) {
            var duration = e.duration,
                media = e.media,
                percentage = Math.round((e.position / duration) * 100),
                videoData = this.getVideoData(),
                playerData = this.getPlayerData();
            var data = {
                completion: percentage + '%',
                videoFulllengthUrl: media.FLVFullLengthURL,
                eventtype: name || e.type,
                videoName: media.customFields.liveVideoSubject || media.displayName,
                contentid: media.id,
                assetid: media.referenceId,
                ssts: videoData.ssts_string || media.customFields.gdp_ssts,
                cst: videoData.cst || media.customFields.gdp_cst,
                keywords: videoData.keywords || media.customFields.gdp_tags,
                videoplayername: playerData.name,
                videocontentprovider: videoData.videocontentprovider || media.customFields.gannetttracking+ '|' +media.displayName,
                contenttype:  videoData.type || 'video',
                videotranscript: (this.subviews.videotranscript && this.subviews.videotranscript.isTranscriptAvailable()) ? 'yes':'no'
            };

            if (duration < 0) {
                data.videoDuration = "";
            } else if (duration < 120) {
                data.videoDuration = "less than 2 min";
            } else {
                data.videoDuration = "greater than 2 min";
            }
            if (videoData.url){
                data.pathName = videoData.url.replace(/^.*\/\/[^\/]+/, '');
            }
            return data;
        },
        /**
         * Determines if the recommendation needs to be included or not.
         * @returns {boolean}
         */
        shouldIncludeRecommendation: function() {
            return StateManager.getActivePageInfo().section_name != 'sponsor-story' && Utils.getNested(this.video,'ssts','section') != 'sponsor-story';
        },


        /**
         * Gets the method key for video ready callback.
         * @returns {string}
         */
        getPlayerReadyCallbackKey: function () {
            return 'onTemplateReady';
        },

        /**
         * Gets the method key for video load callback.
         * @returns {string}
         */
        getPlayerLoadCallbackKey: function () {
            return 'onTemplateLoad';
        },

        /**
         * Gets the method key for video load error callback.
         * @returns {string}
         */
        getPlayerErrorCallbackKey: function () {
            return 'onTemplateError';
        },

        /**
         * Gets a unique identifier key for the player and generates a new one if one doesnt already exist.
         * @returns {string} The player key
         */
        getPlayerKey: function() {
            if (!this._playerKey) {
                BrightcoveVideo.prototype.playerKey++;
            }
            this._playerKey = 'myExperience' + BrightcoveVideo.prototype.playerKey;
            return this._playerKey;
        },

        /**
         * When the user clicks the video still image or play button.
         * @param {Event} e - The event
         */
        onClickVideoStill: function (e) {
            if (this.options.onClickPlay) {
                this.options.onClickPlay(e);
            } else {
                this.playVideo();
            }
        },

        /**
         * Hides the still image and play button.
         */
        hideStill: function() {
            this.$videoStill.removeClass('ui-video-still-image-active');
            this.$playBtn.removeClass('ui-video-play-btn-active');
        },

        /**
         * Shows the still image and play button.
         */
        showStill: function() {
            this.$videoStill.addClass('ui-video-still-image-active');
            this.$playBtn.addClass('ui-video-play-btn-active');
        },

        /**
         * Resets video to state before it was played.
         */
        reset: function() {
            var player = this._player;
            this.fiftypercent = false;
            this.ninetyfivepercent = false;
            this.showStill();
            if (player) {
                player.getIsPlaying(function (playing) {
                    if (playing) {
                        player.getVideoPosition(false, function (secs) {
                            if (secs !== 0) {
                                // playhead is not in start position!
                                player.seek(0);
                            }
                            player.pause(true);
                        });
                    }
                });
            }
        },

        /**
         * Resets the cached video object element and remove it from the DOM.
         * @private
         */
        _destroyVideoObjectElement: function() {
            if(this._videoObjEl) {
                $('#' + this.getPlayerKey()).remove();
                this._videoObjEl = null;
            }
        },

        /**
         * Seeks the video to a particular time.
         *
         */
        seekVideo: function(time){
            if(this._player) {
                this._player.seek(time);
            }
        },

        /**
         * Trashes current video player interaction instance.
         * @private
         */
        _resetPlayerModule: function() {
            if (this._player) {
                this.removeEventListeners(this._player);
                this._player = null;
            }
        },

        /**
         * Gets the pre-existing active class on the play button or video still (if there is one)
         * @param {string} CssClass - The css class to check the pre-existing active class against
         * @returns {string} Returns the active class if there is one, empty string if not
         * @private
         */
        _getPreExistingActiveClass: function(CssClass) {
            var activeClass = CssClass + '-active';
            if (this.isPlayBtnActiveClassPreExisting || this.isVideoStillActiveClassPreExisting) {
                return activeClass;
            } else {
                return '';
            }
        },

        /**
         * Destroys the view and removes event handlers and element (optionally).
         * @param {Boolean} removeEl specifies whether we should remove the el from the dom.
         * @param {Boolean} paused specifies if we're merely pausing the video instead of destroying it completely.
         */
        destroy: function(removeEl, paused) {
            StateManager.startRefreshTimer();
            if (!paused) {
                // player callbacks
                var playerKey = this.getPlayerKey();
                if (window.BCCallbacks && window.BCCallbacks[playerKey]) {
                    delete window.BCCallbacks[playerKey];
                }

                if (window.BCCallbacks && $.isEmptyObject(window.BCCallbacks)) {
                    delete window.BCCallbacks;
                }

                this._resetPlayerModule();
                this.fiftypercent = false;
                this.ninetyfivepercent = false;
                this._destroyVideoObjectElement();
                this.$el.removeClass('video-player-loaded');

                this.$videoStill.addClass(this._getPreExistingActiveClass('ui-video-still-image'));
                this.$playBtn.addClass(this._getPreExistingActiveClass('ui-video-play-btn'));
            }
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });
    return BrightcoveVideo;
});

