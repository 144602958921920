/**
 * @fileoverview Blogs-landing view.
 * @author tweiss@gannett.com (Tyler Weiss)
 */
define('blog/blog-list',[
    'jquery',
    'underscore',
    'backbone',
    'state',
    'modules/global/brightcove-video',
    'baseview'
],
function(
    $,
    _,
    Backbone,
    StateManager,
    Video,
    BaseView
) {

    'use strict';
        /**
         * View class.
         */
        var BlogListView = BaseView.extend({

            el: '.blog-list-module',

            // Events.
            events: {
                'click .button-add-content': 'showMore'
            },

            initialize: function(options){
                var _this = this;
                this.subviews = this.subviews || {};
                this.readMoreBlogsNumber = this.$('.button-add-content').data('next');
            },

            getMoreBlogs: function(nextPageNum) {
                StateManager.fetchHtml(window.location.pathname + nextPageNum + '/new').done(
                    _.bind(function(htmlFrag){
                        this.applyBlogPosts(htmlFrag);
                    }, this)
                ).fail(_.bind(function(){
                    this.$button.removeClass('loading').addClass('inactive').text('No more posts');
                }, this));
            },

            /**
             * Shows the next block of post.
             * @param {Event} event View click event.
             */
            showMore: function(event) {
                if ($(event.target).hasClass('inactive')) return false;
                this.$button = this.$('.button-add-content');
                this.$button.addClass('loading').text(this.$button.attr('data-loading'));
                this.getMoreBlogs(this.readMoreBlogsNumber);
                event.preventDefault();
            },

            applyBlogPosts: function(htmlFrag){
                var _this = this;
                var $page = htmlFrag.find('.blpm-post').removeClass('active').addClass('inactive');
                var $button = htmlFrag.find('.button-add-content');
                this.$('.blpm-all-articles').append(htmlFrag);

                this.readMoreBlogsNumber++;
                this.$button.removeClass('loading').text(this.$button.attr('data-default'));
            }

        });


        /**
         * Return view class.
         */
        return BlogListView;
    }
);

