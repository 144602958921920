/**
 * @fileoverview MonsterSearch
 * @author zaudette@gannett.com (Zac Audette)
 */
define('modules/monster-search/monster-search',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function(
    $,
    _,
    BaseView,
    Utils
    ) {
    "use strict";

    var MonsterSearch = BaseView.extend({

        events: {
            'click .monster-search-submit' : 'seachMonster'
        },

        initialize: function() {
            BaseView.prototype.initialize.call(this);
            this.$form = this.$(".seach-form");
            this.$init_url =  this.$form.attr("action");
            this.$channel =  this.$form.attr("data-nppch");
            this.$radius =  this.$form.attr("data-radius");
        },

        seachMonster: function(event) {
            var url = this.$init_url;
            var loc = this.$('.monster-search-location').val();
            var keyword = this.slugify(this.$('.monster-search-keyword').val());
            if (loc) {
                url += encodeURIComponent(loc);
            }
            if (keyword) {
                url += '&q=' + keyword;
            }
            if (this.$channel) {
                url += '&ch=' + this.$channel;
            }
            if (this.$radius) {
                url += '&rad=' + this.$radius;
            }

            this.$form.attr("action", url);
            this.$form.submit();
        },

        slugify: function(str){
            return str.toString().trim().toLowerCase().replace(/(\s+|[^\w\-]|\-\-+)/g, '-');
        }
    });

    return MonsterSearch;
}
);

