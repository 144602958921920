define('third_party_integrations/taboola/taboola-utils',[
    'jquery',
    'underscore',
    'utils',
    'pubsub',
    'api/analytics'
],
function(
    $,
    _,
    Utils,
    PubSub,
    Analytics
){
    'use strict';
    /**
     * Taboola utility functions.
     * @exports taboola-utils
     * @author Erik Kallevig <ekallevig@gannett.com>
     */
    var TaboolaUtils = function() {
        this.pubSub = {
            'analytics:click:taboola': this._onAnalyticsClick
        };
        this.start();
    };
    TaboolaUtils.prototype = {
        start: function() {
            PubSub.attach(this.pubSub, this);
        },
        stop: function() {
            PubSub.unattach(this.pubSub, this);
        },
        /**
         * Fires a taboola "notify-click" tracking event - requires data attribute 'taboola-click-pixel'
         * @param {Event} e - Click event
         * @param {Object} params
         *      @param {Boolean} [params.fromRec]
         * @private
         */
        _onAnalyticsClick: function(e, params) {
            var $link = $(e.currentTarget);
            if ($link.data('taboola-click-pixel')) {
                $.get($link.data('taboola-click-pixel'));
            }
        },
        /**
         * Appends Taboola-specific params to the href for the passed in link
         * @param {Object} link
         * @param {String} notifyClickUrl Tabola 'notify-click' url for recommendation, etc.
         */
        addLinkData: function(link, notifyClickUrl) {
            $(link).data('taboola-click-pixel', notifyClickUrl);
            Analytics.addAnalyticsToLink(link, 'taboola');
        }

    };
    return new TaboolaUtils();
});

