/* global console */
/* global define */
define('analytics/settealium',[
    'jquery',
    'underscore',
    'pubsub',
    'fwinfo',
    'utils',
    'managers/routemanager',
    'user-manager'
],
function(
    $,
    _,
    PubSub,
    FwInfo,
    Utils,
    RouteManager,
    UserManager
) {
    'use strict';
    var _curPage = {}, _queue = [];
    function _utag(method, data) {
        if (_queue) {
            _queue.push([method, data]);
        } else {
            utagtrack(method, data);
        }
    }
    function utagtrack(typ, dat) {
        for (var p in dat) {
            if (dat.hasOwnProperty(p) && dat[p]) {
                var pStr = '' + dat[p];
                if (pStr.substring(0,4) !== 'eVar' && !p.match('gupUAID|gupInsights')) {
                    dat[p]= pStr.toLowerCase();
                }
            }
        }
        window.utag.track(typ, dat);
    }
    function _buildPipes(detail) {
        //if clickName includes pipe already, sub in all pipe-delimited fields
        if (detail.indexOf("|") !== -1) {
            var notApplicable = "n/a",
                pipeSegments = [
                    _curPage.contenttype || notApplicable,
                    _curPage.section || notApplicable,
                    detail
                ];
            detail = pipeSegments.join("|");
        }
        return detail;
    }
    var SetTealium = function() {
        if (window.tealium_url) {
            this.initialize(window.tealium_url);
        }
    };
    SetTealium.prototype = {
        initialize: function(tealium_url) {
            this.data = {};
            this.pubSub = {
                'analytics:pageload:tealium': this.page_load,
                'heattrack': this.heattrack,
                'freetrialtrack': this.freetrialtrack,
                'slide:change': this.slide_change,
                'user:logout': this.clear_user_data,
                'uotrack': this.uotrack,
                'video:load': this.video_load,
                'track': this.track,
                'vitrack': this.vitrack,
                'newslettertrack': this.newslettertrack,
                'userUtil:personal_link': this.user_util_personal_link,
                'group_page_view': this.group_page_view,
                'product_name': this.product_name,
                'newslettersignupcta': this.newslettersignupcta
            };
            this.hashRegExp = /\bht=([^&]*)/;
            this.cspRegExp = /[?&]csp=([^&]*)/i;
            this.gcionidRegExp = /\bGCIONID=([^;]*)/;
            this.daysArr = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            PubSub.attach(this.pubSub, this);
            Utils.loadScript(tealium_url, 'utag').done(function(utag) {
                _.each(_queue, function(item) {
                    utagtrack(item[0], item[1]);
                });
                _queue = null;
            }).fail(_.bind(function() {
                _queue = null; // clear out the queue cache, cause we're never going to use it
                this.destroy();
            }, this));
        },
        ready: function() {
            // needed until we transition ourselves out of the old callback hack we used which has now been replaced by Utils.loadScript
        },
        destroy: function() {
            PubSub.unattach(this.pubSub, this);
        },
        /**
         * @param {Object} pageInfo
         *      @param {Object} pageInfo.prevPvParams - Params from the previous page view.
         *      @param {Object} pageInfo.abTest
         *      @param {Object} pageInfo.prevPageInfo
         * @param {String} eventType
         * @returns {Object}
         */
        relevant_data: function(pageInfo, eventType) {
            var cstList = this._getCstList(pageInfo.cst),
                sstsList = this._getSstsList(pageInfo.ssts),
                hashValue = this._getHashValue(),
                prevPvParams = pageInfo.prevPvParams || {},
                user = pageInfo.user || {},
                abTest = user.abTest || {},
                prevPageInfo = pageInfo.prevPageInfo || {},
                contributorNames,
                contributorIds;
            this.clickName = hashValue || FwInfo.getandclearsessionvalue('clickName');
            this.clickPage = FwInfo.getandclearsessionvalue('clickPage');
            // adjust contenttype for first slide in a cinematic gallery to match every subsequent slide change
            if (pageInfo.basePageType === 'gallery-cinematic' && pageInfo.contenttype === 'videopage') {
                pageInfo.contenttype = 'videopage gallery';
            }
            try {
                contributorNames = _.map(pageInfo.contributors, function(obj){ return obj.DisplayName; }).join(',');
            } catch (e){}
            try {
                contributorIds = _.map(pageInfo.contributors, function(obj){ return obj.Id; }).join(',');
            } catch (e){}
            return {
                assetid: pageInfo.assetid,
                atyponuserid: _setTealium.data.atypon_id,
                blogname: pageInfo.blogname || '',
                brandview_sponsor: pageInfo.sponsorname || '',
                byline: pageInfo.byline || '',
                category: cstList[0],
                contenttype: pageInfo.contenttype,
                content_protection_state: pageInfo.content_protection_state || '',
                contributorNames: contributorNames || '',
                contributorIds: contributorIds || '',
                cst: cstList.slice(0,3).join(':'),
                dayofweek: this._getDayOfWeek(),
                eventtype: eventType,
                gcionid: this._getGcionId(),
                halfhour: this._getHalfHour(),
                linkTrackVars: 'prop1',
                numPageViewsLeft: pageInfo.numPageViewsLeft,
                pathName: pageInfo.pathName || '',
                priority_asset: pageInfo.priority_asset || "",
                published_date: pageInfo.published_date || '',
                referrerhost: (hashValue) ? document.referrer.split('/')[2] : "",
                refreshed: pageInfo.refreshed || "",
                searchkeywords: pageInfo.searchkeywords,
                section: sstsList[0],
                ssts: sstsList.join(':'),
                subcategory: cstList.slice(0,2).join(':'),
                subsection: sstsList.slice(0,2).join(':'),
                templatetype: pageInfo.templatetype,
                topic: sstsList.slice(0,3).join(':'),
                typeofday: this._getTypeOfDay(),
                user_status: _setTealium.data.user_status || "",
                coreuserid: _setTealium.data.core_user_id || "",
                videoincluded: pageInfo.videoincluded || "no",
                taxonomykeywords: pageInfo.taxonomykeywords || '',
                gallerytitle: pageInfo.gallerytitle || '',
                galleryindex: pageInfo.galleryindex || '',
                queryparamtrack: this._getCspValue() || '',
                viralVideoDomain : "",
                videoContentProvider : "",
                videoFulllengthUrl : "",
                videoName: "",
                videoPlayerName : "",
                videoOrigin: "",
                keywords: "",
                videoDuration: "",
                milestonetrack : "",
                videotranscript: "",
                noinitialanalytics: pageInfo.noinitialanalytics || '',
                embedded: pageInfo.embedded || '',
                abTestSlot: user.abTestSlot,
                abTestId: abTest.id,
                abTestVariant: Utils.getNested(abTest, 'userVariant', 'id'),
                gupPersonaSegment: _setTealium.data.gupPersonaSegment || undefined,
                gupRootDomain: Utils.getNested(window.site_vars, 'base_url') || '',
                gupUAID: Utils.getNested(window.site_vars, 'site_code') + '-GUP' || '',
                feedStories: (pageInfo.contenttype === 'story pages') ? FwInfo.getandclearsessionvalue('feedStories') : undefined,
                // values from previous page
                clickName: this.clickName,
                clickPage: this.clickPage,
                percentage_page_viewed: FwInfo.getandclearsessionvalue('percentagePageViewed'),
                clickAbTestId: prevPvParams.abTestId,
                clickAbTestVariant: prevPvParams.abTestVariant,
                clickPinType: prevPvParams.pinType,
                prevpath: FwInfo.getandclearsessionvalue('prevpath'),
                prevAbTestId: Utils.getNested(prevPageInfo, 'user', 'abTest', 'id'),
                prevAbTestVariant: Utils.getNested(prevPageInfo, 'user', 'abTest', 'userVariant', 'id')
            };
        },
        clear_user_data: function() {
            _setTealium.data = {};
        },
        /**
         * @param {Object} detail
         *      @param {Object} detail.prevPvParams - Params from the previous page view.
         */
        page_load: function(detail) {
            var data;
            if(!detail.noinitialanalytics) {
                _curPage = data = _setTealium.relevant_data(detail, 'page:load');
                FwInfo.setsessionvalue('prevpath', document.location.pathname);
                UserManager.getCoreUserInfo().always(function(atyponData){
                    UserManager.getUserAttributes('firefly')
                    .then(function(response){
                        // Set persona segment from GUP when possible
                        _setTealium.data.gupPersonaSegment = data.gupPersonaSegment = Utils.getNested(response, 'userAttributes', 'personaSegment');
                        _setTealium.data.for_guid = data.for_guid = Utils.getNested(response, 'personalizedLinkData', 'forGuid');
                        _setTealium.data.for_guid_session = data.for_guid_session = Utils.getNested(response, 'personalizedLinkData', 'forGuidSession');
                        // Set gup insights in utag_data when found in user response
                        // This applies to anonymous users as well as logged in users
                        var gupInsights = Utils.getNested(response, 'insightsData');
                        if (gupInsights) {
                            var keys = Object.keys(gupInsights);
                            for (var i=0; i<keys.length; i++) {
                              var key = keys[i];
                              var gupInsightsKey = 'gupInsights_'+key;
                              if (typeof gupInsights[key] === 'object') {
                                  if (gupInsights[key] === null) {
                                      // If value is null then set to string 'null'
                                      gupInsights[key] = 'null';
                                  } else {
                                      continue;
                                  }
                              }
                              _setTealium.data[gupInsightsKey] = data[gupInsightsKey] = gupInsights[key].toString();
                            }
                        }
                        // Store gup user insights in local storage (default to {})
                        window.localStorage.setItem('user_insights', JSON.stringify(gupInsights || {}));
                    });

                    // Double check to make sure ClientHelpers exists before proceeding
                    if ("ClientHelpers" in window) {
                        _setTealium.data.gup_clientid = data.gup_clientid = window.ClientHelpers.getClientId();
                    }

                    // Check the Firefly account for an authorized user
                    var userAccount = UserManager.getAccount('firefly');
                    if("loggingIn" === UserManager.getLoginStatus() || "loggedIn" === UserManager.getLoginStatus()) {
                        _setTealium.data.atypon_id = data.atyponuserid = atyponData.AtyponId;
                        _setTealium.data.core_user_id = data.coreuserid = atyponData.CoreUserId;
                        _setTealium.data.user_status = data.user_status = atyponData.UserLicenseType;

                        // If they have market access, override the numPageViewsLeft with 'authorized'
                        if (Utils.getNested(userAccount, 'user', 'hasMarketAccess')) {
                            _setTealium.data.numPageViewsLeft = data.numPageViewsLeft = 'authorized';
                        }
                    } else {
                        _setTealium.data.user_status = data.user_status = 'none';
                    }

                    // Always call _utag regardless of response from GUP
                    _utag('view', data);
                });
            }
        },
        track: function(options) {
            options = options || {};
            var url = options.url,
                label = this._getLabel(options.event, options.label);
            this.setPageDepth();
            if (url && RouteManager.getInfo(url)) {
                // internal tracking
                this.heattrack(label);
            } else if (label) {
                //TODO have uotrack send the href, then we can remove the requirement of having a label for uotracking
                // external tracking
                this.uotrack(label);
            }
        },
        setPageDepth: function() {
            var percentagePageViewed = Math.floor(((Utils.getScrollPosition() + window.innerHeight) /
                document.body.clientHeight) * 100);
            FwInfo.setsessionvalue('percentagePageViewed', percentagePageViewed);
        },
        _getLabel: function(event, defaultLabel) {
            if (event) {
                var dom = $(event.currentTarget);
                return dom.data('trackLabel') || dom.data('uotrack') || dom.data('ht') || defaultLabel;
            }
            return defaultLabel;
        },
        uotrack: function(detail) {
            if (typeof detail !== 'string') {
                console.error('uotrack has been given a non-string event argument', detail);
                detail = (detail||{}).prop41 || 'somethingbad';
            }
            var vars = detail.match(/{% *[a-z0-9_]* *%}/gi);
            if (vars) {
                for (var j = 0; j < vars.length; j++) {
                    var v = vars[j];
                    var name = v.match(/{% *([a-z0-9_]*) *%}/) && RegExp.$1;
                    detail = detail.replace(v, _curPage[name]);
                }
            }
            _utag('link', {
                clickName: _buildPipes(detail),
                clickPage: location.href,
                eventtype: 'uotrack',
                linkTrackEvents: 'None',
                linkTrackVars: 'prop41,prop64'
            });
        },
        vitrack: function(detail) {
            if (typeof detail !== 'object') {
                console.error('vitrack has been given a non-object event argument', detail);
                detail = (detail||{}).prop9 || 'somethingbad';
            }
            _utag('link', {
                milestonetrack: detail.percentage,
                eventtype: 'vitrack',
                linkTrackEvents: 'None',
                linkTrackVars: 'prop9',
                video_url: detail.video_url
            });
        },
        group_page_view: function(detail) {
            _utag('link', {
                group_page: detail,
                eventtype: 'group_page_view',
                linkTrackEvents: 'None',
                linkTrackVars: 'eVar99'
            });
        },
        product_name: function(detail) {
            _utag('link', {
                product_name: detail,
                eventtype: 'product_name',
                linkTrackEvents: 'None',
                linkTrackVars: 'eVar66'
            });
        },
        freetrialtrack: function(detail) {
            _utag('link', {
                free_trial: detail,
                eventtype: 'freetrial',
                linkTrackEvents: 'event78',
                linkTrackVars: 'None'
            });
        },
        user_util_personal_link: function(detail) {
            _utag('link', {
                gup_personal_link: detail,
                eventtype: 'gup-personal-link',
                linkTrackEvents: 'None',
                linkTrackVars: 'eVar63'
            });
        },
        newslettertrack: function(fbid) {
            _utag('newsltr-signup', {
                fbid: fbid
            });
        },
        heattrack: function(clickName) {
            if (clickName) {
                clickName = _buildPipes(clickName);
                FwInfo.setsessionvalue('clickName', clickName);
            }
            FwInfo.setsessionvalue('clickPage', location.href);
        },
        slide_change: function(detail) {
            var cstList, sstsList;
            try {
                cstList = detail.cst.split('/');
                sstsList = detail.ssts.split('/');
            } catch (er) {
                console.error('Invalid page', er.stack || er.stacktrace || er.message);
                return;
            }
            _utag('view', _.extend(_curPage, {
                byline: detail.byline || "",
                clickName: FwInfo.getandclearsessionvalue('clickName'),
                clickPage: window.location.protocol + '//' + window.location.host + detail.pathName,
                contenttype: detail.contenttype,
                videoincluded: detail.videoincluded || "no",
                gallerytitle: detail.gallery_title,
                galleryindex: detail.gallery_index,
                assetid: detail.gallery_id,
                eventtype: 'slide:change',
                templatetype: '',
                category: cstList[0],
                cst: cstList.slice(0,3).join(':'),
                published_date: detail.publishedDate || '',
                refreshed:"",
                section: sstsList[0],
                ssts: sstsList.join(':'),
                subcategory: cstList.slice(0,2).join(':'),
                subsection: sstsList.slice(0,2).join(':'),
                topic: sstsList.slice(0,3).join(':'),
                pathName: detail.pathName || '',
                viralVideoDomain: "",
                videoContentProvider: "",
                videoFulllengthUrl: "",
                videoName: "",
                videoDuration: "",
                videoPlayerName: "",
                keywords:  "",
                milestonetrack: "",
                videotranscript: ""
            }));
        },
        video_load: function(detail) {
            _utag('view', _.extend(_curPage, {
                byline: detail.byline || '',
                clickName: this._getHashValue() || FwInfo.getandclearsessionvalue('clickName'),
                clickPage: FwInfo.getandclearsessionvalue('clickPage'),
                category: detail.category || '',
                cst: detail.cst || '',
                assetid: detail.assetid || '',
                published_date: detail.publishedDate || '',
                refreshed: '',
                section: detail.section || '',
                ssts: detail.ssts || '',
                subcategory: detail.subcategory || '',
                subsection: detail.subsection || '',
                topic: detail.topic || '',
                videoPlayerName: detail.videoplayername || '',
                prevpath: FwInfo.getandclearsessionvalue('prevpath'),
                completion: detail.completion || '',
                keywords: detail.keywords || '',
                taxonomykeywords: detail.keywords || '',
                contenttype: detail.contenttype || '',
                videoName: detail.videoName || '',
                videoDuration: detail.videoDuration || '',
                eventtype: detail.eventtype || '',
                pathName: detail.pathName || '',
                videoContentID: detail.contentid || '',
                viralVideoDomain: detail.viralVideoDomain || '',
                videoContentProvider: detail.videocontentprovider || '',
                templatetype: detail.templatetype || '',
                noinitialanalytics: false,
                videoincluded: "yes",
                videoFulllengthUrl: detail.videoFulllengthUrl || '',
                milestonetrack: detail.milestonetrack || '',
                videotranscript: detail.videotranscript || '',
                videoOrigin: detail.videoOrigin || ''
            }));
            FwInfo.setsessionvalue('prevpath', document.location.pathname);
        },
        newslettersignupcta: function (detail) {
            _utag('link', {
                clickName: _buildPipes(detail),
                clickPage: location.href,
                atyponuserid: _setTealium.data.atypon_id,
                user_status: _setTealium.data.user_status || "",
                eventtype: 'uotrack',
                linkTrackEvents: 'None',
                linkTrackVars: 'prop41,prop49,prop68'
            });
        },
        _getHalfHour: function() {
            var halfHour = new Date(1800000 * parseInt(new Date().getTime() / 1800000, 10)),
                h = halfHour.getHours();
            return (1 + (h + 11) % 12) + ':' + (function(h) {
                    return h.substr(h.length - 2);
                })('0' + halfHour.getMinutes()) + ( h > 11 ? ' pm' :' am');
        },
        _getHashValue: function() {
            return location.hash.match(this.hashRegExp) && RegExp.$1;
        },
        _getCspValue: function() {
            return window.location.search.match(this.cspRegExp) && RegExp.$1;
        },
        _getCstList: function(cst) {
            return cst.replace(/\/\/*$/,'').split('/');
        },
        _getSstsList: function(ssts) {
            return ssts ? ssts.split('/') : [];
        },
        _getGcionId: function() {
            return document.cookie.match(this.gcionidRegExp) && RegExp.$1;
        },
        _getDayOfWeek: function() {
            return this.daysArr[new Date().getDay()];
        },
        _getTypeOfDay: function() {
            return ((new Date().getDay() + 8) % 7 > 1) ? 'weekday' : 'weekend';
        }
    };
    var _setTealium = new SetTealium();
    return _setTealium;
});

