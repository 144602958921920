/**
 * @fileoverview Headlines List module view.
 */
define('modules/fronts/headline-list-blog',[
    'jquery',
    'underscore',
    'backbone',
    'baseview',
    'state',
    'pubsub',
    'utils'
],
function(
    $,
    _,
    Backbone,
    BaseView,
    StateManager,
    PubSub,
    Utils
) {
    'use strict';
    /**
     * Stolen, then simplified, from headline-grid.js
     */
    var HeadlineView = BaseView.extend({

        // Events.
        events: {
            'click .button-add-content': 'showMore'
        },

        initialize: function(options) {
            this.$button = this.$('.button-add-content');

            // Init if needed
            if (this.$('.headline-page.active').hasClass('placeholder')) {
                this.load();
                if (this.$('.headline-page').length < 2) {
                    // no more items to show, inactivate the show more button
                    this.$button.addClass('inactive').text('No More News');
                }
            }
        },

        showMore: function(event) {
            if ($(event.target).hasClass('inactive')) return false;

            this.$button.addClass('loading').text(this.$button.attr('data-loading'));

            var $nextPage = this.$('.headline-page.active:last').next(),
                ditemnum = $nextPage.attr("data-itemnum"),
                ditemperpage = $nextPage.attr("data-items-per-page"),
                count = Math.round(ditemnum/ditemperpage);
            this.load();
            PubSub.trigger('uotrack', Utils.getSectionPath(window.location.pathname) + 'morestories' + count);
            this.transitionMore($nextPage);

            event.preventDefault();
        },

        transitionMore: function($nextPage) {
            if (Modernizr.csstransitions) {
                $nextPage.removeClass('inactive').addClass('active');
            } else {
                $nextPage.animate({
                    'max-height': '5000px'
                }, 200, function() {
                    $(this).removeClass('inactive').addClass('active').css('max-height', '');
                });
            }
            if (this.$('.headline-page.inactive').length <= 0) {
                // no more items to show, inactivate the show more button
                this.$button.addClass('inactive').text('No More News');
            }
            this.load();
            this.$button.removeClass('loading').text(this.$button.attr('data-default'));
            PubSub.trigger('showmore:headlines');
        },

        load: function() {
            var $activePage = this.$('.headline-page.active');
            Utils.lazyLoadImage($activePage.find('img'), 'data-src', false);
            $activePage.removeClass('placeholder');
        }
    });

    return HeadlineView;
});

