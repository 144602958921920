/**
 * @fileoverview CareerBuilderSearch Apartments
 * @author mguirat@gannett.com (Marouan BEN GUIRAT)
 */
define('apps/career-builder',[
    'jquery',
    'underscore',
    'base-app',
    'utils'
],
function(
    $,
    _,
    BaseApp,
    Utils
    ) {
    /**
     * View class.
     */
    var CareerBuilder = BaseApp.extend({

         /**
         * afterPageReveal view.
         */
        afterPageReveal: function() {

            var careerBuilderOptions = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'CAREERBUILDER') || { lr : '', siteid : ''},
                lr =   careerBuilderOptions.lr,
                siteid =  careerBuilderOptions.siteid;
            this.$('a[href]').each(function(){
                var href = $(this).attr('href').toString()+'?';
                if(href.match(/careerbuilder\.com/) !== null && href.match(/careerbuilder\.com.*lr=/) === null){
                    href +=  href.search(/\?/) == -1 ? '?' : href.search(/\&|\?$/) == -1 ? '&' : '';
                    href += 'lr=' + lr + '&';
                    href += 'siteid=' + siteid;
                    href += '&utm_source=gannett&utm_medium=partner&utm_campaign=gannett-sites';
                    $(this).attr('href', href);
                 }
            });
        }

    });

    /**
     * Return view class.
     */
    return CareerBuilder;
}
);

