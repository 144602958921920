/**
 * @fileoverview Weather dropdown module (in the top nav).
 * @author erik.kallevig@f-i.com (Erik Kallevig)
 */
 
define('modules/header/site-nav-social-module',[
    'jquery',
    'underscore',
    'modules/header/site-nav-dropdown',
    'utils'
],
function(
    $,
    _,
    SiteNavDropdown,
    Utils
) {
    'use strict';

    /**
     * View class.
     */
    var SocialDropdownNavModule = SiteNavDropdown.extend({

        initialize: function() {
            this.initSocialShareButtons();
            SiteNavDropdown.prototype.initialize.apply(this);
        },

        initSocialShareButtons: function() {
            this._loadGooglePlus();
        },

        _loadGooglePlus: function() {
            Utils.loadScript('https://apis.google.com/js/plusone.js', 'gapi');
        }

    });

    return SocialDropdownNavModule;
});

