/**
 * @fileoverview Your Choice view module.
 * @author ttrayhan@gannett.com (Tim Trayhan)
 */

define('apps/your-choice',[
        'jquery',
        'underscore',
        'base-app',
        'user-manager'
    ],
    function(
        $,
        _,
        BaseApp,
        UserManager
    ) {
        'use strict';
         //Used to restore the meta viewport when we destroy
        var yourChoiceApp = BaseApp.extend({
            initialize: function(){

                var userAccount = UserManager.getAccount('firefly');
                if (userAccount) {
                    userAccount.getUserInfo().always(_.bind(function(userInfo) {
                        if (userInfo && userInfo != 'Not logged in.') {
                            this.$('.yc-right').addClass('yc-hidden');
                            this.$('.yc-left').addClass('yc-center');
                        }
                    }, this));
                }
                BaseApp.prototype.initialize.call(this);
            },
            afterPageReveal: function() {
                $('.card-container').addClass('yc-card-container');
            },
            beforeAppRemove: function() {
                $('.card-container').removeClass('yc-card-container');
            }
        });

        /**
         * Return view class.
         */
        return yourChoiceApp;
    }
);

