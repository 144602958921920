define('managers/siteconfig',[
    'jquery',
    'underscore',
    'mergedSiteConfig'
], function($, _, mergedSiteConfig) {
    'use strict';
    /**
     * SiteConfig Loading Helper Manager that will load and merge a number of site configs into one site config
     * @exports managers/siteconfig
     * @author Jay Merrifield <jmerrifiel@gannett.com>
     */
    var SiteConfig = {
        /**
         * Given a list of site configs, will return a merged copy
         * @returns {Object} merged site config
         */
        getSiteConfig: function() {
            if (!this.inited) {
                this.inited = true;
                this.setupSiteConfig(mergedSiteConfig);
            }
            return mergedSiteConfig;
        },

        setupSiteConfig: function(siteConfig) {
            if (siteConfig.require && (!_.isEmpty(siteConfig.require.paths) || !_.isEmpty(siteConfig.require.shim))) {
                require.config(siteConfig.require);
            }
            SiteConfig._replaceEnvVariables(siteConfig);
        },

        /**
         * Loads all the route info from the site config, populating the routeInfo objects
         * @returns {Deferred} resolved with array of {@link RouteInfo} objects representing all the available routes
         */
        loadRoutes: function(){
            return $.Deferred(_.bind(function(defer){
                var siteConfig = this.getSiteConfig(),
                    appPaths = _.pluck(siteConfig.apps, 'path');
                // we preload all apps so we can load them quickly, pages are lazy
                require(appPaths, _.bind(function() {
                    var args = arguments, index = 0;
                    _.each(siteConfig.apps, function(val){
                        val.AppClass = args[index++];
                    });
                    // now that we have all the apps, register the routes and start the history
                    this.urls = this._buildRoutes(siteConfig.pages, siteConfig.apps);
                    defer.resolve(this.urls);
                }, this));
            }, this));
        },

        _buildRoutes: function(pageList, appMap){
            return _.reduce(pageList, function(memo, page) {
                var app = appMap[page.appName],
                    urls = (page.hosturls && page.hosturls[location.hostname]) || page.urls;
                if (!app) {
                    if (page.appName) {
                        console.error('Invalid appName ' + page.appName + ' for Page ' + page.name);
                        page.appName = 'BaseApp';
                    }
                    app = appMap.BaseApp;
                }
                page.modules = (page.init_modules || []).concat(app.init_modules || []);
                return memo.concat(_.map(urls, function(url) {
                    var isAjax = (page.ajax === undefined && app.ajax === undefined) ||
                        (page.ajax === undefined && app.ajax === true) ||
                        page.ajax === true; // undefined is considered ajax by default, but page wins out over app
                    return {url: new RegExp(url), app: app, page: page, ajax: isAjax};
                }, this));
            }, []);
        },
        /**
         * Given a local path, will return the routeInfo site config details on what js belongs to that path
         * @param {String} path
         * @returns {RouteInfo}
         */
        getRouteInfo: function(path) {
            if (path) {
                path = path.split('?')[0].split('#')[0];
                // used for backwards compatibility with backbone's router
                if (path[0] === '/') {
                    path = path.substring(1);
                }
                path = $.trim(path);
            }
            return path !== null && _.find(this.urls, function(url) {
                return url.url.test(path);
            });
        },
        _replaceEnvVariables: function(siteConfig) {
            // environment variables only are allowed in page urls at the moment
            var originalInterpolate = _.templateSettings.interpolate;
            _.templateSettings.interpolate = /{{(.+?)}}/g;
            _.each(siteConfig.pages, function(page) {
                if (page.urls) {
                    var pageUrls = [];
                    _.each(page.urls, function(url) {
                        if (url.indexOf('{{') != -1 && url.indexOf('}}') != -1) {
                            try {
                                url = _.template(url, window.site_vars || {});
                            } catch (e) {
                                console.error("invalid url template: " + url);
                                url = undefined;
                            }
                        }
                        if (url !== undefined) {
                            pageUrls.push(url);
                        }
                    });
                    page.urls = pageUrls;
                }
            });
            _.templateSettings.interpolate = originalInterpolate;
        }
    };
    return SiteConfig;
});
