define('modules/rentalslite-ads',[
        'jquery',
        'baseview',
        'state',
        'adPosition',
        'managers/routemanager'
    ],
    function(
        $,
        BaseView,
        StateManager,
        AdPosition,
        RouteManager
    ) {

        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var RentalsLiteAds = BaseView.extend({
            layoutType : "",
            adRendered : false,
            renderAd : function (){
                var el = this.$('.js-rlad-google-ad')[0];
                if (!this.adRendered && el){
                    this.adRendered = true;
                    this.subviews.adPositionOne = this.createAdPosition(el);
                }
            },
            destroyAd : function () {
                if (this.adRendered){
                    this.subviews.adPositionOne.destroy();
                    this.adRendered = false;
                }
            },
            isSidebarModule : function () {
                return (this.layoutType === 'sidebar');
            },
            isPrimaryWellModule : function () {
                return (this.layoutType === 'primary');
            },
            createAdPosition: function (el){
                return new AdPosition({
                    el: $(el),
                    adSizes: [300,250],
                    adPlacement: $(el).data('adtag')
                });
            },
            initialize: function(options) {
                BaseView.prototype.initialize.call(this);
                this.layoutType = options.layoutType;
            },
            handleCardSizeChange: function(cardInfo) {
                if (this.isSidebarModule()){
                    if (cardInfo.sidebarAds === true){
                        this.$(".js-rlad-sidebar-ad").removeClass("rlad-hide-ad");
                        this.renderAd();
                    }else{
                        this.$(".js-rlad-sidebar-ad").addClass("rlad-hide-ad");   
                        this.destroyAd();
                    }
                }else if (this.isPrimaryWellModule()){
                    if (cardInfo.sidebarOpen === true){
                        this.destroyAd();                        
                    }else{
                        this.renderAd();
                    }
                }
                if (cardInfo.sidebarOpen === true){
                    this.$(".js-rlad-wrap").addClass("rlad-wrap-margin").removeClass("rlad-dynamic-margin");
                    this.$(".js-rlad-google-ad").removeClass("rlad-dynamic-margin");
                    if (this.isPrimaryWellModule()){
                        this.$(".js-rlad-wrap").addClass("rlad-dynamic-height");
                        this.$(".js-rlad-local").addClass("rlad-sidebar-local");
                    }
                }else{
                    this.$(".js-rlad-wrap").removeClass("rlad-wrap-margin");
                    if (this.isPrimaryWellModule()){
                        this.$(".js-rlad-wrap").removeClass("rlad-dynamic-height");
                        this.$(".js-rlad-local").addClass("rlad-primary-local").removeClass("rlad-sidebar-local");
                    }
                    this.$(".js-rlad-wrap").addClass("rlad-dynamic-margin");
                    this.$(".js-rlad-google-ad").addClass("rlad-dynamic-margin");
                }
            },
            onCardWidthChange: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            },
            renderCardInfo: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            }
        });

        /**
         * Return view class.
         */
        return RentalsLiteAds;
    }
);

