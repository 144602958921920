define('managers/routemanager',['jquery', 'underscore', 'state', 'managers/siteconfig', 'pubsub', 'utils', 'fwinfo'],
    function($, _, StateManager, SiteConfig, PubSub, Utils, FwInfo) {
        "use strict";
        /**
         * RouteManager that handles changing and changes to the page url. This manager
         * will react if ajax and pushstate are enabled/available, and trigger the correct
         * calls into statemanager
         * @requires managers/siteconfig
         * @exports managers/routemanager
         * @author Jay Merrifield <jmerrifiel@gannett.com>
         */
        var RouteManager = function(){

        };
        RouteManager.prototype = {
            baseUrl: window.location.protocol + '//' + window.location.host,
            usatBetaDomain: 'http://beta.usatoday.com',
            $canonical: $('link[rel="canonical"]'),
            /**
             * Starts routemanager, loads the routes, and fires the initial javascript event to statemanager
             * @param {Boolean} [ajax=true] whether the site should allow push state ajax navigation
             */
            start: function(ajax){
                this.started = true;
                this.ajaxEnabled = ajax;
                SiteConfig.loadRoutes().done(_.bind(function(routes){
                    if (this.started) {
                        if (ajax) {
                            Utils.get('win').on('popstate', _.bind(this._onStateChange, this));
                        }
                        Utils.get('win').on('unload', _.bind(function(){
                            FwInfo.setsessionvalue('clickPage', location.href);
                        }, this));
                        this._onStateChange();
                        console.log("Site Loaded");
                    }
                }, this));
            },
            /**
             * Given a url, will return the local path used for ajax navigation if it's a local url.
             * This function does not check if it's a registered ajax url
             * @param {String} url
             * @returns {String|Null} returns null if it's not a local path, or just the path part of the url
             */
            getLocalPath: function(url) {
                if (!url || url === '#') {
                    return null;
                } else if (!url.indexOf(this.baseUrl)) {
                    url = url.substring(this.baseUrl.length);
                } else if (!url.indexOf(this.usatBetaDomain)) {
                    url = url.substring(this.usatBetaDomain.length);
                } else if (url.indexOf('://') !== -1) {
                    return null;
                }
                // this trims off the root of the path if one exists
                return url;
            },
            stop: function(){
                this.started = false;
                Utils.get('win').off('popstate unload');
            },
            /**
             * Given a path/url, will return the routeInfo site config details on what js belongs to that url
             * @param {String} path
             * @returns {RouteInfo|Null}
             */
            getInfo: function(path) {
                var localPath = this.getLocalPath(path);
                if (localPath !== null) {
                    return SiteConfig.getRouteInfo($.trim(localPath));
                }
                return null;
            },
            /**
             * Given a path, will answer the life long question of whether that path is an ajax path or not
             * @param {String} path
             * @returns {Boolean}
             */
            isAjax: function(path){
                if (!this.ajaxEnabled){
                    return false;
                }
                var routeInfo = this.getInfo(path);
                return routeInfo && routeInfo.ajax;
            },
            /**
             * Given an path, will trigger an ajax navigation to the link if possible
             * @param {String} url url we want to ajax to
             * @param {String} [trackLabel] link label used for tracking
             * @param {MouseEvent} [event] any mouse event that goes with this trigger
             * @returns {Boolean} whether or not the url was valid and we navigated
             */
            goTo: function(url, trackLabel, event) {
                if (!Utils.isValidUrl(url) || !this._canNavigate()) {
                    return false;
                } else if (!StateManager.getIntentUrl() && this.isCurrentUrl(url)) {
                    console.warn('Tried navigating to the current url, skipping');
                    return false;
                }
                PubSub.trigger('track', {label: trackLabel, url: url, event: event});
                if (this.ajaxEnabled){
                    /*  COMSCORE custom tracking request  */
                    $.ajax({
                        url: "/comscorepvcandidate.xml",
                        headers: {
                            'Cache-Control': 'no-cache, no-store, must-revalidate, private',
                            'Surrogate-Control': 'max-age=0',
                            'Pragma': 'no-cache',
                            'Expires': '0'
                        },
                        cache: false
                    });
                }
                var navigated = this._goHashTag(url) || this._goInternal(url);
                this.$canonical.attr('href', this.baseUrl + url);
                if (!navigated) {
                    this._setLocation(this.attachChromeless(url));
                }
                // reset these on route change
                delete window.simpleExclusion;
                delete window.simpleTarget;

                return true;
            },
            /**
             * This is used when we want to inject a page between 2 urls (ads anyone?). Because this interstitual has no
             * url, we follow the following logic:
             * 1: if the user hits reload, they get the page they were intending to go to.
             * 2: if the user hits the back button they go back to the url they were at.
             * This will not work if one of the following is true
             * 1: the toUrl is invalid
             * 2: there is a cancel navigation dialog the user did not confirm
             * 3: the url we're going to isn't an ajax url
             * @param {String} toUrl we're navigating to
             * @returns {Boolean} whether or not the interstitual insertion succeeded
             */
            goToInterstitual: function(toUrl) {
                toUrl = this.getLocalPath(toUrl);
                if (Modernizr.history && Utils.isValidUrl(toUrl) && this._canNavigate() && this.getInfo(toUrl)) {
                    StateManager.setIntentUrl(toUrl);
                    this._pushState(this.attachChromeless(toUrl));
                    return true;
                }
                return false;
            },
            /**
             * Given a url, tests if it's the current url
             * @param {String} url url we want to check
             * @returns {Boolean}
             */
            isCurrentUrl: function(url) {
                var newPath = this.getLocalPath(url);
                if (newPath) {
                    // hash tags are no matter for me
                    newPath = newPath.split('#')[0];
                }
                return newPath === this._getCurrentPath();
            },
            _getCurrentPath: function(){
                return window.location.pathname + location.search;
            },
            /**
             * Used for unit testing mocking
             * @private
             */
            _setLocation: function(href){
                window.location.assign(href);
            },
            /**
             * Checks to see if it's ok to navigate away from the current page
             * @returns {Boolean}
             * @private
             */
            _canNavigate: function(){
                var navWarning = StateManager.getActivePageInfo().navigationWarning;
                if (navWarning) {
                    if (!window.confirm(navWarning)) {
                        return false;
                    }
                }
                return true;
            },
            /**
             * Detects if the href is an anchor hash tag and will scroll there automatically if it can find the named element
             * @param {String} href
             * @returns {Boolean}
             * @private
             */
            _goHashTag: function(href) {
                if (href[0] === '#') {
                    href = href.substring(1);
                    var offset = $('a[name=' + href + ']').offset();
                    if (offset && offset.top) {
                        // TODO: why are we hard coding 40?? that's bad
                        PubSub.trigger('scrollTop', offset.top - 40);
                    }
                    window.location.hash = href;
                    return true;
                }
            },
            /**
             * If window.chromeless is true, will attach chromeless=true to whatever url passed in
             * @param {String} href - url to append to
             * @returns {String}
             */
            attachChromeless: function(href) {
                if (window.chromeless) {
                    // reattach ?chromeless=true to internal urls
                    return href + ((href.indexOf('?') === -1) ? '?' : '&') + 'chromeless=true';
                }
                return href;
            },
            /**
             * Given an ajaxPath, will do an ajax navigation to the url if the browser supports it
             * @param {String} href url we want to navigate to
             * @returns {Boolean} returns true if this was an internal link
             * @private
             */
            _goInternal: function(href) {
                if (Modernizr.history && this.isAjax(href) && this.isAjax(Utils.getPageUrl())) {
                    // only allow ajax navigation if both the from and to urls are ajax
                    this._pushState(this.attachChromeless(this.getLocalPath(href)));
                    this._onStateChange();
                    return true;
                }
                return false;
            },
            _pushState: function(href) {
                window.history.pushState({}, document.title, href);
            },
            _onStateChange: function(e) {
                var routeInfo, location = window.location;
                if (location.pathname === this._currentPathName) {
                    // hashchange event's trigger pop state, so don't fire routeChange events then
                    return;
                }
                if(typeof e === 'object') {
                    this.$canonical.attr('href', this.baseUrl + location.pathname);

                    if(this._currentPathName) {
                        // pop state event happend, lets record the last page url
                        FwInfo.setsessionvalue('clickPage', this.baseUrl + this._currentPathName);
                    }
                }
                this._currentPathName = location.pathname + location.search;
                routeInfo = this.getInfo(location.pathname);
                if (routeInfo) {
                    StateManager.onRouteChange(routeInfo, location.pathname + location.search + location.hash);
                } else {
                    console.error('Could not find any site-config matching ' + location.pathname);
                }
            }
        };
        return new RouteManager();
    }
);

