/**
 * @fileoverview Firefly flyout.
 * @author Erik Kallevig <ekallevig@gannett.com>
 */
define('modules/stories/utility-bar-module-firefly',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'third-party-apis/firefly/firefly',
    'modules/stories/utility-bar-flyout',
    'adPosition',
    'state',
    'utils',
    'user-manager'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    Firefly,
    UtilityBarFlyout,
    AdPosition,
    StateManager,
    Utils,
    UserManager
) {

    "use strict";
    /**
     * View class.
     */
    var UtilityBarModuleFirefly = UtilityBarFlyout.extend({
        /**
         * Initialize view.
         */
        initialize: function(options) {
            options = $.extend({
                noFlyoutScroll: true
            }, options);
            
            _.bindAll(this, 'fireflyFlyoutAdReady');

            this.events = _.extend({},UtilityBarFlyout.prototype.events,this.events);
            this.$title = this.$('.util-bar-flyout-firefly-title');
            this.$subBtn = this.$('.util-bar-flyout-firefly-subscribe-btn');
            this.$fireflyFlyoutAd = this.$('.util-bar-flyout-firefly-ad');
            this.messaging = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'FIREFLY', 'flyoutMessageOverrides');
            this.gps_tracking = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'FIREFLY', 'gps');
            this.viewsCookie = Firefly.getFireflyViewsCookie();
            UtilityBarFlyout.prototype.initialize.call(this, options);

            //Is ad-blocker enabled? If not, make ad calls
            if(window.adsEnabled !== undefined) {
                this._buildSpecialOffersFireflyFlyoutAd();
            }
        },

        _buildSpecialOffersFireflyFlyoutAd: function() {
            var userAccount = UserManager.getAccount('firefly');
            if(!this.subviews.fireflyFlyoutAd) {
                if(userAccount){
                    userAccount.getUserInfo().done(_.bind(function (firefly) {
                        if(!firefly.hasMarketAccess){
                                this._applySpecialOffersDirectPositionAd();
                        }
                    }, this)).fail(_.bind(function() {
                            this._applySpecialOffersDirectPositionAd();
                    }, this));
                }else {
                    this._applySpecialOffersDirectPositionAd();
                }
            }
        },

       _applySpecialOffersDirectPositionAd: function() {
            this.subviews.fireflyFlyoutAd = new AdPosition({
                el: this.$fireflyFlyoutAd,
                targeting: {
                    "pwvr" : (this.viewsCookie) ? this.viewsCookie.viewsRemaining.toString() : "0"
                },
                adSizes: [264,200],
                adPlacement: 'consumer_sales-flyout',
                onAdReady: this.fireflyFlyoutAdReady
            });
        },

        fireflyFlyoutAdReady: function() {
            this.$subBtn.addClass('hidden');
            this.$fireflyFlyoutAd.removeClass('hidden');
            this.subviews.fireflyFlyoutAd.showAd();
        },

        setTitle: function(title, large) {
            this.$title.text(title);
            if (large) {
                this.$title.addClass('util-bar-flyout-firefly-title-large');
            } else {
                this.$title.removeClass('util-bar-flyout-firefly-title-large');
            }
        },

        setGPS: function(source) {
            var gpsURL = this.$subBtn.attr('href');

            gpsURL = gpsURL.replace(/(gps-source)=.*/, '$1=' + source);

            this.$subBtn.attr('href', gpsURL);
        },

        open: function() {
            // View count messaging logic.
            if (this.viewsCookie) {
                if (this.viewsCookie.viewCount === 1) {
                    this.setTitle(this.messaging.first);
                    this.setGPS(this.gps_tracking.first);
                } else if (this.viewsCookie.viewsRemaining === 0) {
                    this.setTitle(this.messaging.last);
                    this.setGPS(this.gps_tracking.last);
                } else {
                    var viewsRemaining = this.viewsCookie.viewsRemaining,
                        overrideObj = false,
                        tweenerMsg,
                        tweenerGPS;
                    //check overrides
                    overrideObj = _.find(Utils.getNested(this.messaging, 'extra_overrides'), function(obj) {
                        return obj.view_remaining === viewsRemaining;
                    });

                    if (overrideObj) {
                        tweenerMsg = overrideObj.message;
                        tweenerGPS = this.gps_tracking.custom;
                    } else {
                        tweenerMsg = this.viewsCookie.viewsRemaining > 1 ? this.viewsCookie.viewsRemaining + this.messaging.tweener : this.viewsCookie.viewsRemaining + this.messaging.tweener_singular;
                        tweenerGPS = this.viewsCookie.viewsRemaining > 1 ? this.gps_tracking.tweener : this.gps_tracking.tweener_singular;
                    }
                    this.setTitle(tweenerMsg, true);
                    this.setGPS(tweenerGPS);
                }
            }

            UtilityBarFlyout.prototype.open.call(this);
        },

        close: function() {
            PubSub.trigger('uotrack', 'UtilityBarFireflyClose');
            this.destroy();
        }

    });

    /**
     * Return view class.
     */
    return UtilityBarModuleFirefly;
});

