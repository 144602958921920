define('modules/partner/taboola-exchange',[
    'baseview'
],
function(
    BaseView
) {
    'use strict';

    return BaseView;
});
