/**
 * @fileoverview Expandable photo module view.
 * @author Jonathan Hensley
 */
define('modules/stories/expandable-photo',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'modules/myCapture/myCapture'
],
function(
    $,
    _,
    BaseView,
    Utils,
    MyCapture
) {
        /**
         * View class.
         */
        var ExpandablePhotoView = BaseView.extend({

            events: {
                'click .expandable-collapsed': 'expandPhoto',
                'click .expandable-open': 'collapsePhoto'
            },

            sized: false,
            parentHeight: false,

            /**
             * Expand the lead photo
             * Param {object} e is the object clicked
             */
            expandPhoto: function(e) {
                var $expandable = this.$('.single-photo'),
                    $caption = this.$('.image-credit-wrap'),
                    $storyHighlights = $('.story-highlights');
                //Get current div height
                this.parentHeight = this.$el.parent().height();

                if(Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'MYCAPTURE', 'ENABLED') && !this.subviews.mycapture) {
                    this.subviews.mycapture = new MyCapture({
                        el: this.$el
                    });
                }

                if(this.subviews.mycapture && $expandable.find('.js-mycapture-btn-small').length) {
                    this.subviews.mycapture.switchMyCaptureBtn(this);
                }

                //Set parent height so we don't forget it
                this.$el.parent().height(this.parentHeight);

                //Fade out Story Highlights while photo is expanded and hide captions for now
                $storyHighlights.fadeTo(200,0);
                $caption.hide();

                $expandable.animate({
                    'width' : '540px'
                }, 350, function(){
                    $caption.fadeIn(250);
                    $expandable.removeClass('expandable-collapsed').addClass('expandable-open');
                });
                
            },

            /**
             * collapse the lead photo
             * Param {object} e is the object cliked
             */
            collapsePhoto: function(e) {
                // Keep photo expanded if user clicked the mycapture button
                if ($(e.target).is('.js-mycapture-btn')) {
                    return;
                }
                var $expandable = this.$('.single-photo'),
                    $caption = this.$('.image-credit-wrap'),
                    $storyHighlights = $('.story-highlights');

                if(this.subviews.mycapture && $caption.find('.js-mycapture-btn').length) {
                    this.subviews.mycapture.switchMyCaptureBtn(this);
                }
                $caption.hide();

                $expandable.animate({
                    'width' : '180px'
                }, 350, _.bind(function(){
                    $caption.fadeIn();
                    $storyHighlights.fadeTo(500,1.0);
                    $expandable.removeClass('expandable-open').addClass('expandable-collapsed');
                }, this));
            }
        });

        /**
         * Return view class.
         */
        return ExpandablePhotoView;
    }
);

