
/**
 * @fileoverview Dropdown in the global navigation.
 * @author ekallevig@gannett.com (Erik Kallevig)
 *
 */

define('modules/header/site-nav-dropdown',[
    'jquery',
    'underscore',
    'baseview',
    'easing'
],
function(
    $,
    _,
    BaseView
) {

"use strict";

/**
 * View class.
 */
var SiteNavDropdownView = BaseView.extend({

    // Events.
    events: {
        'mouseenter': 'onMouseEnter',
        'mouseleave': 'onMouseLeave',
        'click .site-nav-dropdown': 'closeDropdown',
        'click .site-masthead-search-form-input': 'onClickSearchDropdown',
        'click .search-tr-module-input': 'onClickSearchDropdown'
    },

    /**
     * Initialize the view.
     */
    initialize: function(options) {
        _.bindAll(this, 'openDropdown', 'closeDropdown');
        this.fadeSpeed = 80;
        this.showDelay = 120;
        this.$dropdown = this.$('.site-nav-dropdown');
        this.$searchForm = this.$('.site-masthead-search-form');
        this.$searchDropdown = this.$('.site-masthead-search-form-input');
        BaseView.prototype.initialize.call(this, options);
    },


    onMouseEnter: function(e) {
        clearTimeout(this.hideTimer);
        this.isMouseOnDropdown = true;
        this.openDropdown(e);
    },

    onMouseLeave: function(e){
        this.isMouseOnDropdown = false;
        this.hideTimer = setTimeout(_.partial(this.closeDropdown, e), this.hideDelay);

        // clean up the search dropdown
        if (this.$searchDropdown) {
            this.$searchDropdown.blur();
            this.$searchDropdown.val('');
        }
    },

    /**
     * Close weather dropdown.
     */
    closeDropdown: function(e) {
        if (!this.isDropdownOpen || (e.type == 'mouseout' && this.isMouseOnDropdown)) {
            return;
        }
        this.isDropdownOpen = false;
        this.$dropdown.css('display', 'none');
        this.$el.removeClass('site-nav-active-item');
    },

    /**
     * Open weather dropdown.
     */
    openDropdown: function(e) {
        if (this.isDropdownOpen || (e.type == 'mouseover' && !this.isMouseOnDropdown)) return;
        this.isDropdownOpen = true;
        this.$el.addClass('site-nav-active-item');
        this.$dropdown.css('display', 'block');
        this.animate(this.$dropdown, 'opacity', 1, this.fadeSpeed);
    },

    /**
     * Keeps the search input in focus when the user selects it
     */
    onClickSearchDropdown: function(e) {
        e.stopPropagation();
    }

});


/**
 * Return view class.
 */
return SiteNavDropdownView;

});

