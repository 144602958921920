define('modules/fronts/pr-front',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
)
{
    'use strict';
    var GenericFrontHeader = BaseView.extend({

        initialize: function(options){
            this.prTag = $('.pr-outer-wrap');
            this.prodCdn = this.prTag.attr("data-prod-cdn");
            this.smallLogo = $('.site-nav-logo-img');
            this.largeLogo = $('.logo-link').find('img');
            
            if(window.location.href.indexOf("/pr/") > -1) {
                this.changeLogoToPR();
            }
                      
            // call base class initialize
            BaseView.prototype.initialize.call(this, options);
        },
        //changes logo to pr lgo 
        changeLogoToPR: function(){
            var prLargeLogoSrc = this.prodCdn + "appservices/site-masthead-logo@2x.png";
            var usatLargeLogo = this.largeLogo.attr('src');
            //adding original img src to data attribute
            this.largeLogo.attr('data-new-src',usatLargeLogo);         
            var prSmallLogoSrc = this.prodCdn + "appservices/site-nav-logo@2x.png";
            var usatSmallLogo = this.smallLogo.attr('src');
            this.smallLogo.attr('data-new-src',usatSmallLogo);
            
            this.largeLogo.attr('src', '');
            this.largeLogo.attr('src', prLargeLogoSrc).css({'positon':'absolute','display': 'block','height':'80px'});
            this.smallLogo.attr('src', prSmallLogoSrc);
        }

    });

    return GenericFrontHeader;

});

