define('modules/renvy-lite-search',[
  'jquery',
  'baseview',
  'managers/routemanager'
],
function(
    $,
    BaseView,
    RouteManager) {

  /**
   * View class.
   */

  var RenvyLiteSearch = BaseView.extend({
    events: {
      'submit .renvy-lite-search-form': '_searchRenvyEvent'
    },
    initialize: function(options) {
      BaseView.prototype.initialize.call(this, options);

      this.form = $('form.renvy-lite-search-form');
      this.options = {
        $city: $('.renvy-lite-input.city input'),
        $beds: $('.renvy-lite-input.beds select'),
        $minPrice: $('.renvy-lite-input.min-price select'),
        $maxPrice: $('.renvy-lite-input.max-price select')
      };

      this.errors = {
        $city: $('renvy-lite-input.city .errors')
      };

      this.rootUrl = 'http://renvy.com/search/';
    },
    addOptionsToUrl: function(url) {
      var optionValues = {
        'beds': this.options.$beds.val(),
        'min-price': this.options.$minPrice.val(),
        'max-price': this.options.$maxPrice.val()
      };

      // Add a trailing slash as needed
      if (url[url.length - 1] !== '/') url += '/';

      for (var option in optionValues) {
        if (!optionValues.hasOwnProperty(option)) continue;
        var value = parseInt(optionValues[option]);
        if (isNaN(value) || value < 0) continue;
        url += value + '-' + option + '/';
      }

      return url;
    },
    _searchRenvyEvent: function(e) {
      e.preventDefault();
      e.stopPropagation();
      this.searchRenvy();
    },
    searchRenvy: function() {
      var rootUrl = this.rootUrl;
      var city = this.options.$city.val().toLowerCase();
      if (!city) {
        this.options.$city.addClass('error');
        this.errors.$city.html('<p>Please enter a city</p>');
        return '';
      }

      var finalUrl = rootUrl + encodeURIComponent(city) + '/';
      finalUrl = this.addOptionsToUrl(finalUrl);
      RouteManager.goTo(finalUrl);
      return finalUrl;
    }
  });

  return RenvyLiteSearch;
});

