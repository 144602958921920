/*global FB:true*/
/**
 * @fileoverview Share Tools view.
 * @author Jonathan Hensley <jhensley@gannett.com>
 */
define('modules/stories/utility-bar-module-comments',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'utils',
    'modules/stories/utility-bar-flyout',
    'modules/scroller/vertical-scroll',
    'third-party-apis/facebook/facebook'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    Utils,
    UtilityBarFlyout,
    VerticalScroll,
    Facebook
) {

    var UtilityBarModuleComment = UtilityBarFlyout.extend({

        initialize: function(options) {
            this.pubSub = {
                'breakingbar:after:open': this.scalePanel
            };
            options = $.extend({
                noFlyoutScroll: true
            }, options);
            this.events = _.extend({},UtilityBarFlyout.prototype.events,this.events);
            UtilityBarFlyout.prototype.initialize.call(this, options);
        },

        /**
         * Listen for new comment to update count.
         */
        attachCommentListener: function() {
            Facebook.loadScript().done(function(){
                FB.getLoginStatus(function (response) {
                    if (response.status === 'connected') {
                        // user authenticated!
                        FB.Event.subscribe('comment.create', function () {
                            PubSub.trigger('comment:created');
                        });
                        FB.Event.subscribe('comment.remove', function () {
                            PubSub.trigger('comment:removed');
                        });
                    }
                });
            });
        },

        /**
         * Stop listening for new comments.
         */
        unattachCommentListener: function() {
            Facebook.loadScript().done(function () {
                FB.getLoginStatus(function (response) {
                    if (response.status === 'connected') {
                        // user authenticated!
                        FB.Event.unsubscribe('comment.create');
                        FB.Event.unsubscribe('comment.remove');
                    }
                });
            });
        },

        /**
         * Load Facebook Comments social plugin.
         */
        loadComments: function() {
            return Facebook.loadScript().done(_.bind(function () {
                FB.XFBML.parse(this.$scrollable[0]);
                this.scrollableHeight = this.$scrollable.height();
                this.heightPoll = setInterval(_.bind(function(){
                    if (this.scrollableHeight !== this.$scrollable.height()) {
                        this.scrollableHeight = this.$scrollable.height();
                        this.scalePanel();
                    }
                }, this), 200);
                this.$scrollWrap.css('overflow', 'auto');
            }, this));
        },

        onWindowResize: function() {
            this.scalePanel();
            UtilityBarFlyout.prototype.onWindowResize.call(this);
        } ,

        /**
         * Sets up the proper height of the panel.
         */
        scalePanel: function() {
            this.$scrollWrap.css('height', this.$win.height() - Utils.get('headerHeight') - 115);
            if (this.subviews.vscroll) this.subviews.vscroll.refresh();
        },

        open: function() {
            this.$scrollWrap = this.$('.util-bar-scroll-wrap');
            this.$scrollable = this.$('.scrollable-content');
            this.$fbPluginWrap = this.$('.facebook-comments-plugin-wrap');
            this.$win = Utils.get('win');

            this.loadComments().done(_.bind(function(){
                this.attachCommentListener();
                PubSub.trigger('uotrack', 'UtilityBarCommentsOpen');
                UtilityBarFlyout.prototype.open.call(this);
            }, this));
        },

        close: function() {
            PubSub.trigger('uotrack', 'UtilityBarCommentsClose');
            this.destroy();
        },

        /**
         * Clean up view.
         * Removes event handlers and element (optionally).
         * @param {boolean} removeEl option to also remove View from DOM.
         */
        destroy: function(removeEl) {
            clearInterval(this.heightPoll);
            this.unattachCommentListener();
            UtilityBarFlyout.prototype.destroy.call(this, removeEl);
        }
    });

    /**
     * Return view class.
     */
    return UtilityBarModuleComment;
});

