/**
 * @fileoverview Videos module for smart topic pages
 * @author Kevin Foster <kefoster@gannett.com>
 */
define('modules/topics/topics-videos',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function (
    $,
    _,
    BaseView,
    Utils
) {

    /**
     * View class.
     */
        var TopicVideos = BaseView.extend({

        events: {
            'click .tpasvm-more-link': 'onClickMore'
        },

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {
            this.$moreLink = this.$('.tpasvm-more-link');
            this.$moreWrap = this.$('.tpasvm-items-more');
            BaseView.prototype.initialize.call(this, options);
        },

        onClickMore: function(){
            if(this.$moreWrap.is(":visible")) {
                this.$moreWrap.slideUp();
                this.$moreLink.text('More Videos');
            } else {
                this.$moreWrap.slideDown();
                this.$moreLink.text("Less Videos");
                var $images = this.$moreWrap.find(".tpavm-image");
                Utils.lazyLoadImage($images, 'data-src', true);
            }
        }

    });

    /**
     * Return view class.
     */
    return TopicVideos;
});

