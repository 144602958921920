/**
 * @fileoverview Cards View.
 * @author jmerrifiel@gannett.com (Jay Merrifield)
 */
define('apps/cards/cards',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'site-manager',
    'managers/cachemanager',
    'base-app',
    'apps/cards/layouts',
    'partner/pushdown-ad',
    'partner/gravity-ad',
    'partner/overlay-ad-fronts',
    'modules/partner/paramount-ad-front',
    'partner/digital-wrap',
    'modules/partner/branded-content-tile',
    'adPosition',
    'template-manager',
    'adLogger'
],
function(
    $,
    _,
    PubSub,
    Utils,
    SiteManager,
    CacheManager,
    BaseApp,
    Layouts,
    PushdownAd,
    GravityAd,
    OverlayAdFront,
    ParamountAd,
    DigitalWrap,
    BrandedContentTile,
    AdPosition,
    TemplateManager,
    AdLogger
){
    'use strict';

    /**
     * App class.
     */
    var CardsApp = BaseApp.extend({
        // Instance variables
        cards: (window.section_arrows || 'home').split('|'),
        cardsDisplay: (window.section_arrows_display || 'home').split('|'),

        initialize: function(options){
            options = $.extend(true, {
                animations: {
                    horizontal: {
                        duration: 350,
                        easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)'
                    },
                    fadeIn: {
                        duration: 200
                    }
                },
                UPDATE_FREQUENCY: 900000, // Time in ms before checking for new data.
                keyboard: false,
                peekWidth: -100,
                template:
                    '<div class="card-wrap-<%= layoutType %> card-wrap">' +
                        '<section class="card <%= section %> card-loading" id="section_<%= section %>">' +
                            '<div class="card-loader-init"></div>' +
                            '<div class="card-loading-border"></div>' +
                        '</section>' +
                    '</div>',
                overlayTemplate:
                    '<div class="card-wrap-<%= layoutType %> card-wrap card-wrap-behind-overlay">' +
                        '<section class="card <%= section %> card-loading" id="section_<%= section %>">' +
                            '<div class="card-suspender-color <%= section %>"></div>' +
                            '<div class="card-loading-border"></div>' +
                            '<div class="sidebar"></div>' +
                        '</section>' +
                    '</div>'
            }, options);

            _.bindAll(this, 'handleResizeWindow');

            this.$top = Utils.get('scrollEl');
            this.$doc = Utils.get('doc');
            this.$body = Utils.get('body');
            this.$win = Utils.get('win');
            this.template = _.template(options.template);
            this.overlayTemplate = _.template(options.overlayTemplate);
            this.containerOffset = 0;
            this.header = SiteManager.getHeader();
            this.cardSizes = [];

            var throttledResize = _.throttle(this.handleResizeWindow, 50);
            this.$win.on('resize.' + this.cid, throttledResize);
            if (this.options.keyboard){
                // Keyboard navigation.
                this.$doc.on('keydown.' + this.cid, this.keyNavigation);
            }
            this.pbLog = Utils.getUrlParam('pb_console');
            if(this.pbLog) console.log('CARDS: Init ----------------------', Date.now());
            // call base class initialize
            if (Utils.flag('lazy_front_bft_lb')) {
                this.pubSub = {
                    'exited:view:frontBftLbAd': this.onBftLbAdExitView,
                    'entered:view:frontBftLbAd': this.onBftLbAdEnterView
                };
            }
            BaseApp.prototype.initialize.call(this, options);
        },

        destroy: function(removeEl){
            this.$win.off('.' + this.cid);
            this.$doc.off('.' + this.cid);
            this.$container.css('background', 'none');

            // call base class destroy
            BaseApp.prototype.destroy.call(this, removeEl);
        },
        activateLoader: function() {
            var header = $('.site-header'),
                loader = this.getRevealAppLoader(),
                newCard = loader.find('.card-wrap'),
                currentCard = this.$currentCard;
            this.$currentCard = newCard;
            currentCard.after(newCard);
            currentCard.remove();
            this.header.scrollTop(0);
        },
        animateChangePagePreData: function(fromUrl, toUrl){
            var sectionName = Utils.getSectionPath(toUrl);
            this._updateNav(toUrl, sectionName);
            if (Utils.getNested(window.site_vars, 'flags', 'disable_front_animations')) {
                this.afterLoaderReveal();
                SiteManager.scrollTop(0);
                return false;
            }
            var promise = this.goTo(sectionName);
            this.$container.css('background', 'none');
            if (!promise && this.currentPath !== toUrl) {
                var $blankCard = this.createAndInitializeEmptyCard(sectionName, this.$win.height()),
                    scrollPosition = Utils.getScrollPosition();

                scrollPosition -= SiteManager.scrollTop(0);

                // non-iOS devices can do our scrolling trick by faking the top of the current card after a scrollTop(0)
                this.$currentCard.children('.card').css({top: -1 * scrollPosition });
                $blankCard.css({position: 'relative', margin: '', left: this.containerOffset * -1});
                this.$currentCard.css({left: this.leftMargin + this.containerOffset * -1});
                promise = this.swapContent(this.$currentCard, $blankCard);
                this.$currentCard = $blankCard;
            }
            return promise;
        },
        animateChangePagePostData: function(fromUrl, toUrl, htmlFrag, paused) {
            // current card should be the loader we want to swap out
            var currentCard = this.$currentCard,
                newCard = htmlFrag.find('.card-wrap');
            newCard.css({left: this.containerOffset * -1});
            currentCard.css({height: '', overflow: '', 'padding-right': '', left: this.leftMargin + this.containerOffset * -1});
            // move the current card pointer incase someone navigates while swapContent is happening
            this.$currentCard = newCard;
            this.beforeLoaderRemove();
            return this.swapContent(currentCard, newCard, false, this.getHash(toUrl));
        },
        animateRevealApp: function(fromUrl, toUrl, preload){
            if (preload) {
                this.afterOverlayReveal(0, toUrl);
                return $.Deferred().resolve();
            } else {
                return BaseApp.prototype.animateRevealApp.apply(this, arguments);
            }
        },
        beforePageReveal: function(fromUrl, toUrl, htmlFrag, preload){
            if (preload){
                // hide the preload, we have our own temporary cards shown
                htmlFrag.css('display', 'none');
            }
        },
        afterPageReveal: function(fromUrl, toUrl, paused, ViewClass){
            if (!this.domInitialized) {
                this.initializeDom();
            }
            var sectionName = Utils.getSectionPath(toUrl),
                pageInfo = this.pageInfo;
            if (Utils.getNested(pageInfo, 'bg_image')) {
                this.$container.css({
                    'background-image': 'url(' + pageInfo.bg_image +')',
                    'background-size': 'cover'
                });
            }
            this.cardSizes = Layouts[Utils.getNested(pageInfo, 'layout_type')] || [];
            this.calculateCardDimensions(this.$win.width());
            this._updateNav(toUrl, sectionName);
            this.$currentCard = this.$container.children('.card-wrap');
            this.currentIndex = _.indexOf(this.cards, sectionName);

            if (!paused){
                TemplateManager.getContentOverride(this.$currentCard, pageInfo);
                if (ViewClass){
                    this.subviews.view = new ViewClass({el: this.$currentCard});
                }
                this.setupAds();
                this.setupSourcePoint();
                this.triggerEvent('renderCardInfo', this.currentCardInfo, this.isCurrentCardBumped());
            }
            CacheManager.setValue('last_front', Utils.getNested(pageInfo, 'sub_section_name') || Utils.getNested(pageInfo, 'section_name'), 0);
            PubSub.trigger('cards:loaded', this.$currentCard, this.pageInfo, paused);
        },
        setupAds: function(){
            AdLogger.logInfo('START AD SETUP');
            var $highImpactEl = this.$('.partner-billboard-ad'),
                $cards = this.$currentCard;
            if(this.pbLog) console.log('CARDS: Setup ads -----------------', Date.now());

            // Branded Content Tile
            if(Utils.getNested(window.site_vars, 'ADS', 'branded_content_tile', 'enabled') === true && Utils.getNested(this.pageInfo, 'layout_type') != 'front-list-sidebar-page') {
                this.subviews.bcTile = new BrandedContentTile({
                    el: this.$('.partner-tile-ad'),
                    layout : this.currentCardInfo.layout
                });
            }

            if(!this._hasBigHeadline()) {
                //default ad position
                this.subviews.billboardAd = new AdPosition({
                    el: $highImpactEl,
                    adPlacement : 'high_impact',
                    adSizes: Utils.getNested(window.site_vars, 'ADS', 'front_billboard_ad_sizes') || ['billboard'],
                    adType: ['billboard'],
                    slot: $highImpactEl
                });
                this.subviews.pushdownAd = new PushdownAd({
                    el: this.$('.partner-pushdown-ad'),
                    adClasses: _.pluck(this.cardSizes, 'adClass').join(' '),
                    cards: $cards,
                    slot : $highImpactEl
                });
                this.subviews.gravityAd = new GravityAd({
                    el: this.$('.partner-gravity-ad'),
                    contentSection : this.$el,
                    cards: $cards,
                    slot: $highImpactEl
                });
                this.subviews.overlayAd = new OverlayAdFront({
                    el: this.$('.partner-overlay'),
                    cards: $cards,
                    isCardBumped: this.isCurrentCardBumped(),
                    getHeaderOffset: _.bind(function(){
                        return this.header.getExpandedHeight();
                    }, this),
                    slot: $highImpactEl
                });
                this.subviews.paramountAd = new ParamountAd({
                    el: $highImpactEl,
                    adPlacement: 'high_impact',
                    slot: $highImpactEl
                });

                if(Utils.getNested(window.site_vars, 'ADS', 'digital_wrap') || Utils.getUrlParam('dw_debug')) {
                    this.subviews.DigitalWrap = new DigitalWrap({
                        el: $highImpactEl,
                        slot: $highImpactEl,
                        container: this.$el,
                        backgroundEl: this.$body
                    });
                    this.subviews.sponsorLogo = new AdPosition({
                        el: this.$('.partner-sponsor-logo'),
                        adType : 'Sponsor Logo',
                        adSizes : [800, 40],
                        adPlacement : 'sponsor_logo'
                    });
                }
            }
            if (!Utils.flag('lazy_front_bft_lb')) {
                this.initLeaderboardBtfAd();
            }

            // Generic Ad Call
            this.subviews.genericad = new AdPosition({
                el: $('#ad-staging'),
                adPlacement: 'high_impact',
                adSizes: ['generic'],
                adType: 'generic',
                slot : $highImpactEl
            });

            PubSub.trigger('advertising:defined');
        },
        initLeaderboardBtfAd: function() {
            // Below content leaderboard
            if(Utils.getNested(window.site_vars, 'ADS', 'front_leaderboard_enabled')) {
                this.subviews.leaderboardAd = new AdPosition({
                    el: this.$('.partner-leaderboard-front'),
                    adType : 'leaderboard',
                    adSizes : ['leaderboard'],
                    adPlacement : 'leaderboard_btf'
                });
            }
        },
        onBftLbAdEnterView: function(el) {
            if (!this.subviews.leaderboardAd) {
                this.initLeaderboardBtfAd();
            }
        },
        onBftLbAdExitView: function(el) {
            if (this.subviews.leaderboardAd) {
                this.subviews.leaderboardAd.destroy();
            }
        },
        _hasBigHeadline: function() {
            var modules = Utils.getNested(this.pageInfo, 'js_modules');

            if(!modules) return false;

            return _.find(modules, function(module) {
                if(module.name === 'big-headline') {
                    return true;
                }
            });
        },
        isCurrentCardBumped: function() {
            return this.$currentCard.children('.card-bumped').length;
        },
        beforeAppRemove: function(fromUrl, toUrl){
            $('.site-nav span.site-nav-span').removeClass('site-nav-active-span');
        },
        getRevealAppLoader: function(toUrl){
            var existingLoader = $('.js-ab-app-el');
            if (existingLoader.length) {
                return existingLoader;
            }
            var sectionName = Utils.getSectionPath(toUrl);
            var temp = this.createAndInitializeEmptyCard(sectionName, this.$win.height());
            temp.css({position: 'relative', margin: ''});
            temp = $('<div class="card-container"></div>').append(temp);
            temp = $('<article id="cards" class="cards"></article>').append(temp);
            return temp;
        },
        afterLoaderReveal: function() {
            this.loaderTimeout = setTimeout(_.bind(function() {
                var loading = this.$('.card-loader-init');
                if (loading.length > 0) {
                    loading.removeClass('card-loader-init').addClass('card-circle-loader ui-circle-loader');
                }
            }, this), 200);
        },

        beforeLoaderRemove: function() {
            clearTimeout(this.loaderTimeout);
        },
        initializeDom: function(){
            // Cache selectors
            this.$container = this.$('.card-container');
            this.$currentCard = this.$container.children('.card-wrap');
            this.$prevBtn = this.$('#cards-prev-link');
            this.$nextBtn = this.$('#cards-next-link');
            this.buttonWidth = this.$prevBtn.width();
            this.buttonsVisible = false;

            this.domInitialized = true;
        },

        handleResizeWindow: function(){
            if (this.domInitialized){
                var windowWidth = this.$win.width();
                if (this.windowWidth === windowWidth){
                    return false;
                }
                var oldCardInfo = this.currentCardInfo;
                this.calculateCardDimensions(windowWidth);
                if (this.currentCardInfo !== oldCardInfo) {
                    // card sizing has changed
                    // so pass in whether we're destroying sidebar, or creating the sidebar
                    // (ie, going to min card width, or going from min card width
                    this.triggerEvent('onCardWidthChange', this.currentCardInfo);
                }
            }
        },

        calculateCardDimensions: function(windowWidth){
            var minSize = this.getMinCardInfo();
            if (windowWidth < minSize.windowWidth){
                windowWidth = minSize.windowWidth;
            }
            this.windowWidth = windowWidth;
            var cardInfo = minSize;

            var hasSidebar = this._hasSidebar();
            // IE is a bastard, I hate it. IE8 reports media query, but it doesn't work for min-width
            var mediaQuery = (window.matchMedia || Modernizr.mq('only all')) && !this.$top.hasClass('lt-ie9');

            _.find(this.cardSizes, function(item) {
                if (hasSidebar) {
                    if (mediaQuery) {
                        // media query standard browsers report the width including the scrollbar
                        // but $win.width() reports minus scrollbar, so this makes certain our cardWidth
                        // is accurate
                        if (Modernizr.mq('only screen and (min-width: ' + item.windowWidth + 'px)')) {
                            cardInfo = item;
                            return true;
                        }
                    } else {
                        if (windowWidth >= item.windowWidth) {
                            cardInfo = item;
                            return true;
                        }
                    }
                } else {
                    cardInfo = minSize;
                    return true;
                }
            });
            this.cardWrapWidth = cardInfo.cardWidth + ((windowWidth - 2 * this.options.peekWidth - cardInfo.cardWidth) / 2 + 0.5) >> 0;
            if (this.cardWrapWidth < minSize.cardWidth){
                this.cardWrapWidth = minSize.cardWidth;
            }
            this.leftMargin = (((windowWidth - cardInfo.cardWidth - 2 * this.options.peekWidth) / 2) +
                this.options.peekWidth + 0.5) >> 0; // round up
            this.currentCardInfo = cardInfo;
            this._shouldShowBtns(cardInfo.cardWidth, windowWidth);
            return cardInfo;
        },

        _hasSidebar: function() {
            return this.$('.sidebar').length > 0;
        },

        _shouldShowBtns: function(cardWidth, windowWidth){
            var shouldShowButtons = ((this.buttonWidth * 2) + cardWidth) < windowWidth;
            if (shouldShowButtons !== this.buttonsVisible) {
                if (shouldShowButtons) {
                    // we use visibility so on init we can ask the buttons their width
                    this.$nextBtn.css('visibility', 'visible');
                    this.$prevBtn.css('visibility', 'visible');
                } else {
                    this.$nextBtn.css('visibility', 'hidden');
                    this.$prevBtn.css('visibility', 'hidden');
                }
                this.buttonsVisible = shouldShowButtons;
            }
        },

        updateNavLinks: function(btn, href) {
            href = (href === 'home') ? '/' : '/' + href + '/';
            btn.attr('href', href);
        },

        /**
         * Keyboard navigation (left / right arrows).
         * @param {Event} e Keyboard event.
         */
        keyNavigation: function(e) {
            switch(e.keyCode) {
                // Left arrow.
                case 37:
                    this.previous();
                    break;
                // Right arrow.
                case 39:
                    this.next();
                    break;
                default:
                    break;
            }
        },

        /**
         * Check if index is a) numeric and b) within bounds.
         * @param {Number} n Number to compare.
         * @param {Number} l Max length.
         */
        inBounds: function(n, l) {
            return (!isNaN(parseFloat(n, 10)) &&
                isFinite(n) && n >= 0 && n <= (l - 1));
        },

        /**
         * Animate to a card.
         * @param {String} targetId The id of the target card.
         */
        goTo: function(targetId) {
            // Target index.
            var targetIndex = _.indexOf(this.cards, targetId);

            // Check the bounds.
            if(targetIndex === -1 || this.currentIndex === -1 || targetIndex === this.currentIndex) {
                return;
            }

            var scrollPosition = Utils.getScrollPosition();

            if (this.isApple && scrollPosition > 0){
                // iOS devices can't do the fancy scroll trick we do to trick the user
                // into not believing they aren't scrolling to the top of the window when they
                // actually are. So we need to animate the scroll to the top
                return $.Deferred(_.bind(function(defer){
                    this.$top.animate({scrollTop: 0}, 200).promise().done(_.bind(function(){
                        this._animateCards(targetIndex, scrollPosition).done(function(){
                            defer.resolve();
                        });
                    }, this));
                }, this)).promise();
            }else{
                return this._animateCards(targetIndex, scrollPosition);
            }
        },

        _animateCards: function(targetIndex, scrollPosition) {
            // Ready to animate.
            var nextCard, distance = 0, tempCardList = $([]),
                height = this.$win.height(),
                currentCard = this.$currentCard,
                currentIndex = this.currentIndex,
                currentCardHeight = this.$currentCard.height(),
                currentCardOffsetTop = currentCard.offset().top,
                direction = 0;

            // pick a direction
            if (Math.abs(targetIndex - currentIndex) > (this.cards.length / 2)) {
                // flip around
                direction = targetIndex > currentIndex ? -1 : 1;
            } else {
                direction = targetIndex > currentIndex ? 1 : -1;
            }
            // create temporary cards
            do {
                currentIndex = this._incrementIndex(currentIndex, direction);
                nextCard = this.createAndInitializeEmptyCard(this.cards[currentIndex], height);
                if (currentIndex !== targetIndex) {
                    tempCardList.push(nextCard[0]);
                }
                this._positionCard(nextCard, (++distance) * direction);
                this.$container.append(nextCard);
            } while (currentIndex !== targetIndex);
            // reset internal variables
            this.$currentCard = nextCard;
            this.containerOffset -= distance * direction * this.cardWrapWidth;

            if (scrollPosition > currentCardHeight + currentCardOffsetTop - height){
                currentCard.css({height: height, overflow: 'hidden'});
            }

            scrollPosition -= SiteManager.scrollTop(0);

            // fake scroll to top
            if (!this.isApple) {
                // non-iOS devices can do our scrolling trick by faking the top of the current card after a scrollTop(0)
                currentCard.children('.card').css({top: -1 * scrollPosition });
            }

            var promise = this.animate(this.$container,
                                        'left',
                                        this.containerOffset + 'px',
                                        this.options.animations.horizontal.duration,
                                        this.options.animations.horizontal.easing);
            promise.done(_.bind(function() {
                this.afterLoaderReveal();
                nextCard.css('position', 'relative');
            }, this)).always(function() {
                currentCard.remove();
                tempCardList.remove(); // remove temp cards
            });
            return promise;
        },

        _incrementIndex: function(index, amount) {
            return (this.cards.length + index + amount) % this.cards.length;
        },

        createAndInitializeEmptyCard: function(section, height){
            var $blankCard = $(this.template({
                    section: section,
                    layoutType: Utils.getNested(this.pageInfo, 'layout_type') || 'primary-suspender-sidebar-page'
                }));
            $blankCard.css({'margin': 0, position: 'absolute', height: height, bottom: 0});
            return $blankCard;
        },

        _positionCard: function(targetDom, targetPosition){
            targetDom.css({left: (this.leftMargin - this.containerOffset + (targetPosition * this.cardWrapWidth))});
        },

        getMinCardInfo: function() {
            return this.cardSizes[this.cardSizes.length - 1] || {};
        },

        /**
         * Hide the fixed cards behind the overlay to improve scroll performance.
         */
        afterOverlayReveal: function(scrollTop, currentPath) {
            var currentSection = Utils.getSectionPath(currentPath || this.currentPath),
                cardGap = this.$('.card-wrap > .card').position().top || 50,
                headerFixedThreshold = this.header.getFixedThreshold(),
                placeholderHeight = cardGap + this.header.getExpandedHeight();
            this.$el.hide();
            if (placeholderHeight > scrollTop) {
                // the card gap was visible
                if (headerFixedThreshold < scrollTop) {
                    // we were fixed when we opened the overlay
                    placeholderHeight -= (scrollTop - headerFixedThreshold);
                }
                this.offsetPlaceholder = $('<div id="cards-offset-placeholder" style="height:' + placeholderHeight + 'px"></div>');
                this.$body.append(this.offsetPlaceholder);
            }
            if (this.header.isFixed()) {
                this._fixPlaceholder();
            } else {
                this._unfixPlaceholder();
            }
            this.fakeCardPlaceholder = $(this.overlayTemplate({
                section: currentSection,
                layoutType: Utils.getNested(this.pageInfo, 'layout_type') || 'primary-suspender-sidebar-page'
            }));
            this.$body.append(this.fakeCardPlaceholder);
        },

        _fixPlaceholder: function(){
            if (this.offsetPlaceholder) {
                this.offsetPlaceholder.css({position: 'fixed', top: -1 * this.header.getFixedThreshold()});
            }
        },
        _unfixPlaceholder: function(){
            if (this.offsetPlaceholder) {
                this.offsetPlaceholder.css({position: 'absolute', top: 0});
            }
        },

        _onHeaderFixed: function() {
            this._fixPlaceholder();
            BaseApp.prototype._onHeaderFixed.apply(this, arguments);
        },

        _onHeaderUnfixed: function() {
            this._unfixPlaceholder();
            BaseApp.prototype._onHeaderUnfixed.apply(this, arguments);
        },

        /**
         * Show the fixed cards again (and remove the temporary ones).
         */
        beforeOverlayRemove: function() {
            this.$el.show();
            if (this.offsetPlaceholder) {
                this.offsetPlaceholder.remove();
                this.offsetPlaceholder = null;
            }
            if (this.fakeCardPlaceholder) {
                this.fakeCardPlaceholder.remove();
                this.fakeCardPlaceholder = null;
            }
        },

        _updateNav: function(toUrl, section) {
            var currentIndex = _.indexOf(this.cards, section),
                nextIndex = this._incrementIndex(currentIndex, 1),
                prevIndex = this._incrementIndex(currentIndex, -1),
                nextSection, prevSection;
            if (this.header) {
                this.header.updateNavigation(toUrl);
            }

            if (this.domInitialized){
                if (currentIndex === -1) {
                    // we're in no-man's land, next arrow should be home, prev should be home - 1
                    nextIndex = _.indexOf(this.cards, 'home');
                    prevIndex = this._incrementIndex(nextIndex, -1);
                }
                nextSection = this.cards[nextIndex];
                prevSection = this.cards[prevIndex];
                this.updateNavLinks(this.$nextBtn, nextSection);
                this.updateNavLinks(this.$prevBtn, prevSection);

                //update the inner content for arrow hovers
                this.$('.front-next-arrow-label').html(this.cardsDisplay[nextIndex] || nextSection);
                this.$('.front-prev-arrow-label').html(this.cardsDisplay[prevIndex] || prevSection);

                this.$nextBtn.removeClass().addClass("front-arrow-" + nextSection);
                this.$prevBtn.removeClass().addClass("front-arrow-" + prevSection);

                this.$('.front-overlay-next-arrows-anchor').removeClass().addClass('front-overlay-next-arrows-anchor ' + nextSection + '-theme-bg');
                this.$('.front-overlay-prev-arrows-anchor').removeClass().addClass('front-overlay-prev-arrows-anchor ' + prevSection + '-theme-bg');
            }
        },
        setupSourcePoint: function() {
            // Sourcepoint messaging
            if(!window._sp_) return;
            if(Utils.getNested(window.site_vars, 'ADS', 'sourcepoint', 'onCards') && !window._sp_.displayed_message) {
                window._sp_.mms = window._sp_.mms || {};
                window._sp_.mms.cmd = window._sp_.mms.cmd || [];
                window._sp_.mms.cmd.push(function() {
                    window._sp_.mms.startMsg();
                });
            }
        }

    });

    /**
     * Return app class.
     */
    return CardsApp;

});

