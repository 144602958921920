/*global define, OOo */
/**
 * @fileoverview Loads Opinionlabs scripts if enabled through site_config.
 * @author akothapall@gannett.com (Amarnath Kothapalli)
 *
 */
define('third-party-apis/opinionlabs',[
        'jquery',
        'underscore',
        'utils',
        'user-manager',
        'state',
        'third-party-apis/base-third-party-api'
    ],
    function(
        $,
        _,
        Utils,
        UserManager,
        StateManager,
        BaseThirdPartyApi
    ) {

        "use strict";

        var OpinionLab = BaseThirdPartyApi.extend({
            initialize: function() {
                var siteconfig = this.getApiSiteConfig('OPINIONLAB');
                if (siteconfig.ENABLED) {
                    BaseThirdPartyApi.prototype.initialize.call(this);
                }
            },

            /**
             * Loads OpinionLab scripts.
             * @private
             * @returns {*}
             */
            _handleLoadScript: function() {
                if (!this._loadScriptPromise) {
                    this._loadScriptPromise = $.Deferred();
                    var scriptSources = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'OPINIONLAB', 'SOURCE'),
                        $body = Utils.get('body'),
                        platform = ($body.hasClass('body-dr-styling')) ? 'DFR' : 'legacy';

                    var script1 = $.ajax(scriptSources[0], {
                        cache: true,
                        dataType: 'script'
                    });
                    var script2 = $.ajax(scriptSources[1], {
                        cache: true,
                        dataType: 'script'
                    });

                    $.when(script1, script2).done(_.bind(function() {
                        OOo.oo_feedback = new OOo.Ocode({
                            onPageCard: {
                                closeWithOverlay: true
                            },
                            customVariables: {
                                'authenticationStatus': "false",
                                'subscriptionStatus': "false",
                                'benefitSegment': "null",
                                'platform': platform,
                                'abTestVariant': "null"
                            },
                            referrerRewrite: {
                                searchPattern: /:\/\/.*/g
                            }
                        });
                        OOo.oo_launch = function(e, feedback) {
                            var userInsights = {},
                                pageInfo = StateManager.getActivePageInfo(),
                                abTestVariant = Utils.getNested(pageInfo, 'user', 'abTest', 'userVariant', 'id') || "null";
                            try {
                                userInsights = JSON.parse(window.localStorage.getItem('user_insights')) || {};
                            } catch(error) {
                                // user_insights not a json string
                            }
                            if (userInsights.benefitSegment) {
                                OOo.oo_feedback.options.customVariables.benefitSegment = userInsights.benefitSegment;
                            }
                            OOo.oo_feedback.options.customVariables.abTestVariant = abTestVariant;
                            OOo.oo_feedback.options.customVariables.cta = e.target.dataset.cta;
                            OOo.oo_feedback.options.customVariables.marketName = Utils.getNested(window.site_vars, 'site_key');
                            OOo.oo_feedback.options.referrerRewrite.replacePattern =
                                '://web.gannett.com/authenticationStatus-' + OOo.oo_feedback.options.customVariables.authenticationStatus +
                                '/subscriptionStatus-' + OOo.oo_feedback.options.customVariables.subscriptionStatus +
                                '/platform-' + OOo.oo_feedback.options.customVariables.platform +
                                '/GUID-' + Utils.getNested(window.utag_data, 'gup_anonid') +
                                '/siteId-' + Utils.getNested(window.site_vars, 'siteid') +
                                '/cta-' + e.target.dataset.cta + '/marketName-' + Utils.getNested(window.site_vars, 'site_key') +
                                '/abTestVariant-' + OOo.oo_feedback.options.customVariables.abTestVariant;
                            var evt = e || window.event;
                            OOo[feedback].show(evt);
                        };
                        var guid = Utils.getNested(window.utag_data, 'gup_anonid');
                        if (guid !== '' && guid !== 'undefined' && guid !== null) {
                            OOo.oo_feedback.options.customVariables.GUID = guid;
                        }
                        OOo.oo_feedback.options.customVariables.siteId = Utils.getNested(window.site_vars, 'siteid');
                        var userAccount = UserManager.getAccount('firefly');
                        if (userAccount) {
                            userAccount.getUserInfo().always(_.bind(function (userInfo) {
                                if (userInfo && userInfo !== "Not logged in.") {
                                    OOo.oo_feedback.options.customVariables.authenticationStatus = "true";
                                    if (userInfo.hasMarketAccess === true){
                                        OOo.oo_feedback.options.customVariables.subscriptionStatus = "true";
                                    }
                                }
                                OOo.oo_feedback.options.customVariables.GUID = Utils.getNested(window.utag_data, 'gup_anonid');
                            }));
                        }
                        this._loadScriptPromise.resolve();
                    }, this));
                }
                return this._loadScriptPromise.promise();
            },

            launchModal: function(e) {
                OOo.oo_launch(e, 'oo_feedback');
            }

        });

        return new OpinionLab();

    });

