/**
* @fileoverview Manages the social follow USA TODAY overlay module
* @author kybaker@usatoday.com (Kyle Baker)
*/
define('modules/sports/sports-follow-usat',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {

    /**
     * View class.
     */
    var SportsFollowUSAT = BaseView.extend({

        // Events.
        events: {
        },

        /**
         * Initialize the view.
         */
        initialize: function() {
            // Calls super method.
            BaseView.prototype.initialize.apply(this, arguments);

            // Determine if the Follow us Overlay should appear and show/hide it
            $(document).ready( this.followUSATOverlay );
        },

        followUSATOverlay: function() {
            var followUsatWrap = $('.sp-follow-usat-overlay-wrap');

            // if follow us module exists
            if( followUsatWrap.length > 0 ) {

                /* Check for Cookie */
                var follow_overlay = "";
                var name = "usatfollowus=";
                var ca = document.cookie.split(';');
                for(var i = 0; i < ca.length; i++) {
                    var c = ca[i].trim();
                    if (c.indexOf(name) === 0) {
                        follow_overlay = c.substring(name.length, c.length);
                        break;
                    }
                }

                var d = new Date();
                d.setTime( d.getTime() + followUsatWrap.data('exp')*24*60*60*1000 );    //expiration is entered as days, calculate the milliseconds
                var expires = "expires=" + d.toGMTString();

                switch( follow_overlay ){

                    /* Set Cookie = ready, show on next page view */
                    case "":
                        document.cookie = "usatfollowus=ready; " + expires + "; path=/";
                        break;

                    /* Show Overlay */
                    case "ready":

                        /* Render - Facebook button */
                        if($('.follow-button-wrap.fb-wrap').length > 0) {
                            $('.follow-button-wrap.fb-wrap').append('<iframe src="//www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fusatodaysports&amp;width&amp;layout=button_count&amp;action=like&amp;show_faces=false&amp;share=false&amp;height=21&amp;appId=2530096808" scrolling="no" frameborder="0" style="border:none; overflow:hidden; height:21px; width:84px;" allowTransparency="true"></iframe>');
                        }

                        /* Render - Twitter button */
                        if( $('.follow-button-wrap.tw-wrap').length > 0 ) {
                            // if already created, just return
                            if ($('#twitter-wjs').length > 0) { return; }
                            var p = /^http:/.test(document.location)?'http':'https';
                            //var fjs = $('script')[0];
                            var tw_js = $("<script src='" + p + "://platform.twitter.com/widgets.js' id='twitter-wjs'>");
                            $('head').append(tw_js);
                        }

                        /* Show overlay */
                        $('#follow-usat-box-details').css('top', ($('#follow-usat-box-details').offset().top - 100) + "px" );
                        $('#follow-usat-box-details').css('position', 'absolute' );

                        $('#follow-usat-film').fadeIn(1000, function(){
                            $('#follow-usat-box-details').fadeIn(1000);
                        });

                        /* Set cookie */
                        document.cookie = "usatfollowus=shown; " + expires + "; path=/";

                        /* Remove overlay listeners */
                        $('.sp-details-close').click(function(e){
                            $('.sp-follow-usat-overlay-wrap').parent().remove();
                        });
                        break;

                    /* Cookie was already shown. DON'T show overlay */
                    case "shown":
                        $('.sp-follow-usat-overlay-wrap').parent().remove();
                        break;
                    default:
                        $('.sp-follow-usat-overlay-wrap').parent().remove();
                        break;
                }
            }
        }
    });

    /**
     * Create object and return it.
     */
    return SportsFollowUSAT;
});

