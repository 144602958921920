/* global define */
/* global console */
/**
 * @fileoverview Feed Stories module
 * @author Erik Kallevig <ekallevig@gannett.com>
 */
define('modules/feed-stories/feed-stories',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'pubsub',
    'state',
    'managers/requestmanager',
    'template-utils',
    'fwinfo',
    'libs/moment/moment.min',
    'third_party_integrations/taboola/taboola-utils',
    'modules/global/taboola'
],
function (
    $,
    _,
    BaseView,
    Utils,
    PubSub,
    StateManager,
    RequestManager,
    TemplateUtils,
    FwInfo,
    moment,
    TaboolaUtils,
    Taboola
) {
    'use strict';
    /**
     * View class.
     */
    var FeedStories = BaseView.extend({

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);
            _.bindAll(
                this,
                'loadDefaultModule',
                'updateDom',
                'renderClientTemplate',
                'renderServerTemplate',
                'getImageReqs',
                'formatTaboolaAsset',
                'formatSolrAsset',
                'logAbTestImpression'
            );
            this.layoutType = options.layoutType || 'STORYRIGHTRAIL';
            this._setOptions(options);
            FwInfo.setsessionvalue('feedStories', this.$el.attr('data-options-id') || 'default');
            this.loadModule();
        },

        _getDefaultOptions: function() {
            var pageInfo = StateManager.getActivePageInfo(),
                rawSsts = Utils.getNested(pageInfo, 'ssts') || '',
                sstsArray = rawSsts.split('/');
            return {
                displayName: "POPULAR STORIES",
                layout: "fullWidthLead",
                count: 5,
                rawSsts: rawSsts,
                sstsArray: sstsArray,
                sstsDepth: 1, // Default to top-level ssts
                ssts: sstsArray[0],
                exclude: Utils.getNested(pageInfo, 'assetid'),
                placement: "feedStories",
                source: Utils.getNested(window.site_vars, 'base_url'),
                useShortHeadlines: true,
                showSource: true
            };
        },

        _setOptions: function(options) {
            options = options || {};
            this.options = $.extend(true, this._getDefaultOptions(), options);
            this.options.sstsArray.length = this.options.sstsDepth;
            this.options.ssts = this.options.sstsArray.join('/');
            return this.options;
        },
        /**
         * Fetch module contents based on and handle response.
         */
        loadModule: function() {
            var options = this.options,
                params = {};
            switch (options.provider) {
                case 'dynamic-video-playlist':
                    params = _.pick(options, ['count','ssts','layout','source','linkColor','timestamp','showRank',
                        'showSource','showShares', 'boldHeadlines', 'hideTextOnHover']);
                    RequestManager.fetchHtml('/videos/modules/dynamic-video-playlist/?' + $.param(params))
                        .done(_.bind(function(html) {
                            this.renderServerTemplate(html, options);
                        }, this))
                        .fail(this.loadDefaultModule);
                    break;
                case 'taboola':
                    var taboola = new Taboola({isWidgetPresent:false});
                    taboola.getRelated(
                        undefined, // no callback fn (using promise method)
                        undefined, // source type (rely on taboola.js detection)
                        options.assetType || 'text', // rec type
                        options.count,
                        undefined, // source.url (rely on taboola.js detection)
                        undefined, // source.id (rely on taboola.js detection)
                        options.placement, // placement
                        true // recVisible
                    )
                        .done(_.bind(function(data) {
                            var formattedAssets = _.map(data.list, this.formatTaboolaAsset);
                            var filteredAssets = _.filter(formattedAssets, this.filterTaboolaAsset);
                            this.renderClientTemplate(filteredAssets, options);
                        }, this))
                        .fail(this.loadDefaultModule);
                    break;
                case 'solr':
                    params = _.pick(options, ['metric','count','ssts','tags','days','assetType']);
                    params.count += 1; // fetch an additional result to allow for client-side de-duping
                    RequestManager.fetchData('/modules/feed-stories/json?' + $.param(params))
                        .done(_.bind(function(data) {
                            var filteredAssets = _.filter(data.assets, function(asset) {
                                    return asset.id !== options.exclude;
                                }).slice(0, options.count),
                                formattedAssets = _.map(filteredAssets, this.formatSolrAsset);
                            this.renderClientTemplate(formattedAssets, options);
                        }, this))
                        .fail(this.loadDefaultModule);
                    break;
                default:
                    this.loadDefaultModule();
                }
        },
        /**
         * Compile client-side template, process it (add click handlers, etc) then attach to DOM.
         */
        renderClientTemplate: function(assets, options) {
            var formattedData = {
                    assets: assets,
                    linkColor: options.linkColor,
                    timestamp: options.timestamp,
                    showRank: options.showRank,
                    showSource: options.showSource,
                    showShares: options.showShares,
                    useShortHeadlines: options.useShortHeadlines,
                    boldHeadlines: options.boldHeadlines,
                    hideTextOnHover: options.hideTextOnHover
                },
                template = this.$('.tmpl-feed-stories-' + this.layoutType.toLowerCase()).html(),
                htmlString = _.template(template, formattedData),
                processedHtml = this.processHtmlString(htmlString, assets);
            this.updateDom(processedHtml, options.displayName);
        },
        /**
         * Process (add click handlers, etc) server-rendered HTML, then attach to DOM.
         */
        renderServerTemplate: function(htmlString, options) {
            var processedHtml = this.processHtmlString(htmlString);
            this.updateDom(processedHtml, options.displayName);
        },
        /**
         * Convert html string to DOM nodes, then attach tracking to links and return nodes.
         */
        processHtmlString: function(html, assets) {
            var nodes = _.isString(html) ? $.parseHTML(html) : html, asset;
            $(nodes).find('.fsm-link').each(_.bind(function(i, el) {
                if (assets) {
                    asset = _.first(assets, 'url', $(el).attr('href'));
                    if (this.options.provider === 'taboola') {
                        TaboolaUtils.addLinkData(el, asset.notifyClickUrl);
                    }
                }
            }, this));
            return nodes;
        },
        /**
         * Append rendered feed html to module container, set module title, and log ab test impression.
         */
        updateDom: function(contentHtml, label, isDefaultModule) {
            this.$el.append(contentHtml);
            if (label) {
                this.setModuleLabel(label);
            }
            if (this.options.abTest) {
                this.logAbTestImpression(this.options.abTest, isDefaultModule);
            }
        },

        setModuleLabel: function(label) {
            this.$('.module-title').text(label);
        },

        logAbTestImpression: function(abTest, isDefaultModule) {
            var params = {};
            if (isDefaultModule) {
                params.abTestFail = true;
            }
            params.abTestId = this.options.abTest.id + '';
            params.abTestVariant = this.options.abTest.userVariant.id;
            params.eventType = 'abTestImpression';
            params.inViewport = Utils.isElementInViewport(this.$el);
            PubSub.trigger('analytics:event', 'pong', params);
        },

        /**
         * Determine image size/aspect/prefix for a given asset in a list per module layout.  ie. if the module
         * layout (eg. 'fullWidthLead') requires the lead (first) story to have a larger photo than the other
         * stories, this function figures that out and returns the widht/height/aspect/prefix for the a given
         * asset in a list being looped over (hence the index param).
         */
        getImageReqs: function(asset, index, layout) {
            var img = {};
            if (this.layoutType === 'STORYRIGHTRAIL') {
                if (layout === 'fullWidth' || layout === 'fullWidthLead' && index === 0) {
                    img.width = '299';
                    img.height = '168';
                    img.aspect = '16_9';
                    img.prefix = 'fw';
                } else if (layout === 'clean') {
                    img.width = '330';
                    img.height = '186';
                    img.aspect = '16_9';
                    img.prefix = 'cln';
                } else {
                    img.width = '84';
                    img.height = '84';
                    img.aspect = '1_1';
                    img.prefix = 'thumb';
                }
            }
            return img;
        },

        formatTaboolaAsset: function(asset, index) {
            var img = this.getImageReqs(asset, index, this.options.layout),
                imgUrl = Utils.getNested(asset, 'thumbnail', 0, 'url');
            if (imgUrl) {
                imgUrl = decodeURIComponent(imgUrl.substring(imgUrl.indexOf('/http') + 1));
            }
            return {
                headline: asset.name,
                url: asset.id,
                image: TemplateUtils.getCroppedImage(imgUrl, img.width, img.height),
                layoutPrefix: img.prefix,
                pubTimeAgo: moment(new Date(asset.created)).fromNow(),
                pubDate: asset.created,
                notifyClickUrl: asset.url,
                source: this.getSourceFromUrl(asset.id)
            };
        },

        filterTaboolaAsset: function(item) {
            var imgUrl = item.image.toString() || '',
                url = item.url.toString() || '',
                isFuture = new Date(item.pubDate) > Date.now();
            return url.match(/story|videos|picture-gallery/) && !imgUrl.match(/applogos|images\/logos/) && !isFuture;
        },

        formatSolrAsset: function(asset, index) {
            var imgReqs = this.getImageReqs(asset, index, this.options.layout),
                photoObj = Utils.getNested(asset, 'photo') || {},
                img = TemplateUtils.getCroppedImage(
                    photoObj.absoluteUrl, imgReqs.width, imgReqs.height, photoObj.crops, imgReqs.aspect
                ),
                url = Utils.getNested(asset, 'urls', 'longUrl');
            return {
                headline: asset.headline,
                shortHeadline: asset.shortHeadline,
                url: TemplateUtils.relativizeUrl(url),
                image: img,
                layoutPrefix: imgReqs.prefix,
                pubTimeAgo: moment(new Date(asset.datePublished)).fromNow(),
                pubDate: asset.datePublished,
                source: this.getSourceFromUrl(url)
            };
        },

        loadDefaultModule: function() {
            var options = this._setOptions(),
                params = {
                    count: options.count + 1, // fetch an additional result to allow for client-side de-duping
                    ssts: options.ssts
                };
            RequestManager.fetchData('/modules/feed-stories/json?' + $.param(params))
                .done(_.bind(function(data) {
                    var filteredAssets = _.filter(data.assets, function(asset) {
                            return asset.id !== options.exclude;
                        }).slice(0, options.count),
                        formattedAssets = _.map(filteredAssets, this.formatSolrAsset);
                    this.renderClientTemplate(formattedAssets, options);
                }, this))
                .fail(this.onDefaultModuleFetchFail);
        },

        onDefaultModuleFetchFail: function(err) {
            console.warn('Feed stories module error: default module failed', err);
        },

        getSourceFromUrl: function(url) {
            if (url.charAt(0) === '/') {
                return this.options.source;
            }
            var a = document.createElement('a');
            a.setAttribute('href', url);
            return a.hostname.replace(/^(localhost|www)\./i, '');
        }

    });
    /**
     * Return view class.
     */
    return FeedStories;
});

