/**
 * @fileoverview Arkadium view module.
 * @author brad.smith@azcentral.com
 */
define('modules/obituaries_legacy',[
        'jquery',
        'underscore',
        'baseview'
    ],
    function(
        $,
        _,
        BaseView
    ) {
        var ObituariesView = BaseView.extend({
            events : {
                'click .js-hpom-go-btn' : 'searchObituaries'
            },
            searchObituaries : function (){
                var firstName = this.$(".js-hpom-firstname").val();
                var lastName = this.$(".js-hpom-lastname").val();
                if (lastName.length || firstName.length){
                    if (lastName === undefined){
                        lastName = '';
                    }
                    if (firstName === undefined) {
                        firstName = '';
                    }
                    var searchQuery = this.$(".js-hpom-go-btn").data("searchquery").replace("{{LASTNAME}}", lastName).replace("{{FIRSTNAME}}", firstName);
                    window.location = searchQuery;
                }

            }
        });
        return ObituariesView;
    }
);

