/**
 * @fileoverview Section Highlights module view.
 * @author Kevin Foster <kefosterk@gannett.com>
 */
define('modules/section-highlights/section-highlights',[
    'jquery',
    'underscore',
    'backbone',
    'utils',
    'baseview'
],
function(
    $,
    _,
    Backbone,
    Utils,
    BaseView
) {
    'use strict';
    /**
     * View class.
     */
    var SectionHighlights = BaseView.extend({

        /**
         * Initialize view.
         * @param {Object} options View options passed in during init.
         */
        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);
            this._refreshSports();
        },
        /**
         * Update Sports content with live scores
         * @private
         */
        _refreshSports: function () {
            _.each(this.$(".shpm-score-box"), function (d) {
                if ($(d).data('live') == 'True') {
                    var requrl = "/services/live_sports_scores/" +
                        $(d).data('league') + "/" +
                        $(d).data('team') + "/" +
                        Math.floor(Date.now() / 60000); // timestamp to the minute
                    $.get(requrl, function(snippet) {
                        $(d).replaceWith(snippet);
                    });
                }
            });
        }
    });
    /**
     * Return view class.
     */
    return SectionHighlights;
});

