define('modules/search-module',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub'
],
function(
    $,
    _,
    BaseView,
    PubSub
)
{
    'use strict';
    var SearchModule = BaseView.extend({

        // Events.
        events: {
            'focus .search-module-input': 'startSearch',
            'focusout .search-module-input': 'hideSearch',
            'mousedown .search-module-trending-item': 'updateSearch',
            'mousedown .search-module-cancel': 'cancelSearch',
            'submit .search-module-form': 'formSubmit'
        },

        initialize: function(options){
            this.searchForm = this.$('.search-module-form');
            this.searchInput = this.$('.search-module-input');
            this.searchCancel = this.$('.search-module-cancel');
            this.searchTrending = this.$('.search-module-trending');
            this.trackLabel = '';

            // call base class initialize
            BaseView.prototype.initialize.call(this, options);
        },

        startSearch: function() {
            this.searchTrending.css('display', 'block');
            this.searchCancel.css('display', 'inline-block');
        },

        updateSearch: function(e) {
            this.searchInput.val($(e.currentTarget).find('.search-module-trending-item-tag-js').text());
            this.trackLabel = 'search|' + $(e.currentTarget).find('.search-module-trending-item-tag-js').data('track-headline-var');
            
            this.searchForm.submit();
        },

        hideSearch: function() {
            this.searchTrending.css('display', 'none');
            this.searchCancel.css('display', 'none');
        },

        cancelSearch: function() {
            this.searchInput.val('');
            this.searchTrending.css('display', 'none');
            this.searchCancel.css('display', 'none');
        },

        formSubmit: function(e) {
            if (this.trackLabel === '') {
                this.trackLabel = 'search|' + this.searchInput.val();    
            }
            
            PubSub.trigger('track', {
                label: this.trackLabel
            });
        }

    });

    return SearchModule;

});

