define('modules/videos/video-gallery',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'utils',
    'state',
    'modules/carousel/gallery',
    'modules/videos/brightcove-video',
    'modules/carousel/thumbs',
    'ui/tooltip',
    'modules/stories/taboola-recommendations',
    'modules/scroller/horizontal-scroll',
    'managers/autoplay'
],
    function (
        $,
        _,
        BaseView,
        PubSub,
        Utils,
        StateManager,
        CarouselGallery,
        BrightcoveVideo,
        CarouselThumbs,
        Tooltip,
        TaboolaOutbrain,
        HorizontalScroll,
        AutoPlayManager
        ) {
        'use strict';

        /**
         * @lends videos/video-gallery.prototype
         */

        var VideoGallery = BaseView.extend({

            events: {
                'click .carousel-prev': 'onClickPrevArrow',
                'click .carousel-next': 'onClickNextArrow'
            },

            /**
             * @classdesc Handles the functionality of all carousels that contain brightcove video players
             * @author Mark Kennedy <mdkennedy@gannett.com>
             * @constructs videos/video-gallery
             * @param {Object} options backbone options object
             *     @param {jQuery|Element|String} options.el - element or string of the video carousel container
             *     @param {string} [options.viewportSelector] - a selector representing the container that will serve as the carousel's viewport
             *     @param {string} [options.slidesContainerSelector] - a selector representing the container that contains nested slides
             *     @param {string} [options.slidesSelector] - a selector representing all slides nested inside of the slidesContainerSelector
             */
            initialize: function (options) {

                options = $.extend({
                    viewportSelector: '.gallery-viewport',
                    slidesContainerSelector: '.gallery-slides',
                    slidesSelector: '.gallery-slide',
                    hoverTransition: 0, // if set to non-zero, will cause hovering over thumbs to switch to that slide
                    scrollerColor: 'light',
                    thumbsSelector: '.vgm-thumbs',
                    tooltipOptions: null
                }, options);

                _.bindAll(this, '_transitionSlides', '_getOnStatusChangeMethod', '_getOnClickPlayVideoMethod','goToNextVideo', 'getIntermediateRecommendation', 'startAutoPlay');

                this.$viewport = this.$(options.viewportSelector);
                this.$slidesContainer = this.$viewport.find(options.slidesContainerSelector);
                this.$slides = this.$slidesContainer.find(options.slidesSelector);
                this.$arrows = this.$('.carousel-prev, .carousel-next');
                this.$title = this.$('.video-gallery-page-title');
                this.subviewPrefix = 'galleryItem';

                var baseViewOptions = $.extend(options, {animations: { useCSSTransitions: false}});
                BaseView.prototype.initialize.call(this, baseViewOptions);

                this.index = this.$('.vgm-thumb-item.active').index();

                this.subviews.gallery = new CarouselGallery({
                    el: this.$el,
                    slideTransition: 200,
                    ads: false,
                    index: 0,
                    lookAheadAmount: 3,
                    transitionSlides: this._transitionSlides
                });

                this._initializeThumbs(options.hoverTransition, options.scrollerColor, options.thumbsSelector);
                this._initializeTooltips(this.$('.vgm-thumb-box'));

                // setup
                this.goTo(this.index).done(_.bind(function () {
                    var _autoplay = Utils.getNested(this.getPlayerSubview(this.index), 'player', 'autoplay');
                    if (_autoplay === undefined || _autoplay ){
                        AutoPlayManager.register('brightcove-video', this.startAutoPlay);
                    }
                }, this));
            },

            startAutoPlay: function() {
                this.startVideo(this.index);
                PubSub.trigger('uotrack', 'videoplaylist|nextauto');
                return true;
            },

            /**
             * Setup the Thumbnail view
             * @param {number} hoverTransition - if set to non-zero, will cause hovering over thumbs to switch to that slide
             * @param {string} scrollerColor - theme for scroller, 'light' or 'dark'
             * @param {string} selector - selector used for the view
             * @private
             */
            _initializeThumbs: function(hoverTransition, scrollerColor, selector) {
                this.subviews.thumbs = new CarouselThumbs({
                    el: (selector ? this.$(selector) : this.$el),
                    hoverTransition: hoverTransition,
                    index: this.index,
                    scrollerColor: scrollerColor,
                    carousel: this
                });
            },

            /**
             * Initialize the Tooltip view
             * @param {Object} $items - list of elements that will get a Tooltip
             * @private
             */
            _initializeTooltips: function ($items) {
                _.each($items, function (element, index) {
                    this._setupTooltipView(element, $(element).data('duration'), index);
                }, this);
            },

            /**
             * Setup the Tooltip view
             * @param {String} el - element that will get a tooltip wrapper
             * @param {String} tooltipText - text to be displayed in the tooltip
             * @private
             */
            _setupTooltipView: function (el, tooltipText, galleryView) {
                var options = $.extend({
                    el: el,
                    text: tooltipText,
                    customContainerClass: 'vgm-ui-tooltip',
                    position: 'bottom'
                }, this.options.tooltipOptions);
                var subviewName = this.subviewPrefix + galleryView;
                if (!this.subviews[subviewName]) {
                    this.subviews[subviewName] = new Tooltip(options);
                }
            },

            /**
             * When a video status changes.
             * @param {Object} e Change event
             * @param {Number} index The index of slide that contains the video that fired the change
             */
            onVideoStatusChange: function (e, index) {
                if (e.type === 'mediaComplete') {
                    var currentSlide = this.getActiveSlideIndex(),
                        nextSlideIndex = currentSlide + 1,
                        maxSlideIndex = this.$slides.length - 1;
                    if (Utils.getNested(this.getPlayerSubview(currentSlide),"player","gannett_continuous_endslate")) {
                        if (!this.subviews['intermediateEndslate' + currentSlide]) {
                            this.displayEndslate();
                            return;
                        }
                    }
                    if (nextSlideIndex <= maxSlideIndex) {
                        this.goToNextVideo();
                    }
                }
                if (this.getActiveSlideIndex() !== index && (e.type === 'mediaBegin' || e.type === 'mediaPlay')) {
                    // video on a non-active slide has begun playing, so put in background state!
                    this.enterBackgroundState(index);
                }
            },

            /**
             * When a user clicks on previous arrow.
             * @param {MouseEvent} e The event
             */
            onClickPrevArrow: function (e) {
                e.preventDefault();
                var index = this.getActiveSlideIndex() - 1;
                this.goTo(index).done(_.bind(function () {
                    this.startVideo(index);
                    this.triggerEvent('goToSlide', index);
                    PubSub.trigger('uotrack', 'videoplaylist|prev');
                }, this));

            },

            /**
             * When a user clicks on next arrow.
             * @param {MouseEvent} e The event
             */
            onClickNextArrow: function (e) {
                e.preventDefault();
                var index = this.getActiveSlideIndex() + 1;
                this.goTo(index).done(_.bind(function () {
                    this.startVideo(index);
                    this.triggerEvent('goToSlide', index);
                    PubSub.trigger('uotrack', 'videoplaylist|next');
                }, this));
            },

            /**
             * When a thumbnail is clicked, change the selected video
             * @param {Number} index - index of the thumbnail selected
             */
            switchSlide: function (index) {
                var previousIndex = this.getActiveSlideIndex();

                this.goTo(index).done(_.bind(function () {
                    this.startVideo(index);
                    this.triggerEvent('goToSlide', index);

                    // swap out the video
                    PubSub.trigger('uotrack', 'videoplaylist' + (index + 1));
                    if (previousIndex !== undefined) {
                        this.enterBackgroundState(previousIndex);
                    }
                }, this));
            },

            /**
             * Starts a video on a slide.
             * @param {Number} index - The index number of the slide that the video is on.
             */
            startVideo: function (index) {
                var subview = this.getPlayerSubview(index);
                if (subview) {
                        subview.playVideo();
                }
            },

            /**
             * Puts a video player on a slide in an sleep state to reinstate later.
             * @param {Number} index - The index of the slide
             */
            enterBackgroundState: function (index) {
                var playerSubview = this.getPlayerSubview(index);
                if (playerSubview) {
                    playerSubview.reset();
                }
            },

            /**
             * Goes to a specific slide.
             * @param {Number} index - The slide index to go to
             */
            goTo: function (index) {
                var previousIndex = this.getActiveSlideIndex();
                this.updateArrows(index);
                this._activeSlide = index;
                return this.subviews.gallery.goToSlide(index).done(_.bind(function () {
                    // setup subview if video element exists
                    var subview = this.subviews['video' + index],
                        $el = this.$slides.eq(index).find('.ui-video');
                    if (!subview && index !== undefined && $el.length) {
                        this.subviews['video' + index] = new BrightcoveVideo({
                            el: $el[0],
                            onStatusChange: this._getOnStatusChangeMethod(index),
                            onClickPlay: this._getOnClickPlayVideoMethod(index),
                            overrideGetRecommendations: this.getIntermediateRecommendation,
                            autoPlay: false // we will start videos manually
                        });
                    }
                    if (previousIndex !== undefined) {
                        this.enterBackgroundState(previousIndex);
                    }
                }, this));

            },

            getIntermediateRecommendation: function() {
                var currentSlideIndex = this.getActiveSlideIndex(),
                    nextVideodata = '',
                    nextSlideIndex = currentSlideIndex + 1,
                    maxSlideIndex = this.$slides.length - 1,
                    playerSubview = this.getPlayerSubview(currentSlideIndex);
                if (nextSlideIndex <= maxSlideIndex) {
                    nextVideodata = this.$slides.eq(nextSlideIndex).find('.ui-video-data').html();
                    this.videoresponse = JSON.parse(nextVideodata);
                } else if (nextSlideIndex > maxSlideIndex) {
                    if (!this.subviews.TaboolaOutbrain) {
                        this.subviews.TaboolaOutbrain = new TaboolaOutbrain();
                        this.subviews.TaboolaOutbrain.renderEndslate(playerSubview.video.url, playerSubview.video.brightcove_id);
                    }
                }
            },

            displayEndslate: function() {
                var currentSlideIndex = this.getActiveSlideIndex(),
                    nextSlideIndex = currentSlideIndex + 1,
                    maxSlideIndex = this.$slides.length - 1,
                    playerSubview = this.getPlayerSubview(currentSlideIndex),
                    $endslate_template = this.$slides.eq(currentSlideIndex).find('.video-endslate');
                if (nextSlideIndex <= maxSlideIndex) {
                    if (playerSubview.player.gannett_continuous_endslate) {
                         if (!this.subviews['intermediateEndslate' + currentSlideIndex]) {
                            this.subviews['intermediateEndslate' + currentSlideIndex] = new TaboolaOutbrain({
                                'videoResponse': this.videoresponse,
                                endCountdown: this.goToNextVideo
                            });
                            this.subviews['intermediateEndslate' + currentSlideIndex].playNextRecommendation(playerSubview.player.mediatemplate);
                        }
                    }
                } else if (nextSlideIndex > maxSlideIndex) {
                    if (playerSubview.player.gannett_continuous_endslate) {
                        this.subviews.TaboolaOutbrain.playNextRecommendation(playerSubview.player.mediatemplate);
                    }
                }
            },

            goToNextVideo: function() {
                var nextSlideIndex = this.getActiveSlideIndex() + 1;
                this.goTo(nextSlideIndex).done(_.bind(function () {
                    this.startVideo(nextSlideIndex);
                    this.triggerEvent('goToSlide', nextSlideIndex);
                    PubSub.trigger('uotrack', 'videoplaylist|nextauto');
                }, this));
            },

            /**
             * Shows the new slide and hides the other one.
             * @param {Number} index
             * @private
             */
            _transitionSlides: function(index) {
                var offsetBy = this.$viewport.outerWidth(),
                    targetOffset = offsetBy * index * -1,
                    $container = this.$slidesContainer;
                return this.animate($container, 'left', targetOffset + 'px', 250, 'easeInOutQuad');
            },

            /**
             * Gets the subview of a video player on a slide.
             * @param {Number} index The index of the slide that contains the subview
             * @returns {Deferred}
             */
            getPlayerSubview: function (index) {
                return this.subviews['video' + index];
            },

            /**
             * Gets method (mapped to a slide index) to call when the play button or video still image is clicked.
             * @param {Number} index - The index on which to attach the onClickPlayVideo method to
             * @returns {Function}
             * @private
             */
            _getOnClickPlayVideoMethod: function(index) {
                return _.bind(function (e) {
                    this.onClickPlayVideo(e, index);
                }, this);
            },

            /**
             * When the video play button or video still is clicked.
             * @param {MouseEvent} e - Event
             * @param {Number} index - The slide index where the play button/video still was clicked.
             */
            onClickPlayVideo: function(e, index) {
                this.startVideo(index);
            },

            /**
             * Gets Brightcove Video's onStatusChange method (mapped to a slide index)
             * @param {Number} index - The index on which to attach the onStatusChange method to
             * @returns {Function}
             * @private
             */
            _getOnStatusChangeMethod: function(index) {
                return _.bind(function (e) {
                    this.onVideoStatusChange(e, index);
                }, this);
            },

            /**
             * Gets the currently active slide index.
             * @returns {*|SendStream}
             */
            getActiveSlideIndex: function () {
                return this._activeSlide;
            },

            /**
             * Shows and hides the next/prev arrows based on the index provided.
             * @param {Number} currentIndex
             */
            updateArrows: function (currentIndex) {
                var sel;
                if (this.$slides.length <= 1) {
                    return;
                }
                this.$arrows.addClass('gallery-nav-active');
                if (currentIndex === 0) {
                    sel = 'prev';
                } else if (currentIndex === this.$slides.length - 1) {
                    sel = 'next';
                }
                this.$arrows.filter('.carousel-' + sel).removeClass('gallery-nav-active');
            }
        });

        /**
         * Return view class.
         */
        return VideoGallery;
    }
);
