/**
 * @fileoverview Insider More Deals view module.
 * @author rcopley@gannett.com (Ryan Copley)
 */
define('modules/insider-more-deals',[
    'jquery',
    'underscore',
    'baseview',
    'user-manager'
],
function(
    $,
    _,
    BaseView,
    UserManager
) {

        /**
         * View class.
         */
         //Used to restore the meta viewport when we destroy
        var InsiderMoreDealsView = BaseView.extend({
            /**
             * Initialize view.
             */
            initialize: function() {
                // show the content specific to each login status
                this._toggleSubscribeContent();

                // call base class initialize
                BaseView.prototype.initialize.call(this);
            },
            _toggleSubscribeContent: function() {
                var userAccount = UserManager.getAccount('firefly');
                if (userAccount) {
                    userAccount.getUserInfo().always(_.bind(function (userInfo) {
                        if (userInfo && userInfo != "Not logged in." && userInfo.hasMarketAccess === true) {
                            this.$(".js-fwi-front-fwifm-loggedin-only").removeClass("js-fwi-front-fwifm-loggedin-only");
                        }else{
                            this.$(".fwi-front-login-btn").click();
                        }
                    }, this));
                }
            }

        });

        /**
         * Return view class.
         */
        return InsiderMoreDealsView;
    }
);
