/*global googletag:true*/

define('modules/partner/poster-ad-asset2',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'site-manager',
    'utils',
    'adPosition'
], function(
    $,
    _,
    BaseView,
    StateManager,
    SiteManager,
    Utils,
    AdPosition
) {
    'use strict';

    var PosterAd2 = BaseView.extend({

        /*
         * @param {object}  [options] -
         *      minTimeForAd:    {num} minimum amount of milliseconds before refreshing the ad call
         *      topMargin:       {num} The margin above the poster scroll and below the header when the
         *                        ad is in a "fixed" position (docked to the top)
         *      disableRefresh:  {Boolean} Setting to True disables the refreshing action of the ad,
         *                        a requirement for USAT
         */

        initialize: function(options) {
            _.bindAll(this, '_overlayScrollListener', '_onSecondAdReady', '_handleResizeWindow');
            options = $.extend({
                minTimeForAd: 5000,
                topMargin: 30,
                articleSelector: 'article > div[role=main]',
                rightRailSelector: '.story-right-rail',
                adPlacement: 'poster_scroll'
            }, options);

            var activeApp = StateManager.getActiveApp();

            this.$win = Utils.get('win');
            this.$body = Utils.get('body');
            this.$moreStories = $('.more-stories-wrap.vertical');
            this.header = SiteManager.getHeader();
            this.partnerScrollHeight = 0;

            /* handle breaking bar opening/closing and gravity ad*/
            this.pubSub = {
                'breakingbar:after:open': this._overlayScrollListener,
                'breakingbar:after:close': this._overlayScrollListener,
                'gravity:open': this._handleResizeWindow
            };

            this.$adEl = this.$('.poster-scroll-ad');
            this.$adScroll = this.$('.poster-scroll');
            this.$otherAds = activeApp.$('.partner-asset-right-ad,.datasphere-community-module');
            this.$rightRail = this.$el.closest(options.rightRailSelector);

            this.$body.on('bx_impression.' + this.cid, this._setHeight());
            StateManager.registerScrollListener('poster-scroll' + this.cid, this._overlayScrollListener, 100);
            StateManager.registerResizeListener('poster-scroll' + this.cid, this._handleResizeWindow, 500, true);

            BaseView.prototype.initialize.call(this, options);

            this.subviews.ad = new AdPosition({
                el: this.$adEl,
                adSizes: [[300,250], [300,600], [300,1050]],
                adPlacement: this.options.adPlacement,
                adType: 'poster',
                lazy: true,
                onAdReady: this._onSecondAdReady,
                pageInfo: StateManager.getActivePageInfo()
            });
        },

        _onSecondAdReady: function() {
            this.subviews.ad.show();
            this._setHeight();
            this._overlayScrollListener();
            if (this.state === 'dockBottom') {
                this._dockBottom();
            }
        },

        /*
         * Our Scroll Listener
         *
         */
        _overlayScrollListener: function() {
            this.moduleOffsetTop = this.$el.offset().top;

            if (this._getModuleHeight() < (this.partnerScrollHeight || 250)) {
                // If ad is smaller than available space, we will display it always docked to top
                this._goToState('dockTop');
            } else {
                this._goToState(this._getCurrentState());
            }
        },

        /*
         * Our Ad can be in 3 states, depending on other states of
         * the current viewport, modules, and other ads:
         *
         * 1.   'dockTop' = In its place in the natual
         *       flow of the document, at the top of the
         *       module
         * 2.   'fixed' = Docked to the top of the viewport
         * 3.   'dockBottom' = Docked to the bottom of the
         *       sidebar/article
         */
        _getCurrentState: function() {
            if (this._shouldDockBottom()) {
                return 'dockBottom';
            } else if (this._shouldFixAd()) {
                return 'fixed';
            } else {
                return 'dockTop';
            }
        },

        _goToState: function(state) {
            if (state === this.state) {
                return false;
            }

            if (state === 'fixed') {
                this.$adScroll.css({
                    position: 'fixed',
                    top: this._getHeaderHeight() + this.options.topMargin
                });
            } else if (state === 'dockTop') {
                this.$adScroll.css({
                    position: 'static',
                    top: 'auto'
                });
            } else if (state === 'dockBottom') {
                this._dockBottom();
            }
            this.state = state;

            if(state === 'fixed' || state === 'dockBottom') {
                // We are going to doublecheck, after re setting the dimensions and running through again
                this._setDimensions();
                this._goToState(this._getCurrentState());
            }
        },

        /**** GETTERS/ENVIRONMENT CHECKERS ***/

        _setDimensions: function() {
            this._setHeight();
            this._setArticleDimensions();
        },

        _shouldFixAd: function() {
            return (this.moduleOffsetTop - this.options.topMargin) <= this._getViewportOffset();
        },

        _getViewportOffset: function() {
            return Utils.getScrollPosition() + this._getHeaderHeight();
        },

        _shouldDockBottom: function() {
            var scrollThresholdForDocking = this._getModuleOffsetBottom() - this.partnerScrollHeight - this.options.topMargin;
            return scrollThresholdForDocking < this._getViewportOffset();
        },

        _getHeaderHeight: function() {
            if (this.header) {
                return this.header.getFixedHeight();
            }
            return 0;
        },

        /**
         * height of this module should be from its top
         * to the bottom of the sidebar. It should take up
         * the entire space that is left in the sidebar. Since
         * can't set a height that is 100% of available space,
         * need to use this function to get what the height is.
         *
         * (article offset top + article height) - module offset top = height
         *
         * returns {Integer} height from top of module to bottom of sidebar
         * @private
         */
        _getModuleHeight: function() {
            return this.articleOffsetTop + this.articleHeight - this.moduleOffsetTop;
        },

        _getModuleOffsetBottom: function() {
            return this.moduleOffsetTop + this._getModuleHeight();
        },

        /**** Handlers/Actions ***/

        _handleResizeWindow: function() {
            this.winHeight = this.$win.height();
            this._setArticleDimensions();
        },

        _dockBottom: function() {
            this.$adScroll.css({
                position: 'absolute',
                top: this.articleHeight - this.partnerScrollHeight + this.railPositionTop
            });
        },

        _setArticleDimensions: function() {
            var $articleBody = StateManager.getActiveApp().$(this.options.articleSelector);
            if ($articleBody.length) {
                this.articleHeight = $articleBody.height();
                this.articleOffsetTop = $articleBody.offset().top;
                this.railPositionTop = this.$rightRail.position().top;
            }
            if(this.$moreStories.length) {
                this.articleHeight += this.$moreStories.height();
            }
        },
        
        _setHeight: function() {
            this.partnerScrollHeight = this.$adScroll.outerHeight();
        },

        /**
         * Clean up view.
         * Removes event handlers and element (optionally).
         * @param {boolean} removeEl option to also remove View from DOM.
         */
        destroy: function(removeEl) {
            this.$body.off('.' + this.cid);
            StateManager.unRegisterScrollListener('poster-scroll' + this.cid);
            StateManager.unRegisterResizeListener('poster-scroll' + this.cid);
            if (this.refreshTimeout) {
                window.clearTimeout(this.refreshTimeout);
            }
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });
    return PosterAd2;
});
