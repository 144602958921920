/**
 * @fileoverview Player News module view.
 * @author Kent Lee <kklee@usatoday.com>
 */
define('modules/sports/sports-player-news',[
    'jquery',
    'underscore',
    'utils',
    'state',
    'pubsub',
    'baseview',
    'form/autocomplete'
],
function(
    $,
    _,
    Utils,
    StateManager,
    PubSub,
    BaseView,
    AutoComplete
) {

    var PlayerNewsView = BaseView.extend({

        el: '.player-news-search-form',

        events: {
            'click .button-add-content.player-news': 'showMoreNews'
        },

        initialize: function(options) {
            this.itemsPerPage = this.$el.data('itemsPerPage');
            this.$button = this.$('.button-add-content');
            this.section = Utils.getSectionPath(window.location.pathname) + 'playernews';

            if (location.href.indexOf('#')) {
                var anchor = $('.news'+location.href.split('#')[1]);
                if (anchor.parent().hasClass('inactive')) {
                    anchor.parent().removeClass('inactive').addClass('active');
                }
                if (anchor.length) {
                    var anchorTop = anchor.offset().top;
                    this.scrollEl = Utils.get('scrollEl');
                    this.scrollEl.animate({'scrollTop': (anchorTop - 100)}, 700);
                }
            }

            BaseView.prototype.initialize.call(this, options);
            _.bindAll(this, 'onSearchSelect');

            this.$lookupInput = this.$('.player-news-search-form-input');
            this.subviews.autocomplete = new AutoComplete({
                el: this.$lookupInput,
                resultDisplayTemplate: '<%= full_name %>',
                resultValueTemplate: '<%= full_name %>',
                onSelect: this.onSearchSelect
            });
        },

        onSearchSelect: function(value, data){
            var playerName = data.safe_name;
            this.setPlayerName(playerName);
            this.$el.submit();
        },
        setPlayerName: function(playerName){
            this.$$('.player-news-search-player-input').val(playerName);
        },
        getPlayerName: function(){
            return this.$$('.player-news-search-player-input').val();
        },

        /**
         * Shows the next block of player news.
         * @param {Event} event View click event.
         */
        showMoreNews: function(event) {
            if ($(event.target).hasClass('inactive')) return false;
            this.$button = this.$('.button-add-content');
            this.$button.addClass('loading').text(this.$button.attr('data-loading'));
            var $nextPage = this.$('.page.active:last').next();
            var ditemnum = $nextPage.attr("data-itemnum");
            var ditemperpage = $nextPage.attr("data-items-per-page");
            var ditemsmax = parseInt($nextPage.attr("data-max-items"), 10);
            var itemsloaded = parseInt($nextPage.attr("data-items-loaded"), 10);
            var totalitems = parseInt(ditemnum, 10) + parseInt(ditemperpage, 10);
            var lastdate = $nextPage.attr("data-timestamp");
            var $newsBlock = $nextPage.parent();
            if (((totalitems % itemsloaded) === 0) && (totalitems < ditemsmax))
                this.loadMoreNews($newsBlock, lastdate, totalitems);
            var count = Math.round(ditemnum/ditemperpage);
            $nextPage.find('img').each(function() {
                var $me = $(this);
                $me.attr('src', $me.attr('data-src'));
            });
            PubSub.trigger('uotrack', this.section+'morestories'+count);
            this.transitionMoreNews($nextPage);
            event.preventDefault();
        },

        transitionMoreNews: function($nextPage) {
            if (Modernizr.csstransitions) {
                $nextPage.removeClass('inactive').addClass('active');
            } else {
                $nextPage.animate({
                    'max-height': '5000px'
                }, 200, function() {
                    $(this).removeClass('inactive').addClass('active').css('max-height', '');
                });
            }
            if (this.$('.page.inactive').length <= 0) {
                // no more items to show, inactivate the show more button
                this.$button.addClass('inactive');
            }
            this.$button.removeClass('loading').text(this.$button.attr('data-default'));
            PubSub.trigger('showmore:headlines');
        },

        loadMoreNews: function($newsBlock, lastDate, lastCount) {
            var url = $newsBlock.attr("data-url") + '?before=' + lastDate + '&lastcount=' + lastCount;
            StateManager.fetchHtml(url).done(_.bind(function(html) {
                if (html && html.length) {
                    $newsBlock.append(html);
                    // reactivate the button after new stories loaded
                    this.$button.removeClass('inactive');
                }
            }, this));
        },

        onFormSubmit: function(e){
            if (!this.getPlayerName()) {
                //e.preventDefault();
                if (this.$lookupInput.val()){
                    var playerName = this.$lookupInput.val();
                    var url = this.$lookupInput.data('autocomplete-url') + playerName;
                    StateManager.fetchData(url).done(_.bind(function(data){
                        if (data && data.length){
                            this.$el.submit();
                        }
                    }, this));
                }
            }
        },

        destroy: function(removeEl){
            this.$$('.player-news-search-player-input').val('');
            this.$lookupInput.blur();
            BaseView.prototype.destroy.call(this, removeEl);
        }

    });

    return PlayerNewsView;
});
