define('modules/stories/oembed',[
    'jquery',
    'baseview',
    'utils'
],
function(
    $,
    BaseView,
    Utils
) {
    var OEmbed = BaseView.extend({

        initialize: function(options) {
            if (this.$el.attr('data-oembed-type') === 'rich' && this.$el.attr('data-oembed-provider') === 'twitter') {
                Utils.loadScript('https://platform.twitter.com/widgets.js', 'twttr', function(twttr){
                    twttr.widgets.load();
                });
            }
            BaseView.prototype.initialize.call(this, options);
        }
    });
    return OEmbed;
});

