define('partner/gravity-ad',[
    'jquery',
    'underscore',
    'utils',
    'pubsub',
    'partner/high-impact-base',
    'site-manager',
    'adLogger'
],
function(
    $,
    _,
    Utils,
    PubSub,
    HighImpactBase,
    SiteManager,
    AdLogger
) {
    'use strict';
    var GravityView = HighImpactBase.extend(
    /**
     * @lends partner/pushdown-ad.prototype
     */
    {
        haveBreakingNews: false,
        pubSub: {},
        state: 'stopped',
        events: {
            'click .partner-scroll': 'scrollPage'
        },
        scopedBind: _.uniqueId('gravity'),

        /**
         * @classdesc Gravity ad type, this is a {@link partner/ad-position}.
         *          The el for this view needs to contain both .partner-gravity-ad.partner-placement as well as the styles included in partner.css
         * @constructs partner/gravity-ad
         * @author Jay Merrifield <jmerrifiel@gannett.com>
         * @author Chad Shryock <cdshryock@gannett.com>
         * @author Jordan Manwaring <jmanwaring@gannett.com>
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector that contains both the gravity and leave behind divs
         */
        initialize: function(options) {
            options = $.extend({
                adClasses: '',
                maxDisplayed: 2
            }, options);

            _.bindAll(this, 'onAdReady', 'onResizeWindow', 'onScrollWindow', 'scrollPage');

            // call base class initialize
            HighImpactBase.prototype.initialize.call(this, options);

            this.header = SiteManager.getHeader();
            if(this.header && this.header.subviews) {
                this.breakingbar = this.header.subviews.breakingbar;
            }

            this.$body = Utils.get('body');
            this.$window = Utils.get('win');
            this.$partnerScroll = this.$('.partner-scroll');
            this._createAd(['gravity'], 'gravity');
            AdLogger.logInfo('Gravity Advertisment initialized', this);
        },

        /**
         * Only when a Gravity ad has been delivered to the page does this gets triggered.
         * This happens before the ad is shown
         */
        beforeAdRender: function(){
            //set initialheight and initialwidth of ad
            this.windowWidth = this.$window.width();
            this.windowHeight = this.$window.height();
            this.subviews.adPosition.setInitialDimensions(this.windowWidth, this.windowHeight);
            HighImpactBase.prototype.beforeAdRender.apply(this, arguments);
        },

        destroy: function(removeEl) {
            // no need to cleanup ad, it gets cleaned up by the ad position
            this.closeAd();
            if (this.header) {
                this.header.destroyTransparency();
                this.header.restoreLastState();
            }
            if(this.shiftDownTimeout) {
                clearTimeout(this.shiftDownTimeout);
            }
            //clean up scroll button
            HighImpactBase.prototype.destroy.call(this, removeEl);
        },

        showAd: function(reopened) {
            this.showingAd = true;

            //add our window events after trigger ready has been called by loaded ad
            var throttledResize = _.throttle(this.onResizeWindow, 50),
                throttleScroll = _.throttle(this.onScrollWindow, 50);
            this.$window.on('resize.' + this.scopedBind, throttledResize).on('scroll.' + this.scopedBind, throttleScroll);

            this.pubSub = {
                'breakingbar:before:open': this.onResizeWindow,
                'breakingbar:before:close': this.onResizeWindow
            };
            PubSub.attach(this.pubSub, this);

            this.onResizeWindow();
            this.subviews.adPosition.show();

            this.options.contentSection.css('margin-top', this.cardOffset);
            this.onScrollWindow();

            this.$body.addClass('high-impact-ad-visible');
            this.header.setClosedFixed(true);

            //hide leavebehind
            if (this.subviews.adLeaveBehind) {
                this.subviews.adLeaveBehind.hide();
            }

            this.setBreakingNewsBarrier();

            PubSub.trigger('gravity:open');
        },

        setBreakingNewsBarrier: function() {
            if(!this.breakingNewsBarrier || this.breakingNewsBarrier.state() === 'resolved') {
                this.breakingNewsBarrier = new $.Deferred();
                this.breakingbar.addToBarrier(this.breakingNewsBarrier, 999999, false);
            }
        },

        getHeaderOffset: function(){
            if (this.header) {
                return this.header.getCollapsedHeight();
            } else {
                return 0;
            }
        },

        closeAd: function() {
            if (!this.subviews.adPosition || !this.adData){
                return;
            }

            this.showingAd = false;
            this.subviews.adPosition.stopAd();
            if(this.breakingNewsBarrier) {
                this.breakingNewsBarrier.resolve();
            }
            this.options.contentSection.css({'margin-top': '', 'position': ''});
            this.$body.removeClass('high-impact-ad-visible');

            this.$window.off('.' + this.scopedBind);
        },

        onResizeWindow: function(){
            this.windowWidth = this.$window.width();
            this.windowHeight = this.$window.height();

            if (!this.subviews.adPosition){
                return false;
            }

            this.subviews.adPosition.resizeAd(this.windowWidth, this.windowHeight);

            this.cardOffset =  this.windowHeight - this.getHeaderOffset();
            this.options.contentSection.css('margin-top', this.cardOffset);

            // Our scroll position may have changed, in relation to the ad
            this.onScrollWindow();
        },

        onScrollWindow: function() {
            var scrollTop = this.$window.scrollTop(),
                headerHeight = this.header.getCurrentHeight(),
                scrollPercentage = (this.windowHeight - scrollTop - headerHeight) / (this.windowHeight - headerHeight),
                opacity = (scrollPercentage > 0) ? scrollPercentage : 0;

            this.$el.css('opacity', opacity);

            if (opacity < 0.1 && this.state === 'playing') {
                this.state = 'paused';
                if (this.subviews.adPosition) {
                    this.subviews.adPosition.pauseAd();
                    //resolve the breaking news barrier
                    this.breakingNewsBarrier.resolve();
                    //if we already resolved and had a breakingbar on the page, we'll open it as well
                    this.expandHeaderItems();
                }
                this.header.toggleTransparency(false);
            } else if (opacity > 0.1 && this.state === 'paused') {
                this.state = 'playing';
                if (this.subviews.adPosition) {
                    this.subviews.adPosition.resumeAd();
                    this.collapseHeaderItems();
                }
                this.header.toggleTransparency(true);
            } else if (opacity > 0.1 && this.state === 'stopped') {
                this.state = 'playing';
                if (this.subviews.adPosition) {
                    this.subviews.adPosition.playAd();
                    this.collapseHeaderItems();
                }
                this.header.toggleTransparency(true);
            }

            if (opacity === 0) {
                this.$el.css('display', 'none');
            } else {
                this.$el.css('display', 'block');
            }
            if(this.onArticleScroll) {
                this.onArticleScroll(opacity, headerHeight);
            }
        },

        collapseHeaderItems: function() {
            this.haveBreakingNews = this.breakingbar.closeBreakingBar();
            this.setBreakingNewsBarrier();
            this.header.hideNano();
        },

        expandHeaderItems: function() {
            if(this.haveBreakingNews) {
                this.breakingbar.openBreakingBar();
            }
            this.header.showNano();
        },

        shiftContentUp: function() {
            var cardTop = this.cardOffset - this.header.getCollapsedHeight() - 30;
            this.options.contentSection.animate({'margin-top': cardTop}, 350);
            this.shiftDownTimeout = setTimeout(_.bind(function() {
                this.shiftContentDown();
            }, this), 5000);
        },

        shiftContentDown: function() {
            var content = this.options.contentSection;
            $.when( this.animate(content, 'margin-top', this.cardOffset, 500),
                    this.animate(this.$partnerScroll, 'margin-top', -75, 500))
                .done(_.bind(function(){
                    this.$partnerScroll.css('margin-top', '').addClass('shifted');
            }, this));
        },

        scrollPage: function(e) {
            e.preventDefault();
            var scrollTo = this.windowHeight - this.getHeaderOffset();
            this.expandHeaderItems();
            Utils.get('scrollEl').animate({'scrollTop': scrollTo}, 500);
        }
    });
    return GravityView;

});

