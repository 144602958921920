define('modules/videos/pluto/pluto-scroll',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'modules/videos/pluto/pluto-video-bar',
    'modules/videos/pluto/pluto-analytics'
],
function(
    $,
    _,
    BaseView,
    Utils,
    PlutoVideoBar,
    PlutoAnalytics
) {
    "use strict";
    var PlutoScroll = BaseView.extend({
        /***
         * Makes a video scrollable
         */
        initialize: function(options) {
            options = $.extend({
                videoPlayer: null,
                videoData: null,
                toggleVolume: null,
                plutoVideo: null,
                placementData: null
            }, options);

            BaseView.prototype.initialize.call(this, options);
            this.$win = Utils.get('win');

            this.vidHeight = this.$el.height();
            //-40 for navbar height offset. If scrollBreakpoint is .75 the video will go into scrolling position when
            //user has scrolled through 75% of the video.
            this.scrollBreakpoint = (this.vidHeight * parseFloat(Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'scrollBreakpoint'))) - 40;
            this.adBlacklist = Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'adBlacklist');
            this.playerIsScrolling = false;
            this.scrollListenerAttached = false;
            this.$transitionWrap = $('.transition-wrap');
            this.$videoPlacement = $('.js-pvb-video');
            this.$siteWrap = $('.site-header-inner-wrap');
            this.$breakingNews = $('.bnm-container');
            this.$alertsManager = $('.alerts-manager-container');
            //pvb = pluto video bar
            this.$pvb = $('.js-pvb');

            this.attachListeners();

            this.subviews.videoBar = new PlutoVideoBar({
                plutoVideo: this.options.plutoVideo,
                onClose: _.bind(this.slideVideoIn, this)
            });
            this.setupControls();
        },

        attachListeners: function() {
            if (!this.scrollListenerAttached) {
                this.$win.on('scroll.' + this.cid, _.bind(_.throttle(this.onScroll, 100), this));
                this.$win.on('resize.' + this.cid, _.bind(_.throttle(this.handleResize, 50), this));
                this.scrollListenerAttached = true;
            }
        },

        blackListed: function(){
            var match = false;
            if (this.options.plutoVideo.isAdPlaying()) {
                var currentAd = this.options.plutoVideo.currentAd();
                if (currentAd && currentAd.id) match = $.inArray(currentAd.id, this.adBlacklist) > -1;
            }
            return match;
        },

        onScroll: function() {
            var scrollPos = this.$win.scrollTop();
            if (scrollPos > this.$el.offset().top + this.scrollBreakpoint) {
                if (!this.playerIsScrolling && this.options.plutoVideo.isPlaying() && !this.blackListed() && !this.options.plutoVideo.pauseOnLoad) {
                    this.slideVideoOut();
                    window.videoIsScrolling = true;
                }
            } else {
                if (this.playerIsScrolling) {
                    PlutoAnalytics.trackVideoEvents('scrollingplayercollapse');
                    this.slideVideoIn(false);
                    window.videoIsScrolling = false;
                }
            }
        },

        slideVideoOut: function() {
            this.animatePromise = $.Deferred();
            //can't cache this in init since brightcove takes a sec to initialize the video & update markup
            this.getVideoElement();
            this.playerIsScrolling = true;

            this.toggleControls(false);
            var position = this.$videoPlacement.position();
            this.$video.addClass('pvb-video-docked').css({
                left: position.left,
                'height': 0
            }).animate({'height': 96}, 250, _.bind(function() {
                this.animatePromise.resolve();
            }, this));
            this.options.videoPlayer.dimensions(170, 96);

            this.moveBreakingBar();
            this.movePrivacyPolicy();
            if (!this.subviews.videoBar.open){
                this.subviews.videoBar.expand();
            }
        },

        slideVideoIn: function(pauseVideo) {
            this.animatePromise.done(_.bind(function() {
                this.getVideoElement();
                this.playerIsScrolling = false;
                var original = this.$el.css(['height', 'width']);
                this.toggleControls(true);
                this.$video.removeClass('pvb-video-docked').css({top: "", left: ""});
                this.options.videoPlayer.dimensions(original.width, original.height);
                this.subviews.videoBar.collapse();
                
                this.revertBreakingBar();
                this.revertPrivacyPolicy();
                if (pauseVideo) {
                    //Only true when scrolling player is closed while ad or video loads
                    if (this.options.plutoVideo.buffering) {
                        this.options.plutoVideo.pauseOnLoad = true;
                    } else if (this.options.plutoVideo.isAdPlaying()) {
                        this.options.plutoVideo.pauseAd();
                    } else {
                        this.options.plutoVideo.pauseVideo();
                    }
                }
            }, this));
        },

        updateVideoBar: function(video) {
            this.subviews.videoBar.options.plutoVideo = video;
            this.$video = null;
            this.setElement(video.el);
            this.subviews.videoBar.resetVideoBar(video);
        },

        setupControls: function() {
            this.subviews.videoBar.setTitle(this.options.videoData.title);
        },

        toggleControls: function(show) {
            this.options.videoPlayer.controls(show);
        },

        getVideoElement: function() {
            if (!this.$video) {
                this.$video = this.$('.video-js');
            }
        },

        handleResize: function() {
            this.getVideoElement();
            if (this.playerIsScrolling) this.$video.css({left: this.$videoPlacement.position().left});
        },

        updateVideoBarTitle: function(title) {
            this.subviews.videoBar.setTitle(title);
        },

        onVolumeChange: function(muted) {
            this.subviews.videoBar.onVolumeChange(muted);
        },

        moveBreakingBar: function() {
            this.$breakingNews.appendTo(this.$pvb);
        },

        revertBreakingBar: function() {
            this.$breakingNews.appendTo(this.$siteWrap);
        },

        movePrivacyPolicy: function() {
            this.$pvb.after(this.$alertsManager);
        },

        revertPrivacyPolicy: function() {
            this.$alertsManager.appendTo(this.$siteWrap);
        },

        onAdPlay: function() {
            this.subviews.videoBar.onAdChange(false);
        },

        onAdEnd: function() {
            this.subviews.videoBar.onAdChange(true);
        },

        onPause: function() {
            //seeking a video pauses but if it is playing we don't want to show play button
            if (!this.options.plutoVideo.isSeeking()) {
                this.subviews.videoBar.showPlayButton();
            }
        },

        onVideoPlay: function() {
            if (this.options.plutoVideo.isPlaying()) {
                this.subviews.videoBar.showPauseButton();
            }
        },

        onFullScreenChange: function() {
            if (this.playerIsScrolling) {
                this.toggleControls(this.options.plutoVideo.isFullScreen());
            }

            if ((this.options.plutoVideo.sv.share && this.subviews.videoBar.sv.share && this.options.plutoVideo.sv.share.open) || this.options.plutoVideo.embedOpen) {
                this.slideVideoIn();
            }

        },

        destroy: function() {
            this.revertBreakingBar();
            this.revertPrivacyPolicy();
            delete window.videoIsScrolling;
            this.$win.off('.' + this.cid);
            BaseView.prototype.destroy.apply(this, arguments);
        }
    });

    return PlutoScroll;
});
