define('modules/partner/taboola-poster-asset',[
    'baseview'
],
function(
    BaseView
) {
    'use strict';

    return BaseView;
});

