/*global Modernizr:true*/
define('modules/partner/story-transition-ad',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'site-manager',
    'utils',
    'admanager',
    'partner/overlay-ad-asset'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    SiteManager,
    Utils,
    AdManager,
    OverlayAssetAd
) {
    'use strict';

    var TransitionAd = BaseView.extend({
        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);
            this.renderAd();
        },

        renderAd: function() {
            var referrer = StateManager.getReferrer(),
                pageInfo = StateManager.getActivePageInfo(),
                hasAutoplay = Utils.hasAutoplayVideo(pageInfo),
                ssts = (pageInfo && pageInfo.ssts) ? pageInfo.ssts : '',
                isReviewed = ssts.match('reviewedcom'),
                isRoadblock = ssts === 'get-access',
                overlayAdObj = {
                    el: this.$el,
                    pageInfo: pageInfo,
                    getHeaderOffset: function(){
                        var header = SiteManager.getHeader();
                        if (header) {
                            return header.getExpandedHeight();
                        } else {
                            return 0;
                        }
                    }
                };
            // We've been initialized, which means we're in one of these 4 scenarios
            // 1: transition_referrer - trigger ad
            // 2: !Modernizr.history - trigger ad
            // 3: StoryTransitionAd.getCurrentMeterCount() === 0 - trigger ad
            // 4: StoryTransitionAd.getCurrentMeterCount() !== 0 - just tick meter
            // Exceptions
            // 1: Gravity ad loaded on page
            var triggerAd = false;

            // scenario 1
            // Trigger a transition_referrer ad if we're coming from a front
            var pathIsFront = function (path) {
                var exclusions = ['/search/', '/story/', '/media/'];
                if (!path) {
                    // Blank or null paths should be treated as a front
                    return true;
                }
                for (var i = 0; i < exclusions.length; i++) {
                    // If our path starts with any excluded root paths, we're not on a front
                    if (path.indexOf(exclusions[i]) === 0) {
                        return false;
                    }
                }
                return true;
            };

            if (referrer && pathIsFront(StateManager.lastUrl)) {
                // Referred from outside domain or front
                overlayAdObj.adPlacement = 'transition_referrer';
                overlayAdObj.rateMeterId = 'transition_referrer';
                triggerAd = true;
            }

            this.subviews.transitionAd = new OverlayAssetAd(overlayAdObj);
                
            // scenario 2
            if (!Modernizr.history) {
                triggerAd = true;
            }

            // scenario 3
            if (this.subviews.transitionAd.getCurrentMeterCount() === 0) {
                triggerAd = true;
            }

            AdManager.getActiveSlotStatistics().done(_.bind(function(info) {
                if (this.destroyed) {
                    return;
                }

                // Exception : don't trigger if Gravity is on page, reset counter if triggerAd was true
                if (_.findWhere(info.high_impact_info, {'adType': 'gravity'}) && triggerAd) {
                    triggerAd = false;
                    this.subviews.transitionAd.resetMeter('transition');
                }

                // If we are ready to trigger the ad and there is no videos on the page or it's not a reviewed.com article or paywall roadblock
                if (triggerAd && !hasAutoplay && !isReviewed && !isRoadblock) {
                    this.subviews.transitionAd.triggerAd();
                } else {
                    // tickMeter, don't reset, always tick transition
                    this.subviews.transitionAd.tickMeter(true, 'transition');
                }

                if (overlayAdObj.rateMeterId === 'transition_referrer') {
                    // transition_referrer meter is guarenteed to show an ad,
                    // so we reset the transition meter, so the transition_referrer ad acts like a transition ad
                    // and resets the transition meter to the high water mark
                    this.subviews.transitionAd.resetMeter('transition');
                }

            }, this));
        },

        getTransitionMeterCount: function(){
            if (this.subviews.transitionAd) {
                return this.subviews.transitionAd.getCurrentMeterCount('transition');
            }
            return 0;
        }
    });
    return TransitionAd;
});
