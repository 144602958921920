/* global define */
define('modules/fronts/front-list',[
    'jquery',
    'underscore',
    'utils',
    'state',
    'baseview',
    'adPosition',
    'pubsub',
    'modules/fronts/front-list-video',
    'modules/partner/branded-content-tile'
],
function(
    $,
    _,
    Utils,
    StateManager,
    BaseView,
    AdPosition,
    PubSub,
    FrontListVideo,
    BrandedContentTile
)
{
    'use strict';
    var FrontList = BaseView.extend({

        events: {
            'click .js-video-wrap': '_onVideoClick'
        },

        initialize: function(options) {
            _.bindAll(this, '_onScroll', '_onScrollDepthScroll', '_checkScrollPositionAndDestroyInlineVideoIframe', '_onVideoClick');
            var $flmWrap = this.$('.flm-module-wrap');
            options = $.extend({
                viewportBuffer: 1000,
                adConfigs: {
                    leaderboard: {
                        adType : 'leaderboard',
                        adSizes : ['leaderboard'],
                        adPlacement : 'leaderboard_btf'
                    },
                    mediumrectangle: {
                        adType: 'poster',
                        adSizes: ['mediumrectangle'],
                        adPlacement: 'poster_front_btf'
                    }
                },
                adType: $flmWrap.attr('data-ad-type'),
                lazyLoadEnabled: $flmWrap.hasClass('js-lazy-load'),
                adCleanupEnabled: $flmWrap.hasClass('js-ad-cleanup'),
                scrollDepthTrackingMilestone: parseInt(this.$('.flm-module-wrap').attr('data-scroll-depth-tracking-milestone'), 10)
            }, options);
            this.$win = Utils.get('win');
            this.subviews = {};
            this.$adPositions = this.$$('.flm-ad-position');
            this.$activeVideos = $([]);
            this.pubSub = {
                'exited:view:flmvideo': this.onInlineVideoExitView
            };

            BaseView.prototype.initialize.call(this, options);

            this._initAds();
            this._initScrollHandlers();
        },

        _onVideoClick: function(e) {
            e.preventDefault();
            var $el = $(e.currentTarget),
                videoId = $el.find('.js-uw-iframe-video').attr('data-video-id'),
                subViewId = 'flm-video-' + videoId;
            if (videoId && !this.subviews[subViewId]) {
                PubSub.trigger('track', {
                    label: $el.attr('data-track-label')
                });
                this.$activeVideos = this.$activeVideos.add($el);
                this.subviews[subViewId] = new FrontListVideo({
                    el: $el
                });
                if (this.options.lazyLoadEnabled && StateManager.observer) {
                    $el.addClass('js-llc js-llp').attr('data-name', 'flmvideo');
                    StateManager.observer.observe(e.currentTarget);
                }
            }
        },

        _initScrollHandlers: function() {
            if (!this.options.lazyLoadEnabled) {
                StateManager.registerScrollListener('front-list-' + this.cid, this._onScroll, 1000);
                this._onScroll();
            }
            if (this.options.scrollDepthTrackingMilestone) {
                this._initScrollDepthScrollHandler();
            }
        },

        _initScrollDepthScrollHandler: function() {
            this.scrollDepthTrackingMilestones = [];
            this.scrollDepthAtInit = this.$win.scrollTop();
            if (this.scrollDepthAtInit > 0) {
                var scrollDepthMilestone = this._calculateScrollDepthMilestone();
                if (scrollDepthMilestone > 0) {
                    this.scrollDepthTrackingMilestones.push(scrollDepthMilestone);
                }
            }
            StateManager.registerScrollListener('front-list-scroll-depth-' + this.cid, this._onScrollDepthScroll, 100);
            this._onScrollDepthScroll();
        },

        _calculateScrollDepthMilestone: function() {
            return Math.floor((this.$win.scrollTop() / this.options.scrollDepthTrackingMilestone));
        },

        _onScroll: function() {
            this.$activeVideos.each(this._checkScrollPositionAndDestroyInlineVideoIframe);
        },

        _onScrollDepthScroll: function() {
            if (this.options.scrollDepthTrackingMilestone && this.$win.scrollTop() > this.scrollDepthAtInit) {
                this._checkScrollPositionAndTrackScrollDepth();
            }
        },

        _checkScrollPositionAndTrackScrollDepth: function() {
            var scrollDepthMilestone = this._calculateScrollDepthMilestone();
            if (scrollDepthMilestone > 0 && !_.contains(this.scrollDepthTrackingMilestones, scrollDepthMilestone)) {
                this.scrollDepthTrackingMilestones.push(scrollDepthMilestone);
                PubSub.trigger('track', {
                    label: 'scrollmilestone|' + (scrollDepthMilestone * this.options.scrollDepthTrackingMilestone)
                });
            }
        },

        _initAds: function() {
            this.$adPositions.each(_.bind(function(idx, el) {
                this.createAd(el);
            }, this));
            if(Utils.getNested(window.site_vars, 'ADS', 'branded_content_tile', 'enabled') === true && !this.subviews.bcTile) {
                this.subviews.bcTile = new BrandedContentTile({
                    el: this.$('.partner-tile-ad')
                });
            }
        },

        createAd: function(el) {
            var $el = $(el),
                data = $el.data(),
                elIndex = data.itemIndex,
                lazy = !!data.lazy,
                adCount;
            if (!this.subviews['ad-' + elIndex]) {
                adCount = this.$adPositions.index($el) + 1;
                if (adCount === this.$adPositions.length) {
                    adCount = "last";
                }
                this.subviews['ad-' + elIndex] = new AdPosition({
                    el: $el,
                    adType : this.options.adConfigs[this.options.adType].adType,
                    adSizes : this.options.adConfigs[this.options.adType].adSizes,
                    adPlacement : this.options.adConfigs[this.options.adType].adPlacement,
                    lazy: lazy,
                    targeting: {
                        adCount: adCount
                    }
                });
            }
        },

        onInlineVideoExitView: function(el) {
            this._destroyInlineVideoIframe(el);
        },

        _checkScrollPositionAndDestroyInlineVideoIframe: function(i, el) {
            if (!this._isCloseEnoughToViewport(el, 300)) {
                this._destroyInlineVideoIframe(el);
            }
        },

        _destroyInlineVideoIframe: function(el) {
            var $el = $(el),
                videoId = $el.find('.js-uw-iframe-video').attr('data-video-id'),
                subViewId = 'flm-video-' + videoId;
            if (this.subviews[subViewId] && !this.subviews[subViewId].fullScreenEnabled) {
                this.$activeVideos = this.$activeVideos.not($el);
                this.subviews[subViewId].destroy();
                delete this.subviews[subViewId];
                if (this.options.lazyLoadEnabled && StateManager.observer) {
                    $el.removeClass('js-llc js-llp');
                    StateManager.observer.unobserve(el);
                }
            }
        },

        _isCloseEnoughToViewport: function(el, buffer) {
            buffer = buffer || this.options.viewportBuffer;
            var elemTop = el.getBoundingClientRect().top;
            return (elemTop >= -1 * buffer) && (elemTop <= window.innerHeight + buffer);
        },

        _removeScrollHandlers: function() {
            if (!this.options.lazyLoadEnabled) {
                StateManager.unRegisterScrollListener('front-list-' + this.cid);
            }
            if (this.options.scrollDepthTrackingMilestone) {
                StateManager.unRegisterScrollListener('front-list-scroll-depth-' + this.cid);
            }
        },

        destroy: function(removeEl) {
            this._removeScrollHandlers();
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });

    return FrontList;

});

