/*global FB:true*/
/**
 * @fileoverview Insider view module.
 * @author rcopley@gannett.com (Ryan Copley) and Dave Burke (dburke2@gannett.com)
 */
define('apps/insider',[
        'jquery',
        'underscore',
        'state',
        'base-app',
        'adPosition',
        'pubsub',
        'user-manager',
        'third-party-apis/facebook/facebook'
    ],
    function(
        $,
        _,
        StateManager,
        BaseApp,
        AdPosition,
        PubSub,
        UserManager,
        Facebook
    ) {

        /**
         * View class.
         */

        //Used to restore the meta viewport when we destroy
        var WlnaView = BaseApp.extend({

            // Events.
            events: {
                'click .util-bar-success-link-facebook': 'shareMessage',
                'click .fwinsider-details-merge' : 'trackDetailsClick'
            },

            /**
             * Initialize App.
             */
            initialize: function() {
                // register for the breaking news open and close events
                this.pubSub = {
                    'breakingbar:after:open': this.addBreakingNewsClass,
                    'breakingbar:closing': this.removeBreakingNewsClass
                };

                // updates eVar99 with LDSN
                PubSub.trigger('group_page_view', 'LDSN');
                // updates eVar66
                PubSub.trigger('product_name', 'Member Guide');

                // call base class initialize
                BaseApp.prototype.initialize.call(this);
            },

            /**
             * Add breaking news classes when breaking news bar is open.
             */
            addBreakingNewsClass: function() {
                var fwiNav = this.$('#fwi-nav');
                fwiNav.addClass('fwi-front-fwi-breaking-nav');
                fwiNav.addClass('fwi-breaking-nav');
            },

            /**
             * Remove breaking news classes when breaking news bar is closed.
             */
            removeBreakingNewsClass: function() {
                var fwiNav = this.$('#fwi-nav');
                fwiNav.removeClass('fwi-front-fwi-breaking-nav');
                fwiNav.removeClass('fwi-breaking-nav');
            },

            /**
             * afterPageReveal view.
             */
            afterPageReveal: function() {
                if (this.queryParam("hideHeader")){
                    $(".fwi-noheader a").each(_.bind(function (index, el){
                        var aTag = $(el);
                        var href = aTag.attr("href") ? aTag.attr("href") : "";
                        //Spread the wealth of hideHeader tags across the entire insider thing
                        aTag.attr("href", this.updateQueryStringParameter(href, "hideHeader", true));
                    }, this));
                    $("header").addClass("fwi-hide-header");
                }

                _.bindAll(this, 'fbShareCallback');
                this.$('.share-email-form-container').css('display', 'block');

                var bnmContainer = $('.bnm-container');
                if (bnmContainer.length > 0 && bnmContainer.html().length > 0 && bnmContainer.css('height') != '0px') {
                    this.addBreakingNewsClass();
                }

                this._toggleSubscribeContent();

                this.$sponsorshipAd = this.$('.ad-placement');
                this.$adPlacement = this.$sponsorshipAd.data('ad-placement');

                this.setupAds();

                // stop refresh timer
                StateManager.stopRefreshTimer();


                var userAccount = UserManager.getAccount('firefly');
                if (userAccount) {
                    userAccount.getUserInfo().done(_.bind(function (userInfo) {
                        var redeemButton = this.$(".fwinsider-details-merge");
                        var trackingLink = redeemButton.attr("href");
                        if (trackingLink){
                            trackingLink = trackingLink.replace("{samID}", userInfo.userInfo.response.anonymousId);
                            redeemButton.attr("href", trackingLink);
                        }
                    }, this));
                }
            },

            shareMessage: function(event) {
                //This element changes frequently and needs to ping the DOM
                this.message = this.$('.util-bar-share-message-facebook');
                var obj = {
                    method: 'feed',
                    link: this.message.data('link'),
                    picture: this.message.data('image'),
                    name: this.message.data('title')
                };
                Facebook.loadScript().done(_.bind(function() {
                    FB.ui(obj, this.fbShareCallback);
                }, this));
            },
            /**
             * Check for error or success on posting in this callback.
             * @param {Object} response Feed post response.
             */
            fbShareCallback: function(response) {
                if (response) {
                    if (response.error) {
                        this.$status.text('An error occurred, try again.');
                    } else {
                        this.$successLink.remove();
                        if (response.post_id) {
                            var postid_array = response.post_id.split('_');
                            if (postid_array.length >= 2) {
                                var linkUrl = 'https://www.facebook.com/' + postid_array[0] + '/posts/' + postid_array[1];
                                var link = $('<a class="util-bar-success-link-facebook" href="' + linkUrl + '">View the post.</a>');
                                this.$successDescription.append(link);
                            }
                        }
                        this.$flyoutSection.hide();
                        this.$successPane.show();
                        PubSub.trigger('uotrack', 'UtilityBarShareFacebook');
                    }
                }
            },

            setupAds: function() {
                if (this.$sponsorshipAd.length) {
                    this.subviews.sponsorshipAd = new AdPosition({
                        el: this.$sponsorshipAd,
                        adSizes: [300, 250],
                        adPlacement: this.$adPlacement,
                        pageInfo: StateManager.getActivePageInfo()
                    });
                }
                PubSub.trigger('advertising:defined');
            },

            _toggleSubscribeContent: function() {
                var userAccount = UserManager.getAccount('firefly');
                if (userAccount) {
                    userAccount.getUserInfo().always(_.bind(function(userInfo) {
                        var allowAnyRegisteredUser = (this.$('.sam-hider.fwinsider-article-any-registered-user').length > 0);
                        if (userInfo && userInfo !== 'Not logged in.' && (userInfo.hasMarketAccess === true || allowAnyRegisteredUser)) {
                            this.$('.sam-hider').css('display', 'block');
                            this.$('.fwil-subscriber-header').removeClass('fwil-content-hidden');
                            this.$('.fwil-subscriber-story').removeClass('fwil-content-hidden');
                        } else {
                            var onSuccessRedirectURL = (window.firefly_urls ? encodeURIComponent(window.firefly_urls.onSuccessRedirectURL) + '&amp;gps-source=CPINSIDER' : "");
                            if (userInfo && userInfo !== 'Not logged in.' && userInfo.hasMarketAccess === false) {
                                // first button and title
                                this.$('.fwinsider-article-container-details-first-btn-title').html('Gain full access to site content and enjoy exclusive deals and events!');

                                this.$('.fwinsider-article-container-details-first-btn').html('SUBSCRIBE').attr('href', window.firefly_urls.samSubscribeURL + '?onSuccessRedirectURL=' + onSuccessRedirectURL);

                                this.$('.fwinsider-article-container-details-bottom-links').addClass('fwinsider-content-hidden');
                            }

                            if (allowAnyRegisteredUser) {
                                this.$('.fwinsider-article-container-details-h2').html('Register to enjoy this special offer!');
                                this.$('.fwinsider-article-container-details-first-btn-title').html('Create a free account to unlock access.');
                                this.$('.fwinsider-article-container-details-first-btn').html('CREATE AN ACCOUNT').attr('href', '//login.' + window.site_vars.base_url + '/authenticate?requested-state=create-account').data('track-label', 'insiderstorycreateaccount');
                                this.$('.fwinsider-article-container-details-second-btn-title').html('Already have an account?');
                                this.$('.fwinsider-article-container-details-second-btn').html('SIGN IN').attr('href', '//account.' + window.site_vars.base_url + '/login/?onSuccessRedirectURL=' + onSuccessRedirectURL).data('track-label', 'insiderstorylogin');
                            }

                            this.$('.sam-unhider').css('display', 'block');
                            this.$('.fwil-anon-header').removeClass('fwil-content-hidden');
                            this.$('.fwil-anon-story').removeClass('fwil-content-hidden');
                        }
                    }, this));
                }
            },

            beforeAppRemove: function() {
                $('body').css('min-width', '');

                if (this.queryParam("hideHeader")){
                    $("header").removeClass("fwi-hide-header");
                }
                StateManager.startRefreshTimer();
            },
            queryParam : function (key) {
                key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
                var match = this.getQSPs().match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
                return match && decodeURIComponent(match[1].replace(/\+/g, " "));
            },
            updateQueryStringParameter : function (uri, key, value) {
                var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
                var separator = uri.indexOf('?') !== -1 ? "&" : "?";
                if (uri.match(re)) {
                    return uri.replace(re, '$1' + key + "=" + value + '$2');
                } else {
                    return uri + separator + key + "=" + value;
                }
            },
            getQSPs : function(){
                return window.location.search;
            },
            trackDetailsClick : function () {
                PubSub.trigger('uotrack', "insiderofferdetails");
            }
        });

        /**
         * Return view class.
         */
        return WlnaView;
    }
);

