/**
 * @fileoverview longform/longform share tools
 * @author Matthew Briede, bminton@gannett.com (Brandon Minton)
 */
define('modules/longform/longform-share',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'state',
    'modules/scroller/vertical-scroll',
    'third-party-apis/facebook/facebook',
    'site-manager',
    'utils',
    'modules/stories/share-email'
],
function(
    $,
    _,
    BaseView,
    PubSub,
    StateManager,
    VerticalScroll,
    Facebook,
    SiteManager,
    Utils,
    ShareEmail
) {
    var LongformShare = BaseView.extend({

        // View element
        el: '.longform-share-wrapper',

        // Events
        events: {
            'click .longform-share-flyout-icon-wrap' : 'onClickFlyoutIcon',
            'click .longform-share-icon': 'onClickShare',
            'click .sub-flyout-close, .js-share-email-cancel-btn' : 'closeSubFlyout'
        },

        /**
         * Initialize view.
         */
        initialize: function (options) {
            this.pubSub = {
                'closeShareFlyout': this.closeShareFlyout,
                'onClickShare': this.onClickShare,
                'onClickCommentsIcon': this.onClickCommentsIcon,
                'onClickEmailIcon': this.onClickEmailIcon
            };

            var _this = this;
            this.$shareComments = $('.share-comments');

            //Flyouts
            this.$flyoutWindow = this.$('.longform-share-flyout');
            this.$subFlyoutWrapper = this.$('.sub-flyout-wrapper');
            this.$subFlyoutFirefly = this.$('.sub-flyout-firefly');
            this.$subFlyoutComments = this.$('.sub-flyout-comments');
            this.$subFlyoutEmail = this.$('.sub-flyout-email');

            //Buttons
            this.$fireflyShareFlyoutIcon = this.$('.longform-share-flyout-icon-wrap-firefly');
            this.$commentFlyoutIcon = this.$('.longform-share-flyout-icon-wrap-comments');
            this.$emailFlyoutIcon = this.$('.longform-share-flyout-icon-wrap-email');

            //Scroll Wrappers
            this.$socialBarScrollWrap = this.$('.social-bar-scroll-wrap');
            this.$emailScrollWrap = this.$('.sub-flyout-email-scroll');

            this.$win = Utils.get('win');

            this.flyoutOptions = {
                'flyout': this.$flyoutWindow,
                'icon': 'longform-share',
                'iconSpace': '5px',
                'closeMethod': 'closeShareFlyout'
            };

            this.subFlyoutIsOpen = false;
            this.subFlyoutType = '';

            BaseView.prototype.initialize.call(this, options);
        },

        closeShareFlyout: function() {
            this.animate(this.$flyoutWindow, 'right', '-300px', 200);
            this.closeSubFlyout();
        },

        onClickShare: function(options) {
            if (this.subFlyoutIsOpen) {
                if (options === this.subFlyoutType) {
                    PubSub.trigger('openFlyout', this.flyoutOptions);
                    this.closeSubFlyout();
                } else {
                    this.openSubFlyout(options);
                }
            } else {
                PubSub.trigger('openFlyout', this.flyoutOptions);

                //If the user clicked a social button, open a subflyout; otherwise, don't
                if (options === 'firefly' || options === 'email' || options == 'comments') {
                    this.openSubFlyout(options);
                }
            }
        },

        onClickFlyoutIcon: function(e) {
            if ($(e.target).hasClass('longform-share-flyout-icon-wrap-firefly')) {
                this.openSubFlyout('firefly');
            } else if ($(e.target).hasClass('longform-share-flyout-icon-wrap-comments')) {
                this.openSubFlyout('comments');
            } else if ($(e.target).hasClass('longform-share-flyout-icon-wrap-email')) {
                this.openSubFlyout('email');
            }
        },

        getAvailableVerticalSpace: function() {
            var header = SiteManager.getHeader(),
                headerHeight = 0;
            if (header) {
                headerHeight = header.getCollapsedHeight();
            }
            return this.$win.height() - headerHeight;
        },

        updateFlyoutHeight: function(height) {
            this.$socialBarScrollWrap.css('height', height - 130);
            this.$emailScrollWrap.css('height', height);
        },

        animateSubFlyout: function(el, distance){
            this.animate(el, 'right', distance || '70px', 200, 'ease-in-out');
        },

        openSubFlyout: function(type) {
            if (this.subFlyoutIsOpen && (type === this.subFlyoutType)) {
                this.closeSubFlyout();
                return false;
            } else if (type === 'firefly') {
                if (this.subFlyoutIsOpen) {
                    this.closeSubFlyout();
                }
                this.$fireflyShareFlyoutIcon.addClass('longform-share-flyout-clicked-firefly');
                this.animateSubFlyout(this.$subFlyoutFirefly);
            } else if (type === 'comments') {
                if (this.subFlyoutIsOpen) {
                    this.closeSubFlyout();
                }
                this.$commentFlyoutIcon.addClass('longform-share-flyout-clicked-comments');
                this.animateSubFlyout(this.$subFlyoutComments);
                this.loadComments();
            } else if (type === 'email') {
                if (this.subFlyoutIsOpen) {
                    this.closeSubFlyout();
                }
                this.$emailFlyoutIcon.addClass('longform-share-flyout-clicked-email');
                this.animateSubFlyout(this.$subFlyoutEmail);
                this.loadEmail();
            }

            this.subFlyoutIsOpen = true;
            this.subFlyoutType = type;
            var availableVerticalSpace = this.getAvailableVerticalSpace();

            //size of page minus the size of the flyout header - probably could be done better during a refactor
            availableVerticalSpace = availableVerticalSpace - 140;
            this.updateFlyoutHeight(availableVerticalSpace);
        },

        closeSubFlyout: function() {
            //Firefly flyout close
            this.$fireflyShareFlyoutIcon.removeClass('longform-share-flyout-clicked-firefly');
            this.animateSubFlyout(this.$subFlyoutFirefly, '-300px');
            //Fb Comment flyout close
            this.$commentFlyoutIcon.removeClass('longform-share-flyout-clicked-comments');
            this.animateSubFlyout(this.$subFlyoutComments, '-370px');
            //Email flyout close
            this.$emailFlyoutIcon.removeClass('longform-share-flyout-clicked-email');
            this.animateSubFlyout(this.$subFlyoutEmail, '-370px');
            this.subFlyoutIsOpen = false;
            this.subFlyoutType = '';
        },

        /**
         * Load Facebook Comments social plugin.
         */
        loadComments: function() {
            this.$scrollWrap = this.$('.wide-bar-scroll-wrap');
            this.$scrollable = this.$subFlyoutWrapper;
            var _this = this;

            Facebook.loadScript().done(function (FB) {
                FB.XFBML.parse(_this.$scrollable[0]);
            });
        },

        loadEmail: function() {
            if (!this.subviews.shareEmailView) {
                this.subviews.shareEmailView = new ShareEmail({
                    el:'.share-email-container',
                    uotrackName: 'longformemail',
                    theme: 'dark'
                });
            }
            this.subviews.shareEmailView.showEmailForm();
        },

        destroy: function(){
            this.$shareComments.off('.' + this.cid);
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });

    return LongformShare;
});

