define('modules/videos/pluto/pluto-video-bar',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'pubsub',
    'utils',
    'template-utils',
    'modules/videos/pluto/pluto-analytics'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    PubSub,
    Utils,
    TemplateUtils,
    PlutoAnalytics
) {
    "use strict";
    var PlutoVideoBar = BaseView.extend(
        {
            el: '.js-pvb',

            minHeight: 0,
            maxHeight: 116,
            maxHeightWithBn: 186,
            animateSpeed: 250,
            breakingBarSpeed: 200,
            breakingBarHeight: 70,
            sponsorMessage: "Sponsor Message",
            analytics: {
                close: 'scrolllingplayerclose',
                open: 'scrollingplayeropen'
            },

            events: {
                'click .js-pvb-volume': 'onVolumeToggle',
                'click .js-play-control': 'onPlayToggle',
                'click .js-pvb-close': 'onClose',
                'click .js-pvb-fullscreen': 'onFullScreen'
            },

            initialize: function(options) {
                options = $.extend({
                    plutoVideo: null
                }, options);
                _.bindAll(this, 'onBreakingBarOpen', 'onBreakingBarClose', 'onShare', 'onShareClose', 'resetVideoBar');

                this.sv = {};
                this.settings = Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer');
                this.$title = this.$('.js-pvb-title');
                this.$playControl = this.$('.js-play-control');
                this.$volumeControl = this.$('.js-pvb-volume');
                this.$shareControl = this.$('.js-pvb-share');
                this.$scrubber = this.$('.js-pvb-scrubber');
                this.$siteNav = $('.site-nav-module');
                this.$utilShareControls = $('.util-bar-primary-modules');
                this.ad = this.options.plutoVideo.getAd();

                if (!this.options.plutoVideo.placement.share) {
                    this.$shareControl.hide();
                }

                this.setupNextVideo();
                if (window.videojs.IS_IPAD) {
                    this.$volumeControl.hide();
                }

                this.pubSub = {
                    'breakingbar:opening': this.onBreakingBarOpen,
                    'breakingbar:closing': this.onBreakingBarClose
                };
                BaseView.prototype.initialize.call(this, options);
                this.sv.share = this.subviews.share = this.options.plutoVideo.initShare(this.$el, this.onShare, this.onShareClose);
            },

            /**
             * To expand video bar we need to hide nav and drop article down
             */
            expand: function() {
                PubSub.trigger('video:expandBar');
                this.open = true;
                this.showPauseButton();
                var adInProgress = this.ad.isAdInProgress();
                this.setupProgressBar(!adInProgress);
                this.adjustRunTime(!adInProgress);
                this.$siteNav.addClass('site-nav-item-dim');
                this.$el.animate({'height': this.maxHeight}, this.animateSpeed, function() {
                    PubSub.trigger('video:after:expandBar');
                });
                //breaking news could have been opened on a different page
                if (this.$('.bnm-container').height()) {
                    this.onBreakingBarOpen(this.breakingBarSpeed);
                    this.getUtilBar().css({'top': '226px'});
                } else {
                    this.getUtilBar().css({'top': '156px'});
                }

                this.trackEvent(this.analytics.open);
            },

            /**
             * To collapse the video bar we need to show regular nav and a revert article position
             */
            collapse: function() {
                PubSub.trigger('video:collapseBar');
                this.open = false;
                this.resetProgressBars();
                this.options.plutoVideo.dimVideo(false);
                if (this.sv.share && this.sv.share.open) {
                    this.options.plutoVideo.showShareOverlay();
                    this.sv.share.hideShareOverlay();
                }
                this.$siteNav.removeClass('site-nav-item-dim');
                this.getUtilBar().css({'top': 'auto'});
                this.$el.animate({'height': this.minHeight}, this.animateSpeed);
                this.setParentMargin(this.minHeight);
            },

            getUtilBar: function() {
                if (!this.utilBar) {
                    this.utilBar = $('.util-bar-primary-modules');
                }
                return this.utilBar;
            },

            setParentMargin: function(margin) {
                this.$el.parent().css('margin-top', margin);
            },

            setTitle: function(title) {
                if (title !== this.sponsorMessage) this.title = title;
                this.$title.text(this.truncateText(title, Utils.getNested(this.settings, 'videoTitleLength')));
            },


            onVolumeToggle: function() {
                if (this.ad.isAdInProgress()) {
                    var volume = this.ad.isAdMuted() ? 1 : 0;
                    this.ad.setAdVolume(volume);
                    this.onVolumeChange(!volume);
                } else {
                    this.options.plutoVideo.toggleVolume();
                }
            },

            resetProgressBars: function() {
                if (this.$videoProgressBar || this.$adProgressBar) {
                    this.$videoProgressBar.removeClass('pvb-video-progress-bar');
                    this.options.plutoVideo.setAdProgressBar(this.$adProgressBar);
                    this.options.plutoVideo.setVideoProgressBar(this.$videoProgressBar);
                }
            },

            onPlayToggle: function() {
                if (this.ad.isAdInProgress()) {
                    this.toggleAdPlay();
                } else {
                    this.toggleVideoPlay();
                }
            },

            toggleAdPlay: function() {
                if (this.ad.isAdPlaying()) {
                    this.showPlayButton();
                    this.ad.pauseAd();
                } else {
                    this.showPauseButton();
                    this.ad.playAd();
                }
            },

            toggleVideoPlay: function() {
                if (this.options.plutoVideo.isPlaying()) {
                    this.showPlayButton();
                    this.options.plutoVideo.pauseVideo();
                } else {
                    this.showPauseButton();
                    this.options.plutoVideo.playVideo();
                }
            },

            showPlayButton: function() {
                this.$playControl.removeClass('pvb-video-pause').addClass('pvb-video-play');
            },

            showPauseButton: function() {
                this.$playControl.removeClass('pvb-video-play').addClass('pvb-video-pause');
            },

            onVolumeChange: function(muted) {
                if (muted) {
                    this.$volumeControl.removeClass('pvb-volume-on').addClass('pvb-volume-off');
                } else {
                    this.$volumeControl.removeClass('pvb-volume-off').addClass('pvb-volume-on');
                }
            },

            onClose: function() {
                this.trackEvent(this.analytics.close);
                if (this.options.onClose) this.options.onClose(true);
            },

            onFullScreen: function() {
                this.trackEvent('ScrollingPlayerFullScreen');
                this.options.plutoVideo.goFullscreen();
            },

            setupNextVideo: function() {
                var ssts = StateManager.getActivePageInfo().ssts.split('/');

                $.get('/videos/get_next_video/', {'section': ssts[0]}).done(_.bind(function(data) {
                    if (data) {
                        try {
                            this.$('.js-pvb-next-video-text').text(this.truncateText(data.title, Utils.getNested(this.settings, 'nextVideoTitleLength')));
                            this.$('.js-pvb-next-video').attr('href', data._links.longUrl.href);
                        } catch(err) {
                            this.hideNextVideo();
                        }
                    } else {
                        this.hideNextVideo();
                    }

                }, this));
            },

            hideNextVideo: function() {
                this.$('.js-pvb-next-video-container').css('visibility', 'hidden');
            },

            onBreakingBarOpen: function(slideSpeed) {
                this.breakingBar = true;
                if (this.maxHeight < this.maxHeightWithBn) this.maxHeight += this.breakingBarHeight;
                this.breakingBarSpeed = slideSpeed;
                if (this.open) {
                    this.$el.animate({'height': this.maxHeight}, this.breakingBarSpeed);
                }
            },

            onBreakingBarClose: function(slideSpeed) {
                this.breakingBar = true;
                if (this.maxHeight === this.maxHeightWithBn) this.maxHeight -= this.breakingBarHeight;
                this.breakingBarSpeed = slideSpeed;
                if (this.open) {
                    this.$el.animate({'height': this.maxHeight}, this.breakingBarSpeed);
                }
            },

            onShare: function() {
                this.options.plutoVideo.dimVideo(true);
            },

            onShareClose: function() {
                this.options.plutoVideo.dimVideo(false);
                if (this.options.plutoVideo.isPlaying()) {
                    this.showPauseButton();
                }
            },

            /**
             * Show ad or video run time next to video progress bar
             * @param {boolean} adEnded
             */
            adjustRunTime: function(adEnded) {
                var time;
                if (adEnded) {
                    time = this.options.plutoVideo.getVideoRunTime();
                } else {
                    time = this.ad.getAdRunTime();
                }
                this.$('.js-pvb-video-time').text(time);
            },

            /**
             * Switch ad bars based on ad state
             * @param {boolean} adEnded - if ad has ended
             */
            setupProgressBar: function(adEnded) {
                if (!this.$adProgressBar || !this.$videoProgressBar) {
                    this.$adProgressBar = this.options.plutoVideo.getAdProgressBar();
                    this.$videoProgressBar = this.options.plutoVideo.getVideoProgressBar();
                }
                this.$videoProgressBar.addClass('pvb-video-progress-bar');
                this.$scrubber.append(this.$adProgressBar, this.$videoProgressBar);

                if (adEnded || !this.ad.isAdInProgress()) {
                    this.$adProgressBar.hide();
                    this.$videoProgressBar.show();
                } else {
                    this.$videoProgressBar.hide();
                    this.$adProgressBar.show();
                }
            },

            onAdChange: function(adEnded) {
                this.adjustRunTime(adEnded);
                if (this.open) this.setupProgressBar(adEnded);
                this.toggleShare(adEnded);
                this.setSponsorMessage(adEnded);
                this.showPauseButton();
                if (this.sv.share) {
                    this.sv.share.options.videoData = this.options.plutoVideo.video;
                    this.sv.share.updateShareMetadata();
                }

            },

            toggleShare: function(adEnded) {
                if (this.sv.share) {
                    if (adEnded) {
                        this.$shareControl.removeClass('pvb-share-disabled');
                        this.sv.share.enableShare();
                    } else {
                        this.sv.share.disableShare();
                        this.$shareControl.addClass('pvb-share-disabled');
                    }
                }
            },

            updateShare: function() {
                this.sv.share = this.subviews.share = this.options.plutoVideo.initShare(this.$el, this.onShare, this.onShareClose);
            },

            setSponsorMessage: function(adEnded) {
                if (adEnded) {
                    this.setTitle(this.title);
                } else {
                    //never add sponsor message twice, even if ad does weird things
                    if (this.getCurrentTitleText() !== this.sponsorMessage) {
                        this.setTitle(this.sponsorMessage);
                    }
                }
            },

            getCurrentTitleText: function() {
                return this.$title.text();
            },

            trackEvent: function(event) {
                PlutoAnalytics.trackVideoEvents(event);
            },

            truncateText: function(text, length) {
                return TemplateUtils.smartTruncate(text, length, '...');
            },

            /**
             * Resets videoBar for every new video within a playlist
             */
            resetVideoBar: function(video) {
                this.$adProgressBar = null;
                this.$adControlBar = null;
                this.$videoProgressBar = null;
                this.options.plutoVideo = video;
                this.ad = this.options.plutoVideo.getAd();
                var adInProgress = this.ad.isAdInProgress();
                if (this.open) this.setupProgressBar(adInProgress);
                this.sv.share.updateShareMetadata(video.video);
            },

            destroy: function() {
                this.collapse();
                BaseView.prototype.destroy.apply(this, arguments);
                this.sv = {};
            }

        });

    return PlutoVideoBar;
});

