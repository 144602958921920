/* global define */
/* global Modernizr */
define('modules/sidebar',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'state',
    'modules/scroller/sidebar-scroll',
    'admanager'
],
function(
    $,
    _,
    BaseView,
    PubSub,
    StateManager,
    SidebarScroll,
    AdManager
){
    'use strict';
    var SidebarView = BaseView.extend({
        events: {
            'click .open-sidebar': 'openSidebarClick',
            'click .close-sidebar': 'closeSidebarClick'
        },

        initialize: function(options) {
            this.sidebar = this.$('.sidebar');
            if (!this.sidebar.length) {
                return;
            }
            this.sidebarScrollableWindow = this.$('.sidebar-scrollable-window');
            _.bindAll(this, 'showSidebarOpenButton', 'showSidebarCloseButton', 'onScroll', '_recalculateScrollBar');
            options = $.extend(true, {
                animations: {
                    sidebar: {
                        button: {
                            fadeIn: 200,
                            fadeOut: 200
                        },
                        slide: 250
                    }
                }
            });

            this.pubSub = {
                'sidebar:hide': this.closeSidebarClick,
                'sidebar:show': this.openSidebarClick,
                'showmore:headlines': this.onHeadlinesUpdated
            };
            BaseView.prototype.initialize.call(this, options);
            this._addSidebar();
            if (this.subviews.vscrollbar) {
                this.refreshSidebarScroll = _.debounce(this._recalculateScrollBar, 1000);
            }
        },

        renderCardInfo: function(currentCardInfo){
            this.currentCardInfo = currentCardInfo;
            if (currentCardInfo.sidebarOpen){
                this.isSidebarOpen = true;
                if (this.refreshSidebarScroll) {
                    this.refreshSidebarScroll();
                }
            }
        },

        onCardWidthChange: function(newCardInfo) {
            var sidebarStatusChanged = newCardInfo.sidebarOpen !== this.currentCardInfo.sidebarOpen || this.sidebarOpen !== newCardInfo.sidebarOpen;
            this.currentCardInfo = newCardInfo;
            this.resetSidebar();
            if (sidebarStatusChanged) {
                StateManager.getActiveApp().triggerEvent(newCardInfo.sidebarOpen ? 'openSideBar' : 'closeSideBar');
            }
        },

        _recalculateScrollBar: function(){
            if (this.subviews.vscrollbar) {
                this._setSideBarTitles();
                var fixedContent = this.$('.sidebar-fixed-content'),
                    fixedHeight = fixedContent.height(),
                    cardHeight = this.sidebar.height(),
                    scrollableHeight = Math.max(0, cardHeight - fixedHeight);
                if (fixedHeight > cardHeight) {
                    fixedHeight = cardHeight;
                    fixedContent.css('height', cardHeight);
                }
                if (scrollableHeight !== parseInt(this.sidebarScrollableWindow[0].style.height, 10)) {
                    this.sidebarScrollableWindow.css("height", scrollableHeight);
                    StateManager.getActiveApp().triggerEvent('onSidebarScrollableHeightChange', fixedHeight, scrollableHeight);
                }
                this.subviews.vscrollbar.refresh();
            }
        },

        /**
         * Add sidebar
         * @private
         */
        _addSidebar: function() {
            this.openButton = this.$('.open-sidebar');
            this.closeButton = this.$('.close-sidebar');
            if (this.sidebarScrollableWindow.length) {
                if (!Modernizr.touch) {
                    this.initializeSidebarTitles();
                }
                this.subviews.vscrollbar = new SidebarScroll({
                    el: this.sidebarScrollableWindow,
                    contentClass: this.$('.sidebar-scrollable-content'),
                    padding: 2,
                    lockPageScroll: false,
                    delayScroll: false,
                    fadeout: true,
                    onScroll: this.onScroll
                });
            }
        },

        initializeSidebarTitles: function() {
            this.$sidebarScrollableTitles = this.$('.sidebar-scrollable-content .sidebar-title-wrapper');
            this.$sidebarStaticTitle = this.$('.sidebar-static-title-wrapper');
            var firstTitle = this.$('.sidebar-scrollable-content .sidebar-title-wrapper:first');
            if (firstTitle.length) {
                firstTitle.hide();
                // check if taboola should be hidden for adFreeExperience
                if (!(firstTitle[0].classList.contains("taboola-sidebar-title-wrapper") && AdManager.isAdFreeExperience())) {
                    this.$sidebarStaticTitle.html(firstTitle.html()).show();
                }
            }
        },

        // find all headers and their current positions
        _setSideBarTitles: function() {
            var self = this;
            // add top header to headers
            this.sideBarTitles = [];
            if (!this.$sidebarScrollableTitles) {
                return;
            }
            // get all sub header positions
            this.$sidebarScrollableTitles.each(function(index, target){
                // get sub header positions
                var $target = $(target),
                    hideAfter = false;
                // esnure we can get outer height
                if ($target.is(':hidden')) {
                    $target.show();
                    hideAfter = true;
                }
                var position = $target.position().top + $target.outerHeight();
                if (hideAfter) {
                    $target.hide();
                }
                // content scroll is a negative scroll
                position = position * -1;
                self.sideBarTitles.push({
                    position: position,
                    html: $target.html()
                });
            });
        },

        // on side bar scroll event
        onScroll: function(y) {
            // sub headers exist
            if (this.$sidebarScrollableTitles.length > 1 && y !== 0) {
                var $header = this.$sidebarStaticTitle;
                if (!this.sideBarTitles) {
                    this._setSideBarTitles();
                }
                // get header text
                var i = this.sideBarTitles.length - 1,
                    header,
                    // default text is header text
                    markup = $header.html();
                for (i; i >= 0; i--) {
                    header = this.sideBarTitles[i];
                    if (header.position > y) {
                        markup = header.html;
                        break;
                    }
                }
                // set header text
                if ($header.html() !== markup) {
                    $header.html(markup);
                }
            }
        },

        /**
         * Slides the panel out from behind the page card.
         */
        openSidebarClick: function(event) {
            if (event){
                event.preventDefault();
            }
            StateManager.getActiveApp().triggerEvent('openSideBar');
            this._slideSidebar(this.openButton, true);
        },

        openSidebar: function(){
            this.isSidebarOpen = true;
        },

        /**
         * Slides the sidebar behind the page card.
         */
        closeSidebarClick: function(event) {
            if (event){
                event.preventDefault();
            }
            this._slideSidebar(this.closeButton, false);
            StateManager.getActiveApp().triggerEvent('closeSideBar');
        },

        closeSidebar: function() {
            this.isSidebarOpen = false;
        },

        _slideSidebar: function(button, open){
            var slideSpeed = this.options.animations.sidebar.slide;
            var fadeSpeed = this.options.animations.sidebar.button.fadeOut;

            StateManager.registerAnimation(button.fadeOut(fadeSpeed, 'easeInOutCubic').promise());

            var w = parseInt(this.sidebar.css('width'), 10);

            var slideAnimation = null;
            if (!this.currentCardInfo.sidebarOpen){
                slideAnimation = this.animate(this.sidebar, 'right', -1 * w, slideSpeed, 'ease-in-out');
            } else {
                var cardInfo = open ? this.currentCardInfo : StateManager.getActiveApp().getMinCardInfo();
                this.sidebar.removeClass('top');
                slideAnimation = this.animate(this.$.closest('.card'), 'width', cardInfo.cardWidth, slideSpeed, 'ease-in-out');
            }
            return slideAnimation.then(_.bind(function() {
                var slideAnimation = this.animate(this.sidebar, 'right', 0, slideSpeed, 'ease-in-out');
                if (open){
                    if (!this.currentCardInfo.sidebarOpen){
                        this.sidebar.addClass('top');
                        slideAnimation.done(this.showSidebarCloseButton);
                    }else{
                        this.sidebar.removeClass('top');
                        this.$el.closest('.card').css('width', '');
                    }
                }else{
                    slideAnimation.done(this.showSidebarOpenButton);
                    this.sidebar.removeClass('top');
                }
                return slideAnimation;
            }, this));
        },

        /**
         * Recalculate and reset screen width for sidebar
         */
        resetSidebar: function() {
            this.$el.closest('.card').css('width', '');
            this.sidebar.removeClass('top');
            if (!this.currentCardInfo.sidebarOpen) {
                if (!this.adVisible) {
                    this.openButton.show();
                } else {
                    this.openButton.hide();
                }
                this.closeButton.hide();
            }
            else {
                this.openButton.hide();
                this.closeButton.hide();
            }
            // set new postions to side bar titles
            this._setSideBarTitles();
        },
        /**
         * Show the sidebar open button.
         */
        showSidebarOpenButton: function() {
            if (!this.adVisible){
                this.openButton.css({'visibility': 'visible', display: 'none'});
                this.openButton.fadeIn(this.options.animations.sidebar.button.fadeIn, 'easeInOutCubic');
            }
        },

        /**
         * Show the sidebar close button.
         */
        showSidebarCloseButton: function() {
            this.closeButton.fadeIn(this.options.animations.sidebar.button.fadeIn, 'easeInOutCubic');
        },

        /**
         * Called when the page height changes due to more stories loading.
         * 1 second is an arbitrary wait time to allow height transition to complete.
         */
        onHeadlinesUpdated: function() {
            if (this.sidebarScrollableWindow.length) {
                this.headlinesUpdate = setTimeout(this._recalculateScrollBar, 1000);
            }
        },

        onHeroAdClose: function() {
            this.adVisible = false;
            if (!this.isSidebarOpen) {
                this.showSidebarOpenButton();
            }
        },

        onHeroAdOpen: function() {
            this.adVisible = true;
            if (this.isSidebarOpen) {
                if (!this.currentCardInfo.sidebarOpen){
                    this.closeSidebar();
                }
            } else {
                this.openButton.fadeOut(this.options.animations.sidebar.button.fadeOut, 'easeInOutCubic');
            }
        }

    });
    return SidebarView;
});

