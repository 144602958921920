define('modules/ggm-va-loan/ggm-va-loan',[
    'jquery',
    'underscore',
    'baseview',
    'form/dropdown',
    'state',
    'managers/routemanager'
],
    function(
        $,
        _,
        BaseView,
        Dropdown
        ) {
        "use strict";
        /**
         * View class.
         */
 
        var VaLoanModule = BaseView.extend({
 
            // View element.
            events: {
                'submit .ggmval-form': 'onFormSubmit'
            },
 
            /**
             * Initialize view.
             * @param {Object} options
             */
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);
                this._setupForm();
 
            },
            _setupForm: function(){
                this.subviews.dropdownProduct = new Dropdown({
                    el: this.$('.ggmval-select')
                });
            },
            onFormSubmit: function(i) {
                var input = this.$(".ggmval-input");
                if(!/^\d{5}$/.test(input.val())){
                    input.addClass("ggmval-input-error");
                    i.preventDefault();
                }else{
                    input.removeClass("ggmval-input-error");
                    return;
                }
            },
           
        });
 
        /**
         * Return view class.
         */
        return VaLoanModule;
    }
);
