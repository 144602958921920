define('partner/ad-utils',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {
    'use strict';
    /**
     * Ad Utils to be used for advertisements
     * @exports adutils
     * @author Jordan Manwaring <jmanwaring@gannett.com>
     */
    var AdUtils = function() {};
    AdUtils.prototype = {
        /**
         * Checks new color/image and then changes background color/image accordingly
         * @param  {jQuery} $el                      element to change the background color of
         * @param  {String} backgroundSolidColor     new background color
         * @param  {String} backgroundRepeatingImage new background image
         * @return {Boolean}
         */
        changeBg: function($el, backgroundSolidColor, backgroundRepeatingImage) {
            if (!backgroundSolidColor || backgroundSolidColor === 'transparent') {
                return false;
            }

            // Body Background goes to the png
            $el.addClass('partner-background');

            // Animate into the background color
            BaseView.prototype.animate($el, 'background-color', backgroundSolidColor, 200, 'ease-in').done(_.bind(function() {
                if (backgroundRepeatingImage) {
                    this.changeBgImg($el, backgroundRepeatingImage);
                } else {
                    this.changeBgImg($el, window.site_static_url + 'images/pixels/pixel-transparent.png');
                }
            }, this));
            
            return true;
        },

        /**
         * Changes the body's background image to the one specified
         * @param {jQuery} $el      element to change the background color of
         * @param {String} imageSrc source of new background image
         */
        changeBgImg: function($el, imageSrc) {
            $el.css({
                'background-image': 'url(\'' + imageSrc + '\')',
                'background-repeat': 'repeat'
            });
        },

        /**
         * Clears any background color/image set by an ad
         * @param {jQuery} $el                     element to change the background color of
         * @param {String} startingBackgroundColor original background color
         */
        clearBg: function($el, startingBackgroundColor) {
            if ($el.css('background-color') !== startingBackgroundColor) {
                BaseView.prototype.animate($el, 'background-color', startingBackgroundColor, 200, 'ease-in').done(_.bind(function() {
                    $el.removeClass('partner-background');
                }, this));
            } else {
                $el.css('background-color', '');
                $el.removeClass('partner-background');
            }
        }
    };
    return new AdUtils();
});

