/**
 * @fileoverview ApartmentFinderClassified view module.
 * @author tgreese@gannett.com (https://github.com/tommy-boy)
 */
define('modules/rentalslite-classified',[
        'jquery',
        'baseview',
        'state',
        'adPosition',
        'managers/routemanager'
    ],
    function(
        $,
        BaseView,
        StateManager,
        AdPosition,
        RouteManager
    ) {

        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var RentalsLiteClassified = BaseView.extend({
            adRendered : false,
            events: {
                'click .js-rlc-search-btn': 'aptsClassifieds'
            },
            renderAd : function (){
                var el = this.$('.js-rlc-google-ad')[0];
                    if (!this.adRendered && el){
                        this.adRendered = true;
                        this.subviews.adPositionOne = this.createAdPosition(el);
                }
            },
            createAdPosition: function (el){
                return new AdPosition({
                    el: $(el),
                    adSizes: [300,250],
                    adPlacement: $(el).data('adtag')
                });
            },
            initialize: function(options) {
                BaseView.prototype.initialize.call(this);
                this.renderAd();
            },
            aptsClassifieds: function (event) { 
                RouteManager.goTo(this.$('input:hidden.js-rlc-search-url').val());
            }
        });

        /**
         * Return view class.
         */
        return RentalsLiteClassified;
    }
);

