/**
* @fileoverview Manages top nav bar and headers (league/teams) for sports.
* @author erik.kallevig@f-i.com (Erik Kallevig)
*/
define('modules/sports/sports-nav',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {

    /**
     * View class.
     */
    var SportsNav = BaseView.extend({

        // DOM element.
        el: '.sp-nav-wrap',

        // Events.
        events: {
            'mouseenter .sp-nav-item': 'onHoverNavBarItem',
            'mouseleave .sp-nav-item': 'onHoverNavBarItem',
            'mouseenter .sp-nav-item.more': 'onHoverNavBarMore',
            'mouseleave .sp-nav-item.more': 'onHoverNavBarMore',
            'mouseenter .sp-head-item.more': 'onHoverHeadMore',
            'mouseleave .sp-head-item.more': 'onHoverHeadMore',
            'mouseenter .sp-head, .sp-hover-team-front-picker': 'onHeaderEnter',
            'mouseleave .sp-head, .sp-hover-team-front-picker': 'onHeaderLeave'
        },

        /**
         * Initialize the view.
         */
        initialize: function() {
            this.$spHeads = this.$('.sp-heads');
            this.$popupBox = $('.sp-details-box');

            // If you're on a subsection page, this will be true b/c the head is
            // always open in that case (as opposed to the sports homepage where
            // it's collapsed by default).
            this.stayOpen = this.$spHeads.hasClass('stay-open');

            this.slideSpeed = 200;

            _.bindAll(this, 'toggleNavBarMoreFlyout', 'toggleHeadMoreFlyout', 'toggleHead', 'switchHead');

            // Calls super method.
            BaseView.prototype.initialize.apply(this, arguments);

        },

        /**
         * Hover handler for nav list item.
         * @param {Event} e Hover event.
         */
        onHoverNavBarItem: function(e) {
            clearTimeout(this.navBarItemTimer);
            clearTimeout(this.navBarSwitchHeadTimer);
            this.navBarItem = $(e.currentTarget);
            var isMoreItem = this.navBarItem.hasClass('more');
            if (!isMoreItem) {
                this.navBarSwitchHeadTimer = _.delay(this.switchHead,
                    80, e);
            }
            var open = isMoreItem ? false : e.type === 'mouseenter';
            if (this.navBarItem.hasClass('external')) open = false;
            this.navBarItemTimer = _.delay(this.toggleHead,
                100, undefined, open);
        },

        /**
         * Delayed active head switcher (when flipping between subsection heads on hover).
         * @param {Object} e Hover event object.
         */
        switchHead: function(e) {
            if (e.type === 'mouseout') return;
            this.setActiveHead(this.navBarItem.data('ss'));
        },

        /**
         * Set active head div.
         * @param {string} ss Subsection string.
         */
        setActiveHead: function(ss) {
            this.$('.sp-head.active').removeClass('active');
            this.$('.sp-head.' + ss).addClass('active');
        },

        /**
         * Hover handler for 'more' nav list item.
         * @param {Event} e Hover event.
         */
        onHoverNavBarMore: function(e) {
            clearTimeout(this.navBarMoreTimer);
            var navBarMoreItem = $(e.currentTarget);
            this.navBarMoreBtn = navBarMoreItem.find('.more');
            this.navBarMoreFlyout = navBarMoreItem.find('.sp-nav-more-fly');
            this.rightNow = $('.sidebar-button');
            this.sidebar = $('.sidebar-sports');
            this.navBarMoreTimer = _.delay(this.toggleNavBarMoreFlyout,
                100, undefined, e.type === 'mouseenter');
        },

        /**
         * Toggle navbar 'more' flyout.
         * @param {MouseEvent} e
         * @param {Boolean} open Whether to force open the flyout.
         */
        toggleNavBarMoreFlyout: function(e, open) {
            $.datepicker.dpDiv.css({'display': 'none'});
            this.rightNow.toggleClass('disabled',open);
            this.sidebar.toggleClass('disabled',open);
            this.navBarMoreBtn.toggleClass('active', open);
            this.navBarMoreFlyout.toggleClass('active', open);
            return false;
        },

        /**
         * Hover handler for 'more' head list item.
         * @param {Event} e Hover event.
         */
        onHoverHeadMore: function(e) {
            clearTimeout(this.headMoreTimer);
            var headMoreItem = $(e.currentTarget);
            this.headMoreBtn = headMoreItem.find('.more');
            this.headMoreFlyout = headMoreItem.find('.sp-head-more-fly');
            this.allHeadMoreFlys = headMoreItem.parent().find('.sp-head-more-fly');
            this.headMoreTimer = _.delay(this.toggleHeadMoreFlyout,
                100, undefined, e.type === 'mouseenter');
        },

        /**
         * Toggle head 'more' flyout.
         * @param {MouseEvent} e
         * @param {Boolean} open Whether to force open the flyout.
         */
        toggleHeadMoreFlyout: function(e, open) {
            $.datepicker.dpDiv.css({'display': 'none'});
            this.allHeadMoreFlys.removeClass('active');
            this.headMoreBtn.toggleClass('active', open);
            this.headMoreFlyout.toggleClass('active', open);
            if (open) {
                if (this.sidebarHidden) {
                    $('.open-sidebar').hide();
                } else {
                    $('.sidebar-sports').css('z-index', 15);
                }
            } else {
                if (this.sidebarHidden) {
                    $('.open-sidebar').show();
                } else {
                    $('.sidebar-sports').css('z-index', '');
                }
            }
        },

        /**
         * Inherit currentCardInfo from cards.js
         */
        onCardWidthChange: function(currentCardInfo) {
            this.sidebarHidden = !currentCardInfo.sidebarOpen;
        },

        renderCardInfo: function(currentCardInfo) {
            this.sidebarHidden = !currentCardInfo.sidebarOpen;
        },

        openSideBar: function() {
            this.sidebarHidden = ($('.close-sidebar').css('display') == 'block');
        },

        closeSideBar: function() {
            this.sidebarHidden = ($('.close-sidebar').css('display') == 'block');
        },

        /**
         * Rewritten onHoverHead into Enter/Leave functions
         */

        onHeaderEnter: function(e) {
            // Prevent the popup sp-head hover from timing out and refreshing
            if ($(e.relatedTarget).parents().length === 0) return;
            clearTimeout(this.navBarItemTimer);
        },

        onHeaderLeave: function(e) {
            var boxTop = this.$popupBox.offset().top;
            var boxLeft = this.$popupBox.offset().left;
            var boxWide = this.$popupBox.outerWidth();
            var boxTall = this.$popupBox.outerHeight();
            if ((e.pageX < boxLeft) || (e.pageX > (boxLeft +boxWide)) ||
                    (e.pageY < boxTop) || (e.pageY > (boxTop + boxTall))) {
                this.navBarItemTimer = _.delay(this.toggleHead,
                    100, undefined, false);
            }
        },

        toggleHead: function(e, open) {
            if (this.stayOpen) {
                if (!open) this.setActiveHead(this.$('.sp-head.current').data('ss'));
            } else if (open) {
                this.$spHeads.stop(true).animate({'height': 90}, this.slideSpeed, function(){
                        $(this).addClass('temp-open');
                    });
            } else {
                this.$spHeads.removeClass('temp-open').stop(true).animate({'height': 0}, this.slideSpeed);
            }
        }

    });

    /**
     * Create object and return it.
     */
    return SportsNav;
});

