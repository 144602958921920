define('partner/digital-wrap',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'utils',
    'partner/ad-utils',
    'adPosition'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    Utils,
    AdUtils,
    AdPosition
) {
    'use strict';
    var DigitalWrap = BaseView.extend({
        /**
         * Initialize Digital Wrap
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector 
         */
        initialize: function(options) {
            _.bindAll(this, 'onAdReady', 'showAd');
            BaseView.prototype.initialize.call(this, options);

            this.subviews.ad = new AdPosition({
                el: this.$el,
                adPlacement: 'high_impact',
                adSizes: [1280, 768],
                adType: 'digitalwrap',
                onAdReady: this.onAdReady,
                slot : options.slot,
                legacyHighImpact: true
            });

            this.$body = Utils.get('body');
        },

        /**
         * Clean up the view (no argument).
         */
        destroy: function(removeEl) {
            if (this.$wrap) this.$wrap.remove();
            if (this.$clickLeft) this.$clickLeft.remove();
            if (this.$clickRight) this.$clickRight.remove();
            if (this.startBgColor) AdUtils.clearBg(this.options.backgroundEl, this.startBgColor);
            this.$body.removeClass('high-impact-ad-visible');
            BaseView.prototype.destroy.call(this, false);
        },

        onAdReady: function(adData) {
            var slot = this.options.slot,
                $container = this.options.container,
                offset = this.options.offset || 0;

            //need to have the ad slot visible on the page in order for DFP to track visibile impressions
            if (slot) {
                slot.addClass('show-slot');
            }

            // Append html to page for digital wrap
            $('<img>').load(_.bind(function(el) {
                    // remove img to prevent memory leaks
                    $(el.currentTarget).remove();
                    this.$wrap = $('<div>')
                        .css('background-image', 'url("' + adData.image + '")')
                        .addClass('digital-wrap');

                    if(offset > 0) {
                        this.$wrap.css({top: offset});
                    }

                    $container.prepend(this.$wrap);
                    this.$body.addClass('partner-background');
                    this.showAd(adData);
                }, this))
                .attr('src', adData.image);

            this.$clickLeft = $('<a class="dw-click dw-click-left" target="_blank" href="' + adData.click + '" style="top: ' + offset + 'px; ' + adData.styles + '">');
            this.$clickRight = this.$clickLeft.clone().attr('class', 'dw-click dw-click-right');

            $container.prepend(this.$clickRight).prepend(this.$clickLeft);
        },

        showAd: function(adData) {
            this.$body.addClass('high-impact-ad-visible');
            this.startBgColor = this.$body.css('background-color');
            AdUtils.changeBg(this.options.backgroundEl, adData.backgroundColor);
            this.$wrap.addClass('show');
        }
    });
    return DigitalWrap;
});

