/* global define */
/**
 * @fileoverview Simple Extendable Overlay.
 * @author Jay Merrifield
 */
define('apps/simple-overlay',[
    'jquery',
    'underscore',
    'backbone',
    'base-app',
    'state',
    'utils',
    'site-manager',
    'managers/routemanager',
    'pubsub',
    'user-manager'

],
function(
    $,
    _,
    Backbone,
    BaseApp,
    StateManager,
    Utils,
    SiteManager,
    RouteManager,
    PubSub,
    UserManager
) {
    "use strict";

        /**
         * View class.
         */
        var SimpleOverlay = BaseApp.extend({
            // View element.
            el: '#overlay',

            // general overlay events.
            events: {
                'click .transition-wrap': 'close',
                'click .close-overlay': 'close'
            },

            /**
             * Initialize view.
             * @param {Object} options View options passed during init.
             */
            initialize: function(options) {
                var disableAnimations = Utils.getNested(window, 'site_vars', 'flags', 'disable_overlay_animations');
                options = $.extend(true, {
                    template: '<div id="overlay" class="no-transition">' +
                        '<div class="transition-wrap">' +
                        '<article class="asset clearfix story overlay-loader-wrap">' +
                        '<div class="overlay-loader-init"></div>' +
                        '</article>' +
                        '</div>' +
                        '</div>',
                    animations: {
                        fadeIn: {
                            duration: disableAnimations ? 0 : 200
                        },
                        fadeOut: {
                            duration: disableAnimations ? 0 : 200
                        }
                    },
                    disableAnimations: disableAnimations
                }, options);

                _.bindAll(this, 'resizeHandler', 'scrollHandler', 'onBreakingOpen', 'onBreakingClose', 'onVideoBarExpand', 'onVideoBarCollapse');

                this.win = Utils.get('win');
                this.scrollEl = Utils.get('scrollEl');
                this.$body = Utils.get('body');
                this.header = SiteManager.getHeader();
                this.pubSub = {
                    'video:after:expandBar': this.onVideoBarExpand,
                    'video:collapseBar': this.onVideoBarCollapse,
                    'breakingbar:after:open': this.onBreakingOpen,
                    'breakingbar:after:close': this.onBreakingClose,
                    'newsreel:open': this.onNewsreelOpen,
                    'newsreel:unfixed': this.onNewsreelUnfixed
                };

                this.resizeHandler();

                // call base class initialize
                BaseApp.prototype.initialize.call(this, options);
            },

            destroy: function(removeEl){
                this._removeOverlayCardPlaceholder();
                this.win.off('.' + this.cid);

                // call base class destroy
                BaseApp.prototype.destroy.call(this, removeEl);
            },

            onBreakingClose: function() {
                this.breakingbar = false;
                this.bnmHeight = 0;
            },

            onBreakingOpen: function() {
                this.breakingbar = Utils.getNested(this.header, 'subviews', 'breakingbar') || false;
                this.bnmHeight = $('.bnm-bar').outerHeight();
            },

            onNewsreelOpen: function() {
                this.newsreelOffset = this.$('.nrrm-stb').outerHeight();
                this.closeWrapOffset = this.closeWrap.offset().top;
            },

            onNewsreelUnfixed: function() {
                this.closeWrap.css({position: 'absolute', top: ''});
            },

            scrollHandler: function() {
                var parentOffset,
                    bounds = this.closeWrap[0].getBoundingClientRect(),
                    self = this.closeWrap.offset().top,
                    breakingOffset = this.bnmHeight || 0,
                    videoOffset = this.videoOffset || 0,
                    newsreelOffset = this.newsreelOffset || 0,
                    closeWrapOffset = this.closeWrapOffset || 0,
                    offset = breakingOffset + videoOffset + newsreelOffset;
                // Make sure this is actually set
                if(!this.transitionWrap) {
                    this.transitionWrap = this.$('.transition-wrap:first');
                }
                parentOffset = this.transitionWrap.offset().top;

                if (self - parentOffset + offset < 0 || newsreelOffset > 0 && $(document).scrollTop() < closeWrapOffset) {
                    this.closeWrap.css({position: 'absolute', top: ''});
                } else if (bounds.top <= 40 + offset) {
                    this.closeWrap.css({position: 'fixed', top: 40 + offset});
                }
            },

            resizeHandler: function() {
                this.winSize = {
                    width: this.win.width(),
                    height: this.win.height()
                };
                if (this.header) {
                    this.winSize.height -= this.header.getCollapsedHeight();
                }
                this.setArticleMinHeight(this.$('.transition-wrap:first .asset'));
            },

            setArticleMinHeight: function(storyArticle) {
                if (storyArticle.length && storyArticle.outerHeight() < this.winSize.height) {
                    storyArticle.css('min-height', this.winSize.height);
                }
            },

            _onHeaderFixed: function() {
                if (this.overlayOffsetPlaceholder) {
                    this._fixPlaceholder();
                }
                BaseApp.prototype._onHeaderFixed.apply(this, arguments);
            },

            _onHeaderUnfixed: function() {
                if (this.overlayOffsetPlaceholder) {
                    this._unfixPlaceholder();
                }
                BaseApp.prototype._onHeaderUnfixed.apply(this, arguments);
            },

            _fixPlaceholder: function(){
                this.overlayOffsetPlaceholder.css({
                    position: 'fixed',
                    top: -1 * this.header.getFixedThreshold()
                });
            },

            _unfixPlaceholder: function(){
                this.overlayOffsetPlaceholder.css({
                    position: 'absolute',
                    top: 0
                });
            },

            _removeOverlayCardPlaceholder: function() {
                if (this.overlayCardPlaceholder) {
                    this.overlayOffsetPlaceholder.remove();
                    this.overlayOffsetPlaceholder = null;
                    this.overlayCardPlaceholder.remove();
                    this.overlayCardPlaceholder = null;
                }
            },

            onVideoBarExpand: function() {
                this.videoOffset = this.$('.js-pvb').outerHeight();
                this.scrollHandler();
            },

            onVideoBarCollapse: function() {
                this.videoOffset = 0;
                this.closeWrap.css({top: 40});
                this._onHeaderFixed();
            },

            beforeAppRemove: function(fromUrl, toUrl){
                this.win.off('.' + this.cid);
                this.positionCloseButton('absolute', Utils.getScrollPosition());
            },

            animateRemoveApp: function(fromUrl, toUrl) {
                if (this.isSafari5 || this.options.disableAnimations){
                    return this.hide();
                }
                if (this.header) {
                    this.header.updateNavigation(toUrl);
                }
                this.$el.css({position: 'relative', opacity: 1});
                return this.animate(this.$el, 'opacity', 0, 250);
            },

            beforePageReveal: function(fromUrl, toUrl, htmlFrag, paused) {
                htmlFrag.find('.asset').css('min-height', this.winSize.height);
            },

            animateRevealApp: function(fromUrl, toUrl, preload) {
                var transitionWrap = this.$('.transition-wrap'),
                    height = this.winSize.height;

                this.transitionWrap = transitionWrap;
                if (this.isSafari5 || !$.support.css.transform || this.options.disableAnimations){
                    transitionWrap.css('height', height);
                    return this.show();
                }
                transitionWrap.css({'height': height, overflow: 'hidden', opacity: 0});
                transitionWrap[0].style[$.support.css.transform.cssName] = 'scale(0.4)';
                transitionWrap[0].style[$.support.css.transform.originCssName] = '50% ' + (height * 0.25) + 'px';
                transitionWrap[0].style[this.transitionCssName] = $.support.css.transform.cssHyphenName + ' 300ms linear, opacity 300ms linear';
                this.$el.show().css({opacity: 1, display: 'block'});

                // need to let the browser render the non-zoomed-in version before triggering the animation
                _.delay(_.bind(function(){
                    transitionWrap[0].style[$.support.css.transform.cssName] = 'scale(1.0)';
                    transitionWrap[0].style.opacity = 1;
                }, this));

                var deferred = $.support.css.transition.registerTransitionEndListener(transitionWrap[0]);
                deferred.done(_.bind(function(){
                    transitionWrap[0].style[$.support.css.transform.cssName] = '';
                    transitionWrap[0].style[this.transitionCssName] = '';
                    transitionWrap[0].style[$.support.css.transform.originCssName] = '';
                }, this));

                return deferred.promise();
            },

            getRevealAppLoader: function(toUrl){
                return this.options.template;
            },

            afterLoaderReveal: function() {
                this.loaderTimeout = setTimeout(_.bind(function() {
                    var loading = this.$('.overlay-loader-init');
                    if (loading.length > 0) {
                        loading.removeClass('overlay-loader-init').addClass('ui-circle-loader overlay-circle-loader');
                    }
                }, this), 200);
            },

            beforeLoaderRemove: function() {
                clearTimeout(this.loaderTimeout);
            },

            animateChangePagePreData: function(fromUrl, toUrl) {
                var winHeight = this.winSize.height,
                    scrollPosition = Utils.getScrollPosition(),
                    activeTransitionWrap = this.$('.transition-wrap:first');
                this.prepareContentForTransition(activeTransitionWrap, scrollPosition, winHeight);

                var htmlFrag = $(this.options.template);
                var stagedTransitionWrap = htmlFrag.find('.transition-wrap');
                this.prepareContentForTransition(stagedTransitionWrap, 0, winHeight);

                this.$el.prepend(stagedTransitionWrap);

                SiteManager.scrollTop(0);

                // Unfix close button so it can slide with card.
                this.positionCloseButton('absolute', scrollPosition, activeTransitionWrap);

                return this.swapContent(activeTransitionWrap, stagedTransitionWrap);
            },

            prepareContentForTransition: function(transitionWrap, scrollPosition, winHeight){
                transitionWrap.css({height:winHeight});
                transitionWrap.children().css({'top': -1 * scrollPosition});
            },

            animateChangePagePostData: function(fromUrl, toUrl, htmlFrag, paused) {
                var transition = htmlFrag.find('.transition-wrap');
                transition.css('height', this.winSize.height);
                this.setArticleMinHeight(transition.find('.asset'));
                this.beforeLoaderRemove();
                return this.swapContent(this.$('.transition-wrap:first'), transition, this.getHash(toUrl)).done(_.bind(function(){
                    transition.css('height', '');
                }, this));
            },

            afterPageReveal: function(fromUrl, toUrl, paused, ViewClass){
                var transitionWrap = this.$('.transition-wrap:first');
                transitionWrap.css({height: '', overflow: 'visible'});
                this.currentSection = this.getSectionName();

                this.transitionWrap = transitionWrap;
                this.closeWrap = this.$('.close-wrap');

                this.preloadedUrl = this.options.preloadedUrl || this.currentSection || '/';
                if (this.header) {
                    this.header.updateNavigation((this.pageInfo || {}).ssts);
                }
                if (fromUrl === null) {
                    if (Utils.flag('disable_overlay_preload')) {
                        this.overlayOffsetPlaceholder = $('<div id="cards-offset-placeholder" style="height:' + (this.header.getExpandedHeight() + 50) + 'px;"></div>');
                        this.overlayCardPlaceholder = $('<div class="card-wrap card-wrap-behind-overlay card-wrap-placeholder"></div>');
                        if (this.header.isFixed()) {
                            this._fixPlaceholder();
                        } else {
                            this._unfixPlaceholder();
                        }
                        this.$body.append(this.overlayOffsetPlaceholder, this.overlayCardPlaceholder);
                    } else {
                        this.preloadPath();
                    }
                }
                if (ViewClass){
                    this.subviews.view = new ViewClass({
                        el: transitionWrap,
                        path: toUrl
                    });
                }

                this.win.on('resize.' + this.cid, _.throttle(this.resizeHandler, 50)).on('scroll.' + this.cid, _.throttle(this.scrollHandler, 50));

                this._referrerCheck();
            },

            /**
             *  This function is an override of the method in the extended base-app.js
             */
            verifyPageInfo: function(pageInfo, requestInfo) {
                var userAccount = UserManager.getAccount('firefly'),
                    viewsLeft = 'nothitpaywall'; // The default status is that a user has not hit a paywall

                pageInfo = BaseApp.prototype.verifyPageInfo.apply(this, arguments);

                if (userAccount) {
                    // Grab the numerical value from the cookie
                    var cookieViewsLeft = userAccount.getViewsCookie().viewsRemaining;

                    if (cookieViewsLeft >= 0) {
                        // In the case of zero articles remaining, use placeholder text instead of the number 0
                        // per analytics team request
                        if (cookieViewsLeft === 0) {
                            viewsLeft = 'nofreearticlesremaining';

                        // Otherwise, just set it to the numeric value from the cookie
                        } else {
                            viewsLeft = cookieViewsLeft;
                        }
                    }
                }

                pageInfo.numPageViewsLeft = viewsLeft;
                return pageInfo;
            },

            preloadPath: function(){
                StateManager.preloadPath(this.preloadedUrl);
            },

            /**
             * This function sets the close button to either fixed or absolute positioning.
             * @param {String} positionType Either 'fixed' or 'absolute'.
             * @param {Number} [scrollPosition] position of where the close button should be placed, defaults to 0
             * @param {jQuery} [closeStoryWrap] The story wrap parent of the close button to position, defaults to this.$('.close-wrap').
             */
            positionCloseButton: function(positionType, scrollPosition, closeStoryWrap) {
                var closeWrap = closeStoryWrap ? closeStoryWrap.find('.close-wrap') : this.closeWrap;
                if (positionType === 'absolute') {
                    closeWrap.css({ 'position': positionType, 'margin-top': '', 'top': scrollPosition || 0 });
                } else {
                    closeWrap.css({ 'position': positionType, 'margin-top': -80, 'top': '' });
                }
            },

            getSectionName: function() {
                var pageInfo = this.pageInfo || {},
                    section = (pageInfo.ss || pageInfo.ssts || '').split('/')[0];
                if (section === "home") {
                    return "/";
                } else if (_.contains(Utils.getSiteSections(), section)) {
                    return "/" + section + "/";
                }
            },

            /**
             * Close page.
             * @param {Event} e Click event to close overlay.
             */
            close: function(e) {
                if (e && e.target !== e.currentTarget){
                    // trap clicks inside the transition wrap to prevent them from closing the overlay
                    return;
                } else if ($(e.currentTarget).hasClass('transition-wrap') && window.pluto360Asset) {
                    //return if transition wrap click but 360 video is in view
                    return;
                } else if ($(e.currentTarget).hasClass('transition-wrap')) {
                    // request to continue to track transition wrap clicks but prevent closing of the overlay
                    PubSub.trigger('uotrack', 'transition-wrap-click');
                }

                var utvalue = $(e.currentTarget).attr("data-ht") || 'modalclose';
                PubSub.trigger('heattrack', utvalue);

                if (!this.notUW) {
                    var counter = this.uwCounter;
                    var uwPageDepth = 2;
                    if (counter >= 1 && counter <= uwPageDepth){
                        this._UWRedirect();
                        return;
                    }
                }
                this._removeOverlayCardPlaceholder();
                RouteManager.goTo(StateManager.getPreloadedUrl() || this.currentSection || '/');
                if (e) {
                    return false;
                }
            },

            _UWRedirect: function(){
                if (this.uwref){
                    window.history.go(-this.uwCounter);
                } else {
                    window.location.href = this.referrerUrl;
                }
            },

            _referrerCheck: function(){
                if (!this.uwCounter && !this.notUW){if (this._getQueryVariable("uwref") === "1"){
                        this.uwref = true;
                        this.uwCounter = 1;
                    } else {
                        this.notUW = true;
                    }
                } else if (this.uwCounter >= 1) {
                    this.uwCounter += 1;
                }
            },

            _getQueryVariable: function(variable){
               var query = window.location.search.substring(1);
               var vars = query.split("&");
               for (var i=0;i<vars.length;i++) {
                   var pair = vars[i].split("=");
                   if (pair[0] === variable){
                       return pair[1];
                   }
               }
               return(false);
            }
        });

        /**
         * Return view class.
         */
        return SimpleOverlay;
    }
);

