/**
* @fileoverview NFL United Way Series.
* @author tjrivera@usatoday.com (Tommy Rivera)
*/
define('modules/sports/nfl-unitedway',[
    'jquery',
    'underscore',
    'backbone',
    'baseview'
],
function(
    $,
    _,
    Backbone,
    BaseView
) {

    /**
     * View class.
     */
    var NflUnitedWay = BaseView.extend({
     // Events.
        events: {
            'click #nfl-unitedway-skip': 'skipSubscribe',
            'click #nfl-unitedway-submit': 'validateForm'
        },
        
        initialize: function() {
           
            this.$email_address = $('#nfl-unitedway-subscribe form [name="Email Address"]');
            
            BaseView.prototype.initialize.call(this, this.options);
            _.bindAll(this, 'setup', 'update');
            this.setup();  
        },

        /**
         * Sets up module
         */
        setup: function() {
          this.setUpNewsletter();
        },
        
        /**
         * Skip Subscribte to United Way
         */
        skipSubscribe: function() {
            $.cookie('unitedway-subscribed', 'true', { expires: 30, path: '/' });
            $('#nfl-unitedway-subscribe').hide();
            $('#fs-survey-iframe').show();
        },
        
        /**
         * Set News Letter Logic
         */
        setUpNewsletter: function() {
            if($.cookie('unitedway-subscribed') == 'true') {
                $('#nfl-unitedway-subscribe').hide();
                $('#fs-survey-iframe').show();
             } else {
                 $('#nfl-unitedway-subscribe').show();
                 $('#nfl-unitedway-subscribe form').submit(function(e) {
                     e.preventDefault();
                     $.post($(this).attr('action'), $(this).serialize());
                     $('#nfl-unitedway-subscribe').hide();
                     $('#fs-survey-iframe').show();
                     $.cookie('unitedway-subscribed', 'true', { expires: 30, path: '/' });
                 });
             } 
        },
        
        /**
         * Invalid Email Error Handling
         */
        emailError: function() {
           $(this.$email_address).css('background','yellow').focus();
            window.alert('Please enter a valid email address');
        },
        
        signUp: function() {
           $('#nfl-unitedway-subscribe form').trigger('submit');
        },
        
        /**
         * Validate User Input Email Adress
         */
        validateForm: function() {
           
            var email = $('#nfl-unitedway-subscribe form [name="Email Address"]').val();
      
            var emailReg = /\S+@\S+\.\S+/; 
            if( !emailReg.test( email ) ) {
                this.emailError();
            } else {
                if ( $('#nfl-unitedway-subscribe form [name="Thirteen Older"]').prop('checked') ) {
                    this.signUp();
                } else {
                    window.alert('You must be 13 years or older to participate in the online voting.');
                }
                
            }    
        },
        
        
        /**
         * Updates module contents
         */
        update: function(){
            this.setup();
        },
        
        destroy: function() {
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });

    return NflUnitedWay;
});
