define('modules/global/barrier',['jquery'],
    function($) {
        var TimedOptionalDeferred = function(promise, timeoutMs, optional) {
            $.extend(this, $.Deferred()); // make this act like a Deferred() object
            if (optional) {
                this.reject = this.resolve;
            }
            
            if(timeoutMs) {
                var timeout = setTimeout($.proxy(function() {
                    this.reject();
                }, this), timeoutMs);
            }

            promise.then(
                    // success
                    $.proxy(function() {
                        this.resolve.apply(this, arguments);
                    }, this),
                    // failure
                    $.proxy(function() {
                        this.reject.apply(this, arguments);
                    }, this)
                ).always(function() {
                    if(timeout) {
                        clearTimeout(timeout);
                    }
                });
        };
        /**
         * @classdesc Promise Barrier. A collection of promises that can be waited on for synchronization purposes.
         * Offers timeouts on promises, as well as the concept of optional tasks whose failure does not fail the barrier
         * @constructs modules/global/barrier
         * @author Jay Merrifield <jmerrifiel@gannett.com>
         */
        var Barrier = function() {
            this.init();
        };
        Barrier.prototype = {
            init: function() {
                this._processes = [];
                this._waiting = [];
            },
            /**
             * Adds a barrier to the queue that we can wait on
             * @param {Deferred} promise - Async Promise to wait on
             * @param {Number} [timeoutMs] - optional time in ms to wait for promise to succeed
             * @param {Boolean} [optional=false] - specify whether this process is optional or required
             */
            add: function(promise, timeoutMs, optional) {
                this._addToQueue(this._processes, new TimedOptionalDeferred(promise, timeoutMs, optional));
            },
            /**
             * Aborts anyone waiting on the barrier
             * @param {Boolean} [abortProcesses=false] - specifies whether we should also abort all running processes in the barrier
             */
            cancel: function(abortProcesses) {
                $.each(this._waiting, function(i, d) {
                    d.reject();
                });
                this._waiting = [];
                if (abortProcesses) {
                    // copy array so we don't modify the array we're iterating over
                    $.each(this._processes.slice(0), function(i, d) {
                        d.reject();
                    });
                    this._processes = [];
                }
            },
            /**
             * Waits for all entries in the queue to finish before resolving
             * @returns {Deferred}
             */
            wait: function() {
                var processes = this._processes;
                if (!processes.length) {
                    return $.Deferred().resolve();
                } else {
                    return this._addToQueue(this._waiting, $.Deferred(function(defer) {
                        // we don't return the $.when() directly because we want the ability to cancel the queue
                        $.when.apply($, processes).then(function() {
                            defer.resolve.apply(defer, arguments);
                        }, function() {
                            defer.reject.apply(defer, arguments);
                        });
                    }));
                }
            },
            _addToQueue: function(queue, promise) {
                queue.push(promise);
                return promise.always($.proxy(function() {
                    return this._removeFromQueue(queue, promise);
                }, this));
            },
            _removeFromQueue: function(queue, entry) {
                var i = $.inArray(entry, queue);
                if (i !== -1) {
                    queue.splice(i, 1);
                    return true;
                }
                return false;
            }
        };
        return Barrier;
    }
);
