/**
 * @fileoverview RentalsApartmentAd view module.
 * @author rcopley@gannett.com (Ryan Copley)
 */
define('modules/rentals-apartments-ad',[
        'jquery',
        'baseview',
        'state',
        'adPosition',
        'managers/routemanager'
    ],
    function(
        $,
        BaseView,
        StateManager,
        AdPosition,
        RouteManager
    ) {

        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var RentalsApartmentAd = BaseView.extend({
            /**
             * Initialize view.
             */
            events: {
                'click .js-rad-search-btn': 'aptAffWidget'
            },
            layoutType : "",
            renderedAd : false,
            createAdPosition: function (el){
                return new AdPosition({
                    el: $(el),
                    adSizes: [300,250],
                    adPlacement: $(el).data('adtag')
                 });
            },
            renderAd : function (){
                if (!this.renderedAd){
                    this.renderedAd = true;
                    var el = this.$('.js-rad-google-ad')[0];
                    if (el){
                        this.subviews.adPositionOne = this.createAdPosition(el);
                    }
                }
            },
            destroyAd : function () {
                if (this.renderedAd){
                    this.subviews.adPositionOne.destroy();
                    this.renderedAd = false;
                }
            },
            initialize: function(options) {
                BaseView.prototype.initialize.call(this);
                this.layoutType = options.layoutType;
            },
            isSidebarModule : function () {
                return (this.layoutType === 'sidebar');
            },
            isPrimaryWellModule : function () {
                return (this.layoutType === 'primary');
            },
            handleCardSizeChange: function(cardInfo) {
                if (this.isSidebarModule()){
                    if (cardInfo.sidebarAds === true){
                        this.$(".js-rad-sidebar-ad").removeClass("rad-hide-ad");
                        this.renderAd();
                    }else{
                        this.$(".js-rad-sidebar-ad").addClass("rad-hide-ad");
                        this.destroyAd();
                    }
                }else if (this.isPrimaryWellModule()){
                    if (cardInfo.sidebarOpen === true){
                        this.destroyAd();                        
                    }else{
                        this.renderAd();
                    }
                }

                var radWrap = this.$(".js-rad-wrap");                
                if (cardInfo.sidebarOpen === true){
                    radWrap.removeClass("rad-wrap-margin");
                    if (this.layoutType === "primary"){
                        radWrap.addClass("rad-dynamic-height");
                    }
                    this.$(".js-rad-wrap-primary").removeClass("rad-top-border");
                    this.$(".js-rad-google-ad").removeClass("rad-dynamic-margin");
                }else{
                    radWrap.addClass("rad-wrap-margin");
                    if (this.layoutType === "primary"){
                        radWrap.removeClass("rad-dynamic-height");
                    }
                    this.$(".js-rad-wrap-primary").addClass("rad-top-border");
                    this.$(".js-rad-google-ad").addClass("rad-dynamic-margin");
                }

            },
            onCardWidthChange: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            },
            renderCardInfo: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            },
            aptAffWidget: function (event) { 
                var cityStateZip, bedrooms, bathrooms, rentMax, aptsURL;

                cityStateZip = this.$('.js-rad-form-city').val();
                bedrooms = this.$('.js-rad-form-bedrooms').val();
                bathrooms = this.$('.js-rad-form-bathrooms').val();
                rentMax = this.$('.js-rad-form-rent').val();
                aptsURL = 'https://www.apartments.com/search/?query=' + cityStateZip;
                if (!cityStateZip) {
                    $('.js-rad-form-city').focus();
                } else {
                    if (bedrooms) aptsURL += '&bedchkvalue=' + bedrooms;
                    if (bathrooms) aptsURL += '&bathchkvalue=' + bathrooms;
                    if (rentMax) aptsURL += '&rentmax=' + rentMax;
                    aptsURL += '&stype=CityStateOrZip';
                    RouteManager.goTo(aptsURL);
                }
                return false;
            }

        });

        /*
         * Return view class.
         */
        return RentalsApartmentAd;
    }
);



