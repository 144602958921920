define('user-manager',[
    'jquery',
    'underscore',
    'pubsub',
    'managers/cachemanager'
],
function(
    $,
    _,
    PubSub,
    CacheManager
) {
    'use strict';
    /**
     * User Manager manages the state of a user on the site providing login and logout functionality.
     * @exports user-manager
     * @author Mark Kennedy <mdkennedy@gannett.com>
     */
    var UserManager = function(){
        this.initialize();
    };

    UserManager.prototype = {
            /** @const
             * @private */
            PREFERRED_ACCOUNT_KEY: 'userPreferredAccount',
            /** @const
             * @private */
            PREFERRED_ACCOUNT_TIMEOUT: 365 * 60 * 24, // 365 days

            /**
             * Initializes UserManager and gets initial variables.
             */
            initialize: function() {
                this.resetManager();
                if (this.preferredAccount) {
                    this.state = 'loggingIn';
                    PubSub.on('page:load', this.autoLogin, this);
                }
                PubSub.on('user:statuschange', this._userStatusChange, this);
            },

            destroy: function() {
                this.resetManager();
                PubSub.off('user:statuschange', this._userStatusChange, this);
                PubSub.off('page:load', this.autoLogin, this);
            },

            autoLogin: function() {
                this.state = null;
                this.loginUser(this.preferredAccount, true).fail(_.bind(function(){
                    // if we can't auto login, trigger a logout to flush any caches we may have
                    this.logoutUser();
                }, this));
                PubSub.off('page:load', this.autoLogin, this);
            },

            /**
             * Registers a account which gets added to User Manager's workflow.
             * @param {Object} Account view account
             */
            registerAccount: function(Account) {
                var accountName = Account.getName();

                if (!this.accounts[accountName]) {
                    this.accounts[accountName] = Account;
                } else {
                    console.error('duplicate account name given to registerAccount');
                    return;
                }
            },

            /**
             * Un-registers a account.
             * @param {Object} Account view
             */
            unRegisterAccount: function(Account) {
                var accountName = Account.getName();

                if (this.accounts[accountName]) {
                    this.accounts[accountName] = null;
                } else {
                    console.error('unRegisterAccount called on name that isn\'t registered!');
                }
                Account.logout();
                if (this.preferredAccount === accountName) {
                    this.setPreferredAccount(null);
                }
            },

            /**
             * Attempts to log a user into the site.
             * @param {String} [accountName=preferredAccount] The account name
             * @param {Boolean} [isAutoLogin] True if this is an autoLogin attempt
             * @return {Deferred} promise object that resolves when the user is logged in successfully
             */
            loginUser: function(accountName, isAutoLogin) {
                return this._askAccount('login', accountName, isAutoLogin);
            },

            /**
             * Listener of user status changes so we can update the state of UserManager appropriately
             * @param {String} accountName
             * @param {String} status
             * @param {Object} userData
             * @private
             */
            _userStatusChange: function(accountName, status, userData) {
                if (status === 'loggedIn') {
                    this._onLoginSuccess(accountName, userData);
                } else if (status === 'loggedOut') {
                    this._onLogoutSuccess(accountName);
                }
            },

            /**
             * When a user is logged in successfully.
             * @param {String} accountName The account view that user has logged into
             * @param {Object} userData The user's information.
             * @private
             */
            _onLoginSuccess: function(accountName, userData) {
                this.setPreferredAccount(accountName);
                PubSub.trigger("user:login", userData, accountName);
            },

            /**
             * Sets the preferred account
             * @param {String} accountName The name of the account to use as the preferred one.
             */
            setPreferredAccount: function(accountName) {
                var accountKey = this.getPreferredAccountLookupKey();
                if (accountName) {
                    CacheManager.setValue(accountKey, accountName, this.PREFERRED_ACCOUNT_TIMEOUT);
                } else {
                    CacheManager.clearValue(accountKey);
                }
                this.preferredAccount = accountName;
            },

            /**
             * Gets the cookie lookup identifier for the preferred account.
             * @returns {String}
             */
            getPreferredAccountLookupKey: function() {
                return this.PREFERRED_ACCOUNT_KEY;
            },

            /**
             * Gets the currently set preferred account.
             */
            getPreferredAccount: function() {
                return this.preferredAccount || CacheManager.getValue(this.getPreferredAccountLookupKey(), null, this.PREFERRED_ACCOUNT_TIMEOUT);
            },

            /**
             * Reports the user's login/logout state.
             * @returns {String} returns 'loggedIn', 'loggingIn', 'loginFailed' or 'loggedOut'
             */
            getLoginStatus: function(accountName) {
                var loginStatus, account = this.getAccount(accountName);
                if (account) {
                    loginStatus = account.getLoginStatus();
                } else {
                    loginStatus = 'loggedOut';
                }
                // handle situations where someone has asked the login status before the world has set up,
                // so there might not be any accounts registered, or we haven't tried to auto login yet,
                return this.state || loginStatus;
            },

            /**
             * Logs a user out of the site.
             * @param {String} [accountName=preferredAccount] optional account name to log out of
             * @return {Deferred} promise object that resolves when the user logged out successfully
             */
            logoutUser: function(accountName) {
                return this._askAccount('logout', accountName);
            },

            /**
             * Logs out of all accounts in the system
             * @returns {Deferred} promise that will resolve when all accounts are logged out of
             */
            logoutAll: function() {
                return $.when.apply($, _.map(this.accounts, function(account) {
                    return account.logout();
                }));
            },

            /**
             * Clears user session data.
             * @private
             */
            _onLogoutSuccess: function(accountName) {
                PubSub.trigger("user:logout", accountName);
                if (accountName === this.getPreferredAccount()) {
                    this.setPreferredAccount(null);
                }
            },

            /**
             * Gets a user account
             * @param {String} [accountName=preferredAccount] The account name to grab
             * @returns {Object} Returns the account object
             */
            getAccount: function(accountName) {
                return this.accounts[accountName || this.preferredAccount];
            },

            /**
             * Gets information for the currently logged in user.
             * @param {String} [accountName=preferredAccount]
             * @returns {Deferred}
             */
            getUserInfo: function(accountName) {
                return this._askAccount('getUserInfo', accountName);
            },

            /**
             * Gets attributes for the current user.
             * @param {String} [accountName=preferredAccount]
             * @returns {Deferred}
             */
            getUserAttributes: function(accountName) {
                return this._askAccount('getUserAttributes', accountName);
            },

            /**
             * Uses an account to grab the core information for a user.
             * @param {String} [accountName=preferredAccount] Account to use for core user info login
             * @returns {Deferred} promise object that resolves when the core information is fetched successfully
             */
            getCoreUserInfo: function(accountName) {
                return this._askAccount('getCoreUserInfo', accountName);
            },

            /**
             Reset manager to a valid state
            */
            resetManager: function() {
                this.accounts = {};
                this.preferredAccount = this.getPreferredAccount();
            },

            /**
             * Private delegator, will get an account name (optional, defaults to preferred account),
             * and calls a method on it, returning it's results
             * @param {String} methodName - name of method to call
             * @param {String} [accountName=preferredAccount] - name of account to call method on
             * @param {...*} args - arguments to pass to the method
             * @returns {*}
             * @private
             */
            _askAccount: function(methodName, accountName, args) {
                var account = this.getAccount(accountName);
                if (account) {
                    if (account[methodName]) {
                        return account[methodName].apply(account, Array.prototype.slice.call(arguments, 2));
                    } else {
                        return $.Deferred().reject('Account ' + (accountName || this.preferredAccount) + ' does not support method ' + methodName);
                    }
                } else {
                    return $.Deferred().reject('Invalid Account');
                }
            }

        };
    return new UserManager();
});

