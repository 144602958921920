/**
 * @fileoverview Weather 24 Hour module.
 * @author bminton@gannett.com (Brandon Minton)
 */
define('modules/weather/weather-twentyfour-hour',[
    'jquery',
    'underscore',
    'baseview',
    'state'
],
    function(
        $,
        _,
        BaseView,
        StateManager
        ) {
        "use strict";
        /**
         * View class.
         */

        var weatherTwentyFourHour = BaseView.extend({

            // View element.
            el: '.weather-twentyfour-hour-wrapper',

            // Events.
            events: {
                'click .paginator-indicator-bullet': 'toggleTwelve'
            },

            /**
             * Initialize view.
             * @param {Object} options
             */
            initialize: function(options) {
                this.$slide = this.$('.weather-twentyfour-hour-wrapper .slide');
                this.$nextTwelve = this.$('#current12');
                this.$currentTwelve = this.$('#next12');

                BaseView.prototype.initialize.call(this, options);
            },

            toggleTwelve: function(e) {
                this.$clicked = $(e.currentTarget).attr('id');
                this.$isActive = $(e.currentTarget).hasClass('active');

                if (!this.$isActive && this.$clicked == 'next12') {
                    this.$nextTwelve.removeClass('active');
                    this.$currentTwelve.addClass('active');
                    this.$slide.animate({left: '-686px'}, 500);
                } else if (!this.$isActive && this.$clicked == 'current12') {
                    this.$currentTwelve.removeClass('active');
                    this.$nextTwelve.addClass('active');
                    this.$slide.animate({left: '0px'}, 500);
                }
            }
        });

        /**
         * Return view class.
         */
        return weatherTwentyFourHour;
    }
);
