/**
 * @fileoverview Insider Front view module.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('modules/insider-fronts',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {

        /**
         * View class.
         */
         //Used to restore the meta viewport when we destroy
        var InsiderFrontView = BaseView.extend({
            /**
             * Initialize view.
             */
            initialize: function() {
                // call base class initialize
                BaseView.prototype.initialize.call(this);

                // add ellipsis if text exceeds container bounds
                this.addEllipsisToHeadline();
            },
            addEllipsisToHeadline: function() {
                this.$('.fwi-front-fwifm-deal-module').each(function() {
                    var next_headline = $(this).find('.fwi-front-fwifm-deal-module-h1');
                    var headline_height =  next_headline.height();
                    while (next_headline[0].scrollHeight > headline_height) {
                        var text = next_headline.html().replace(/\W*\s(\S)*$/, '&hellip;');
                        next_headline.html(text);
                    }
                });
            }
        });

        /**
         * Return view class.
         */
        return InsiderFrontView;
    }
);
