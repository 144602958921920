define('modules/videos/pluto/pluto-ads',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'cookie'

],
    function (
    $,
    _,
    BaseView,
    Utils
) {

    "use strict";


    var PlutoAds = BaseView.extend(
        {
            events: {
                'click .pluto-skip-ad-button' : 'skipAd',
                'mouseenter .vjs-ima3-ad-container' : 'showAdControlBar'
            },

            initialize: function (options) {
                options = $.extend({
                    actionName: 'plutoVideoReport',
                    adAccountId: null,
                    adAccountName: null,
                    adLabel: null,
                    adPlacement: null,
                    adUnit: null,
                    videostart: null,
                    ima3: null,
                    minimumAdLength: null,
                    onAdEnd: null,
                    onAdPause: null,
                    onAdPlay: null,
                    onVolumeChange: null,
                    pageInfo: null,
                    placementData: null,
                    playVideo: null,
                    videoData: null,
                    vjsPlayer: null,
                    bidSlot: null
                }, options);
                this.bidSlot = this.options.bidSlot;

                BaseView.prototype.initialize.call(this, options);
                _.bindAll(this, 'renderAdCounter');

                this.minumumAdLength = this.options.minimumAdLength;
                this.msgSkip = 'Skip in ';
                this.vjsPlayer = this.options.vjsPlayer;
                this.ima3Settings = this.options.ima3;
                this.blockFlash = this.isSafari() && this.isDockedPlayer();
                this.isIPhone = navigator.userAgent.match(/(iPhone)/i);
                this.isIPad = navigator.userAgent.match(/(iPad)/i);
                this.isIOS = this.isIPad || this.isIPhone;
                //safari 10 blocks flash ads by default; known issue with flash + scrolling player in safari
                if (this.blockFlash) {
                    this.ima3Settings.adTechOrder = ['html5'];
                }
                if (!this.vjsPlayer.ima3.settings){
                    this.vjsPlayer.ima3(this.ima3Settings);
                }
                else {
                    $.extend(this.vjsPlayer.ima3.settings, this.ima3Settings);
                }
                if (!this.hasListeners) this.setupAdEventListeners(this.vjsAdEvents);
                this.$adContainer = this.$('.vjs-ima3-ad-container');
                this.$adOverlayContainer = this.$('.pluto-ad-overlay');
                this.$adOverlayMsg = this.$('.pluto-ad-msg');
                this.$adOverlaySkip = this.$('.pluto-skip-ad-button');
            },
            setBid: function (bid) {
                this.bidSlot = bid;
            },

            /**
             * Builds Ad server params for video.
             * @returns {string}
             */
            buildAdServerParam: function () {

                var adServerUrl = Utils.getUrlParam('adserverurl');
                if (adServerUrl) {
                    return decodeURIComponent(adServerUrl);
                } else {
                    var aam = (($.cookie('aamusat') || '').match(/[0-9]+/g) || []).join(','),
                        adRule = this.ima3Settings.adRule || '0',
                        aid = $.cookie('aam_uuid'),
                        cust_params = '',
                        output = this.ima3Settings.output,
                        pageInfo = this.options.pageInfo,
                        playersize = this.vjsPlayer.width() + 'x' + this.vjsPlayer.height(),
                        simpleExclusion = (window.simpleExclusion || []).join(','),
                        simpleTarget = (window.simpleTarget || []).join(','),
                        sz = '920x508',
                        videoData = this.options.videoData,
                        cst = this.options.cstObj,
                        keywords = videoData.presto_keywords || '', // presto taxo keywords
                        ssts = this.options.sstsObj,
                        series = videoData.series || pageInfo.series || '',
                        videotag = videoData.keywords || '', // free form/brightcove
                        cmsid = this.ima3Settings.cmsId || "12768",
                        pageLevelInfo = '',
                        breaking = Utils.getNested(pageInfo, 'breaking'),
                        alerttype = (breaking) ? 'breaking': (breaking === false) ? 'none': 'NA',
                        navigationtype = Utils.getUrlParam('navigationtype');

                    if (pageInfo.basePageType) {
                        cust_params += '&pagetype=' + encodeURIComponent(pageInfo.basePageType);
                    }
                    if (this.options.adLabel) {
                        cust_params += '&adlabel=' + encodeURIComponent(this.options.adLabel);
                    }
                    if (pageInfo.adSize) {
                        sz = pageInfo.adSize;
                    }
                    if (pageInfo.topic) {
                        // Extend the video keywords with the article keywords to help with brand safety
                        keywords += ',' + pageInfo.topic;
                    }
                    if (simpleTarget) {
                        cust_params += '&categoryvalue=' + simpleTarget;
                    }
                    if (simpleExclusion) {
                        cust_params += '&excl_cat=' + simpleExclusion;
                    }
                    if (pageInfo.texttype) {
                        pageLevelInfo  = this.getpageLevelInfo(pageInfo) || '';
                    }

                    cust_params += '&aam=' + aam;
                    cust_params += '&contentid=' + videoData.id;
                    cust_params += '&credit=' + videoData.credit;
                    cust_params += '&origin=' + videoData.origin;
                    cust_params += '&cst_section=' + cst.section;
                    cust_params += '&cst_subsection=' + cst.subsection;
                    cust_params += '&cst_topic=' + cst.topic;
                    cust_params += '&playersize=' + playersize;
                    cust_params += '&playertype=' + this.options.placementData.name;
                    cust_params += '&series=' + series;
                    cust_params += '&ssts_section=' + ssts.section;
                    cust_params += '&ssts_subsection=' + ssts.subsection;
                    cust_params += '&ssts_topic=' + ssts.topic;
                    cust_params += '&ssts_subtopic=' + ssts.subtopic;
                    cust_params += '&topic=' + keywords;
                    cust_params += '&videolength=' + Math.round(this.vjsPlayer.mediainfo.duration);
                    cust_params += '&videostart=' + this.options.videoStart;
                    cust_params += '&videotag=' + videotag;
                    cust_params += '&u=' + aid;
                    cust_params += '&mutedAutoplay=' + this.options.placementData.mutedAutoplay;
                    cust_params += '&alerttype=' + alerttype;

                    if (navigationtype) {
                        cust_params += '&navigationtype=' + navigationtype;
                    }

                    if (this.options.adAccountId || this.options.adAccountName || this.options.adPlacement !== 'preroll_video') {
                        this.rebuildAdUnit();
                    }
                    var url = 'https://pubads.g.doubleclick.net/gampad/ads?env=vp&gdfp_req=1&impl=s&output=' + output +
                        '&iu=' + this.options.adUnit + '/' + videoData.cst +
                        '&ad_rule=' + adRule + '&description_url=' + videoData.url +
                        '&sz='+sz+ '&unviewed_position_start=1' +
                        '&pageInfo='+ pageLevelInfo +
                        '&cmsid='+cmsid+'&vid=' + videoData.brightcove_id;
                    if (this.bidSlot && this.bidSlot.encodedQsParams) {
                        url += '&scp=' + this.bidSlot.encodedQsParams;
                    }
                    if (window.videoPrebidBids && window.videoPrebidBids['video-' + videoData.id]) {
                        // Generate DFP Video Ad Server Tag URL
                        var options = {
                            'adserver': 'dfp',
                            'code': 'video-' + videoData.id
                        };
                        url = window.pbjs.buildMasterVideoTagFromAdserverTag(url, options);
                    }
                    // buildMasterVideoTagFromAdserverTag decodes cust_params, so add them after that function has been called
                    url += '&cust_params=' + encodeURIComponent(cust_params);
                    return url;
                }
            },

            getpageLevelInfo: function(pageInfo) {
                switch (pageInfo.texttype) {
                    case 'front':
                        return 'front:' + (pageInfo.ssts).toUpperCase();
                    case 'story':
                    case 'blog':
                        return 'story:' + pageInfo.assetid;
                    case 'video':
                    case 'video-playlist':
                        return 'video:' + pageInfo.assetid;
                    default:
                        return;
                }
            },

            rebuildAdUnit: function () {
                if (this.options.adAccountId && this.options.adAccountName && this.options.adPlacement !== 'preroll_video') {
                    this.options.adUnit = this.options.adAccountId + '/' + this.options.adAccountName + '/' + this.options.adPlacement;
                } else {
                    var adUnit = this.options.adUnit.split('/');
                    if (this.options.adAccountId) adUnit[0] = this.options.adAccountId;
                    if (this.options.adAccountName) adUnit[1] = this.options.adAccountName;
                    if (this.options.adPlacement !== 'preroll_video') adUnit[2] = this.options.adPlacement;

                    this.options.adUnit = adUnit.join('/');
                }
            },


            /**
             * Sets up the ad event listeners for a video player.
             * http://docs.brightcove.com/en/perform/brightcove-player/guides/ima-plugin.html#events
             */
            setupAdEventListeners: function () {
                this.hasListeners = true;
                this.vjsAdEvents = {
                    'ads-ad-ended': this.onAdEnded,
                    'ads-ad-started': this.onAdsAdStarted,
                    'ads-allpods-completed': this.onAdsAllpodsCompleted,
                    'ads-click': this.onAdsClick,
                    'ads-pause': this.onAdPause,
                    'ads-play': this.onAdPlay,
                    'ads-volumechange': this.onAdVolumeChange,
                    'ima3-ad-error': this.ima3AdError,
                    'ima3-ready': this.onIma3Ready,
                    'ads-load': this.onAdsLoad
                };

                _.each(this.vjsAdEvents, _.bind(function (value, prop) {
                    this.vjsPlayer.on(prop, _.bind(value, this));
                }, this));
            },

            onAdsAllpodsCompleted: function () {
                // facebook flash ads don't fire ended event so we have to rely on pods completed event
                this.skipAd();
            },

            onAdVolumeChange: function(e) {
                if (this.options.onVolumeChange) {
                    this.options.onVolumeChange(this.isAdMuted());
                }
            },

            onIma3Ready: function() {
                // setup ad error listener
                this.vjsPlayer.ima3.adPlayer.on('adserror',_.bind(function(e){
                    this.ima3AdError(e);
                }, this));
            },

            onAdsLoad: function () {
                if (this.vjsPlayer.muted() || this.playerVolume === 0) {
                    this.vjsPlayer.ima3.adPlayer.volume(0);
                } else {
                    this.vjsPlayer.ima3.adPlayer.volume(this.playerVolume);
                }
            },


            makeAdRequest: function(data) {
                if (data) this.options.videoData = data;
                if (this.prebidPromise) {
                    this.prebidPromise.then(_.bind(function() {
                        this.vjsPlayer.ima3.adrequest(this.buildAdServerParam());
                    }, this));
                } else {
                    this.vjsPlayer.ima3.adrequest(this.buildAdServerParam());
                }
            },

            /**
             * on ad error, remove overlay
             */
            ima3AdError: function(e){
                if (Utils.getNested(this.options.placementData, 'enableLogging') && Utils.logAction) {
                    var details = $.extend(this.getImaErrorDetails(e), this.getAdVendorDetails(e));
                    Utils.logAction(this.options.actionName, {
                        'type': 'adError',
                        'details': details,
                        'adSystem': details.adSystem ? details.adSystem : '',
                        'placement': Utils.getNested(this.options.placementData, 'name'),
                        'domain': Utils.getNested(window.site_vars, 'site_key'),
                        'siteCode': Utils.getNested(window.site_vars, 'site_code'),
                        'videoId': this.options.videoData.id
                    });
                }
                this.onAdEnded();
                /**
                 * Don't call playVideo when ad errors out on iPad,
                 * otherwise video will be paused with video_still being visible
                 */
                if ((!this.isIPad && this.options.playVideo) || this.options.placementData.name == 'playlistfronthtml5') {
                    this.options.playVideo();
                }
            },

            /**
             * Try to get details from ima3 error event
             * @param {Event} e - error event object
             * @returns {Object} - error event details
             */
            getImaErrorDetails: function(e) {
                var error = {'event': e.type};
                try {
                    if (e.originalEvent && e.originalEvent.getError) {
                        error.event = e.originalEvent.getError().toString();
                    }
                } catch (err) {}

                return error;
            },

            /**
             * Get ad vendor details from error event
             * @param {Event} e - error event object
             * @returns {Object} - ad vendor details
             */
            getAdVendorDetails: function(e) {
                var ad = null,
                    details = {};
                if (e.emitter && e.emitter.getCurrentAd) {
                    ad = e.emitter.getCurrentAd();
                }
                if (ad) {
                    try {
                        details = {
                            adSystem: ad.getAdSystem(),
                            adId: ad.getAdId(),
                            apiFramework: ad.getApiFramework(),
                            mediaUrl: ad.getMediaUrl(),
                            duration: ad.getDuration(),
                            creativeId: ad.getCreativeId()
                        };
                    } catch(err) {}
                }
                return details;
            },


            /**
             * Set flags, add dom elements
             * Only add overlay if skippable=false
             */
            onAdsAdStarted: function(){
                //Brightcove maps flash ads to currentAd root and non-flash to currentAd.b
                this.currentAd = this.vjsPlayer.ima3.currentAd;
                 // Flash is blocked by default in Safari 10
                 // there is also a known issue with flash + scrolling player in Safari
                if (this.blockFlash && (this.currentAd.getContentType() || '').indexOf('flash') > -1) {
                    this.skipAd();
                }

                // This calls the player instance's pause which causes preroll to pause in ios devices.
                if (!this.isIOS) {
                    this.options.vjsPlayer.pause();
                }

                this.adControlBar();
                this.onAdPlay();
                this.isAndroid = /(android)/i.test(navigator.userAgent);

                /**
                 * Resize the ad if played on Android device or if embeded
                 * 'normal' view mode is hardcoded to avoid warning (since google.ima.ViewMode.NORMAL gives the same value)
                 */
                if (this.isAndroid || this.options.placementData.responsive) {
                    this.vjsPlayer.ima3.adsManager.resize(this.vjsPlayer.width(), this.vjsPlayer.height(), 'normal');
                }

                this.$adContainer.removeClass('pluto-ad-visibility-hidden');

                /*
                    this.adHasSkip is true when the user gets a TruVu or a Facebook ad which have their own skip button
                 */
                this.adHasSkip = this.adHasOwnSkip();
                if (!this.adHasSkip && !(this.isIPhone)) {
                    this.adOverlay();
                    /* Delay display until adOverlay has chance to run */
                    window.setTimeout(_.bind(function () {
                        this.$adOverlayContainer.removeClass('pluto-ad-hide');
                    }, this), 500);
                }
                this.$('.vjs-video-title span').html('Sponsor Message');

            },

            onAdEnded: function() {
                if (this.options.onAdEnd) this.options.onAdEnd();
                this.adOverlayReset();
            },

            onAdPlay: function() {
                if (this.options.onAdPlay) this.options.onAdPlay();
            },

            onAdPause: function() {
                if (this.options.onAdPause) this.options.onAdPause();
            },


            /**
             * A viewer clicked on the playing ad
             * force ad to pause on click, not automatically paused by all ads
             */
            onAdsClick: function(){
                this.vjsPlayer.ima3.adPlayer.pause();
            },


            /**
             * When a flash ad is called a new controlbar is created
             * Need to add title element to adControlBar
             */
            adControlBar: function () {
                var controlBar = '.vjs-control-bar.vjs-ad-control-bar';

                if(!this.$(controlBar + ' .vjs-video-title')[0]){
                    this.$(controlBar).append(
                       '<div class="vjs-video-title"><span></span></div>'
                    );
                }
            },

            /**
             * Repeat function to render text/time if ad not complete
             */
            adOverlay: function (){
                this.renderAdCounter();
                this.adCounter = setInterval(this.renderAdCounter, 500);
            },

            adHasOwnSkip: function () {
                var hasSkip;
                try {
                    hasSkip = this.currentAd.isSkippable() || !_.isEmpty(Utils.getNested(JSON.parse(this.currentAd.getTraffickingParametersString() || '{}'), 'capabilities', 'skipButton'));
                } catch (err) {
                    hasSkip = false;
                }
                return hasSkip;
            },

            /**
             * * @param {string} [state] - returns true or false
             * * 'ad-playback' || 'content-playback'
             */
            adState: function (state){
                return this.vjsPlayer.ads.state === state;
            },


            /**
             * * {string} [duration] - ad length
             * * {number} [secondsBeforeSkip] - Number of seconds to before user can skip
             * * {boolean} [minSkipFlag] - Minimum ad length in seconds to allow skip and > duration
             * * {string} [currentTime] - current ad time
             * * {number} [adManagerCurrentTime] - returns adManager remaining ad time in seconds
             * * {number} [remainingTime] - returns remaining ad time in seconds
             * * {boolean} [plutoSkippable] - flag to display this.msgSkip
             * * {string} [seconds] - seconds before skip and/or seconds left
             */
            renderAdCounter: function () {
                var ima3 = this.vjsPlayer.ima3,
                    duration = ima3.adPlayer.duration(),
                    secondsBeforeSkip = this.ima3Settings.secondsBeforeSkip,
                    minSkipFlag = duration >= this.ima3Settings.requiredSkipAdLength,
                    currentTime = ima3.adPlayer.currentTime(),
                    adManagerCurrentTime = ima3.adsManager ? ima3.adsManager.getRemainingTime() : 0,
                    remainingTime = (currentTime !== 0 && duration !== 0) ? duration - currentTime : adManagerCurrentTime,
                    plutoSkippable = (currentTime < secondsBeforeSkip) && minSkipFlag,
                    seconds = plutoSkippable ? Math.round(secondsBeforeSkip - currentTime) : Math.round(remainingTime);

                if (this.adState('ad-playback') ) {
                    if (currentTime > 0 && remainingTime > 0 && duration > this.minumumAdLength) {
                        this.$adOverlayContainer.removeClass('pluto-ad-visibility-hidden');
                        if (plutoSkippable && seconds > 0) {
                            this.$adOverlayMsg.removeClass('pluto-ad-hide');
                            this.$adOverlayMsg.html(this.msgSkip + seconds);
                        } else {
                            if (minSkipFlag) {
                                this.$adOverlaySkip.removeClass('pluto-ad-hide');
                                this.$adOverlayMsg.addClass('pluto-ad-hide');
                            }
                        }
                    } else {
                        this.$adOverlayContainer.addClass('pluto-ad-visibility-hidden');
                    }
                } else if (this.adState('content-playback')) {
                    this.adOverlayReset();
                }
            },


            /**
             * Reset flags, title
             * Clear ad messages, hide skip
             */
            adOverlayReset: function () {
                this.$adContainer.addClass('pluto-ad-visibility-hidden');
                this.$adOverlayMsg.html('');
                this.$adOverlayContainer.addClass('pluto-ad-hide');
                this.$adOverlaySkip.addClass('pluto-ad-hide');
                this.$('.vjs-video-title span').html(this.options.videoData.title);
                clearInterval(this.adCounter);
            },

            /**
             * ends ad, resets ad overlay content
             */
            skipAd: function (e) {
                if(this.adState('ad-playback') || (this.adHasSkip && this.adState('content-playback'))) {
                    if (e) e.preventDefault();

                    this.vjsPlayer.ima3.adPlayer.pause();
                    this.vjsPlayer.ads.endLinearAdMode();
                    this.vjsPlayer.ima3.adsManager.destroy();

                    this.adOverlayReset();
                    if (this.options.onAdEnd) this.options.onAdEnd();
                }
            },

            showAdControlBar: function (){
                if(this.adState('ad-playback')) {
                    this.vjsPlayer.userActive(true);
                }
            },

            pauseAd: function() {
                this.vjsPlayer.ima3.adPlayer.pause();
            },

            playAd: function() {
                this.vjsPlayer.ima3.adPlayer.play();
            },

            isAdPlaying: function() {
                return !this.vjsPlayer.ima3.adPlayer.paused() && this.adState('ad-playback');
            },

            isAdInProgress: function() {
                return this.vjsPlayer.ads.state === "ad-playback";
            },

            setAdVolume: function(volume) {
                this.vjsPlayer.ima3.adPlayer.volume(volume);
            },

            getAdVolume: function() {
                return this.vjsPlayer.ima3.adPlayer.volume();
            },

            isAdMuted: function() {
                return this.getAdVolume() === 0;
            },

            getAdRunTime: function() {
                var time = this.vjsPlayer.ima3.adPlayer.duration(),
                    mins = Math.floor(time / 60),
                    s = time - mins * 60;

                return mins + ":" + Math.round(s);
            },

            isSafari: function() {
                return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;
            },

            isDockedPlayer: function() {
                return Utils.getNested(this.options.placementData, 'scrollingplayer') &&
                    Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'docked');
            },
            
            /**
             * Destroys the view
             */
            destroy: function () {
                this.adOverlayReset();
                BaseView.prototype.destroy.apply(this, arguments);
            }

        });

    /**
     * Return view class.
     */
    return PlutoAds;
    }
);

