/**
 * @fileoverview Assigned Content Nav module view.
 * @author Craig Johnson
 */
define('modules/fronts/assigned-content-nav',[
    'jquery',
    'underscore',
    'baseview',
    'ui/generic-paginator'
], function ($, _, BaseView, GenericPaginatorView) {

    var AssignedContentNav = BaseView.extend({
    
        events: {
            'click .acnpm-filter' : 'filterItems'
        },

        initialize: function(options) {
        
            _.bindAll(this, 'goTo');

            this.subviews = {};
            
            this.$itemsContainer = this.$('.acnpm-items-container');
            this.$items = this.$('.acnpm-item');
            this.itemsPerRow = this.$el.hasClass('six-across') ? 6 : 8;
            this.$pageElements = this.$('.paginator-indicator-target');
            
            this.subviews.paginator = new GenericPaginatorView({
                el: this.$('.paginator'),
                onGoTo: this.goTo
            });

            // call base class initialize
            BaseView.prototype.initialize.call(this, options);
        },
        
        filterItems: function (evt) {
            evt.preventDefault();
            
            var targetClass = evt.currentTarget.id,
                visibleCount, bulletCount;
        
            this.$('.acnpm-filter').removeClass('selected').filter(evt.currentTarget).addClass('selected');
        
            this.$pageElements.eq(0).trigger('click');

            this.$items.show();
        
            if (targetClass != 'all') {
                this.$items.not('.'+targetClass).hide();
            }
            
            // adjust number of pagination bullets to match number of visble items

            visibleCount = this.$items.filter(':visible').length;
            
            bulletCount = Math.floor( visibleCount / this.itemsPerRow );
            
            if (bulletCount === 0) {
                this.$('.acnpm-container').addClass('nopagination');
            } else {
                this.$('.acnpm-container').removeClass('nopagination');
                this.$pageElements.show().filter(':gt('+bulletCount+')').hide();
            }
        }, 
        
        goTo: function (pageNumber) {
            this.animate(this.$itemsContainer, 'left', (-pageNumber * 696) + 'px', 450, 'ease-in-out');
        }
        
    });

    return AssignedContentNav;

});

