/**
 * @fileoverview Sidebar Poster Ad View
 * @author teckels@gannett.com (Trey Eckels)
 */
define('modules/fronts/sidebar-poster-ad',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'utils',
    'state',
    'adPosition'
],
function(
    $,
    _,
    BaseView,
    PubSub,
    Utils,
    StateManager,
    AdPosition
)
    {
        'use strict';
        /**
         * View class.
         */
        var SidebarPosterAd = BaseView.extend({

            /**
             * Initialize view.
             */
            initialize: function(options) {
                _.bindAll(this,
                    'onSidebarAdReady'
                );

                var $el = this.$el;
                this.layoutType = this._getLayoutType();

                options = $.extend({
                    adType : $el.attr('data-ad-type') || 'sidebar',
                    adPlacement: $el.attr('data-ad-placement') ||  'poster_front',
                    adSizes: $el.data('ad-sizes') || [[300, 250], [300, 600]],
                    deferred: $.Deferred()
                }, options, {
                    el: this.$el,
                    pageInfo: StateManager.getActivePageInfo(),
                    onAdReady: this.onSidebarAdReady
                });

                this.pubSub = {
                    'entered:view:sidebarPosterAd': this.onAdEnterView,
                    'exited:view:sidebarPosterAd': this.onAdExitView
                };

                BaseView.prototype.initialize.call(this, options);

                if (!this.subviews.sidebarAd) {
                    this.subviews.sidebarAd = new AdPosition(options);
                }
            },

            onAdEnterView: function(el) {
                if (Utils.flag('scroll_destroy_sidebar_ad') && el.gannett.intersectionCount > 1) {
                    var timer = setTimeout(_.bind(function() {
                        if (el.gannett.isIntersecting) {
                            this._initAds();
                        }
                    }, this), 1000);
                }
            },

            onAdExitView: function(el) {
                if (Utils.flag('scroll_destroy_sidebar_ad')) {
                    this.destroyAd(el);
                }
            },

            destroyAd: function(el) {
                if (this.subviews.sidebarAd && el === this.el) {
                    this.subviews.sidebarAd.destroy();
                    delete this.subviews.sidebarAd;
                }
            },

            _getLayoutType: function(){
                var layout = null;
                if(this.options.layoutType){
                    if (this.options.layoutType.indexOf('primary') !== -1) {
                        layout = 'primary';
                    } else if (this.options.layoutType === 'sidebar'){
                        layout = 'sidebar';
                    }
                }
                return layout;
            },
            
            onSidebarAdReady : function() {
                this.options.deferred.then(_.bind(function() {
                    this.subviews.sidebarAd.showAd();
                    // announce that the scrollbar should update
                    this.refreshSidebar();
                }, this));
            },

            refreshSidebar: function(){
                StateManager.getActiveApp().triggerEvent('refreshSidebarScroll');
            },

            /**
             * Used to call the ad position's destroy method if the card width changes and the ad exists
             * because the sidebar width will be too small to show ad
             * @private
             */
            _onHideSidebarAd: function() {
                //if we have an ad placement
                if(this.subviews.sidebarAd){

                    if(this.layoutType === 'sidebar'){
                        this.hide();

                        // clear ad height on sidebar
                        this.$('.sidebar-wrapper').css('top', 0);
                        // announce that the scrollbar should update
                        this.refreshSidebar();
                    }
                }
            },

            /**
             * Logic to determine whether to show/hide poster front ads (sidebar/below the fold/below the hero)
             */
             showHidePosterAds: function(currentCardInfo) {
                var options = this.options,
                    defer = options.deferred,
                    adPlacement = options.adPlacement;
                if(this.layoutType === 'primary' && ((adPlacement == "poster_front" && currentCardInfo.belowHeroAd) || (currentCardInfo.belowTheFoldAd && adPlacement == "poster_front_btf"))) {
                    defer.resolve();
                } else if(currentCardInfo.sidebarAds && this.layoutType === 'sidebar') {
                    this.show();
                    defer.resolve();
                } else {
                    this._onHideSidebarAd();
                }

             },


            /**
             * Triggered from card.js
             * If the card width has changed and there is an ad placement, we need to destory the
             * ad placement because any size change at this point will be too small to handle the ad
             * @param {Object} currentCardInfo provides state information about the card
             */
            onCardWidthChange: function(currentCardInfo){
                this.showHidePosterAds(currentCardInfo);
            },

             /**
             * Triggered from card.js
             * We place the initAds function here and not in initialize so that we only call an
             * ad once the card is ready to render modules in the sidebar and once we know if the
             * sidebar is actually big enough to handle the ad
             * @param {Object} currentCardInfo provides state information about the card
             */
            renderCardInfo: function(currentCardInfo) {
                this.showHidePosterAds(currentCardInfo);
            }

        });


        /**
         * Return view class.
         */
        return SidebarPosterAd;
    }
);

