define('modules/videos/pluto/pluto-analytics',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'fwinfo'
],
function (
    $,
    _,
    PubSub,
    Utils,
    FwInfo
) {
    "use strict";
    var PlutoAnalytics = function() {};

    PlutoAnalytics.prototype = {

        initAnalytics: function(liteAnalytics) {
            this.isAnalyticsLoaded = $.Deferred();
            this.comscoreEnabled = Utils.getNested(window.site_vars, 'flags', 'comscore_video');
            if (liteAnalytics){
                if (window.tealium_url){
                    var tealium_url = window.tealium_url;
                    Utils.loadScript(tealium_url, 'utag').done(_.bind(function() {
                        window.utag.data.noinitialanalytics = "True";
                        this.isAnalyticsLoaded.resolve(this);
                    }, this)).fail(_.bind(function() {
                        this.isAnalyticsLoaded.reject();
                    }, this));
                }
            } else {
                this.isAnalyticsLoaded.resolve(this);
                this.FullAnalytics = true;
            }
            return this.isAnalyticsLoaded;
        },

        /**
         * video load analytics call
         */
        trackVideoOnload: function(videoData, externalVideoObject, playerName) {
            this.isAnalyticsLoaded.done(_.bind(function(){
                if (this.FullAnalytics) {
                    PubSub.trigger('video:load', this.getVideoEventAnalyticsData(videoData, externalVideoObject, playerName));
                } else {
                    this.liteVideo_load(this.getVideoEventAnalyticsData(videoData, externalVideoObject, playerName));
                }
            }, this));
        },

        /**
         *video load analytics call
         */
        trackVideoEvents: function(eventName) {
            this.isAnalyticsLoaded.done(_.bind(function(){
                if (this.FullAnalytics){
                    PubSub.trigger('uotrack', eventName);
                } else {
                    this.liteUotrack(eventName);
                }
            }, this));
        },

        /**
         *video load analytics call
         */
        trackVideoProgression: function(percentage, url) {
            this.isAnalyticsLoaded.done(_.bind(function(){
                if (this.FullAnalytics) {
                    PubSub.trigger('vitrack', {
                        percentage: percentage,
                        video_url: url
                    });
                } else {
                    this.liteVitrack({percentage: percentage, video_url: url});
                }
            }, this));
        },

        /**
         * Gets analytics data for a video event.
         * @param {Object} videoData
         * @param {Object} externalVideoObject
         * @param {string} [placementName] - A custom event name
         */
        getVideoEventAnalyticsData: function(videoData, externalVideoObject, placementName) {
            var media = externalVideoObject.mediainfo || externalVideoObject.video,
                duration = media.duration;

            var data = {
                    assetid: videoData.id || media.reference_id,
                    byline: this.buildCredit(videoData.credit),
                    videoOrigin: videoData.origin,
                    completion: '0%',
                    contentid: videoData.brightcove_id || media.id,
                    contenttype: videoData.type || 'video',
                    create_date: videoData.create_date || '',
                    cst: videoData.cst || media.custom_fields.gdp_cst,
                    eventtype: 'video:load',
                    keywords: videoData.keywords || media.custom_fields.gdp_tags,
                    miliseconds: Math.round(duration * 1000),
                    ssts: videoData.ssts_string || media.custom_fields.gdp_ssts,
                    url: videoData.full_url,
                    videocontentprovider: videoData.videocontentprovider || media.custom_fields.gannetttracking + '|' + media.name,
                    videoFulllengthUrl: externalVideoObject.src(),
                    videoName: videoData.title || media.custom_fields.liveVideoSubject || media.name,
                    videoplayername: placementName
                };

            if (duration < 0) {
                data.videoDuration = "";
            } else if (duration < 120) {
                data.videoDuration = "less than 2 min";
            } else {
                data.videoDuration = "greater than 2 min";
            }
            if (videoData.url) {
                data.pathName = videoData.url.replace(/^.*\/\/[^\/]+/, '');
            }
            if(!_.isEmpty(videoData)){
                data.section = videoData.ssts.section ? videoData.ssts.section : videoData.ssts_string.split("/")[0];
            } else {
                data.section = '';
            }
            return data;
        },

        buildCredit: function(credit) {
            return (credit && credit.length) ? 'video|' + credit : 'video|author unavailable';
        },

        /**
         * Gets comscore data from site_vars
         * @returns {object} to access values
         */
        getComscoreOptions: function(){
            var comscore = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'COMSCORE'),
                site = comscore.site ? comscore.site : Utils.getNested(window.site_vars, 'site_key');
            return {
                customerC2: comscore.customerC2,
                site: site,
                script_url: comscore.script_url
            };
        },

        /**
         * Stop tracking on pause, content-playback ended or ad-playback ended
         */
        stopComscoreTracking: function(){
            if(this.myStreamingTag && this.comscoreEnabled) {
                this.myStreamingTag.stop();
            }
        },

        /**
         * Metadata options to pass to comscore
         * * [c3] site value, site_key or custom value
         * * [c4] section value
         * * [c6] required null value
         * * [c7] full video url
         * * [c8] video title
         * * [ns_st_ce] Complete episode flag
         * * [ns_st_ci] content id
         * * [ns_st_cl] clip length
         * * [ns_st_ddt] Digital air date
         * * [ns_st_ge] content genre
         * * [ns_st_ia] Ad Load Flag
         * * [ns_st_tdt] TV air date
         * * [ns_st_ti] TMS id
         * */
        comscoreContentPlayback: function(videoData, externalVideoObject, playerName){
           if (this.comscoreEnabled) {
               this.requireComscore().done(_.bind(function() {
                   var data = this.getVideoEventAnalyticsData(videoData, externalVideoObject, playerName),
                       comscoreC4value = (data || {}).contenttype === '360video' ? data.contenttype : data.section,
                       metadata = {
                           c3: this.getComscoreOptions().site,
                           c4: comscoreC4value,
                           c6: "*null",
                           c7: data.url,
                           c8: data.videoName,
                           ns_st_ce: "*null",
                           ns_st_ci: data.assetid,
                           ns_st_cl: data.miliseconds,
                           ns_st_ddt: data.create_date,
                           ns_st_ge: data.section,
                           ns_st_ia: "*null",
                           ns_st_tdt: "*null",
                           ns_st_ti: "*null"
                       };
                   this.myStreamingTag.playVideoContentPart(metadata, window.ns_.StreamingTag.ContentType.LongFormOnDemand);
               }, this));
           }
        },  
        
        resetMyStreamingTag: function () {
            if (this.comscoreEnabled && this.myStreamingTag) {
                this.myStreamingTag = false;
            }
        },

        /**
         * * {string} [ns_st_cl] ad duration
         * */
        comscoreAdPlayback: function(assetId, duration){
           if (this.comscoreEnabled) {
               this.requireComscore().done(_.bind(function() {
                   this.myStreamingTag.playVideoAdvertisement({ ns_st_cl: duration, ns_st_ci: assetId }, window.ns_.StreamingTag.AdType.LinearOnDemandPreRoll);
               }, this));
           }
        },

        /**
         * Require comscore script
         * @returns {Deferred} a promise that resolves after it loads
         * initalize ns_.StreamingTag with account id/#
         */
        requireComscore: function() {
            var reqPromise = $.Deferred();

                var req = require.config({
                    'paths': {
                        'comscore': this.getComscoreOptions().script_url
                    }
                });

                req(["comscore"], _.bind(function () {
                    if(!this.myStreamingTag){
                        this.myStreamingTag = new window.ns_.StreamingTag({customerC2: this.getComscoreOptions().customerC2});
                    }
                    reqPromise.resolve();
                }, this));

            return reqPromise;
        },

        /**
         * The functions that follow are used to track analytics for embed player, bypassing settealium.
         * All other video tracking happens within settealium
         */

        liteVideo_load: function(detail) {
            var data = {},
                hashvalue=location.hash.match(/\bht=([^&]*)/) && RegExp.$1;

            data.byline = detail.byline || "";
            data.origin = detail.origin || "";
            data.clickName= hashvalue || FwInfo.getandclearsessionvalue('clickName');
            data.clickPage=  FwInfo.getandclearsessionvalue('clickPage');
            data.category = detail.category || '';
            data.cst = detail.cst || '';
            data.assetid = detail.assetid || '';
            data.published_date = detail.publishedDate||'';
            data.refreshed = '';
            data.section = detail.section || '';
            data.ssts = detail.ssts || '';
            data.subcategory = detail.subcategory || '';
            data.subsection = detail.subsection || '';
            data.topic = detail.topic || '';
            data.videoPlayerName = detail.videoplayername || '';
            data.prevpath= FwInfo.getandclearsessionvalue('prevpath');
            data.completion = detail.completion || '';
            data.keywords = detail.keywords || '';
            data.taxonomykeywords = detail.keywords || '';
            data.contenttype = detail.contenttype || '';
            data.videoName = detail.videoName || '';
            data.videoDuration = detail.videoDuration || '';
            data.eventtype = detail.eventtype || '';
            data.pathName = detail.pathName || '';
            data.videoContentID = detail.contentid || '';
            data.viralVideoDomain = detail.viralVideoDomain || '';
            data.videoContentProvider = detail.videocontentprovider || '';
            data.templatetype = detail.templatetype || '';
            data.noinitialanalytics = false;
            data.videoincluded = "yes";
            data.videoFulllengthUrl = detail.videoFulllengthUrl || '';
            data.milestonetrack = detail.milestonetrack || '';
            data.videotranscript = detail.videotranscript || '';
            window.utag.track('view', data);
            FwInfo.setsessionvalue('prevpath', document.location.pathname);
        },

        liteVitrack: function(detail) {
            if ('object' != typeof detail) {
                console.error('vitrack has been given a non-object event argument', detail);
                detail= (detail||{}).prop9 || 'somethingbad';
            }

            var data= {
                milestonetrack: detail.percentage,
                eventtype: 'vitrack',
                linkTrackEvents: 'None',
                linkTrackVars: 'prop9',
                video_url: detail.video_url
            };
            window.utag.track('link', data);
        },

        liteUotrack: function(detail) {
            if ('string' != typeof detail) {
                console.error('uotrack has been given a non-string event argument', detail);
                detail= (detail||{}).prop41 || 'somethingbad';
            }
            var data= {clickName: detail, clickPage: location.href, eventtype: 'uotrack', linkTrackEvents: 'None', linkTrackVars: 'prop41,prop64'};
            window.utag.track('link', data);
        }
    };

    return new PlutoAnalytics();
});

