/*global FB:true*/
/*global grecaptcha*/
/**
 * @fileoverview Insider view module.
 * @author rcopley@gannett.com (Ryan Copley) and Dave Burke (dburke2@gannett.com)
 */
define('modules/insider-email',[
        'jquery',
        'underscore',
        'baseview',
        'utils',
        'form/input-field'
    ],
    function(
        $,
        _,
        BaseView,
        Utils,
        InputField
    ) {

        /**
         * View class.
         */
        var InsiderEmailView = BaseView.extend({

            // Events.
            events: {
                'submit .js-share-email-form': 'onSubmitEmailForm',
                'click .recaptcha_option': 'onRecaptcha',
                'click .js-share-email-cancel-btn': 'onClickCancelBtn',
                'click .js-share-email-send-btn': 'onClickSendBtn',
                'click .share-email-form-cancel-btn': 'cancelEmailSend',
                'click .insider-email-button': 'onEmailClicked'
            },

            /**
             * Initialize App.
             */
            initialize: function() {
                // call base class initialize
                _.bindAll(this, 'initInputs', 'sendEmail');
                this.subviews = {};
                this.$recaptchaV2 = this.$('#recaptcha-share');
                this.v2_captcha_available = true;
                this.$emailForm = this.$('.js-share-email-form');
                this.$emailFormContainer = this.$('.js-share-email-form-container');
                BaseView.prototype.initialize.call(this);
            },

            onEmailClicked: function() {
                if (Modernizr.touch){
                    window.location.href = ["mailto:","?","subject=", $(".fwinsider-article-title").text(), "&", "body=", document.URL].join("");
                }else{
                    this.$(".js-share-email-send-btn").addClass("email-send-btn");
                    if (this.$recaptchaV2.length === 0){
                        this.setRecaptcha();
                        Utils.requireSingleUseScript('https://www.google.com/recaptcha/api/challenge?k=6Lf7fuESAAAAAJ3_KMIDbkQySsEE0vMkLXUkq4eY');
                    } else {
                        this.initCaptcha();
                    }
                    this.$(".email-message").css("height", "100px");
                    this.m_uiModalBgrnd = this.$("#insider-lightbox");
                    this.m_uiModal = this.$(".email-modal");
                    this.m_uiModal.css("display", "");
                    this.m_uiModalBgrnd.fadeIn(300);
                    this.m_uiModal.fadeIn(300);
                    this.initInputs();
                }
            },

            cancelEmailSend: function() {
                this.m_uiModalBgrnd.fadeOut(300);
                this.m_uiModal.fadeOut(300);
            },

            /**
             * Create the input views
             */
            initInputs: function() {
                this.$inputs = this.$('.ui-text-input');

                _.each(this.$inputs, function($input, i) {
                    if (this.subviews['input' + i]) {
                        this.subviews['input' + i].destroy();
                    }
                    this.subviews['input' + i] = new InputField({
                        el: $input
                    });
                }, this);
            },

            /**
             * Create recaptcha box, script must be loaded first.
             */
            initCaptcha: function() {
                if (this.$recaptchaV2.is(":empty")) {
                    if (this.$recaptchaV2.data('key')) {
                        grecaptcha.render('recaptcha-share', {
                            'sitekey' : this.$recaptchaV2.data('key'),
                            'callback' : this.sendEmail,
                            'theme': this.options.theme,
                            'size': 'invisible',
                            'badge': 'inline'
                        });                        
                    } else {
                        this.v2_captcha_available = false;
                    }
                }
            },

            /**
             * Remove any other recaptcha instances then insert recaptcha template to current view.
             * Can only have one recaptcha instance on the window at a time
             */
            setRecaptcha: function() {
                $('.recaptcha_widget').remove();

                var $recaptchaTemplate = $('#recaptcha_widget').html();
                this.$('.js-email-form-recaptcha-widget').html($recaptchaTemplate);

                //since we have to reuse the same captcha widget -
                // style the response field correctly for the share modal form
                if (this.$el.attr('id') === 'modalEmailForm') {
                    $('#recaptcha_response_field').addClass('ui-light');
                }
            },

            /**
             * Validate email form.
             */
            isValid: function($form) {
                // TODO: Add validation for email addresses if possible.
                var valid = true;
                $form.children('input,textarea').removeClass('error').each(function(index, el) {
                    var $el = $(el);
                    if ($el.hasClass('required') && $el.val() === '') {
                        $el.addClass('error');
                        valid = false;
                    }
                });
                return valid;
            },

            /**
             * Click handler for 'send' button.
             */
            onClickSendBtn: function(e) {
                this.$emailForm = this.$('.js-share-email-form');
                this.$emailForm.trigger('submit');
            },

            /**
             * Submit handler for 'email' form.
             */
            onSubmitEmailForm: function(e) {
                e.preventDefault();
                if (this.isValid(this.$emailForm)) {
                    if (this.$recaptchaV2.length) {
                        if (this.v2_captcha_available){
                            grecaptcha.execute();
                        } else {
                            this.sendEmail("v2_captcha_unavailable");
                        }
                    } else {
                        this.sendEmail("v1");
                    }
                }
            },


            /**
             * Callback from captcha, sends actual email
             */
            sendEmail: function(captcha_response) {
                this.$emailForm.children('input,textarea').removeClass('error');
                $.ajax({
                    type: 'POST',
                    url: this.$emailForm.attr('action'),
                    data: this.$emailForm.serialize()
                }).done(_.bind(function(response) {
                        this.onSuccessfulSubmission();
                        // TODO: Add error checking when endpoint is fixed.
                        // TODO: Add stock text/link to email body.
                    }, this)).fail(_.bind(function(response) {
                        this.onFailedSubmission();
                    }, this));
            },

            /**
             *  Show success pane and hide/reset form for future use
             */
            onSuccessfulSubmission: function() {
                this.triggerEvent('clearInputValue');
                if (this.$recaptchaV2.length) {
                    if (this.v2_captcha_available){
                        grecaptcha.reset();
                    }
                } else {
                    this.reloadRecaptcha();
                }
                this.m_uiModalBgrnd.fadeOut(300);
                this.m_uiModal.fadeOut(300);
            },

            /**
             *  Show errors
             */
            onFailedSubmission: function() {
                if (this.$recaptchaV2.length) {
                    this.$emailForm.children('input,textarea').addClass('error');
                    if (this.v2_captcha_available){
                        grecaptcha.reset();
                    }
                } else {
                    this.$('#recaptcha_response_field').addClass('error');
                    window.Recaptcha.reload();
                }
            },

            onRecaptcha: function(e) {
                var recaptchaOption = $(e.currentTarget).data('option');

                if (recaptchaOption === 'audio' || recaptchaOption === 'image') {
                    this.switchRecaptchaType(recaptchaOption);
                } else if (recaptchaOption === 'help') {
                    this.showRecaptchaHelp();
                } else {
                    this.reloadRecaptcha();
                }
            },

            /**
             * Switch the recaptcha type
             * @param {String} type image or audio
             */
            switchRecaptchaType: function(type) {
                window.Recaptcha.switch_type(type);
            },

            /**
             * Show the recaptcha help popup
             */
            showRecaptchaHelp: function() {
                window.Recaptcha.showhelp();
            },

            /**
             * Reload recaptcha - retrieves a new image or audio sample
             */
            reloadRecaptcha: function() {
                window.Recaptcha.reload();
            }
        });

        /**
         * Return view class.
         */
        return InsiderEmailView;
    }
);
