/**
 * @fileoverview Sidebar Poster Ad View
 */
define('modules/partner/leaderboard-ad',[
    'jquery',
    'underscore',
    'utils',
    'modules/partner/simple-ad',
    'state'
],
function(
    $,
    _,
    Utils,
    SimpleAd,
    StateManager
)
    {
        'use strict';
        /**
         * View class.
         */
        var LeaderBoardAd = SimpleAd.extend({

            /**
             * Initialize view.
             */
            initialize: function(options) {

                var $el = this.$el,
                    pageInfo = StateManager.getActivePageInfo();

                // Don't set up if we are part of an editorial series or sponsored series
                if((pageInfo.series || pageInfo.sponsored_series || pageInfo.sponsor_story) &&
                    !Utils.getNested(window.site_vars, 'ADS', 'leaderboard_on_sponsored')) {
                    return false;
                }

                options = $.extend({
                    adType : $el.attr('data-ad-type') || 'leaderboard',
                    adPlacement: $el.attr('data-ad-placement') || 'leaderboard_btf',
                    adSizes: $el.data('ad-sizes') || 'leaderboard',
                }, options);

                SimpleAd.prototype.initialize.call(this, options);

                this._initAds();
            },
        });

        /**
         * Return view class.
         */
        return LeaderBoardAd;
    }
);

