/**
 * @fileoverview longform/longform full width photo gallery module
 * @author Adam Hostettler, ahostettle@gannet.com
 */
define('modules/longform/longform-fullsize-gallery',[
    'jquery',
    'underscore',
    'baseview'
],

function (
$,
_,
BaseView
) {
    'use strict';
    /**
     * View class.
     */
    var longformFullwidthGallery = BaseView.extend({

        // Events
        events: {
            'click .js-lfa-tabs-thumb': 'toggleThumbs',
            'click .js-lfa-tabs-caption': 'toggleCaptions'
        },

        /**
		 * Initialize view
		 */
        initialize: function(options) {
            this.$thumbTab = this.$('.js-lfa-tabs-thumb');
            this.$captionTab = this.$('.js-lfa-tabs-caption');
            this.$lfaGalleryThumbs = this.$('.js-lfa-gallery-thumbs');
            this.$lfaGalleryThumbswrap = this.$('.js-lfa-full-gallery-thumbswrap');
            this.$lfaGalleryThumblist = this.$('.js-lfa-full-gallery-thumblist');
            this.$lfaGalleryCaption = this.$('.js-lfa-full-gallery-view-meta');
            this.$thumbTabTxt = this.$('.js-lfa-gallery-ctrl-lbl-thumb');
            this.$captionTabTxt = this.$('.js-lfa-gallery-ctrl-lbl-caption');

            this.scrollCheck();
            BaseView.prototype.initialize.call(this, options);
        },

        /**
         * Checks to see if a scrollbar will be added, if not, adjusts the position
         * of the thumbs to be centered vertically
         */
        scrollCheck: function() {
            if (this.$lfaGalleryThumblist.width() <= this.$lfaGalleryThumbs.width()){
                this.$lfaGalleryThumbswrap.addClass('lfa-gallery-noscroll');
            }
        },

        /**
         * Controls the thumbs drawer - open/close
         */
        toggleThumbs: _.debounce(function() {
            var that = this;
            if (this.$thumbTab.hasClass("thumbs-active")){
                that.$('.horizontal-scroll').css("visibility", "hidden");
                that.$lfaGalleryThumbs.removeClass("thumbs-with-ad");
                that.$lfaGalleryThumbs.animate({
                    "height": "0px"
                }, 300, function(){
                    that.$thumbTab.removeClass("thumbs-active");
                    that.$thumbTabTxt.html("Show thumbnails");
                });
                that.$lfaGalleryCaption.animate({
                    "bottom": "36px"
                }, 300);
            } else {
                that.$lfaGalleryThumbs.animate({
                    "height": "75px"
                }, 300, function(){
                    that.$thumbTab.addClass("thumbs-active");
                    that.$thumbTabTxt.html("Hide thumbnails");
                    that.$('.horizontal-scroll').css("visibility", "visible");
                    that.$lfaGalleryThumbs.addClass("thumbs-with-ad");
                });
                that.$lfaGalleryCaption.animate({
                    "bottom": "111px"
                }, 300);
            }
        }, 300, true),

        /**
         * Controls the captions drawer - open/close
         */
        toggleCaptions: _.debounce(function() {
            var that = this;
            if (this.$captionTab.hasClass("caption-active")){
                this.$lfaGalleryCaption.animate({
                    "height": "toggle"
                }, 300, function(){
                    that.$captionTab.removeClass("caption-active");
                    that.$captionTabTxt.html("Show captions");
                });
            } else {
                this.$lfaGalleryCaption.animate({
                    "height": "toggle"
                }, 300, function(){
                    that.$captionTab.addClass("caption-active");
                    that.$captionTabTxt.html("Hide captions");
                });
            }
        }, 300, true),

    });

    return longformFullwidthGallery;
});

