/**
* @fileoverview JS functions for sports scores.
* @author webninjataylor@gmail.com (Taylor Johnson)
* @author stephen.burgess@f-i.com (Stephen Burgess)
*/
define('modules/sports/sports-scores-suspender',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'state',
    'baseview',
    'modules/scroller/sidebar-scroll',
    'chosen',
    'animatecolors'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    StateManager,
    BaseView,
    SidebarScroll
)
    {
        /**
         * View class.
         */
        var ScoresView = BaseView.extend({

            // Events.
            events: {
                'mouseover .top-wrapper, .bottom-wrapper, .magicscroll, .game': 'stopRotation',
                'touchstart .top-wrapper, .bottom-wrapper, .magicscroll, .game': 'stopRotation',
                'change': 'onChangeLeague',
                'click .game': 'gameClicked'
            },
            
            /**
             * Constructor method.
             */
            initialize: function(options) {
                $.extend(options, {
                    rotateInterval: 5000,
                    refreshInterval: 30000,
                    leagueFailLimit: 3
                });

                // Call base class initialize
                BaseView.prototype.initialize.call(this, options);

                _.bindAll(this, 'onScoresLoaded', 'rotateToNextScore', 'onUpdateLoaded', 'scoresSuspenderRefresher');
                this.$scroller = this.$('.suspender-scroll-content');
                this.$pairings = this.$('#pairings');
                this.$noScores = this.$pairings.find('.league.none');
                this.padding = parseInt(this.$pairings.css('paddingTop'), 10);
                this.canSlideUp = true;
                this.yp = 0;
                this.leagues = {};
                this.scoreType = 'schedule';

                //set initial variables to true to kick off rotation and refresh if no user interaction stops it
                this.continueRotate = true;
                this.continueRefresh = true;

                //set initial variable false until the refresh routine is started. Prevents multiple refresh "threads" starting.
                this.refreshStarted = false;

                //Set initial variable to stop loading if a league's data can not be retrieved
                this.failLeague = null;
                this.leagueFailCount = 1;

                //get wrapper height and minus top/bottom wrappers to give viewport height
                var h = this.$('.magicscroll').outerHeight();
                var bh = this.$('.bottom-wrapper').outerHeight() + 23; //23 is the height of the ellipse w/ arrow
                var th = this.$('.top-wrapper').outerHeight();
                this.viewport = h - (bh + th);
                this.chosenSelector = this.$('.filter');
                this.chosenSelector.chosen({disable_search_threshold: 30});

                //Set the start index for the suspender rotation routine
                this.startIndex = this.$('.chzn-results .result-selected').index();
                this.oldHbox = this.$('.hoverGameFunction'); 
                this.onChangeLeague();
                this.addscrollBar();
            },

            gameClicked: function(e){
                this.oldHbox.css('display','none');
                var hoverBox = e.target || e.srcElement; 
                while (hoverBox && !hoverBox.id) {
                    hoverBox = hoverBox.parentNode;
                }
                var gameBox = $(hoverBox).next();
                gameBox.css('display','block');
                this.oldHbox = gameBox;
            },

            /**
             * This function initiates the scores suspender refresh by fetching data
             * and letting the callback function determine whether to continue calling
             * the refresh routine
            */
            scoresSuspenderRefresher: function() {
                this.refreshStarted = true;
                var url;
                //fetch selected league data
                url = '/sports/services/scores-suspender/' + this.league + '/';

                if (this.$scroller.data('max-count') !== undefined) {
                    url += '?count=' + this.$scroller.data('max-count');
                }
                this.fetchData(url, this.onUpdateLoaded, true);
            },

            /**
            * The function stops the refresh routine
            */
            stopRefresh: function() {
                this.continueRefresh = false;
                if (this.refreshID) {
                    clearTimeout(this.refreshID);
                }
            },

            /**
             * The function stops the rotatation routine
             */
            stopRotation: function() {
                this.continueRotate = false;
                if (this.rotateID) {
                    this.rotateID = clearInterval(this.rotateID);
                }

                // If the rotation has been stopped and the refresh routine has not been stopped for some reason
                // and it has not already been intiated then the refresh routine will be started here
                if (this.continueRefresh && !(this.refreshStarted)) {
                    this.refreshStarted = true;
                    this.refreshID = setTimeout(this.scoresSuspenderRefresher, this.options.refreshInterval);
                }
            },

            /**
             * The function rotates the suspender to the next league unless all leagues have been shown.
             */
            rotateToNextScore: function() {
                var nextLeague = this.$('.chzn-results .result-selected').next();

                if (nextLeague.length === 0){
                    nextLeague = this.$('.chzn-results').children(':first');
                }

                if (nextLeague.index() === this.startIndex){
                    // initiates the stop rotation funtion so that the suspender refresh routine will run only when suspender
                    // rotation has stopped
                    this.stopRotation();
                }

                nextLeague.mouseup(); // needed to trigger chosen, should be click event if chosen is ever taken away
            },

            /**
             * Retrieves the scores for a specified league via an ajax request.
             * @param {String} url to load data for.
             * @param {Function} callback the callback method.
             * @param {Boolean} isUpdate  whether this data fetch is for updating scores reloading page
             */
            fetchData: function(url, callback, isUpdate) {
                if (isUpdate) {
                    StateManager.fetchHtml(url)
                        .done(callback)
                        .fail(_.bind(function(){
                            console.log("ERROR: Scores Suspender AJAX load failed!");
                            if (this.failLeague == this.league){
                                this.leagueFailCount += 1;
                            }
                            this.failLeague = this.league;
                            callback();
                        }, this));
                } else {
                    this.$el.addClass('loading');
                    StateManager.fetchHtml(url)
                        .done(callback)
                        .fail(_.bind(function(){
                            this.$noScores.show();
                            callback();
                        }, this))
                        .always(_.bind(function(){
                            this.$el.removeClass('loading');
                        }, this));
                }

            },

            /**
             * Handler for when a new league/scores are selected via dropdown.
             * @param {Event} event Change event.
             */
            onChangeLeague: function(event) {
                this.refreshScrollBar();
                //hides all leagues
                this.$('.league').hide();
                this.league = this.$('.filter').val();
                if (this.league === undefined) {
                    this.league = this.$el.closest('.card').data('league-id');
                }
                if (this.league === undefined) {
                    this.league = this.$el.closest('.card').data('subsection-id');
                }
                if (this.league === undefined) {
                    this.$noScores.show();
                    return;
                }

                if (this.leagues[this.league]) {
                    this.updateDisplay(this.leagues[this.league]);
                } else {
                    var url;
                    if (this.$scroller.data('sub-section-name') === 'SMG') {
                        url = '/sports/services/scores-suspender/desktop/' + this.league + '/';
                    }
                    else {
                        url = '/sports/services/scores-suspender/' + this.league + '/';
                    }

                    if (this.$scroller.data('max-count') !== undefined) {
                        url += '?count=' + this.$scroller.data('max-count');
                    }
                    this.fetchData(url, this.onScoresLoaded);
                }
            },

            /**
             * Handler for when score data is loaded for refresh.
             * @param {String} markup html markup retrived from ajax request.
             */
            onUpdateLoaded: function(markup) {
                if (!markup) {
                    return;
                }
                var $gamesContainer = this.$("#pairings ."+this.league);
                var $newGames = $(markup);

                $gamesContainer.find('.game').each(_.bind(function(i, game){
                    var $game = $(game);
                    var eventKey = $game.attr('id');
                    var $newGame = $newGames.find("#" + eventKey);
                    var $newGameScores = $newGame.find('.suspender_score');
                    var $oldGameScores = $game.find('.suspender_score');

                    // For each set of scores, check to see if there has been a change and add the highlight
                    // css class to the score container that has changed
                    this.markChangedScores($newGameScores, $oldGameScores);
                }, this));

                // Replace the old markup with the new
                $gamesContainer.html(markup);
                this.addScoreHighlight($gamesContainer.find(".scoreChangeFont"), "rgb(164, 23, 18)", 1500);

                // If refresh has not been stopped, block will wait the specified time interval and then intiate
                // the next refresh routine. It will also stop the refreshing if more than the specified request 
                // failures for the current league have occurred.
                if ((this.continueRefresh) && (this.leagueFailCount < this.options.leagueFailLimit)){
                    this.refreshID = setTimeout(this.scoresSuspenderRefresher, this.options.refreshInterval);
                }
            },

            /**
             * For an individal game, check home and away scores to see if there has been a change.
             * Adds a class if the score has changed.
             * @param {Object} $newScores jQuery object list
             * @param {Object} $oldScores jQuery object list
             */
            markChangedScores: function($newScores, $oldScores) {
                $newScores.each(_.bind(function(index, item){
                    var $item = $(item);
                    if ($item.html() !== $oldScores.eq(index).html()) {
                        $item.addClass("scoreChangeFont");
                    }
                }, this));
            },

            /**
             * Handler for when score data is loaded.
             * @param {String} markup html markup retrived from ajax request.
             */
            onScoresLoaded: function(markup) {
                var $league = this.leagues[this.league] = $(markup);
                if (!$league.find('.gameBox').length) {
                    console.log("FAILED: Scores Suspender AJAX retrieved BAD data!");
                    this.$noScores.show();
                } else {
                    this.$pairings.append($league);
                    this.scoreType = 'scores';
                    this.updateDisplay($league);

                    //Rotate scores suspender if still in rotation period and on the sports front page
                    if ((this.continueRotate) && (location.href.search("sports/$") > 0)) {
                        this.rotateID = setTimeout(this.rotateToNextScore, this.options.rotateInterval);
                    } else {
                        //If scores have been fetched and rotation is not going to run start refresh for subfront page
                        this.refreshID = setTimeout(this.scoresSuspenderRefresher, this.options.refreshInterval);
                    }
                }
            },

            /**
             * Display the currently selected league.
             * @param {Object} $league The league to be displayed.
             */
            updateDisplay: function($league) {
                this.$games = $league.find('.game');

                //reset index
                this.index = 0;
                this.total = this.$games.length;

                if (this.total) {
                    $league.show();
                } else {
                    this.$noScores.show();
                }
                // Update the 'All Scores' link.
                var $scoresLink = this.$('.all-scores-link');
                $scoresLink [0].innerHTML = $league.data('text');
                $scoresLink.attr('href', $league.data('link'));
                $scoresLink.attr('target', $league.data('target'));

                this.refreshScrollBar(); 
            },

            /**
             * Displays the currently selected league / scores.
             * @param {Event} event Change event.
             */
            showLeague: function(event) {
                //hides all leagues
                this.$('.league').hide();

                var league = this.$('.filter').val();
                var $league = this.$('.'+league);
                $league.show();
                this.$games = $league.find('.game');

                //reset index
                this.index = 0;
                this.total = this.$games.length;
            },
            
            /**
             * This function addes a highlight animation to JQuery objects that are passed to it
             * This animation runs through two cycles and last the duration specified by the passed parameter 
             *
             * @param {jQuery} targetEL elements to add the hightlight to
             * @param {String} highlightColor color to highlight the element with
             * @param {Number} duration time in milisecond that the hightlight animation should run
             */
            addScoreHighlight: function(targetEL, highlightColor, duration) {
                var highlight = highlightColor || "#FFFF9C";
                var animateMs = duration || 1000;
                var originalColor = targetEL.css("color");
                targetEL.stop()
                    .css("color", highlight)
                    .animate({color: originalColor}, animateMs)
                    .animate({color: highlight}, animateMs)
                    .animate({color: originalColor}, animateMs);
            },
         
            addscrollBar: function(){
                this.scrollbar = new SidebarScroll({  
                    el: this.$('.suspender-scroll-content'),
                    color: 'dark',
                    padding: 2,
                    lockPageScroll: false,
                    delayScroll: false,
                    fadeout: true
                });
           },

            refreshScrollBar: function() {
                if (this.scrollbar){
                    this.scrollbar.refresh();
                    this.scrollbar.moveDraggerToPosition(0, 0);   
                }
            },

            destroy: function() {
                this.scrollbar.destroy();
                // Stops refresh routine when the page is unloaded
                this.stopRefresh();
                // Stops the rotation of the Scores Suspender when the page is unloaded
                this.stopRotation();
                // Call base call destroy
                BaseView.prototype.destroy.call(this);
            }
        });
        return ScoresView;
    }
);
