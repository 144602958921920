/**
 * @fileoverview Monster
 * @author zaudette@gannett.com (Zac Audette)
 */
define('apps/monster',[
    'jquery',
    'underscore',
    'base-app',
    'utils',
    'state',
    'pubsub'
],
function(
    $,
    _,
    BaseApp,
    Utils,
    StateManager,
    PubSub
    ) {

    var Monster = BaseApp.extend({
        afterPageReveal: function() {
            // stop refresh timer
            StateManager.stopRefreshTimer();
        },

        beforeAppRemove: function() {
            StateManager.startRefreshTimer();
        }
    });

    return Monster;
}
);

