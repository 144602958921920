define('partner/gravity-ad-article',[
    'jquery',
    'underscore',
    'utils',
    'managers/autoplay',
    'partner/gravity-ad'
],
function(
    $,
    _,
    Utils,
    AutoPlayManager,
    GravityBase
) {
    'use strict';
    var GravityArticleView = GravityBase.extend(
    /**
     * @lends partner/pushdown-ad.prototype
     */
    {
        articleElsHidden: false,

        /**
         * @classdesc Gravity ad type for an article overlay page, this is a {@link partner/ad-position}.
         *          The el for this view needs to contain both .partner-gravity-ad.partner-placement as well as the styles included in partner.css
         * @constructs partner/gravity-ad
         * @author Jordan Manwaring <jmanwaring@gannett.com>
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector that contains both the gravity and leave behind divs
         */
        initialize: function(options) {
            var contentSection = this.options.contentSection;
            this.$recommended = contentSection.find('.recommended-flyout-container');
            this.$utilityBarPrimaryModules = contentSection.find('.util-bar-primary-modules');
            this.$utilityBarSecondaryModules = contentSection.find('.util-bar-secondary-modules');
            this.$closeWrap = contentSection.find('.close-wrap');
            this.autoplayPromise = $.Deferred();
            // call base class initialize
            GravityBase.prototype.initialize.call(this, options);
        },

        beforeAdRender: function(adData) {
            this.adData = adData;

            // only register module when we know for certain that a gravity ad has been displayed
            AutoPlayManager.register('high-impact-ad', _.bind(function() { return this.autoplayPromise; }, this));
        },

        onAdReady: function(adData) {
            this.showAd();
            this.options.slot.css({minHeight: null}).removeClass('partner-has-min-height');
            Utils.get('scrollEl').animate({'scrollTop': 0}, 500);
        },

        onArticleScroll: function(opacity, headerHeight) {
            var utilitySecondaryHeight = this.$utilityBarSecondaryModules.height();

            if (opacity < 0.1 && this.articleElsHidden) {
                this.$utilityBarSecondaryModules.stop('gravity').animate({bottom: 0}, { duration: 200, queue: 'gravity' }).dequeue('gravity');
                this.$recommended.show();
                this.$closeWrap.show();
                this.$body.removeClass('high-impact-ad-visible');
                this.articleElsHidden = false;
                this.autoplayPromise.resolve();
                this.controlVideoPlayer('uw-play');
            } else if (opacity > 0.1 && !this.articleElsHidden) {
                this.$recommended.hide();
                this.$closeWrap.hide();
                this.$utilityBarSecondaryModules.stop('gravity').animate({bottom: -1 * utilitySecondaryHeight}, { duration: 200, queue: 'gravity'}).dequeue('gravity');
                this.$body.addClass('high-impact-ad-visible');
                this.articleElsHidden = true;
                this.controlVideoPlayer('uw-pause');
            }

            //article specific stuff
            if (opacity <= 0) {
                this.$utilityBarPrimaryModules.css({position: 'fixed', top: 80 + headerHeight});
                this.$closeWrap.css({position: 'fixed', top: 80 + headerHeight});
            } else if (opacity > 0) {
                this.$utilityBarPrimaryModules.css({position: 'absolute', top: 80});
                this.$closeWrap.css({position: 'absolute', top: 80});
            }
        },

        controlVideoPlayer: function(state){
            $('.snow-iframe').each(function (i, el) {
                el.contentWindow.postMessage(state , '*');
            });
        },

        shiftContentUp: function() {
            GravityBase.prototype.shiftContentUp.call(this);
            this.$utilityBarPrimaryModules.css({position: 'absolute', top: 80});
            this.$partnerScroll.animate({'margin-top': -145}, 350);
        },

        showAd: function() {
            GravityBase.prototype.showAd.call(this);
            this.shiftContentUp();
        }
    });
    return GravityArticleView;

});

