/* global define, console, require */
define('state',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'abtest-manager',
    'modules/global/alert',
    'managers/trafficcop',
    'managers/requestmanager',
    'managers/resourcemanager',
    'managers/siteconfig',
    'intersection-observer'
],
function(
    $,
    _,
    PubSub,
    Utils,
    AbTestManager,
    Alert,
    TrafficCop,
    RequestManager,
    ResourceManager,
    SiteConfig,
    IntersectionObserve
) {
    'use strict';
    if (!Object.getPrototypeOf) {
        // polyfill for IE8 (fullscreen transitions need it)
        Object.getPrototypeOf = function(object){
            // May break if the constructor has been tampered with
            return object.constructor.prototype;
        };
    }
    /**
     * @event page:unload
     * @desc This event is fired when a page is torn down before all animation and ajax requests are fired
     */
    /**
     * State Manager that handles the application state. Maintains the active view and
     * manages routing and transitioning between the views. Ajax calls and animation calls
     * should be registered with the state manager to guarantee that animation isn't interrupted
     * and that stale or requests that are being thrown out.
     * @requires managers/trafficcop
     * @requires managers/requestmanager
     * @requires managers/siteconfig
     * @exports state
     * @author Jay Merrifield <jmerrifiel@gannett.com>
     */
    var StateManager = function(){
        this.scrollEl = Utils.get('scrollEl');
        this.body = Utils.get('body');
        this.$win = Utils.get('win');
        this.initialize();
    };
    StateManager.prototype = {
            /** @const
             * @private */
            DEBUG: true,
            /** @const
             * @private */
            LAYER_OVERLAY: 'overlay',
            /** @const
             * @private */
            LAYER_PRELOAD: 'preload',
            /** @const
             * @private */
            LAYER_BASE: 'base',
            /** @const
             * @private */
            REFRESH_FREQUENCY: (Utils.getNested(window.site_vars, 'REFRESH_RATE') || 0) * 60 * 1000,

            /**
             * This starts state manager, spin up the route manager, resource manager, and refresh timer
             */
            start: function(header) {
                var siteConfig = SiteConfig.getSiteConfig(); // stash this so we can debug it's value
                this.started = true;
                this.header = header;
                // pubsubs that indicate user activity has happened
                this.pubSub = {
                    'page:unload': this.updateActivityTimestamp,
                    'page:load': this.onPageLoad,
                    'video:load': this.updateActivityTimestamp,
                    'slide:change': this.updateActivityTimestamp,
                    'heattrack': this.updateActivityTimestamp,
                    'vitrack': this.updateActivityTimestamp,
                    'uotrack': this.updateActivityTimestamp,
                    'dom:update': this.onDomUpdate
                };
                PubSub.attach(this.pubSub, this);
                this._setupGlobalPubSub(Utils.getNested(siteConfig, 'global', 'pubSub'));

                // If click is not registered for 15 min, refresh the page --
                // for non-overlays.
                this.startRefreshTimer();
            },

            initIntersectionObservers: function() {
                var activeAppEl = $(this.getActiveApp().el);
                var $lazyLoadContainers = activeAppEl.find('.js-llc');
                if ($lazyLoadContainers.length) {
                    if (!this.observer) {
                        var config = {
                            rootMargin: '300px 50px'
                        };
                        this.observer = new IntersectionObserve(_.bind(this.onIntersection, this), config);
                    }
                    $lazyLoadContainers.each(_.bind(function(i, el) {
                        this.observer.observe(el);
                    }, this));
                }
            },

            clearIntersectionObservers: function() {
                if (this.observer) {
                    $('.js-llc,.js-lli,.js-llif').each(_.bind(function(i, el) {
                        this.observer.unobserve(el);
                        if (el.gannett) {
                            el.gannett = {};
                        }
                    }, this));
                }
            },

            resetIntersectionObservers: function() {
                this.clearIntersectionObservers();
                this.initIntersectionObservers();
            },

            onIntersection: function(entries) {
                _.each(entries, function(entry) {
                    var $target = $(entry.target),
                        elName = $target.attr('data-name') || 'el',
                        eData = entry.target.gannett = entry.target.gannett || {};
                    if (entry.isIntersecting) { // element is entering intersection
                        if (!$target.hasClass('js-llp')) { // lazy-load persist (keep observing after intersection)
                            this.observer.unobserve(entry.target);
                        }
                        if ($target.hasClass('js-lli')) { // lli == "lazy load image"
                            $target.removeClass('js-lli');
                            Utils.lazyLoadImage($target);
                        } else if ($target.hasClass('js-llif')) { // llif == "lazy load iframe"
                            $target.removeClass('js-llif');
                            Utils.lazyLoadIframe($target);
                        } else {
                            // '.js-llc' == "lazy load container"; generic container elements being observed as they
                            // enter viewport.
                            eData.isIntersecting = true;
                            eData.hasIntersected = true;
                            if (eData.intersectionCount) {
                                eData.intersectionCount++;
                            } else {
                                eData.intersectionCount = 1;
                            }

                            // find images in container to observe and lazy load as each one enters viewport.
                            $target.find('.js-lli').each(_.bind(function(i, el) {
                                this.observer.observe(el);
                            }, this));
                            
                            // find iFrames in container to observe and lazy load as each one enters viewport.
                            $target.find('.js-llif').each(_.bind(function(i, el) {
                                this.observer.observe(el);
                            }, this));

                            // llbi == "lazy load batch images"; batch load images with this class once its container
                            // has entered viewport
                            var $lazyLoadBatchImages = $target.find('.js-llbi');
                            Utils.lazyLoadImage($lazyLoadBatchImages);
                            $lazyLoadBatchImages.removeClass('js-llbi');

                            // modules, etc can subscribe to intersection events and respond as needed.
                            PubSub.trigger('entered:view:' + elName, entry.target);
                        }
                    } else { // element is exiting intersection
                        if (eData.hasIntersected) {
                            eData.isIntersecting = false;
                            PubSub.trigger('exited:view:' + elName, entry.target);
                        }
                    }
                }, this);
            },

            _checkForIntersections: function() {
                if (this.observer && this.observer._checkForIntersections) {
                    this.observer._checkForIntersections();
                }
            },

            addGlobalScrollListener: function() {
                var _onScrollThrottled = _.throttle(this._onScroll, 100).bind(this);
                this.$win.on('scroll.' + this.cid, _onScrollThrottled);
            },

            removeGlobalScrollListener: function() {
                this.$win.off('scroll.' + this.cid);
            },

            removeScrollListeners: function() {
                this.removeGlobalScrollListener();
                this.globalScrollListeners = [];
            },

            _onScroll: function(e) {
                _.each(this.globalScrollListeners, function(listener) {
                    try {
                        listener.fn();
                    } catch (err) {
                        console.error('Failed to execute global scroll handler ' + listener.name);
                    }
                });
            },

            registerScrollListener: function(name, fn, freq, leading) {
                if (_.isFunction(fn)) {
                    freq = freq || 500;
                    var listener = _.throttle(fn, freq);
                    if (this.globalScrollListeners.length === 0) {
                        this.addGlobalScrollListener();
                    }
                    this.globalScrollListeners.push({
                        fn: listener,
                        name: name
                    });

                    if(leading) {
                        fn();
                    }
                }
            },

            unRegisterScrollListener: function(name) {
                _.each(this.globalScrollListeners, function(listener, i) {
                    if (listener.name === name) {
                        this.globalScrollListeners.splice(i, 1);
                    }
                }, this);
                if (this.globalScrollListeners.length === 0) {
                    this.removeGlobalScrollListener();
                }
            },

            addGlobalResizeListener: function() {
                var _onResizeThrottled = _.throttle(this._onResize, 100).bind(this);
                this.$win.on('resize.' + this.cid, _onResizeThrottled);
            },

            removeGlobalResizeListener: function() {
                this.$win.off('resize.' + this.cid);
            },

            removeResizeListeners: function() {
                this.removeGlobalResizeListener();
                this.globalResizeListeners = [];
            },

            _onResize: function(e) {
                _.each(this.globalResizeListeners, function(listener) {
                    try {
                        listener.fn();
                    } catch (err) {
                        console.error('Failed to execute global resize handler ' + listener.name);
                    }
                });
            },

            registerResizeListener: function(name, fn, freq, leading) {
                if (_.isFunction(fn)) {
                    freq = freq || 500;
                    var listener = _.throttle(fn, freq);
                    if (this.globalResizeListeners.length === 0) {
                        this.addGlobalResizeListener();
                    }
                    this.globalResizeListeners.push({
                        fn: listener,
                        name: name
                    });

                    if(leading) {
                        fn();
                    }
                }
            },

            unRegisterResizeListener: function(name) {
                _.each(this.globalResizeListeners, function(listener, i) {
                    if (listener.name === name) {
                        this.globalResizeListeners.splice(i, 1);
                    }
                }, this);
                if (this.globalResizeListeners.length === 0) {
                    this.removeGlobalResizeListener();
                }
            },


            _addGlobalVisibilityListener: function() {
                if (typeof document.hidden !== 'undefined') { 
                    this.hiddenAttr = 'hidden';
                    this.visibilityEvent = 'visibilitychange';
                } else if (typeof document.msHidden !== 'undefined') {
                    this.hiddenAttr = 'msHidden';
                    this.visibilityEvent = 'msvisibilitychange';
                } else if (typeof document.webkitHidden !== 'undefined') {
                    this.hiddenAttr = 'webkitHidden';
                    this.visibilityEvent = 'webkitvisibilitychange';
                }
                document.addEventListener(this.visibilityEvent, this._onVisibilityChange, false);
            },

            _removeVisibilityListener: function() {
                document.removeEventListener(this.visibilityEvent, this._onVisibilityChange);
            },

            registerVisibilityListener: function(name, fn, leading) {
                if (_.isFunction(fn)) {
                    if (this.globalVisibilityListeners.length === 0) {
                        this._addGlobalVisibilityListener();
                    }
                    if(_.find(this.globalVisibilityListeners, function(l) { return l.name === name; })) {
                        console.info('Duplicate name. Visibility Listener not registered', name);
                        return;
                    }
                    this.globalVisibilityListeners.push({
                        fn: fn,
                        name: name
                    });

                    if(leading) {
                        fn(!document[this.hiddenAttr]);
                    }
                }
            },

            unRegisterVisibilityListener: function(name) {
                _.each(this.globalVisibilityListeners, function(listener, i) {
                    if (listener.name === name) {
                        this.globalVisibilityListeners.splice(i, 1);
                    }
                }, this);
                if (this.globalVisibilityListeners.length === 0) {
                    this._removeVisibilityListener();
                }
            },

            _onVisibilityChange: function() {
                var visible = !document[this.hiddenAttr];

                _.each(this.globalVisibilityListeners, function(listener) {
                    try {
                        listener.fn(visible);
                    } catch (err) {
                        console.error('Failed to execute global visible handler ' + listener.name);
                    }
                });
            },

            stop: function(){
                this.started = false;
                this._currentViewport = "width=1070";
                PubSub.unattach(this.pubSub, this);
                _.each(Utils.getNested(this.siteConfig, 'global', 'pubSub'), function(paths, key) {
                    PubSub.off(key);
                });
                this.removeScrollListeners();
                AbTestManager.stop();
            },

            _setupGlobalPubSub: function(pubSubMap) {
                _.each(pubSubMap, function(paths, key) {
                    if (!(paths instanceof Array)){
                        paths = [paths];
                    }
                    require(paths, _.bind(function() {
                        var mods = arguments;
                        if (this.started){
                            PubSub.on(key, function(options) {
                                _.each(mods, function(Mod) {
                                    new Mod(options);
                                });
                            });
                        }
                    }, this));
                }, this);
            },
            /**
             * Internal initialization helper, builds activeAppInfo and preloadedAppInfo objects
             */
            initialize: function() {
                this.$overlayFilm = $('#overlay-film');
                this.activeAppInfo = {
                    url: null,
                    css: [],
                    layer: null,
                    scrollTop: 0,
                    appConfig: null,
                    pageConfig: null,
                    app: null
                };
                this.lastUrl = null;
                this.getActivePageInfo();
                this._clearPreloadedAppInfo();
                this.fullscreenView = null;
                this.globalScrollListeners = [];
                this.globalResizeListeners = [];
                this.globalVisibilityListeners = [];
                AbTestManager.start(this.getActivePageInfo());

                _.bindAll(this, '_onVisibilityChange');
            },
            _clearPreloadedAppInfo: function() {
                this.preloadedAppInfo = {
                    url: null,
                    css: [],
                    scrollTop: 0,
                    appConfig: null,
                    pageConfig: null,
                    layer: this.LAYER_PRELOAD,
                    app: null
                };
            },

            /**
             * Route Change callback for route manager, will make certain the correct app, route, and path
             * are loaded properly into state manager
             * @param {RouteInfo} routeInfo - object representing the app and page that owns the path, including what class needs to be initialized
             * @param {String} url that is being loaded
             * @returns {Deferred} promise that resolves when the route is successfully navigated to
             */
            onRouteChange: function(routeInfo, url) {
                console.log('App: ' + routeInfo.page.appName + '/' + routeInfo.page.name);
                if (this.activeAppInfo.app) {
                    PubSub.trigger('page:unload');
                }
                return this._loadRoute(routeInfo, url);
            },
            /**
             * Helper method for loading a route object
             * @param {RouteInfo} routeInfo - object representing the app and page that owns the path, including what class needs to be initialized
             * @param {String} toUrl that is being loaded
             * @param {Deferred} [requestPromise] optional promise with a request object you'd like to use when loading the app
             * @returns {Deferred} promise that resolves when the route is successfully navigated to
             * @private
             */
            _loadRoute: function(routeInfo, toUrl, requestPromise) {
                var appInitOptions = {
                    preloadedUrl: routeInfo.page.preloadedUrl || routeInfo.app.preloadedUrl
                };
                return this._loadApp(routeInfo.app.AppClass, appInitOptions, toUrl, routeInfo.app, routeInfo.page, requestPromise);
            },

            /**
             * Helper method for preloading a route object
             * @param {RouteInfo} routeInfo - object representing the app and page that owns the path, including what class needs to be initialized
             * @param {String} toUrl that is being loaded
             * @param {Deferred} [requestPromise] optional promise with a request object you'd like to use when loading the app
             * @returns {Deferred} promise that resolves when the route is successfully navigated to
             * @private
             */
            _preloadRoute: function(routeInfo, toUrl, requestPromise) {
                return this._preloadApp(routeInfo.app.AppClass, {}, toUrl, routeInfo.app, routeInfo.page, requestPromise);
            },

            /**
             * Will load the given path into a preloaded/stashed state that can be quickly navigated to when asked
             * can only be used if the active app is an overlay
             * (but if we do not own that path, just ignore it until we close out of the current content)
             * @param {String} toUrl to preload
             * @return {Deferred} promise object that resolves when the path is fully loaded
             */
            preloadPath: function(toUrl) {
                if (this.DEBUG) {
                    console.log('Router: Preloading: ', toUrl);
                }
                var routeInfo = SiteConfig.getRouteInfo(toUrl);
                if (routeInfo && !window.chromeless && (!this.activeAppInfo.layer || this.activeAppInfo.layer === this.LAYER_OVERLAY)) {
                    return this._preloadRoute(routeInfo, toUrl);
                } else {
                    /* fake preload: do not preload anything, but tell .navigateToPreloadedUrl() where to go */
                    return $.Deferred().reject();
                }
            },

            /**
             * Given a path, determine it's layer, resources, and how to transition to it correctly
             * @param {String} url to navigate to
             * @return {Deferred} promise object
             * @private
             */
            _loadPath: function(url) {
                var routeInfo = SiteConfig.getRouteInfo(url);
                if (!routeInfo) {
                    console.error('StateManager: tried navigating to path, but no match found: ' + url);
                    return $.Deferred().reject();
                } else {
                    return this.onRouteChange(routeInfo, url);
                }
            },

            /**
             * Triggers an ajax reload of the current page
             * @returns {Deferred}
             */
            refreshActiveApp: function() {
                var currentPath = this.activeAppInfo.url,
                    pageInfo = SiteConfig.getRouteInfo(currentPath).page,
                    resourcePromise = ResourceManager.fetchJavascript(pageInfo.path, pageInfo.modules),
                    requestPromise = this._fetchPathHtml(currentPath);

                PubSub.trigger('page:unload');
                return this._changePage(this.activeAppInfo, currentPath, currentPath, requestPromise, resourcePromise);
            },

            /**
             * This is used when we want to pretend like we navigated to the url, but don't make the request yet.
             * This is to facility inbetween ads that want to interrupt and redirect a page navigation to an ad instead.
             * The goal is that if the user hits reload, they get the page they were intending to go to. If they hit the
             * back button they go back to the url they were at.
             * This function will destroy all active modules because we're assuming you're navigating away
             * @param {String} url
             */
            setIntentUrl: function(url) {
                this.activeAppInfo.intentUrl = url;
                if (this.activeAppInfo.app) {
                    this.activeAppInfo.app.destroyModules();
                }
            },
            /**
             * Returns the intent url if one is registered
             * @returns {String}
             */
            getIntentUrl: function(){
                return this.activeAppInfo.intentUrl;
            },
            /**
             * Gets the url of the preloaded app's current page
             * @returns {String}
             */
            getPreloadedUrl: function(){
                var previousFront = Utils.getSessionData('previousFront', false);
                return (previousFront && previousFront.path) || this.preloadedAppInfo.url;
            },

            /**
             * Registers a full screen view with the state manager. This is necessary because
             * the full screen view doesn't have a unique url and lives outside the knowledge
             * of the state manager
             * @param {Object} fullscreenView
             */
            registerFullScreenView: function(fullscreenView) {
                this._setFixedPosition(this.activeAppInfo, this.activeAppInfo.app, true);
                this.fullscreenView = fullscreenView;
                // pause the app, and all it's modules
                this.activeAppInfo.app.pause();
            },

            setActiveAppFixed: function(partialCover) {
                this._setFixedPosition(this.activeAppInfo, this.activeAppInfo.app, partialCover);
            },

            /**
             * Clears out the full screen view from the state manager
             */
            clearFullScreenView: function() {
                this._clearFixedPosition(this.activeAppInfo, this.activeAppInfo.app, true);
                // fullscreenView will be null if state manager triggered the close, which means
                // the user triggered a transition somewhere else, so don't rebuild the current view
                if (this.fullscreenView) {
                    this.fullscreenView = null;
                    // trigger a transition in place, with no requestPromise to fire beforePageReveal and afterPageReveal
                    // and restore all javascript
                    return this._loadPath(this.activeAppInfo.url);
                }
            },

            clearActiveAppFixed: function(partialCover) {
                this._clearFixedPosition(this.activeAppInfo, this.activeAppInfo.app, partialCover);
            },


            /**
             * This is the main state manager call, this will compare what the current state
             * of the universe is, and make any ajax calls, and transition to the current state
             * with the correct view information
             *
             * @param {Function} NextAppClass the javascript class that is taking over the site.
             * @param {Object} initOptions any initialization options for the view class.
             * @param {String} toUrl path being loaded.
             * @param {Object} appConfig app site config object for this path
             * @param {Object} pageConfig page site config object for the path
             * @param {Deferred} [requestPromise] - optional promise that provides html for the transition we're going to have
             * @private
             */
            _loadApp: function(NextAppClass, initOptions, toUrl, appConfig, pageConfig, requestPromise) {
                var finishPromise, type = (appConfig.overlay ? this.LAYER_OVERLAY : this.LAYER_BASE),
                    resourcePromise = ResourceManager.fetchJavascript(pageConfig.path, pageConfig.modules);

                this._closeFullscreenView();

                // Save the active url before we transition which could tamper with the url
                this.lastUrl = this.activeAppInfo.url;
                if (this.activeAppInfo.app) {
                    if (this.activeAppInfo.intentUrl && this.activeAppInfo.intentUrl !== toUrl) {
                        // pretend as if we're coming from the intentUrl instead of the actual current url
                        this.activeAppInfo.url = this.activeAppInfo.intentUrl;
                    }
                    this.activeAppInfo.intentUrl = null;
                }
                finishPromise = this._handleTransition(toUrl, this.activeAppInfo, NextAppClass, initOptions, type, requestPromise, resourcePromise);
                this.activeAppInfo.css = pageConfig.css || [];
                this.activeAppInfo.url = toUrl;
                this.activeAppInfo.layer = type;
                this.activeAppInfo.pageConfig = pageConfig;
                this.activeAppInfo.appConfig = appConfig;
                ResourceManager.fetchStyles(_.union(this.preloadedAppInfo.css, this.activeAppInfo.css), finishPromise);
                return finishPromise;
            },
            /**
             * preloads an app
             * @param {Function} NextAppClass the javascript class that is taking over the site.
             * @param {Object} initOptions any initialization options for the view class.
             * @param {String} toUrl path being loaded.
             * @param {Object} appConfig app site config object for this path
             * @param {Object} pageConfig page site config object for the path
             * @param {Deferred} [requestPromise] optional promise that provides html for the transiton we're going to have
             * @returns {Deferred}
             * @private
             */
            _preloadApp: function(NextAppClass, initOptions, toUrl, appConfig, pageConfig, requestPromise) {
                var finishPromise = this._handleTransition(toUrl, this.preloadedAppInfo, NextAppClass, initOptions, this.LAYER_PRELOAD, requestPromise);
                this.preloadedAppInfo.css = pageConfig.css || [];
                this.preloadedAppInfo.url = toUrl;
                this.preloadedAppInfo.pageConfig = pageConfig;
                this.preloadedAppInfo.appConfig = appConfig;
                ResourceManager.fetchStyles(_.union(this.preloadedAppInfo.css, this.activeAppInfo.css), finishPromise);
                return finishPromise;
            },
            _closeFullscreenView: function(){
                // full screen views live outside of the state manager because they don't
                // modify the url. If that assumption ever changes, we should switch
                // full screen view to being state managed and this hack can go away
                if (this.fullscreenView) {
                    // we need to clear out fullscreenView before calling close,
                    // because we are navigating to a new view, and don't want an accidental
                    // call to clearFullscreenView to reinstantiate the activeView
                    var fullscreenView = this.fullscreenView;
                    this.fullscreenView = null;
                    fullscreenView.close();
                }
            },
            /**
             * Handle transitions from fromUrl to toUrl
             * @param {String} toUrl - url we're going to
             * @param {{url: String, app: Object, layer: String}} layerObj - reference to the layer we're transitioning
             * @param {Function} NextAppClass - function for generating the next app
             * @param {Object} initOptions - options for the new app
             * @param {String} requestedLayerType - type of layer the new app is
             * @param {Deferred} [requestPromise] - optional promise that provides html for the transition we're going to have
             * @param {Deferred} [resourcePromise] - optional promise that resolves when all the js resources are loaded
             * @returns {Deferred} promise that will resolve when the transition finishes
             * @private
             */
            _handleTransition: function(toUrl, layerObj, NextAppClass, initOptions, requestedLayerType, requestPromise, resourcePromise) {
                var requestedApp,
                    fromUrl = layerObj.url;
                if (this._needsNewApp(NextAppClass, layerObj)) {
                    requestedApp = new NextAppClass(initOptions); // construct a new app, so we can talk to it
                    requestPromise = requestPromise || this._requestPageHtml(fromUrl, toUrl, layerObj === this.preloadedAppInfo); // request html (optional)
                    // Scenarios
                    if (!layerObj.app) {
                        // 1: First time page load or first time preload (just reveal app)
                        return this._revealApp(layerObj, requestedApp, fromUrl, toUrl, requestPromise, resourcePromise);
                    } else {
                        return this._changeApps(layerObj, requestedLayerType, requestedApp, fromUrl, toUrl, requestPromise, resourcePromise);
                    }
                } else {
                    // no new app scenarios:
                    if (layerObj.layer === requestedLayerType) {
                        // 1: changePage of current app
                        requestPromise = this._requestPageHtml(fromUrl, toUrl, layerObj === this.preloadedAppInfo);
                        return this._changePage(layerObj, fromUrl, toUrl, requestPromise, resourcePromise);
                    } else {
                        // 2: remove overlay and show preloaded app
                        return this._transitionFromOverlayToPreloadedApp(fromUrl, toUrl, resourcePromise);
                    }
                }
            },
            /**
             * Introduces a new app to take control of the page, removing/stashing any existing app
             * @param {{url: String, app: Object, layer: String}} layerObj - reference to the layer we're transitioning
             * @param {String} requestedLayerType - type of layer the new app is
             * @param {Object} requestedApp - app we're bringing in
             * @param {String} fromUrl - url we're coming from
             * @param {String} toUrl - url we're going to
             * @param {Deferred} requestPromise - request promise that provides html for the new app
             * @param {Deferred} [resourcePromise] - optional promise that provides js resources to be loaded
             * @returns {Deferred} promise that will resolve when the transition finishes
             * @private
             */
            _changeApps: function(layerObj, requestedLayerType, requestedApp, fromUrl, toUrl, requestPromise, resourcePromise) {
                var isLongform = (Utils.getNested(layerObj, 'app', 'pageInfo', 'basePageType') === 'story-longform') ? true : false;
                if (layerObj.layer === requestedLayerType) {
                    // 2: change from layer -> same layer, but different app (remove current app, reveal new app)
                    return layerObj.app.removeApp(fromUrl, toUrl).then(_.bind(function(){
                        return this._revealApp(layerObj, requestedApp, fromUrl, toUrl, requestPromise, resourcePromise);
                    }, this));
                } else if (requestedLayerType === this.LAYER_OVERLAY && !isLongform) {
                    // 3: Opening overlay on top of app (stash current app, reveal new app)
                    return this._revealOverlay(requestedApp, fromUrl, toUrl, requestPromise, resourcePromise);
                } else if (isLongform && requestedLayerType === 'overlay') {
                    //Same as below, except do not remove the overlay since it does not exist in longform
                    return $.when(this._removePreloadedApp(fromUrl, toUrl)).then(_.bind(function() {
                        return this._revealApp(layerObj, requestedApp, fromUrl, toUrl, requestPromise, resourcePromise);
                    }, this));
                } else {
                    // overlay to base layer, remove preloaded app if necessary and reveal new app
                    return $.when(this._removePreloadedApp(fromUrl, toUrl), this._removeOverlay(layerObj.app, fromUrl, toUrl)).then(_.bind(function() {
                        return this._revealApp(layerObj, requestedApp, fromUrl, toUrl, requestPromise, resourcePromise);
                    }, this));
                }
            },
            updateState: function() {
                var info = this.activeAppInfo;
                if (!info.appConfig || !info.pageConfig) {
                    return;
                }
                if (this.header) {
                    this._setHeader(this.header, $.extend({}, info.appConfig.header, info.pageConfig.header));
                }
                var requestedViewport = info.appConfig.viewport || info.pageConfig.viewport || "width=1070";
                if (this._currentViewport !== requestedViewport){
                    this._currentViewport = requestedViewport;
                    $('meta[name=viewport]').attr('content', requestedViewport);
                }
                if(window.Scroll && window.Scroll.virtualPage) {
                    window.Scroll.do(window.Scroll.virtualPage());
                }
            },
            _setHeader: function(header, options) {
                if (_.isEmpty(options)) {
                    header.restoreDefaultState();
                } else {
                    if (options.fixed) {
                        if (options.open) {
                            header.setOpenFixed();
                        } else {
                            header.setClosedFixed();
                        }
                    } else {
                        header.setFixingScroller(true);
                    }
                }
            },
            /**
             * Triggers a changePage event on the layerObject passed in
             * @param {Object} layerObj
             * @param {String} fromUrl url we're coming from
             * @param {String} toUrl url we're going to
             * @param {Deferred} requestPromise promise holding the html for the next page
             * @param {Deferred} resourcePromise promise holding any js we need for the next page
             * @returns {Deferred}
             * @private
             */
            _changePage: function(layerObj, fromUrl, toUrl, requestPromise, resourcePromise){
                return layerObj.app.changePage(fromUrl, toUrl, requestPromise, resourcePromise, layerObj === this.preloadedAppInfo)
                    .then(null, _.bind(function(reason, routeInfo) {
                        if (reason === 'InvalidApp' && routeInfo) {
                            // replace the original promise with a new one, just because this one failed, doesn't mean the entire transition failed
                            return this._invalidateApp(layerObj, routeInfo, fromUrl, toUrl, requestPromise);
                        }
                    }, this));
            },

            _rerouteInitialPageLoad: function(layerObj, toUrl, routeInfo) {
                // we defer because the initial page load has no delays in it, so we can clobber statemanager variables by
                // immediately firing another change page request
                return $.Deferred(function(defer) {
                    _.defer(function() {
                        // reset some variables so we end up back at initial page load
                        layerObj.app = null;
                        layerObj.url = null;
                        defer.resolve();
                    });
                }).then(_.bind(function() {
                    return this._loadRoute(routeInfo, toUrl);
                }, this));
            },

            /**
             * Given the preloaded app, will remove it from the internal data structure as well as the dom
             * @param {String} fromUrl - url we're coming from
             * @param {String} toUrl - url we're going to
             * @returns {Deferred|undefined}
             * @private
             */
            _removePreloadedApp: function(fromUrl, toUrl) {
                var preloadedApp = this.preloadedAppInfo.app;
                if (preloadedApp) {
                    this._clearPreloadedAppInfo();
                    preloadedApp.beforeOverlayRemove(toUrl);
                    return preloadedApp.removeApp(fromUrl, toUrl);
                }
            },

            /**
             * triggers revealApp on the requestedApp, sets the app as active on the layer
             * @param {Object} layerObj
             * @param {Object} requestedApp
             * @param {String} fromUrl
             * @param {String} toUrl
             * @param {Deferred} requestPromise
             * @param {Deferred} resourcePromise
             * @returns {Deferred}
             * @private
             */
            _revealApp: function(layerObj, requestedApp, fromUrl, toUrl, requestPromise, resourcePromise) {
                layerObj.app = requestedApp;
                return requestedApp.revealApp(fromUrl, toUrl, requestPromise, resourcePromise, layerObj === this.preloadedAppInfo)
                    .then(null, _.bind(function(reason, routeInfo) {
                            if (reason === 'InvalidApp' && routeInfo) {
                                // replace the original promise with a new one, just because this one failed, doesn't mean the entire transition failed
                                return this._invalidateApp(layerObj, routeInfo, fromUrl, toUrl, requestPromise);
                            }
                        }, this)
                    );
            },
            _invalidateApp: function(layerObj, routeInfo, fromUrl, toUrl, requestPromise){
                try {
                    layerObj.app.destroy();
                } catch(ex) {
                    console.error('View threw an exception on destroy: ', (ex.stack || ex.stacktrace || ex.message));
                }
                console.log('Rerouting: ' + routeInfo.page.appName + '/' + routeInfo.page.name);
                if (requestPromise) {
                    layerObj.url = fromUrl;
                    if (layerObj === this.activeAppInfo) {
                        return this._loadRoute(routeInfo, toUrl, requestPromise);
                    } else {
                        return this._preloadRoute(routeInfo, toUrl, requestPromise);
                    }
                } else {
                    return this._rerouteInitialPageLoad(layerObj, toUrl, routeInfo);
                }
            },

            /**
             * Returns whether or not we need a new app to handle the current url request
             * @param {Function} NextAppClass
             * @param {Object} layerObj
             * @returns {Boolean}
             * @private
             */
            _needsNewApp: function(NextAppClass, layerObj) {
                return !layerObj.app || // no app yet, ie first page load or first preload load
                    !layerObj.app.isRevealed() || // app isn't ready yet
                    // the new app doesn't match the active app, opening overlay, closing overlay, changing app at current level
                    (Object.getPrototypeOf(layerObj.app) !== NextAppClass.prototype &&
                        // we do need a new app unless there's a preloaded app and the preloaded app matches the requested app (close overlay to preloaded app)
                        (!this.preloadedAppInfo.app || Object.getPrototypeOf(this.preloadedAppInfo.app) !== NextAppClass.prototype || !this.preloadedAppInfo.app.isRevealed()));
            },
            /**
             * Requests the next page's html, or, if it's not needed, will abort all non-nav requests because the current page is being destroyed
             * @param {String} fromUrl - url we're coming from
             * @param {String} toUrl - url we're going to
             * @param {Boolean} preload - whether this fetch is for a preloaded app or an active app, controls whether we need to abort the ajax
             * @returns {Deferred|null}
             * @private
             */
            _requestPageHtml: function(fromUrl, toUrl, preload) {
                // Check for "full page" ab tests where we need to fetch a url different from the requested one.
                var fullPageAbTest = AbTestManager.getUserTestByTargetAndPath('full-page', toUrl),
                    abTestOptions = Utils.getNested(fullPageAbTest, 'userVariant', 'options') || {},
                    altUrl = abTestOptions.altUrl,
                    altParams = _.omit(abTestOptions, 'altUrl');
                if (altUrl && !_.isEmpty(altParams)) {
                    altUrl += "?" + $.param(altParams);
                }
                if (fromUrl !== null || preload || altUrl) {
                    if (fromUrl !== toUrl) {
                        if (altUrl) {
                            return this._fetchPathHtml(altUrl, false, { ignoreVersionCheck: true });
                        }
                        return this._fetchPathHtml(toUrl, preload);
                    } else if (!preload) {
                        // without this, background requests would still fire because we didn't trigger a nav request
                        RequestManager.abortAllRequests();
                    }
                }
                return null;
            },
            /**
             * Transitions from the overlay layer to the preloaded layer, assumes that _removeApps has been called
             * and will pass in a valid removalPromise
             * @param {String} fromUrl - url we're coming from
             * @param {String} toUrl - url we're going to
             * @param {Deferred} resourcePromise - promise that resolves when all the js resources are loaded
             * @returns {Deferred} promise that resolves when the entire transition is complete
             * @private
             */
            _transitionFromOverlayToPreloadedApp: function(fromUrl, toUrl, resourcePromise) {
                var nextAppInfo = this.preloadedAppInfo,
                    requestPromise = this._requestPageHtml(nextAppInfo.url, toUrl, false),
                    currentApp = this.activeAppInfo.app;
                this._movePreloadAppToActiveApp(this.LAYER_BASE);
                nextAppInfo.app.beforeOverlayRemove(toUrl);
                return this._removeOverlay(currentApp, fromUrl, toUrl).then(_.bind(function() {
                    // transition the active app to optionally trigger an animation and init it
                    this.updateState(); // we need to update the state here so when we adjust the scroller, we're in the right header state for it
                    this._transitionFromOverlayScroll(nextAppInfo);
                    return this._changePage(nextAppInfo, fromUrl, toUrl, requestPromise, resourcePromise);
                }, this));
            },

            /**
             * Removes the passed in overlay app and it's overlay
             * @param {Object} overlayApp
             * @param {String} fromUrl - url we're coming from
             * @param {String} toUrl - url we're going to
             * @returns {Deferred}
             * @private
             */
            _removeOverlay: function(overlayApp, fromUrl, toUrl) {
                return $.when(overlayApp.removeApp(fromUrl, toUrl), this._fadeOutOverlayFilm(overlayApp));
            },

            /**
             * Takes the preloaded app, and copies it to the active app structure
             * @param {String} requestedLayer
             * @private
             */
            _movePreloadAppToActiveApp: function(requestedLayer) {
                $.extend(this.activeAppInfo, this.preloadedAppInfo);
                PubSub.trigger('activePageInfo:set', this.activeAppInfo.app.pageInfo);
                this.activeAppInfo.layer = requestedLayer;
                this._clearPreloadedAppInfo();
            },
            /**
             * Takes the current app, and puts it into the preloadedApp data structure
             * @private
             */
            _deactivateActiveApp: function() {
                this.activeAppInfo.app.pause();
                $.extend(this.preloadedAppInfo, this.activeAppInfo);
            },
            _revealOverlay: function(nextApp, fromUrl, toUrl, requestPromise, resourcePromise) {
                // save the transitionView, cause the world might change by the time we finish animating
                var appToBeStashed = this.activeAppInfo.app;
                if (!appToBeStashed.isRevealed()) {
                    appToBeStashed.removeApp(fromUrl, toUrl);
                    appToBeStashed = null; // skip it, it's not in a valid state
                } else {
                    this._deactivateActiveApp();
                }
                this.body.css('overflow-y', 'scroll');
                this._transitionToOverlayScroll(this.preloadedAppInfo);
                var promise = $.when(this._fadeInOverlayFilm(nextApp), this._revealApp(this.activeAppInfo, nextApp, fromUrl, toUrl, requestPromise, resourcePromise));
                PubSub.trigger('scrollTop', 0);
                promise.done(_.bind(function() {
                    this.body.css('overflow-y', '');
                    if (appToBeStashed && !appToBeStashed.destroyed) {
                        appToBeStashed.afterOverlayReveal(this.preloadedAppInfo.scrollTop);
                    }
                }, this));
                return promise;
            },
            /**
             * Fades in the overlay film, adds one to the dom if necessary
             * @param {Object} nextApp
             * @returns {Deferred}
             * @private
             */
            _fadeInOverlayFilm: function(nextApp) {
                var fadeInOptions = nextApp.options.animations.fadeIn;
                if (!this.$overlayFilm.length) {
                    this.$overlayFilm = $('<div class="ui-film"></div>');
                    this.body.append(this.$overlayFilm);
                }
                return nextApp.animate(this.$overlayFilm, 'opacity', 0.7, fadeInOptions.duration, 'ease-in');
            },
            /**
             * Fades out the overlay film, removes it from the dom
             * @param {Object} activeApp
             * @returns {Deferred}
             * @private
             */
            _fadeOutOverlayFilm: function(activeApp) {
                var overlayFilm = this.$overlayFilm,
                    fadeOutOptions = activeApp.options.animations.fadeOut;
                this.$overlayFilm = $([]);
                return activeApp.animate(overlayFilm, 'opacity', 0, fadeOutOptions.duration, 'ease-out').done(function(){
                    overlayFilm.remove();
                });
            },
            _fetchPathHtml: function(toUrl, preload, options) {
                var requestPromise = this.fetchHtml(toUrl, options || null, !preload);
                if (!preload) {
                    requestPromise.fail(_.bind(function(e) {
                        if (e) {
                            if (e === 'NOT AUTHORIZED') {
                                window.location.assign(Utils.getNested(window, 'firefly_urls', 'samSubscribeURL') || '');
                            } else {
                                var msg = this.generateRequestError(e);
                                if (msg) {
                                    Alert.showError(msg);
                                }
                                if ((e.status !== 200 && e.status) || e.statusText === 'timeout') {
                                    // if the status is 200, means the request succeeded, but the promise was rejected/aborted
                                    // status of 0 means the connection itself was aborted
                                    window.history.back();
                                }
                            }
                        }
                    }, this));
                }
                return requestPromise;
            },
            generateRequestError: function(e) {
                if (e) {
                    if (e.status === 500) {
                        return 'Connection Error... (INTERNAL SERVER ERROR)';
                    } else if (e.status === 404) {
                        return 'Connection Error... (FILE NOT FOUND)';
                    } else if (e.status !== 200 && e.status) {
                        // if the status is 200, we just got canceled, otherwise we show some error message
                        return 'Connection Error... (' + (e.statusText || 'Unknown Error') + ')';
                    }
                }
                return null;
            },
            _transitionFromOverlayScroll: function(appInfo) {
                var app = appInfo.app;
                if (app) {
                    if (app.isApple) {
                        // fixed position and scrolltop don't get along
                        app.show();
                    } else {
                        this._clearFixedPosition(appInfo, app);
                    }
                    // reset z-index
                    app.$el.css('z-index', '');
                }
            },
            _transitionToOverlayScroll: function(appInfo) {
                var app = appInfo.app;
                if (app) {
                    if (app.isApple) {
                        // fixed position and scrolltop don't get along
                        app.hide();
                    } else {
                        this._setFixedPosition(appInfo, app);
                    }
                    // force the active app to sit below the overlay
                    app.$el.css('z-index', '0');
                }
            },
            _clearFixedPosition: function(appInfo, app, partialCover) {
                app.clearFixedPosition(partialCover);
                PubSub.trigger('scrollTop', appInfo.scrollTop || 0);
            },
            _setFixedPosition: function(appInfo, app, partialCover) {
                var appPosition = app.getWindowOffset().top;
                appInfo.scrollTop = Utils.getScrollPosition();
                PubSub.trigger('scrollTop', 0);
                app.setFixedPosition(appPosition, partialCover);
            },

            onPageLoad: function() {
                if (Utils.flag('lazy_load')) {
                    this.resetIntersectionObservers();
                }
                this.updateActivityTimestamp();
            },

            onDomUpdate: function() {
                this._checkForIntersections();
            },

            /**
             * Store the timestamp of the latest activity (ie. mousemove). Used
             * to determine whether to refresh browser automatically after a
             * certain period of idle time.
             */
            updateActivityTimestamp: function() {
                this.lastActivityTimestamp = (new Date()).getTime();
            },

            /**
             * Gets the active page info object
             * @returns {PageInfo}
             */
            getActivePageInfo: function(){
                var activeAppPageInfo = Utils.getNested(this.activeAppInfo, 'app', 'pageInfo');
                if (activeAppPageInfo) {
                    return activeAppPageInfo;
                } else {
                    if (!this.initialPageInfo) {
                        this.initialPageInfo = Utils.parsePageInfo(this.body);
                        this.initialPageInfo.headerAbVariant = this.body.attr('data-ab-variant');
                    }
                    return this.initialPageInfo;
                }
            },
            getActiveAppClientInfo: function(){
                return this.activeAppInfo.app && this.activeAppInfo.app.getClientInfo() || {};
            },
            /**
             * Gets the preloaded page info object
             * @returns {PageInfo}
             */
            getPreloadedPageInfo: function(){
                return this.preloadedAppInfo.app && this.preloadedAppInfo.app.pageInfo || {};
            },
            /**
             * Gets the current active app
             * @returns {Object}
             */
            getActiveApp: function(){
                return this.activeAppInfo.app;
            },
            /**
             * Gets the preloaded app if one exists
             * @returns {Object}
             */
            getPreloadedApp: function(){
                return this.preloadedAppInfo.app;
            },

            /**
             * Retrieves the last url the site ajax'd from
             * @returns {String}
             */
            getReferrer: function(){
                if (this.lastUrl === null) {
                    return document.referrer;
                } else {
                    return Utils.getOrigin() + this.lastUrl;
                }
            },

            getCurrentUrl: function(){
                return (this.activeAppInfo.url) ? Utils.getOrigin() + this.activeAppInfo.url : window.location.href;
            },

            /**
             * Start refresh interval. Check current time against last activity
             * and refresh the page if it's been longer than threshold of idle
             * time.
             */
            startRefreshTimer: function() {
                if (!this.refreshTimer && this.REFRESH_FREQUENCY) {
                    this.lastActivityTimestamp = 0;
                    this.refreshTimer = setInterval(_.bind(function(){
                        if (!this.fullscreenView && this.activeAppInfo.layer !== this.LAYER_OVERLAY) {
                            var currentTime = (new Date()).getTime();
                            var idleTime = currentTime - this.lastActivityTimestamp;
                            if (idleTime > this.REFRESH_FREQUENCY) {
                                PubSub.trigger('site:refresh', 'refresh:' + (this.getActivePageInfo().ssts || '').replace(/[\/:].*/, ''));
                                window.location = window.location;
                            }
                        }
                    }, this), 60 * 1000); // check every 60 seconds
                }
            },

            /**
             * Stop refresh timer.
             */
            stopRefreshTimer: function() {
                if (this.refreshTimer) {
                    clearInterval(this.refreshTimer);
                    this.refreshTimer = null;
                }
            },

            /**
             * Registers a navigation animation that should defer all incoming ajax requests
             * @param {Deferred} deferred jQuery promise object
             * @param {jQuery} [el] dom element
             * @param {String} [property] name
             * @param {String|Number} [value] being animated to
             * @param {Number} [timeMs] time for animation
             * @return {Deferred} representing when all animations are done
             */
            registerAnimation: function(deferred, el, property, value, timeMs) {
                return TrafficCop.addAnimation(deferred, el, property, value, timeMs);
            },

            /**
             * Helper function that auto populates fetchData with the isHtml flag being true
             * @param {String} path The path to the ajax endpoint.
             * @param {Object} [options] jQuery ajax option.
             * @param {Boolean} [isNavigation] specifies if this request is a navigation request
             *                  or a background loading request.
             * @param {Boolean} [isStatic] tells the ajax request whether to add
             *      the pjax headers or not
             * @return {Deferred} jQuery promise object
             */
            fetchHtml: function(path, options, isNavigation, isStatic) {
                return RequestManager.fetchHtml(path, options, isNavigation, isStatic, true);
            },

            persistentFetchHtml: function(path, options, isNavigation, isStatic) {
                return RequestManager.persistentFetchHtml(path, options, isNavigation, isStatic, true);
            },

            /**
             * Fetch data from server via AJAX. Takes a path to fetch and a
             * callback to parse the data and initialize views.
             * @param {String} path The path to the ajax endpoint.
             * @param {Object} [options] jQuery ajax option.
             * @param {Boolean} [isNavigation] specifies if this request is a navigation request
             *                  or a background loading request.
             * @param {Boolean} [isStatic] tells the ajax request whether to add
             *      the pjax headers or not
             * @param {Boolean} [isHtml] will return a quickly built jQuery dom object.
             * @return {Deferred} jQuery promise object
             */
            fetchData: function(path, options, isNavigation, isStatic, isHtml) {
                return RequestManager.fetchData(path, options, isNavigation, isStatic, isHtml);
            },
            persistentFetchData: function(path, options, isNavigation, isStatic, isHtml) {
                return RequestManager.persistentFetchData(path, options, isNavigation, isStatic, isHtml);
            },

            /**
             * repeatedly calls fetchHtml at a specified interval and passing the results to a callback
             * @param {String} path The path to the ajax endpoint.
             * @param {Object} options ajax options
             * @param {Number} interval time in ms to repeat
             * @param {Function} callback function to call when fetchHtml succeeds
             * @param {Boolean} [isStatic] tells the ajax request whether to add the pjax headers or not
             * @return {Number} setInterval id
             */
            recurringFetchHtml: function(path, options, interval, callback, isStatic){
                return RequestManager.recurringFetchHtml(path, options, interval, callback, isStatic, true);
            },

            /**
             * repeatedly calls fetchData at a specified interval and passing the results to a callback
             * @param {String} path The path to the ajax endpoint.
             * @param {Object} options ajax options
             * @param {Number} interval time in ms to repeat
             * @param {Function} callback function to call when fetchHtml succeeds
             * @param {Boolean} [isStatic] tells the ajax request whether to add the pjax headers or not
             * @param {Boolean} [isHtml] will return a quickly built jQuery dom object
             * @return {Number} setInterval id
             */
            recurringFetchData: function(path, options, interval, callback, isStatic, isHtml){
                return RequestManager.recurringFetchData(path, options, interval, callback, isStatic, isHtml);
            }
        };

        /**
         * @global
         */
    window.stateManager = new StateManager();
    return window.stateManager;
});

