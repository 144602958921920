/**
* @fileoverview Manages Sports Brackets Page.
* @author tjrivera@usatoday.com (Thomas Rivera)
*/
define('modules/sports/sports-brackets',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'state',
    'baseview'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    StateManager,
    BaseView
) {

    /**
     * View class.
     */
    var SportsBrackets = BaseView.extend({
            
        events: {
            "click .sp-region-hat .view-nav a" : "scrollTo",
            "mouseenter .sports-bracket-grid .match.activated" : "hoverOn",
            "mouseleave .sports-bracket-grid .match.activated" : "hoverOff"
        },
        
        
        /**
         * Initialize the view.
         */
        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);
            _.bindAll(this, "scrollTo" );
            
            $('.cards').addClass('sports-brackets');

            //SHARE THIS API
            this.share_this_api = "http://w.sharethis.com/button/buttons.js";
            this.$share_bar = $('.sp-brackets-sharebar');
            this.$share_count_element = $('.sp-brackets-sharebar .count');
            this.shared_url = this.$share_count_element.data('url');
            this.share_counts_url = '/sports/services/brackets-share-count/?shared_url=' + this.shared_url;
            
            
            //SET NEWS VARIABLES
            this.$news_headlines = $("#sp-brackets-headlines");
            this.$news_data_container = $("#sp-brackets-headlines-data");
            this.news_count = $("#sp-brackets-headlines-data").data('count');
            this.news_totalcount = 0;
            this.news_offset = 0;
            this.$news_data_loader = $("#sp-brackets-headlines-loader");
            this.news_max_reached = false;
            this.news_url = "/sports/services/brackets-news/" + this.news_count + "/" + this.news_offset + "/";
            this.news_counter = this.news_count;
            this.pause_infinite_scroll = false;
            
            
            this.$bracket_header_element = $(".sp-brackets-header");
            this.auto_refresh = this.$bracket_header_element.data('autorefresh');
            this.$bracket_grid_element = $('.sports-bracket-grid');
            
           //BRACKET AJAX VARS
            this.$bracket_data_element = $('#sp-bracket-data');
            this.bracket_league = this.$bracket_data_element.data('league');
            this.bracket_season = this.$bracket_data_element.data('season');
            this.bracket_data_url = "/sports/services/brackets-realtime/" + this.bracket_league + "/" + this.bracket_season + "/";
            
            //BRACKET AJAX BOXSCORE VARS
            this.$bracket_boxscore_data_element = $('#sp-bracket-boxscore-data');
            this.bracket_boxscore_league = this.$bracket_boxscore_data_element.data('league');
            this.bracket_boxscore_season = this.$bracket_boxscore_data_element.data('season');
            this.bracket_boxscore_eventid = this.$bracket_boxscore_data_element.data('eventid');
            this.bracket_boxscore_data_url = "/sports/services/brackets-boxscore-realtime/" + this.bracket_boxscore_league + "/" + this.bracket_boxscore_season + "/" + this.bracket_boxscore_eventid + "/";
            this.refreshBracketInterval = false;
            this.refreshBoxscoreInterval = false;
            
            //GET NEWS IF MODULE EXISTS
            if (this.$news_data_container.length > 0) {
                this.getNews(this.news_url);
            }
            
            //STOP DEFAULT REFRESH TIMER
            StateManager.stopRefreshTimer();
            
            //INIT/SET REFRESH RATE
            if ( this.$bracket_header_element.length > 0 ) {
                if ( this.auto_refresh === "yes") {
                    //this.setRefreshRate();
                    
                    if ( this.$bracket_boxscore_data_element.length > 0 ) {
                        this.refreshBoxscoreData();
                    }
                    
                    if ( this.$bracket_data_element.length > 0 ) {
                        this.refreshBracketData();
                    }  
                }
            }    
            
            //INIT SHARE COUNT
            if (this.$share_count_element.length > 0) {
                this.getShareCount();
                this.loadShareScripts();
            }

            // If skin_link defined, override logo link
            this.$skinLink = this.$el.parent().data('skin-link');
            if (this.$skinLink) {
                this.$('.sp-brackets-sponsor-logo').attr('href', this.$skinLink);
            }
        },
            
        refreshBracketData: function() {
            this.refreshBracketInterval = setInterval(_.bind(function(){
                
                //var date = new Date();
                //var url = this.bracket_data_url + "?timestamp=" + date;
                var url = this.bracket_data_url;
                StateManager.fetchHtml(url)
                .done(function(html) {
                    $('#sp-bracket-data').empty();
                    $('#sp-bracket-data').html(html);
                })
                .fail(function(){
                    console.log('bracket data failed');
                });
               
            }, this),90000); 
        },
        
        refreshBoxscoreData: function() {
            this.refreshBoxscoreInterval = setInterval(_.bind(function(){
                
                //var date = new Date();
                //var url = this.bracket_boxscore_data_url + "?timestamp=" + date;
                var url = this.bracket_boxscore_data_url;
                StateManager.fetchHtml(url)
                .done(function(html) {
                    $('#sp-bracket-boxscore-data').empty();
                    $('#sp-bracket-boxscore-data').html(html);
                })
                .fail(function(){
                    console.log('boxscore data failed');
                });
               
            }, this),90000); 
        },
        
               
        getShareCount: function() {
            var url = this.share_counts_url;
            StateManager.fetchHtml(url)
            .done(_.bind(function(html) {
                this.$share_count_element.empty();
                this.$share_count_element.html(html);
            }, this))
            .fail(_.bind(function(){
                console.log('sharethis count failed');
            }, this));
        },
        
        loadShareScripts: function() {
            require([this.share_this_api], _.bind(function() {
                if (typeof window.stLight !== 'undefined') {
                    //this.shareopt = window.stLight;
                    window.stLight.options({
                        publisher:"df703b24-a0ff-41d1-93f3-b4ce80edef4b", 
                        doNotHash:false, 
                        doNotCopy:true, 
                        hashAddressBar:false, 
                        newOrZero:"zero", 
                        onhover:false, 
                        shorten:true, 
                        servicePopup:true, 
                        tracking:true 
                        //publisherGA:"UA-39676707-1"
                     });
                    window.stButtons.locateElements();
                } else {
                    console.log('Error loading ShareThis!');
                }
                
            }, this));
        },
        
        setRefreshRate: function() {
            StateManager.stopRefreshTimer();
            StateManager.REFRESH_FREQUENCY = (1.5) * 60 * 1000;
            StateManager.startRefreshTimer();
        },
        
        scrollTo: function(e) {
            e.preventDefault();
            var el = $(e.currentTarget);
            var goEl = "#" + el.data("id");
            $('html,body').animate({
                scrollTop: $(goEl).offset().top
            }, 1000);
        },
        
        //BRACKET GRID MATCH HOVER STATE
        hoverOn: function(e) {
            var el = $(e.currentTarget);
                el.fadeIn('slow').animate({ backgroundColor: '#E2E2E2'}, 300);
        },
        
        //BRACKET GRID MATCH HOVER OFF STATE
        hoverOff: function(e) {
            var el = $(e.currentTarget);
            if ( el.hasClass('islive') ) {
                el.fadeIn('slow').animate({ backgroundColor: '#fef9d3'}, 300);
            } else {
                el.fadeIn('slow').animate({ backgroundColor: '#FBFBFB'}, 300);
            }
            
        },
        
        //GET NEWS
        getNews: function(url) {
            
            StateManager.fetchHtml(url)
            .done(_.bind(function(html) {    
           
                this.$news_data_loader.hide();
                this.$news_data_container.append(html);
                     
                if ($(html).length > 0  ) {
                    this.news_totalcount = parseInt($(html).data('totalcount'), 10);
                    if (this.news_count < this.news_totalcount) {
                        this.updateNewsUrl();
                        this.initInfiniteScroll();
                    } 
                }
                
            }, this))
            .fail(_.bind(function(){
                this.$news_data_container.append("<p>We're sorry. An error has occurred please try again later.</p>"); 
            }, this));
            
            
        },
        
        //BRACKET NEWS INFINITE SCROLL
        getMoreNews: function() {
            this.$news_data_loader.show();
           
            var url =  this.newsUrl;
            StateManager.fetchHtml(url)
            .done(_.bind(function(html) {
                
                this.$news_data_loader.hide();
                
                if ( $(html).length > 0 ) {
                    this.$news_data_container.append(html);
                    this.updateNewsUrl();
                    this.pause_infinite_scroll = false;
                    this.news_counter = (this.news_counter + this.news_count);
                    
                    if (this.news_counter >= this.news_totalcount) {
                        this.news_max_reached = true;
                    } 
                } else {
                    this.news_max_reached = true;
                }
                
            }, this))
            .fail(_.bind(function(){
                this.$news_data_container.append("<p>We're sorry. An error has occurred please try again later.</p>"); 
            }, this));
            
        },
        
        updateNewsUrl: function() {
            this.news_offset = parseInt(this.news_offset + this.news_count, 10);
            
            this.newsUrl = "/sports/services/brackets-news/" + this.news_count + "/" + this.news_offset + "/";
        },
        
        initInfiniteScroll: function() {
            
            $(window).scroll(_.bind(function() {
                if($(window).scrollTop() >= ($(document).height() - 700) - $(window).height() && this.news_max_reached === false){
                    if ( this.pause_infinite_scroll === false ) {
                        this.pause_infinite_scroll = true;
                        this.getMoreNews();        
                    }
                } 
            }, this));
        },
        
        destroy: function() {
            if (this.refreshBracketInterval !== false) {
                clearInterval(this.refreshBracketInterval);
                this.refreshBracketInterval = false;  
            }
            
            if (this.refreshBoxscoreInterval !== false) {
                clearInterval(this.refreshBoxscoreInterval);
                this.refreshBoxscoreInterval = false;  
            }
            
            clearInterval(this.refreshBoxscoreInterval);
            
            $('.cards').removeClass('sports-brackets');
            //StateManager.REFRESH_FREQUENCY = (window.site_vars.REFRESH_RATE || 0) * 60 * 1000;
            // Call base call destroy
            BaseView.prototype.destroy.call(this);
        }
   
    });

    return SportsBrackets;
});

