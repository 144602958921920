define('modules/featured-events/featured-events',[
    'jquery',
    'underscore',
    'state',
    'baseview',
    'utils',
    'ui/generic-paginator'
], function (
    $,
    _,
    StateManager,
    BaseView,
    Utils,
    GenericPaginatorView) {

    var FeaturedEvents = BaseView.extend({
        // Events.
        events: {
            'click .js-pfem-carousel-prev': 'onPrevPage',
            'click .js-pfem-carousel-next': 'onNextPage',
            'click .js-pfem-ui-dropdown': 'toggleDropdown',
            'click .js-pfem-cat-ddl .ui-dropdown-item': 'onCategoryDropdownItemSelect',
            'click .js-pfem-when-ddl .ui-dropdown-item': 'onWhenDropdownItemSelect'
        },

        initialize: function(options) {
            // call base class initialize
            BaseView.prototype.initialize.call(this, options);

            this.moveMe = this.$('.pfem-items');
            this.viewport = this.moveMe.parent();
            this.pageIndex = 0;
            this.maxIndex = Math.ceil(this.moveMe.children().length / 3);
            this.prevCarousel = this.$('.pfem-carousel-prev');
            this.nextCarousel = this.$('.pfem-carousel-next');

            _.bindAll(this, 'goTo');
            this.subviews.paginator = new GenericPaginatorView({
                el: this.$('.paginator'),
                onGoTo: this.goTo
            });
            this.pageLastIndex = (this.subviews.paginator.$pageElements.length - 1);

            if (this.maxIndex === 1) {
                this.nextCarousel.removeClass('pfem-hidden');
            }
        },

        onPrevPage: function() {
            var newIndex = this.pageIndex - 1;
            if (newIndex >= 0) {
                this.subviews.paginator.goToPage(newIndex);
            }
        },

        onNextPage: function() {
            var newIndex = this.pageIndex + 1;
            if (newIndex <= this.pageLastIndex) {
                this.subviews.paginator.goToPage(newIndex);
            }
        },

        toggleDropdown: function(selectedItem) {
            this.$(selectedItem.currentTarget).toggleClass('expanded');
        },

        onCategoryDropdownItemSelect: function (selectedItem) {
            var selectedOption = this.$(selectedItem.currentTarget);
            this.$('#pfem-cat-display').html(selectedOption.html());
            this.$('#pfem-cat').val(selectedOption.data().value);
        },

        onWhenDropdownItemSelect: function (selectedItem) {
            var selectedValue = this.$(selectedItem.currentTarget).html();
            this.$('#pfem-when-display').html(selectedValue);
            this.$('#pfem-when').val(selectedValue);
        },

        goTo: function(pageIndex) {
            var offsetBy = this.viewport.outerWidth();
            var targetOffset = offsetBy * pageIndex * -1;

            this.animate(this.moveMe, 'left', targetOffset + 'px', 450, 'ease-in-out');

            if (pageIndex > 0) {
                this.prevCarousel.removeClass('pfem-hidden');
            } else {
                this.prevCarousel.addClass('pfem-hidden');
            }

            if (pageIndex < this.pageLastIndex) {
                this.nextCarousel.removeClass('pfem-hidden');
            } else {
                this.nextCarousel.addClass('pfem-hidden');
            }

            this.pageIndex = pageIndex;
        }

    });

    return FeaturedEvents;

});

