/* global define */
define('modules/partner/paramount-ad-front',[
    'underscore',
    'baseview',
    'site-manager',
    'utils',
    'adPosition'
], function(
    _,
    BaseView,
    SiteManager,
    Utils,
    AdPosition
) {
    'use strict';

    var ParamountAdFront = BaseView.extend({

        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);
            this.$body = Utils.get('body');

            _.bindAll(this, '_onAdReady', '_onMessage');

            // Paramount Front Ad
            this.subviews.adPosition = new AdPosition({
                el: this.$el,
                adType: 'Paramount Ad Front',
                adPlacement: options.adPlacement,
                adSizes: 'fluid',
                onAdReady: this._onAdReady,
                onMessage: this._onMessage
            });
        },

        _onMessage: function(data) {
            if (data.adType === 'paramount') {
                this.$el.addClass('partner-paramount');
                var header = SiteManager.getHeader();
                if (header) {
                    header.setClosedFixed(true);
                }
                this.$body.addClass('paramount-ad-visible');
            }
        },

        _onAdReady: function() {
            this.subviews.adPosition.show();
        },

        destroy: function(removeEl) {
            this.$body.removeClass('paramount-ad-visible');
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });
    return ParamountAdFront;
});

