define('managers/autoplay',[
    'jquery',
    'underscore',
    'pubsub',
    'admanager',
    'utils'
],
function($, _, PubSub, AdManager, Utils) {
    "use strict";
    /**
     * This callback is called when it's time for your module to autoplay, must return a boolean or a Deferred promise.
     * Returning false will prevent any more autoplay from happening.
     * Returning true will allow the next autoplay to happen.
     * Returning a deferred will delay the next autoplay until it is resolved (rejected will be treated like returning false)
     * @callback onAutoPlay
     * @param {Array<String>} previousModules - list of names of previous modules that were called before you
     * @returns {Boolean|Deferred}
     */
    /**
     * Autoplay manager is designed to handle multiple modules that all want to autoplay at once,
     * but only one should actually play. It uses a global web-site-config managed list of module
     * names to pick the priority of modules and then each module can decide whether or not it wants
     * to prevent or delay future autoplays.
     * @requires admanager
     * @exports managers/autoplay
     * @author Jay Merrifield <jmerrifiel@gannett.com>
     */
    var AutoPlayManager = function() {
        this.pubSub = {
            'page:load': this._onPageLoad,
            'page:unload': this._onPageUnload
        };
    };
    AutoPlayManager.prototype = {
        start: function() {
            this._pageIndex = 0;
            // modules may have been registered to autoplay manager before it was started, we want to keep those
            this._currentModules = this._currentModules || {};
            this._playedModules = [];
            this._autoPlayOrder = Utils.getNested(window.site_vars, 'autoplay', 'default') || [];
            PubSub.attach(this.pubSub, this);
        },
        reset: function() {
            this._currentModules = {};
            this._playedModules = [];
        },
        stop: function() {
            PubSub.unattach(this.pubSub, this);
        },
        /**
         * The function registers an autoplay function with a name that will be called based on the order in site_vars
         * @param {String} name
         * @param {onAutoPlay} callback - callbacks must return a {@link Boolean}, or a jQuery Deferred object
         */
        register: function(name, callback) {
            this._currentModules = this._currentModules || {};
            if (_.isFunction(callback)) {
                this._currentModules[name] = callback;
            }
        },
        _onPageLoad: function() {
            AdManager.getActiveSlotStatistics().done(_.bind(function(stats) {
                this._go(0, this._pageIndex);
            }, this));
        },
        _onPageUnload: function() {
            this._pageIndex++;
            this.reset();
        },
        /**
         * Go through the autoplay list and trigger the startAutoPlay function.
         * This is an async loop using recursion until `yield` becomes available in the javascript spec
         * @param {Number} index
         * @param {Number} pageIndex
         * @private
         */
        _go: function(index, pageIndex) {
            if (pageIndex === this._pageIndex) {
                this._triggerAutoPlay(index || 0).then(_.bind(function() {
                    this._go(index + 1, pageIndex);
                }, this));
            }
        },
        _triggerAutoPlay: function(index) {
            var result = this._getAutoPlayResult(index);
            if (result && _.isFunction(result.then)) {
                return result;
            } else {
                return $.Deferred(_.bind(function(defer) {
                    if (result === false) {
                        defer.reject();
                    } else if (result === true) {
                        defer.resolve();
                    } else {
                        console.error('AutoPlayManager: Invalid return value from ' + this._autoPlayOrder[index] + '.startAutoPlay()');
                        defer.resolve();
                    }
                }, this));
            }
        },
        /**
         * Triggers next auto play and returns it's result.
         * Will return a boolean or a promise specifying either stop (false), go to next (true), or wait (promise)
         * @param {Number} index
         * @returns {Boolean|Promise}
         * @private
         */
        _getAutoPlayResult: function(index) {
            var moduleName = this._autoPlayOrder[index],
                previousModules = this._playedModules,
                callback = this._currentModules[moduleName];
            if (!callback) {
                // module doesn't exist, check if we're past the end of the list or not
                return index < this._autoPlayOrder.length;
            } else {
                this._playedModules = this._playedModules.concat(moduleName);
                return callback(previousModules);
            }
        }
    };
    return new AutoPlayManager();
});
