/**
 * @fileoverview mycapture button
 */

define('modules/myCapture/myCapture',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'utils'
],
function (
    $,
    _,
    BaseView,
    PubSub,
    Utils
) {
    "use strict";
    /**
     * mycapture view
     */
    var MyCapture = BaseView.extend({

        events: {
            'click .js-mycapture-btn': 'mycaptureBtnClick'
        },

        initialize: function (options) {
            options = $.extend({
                target: null
            }, options);

            BaseView.prototype.initialize.call(this, options);
        },

        /**
         * Build the mycapture url and open the new tab/window/popup?
         */
        mycaptureBtnClick: function (e) {
            e.preventDefault();

            if (this.options.target) {
                if (e.currentTarget.classList.contains(this.options.target)) this.handleClick(e);
            } else {
                this.handleClick(e);
            }

            
        },
        handleClick: function (e) {
            PubSub.trigger('uotrack', 'mycapturepurchase');

            var mycaptureUrl = this.buildLink($(e.currentTarget.parentElement).parent());

            window.open(mycaptureUrl, 'mycapture');
        },
        /**
         * Switch large mycapture button for small button or vice versa.
         * Primarily for expandable photos
         */
        switchMyCaptureBtn: function(view) {
            var $expandable = view.$('.single-photo'),
                $smallMyCaptureBtn = $expandable.find('.js-mycapture-btn-small'),
                $largeMyCaptureBtn = $expandable.find('.js-mycapture-btn'),
                $caption = view.$('.js-caption-wrapper');

            if ($expandable.hasClass('expandable-collapsed')) {
                $smallMyCaptureBtn.hide();
                $largeMyCaptureBtn.show();
                $caption.addClass('expandable-photo-caption-with-mycapture');
            } else {
                $largeMyCaptureBtn.hide();
                $smallMyCaptureBtn.show();
                $caption.removeClass('expandable-photo-caption-with-mycapture');
            }
        },

        /**
         * Hides the small 'buy photo' tag
         * @param {Boolean} show
         */
        toggleSmallTag: function(show) {
            this.$('.mycapture-small-btn').toggle(show);
        },

        /**
         * Build myCapture link
         * @param {jQuery} $el image element to build myCapture url from
         */
        buildLink: function($el) {
            var hostSegment = this.getHost(),
                mycaptureDomainAndPath = '.mycapture.com/mycapture/remoteimage.asp?',
                img = $el.parent().find('img'),
                params = {
                    image: img.attr('data-mycapture-sm-src'),
                    notes: img.attr('data-mycapture-src'),
                    caption: $.trim($el.find('.js-caption').text()),
                    credit: $.trim($el.find('.credit').text()),
                    backtext: 'Back to Site',
                    backurl: location.href,
                    pricingsheetid: Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'MYCAPTURE', 'PRICING_SHEET_ID') || ''
                };

            return 'http://' + hostSegment + mycaptureDomainAndPath + $.param(params);
        },

        getHost: function() {
            return Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'MYCAPTURE', 'SITE_OVERRIDE') || Utils.getNested(window.site_vars, 'site_key');
        }
    });
    return MyCapture;
});

