 /**
 * @fileoverview Interactive Templates ad view.
 * @author jlcross@gannett.com (Jon Cross)
 */
define('modules/interactives/interactive-ads',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'utils',
    'baseview',
    'adPosition'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    Utils,
    BaseView,
    AdPosition
 ) {
    /**
     * View class.
     */
    var interactiveAds = BaseView.extend({
        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {
            // Call base class initialize to make options available to class.
            BaseView.prototype.initialize.call(this, options);

            if (this.$el.length > 0) {
                this.setupAds();
            }
        },

        /**
         * Sets up sponsorship ads when enabled.
         */
        setupAds: function() {
            var data = this.$el.data(),
                targeting = {
                    title: data.seoTitle,
                    series: data.series,
                    sitePage: 'usat/' + (data.ssts || '').replace(/\/\/*$/, ''),
                    topic: data.topic
                };

            // Set up sponsorship ad.
            this.subviews.sponsorshipAd = new AdPosition({
                el: this.$el,
                adPlacement: 'sponsor_logo/interactive',
                adSizes: ['sponsor_logo'],
                targeting: targeting
            });
        }

    });

    /**
     * Return view class.
     */
    return interactiveAds;
});
 
