define('utils',[
    'jquery',
    'underscore'
],
function(
    $,
    _
){
    'use strict';
    /**
     * Global Utils to be used for any globally required
     * references -- cached DOM lookups, global properties, utility functions etc.
     * @exports utils
     * @author Erik Kallevig <ekallevig@gannett.com>
     */
    var Utils = function() {
        this.storage = {};
        this.set('DEBUG', true);

        // Set global properties/lookups.
        this.set('document', $(document));
        this.set('win', $(window));
        this.set('body', $('body'));
        this.set('scrollEl', $('html,body'));
        this.set('doc', $(document));
    };
    Utils.prototype = {
        /**
         * Sets a value into the Utils key/value storage
         * @param {String} key
         * @param {*} value
         */
        set: function(key, value) {
            this.storage[key] = value;
        },

        /**
         * Gets a value out of the Utils key/value storage
         * @param {String} key
         * @returns {*}
         */
        get: function(key) {
            return this.storage[key];
        },

        /**
         * the page's url, without any query string or hashtags
         * this should always be valid (or the app is broken)
         */
        getPageUrl: function() {
            return this.getOrigin() + window.location.pathname;
        },

        getOrigin: function() {
            return window.location.protocol + "//" + window.location.host;
        },

        getPathname: function() {
            return window.location.pathname;
        },

        /**
         * Gets the current scroll position, supports caching for multiple calls within one animation frame
         * @returns {Number}
         */
        getScrollPosition: function() {
            return window.pageYOffset || document.documentElement.scrollTop;
        },

        parsePageInfo: function(el) {
            return JSON.parse(el.find('.pageinfo').eq(0).html() || '{}');
        },

        _loadedScripts: {},
        /**
         * Load externally hosted script (SDK, library, etc) on-demand.
         * @param {String} scriptUrl URL of script file.
         * @param {String} symbol Unique window property from external
         *     script to validate the script has been successfully loaded.
         * @param {Function} [callback] Function to call when script has loaded.
         * @return {Deferred} will resolve or reject depending on whether the script succeeds or fails to load
         */
        loadScript: function(scriptUrl, symbol, callback) {
            this._loadedScripts[symbol] = this._loadedScripts[symbol] || this._requireScript(scriptUrl, symbol);
            return this._loadedScripts[symbol].done(callback);
        },
        _requireScript: function(scriptUrl, symbol) {
            return $.Deferred(function(defer) {
                require([scriptUrl], function() {
                    var obj = window[symbol];
                    if (!obj) {
                        defer.reject('Missing symbol: ' + symbol + ' from script: ' + scriptUrl);
                    } else {
                        defer.resolve(obj);
                    }
                }, defer.reject);
            }).fail(function(err) {
                console.error(err);
            });
        },
        /**
         * Open a centered popup window.
         * @param {String} url URL to load in the popup.
         * @param {Number} [width=600] Width of popup.
         * @param {Number} [height=400] Height of popup.
         */
        openPopup: function(url, width, height) {
            width = width || 600;
            height = height || 400;
            var winCoords = this.popupCoords(width, height);
            window.open(
                url,
                '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' +
                'height=' + height + ',width=' + width + ',top=' + winCoords.top +
                ',left=' + winCoords.left
            );
        },
        isValidUrl: function(href) {
            /*jshint scripturl:true*/
            if (!href) {
                return false;
            } else if (href.indexOf('mailto:') !== -1) {
                return true;
            } else if (href.indexOf('javascript:') !== -1) {
                return false;
            } else if (href[0] === '#') {
                if (href.length === 1) {
                    return false;
                }
            } else if (href.indexOf('../') !== -1 || (href[0] !== '/' && href.indexOf('://') === -1)) {
                console.error('Attempting to load a relative url, bad code monkey! (' + href + ')');
                return false;
            }
            return true;
        },
        /**
         * mirroring the functionality of python's get_nested() function which will deep dive into an object with
         * crashing on null values
         * @param {Object} obj - object to retrieve a nested value from
         * @param {...String|Number} args - comma seperated list of keys to retrieve
         */
        getNested: function(obj, args) {
            if (obj) {
                args = Array.prototype.slice.call(arguments, 1);
                return _.reduce(args, function(obj, arg) {
                    return obj && obj[arg];
                }, obj);
            }
        },

        flag: function(flagName) {
            return this.getNested(window.site_vars, 'flags', flagName);
        },

        /**
         * Reads from the window object site_vars.sections which should contain the site's home sections | separated.
         * @returns {Array}
         */
        getSiteSections: function() {
            var sectionStr = this.getNested(window.site_vars, 'sections') || '';
            if (sectionStr) {
                return sectionStr.split('|');
            } else {
                return [];
            }
        },

        /**
         * Calculate and return coordinates for centering a popup window to
         *     the outerWidth/outerHeight of the browser window.
         * @param {Number} w Width of popup window.
         * @param {Number} h Height of popup window.
         */
        popupCoords: function(w, h) {
            var wLeft = window.screenLeft ? window.screenLeft : window.screenX;
            var wTop = window.screenTop ? window.screenTop : window.screenY;
            var wWidth = window.outerWidth ? window.outerWidth : document.documentElement.clientWidth;
            var wHeight = window.outerHeight ? window.outerHeight : document.documentElement.clientHeight;

            // Subtract 25 pixels to top to approximately compensate for chrome
            // on top of popup window, since we can't calculate that before
            // opening it.
            return {
                left: wLeft + (wWidth / 2) - (w / 2),
                top: wTop + (wHeight / 2) - (h / 2) - 25
            };
        },

        /**
         * Format number (or string version of number) to use commas.
         * 40532 -> 40,532
         *
         * @param {Number} nStr Number to format
         */
        addCommas: function(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },

        passiveSupported: function() {
            var supportsPassiveOption = false;
            try {
              var opts = Object.defineProperty({}, 'passive', {
                get: function() {
                  supportsPassiveOption = true;
                }
              });
              window.addEventListener('test', null, opts);
            } catch (e) {}
            return supportsPassiveOption;
        },

        /**
         * Lazy load an image. Moves data-src attribute to src.
         * @param {jQuery} $img jQuery object e.g. $('img')
         * @param {String} [dataSrcAttr="data-src"] optional parameter to specify which dataSrcAttr to retrieve
         * @param {Boolean} [keepAttr=false] optional parameter that will keep the attribute instead of deleting it
         * @param {Function} [onError] optional function to call when an image fails to load
         * @param {Function} [onSuccess] optional function to call when an image succeeds to load
         * @returns {Boolean} true if a lazy image was found and triggered to load
         */
        lazyLoadImage: function($img, dataSrcAttr, keepAttr, onError, onSuccess) {
            var hasLazyImage = false,
                dataSrcsetAttr = 'data-srcset';
                dataSrcAttr = dataSrcAttr || 'data-src';
            $img.each(_.bind(function(index, el) {
                var $el = $(el),
                    dataSrc = $el.attr(dataSrcAttr),
                    src = $el.attr('src'),
                    dataSrcset = $el.attr(dataSrcsetAttr),
                    srcset = $el.attr('srcset');
                if (dataSrc && src !== dataSrc) {
                    this._attachHandler('error', $el, onError);
                    this._attachHandler('load', $el, onSuccess);
                    $el.attr('src', dataSrc);
                    if (dataSrcset && srcset !== dataSrcset) {
                        $el.attr('srcset', dataSrcset);
                    }
                    if (!keepAttr) {
                        $el.removeAttr(dataSrcAttr);
                        if (dataSrcset) {
                            $el.removeAttr(dataSrcsetAttr);
                        }
                    }
                    hasLazyImage = true;
                }
            }, this));
            return hasLazyImage;
        },

        /**
         * Lazy load an iFrame. Moves data-src attribute to src.
         * @param {jQuery} $iFrame jQuery object e.g. $('iframe')
         * @param {String} [dataSrcAttr="data-src"] optional parameter to specify which dataSrcAttr to retrieve
         * @param {Boolean} [keepAttr=false] optional parameter that will keep the attribute instead of deleting it
         * @param {Function} [onError] optional function to call when an image fails to load
         * @param {Function} [onSuccess] optional function to call when an image succeeds to load
         * @returns {Boolean} true if a lazy image was found and triggered to load
         */
        lazyLoadIframe: function($iFrame, dataSrcAttr, keepAttr, onError, onSuccess) {
            var hasLazyIframe = false;
            dataSrcAttr = dataSrcAttr || 'data-src';
            $iFrame.each(_.bind(function(index, el) {
                var $el = $(el),
                    dataSrc = $el.attr(dataSrcAttr),
                    src = $el.attr('src');
                if (dataSrc && src !== dataSrc) {
                    this._attachHandler('error', $el, onError);
                    this._attachHandler('load', $el, onSuccess);
                    $el.attr('src', dataSrc);
                    if (!keepAttr) {
                        $el.removeAttr(dataSrcAttr);
                    }
                    hasLazyIframe = true;
                }
            }, this));
            return hasLazyIframe;
        },

        _attachHandler: function(type, $el, callback) {
            var fn = function() {
                $el.off(type, fn);
                if (type === 'error') {
                    $el.hide();
                }
                if (callback) {
                    callback($el);
                }
            };
            $el.on(type, fn);
        },

        /**
         * get section path function
         * returns the section name without the slash
         * @param {String} [path] story folder path.
         */
        getSectionPath: function(path) {
            if (path) {
                path = this._stripQueryParams(path);
            }
            if (path && path !== '/') {
                if (path[0] === '/') {
                    path = path.substring(1);
                }
                var slashIndex = path.indexOf('/');
                if (slashIndex === -1) {
                    return path;
                } else {
                    return path.substring(0, slashIndex);
                }
            } else {
                return 'home';
            }
        },

        /**
         * gets the sub section name from the path
         * @param {String} [path] story folder path.
         */
        getSubSection: function(path) {
            if (path) {
                path = this._stripQueryParams(path);
                if (path[path.length - 1] !== '/') {
                    path = path + '/';
                }
                var startIndex = path.indexOf('/', 1),
                    endIndex = path.indexOf('/', startIndex + 1);
                if (startIndex !== -1 && endIndex !== -1) {
                    return path.substring(startIndex + 1, endIndex);
                }
            }
            return '';
        },

        _stripQueryParams: function(path) {
            var query = path.indexOf('?');
            if (query !== -1) {
                path = path.substring(0, query);
            }
            return path;
        },

        /**
         * Lazy load error function
         * hides the image that threw the error, optionally calls an error function
         * @param {Function} [onError] optional function to call when an image fails to load.
         */
        lazyLoadError: function(onError) {
            $(this).hide();
            if (onError) {
                onError($(this));
            }
        },

        /**
         * Given a url to require, will load once and immediately clean up
         * @return {Deferred}
         */
        requireSingleUseScript: function(script) {
            return $.Deferred(function(defer) {
                require([script], function(view) {
                    defer.resolveWith(this, [view]);
                }, function(err) {
                    console.error('failed loading scripts', err);
                    defer.reject(err);
                });
            }).always(_.bind(function() {
                // cleanup
                this.removeRequireModule(script);
            }, this)).promise();
        },

        removeRequireModule: function(url) {
            require.undef(url);
            $('script[data-requiremodule="' + url + '"]').remove();
        },

        getUrlParam: function(key) {
            var s = decodeURI((new RegExp(key + '=' + '(.+?)(&|$)').exec(window.location.search) || [0, false])[1]);
            if (s === 'false') {
                return false;
            } else if (s === 'true') {
                return true;
            }
            return s;
        },

        getSessionData: function(key, defaultValue) {
            var value = sessionStorage[key];
            return (value && JSON.parse(value)) || defaultValue;
        },

        setSessionData: function(key, value) {
            if (!_.isString(value)) {
                value = JSON.stringify(value);
            }
            sessionStorage.setItem(key, value);
        },

        clearSessionData: function(key) {
            sessionStorage.removeItem(key);
        },

        /**
         * Answers the question of whether or not the document is hidden (tabbed away, backgrounded, etc
         * @returns {Boolean}
         */
        isDocumentHidden: function() {
            return document.hidden || document.webkitHidden || document.mozHidden || document.msHidden || document.oHidden;
        },

        /**
         * Appends params to the passed-in url.
         * @param {String} url
         * @param {Object} params
         * @param {String} [prefix]
         * @returns {String}
         */
        appendHashParams: function(url, params, prefix) {
            if (params && typeof url === "string") {
                var splitUrl = url.split("#"),
                    paramsStr = "",
                    i = 0;
                _.each(params, function(val, key) {
                    paramsStr += ((i > 0) ? "&" : "") + ((prefix) ? prefix + "_" : "") + key + "=" + val;
                    i++;
                });
                url = splitUrl[0] + "#" + paramsStr + ((splitUrl[1]) ? "&" + splitUrl[1] : "");
            }
            return url;
        },

        /**
         * Removes params that begin with the provided prefix.
         * @param {String} hash - The hash string to be processed.
         * @param {String} prefix - The prefix of the params that should be removed from the passed in hash string.
         * @returns {String}
         */
        removeHashParamsByPrefix: function(hash, prefix) {
            if (typeof hash === "string" && typeof prefix === "string") {
                var hashArr = ((hash[0] === '#') ? hash.substr(1) : hash).split('&');
                var i = hashArr.length;
                while (i--) {
                    if (hashArr[i].substring(0, prefix.length) === prefix) {
                        hashArr.splice(i, 1);
                    }
                }
                return "#" + hashArr.join('&');
            }
            return hash;
        },

        /**
         * Removes params from the current url hash that begin with the provided prefix.
         * @param {String} prefix
         */
        removeHashParamsFromCurrentUrl: function(prefix) {
            if (window.location.hash.indexOf(prefix)) {
                var updatedHash = this.removeHashParamsByPrefix(window.location.hash, prefix);
                if (updatedHash.length > 1) {
                    window.location.hash = updatedHash;
                } else if (window.history && window.history.replaceState) {
                    history.replaceState('', document.title, window.location.pathname + window.location.search);
                }
            }
        },

        /**
         * Checks to see if an element is either fully or partially positioned within the viewport or positioned
         * outside of the viewport.
         * @param {Object} el
         * @returns {String}
         */
        isElementInViewport: function(el) {
            if (el instanceof $) el = el[0];
            var bounds = el.getBoundingClientRect(),
                viewportHeight = (window.innerHeight || document.documentElement.clientHeight),
                viewportWidth = (window.innerWidth || document.documentElement.clientWidth),
                inView = (bounds.top <= viewportHeight) && ((bounds.top + bounds.height) >= 0) &&
                         (bounds.left <= viewportWidth) && ((bounds.left + bounds.width) >= 0);
            return ((bounds.left >= 0) && (bounds.top >= 0) && ((bounds.left + bounds.width) <= viewportWidth) &&
                    ((bounds.top + bounds.height) <= viewportHeight)) ? "full" : (inView) ? "partial" : "outside";
        },

        /**
         * Checks the pageInfo to see if the page has an autoplay video on it
         * @param  {Object}  pageInfo PageInfo object for currect app
         * @return {Boolean}
         */
        hasAutoplayVideo: function(pageInfo) {
            return (pageInfo.priority_asset === 'video' || pageInfo.basePageType === 'video-asset' || pageInfo['web-template'] === 'SmallPlayerForPriorityVideo' || _.where(pageInfo.js_modules, {oembedprovider: 'Inform, Inc.'}).length);
        },

        /**
         * Scrubs a string of any emails
         * @param  {String} str String to be checked for email addresses
         * @return {String}     String clean of email addresses
         */
        scrubEmail: function(str) {
            var re = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi;
            return String(str).replace(re, '');
        },

        /**
         * Simplified email check
         * @param  {String} str String to be checked for the presence of an @ sign
         * @return {Boolean}
         */
        checkForEmail: function(str) {
            var re = /@/gi;
            return (str.match(re)) ? true : false;
        },

        /**
         * Logs a page action to New Relic, queryable through Insights
         * https://docs.newrelic.com/docs/insights
         * @param {String} actionName to associate action to
         * @param {Object} action the event/action to log
         */
        logAction: function(actionName, action) {
            try {
                window.newrelic.addPageAction(actionName, action);
            } catch (e) {}
        }

    };
    return new Utils();
});

