/**
* @fileoverview JS functions for sports scores page.
* @author tdljackson@usatoday.com [Tony Jackson]
*/
define('modules/sports/scorespage',[
    'jquery', 
    'underscore', 
    'backbone',
    'pubsub',
    'state',
    'admanager',
    'baseview',
    'chosen',
    'animatecolors'
],
function(
    $, 
    _, 
    Backbone,
    PubSub,
    StateManager,
    AdManager,
    BaseView
)
    {
        /**
         * View class.
         */
        var ScoresPageView = BaseView.extend({

            el: '#scorespage',
            
            /**
             * Constructor method.
             */
            initialize: function(options) {
                $.extend(options, {
                    refreshInterval: 30000,
                    adInterval: 1000
                }, options);

                BaseView.prototype.initialize.call(this, options);

                _.bindAll(this, 'onScoresUpdate', 'scoresPageRefresher');

                // Set the initial variable to true so the refresh will start
                this.continueRefresh = true;

                //Refresh scores page in a defined interval
                this.refreshID = setTimeout(this.scoresPageRefresher, this.options.refreshInterval);
                
                //Sets a time the calls a method that checks to see if there is a 300x250
                this.adTimer = setTimeout(this.isAdStatsLoaded, this.options.adInterval);
            },
            
            
            //sets clear fix divs if there is a 300x250 or not.
            isAdStatsLoaded: function() {
                AdManager.getActiveSlotStatistics().done(function(info) {
                    $("#scorespage > .clearfix").remove();
                    if ($('#scorespage > .poster').css('display') === 'none') {
                        $("#scorespage > .game-even").after('<div class="clearfix"></div>');
                    } else {
                        $("#scorespage > .game:nth-child(2n)").after('<div class="clearfix"></div>');
                    }
                 });
            },

            /**
             * This function initiates the scores page refresh by fetching data
             * and letting the callback function determine whether to continue calling
             * the refresh routine
            */
            scoresPageRefresher: function() {
                if (this.continueRefresh) {
                    var path = window.location.pathname;
                    this.fetchData(path, this.onScoresUpdate);
                    this.isAdStatsLoaded();
                }
            },

             /**
             * The function stops the refresh routine
             */
            stopRefresh: function() {
                this.continueRefresh = false;
                if (this.refreshID) {
                    clearTimeout(this.refreshID);
                }
            },

            /**
             * Retrieves the scores for a specified league via an ajax request.
             * @param {String} url to load data for.
             * @param {Function} callback the callback method.
             */
            fetchData: function(url, callback) {
                StateManager.fetchHtml(url)
                    .done(callback)
                    .fail(function(){
                        console.log("ERROR: Scores Page AJAX load failed!");
                    });
            },

             /**
             * Handler for when score data is loaded for refresh.
             * @param {String} markup html markup retrived from ajax request.
             */
            onScoresUpdate: function(markup) {
                if (!markup) {
                    return;
                }
                var $gamesContainer = this.$el.closest('#scorespage');
                var $newGames = $(markup).find('#scorespage');

                $gamesContainer.find('.game').each(_.bind(function(i, game){
                    var $game = $(game);
                    var eventKey = $game.attr('id');
                    var $newGame = $newGames.find("#" + eventKey);
                    if ($newGame.length) {
                        var $newGameScores = $newGame.find('.total');
                        var $oldGameScores = $game.find('.total');
                        // For each set of scores, check to see if there has been a change and add the highlight
                        // css class to the score container that has changed
                        this.markChangedScores($newGameScores, $oldGameScores);

                        $game.html($newGame.html());
                    }
                }, this));

                this.addScoreHighlight($gamesContainer.find('.scoreChangeFont'), "rgb(164, 23, 18)", 1500);

                // Wait the specified time interval and then intiate the next refresh routine
                this.refreshID = setTimeout(this.scoresPageRefresher, this.options.refreshInterval);
            },

            /**
             * For an individal game, check home and away scores to see if there has been a change.
             * Adds a class if the score has changed.
             * @param {Object} $newScores jQuery object list
             * @param {Object} $oldScores jQuery object list
             */
            markChangedScores: function($newScores, $oldScores) {
                $newScores.each(_.bind(function(index, item){
                    var $item = $(item);
                    if ($item.html() !== $oldScores.eq(index).html()) {
                        $item.addClass("scoreChangeFont");
                    }
                }, this));
            },

            /**
             * This function addes a highlight animation to JQuery objects that are passed to it
             * This animation runs through two cycles and last the duration specified by the passed parameter 
             *
             * @param {jQuery} targetEL elements to add the hightlight to
             * @param {String} highlightColor Color to hightlight the element with
             * @param {Number} duration Time in milisecond that the hightlight animation should run
             */
            addScoreHighlight: function(targetEL, highlightColor, duration) {
                var highlight = highlightColor || "#FFFF9C";
                var animateMs = duration || 1000;
                var originalColor = targetEL.css("color");
                targetEL.stop()
                    .css("color", highlight)
                    .animate({color: originalColor}, animateMs)
                    .animate({color: highlight}, animateMs)
                    .animate({color: originalColor}, animateMs);
            },

            destroy: function() {
                // Stops refresh routine when the page is unloaded
                this.stopRefresh();

                // Call base call destroy
                BaseView.prototype.destroy.call(this);
            }
        });

        return ScoresPageView;
    }
);
