/* global define */
define('api/analytics',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'fwinfo',
    'analytics/settealium',
    'third_party_integrations/pong/pong-utils',
    'cookie'
],
function(
    $,
    _,
    PubSub,
    Utils,
    FwInfo
){
    'use strict';
    /**
     * @exports analytics
     */
    var Analytics = function() {
        _.bindAll(this, '_onActivePageInfoSet', '_onAnalyticsClick');
        this.pubSub = {
            'page:load': this.trackPageView,
            'site:refresh': this._onSiteRefresh,
            'activePageInfo:set': this._onActivePageInfoSet,
            'analytics:pageload': this.trackPageView,
            'analytics:event': this.trackCustomEvent
        };
        this.providersEnabled = (window.site_vars) ? Utils.getNested(window.site_vars, 'analytics') || [] : ["tealium"];
        this.start();
    };
    Analytics.prototype = {
        start: function() {
            PubSub.attach(this.pubSub, this);
        },
        stop: function() {
            PubSub.unattach(this.pubSub, this);
        },
        /**
         *
         * @param {Object} pageInfo
         * @private
         */
        _onActivePageInfoSet: function(pageInfo) {
            pageInfo.prevPvParams = this.getPrevPvParamsFromCookie() || {};
            this.removePrevPvParamsCookie();
            pageInfo.prevPageInfo = FwInfo.getAndClearPersistentSessionValue('prevPageInfo') || {};
            FwInfo.setPersistentSessionValue('prevPageInfo', _.omit(pageInfo, [
                // to help improve performance, we avoid caching large, unused objects
                'prevPageInfo', 'prevPvParams', 'js_modules', 'abtests', 'asset_collection', 'share_imgs'
            ]));
            if (!pageInfo.noinitialanalytics) {
                pageInfo.refreshed = FwInfo.getandclearsessionvalue('refreshed');
            }
            return pageInfo;
        },
        _onSiteRefresh: function(refreshInfo) {
            FwInfo.setsessionvalue('refreshed', refreshInfo);
        },
        trackEvent: function(trackLabel, destinationUrl) {
            PubSub.trigger('track', {
                label: trackLabel,
                url: destinationUrl
            });
        },
        /**
         * Triggers a pubSub event for the specified provider(s), passing the pageInfo object (with defaults) along
         * with any page view parameters specific to that provider.
         * @param {Object} pageInfo
         * @param {String|Array.<String>} [providers] - Provider(s) to trigger page view events for.  Defaults to
         * providers that are enabled in the site's config (if available).
         * @param {Boolean} [bypassSiteConfig] - By default we only fire page view events for analytics providers that
         *        are enabled in the site config.  This will bypass that check.
         * @returns {Object} pageInfo - Updated pageInfo object with defaults and additional values attached.
         */
        trackPageView: function(pageInfo, providers, bypassSiteConfig) {
            pageInfo = _.chain({
                awspath: "undefined",
                ssts: "bugpages",
                cst: "undefined",
                contenttype: pageInfo.contentType || "undefined"
            }).extend(pageInfo).omit('contentType').value();
            providers = this._getAnalyticsProviders(providers, bypassSiteConfig);
            _.each(providers, function (provider) {
                PubSub.trigger('analytics:pageload:' + provider, pageInfo);
            }, this);
            return pageInfo;
        },
        /**
         * Triggers a pubSub event for the specified provider(s), passing along the provided set of parameters.
         * @param {String|Array.<String>} providers - Provider(s) that the event should be triggered for.
         * @param {Object.<String|Number>} params - key/value pairs to be passed along to the specified event.
         * @param {Boolean} [bypassSiteConfig] - By default we only fire page view events for analytics providers that
         *        are enabled in the site config.  This will bypass that check.
         */
        trackCustomEvent: function(providers, params, bypassSiteConfig) {
            providers = this._getAnalyticsProviders(providers, bypassSiteConfig);
            _.each(providers, function(provider) {
                PubSub.trigger('analytics:event:' + provider, params);
            });
        },
        /**
         * Adds click events to a link for each of the providers in the given list, passing along the provided params
         * in the event's data object.
         * @param {Object} link - Link object that the analytics data and markup should be appended to.
         * @param {String|Array.<String>} providers - Provider(s) that the event should be triggered for.
         * @param {Object} [params] - To be used in page view tracking.
         * @param {Boolean} [bypassSiteConfig] - By default we only fire page view events for analytics providers that
         *        are enabled in the site config.  This will bypass that check.
         */
        addAnalyticsToLink: function(link, providers, params, bypassSiteConfig) {
            params = params || {};
            var $link = $(link),
                linkAnalytics = $link.data('analytics') || {};
            providers = this._getAnalyticsProviders(providers, bypassSiteConfig);
            // Check to see if the link already has analytics attached and if so merge them.
            if (linkAnalytics.providers) {
                providers = _.union(providers, linkAnalytics.providers);
            }
            $link
                .data('analytics', {
                    providers: providers,
                    params: _.extend(params, linkAnalytics.params)
                })
                .off('click.analytics')
                .on('click.analytics', this._onAnalyticsClick);
        },
        /**
         * Prepares additional information to be used in page view tracking by either writing the params to a cookie
         * (internal urls) or by attaching the params to the url's hash (external urls).  Also fires a PubSub trigger
         * specific to the associated provider.
         * @param {Event} e - Click event.
         * @private
         */
        _onAnalyticsClick: function(e) {
            var $link = $(e.currentTarget),
                linkAnalytics = $link.data('analytics') || {};
                if ($link.attr('href').charAt(0) === "/") {
                    // For internal URLs we store the params in a cookie to be processed on the subsequent page view.
                    this.setPrevPvParamsCookie(linkAnalytics.params);
                } else {
                    // For external urls we process the params at the destination so we pass them along in the url.
                    $link.attr('href', Utils.appendHashParams(
                        $link.attr('href'),
                        linkAnalytics.params
                    ));
                }
                _.each(linkAnalytics.providers, function(provider) {
                    PubSub.trigger('analytics:click:' + provider, e, linkAnalytics.params);
                }, this);
        },
        /**
         * @param {Object} params
         */
        setPrevPvParamsCookie: function(params) {
            var prevPvParams = this.getPrevPvParamsFromCookie();
            if (prevPvParams) {
                params = _.extend(prevPvParams, params);
            }
            $.cookie('pv_params', JSON.stringify(params), { expires: 0.003, path: '/' });
        },
        getPrevPvParamsFromCookie: function() {
            try {
                return JSON.parse($.cookie('pv_params'));
            } catch(e) {
                // no op
            }
        },
        removePrevPvParamsCookie: function() {
            $.removeCookie('pv_params', { path: '/' });
        },
        /**
         * Filters list of given providers based on whether they are enabled in the site's config.  If no providers
         * are passed, the list of enabled providers from the site config is used.
         * @param {String|Array.<String>} [providers] - Provider(s) to trigger page view events for.  Defaults to
         * providers that are enabled in the site's config (if available).
         * @param {Boolean} [bypassSiteConfig] - By default we only fire page view events for analytics providers that
         * @returns {Array}
         * @private
         */
        _getAnalyticsProviders: function(providers, bypassSiteConfig) {
            if (providers) {
                if (_.isString(providers)) {
                    providers = [providers];
                }
                if (!bypassSiteConfig) {
                    providers = _.filter(providers, function (provider) {
                        return _.contains(this.providersEnabled, provider);
                    }, this);
                }
            }
            return providers || this.providersEnabled;
        }
    };
    return new Analytics();
});

