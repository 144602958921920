define('modules/videos/pluto/pluto-title-bar',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {
    'use strict';
    var PlutoTitleBar = BaseView.extend(
        {
            events: {
                'click .js-ptb-close': 'close'
            },
            initialize: function(options){
                options = $.extend({
                    titleLength: 42,
                    onClose: null,
                    title: '',
                    adText: 'Sponsor Message'
                }, options);
                BaseView.prototype.initialize.call(this, options);
                this.setTitle(options.title);
            },

            close: function() {
                this.collapse();
                if (this.options.onClose) this.options.onClose();
            },

            collapse: function() {
                this.$el.css('top', '0px');
            },

            setEl: function(el) {
                this.setElement(el);
            },

            setTitle: function(text) {
              this.$$('.pluto-title-bar-text').text(this.truncate(text));
            },

            /**
             * 25px height + 1px for bottom border
             */
            show: function() {
                this.$el.stop().animate({'top': '-26px'}, 200);
            },

            showAdText: function() {
                this.setTitle(this.options.adText);
            },

            truncate: function(text) {
                return text.length > this.options.titleLength ? text.substring(0, this.options.titleLength) + '...' : text;
            }
        }
    );

    return PlutoTitleBar;
});
