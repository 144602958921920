define('modules/videos/video-transcript',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'pubsub'
],
    function ($, _, BaseView, StateManager, PubSub) {
        "use strict";
        var VideoTranscript = BaseView.extend(

            {
                events: {
                    'click .video-transcript-caption': 'onClickVideoSeek',
                    'click .video-transcript-keywordresult': 'onClickkeyword',
                    'keydown .video-search-input': 'onKeyDown',
                    'click .ui-ramp-transcriptplus': 'onClickTranscriptSlide',
                    'click .toggle-transcript': 'onClickTranscriptSlide',
                    'click .video-search-btn': 'onClickVideoSearch',
                    'click .video-search-close-btn': 'onClickSearchCloseBtn',
                    'click .transcript-navigate-next-active': 'nextslide',
                    'click .transcript-navigate-prev-active': 'prevslide',
                    'mouseenter .video-full-transcript-text': 'toggleautoscroll',
                    'mouseleave .video-full-transcript-text': 'toggleautoscroll'
                },

                initialize: function (options) {
                    var baseViewOptions = $.extend(true, {animations: { useCSSTransitions: false}}, options);
                    BaseView.prototype.initialize.call(this, baseViewOptions);
                    this.currentplayer = this.options.player || {};
                    this.$viewport = this.$('.keywords-viewport');
                    this.$searchViewport = this.$('.search-viewport');
                    this.$keywordWindow = this.$('.video-keywords-result');
                    this.$searchWindow = this.$('.video-search-result');
                    this.initializeTranscript();
                    this.$searchinput = this.$('.video-search-input');
                    this.$keywords = this.$('.video-transcript-keywords');
                    this.$search = this.$('.video-transcript-search');
                    this.$searchresults = this.$('.video-search-display');
                    this.$searchCancelBtn = this.$('.video-search-close-btn');
                    this.$search.hide();
                    this._currentSlideCount = 1;
                    this.autoscroll = true;
                },

                /**
                 * Searches for a word in the transcript and returns the cuepoint.
                 *  @param {Event} e click event
                */
                onClickVideoSearch: function (e) {
                    this.$currentcaption.blur();
                    var cuepoint,cuepoint_display;
                    // call function to clear out keyword display view OnclickSearchEnter();
                    this.$searchresults.empty();
                    this.$searchWindow.empty();
                    this.clearActiveArrows();
                    var value = this.$searchinput.val().toLowerCase();
                    var regex = new RegExp("\\b" + value + "\\b");
                    var valuearr = value.split(' ');
                    if(value.length !== 0) {
                        var result = this.$videotranscriptmain.filter(function() {
                                    return $(this).text().toLowerCase().search(regex) > -1;
                                 });
                        // Loop one more time only if there are two words to find matching words which are outside the group.
                        if(valuearr.length == 2 ){
                            var result_anothergroup = this.$videotranscriptmain.filter(function() {
                                var $this = $(this);
                                // remove the empty space and create an array of words
                                var textarr = $.trim($this.text().toLowerCase()).split(' ');
                                // is last word matches with the first word of the search words
                                if (textarr[textarr.length-1] == valuearr[0]){
                                    var nextelement_textarr = $.trim($this.next().text().toLowerCase()).split(' ');
                                    // is first word matches with the last word of the search words
                                    return nextelement_textarr[0] == valuearr[1];
                                } else {
                                    return false;
                                    }
                             });
                            if (result_anothergroup.length >0 ){
                                result = result.add(result_anothergroup);
                            }
                        }
                        // Even if result is zero if user clicked the keywords get the cue points from keywords
                        if(result.length === 0 && typeof e !== 'undefined' && e.currentTarget.getAttribute('data-startoffset') ){
                            result = result.add($(e.currentTarget));
                        }
                        this.trackclicks(this.$searchinput.data().track);
                        this.$keywords.hide();
                        this.$search.show();
                        this.$searchOpen = this.$('.transcript-search-input');
                        this.$searchOpen.removeClass('video-search-open-button');
                        this.$searchCancelBtn.addClass('video-search-close-btn-visible');
                        this.$searchresults.addClass('show-results');
                        if (result.length !==0) {
                            this.$searchresults.append("<span class='video-search-term'>"+value+"</span>"+' '+"found at");
                            for(var x=0;x<result.length; x++){
                                cuepoint = result[x].getAttribute('data-startoffset');
                                cuepoint_display = result[x].getAttribute('data-startoffset_display');
                                this.$searchWindow.append("<span class='video-transcript-caption transcript-search-result' data-track='searchclick' data-startoffset="+cuepoint+">"+cuepoint_display+"</span>");
                            }
                        }
                        else {
                            this.$searchresults.append("<span class='no-results'>"+"No results found for"+' '+"<span class='video-search-term'>"+'"'+value+'"'+"</span></span>");
                        }
                    }
                    this.updateSlideCount();
                    this.loadActiveArrows();
                    this._currentSlideCount = 1;
                },

                /*
                 * Handler for typing
                 * @param {Event} e Event
                 */

                onKeyDown: function(e) {
                    var keycode = (e.keyCode ? e.keyCode : e.which);
                    // enter key
                    if (keycode === 13){
                        this.$searchresults.empty();
                        this.$searchWindow.empty();
                        this.onClickVideoSearch(e);

                    }
                },

                /**
                 * Closes the search view and switches to keyword display
                 *  @param {Event} e click event
                 */
                onClickSearchCloseBtn: function(e) {
                    this.$searchCancelBtn.removeClass('video-search-close-btn-visible');
                    this.$searchOpen.addClass('video-search-open-button');
                    this.$searchresults.removeClass('show-results');
                    this.$searchresults.empty();
                    this.$searchWindow.empty();
                    this.clearActiveArrows();
                    this.$searchWindow.css('left', 'auto');
                    this.$keywordWindow.css('left', 'auto');
                    this.$searchinput.val('');
                    this.$search.hide();
                    this.$keywords.show();
                    this.updateSlideCount();
                    this.loadActiveArrows();
                    this._currentSlideCount = 1;
                },

                /**
                 * Toggles the search view
                 *  @param {boolean} flag value recieved from the player
                 */
                togglesearch:function(flag){
                    var $control = this.$('.video-full-transcript-control');
                    if (flag && $control.hasClass('searchcontroloff')){
                        $control.removeClass('searchcontroloff');
                        this.trackclicks('open-search');
                            this.updateSlideCount();
                            this.loadActiveArrows();
                    }
                    else{
                        $control.addClass('searchcontroloff');
                        this.trackclicks('close-search');
                    }

                },

                isTranscriptAvailable: function(){
                    return this.$videotranscriptmain.length>0 ;
                },

                /**
                 * Initializes all the views
                 */

                initializeTranscript: function(){
                    this.$videotranscriptmain = this.$('.video-transcript-caption');
                    this.$currentcaption = $(this.$videotranscriptmain[0]);
                    this.clearHighlight(this.$currentcaption);
                    this.addHighlight(this.$currentcaption);
                    if (!this.options.player.hidesearchcontrol && this.options.player.flashsearchcontrolOn) {
                        this.updateSlideCount();
                        this.loadActiveArrows();
                    }
                },

                loadActiveArrows: function() {
                    if ((this.$('.video-transcript-keywords')).is(':visible')) {
                        if (!this.$('.video-transcript-navigate-next').hasClass('transcript-navigate-next-active') && this.$keywordWindow.width() > this.$viewport.width()) {
                            this.$('.video-transcript-navigate-next').addClass("transcript-navigate-next-active");
                        }
                    } else if ((this.$search).is(':visible')) {
                        if (!this.$('.video-transcript-navigate-next').hasClass('transcript-navigate-next-active') && this.$searchWindow.width() > this.$searchViewport.width()) {
                            this.$('.video-transcript-navigate-next').addClass("transcript-navigate-next-active");
                        }
                    }
                },

                clearActiveArrows: function() {
                    this.$('.video-transcript-navigate-next').removeClass('transcript-navigate-next-active');
                    this.$('.video-transcript-navigate-prev').removeClass('transcript-navigate-prev-active');
                },

                /**
                 * Takes user to a particular cue point
                */
                onClickVideoSeek: function(e) {
                    var $el = $(e.currentTarget),
                        $transcriptPos = $el.data();
                   // Use brightcove video object to take user to particular point(seek method)
                   this.trackclicks($transcriptPos.track);
                   if (this.options.seek) {
                       this.options.seek($transcriptPos.startoffset);
                   }
                },

                /**
                 * search the keywords to find the cue point
                */
                onClickkeyword: function(e) {
                    var keyword = $(e.currentTarget).text();
                    this.$searchinput.val($.trim(keyword));
                    this.onClickVideoSearch(e);

                },

                /**
                 * Handle the on progress change of the video
                */
                progressHighlight: function(e){
                    if( this.$currentcaption ){
                        if ( e.position >parseFloat(this.$currentcaption.attr("data-startoffset")) + parseFloat(this.$currentcaption.attr("data-duration")) ){
                            this.clearHighlight(this.$currentcaption);
                            this.addHighlight(this.$currentcaption.next());
                        }
                    }
                },

                /**
                 * Remove the highlight for a particular element
                */
                clearHighlight: function(el){
                    if (el.hasClass('active-transcript')){
                        el.removeClass('active-transcript');
                    }
                },

                /**
                 * Turn off the auto scroll when user is on the transcript text block
                 * Turn on the auto scroll when user moves away from the transcript block
                 *  @param {Event} e click event
                 */
                toggleautoscroll: function(e){
                    if (e.type == 'mouseenter'){
                       this.autoscroll = false;
                    } else {
                       this.autoscroll = true;
                    }

                },

                /**
                 *  Auto scroll to the next element if autoscroll is set to true
                 *  @param {Element} nextel element
                 */
                transcriptautoscroll: function(nextel){
                    if (this.autoscroll){
                        var offset = nextel.offsetTop - nextel.parentElement.offsetTop;
                        this.$('.video-full-transcript-text').animate({scrollTop:offset },1000);
                    }
                },

                /**
                 * Add the highlight for a particular element
                */
                addHighlight: function(nextel){
                    if (nextel.length > 0){
                        this.transcriptautoscroll(nextel[0]);
                        this.$currentcaption = nextel ;
                        this.$currentcaption.addClass('active-transcript');
                    }
                },

                /*
                 *  Handles adding and removing the highlight as respective elements as video plays
                 */
                onStatusChange: function(e){
                    if (e.type === 'mediaSeekNotify'){
                        this.clearHighlight(this.$currentcaption);
                        var t = _.find(this.$videotranscriptmain, function(caption, idx) {
                            var $cap = $(caption);
                            if (e.position < $cap.data().startoffset + $cap.data().duration) {
                                this.$currentcaption = $cap;
                                this.addHighlight($cap);
                                return true;
                            }
                        }, this);
                    } else if (e.type === 'mediaBegin' && e.media.referenceId.toString() != this.options.video.id){
                        this.$el.hide();
                    }
                },

                /**
                 * Open or close the transcipt button .
                 */
                onClickTranscriptSlide: function(e) {
                    this.trackclicks('transcript');
                    this.$('.video-full-transcript-text').toggle("slow");
                },

                /**
                 * Slide the keywords or cuepoints if there are more than the width of their container.
                 */
                keywordSlide: function(container, targetWindow) {
                    return this.animate(container, 'left', targetWindow + 'px', 250, 'easeInOutQuad');
                },

                /**
                 * Handles next arrow click for keywordSlides
                 */

                nextslide: function(){
                    var previousSlide = this.getCurrentSlide();
                    if (this.totalSlides !== 0 && previousSlide != this.totalSlides){
                        if (previousSlide == 1){
                            this.targetWindow = this.slideWidth * -1;
                        } else {
                            this.targetWindow = this.slideWidth * previousSlide * -1;
                        }
                        if ((this.$('.video-transcript-keywords')).is(':visible')) {
                            this.keywordSlide(this.$keywordWindow, this.targetWindow);
                        } else if ((this.$search).is(':visible')) {
                            this.keywordSlide(this.$searchWindow, this.targetWindow);
                        }
                        this._currentSlideCount+=1;
                        this.$('.video-transcript-navigate-prev').addClass("transcript-navigate-prev-active");
                    }
                    if (this._currentSlideCount == this.totalSlides) {
                        this.$('.video-transcript-navigate-next').removeClass("transcript-navigate-next-active");
                    }
                },

                /**
                 * Handles previous arrow click for keywordSlides
                 */
                prevslide: function(){
                    var previousSlide = this.getCurrentSlide();
                    if (this.totalSlides !== 0 && previousSlide != 1) {
                        this.targetWindow = this.slideWidth * (previousSlide-2) * -1;
                        if ((this.$('.video-transcript-keywords')).is(':visible')) {
                            this.keywordSlide(this.$keywordWindow, this.targetWindow);
                        } else if ((this.$search).is(':visible')) {
                            this.keywordSlide(this.$searchWindow, this.targetWindow);
                        }
                        this._currentSlideCount-=1;
                        this.$('.video-transcript-navigate-next').addClass("transcript-navigate-next-active");
                        if ( this._currentSlideCount == 1) {
                            this.$('.video-transcript-navigate-prev').removeClass("transcript-navigate-prev-active");
                        }
                    }
                },

                getCurrentSlide: function() {
                    return this._currentSlideCount;
                },

                /**
                 * Divides the keywords into virtual slides, based on the width of the viewport window.
                 */
                updateSlideCount: function() {
                    var $container, x;
                    if ((this.$('.video-transcript-keywords')).is(':visible')) {
                        $container = this.$keywordWindow;
                        x = $container.width();
                        this.viewportWidth = this.$viewport.width();
//                        this.slideWidth = Math.floor(this.viewportWidth / 2);
                    } else if ((this.$search).is(':visible')) {
                        $container = this.$searchWindow;
                        x = $container.width();
                        this.viewportWidth = this.$searchViewport.width();
                    }
                    this.slideWidth = Math.floor(this.viewportWidth / 2);
                    if (x > (this.viewportWidth/2)) {
                        this.totalSlides = (Math.ceil(x / this.viewportWidth)*2);
                    }
                },

                /**
                 * Track the click events.
                 *
                 */
                trackclicks: function(event) {
                    PubSub.trigger('uotrack','ramp'+ this.currentplayer.name + event);
                }

            });

        /**
         * Return view class.
         */
        return VideoTranscript;
    }
);

