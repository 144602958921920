/**
 * @fileoverview Timeline module for smart topic pages
 * @author Kevin Foster <kefoster@gannett.com>
 */
define('modules/topics/topics-timeline',[
    'jquery',
    'underscore',
    'state',
    'baseview',
    'utils',
    'pubsub'
],
function (
    $,
    _,
    StateManager,
    BaseView,
    Utils,
    PubSub
) {

    /**
     * View class.
     */
        var TopicTimeline = BaseView.extend({

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {

            _.bindAll(this, 'onScroll');

            options = $.extend({
                enableInfiniteScroll: true
            }, options);

            if(options.enableInfiniteScroll) {
                this.$win = Utils.get('win');
                this.$win.on('scroll.' + this.cid, _.throttle(this.onScroll, 200));
                this.$storyWrapper = this.$('.tpatm-wrap');
                this.fetchingStories = false;
                this.loaderClass = "tpatm-loading-icon";
                this.loader = '<div class="' + this.loaderClass + '">Loading...</div>';
            }

            BaseView.prototype.initialize.call(this, options);

        },

        getMoreStories: function(){
            var $lastStory = this.$('.tpatm-item:last'),
                moreStoriesPath = $lastStory.attr('data-more-stories-path');
            if(moreStoriesPath) {
                this.fetchingStories = true;
                this.$storyWrapper.append(this.loader);
                PubSub.trigger('topicarticles:fetching');
                var $loader = this.$storyWrapper.find('.' + this.loaderClass);
                var url = '/topic/ajax/stories/?counter=' + this.getStoriesCount() + '&path=' + encodeURIComponent(moreStoriesPath);
                $lastStory.removeAttr('data-more-stories-path');
                StateManager.fetchHtml(url).done(_.bind(function(html){
                    $loader.remove();
                    html.hide().appendTo(this.$storyWrapper).fadeIn(1000);
                    this.fetchingStories = false;
                    PubSub.trigger('topicarticles:fetched');
                }, this)).fail(_.bind(function(){
                    $loader.remove();
                }, this));
            }
        },

        getStoriesCount: function(){
            return this.$('.tpatm-item').length;
        },

        onScroll: function(){
            if(!this.fetchingStories) {
                var lastItemPos = this.$('.tpatm-item').last().position().top,
                    wrapOffset = this.$storyWrapper.offset().top;
                if(Utils.getScrollPosition() >= (lastItemPos - wrapOffset)){
                    this.getMoreStories();
                }
            }

            return false;
        },

        destroy: function(removeEl) {
            this.$win.off('.' + this.cid);
            BaseView.prototype.destroy.call(this, removeEl);
        }

    });

    /**
     * Return view class.
     */
    return TopicTimeline;
});

