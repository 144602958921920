define('migrations',['underscore', 'managers/migrationmanager'],
    function(_, MigrationManager) {
        "use strict";
        var Migrations = function(){
            _.each(Migrations.prototype, function(fn, key){
                MigrationManager.registerMigration(key, fn);
            });
        };
        Migrations.prototype = {
            legacycookie: function(){
                var cookies = (document.cookie || '').match(/[\w-]+(?==)/g),
                    expires = 'expires=Thu, 01 Jan 1970 00:00:00 GMT;',
                    domain = 'domain=' + document.domain;
                // looking for magic legacy cookie to trigger us blowing away all legacy cookies
                if (_.contains(cookies, 'EMETA_NCLICK')) {
                    _.each(cookies, function(name) {
                        // since we don't know if the original cookie was set on a domain, or a path, with www or without, we try all combinations!
                        document.cookie = name + '=;' + expires;
                        document.cookie = name + '=;' + expires + '/;';
                        document.cookie = name + '=;' + expires + domain;
                        document.cookie = name + '=;' + expires + domain + ';/;';
                        document.cookie = name + '=;' + expires + domain.replace('www.', '');
                        document.cookie = name + '=;' + expires + domain.replace('www.', '') + ';/;';
                    });
                }
            }
        };
        new Migrations();
    }
);
