/**
 * @fileoverview Utility Bar module view.
 * @author Erik Kallevig <ekallevig@gannett.com>
 */
define('modules/stories/utility-bar',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'utils',
    'state',
    'baseview',
    'site-manager',
    'managers/resourcemanager',
    'third-party-apis/firefly/firefly',
    'user-manager',
    'modules/stories/utility-bar-module-popups',
    'managers/autoplay'
],
function (
    $,
    _,
    Backbone,
    PubSub,
    Utils,
    StateManager,
    BaseView,
    SiteManager,
    ResourceManager,
    Firefly,
    UserManager,
    UtilityBarModulePopups,
    AutoPlayManager
) {

    /**
     * View class.
     */
        var UtilityBarView = BaseView.extend({

        // View element.
        el: '.utility-bar-wrap',

        // Events.
        events: {
            'click .util-bar-btn': 'onClickUtilityBtn'
        },

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {
            _.bindAll(this, 'onDestroySubview', 'scrollHandler', 'breakingNewsCheck', 'breakingNewsCloseCheck', 'onVideoBarExpand', 'onVideoBarCollapse');
            this.pubSub = {
                'user:statuschange': this._loginStatus,
                'fireflyout:open': this.allowOpenFireflyout,
                'video:after:expandBar': this.onVideoBarExpand,
                'video:collapseBar': this.onVideoBarCollapse,
                'breakingbar:after:open': this.breakingNewsCheck,
                'breakingbar:after:close': this.breakingNewsCloseCheck
            };
            this.$primaryModules = this.$('.util-bar-primary-modules');
            this.$secondaryModules = this.$('.util-bar-secondary-modules');

            this.canFireflyoutOpen = !Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'GOOGLECONSUMERSURVEY', 'ENABLED') || false;
            this.isFireflyoutOpen = false;

            this.header = SiteManager.getHeader();
            if (this.header && this.header.isFixed()) {
                this.onHeaderFixed();
            }

            this.win = Utils.get('win');
            this.breakingNewsCheck();
            this.win.on('scroll.' + this.cid, _.throttle(this.scrollHandler, 50));

            BaseView.prototype.initialize.call(this, options);

            this.autoPopoutComments();

            this.fireflyAutoplayEnabled = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'FIREFLY', 'autoplayEnabled') || false;
            if(this.fireflyAutoplayEnabled) {
                _.bindAll(this, 'resolveFireflyPromise', 'returnFireflyPromise');
                this.fireflyAutoplayPromise = $.Deferred();
                AutoPlayManager.register("firefly", this.returnFireflyPromise);
            }

            this.setupFireflyout();
        },

        resolveFireflyPromise: function() {
            if(this.fireflyAutoplayEnabled) {
                this.fireflyAutoplayPromise.resolve();
            }
        },

        returnFireflyPromise: function() {
            return this.fireflyAutoplayPromise;
        },

        breakingNewsCloseCheck: function() {
            this.breakingbar = false;
            this.bnmHeight = 0;
        },

        breakingNewsCheck: function() {
            this.breakingbar = Utils.getNested(this.header, 'subviews', 'breakingbar') || false;
            this.bnmHeight = $('.bnm-bar').outerHeight() || 0;
        },

        onVideoBarExpand: function() {
            this.videoOffset = $('.js-pvb').outerHeight();
            this.scrollHandler();
        },

        onVideoBarCollapse: function() {
            this.videoOffset = 0;
            this.$primaryModules.css({top: 120});
        },

        scrollHandler: function() {
            var bounds = this.$primaryModules[0].getBoundingClientRect(),
                self = this.$primaryModules.offset().top,
                parentOffset = this.$el.offset().top,
                breakingOffset = this.bnmHeight || 0,
                videoOffset = this.videoOffset || 0,
                offset = breakingOffset + videoOffset;

            if (self - parentOffset + offset < 80) {
                this.$primaryModules.css({position: 'absolute', top: ''});
            } else if(bounds.top <= (120 + offset)) {
                this.$primaryModules.css({position: 'fixed', top: 120 + offset});
            }
        },

        autoPopoutComments: function() {
            this.$commentsBtn = this.$('.util-bar-btn-comments');

            // See if the current URL indicates it is trying to show a particular FB comment
            if (window.location.search.search('[?&]fb_comment_id=.+') > -1) {
                _.defer(_.bind(function(){
                    this.openComments();
                }, this));                
            }
        },

        setupFireflyout: function() {
            this.$fireflyModule = this.$('.util-bar-module-firefly');
            this.$fireflyBtn = this.$('.util-bar-btn-firefly');

            if (this.$fireflyModule.length) {
                this._checkUserAccount();
            } else {
                this.resolveFireflyPromise();
            }
        },

        _checkUserAccount: function() {
            var userAccount = UserManager.getAccount('firefly');
            if (userAccount) {
                userAccount.getUserInfo().always(_.bind(function(userInfo) {
                    this._loginStatus('firefly', userAccount.getLoginStatus(), userInfo);
                }, this));                
            } else {
                this.resolveFireflyPromise();
            }
        },
        
        _loginStatus: function(accountName, loginStatus, userData) {
            if (accountName !== 'firefly') {
                this.resolveFireflyPromise();
                return;
            }

            // When logged in but not subscribed, don't show the login button anymore.
            if (loginStatus === 'loggedIn') {
                this.$fireflyModule.addClass('util-bar-module-firefly-authenticated');
            } else if (loginStatus !== 'loggingIn') {
                this.$fireflyModule.removeClass('util-bar-module-firefly-authenticated');                
            }

            var protectedState = StateManager.getActivePageInfo().content_protection_state;
            var isContentFree = !protectedState || protectedState === 'free';
            if ((loginStatus === 'loggedIn' && userData && userData.hasMarketAccess) || isContentFree) {
                this.$fireflyModule.removeClass('util-bar-module-firefly-visible');
                this.resolveFireflyPromise();
            } else if (loginStatus !== 'loggingIn') {
                this.$fireflyModule.addClass('util-bar-module-firefly-visible');
                this.fireflyCookieCheck();
            }
        },

        fireflyCookieCheck: function() {
            if (this.shouldShowFireFly()) {
                _.delay(_.bind(function() {
                    this.isFireflyoutOpen = true;
                    this.openFireflyout();
                }, this), 0);
            } else {
                this.resolveFireflyPromise();
            }
        },

        shouldShowFireFly: function() {
            var pageInfo = StateManager.getActivePageInfo();
            // Firefly cookie check for auto-opening Fireflyout. Only auto-open flyout once per page load.
            var viewsCookie = Firefly.getFireflyViewsCookie();
            //if there's auto play video, firefly should't flyout
            var autoPlayVideo = Utils.hasAutoplayVideo(pageInfo);

            return ((viewsCookie && this._canFireflyoutOpen() && !this.isFireflyoutOpen &&
                (viewsCookie.viewCount === 1 || viewsCookie.viewsRemaining < 2 || this._overrideFlyoutOpen(viewsCookie.viewsRemaining))) && !autoPlayVideo);
        },

        _overrideFlyoutOpen: function(views) {
            return _.indexOf(Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'FIREFLY', 'flyoutOverrideArticleViewsRemaining'), views) > -1;
        },

        _canFireflyoutOpen: function() {
            return this.canFireflyoutOpen;
        },

        allowOpenFireflyout: function() {
            this.canFireflyoutOpen = true;
            var userAccount = UserManager.getAccount('firefly');
            if (userAccount) {
                var userAccountStatus = userAccount.getLoginStatus();
                if(userAccountStatus !== 'loggedIn' || userAccountStatus !== 'loggingIn') {
                    this.fireflyCookieCheck();
                }
            }
        },

        openFireflyout: function() {
            PubSub.trigger('track', {
                label: 'utilitybarfireflyopen|auto'
            });
            this.$fireflyBtn.trigger('click');
        },

        openComments: function() {
            this.$commentsBtn.trigger('click');
        },

        onHeaderFixed: function(){
            this.$primaryModules.css({position: 'fixed', top: 'auto'});
        },

        onHeaderUnfixed: function(){
            this.$primaryModules.css({position: 'absolute', top: ''});
        },

        onClickUtilityBtn: function(e){
            var $btn = $(e.currentTarget);

            // Custom popup code that must be triggered as a
            // direct result of click to avoid popup blocking.
            if ($btn.data('popup-callback')) {
                var popupCallback = $btn.data('popup-callback');
                UtilityBarModulePopups[popupCallback]();

            // Init util-bar-module
            } else if ($btn.prop('tagName') !== 'A') {
                e.preventDefault();
                var moduleName = $btn.data('module-name');
                var moduleSection = $btn.data('module-section') || '';
                if (this.subviews[moduleName]) {
                    if (moduleSection && moduleSection !== this.subviews[moduleName].activeSection) {
                        if (moduleName === 'utility-bar-module-firefly' && e.isTrigger === undefined){
                            PubSub.trigger('track', {
                                label: 'utilitybarfireflyopen|click'
                            });
                        }
                        this.subviews[moduleName].open(moduleSection);
                    } else {
                        if (this.subviews[moduleName].close) {
                            this.subviews[moduleName].close();
                        }
                    }
                } else {
                    _.each(this.subviews, function(subview) {
                        if (subview && subview.close) {
                            subview.close();
                        }
                    });
                    ResourceManager.getSiteModuleByName(moduleName).done(_.bind(function(module){
                        if (!this.destroyed) {
                            this.subviews[module.name] = new module.ModuleClass(_.extend({
                                'el': module.selector,
                                'onDestroy': this.onDestroySubview
                            }, module.options));
                            if (this.subviews[module.name].open) {
                                if (moduleName === 'utility-bar-module-firefly' && e.isTrigger === undefined){
                                    PubSub.trigger('track', {
                                        label: 'utilitybarfireflyopen|click'
                                    });
                                }
                                this.subviews[module.name].open(moduleSection);
                            }
                        }
                    }, this)).fail(function(){
                        console.error('Failed to load utility bar module: ' + moduleName + '.js');
                    });
                }
            }
        },

        onDestroySubview: function(destroyedSubview) {
            _.each(this.subviews, function(iterSubview, i) {
                if (iterSubview === destroyedSubview) {
                    this.subviews[i] = null;
                }
            }, this);
        },

        /**
         * Adds show class to el.
         */
        show: function() {
            this.$el.addClass('show');
        },

        /**
         * Removes show class from el.
         */
        hide: function() {
            this.$el.removeClass('show');
        },

        /*
         * Destroy view.
         */
        destroy: function(removeEl){
            this.destroyed = true;

            // Avoid jumpiness in Chrome due to fixed position bug.
            this.$el.css('padding-top', Utils.getScrollPosition());
            this.$primaryModules.css('position', 'absolute');
            this.$secondaryModules.css('position', 'absolute');
            this.win.off('.' + this.cid);
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });

    /**
     * Return view class.
     */
    return UtilityBarView;
});

