/**
 * @fileoverview Sports Popup Media View
 */

define('modules/sports/sports-popup-media-view',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'baseview',
    'modules/sports/sports-front-galleries',
    'modules/global/brightcove-video',
    'state',
    'ui/loader'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    BaseView,
    SportsFrontGalleries,
    Video,
    StateManager,
    Loader
)
    {
        var SportsPopupMedia = BaseView.extend({
            
            el: 'body',

            events: {
                'click .video-popup-close': 'hideVideo',
                'click .popup-media-video': 'showVideo',
                'click .popup-media-gallery': 'showGallery',
                'click .gallery-sidebar-close.active': 'hideGallery'
            },

            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);

                this.$cardfilm = this.$('.sports-popup-media-module .popup-film');
                this.$title = this.$('.sports-popup-media-module .gallery-sidebar-title');
                this.$video = this.$('.sports-popup-media-module .video-wrapper');
                this.$gallery = this.$('.sports-popup-media-module .gallery-wrapper');
                this.$galleryLinks = this.$('.sports-popup-media-module .gallery-link-wrapper');
                this.$galleryCount = 0;

                _.bindAll(this, 'prepareMedia', 'updateShareTools', 'lightsOn', 'lightsOut',
                    'hideGallery', 'showGallery', 'hideVideo', 'showVideo');

                PubSub.on('carousel:sidebarClosed', _.bind(function() {
                    this.hideGallery();
                }, this));

                this.pattern = '/popup-media-link';
                this.prepareMedia();
                PubSub.on('showmore:headlines', this.prepareMedia);

                this.subviews.gallery = new SportsFrontGalleries({
                    el: $('.stagfront-content')
                });

                this.subviews.loader = new Loader({
                    el: this.$video
                });
            },

            prepareMedia: function() {
                var _this = this;

                this.$('.hero-asset-open').addClass('hero-asset-popup');

//                this.$('.hero-asset-popup').removeClass('hero-asset-open').removeClass('slide');

                this.$('.anchor, .hero-asset-popup a').each(function() {
                    var link = $(this).attr('href');    // headline
                    if (link === undefined) {
                        link = $(this).data('href');    // hero
                    }
                    if (link !== undefined) {
                        if (link.indexOf('/picture-gallery/') === 0) {
                            $(this).addClass('popup-media-gallery');
                        } else if (link.indexOf('/videos/') === 0) {
                            $(this).addClass('popup-media-video');
                        }
                    }
                });

                this.$('.popup-media-gallery').each(function(i) {
                    if (i >= _this.$galleryCount) {
                        var href = $(this).attr('href');
                        var galleryid = href.split('/').slice(-2,-1)[0];
                        $(this).data('id', galleryid);

                        var link = '<div class="front-gallery-item"><a id="link' + galleryid + '" class="front-gallery-link" href="' + href + '"></a></div>';
                        _this.$galleryLinks.append(link);

                        var view = '<div id="' + galleryid + '" class="front-gallery"></div>';
                        _this.$('.front-gallery').last().after(view);
                    }
                });
                this.$galleryCount = this.$('.front-gallery-item').length;
                this.$('.gallery-sidebar-close').addClass('active');
                this.hideGallery(); // hide the selected gallery
                this.hideVideo();
            },

            lightsOn: function() {
                if (!this.$cardfilm.hasClass('inactive')) {
                    this.$cardfilm.addClass('inactive');
                }
            },

            lightsOut: function(e) {
                var target = $(e.currentTarget);
                e.preventDefault();
                this.$cardfilm.removeClass('inactive');
            },

            hideGallery: function() {
                this.lightsOn();
                this.$gallery.hide();
                PubSub.trigger('gallery:hideSidebar');
            },

            showGallery: function(e) {
                this.lightsOut(e);
                var target = $(e.currentTarget);

                this.$galleryLinks.find('#link' + target.data('id')).click();
                var title = target.find('h1, h3 span').html();
                this.$title.html(title);

                var viewSize = $(window).outerWidth();
                if (viewSize > 1080) {
                    this.$gallery.css('left', (viewSize-1080)/2);
                } else {
                    this.$gallery.css('left', (viewSize-720)/2);
                }

                this.updateShareTools(target.attr('href'));
                PubSub.trigger('gallery:clickSidebar');
                this.$gallery.show();
            },

            hideVideo: function(e) {
                this.lightsOn();
                if (this.subviews.video) {
                    this.subviews.video.pauseVideo();
                }
                this.$video.hide();
            },

            showVideo: function(e) {
                var _this = this;
                var target = $(e.currentTarget);
                var url = target.attr('href');
                var viewSize = $(window).outerWidth();
                this.$video.css('left', (viewSize-this.$video.width())/2);
                var index = this.$('.popup-media-video').index(target);
                this.lightsOut(e);
                this.$video.show();
                this.subviews.loader.show();
                StateManager.fetchHtml(url).done(function(data) {
                    var html = data.find('.asset .video');
                    var video = _this.$('.video-wrapper .video-slot');
                    video.html(html); // inject object and then rewrite
                    video.html(video.html().replace('usat_shareFunction', 'default_shareFunction'));
                    _this.subviews.video = new Video({
                        el: html,
                        autostart: true
                    });
                    _this.subviews.loader.hide();
                });
            },
            
            updateShareTools: function(link) {
                var facebook = this.$('.gallery-sidebar-ad .util-bar-flyout-nav-btn-facebook');
                facebook.data('share-link', facebook.data('share-link').replace(this.pattern, link));
                // inspector will not display any change in the data-share-link, check using title:
                //facebook.data('share-title', facebook.data('share-link').replace(pattern, link));

                var twitter = this.$('.gallery-sidebar-ad .util-bar-flyout-nav-btn-twitter');
                twitter.attr('href', twitter.attr('href').replace(this.pattern, link));

                var googleplus = this.$('.gallery-sidebar-ad .util-bar-flyout-nav-btn-googleplus');
                googleplus.attr('href', googleplus.attr('href').replace(this.pattern, link));

                var linkedin = this.$('.gallery-sidebar-ad .util-bar-flyout-nav-btn-linkedin');
                linkedin.attr('href', linkedin.attr('href').replace(this.pattern, link));

                this.pattern = link;
            },

            destroy: function() {
                PubSub.off('showmore:headlines');
                PubSub.off('carousel:sidebarClosed');
                BaseView.prototype.destroy.apply(this, arguments);
            }      
           
        });
        return SportsPopupMedia;
    }
);

