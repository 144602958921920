/* global define */
define('modules/stories/taboola-recommendations',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'fwinfo',
    'managers/routemanager',
    'utils',
    'modules/global/taboola',
    'pubsub',
    'admanager'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    FwInfo,
    RouteManager,
    Utils,
    Taboola,
    PubSub,
    AdManager
) {
    'use strict';

    var TaboolaOutbrain = BaseView.extend({

        initialize: function(options) {
            options = $.extend({
                videoResponse: {}
            }, options);
            var pageInfo = this.pageInfo = StateManager.getActivePageInfo();
            this.texttype = pageInfo.texttype || 'story';

            _.bindAll(this, 'showTaboola', 'showNextRecommendation', 'getPlacement', 'renderEndslate', 'isVideo', 'isStory');

            BaseView.prototype.initialize.call(this, options);
            this.$endslate = this.$('#endslate-taboola-template');
            this.$endslate_template = this.$('.video-endslate');
            var pageType = pageInfo.basePageType;
            if (!AdManager.isAdFreeExperience()) {
                if (Utils.getNested(options, 'layoutType') === 'sidebar') {
                    this.createFrontSidebarTaboolaWidget(pageInfo);
                } else if (Utils.getNested(options, 'layoutType') === 'full-width' || Utils.getNested(options, 'layoutType') === 'above-content' || Utils.getNested(options, 'layoutType') === 'below-content') {
                    this.createSectionTaboolaWidget(pageType);
                } else if (!this.options.isContinuousEndslate && pageType !== 'section-front' && pageType !== 'subsection-front' && pageType !== 'home-front') {
                    this.render();
                }
            }
        },

        /**
         * Populate more stories fragments on asset page.
         */
        render: function() {
            var $pageEl, moreStoriesVertical;
            $pageEl = $('.transition-wrap:first');
            if (this.texttype !== 'interactive') {
                if (this.texttype.indexOf('video') !== -1 || this.texttype === 'galleries') {
                    moreStoriesVertical = false;
                } else if (this.isStory()) {
                    // Determine which presentation to use based on left/right column heights.
                    var leftCol = $pageEl.find('.double-wide');
                    var rightCol = $pageEl.find('.story-right-rail');
                    if (!leftCol.length) {
                        return;
                    }
                    if (Utils.hasAutoplayVideo(StateManager.getActivePageInfo()) && Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'docked')) {
                        moreStoriesVertical = true;
                    } else if (!rightCol.length) {
                    // if right rail is mia we assume horizontial
                        moreStoriesVertical = false;
                    } else {
                        var leftTop = leftCol.position().top;
                        var leftH = leftCol.height();
                        var rightH = rightCol.position().top + rightCol.height() + 1400 - leftTop;
                        if (leftH < rightH) {
                            moreStoriesVertical = true;
                        }
                    }
                }
                if ($('.iframe-auto').length > 0){
                    moreStoriesVertical = true;
                }
                this.createTaboola(moreStoriesVertical);

            }
        },

        /**
         * Taboola Widget for Front Sidebar
         */
        createFrontSidebarTaboolaWidget: function(pageInfo) {
            var mode,
                sidebarTheme = Utils.getNested(window, 'site_vars', 'display_options', 'sidebar_theme'),
                sidebarMode = Utils.getNested(window, 'site_vars', 'THIRDPARTYAPI', 'Taboola', 'sidebar_widget_mode'),
                section = Utils.getNested(pageInfo, 'section_name'),
                pagetypeWidget = {
                    url: Utils.getNested(pageInfo, 'canonical')
                },
                categoryKey = section === 'home' ? 'home' : 'category',
                widget = [],
                taboolaOpts = {};

            if (sidebarTheme) {
                mode = sidebarTheme === 'light' ? 'thumbnails-f' : 'thumbnails-n';
            } else if (sidebarMode) {
                mode = sidebarMode;
            } else {
                mode = 'thumbnails-f';
            }

            pagetypeWidget[categoryKey] = 'auto';

            if(this.$el.hasClass('dr-styling')) {
                widget.push(this.getMetadata('thumbnails-p', 'taboola-right-rail-thumbnails', 'Right Rail Thumbnails - AB Test', 'mix'));
            } else {
                widget.push(this.getMetadata(mode, 'taboola-right-rail-thumbnails', 'Right Rail Thumbnails', 'mix'));
            }

            taboolaOpts.widget = widget;
            taboolaOpts.pagetypeWidget = pagetypeWidget;
            this.subviews.taboola = new Taboola(taboolaOpts);
        },

        /**
         * constructs taboola widget for section-fronts
         */
        createSectionTaboolaWidget: function(sectionName) {
            var widget = [], pagetypeWidget = {}, taboolaOpts= {};
            if (sectionName === 'section-front' || sectionName === 'subsection-front') {
                widget.push(this.getMetadata('thumbnails-g', 'taboola-section-front-thumbnails', 'Section Front Thumbnails', 'mix'));
                pagetypeWidget.category = 'auto';
            } else if (sectionName === 'home-front') {
                widget.push(this.getMetadata('thumbnails-s', 'taboola-homepage-thumbnails', 'Homepage Thumbnails', 'mix'));
                pagetypeWidget.home = 'auto';
            }
            pagetypeWidget.url = Utils.getNested(this.pageInfo, 'canonical');
            taboolaOpts.widget = widget;
            taboolaOpts.pagetypeWidget = pagetypeWidget;
            this.subviews.taboola = new Taboola(taboolaOpts);
        },

        /**
         * constructs taboola subview
         * @param {Boolean} moreStoriesVertical - whether we render taboola as vertical or horizontal
         */

        createTaboola: function(moreStoriesVertical) {
            var isSports, pageUrl, vid_assetId,
                pagetypeWidget = {},
                taboolaOpts= {},
                widget = [],
                $pageEl = $('.transition-wrap:first'),
                widgetsFromOptions = Utils.getNested(this.options, 'taboolaWidgets') || {};
            pageUrl = Utils.getPageUrl();
            isSports = (pageUrl.indexOf('/sports/') !== -1 || pageUrl.indexOf('/gameon/') !== -1);
            if (moreStoriesVertical) {
                if (_.isArray(widgetsFromOptions.belowArticle)) {
                    widget = widget.concat(widgetsFromOptions.belowArticle);
                } else {
                    widget.push(this.getMetadata('thumbnails-r', 'taboola-short-article-2x5','Below Article SC 3x2', 'mix'));
                    widget.push(this.getMetadata('thumbnails-k', 'taboola-organic-short-article-2x5','Below Article Organic 2x2', 'mix'));
                }
                pagetypeWidget.article = 'auto';
                $pageEl.find('.more-stories-wrap').addClass('vertical');
            }  else if (this.isVideo()) {
                widget.push( this.getMetadata('thumbnails-b', 'taboola-below-video-thumbnails','Below Video Thumbnails', 'mix'));
                widget.push(this.getMetadata('organic-thumbnails-d', 'taboola-below-video-thumbnails-2nd','Below Video Thumbnails-2nd', 'mix'));
                if (this.texttype === 'video') {
                    vid_assetId = StateManager.getActivePageInfo().assetid;
                    pagetypeWidget.video = vid_assetId;
                }
            } else if (this.texttype === 'galleries') {
                widget.push(this.getMetadata('thumbnails-b', 'taboola-below-photo-thumbnails','Below Photo Thumbnails', 'mix'));
                widget.push(this.getMetadata('organic-thumbnails-d', 'taboola-below-photo-thumbnails-2nd','Below Photo Thumbnails-2nd', 'mix'));
                pagetypeWidget.photo = 'auto';
            } else if (isSports) {
                widget.push(this.getMetadata('thumbnails-l', 'taboola-grid-sports-3x3', 'grid-sports-3x3', 'mix'));
                widget.push(this.getMetadata('thumbnails-m', 'taboola-organic-grid-sports-3x3', 'organic-grid-sports-3x3', 'mix'));
                pagetypeWidget.article = 'auto';
            } else if (this.isStory()) {
                if (_.isArray(widgetsFromOptions.belowArticle)) {
                    widget = widget.concat(widgetsFromOptions.belowArticle);
                } else {
                    widget.push(this.getMetadata('thumbnails-r', 'taboola-grid-3x3', 'Below Article SC 3x2', 'mix'));
                    widget.push(this.getMetadata('thumbnails-k', 'taboola-organic-grid-3x3', 'Below Article Organic 2x2', 'mix'));
                }
                pagetypeWidget.article = 'auto';
            }
            if (Utils.getNested(window.site_vars, 'ADS', 'taboola_poster', 'enabled') || Utils.getUrlParam('enable_taboola_abp')) {
                widget.push(this.getMetadata('thumbnails-q-abp', 'taboola-abp-right-rail-thumbnails', 'ABP Right Rail Thumbnails', 'mix'));
            }
            if (Utils.getNested(window.site_vars, 'taboola_exchange', 'enabled')  && (this.isStory() || moreStoriesVertical)){
                widget.push(this.getMetadata('organic-thumbnails-rr', 'taboola-right-rail-exchange-thumbnails', 'Right Rail Thumbnails - Exchange', 'mix'));
                pagetypeWidget.article = 'auto';
            }
            taboolaOpts.widget = widget;
            taboolaOpts.pagetypeWidget = pagetypeWidget;
            this.subviews.taboola = new Taboola(taboolaOpts);
        },

        getMetadata: function(mode, container, placement, target_type) {

            return {
                    mode: mode,
                    container: container,
                    placement: placement || '',
                    target_type: target_type || ''
                };
        },

        isStory: function() {
            return (['story', 'Story', 'blog', 'data'].indexOf(this.texttype) !== -1);
        },

        isVideo: function() {
            return (['video', 'video-playlist', 'brightcove-video-playlist'].indexOf(this.texttype) !== -1);
        },

        /*
         * Find taboola templates and populate them.
         */
        showTaboola: function(data) {
            var more = this.$('#more-galleries-template');
            if (more.length === 1) {
                var compiled = _.template(more.html(), {data: data.list});
                this.$('.headlines.gallery-more').append(compiled);
            }
        },

        /**
        * Populate continuous play endslate on video and cinematic view videos
        */
        renderEndslate: function(url, id, is_media) {
            if (!this.subviews.taboola) {
                this.subviews.taboola = new Taboola({isContinuousEndslate: this.options.isContinuousEndslate});
            }
            this.subviews.taboola.getRelated(this.showNextRecommendation, 'video', 'video', 1, url, id, this.getPlacement(is_media), false);
        },

        /**
         * Get placement value for taboola recommendation
         */
        getPlacement: function(is_media) {
            if (is_media) {
                return 'Continuous Endslate - Cinematic';
            } else {
                switch (this.texttype) {
                    case "story":
                    case "data":
                        return 'Continuous Endslate - Article';
                    case "video":
                        return 'Continuous Endslate - Asset';
                    case "video-playlist":
                        return 'Continuous Endslate - Presto Playlist';
                    case "brightcove-video-playlist":
                        return 'Continuous Endslate - Brightcove playlist';
                }
            }
        },

        /*
        * Fetch data pertaining to the next recommended video by taboola's api call.
        */
        showNextRecommendation: function(data) {
            if (data.list && !$.isEmptyObject(data.list[0])) {
                var nextAssetID = '',
                siteconfig = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Taboola') || {};
                if (siteconfig.presto_recommendation) {
                    nextAssetID = data.list[0].id;
                } else {
                    var url = decodeURIComponent(data.list[0].url.match(/\&redir\=(.+)/)[1]),
                        relativeUrl = url.replace(/^.*\/\/[^\/]+/, '');
                    nextAssetID = relativeUrl.replace(/^\D+/g, '');
                }

                StateManager.fetchData('/videos/services/'+ nextAssetID).done(_.bind(function(response) {
                    if (response) {
                        this.videoResponse = response;
                        this.videoResponse.taboolaResponseId = data.id;
                        if (siteconfig.presto_recommendation) {
                            this.video_media_url = "/media/cinematic/video/" + nextAssetID;
                        } else {
                            this.video_media_url = decodeURIComponent(data.list[0].url.match(/\&redir\=(.+)/)[1]);
                        }
                    } else {
                        PubSub.trigger('uotrack', 'taboola-no-recommendations');
                    }
                }, this)).fail(_.bind(function(){
                    this.videoResponse = {};
                }, this));
            } else {
                this.videoResponse = {};
            }
        },

        /*
        * Play the next recommended video
        */
        playNextRecommendation: function(is_media) {
            var _is_media = is_media,
                next_video_url = '';
            if (!this.subviews.taboola) {
                this.subviews.taboola = new Taboola();
            }
            if (!this.videoResponse) {
                this.videoResponse = this.options.videoResponse;
            }
            if (!$.isEmptyObject(this.videoResponse)){
                next_video_url = this.videoResponse.url.replace(/^.*\/\/[^\/]+/, '');
                if (_is_media) {
                    next_video_url = this.video_media_url.replace(/^.*\/\/[^\/]+/, '');
                }
                this.subviews.taboola.registerTaboolaVisible(this.videoResponse.taboolaResponseId);
                this.subviews.taboola.registerTaboolaClick(this.videoResponse.id ,'video',this.videoResponse.taboolaResponseId);
                FwInfo.setsessionvalue('clickName', 'taboola-autoplay');
                if (['video', 'video-playlist', 'brightcove-video-playlist'].indexOf(this.texttype) !== -1 || _is_media) {
                    RouteManager.goTo(next_video_url);
                } else {
                    this.options.playRecommendedVideo(this.videoResponse);
                }
            }
        },

        destroy: function(removeEl) {
            clearInterval(this.timer);
            if (window.taboolainitialized) {
                window.taboolainitialized = false;
            }
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });
    return TaboolaOutbrain;
});

