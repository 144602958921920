/**
 * @fileoverview Sports Carousel module view.
 */
define('modules/sports/sports-front-carousel',[
    'jquery',
    'underscore',
    'utils',
    'pubsub',
    'modules/carousel/carousel',
    'modules/sports/sports-carousel-gallery'
],
function(
    $,
    _,
    Utils,
    PubSub,
    Carousel,
    SportsCarouselGallery
) {
    'use strict';

    var SportsFrontCarouselView = Carousel.extend({

        events: function() {
            var parentEvents = Carousel.prototype.events;
            if(_.isFunction(parentEvents)){
                parentEvents = parentEvents();
            }
            return _.extend({},parentEvents,{
                mouseenter: 'mouseEnter',
                click: 'mouseClick'
            });
        },

        initialize: function(options) {
            this.$primary = $('.front-galleries-primary');
            this.$module = $('.front-galleries-module');
            this.$closer = $('.gallery-sidebar-close');
            this.$sidebar = $('.gallery-sidebar-ad');
            this.$counter = $('.gallery-count');
            this.$cardfilm = $('.front-card-film');
            this.$cardcolor = $('.card-suspender-color');
            this.$suspender = $('.front-galleries-suspender');
            this.$caption = $('.gallery-sidebar-ad .caption').html();

            if (this.$module.length < 1) {
                this.$module = $(".sports-front-galleries-module");
                if (this.$module.length < 1) {
                    this.$module = $(".sports-galleries-module");
                }
            }

            _.bindAll(this, 'mouseScroll', 'mouseClick', 'mouseEnter', 'isSideBarOpen', 'sidebarCaption');

            Carousel.prototype.initialize.call(this, options);

            this.sidebarCaption();
            PubSub.on('carousel:switchSlide', _.bind(function() {
                this.captionPoll = setInterval(this.sidebarCaption, 200);
            }, this));

            PubSub.on('gallery:clickSidebar', _.bind(function() {
                this.showSidebar();
            }, this));

            this.$win = Utils.get('win');
            this.$win.on('scroll.' + this.cid, this.mouseScroll);
            this.$win.on('click.' + this.cid, this.mouseClick);
        },

        _initializeGallery: function(imageLazySrcAttr, slideTransition, showAds, selector){
            this.subviews.gallery = new SportsCarouselGallery({
                ads: showAds,
                el: (selector ? this.$(selector) : this.$el),
                index: this.index,
                imageLazySrcAttr: imageLazySrcAttr,
                slideTransition: slideTransition,
                carousel: this,
                isSideBarOpen: this.isSideBarOpen,
                sidebarAds: this.options.sidebarAds
            });
        },

        sidebarCaption: function() {
            var sidebar = $('.gallery-sidebar-ad .caption');
            var caption = $('.selected .active .gallery-viewport-caption').html();
            if (caption != this.$caption) {
                this.$caption = caption;
                clearInterval(this.captionPoll);
            }
            if (caption) {
                sidebar.html(caption);
            } else {
                sidebar.empty();
            }
        },

        openSidebar: function(e) {
            if (e){
                e.preventDefault();
                e.stopPropagation();
            }
            var w = parseInt(this.$sidebar.outerWidth() + 1, 10);
            var slideAnimation = null;
            if (!this.$sidebar.hasClass('active')) {
                this.$sidebar.addClass('active');
                slideAnimation = this.animate(this.$sidebar, 'right', -(w)+'px', 250, 'ease-in-out').promise().done(_.bind(function() {
                    //call function to initialize medium rectangle
                    if (this.options && this.options.onSidebarAfterReveal) {
                        this.options.onSidebarAfterReveal();
                    }
                    PubSub.trigger('carousel:sidebarOpened');
                    //track inital flyout open
                    window.utag.link({clickName: 'gallery caption flyout', clickPage: location.href, linkTrackVars: 'prop41,prop64'});
                }, this));
            }
        },

        showSidebar: function() {
            var w = parseInt(this.$sidebar.outerWidth(), 10);
            if (!this.$sidebar.hasClass('active')) {
                this.$sidebar.addClass('active');
                this.$primary.addClass('active');
                this.$sidebar.css({'right': -(w)+'px', 'transition': ''});
                if (this.options && this.options.onSidebarAfterReveal) {
                    this.options.onSidebarAfterReveal();
                }
                this.$closer.addClass('active');
            }
        },

        isSideBarOpen: function() {
            return !this.$cardfilm.hasClass('inactive');
        },

        mouseEnter: function() {
            return;
        },

        mouseScroll: function(e) {
            if (!this.$sidebar.hasClass('active'))
                return;
            var modSize = this.$sidebar.outerHeight();
            var modTop = this.$module.position().top;
            var scrollTop = $(window).scrollTop();
            var viewSize = $(window).outerHeight();
            if ((scrollTop > (modTop + modSize + 90)) ||
                    (modTop > (scrollTop + viewSize - 130)))
                this.$closer.click();
        },

        mouseClick: function(e) {
            if (!this.$sidebar.hasClass('active'))
                return;
            PubSub.trigger('carousel:cardfilmActive');
            var modTop = this.$module.offset().top;
            var modLeft = this.$module.offset().left;
            var modWide = this.$module.outerWidth() + this.$sidebar.outerWidth();
            var modTall = this.$sidebar.outerHeight();
            if ((e.pageX < modLeft) || (e.pageX > (modLeft + modWide)) ||
                    (e.pageY < modTop) || (e.pageY > (modTop + modTall))) {
                this.$closer.click();
            } else {
                if (!this.$closer.hasClass('active')) {
                    this.$closer.addClass('active');
                    this.$cardfilm.removeClass('inactive');
                    this.$module.css('z-index', 501);
                    this.$suspender.css('background-color', this.$cardcolor.css('background-color'));
                }
            }
        },

        destroy: function(removeEl) {
            clearInterval(this.captionPoll);
            this.$win.off('.' + this.cid);
            if (removeEl){
                this.$el.parent().remove();
            }
            PubSub.off('carousel:switchSlide');
            PubSub.off('gallery:clickSidebar');
            Carousel.prototype.destroy.call(this, false);
        }

    });

    return SportsFrontCarouselView;

});

