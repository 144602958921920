/**
 * @ Recommendation Flyout
 * @author Jonathan Hensley
 */
define('modules/stories/recommended-flyout',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function(
    $,
    _, 
    BaseView,
    Utils
) {


    /**
     * View class.
     */
    var RecommendedFlyout = BaseView.extend({

        // Events.
        events: {
            'click .closed': 'toggle',
            'click .recommended-flyout-close': 'toggle'
        },

        /**
         * Initialize view.
         */
        initialize: function() {
            this.isOpen = false;
            this.forcedStatus = false;
            this.hasOpened = false;
            this.rendered = false;

            this.$flyout = this.$('.recommended-flyout');
            this.$flyoutWrap = this.$('.recommended-flyout-wrap');
            this.$flyoutTemplate = this.$('#recommended-flyout-template');
            this.$win = Utils.get('win');
            this.winHeight = this.$win.height();
            _.bindAll(this, 'scrollListener');

            BaseView.prototype.initialize.apply(this, arguments);
        },

        storyCollectionLoaded: function(storyCollection, storyIndex) {
            if (storyCollection && storyCollection.length > 0 && storyIndex > -1) {
                var nextStory = storyCollection[storyIndex + 1];
                this.$triggerDom = this.$el.next();
                this.triggerDomOffset = this.$triggerDom.offset().top;
                if (nextStory && this.$triggerDom.length) {
                    this.render(nextStory);

                    var overlayScrollListenerThrottle = _.throttle(this.scrollListener, 100);
                    this.$win.on('scroll.' + this.cid, overlayScrollListenerThrottle);
                }
            }
        },

        scrollListener: function(){
            var windowOffset = Utils.getScrollPosition(),
                windowBottom = windowOffset + this.winHeight;
            if ((this.triggerDomOffset <= windowBottom)) {
                this.openRecommendedFlyout();
            } else {
                this.closeRecommendedFlyout();
            }
        },

        /**
         * Process the story collection, render a template and deliver it to the page
         * @param {Object} data - data returned from Taboola
         */
        render: function(data) {
            try {
                if (data && data.photo && data.photo.crops) {
                    data.image = data.photo.crops['1_1'];
                }

                var compiled = _.template(this.$flyoutTemplate.html(), {data: data});
                this.$flyout.html(compiled);
                this.rendered = true;
            } catch(e){
                console.error('failed rendering flyout template', e);
            }
        },

        /**
         * Toggle the flyout
         * @param {MouseEvent} event Event object from clicked element
         */
        toggle: function(event) {

            event.preventDefault();

            if (!this.isOpen) {

                this.forcedStatus = 'open';
                this.openRecommendedFlyout();

            } else {

                this.forcedStatus = 'closed';
                this.closeRecommendedFlyout();

            }

        },

        /**
         * Open the flyout
         */
        openRecommendedFlyout: function() {
            if (!this.isOpen && this.rendered && this.forcedStatus != 'closed') {
                this.animate(this.$flyoutWrap, 'width', 360, 350, 'ease-out');
                this.$flyout.removeClass('closed');
                this.isOpen = true;
                this.hasOpened = true;
            }
        },

        /**
         * Close the flyout
         */
        closeRecommendedFlyout: function() {
            if (this.hasOpened && this.isOpen && this.forcedStatus != 'open') {
                this.animate(this.$flyoutWrap, 'width', 24, 250, 'ease-out');
                this.$flyout.addClass('closed');
                this.isOpen = false;
            }
        },

        /**
         * Clean up view.
         * Removes event handlers and element (optionally).
         * @param {Boolean} removeEl option to also remove View from DOM.
         */
        destroy: function(removeEl) {
            this.$win.off('.' + this.cid);
            this.$el.remove();
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });

    /**
     * Return view class.
     */
    return RecommendedFlyout;
});
