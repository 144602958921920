/* global Reuters:true */
/**
 * @fileoverview Olympics schedule results
 * @author ikenticus
 */
define('apps/overlay/pages/olympics-schedule-results',[
    'jquery',
    'underscore',
    'baseview',
    'state'
],
function(
    $,
    _,
    BaseView,
    StateManager
)
    {
        var OlympicsScheduleView = BaseView.extend({

            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);

                this.$events = this.$('.schedule-results');
                this.fetchResults();
            },

            fetchResults: function() {
                var _this = this;
                var url = this.$('.day-schedule').data('url');
                StateManager.fetchHtml(url)
                    .done(_.bind(function(html) {
                        _this.displayResults(html);
                    }, this)
                );
            },

            displayResults: function(results) {
                var _this = this;
                results.each(function() {
                    if ($(this).find('.flag').length > 0) {
                        var results_id = $(this).data('id');
                        _this.$('#' + results_id).html(this);
                    }
                });
            },

            destroy: function() {
                BaseView.prototype.destroy.call(this);
            }
            
        });

        return OlympicsScheduleView;
    }
);
