/**
*
*
*/
define('modules/longform/longform-nav-rail-bucket',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'state',
    'modules/scroller/vertical-scroll',
    'third-party-apis/facebook/facebook',
    'site-manager',
    'utils'
],
function(
    $,
    _,
    BaseView,
    PubSub,
    StateManager,
    VerticalScroll,
    Facebook,
    SiteManager,
    Utils
){
    var LongformSeriesFlyout = BaseView.extend({

        //View element
        el: '.longform-series-flyout-bucket-wrapper',

        //Events
        events:{
            'click .longform-series-flyout-bucket-icon, .longform-series-flyout-bucket-text': 'openSeriesFlyout',
            'click .longform-series-up' : 'onClickSeriesUp',
            'click .longform-series-down' : 'onClickSeriesDown'
        },

        /**
        * Initialize view.
        */
        initialize: function (options) {
            this.$win = Utils.get('win');
            
            this.pubSub = {
                'closeSeriesFlyout': this.closeSeriesFlyout
            };
            
            this.$flyoutWindow = this.$('.longform-series-flyout');

            this.$upBtn = $('.longform-series-up');
            this.$downBtn = $('.longform-series-down');
            this.$topGrad = $('.longform-series-grad-top');
            this.$botGrad = $('.longform-series-grad-bot');

            this.$seriesNavItem = this.$('.longform-series-item');
            this.$seriesItemWrap = this.$('.longform-series-item-wrap');
            this.$seriesOverflow = this.$('.longform-series-overflow-wrap');
            this.seriesCount = 0;
            this.seriesOverflowPos = 0;
            this.seriesHeights = [];
            this.calcHeightBool = false;

            //Setup Series Nav & Overflows
            this.availableVertSpace = this.getAvailableVerticalSpace();        

            this.flyoutOptions = {
                'flyout': this.$flyoutWindow,
                'icon': 'longform-series-flyout-bucket',
                'iconSpace': '105px',
                'closeMethod': 'closeSeriesFlyout'
            };
            
            BaseView.prototype.initialize.call(this, options);
        },

        openSeriesFlyout: function(e){
            if (!this.calcHeightBool) {
                this._setSeriesWrapHeight();
            }
            PubSub.trigger('openFlyout', this.flyoutOptions);
        },

        closeSeriesFlyout: function(){
            //Close Series Flyout
            this.animate(this.$flyoutWindow, 'right', '-300px', 200); 
        },

        _setSeriesWrapHeight: function() {
            this.calcHeightBool = true;
            var space_needed = this.getSeriesHeights();
            var space_avail = this.availableVertSpace;

            if(space_needed > space_avail) {
                this.$seriesItemWrap.height(space_avail);
            } else {
                this.$seriesItemWrap.height(space_needed);
                this.$seriesOverflow.css({"position": "inherit"});
                this.$upBtn.hide();
                this.$downBtn.hide();
                this.$topGrad.hide();
                this.$botGrad.hide();
            }
        },

        getAvailableVerticalSpace: function() {
            var header = SiteManager.getHeader(),
                headerHeight = 0;
            if (header) {
                headerHeight = header.getCollapsedHeight();
            }
             return this.$win.height() - 320;
        },

        getSeriesHeights: function() {
            var total = 0;
            var that = this;
            this.$seriesNavItem.each(function(index) {
                that.seriesHeights.push($(this).height());
                total += $(this).height();
            });
            return total;
        },

        onClickSeriesDown: function(e) {
            if((this.seriesOverflowPos + this.$seriesOverflow.height() - this.availableVertSpace) > 0) {
                this.$upBtn.css('opacity', '1');
                this.animate(this.$seriesOverflow, 'top', (this.seriesOverflowPos -= this.seriesHeights[this.seriesCount])+'px', 100, 'linear');
                this.seriesCount++;
                if((this.seriesOverflowPos + this.$seriesOverflow.height() - this.availableVertSpace) < 0) {
                    this.$downBtn.css('opacity', '.5');
                }
            }
        },

        onClickSeriesUp: function(e) {
            if(this.seriesCount > 0) {                
                this.$downBtn.css('opacity', '1');
                this.animate(this.$seriesOverflow, 'top', (this.seriesOverflowPos += this.seriesHeights[this.seriesCount])+'px', 100, 'linear');
                this.seriesCount--;
                if(this.seriesCount === 0) {
                    this.$upBtn.css('opacity', '.5');
                }
            }
        }
    });
    
    return LongformSeriesFlyout;
});
