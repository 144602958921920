/**
 * @fileoverview Story_series view.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('apps/story-series',[
        'jquery',
        'underscore',
        'base-app',
        'pubsub',
        'state',
        'user-accounts/firefly-user-account'
    ],
    function(
        $,
        _,
        BaseApp,
        PubSub,
        StateManager)
    {
        var StorySeriesApp = BaseApp.extend({
            afterPageReveal: function() {
                // stop refresh timer
                StateManager.stopRefreshTimer();
            },

            beforeAppRemove: function() {
                StateManager.startRefreshTimer();
            }
        });

        return StorySeriesApp;
    }
);

