/**
 * @fileoverview User dropdown module (in the top nav).
 * @author erik.kallevig@f-i.com (Erik Kallevig)
 */
 
define('modules/users/site-nav-firefly-module',[
    'jquery',
    'underscore',
    'modules/header/site-nav-dropdown',
    'pubsub',
    'utils',
    'user-manager',
    'state',
    'user-accounts/firefly-user-account',
    'modules/global/expired-card',
    'admanager'
],
function(
    $,
    _,
    SiteNavDropdown,
    PubSub,
    Utils,
    UserManager,
    StateManager,
    FireflyUserAccount,
    ExpiredCard,
    AdManager
) {
    'use strict';

    /**
     * View class.
     */
    var FireflyDropdown = SiteNavDropdown.extend({

        events: {
            'click .ff-insider': 'onClickInsider',
            'mouseenter': 'onMouseEnter',
            'mouseleave': 'onMouseLeave',
            'click .site-nav-dropdown': 'closeDropdown'
        },

        initialize: function(options) {
            this.pubSub = {
                'page:load': this.updateOnPageLoad,
                'user:statuschange': this.showLoggedInStatus
            };

            this.$siteNavDropdown = this.$('.site-nav-dropdown');
            this.$navSpan = this.$('.site-nav-firefly-span');
            this.$name = this.$('.site-nav-firefly-user-display-name');

            this.loginData = Utils.getNested(window.site_vars, 'userAuthentication', 'login');
            this.logoutData = Utils.getNested(window.site_vars, 'userAuthentication', 'logout');

            AdManager.getActiveSlotStatistics().done(_.bind(function(info) {
                if (this.destroyed) {
                    return;
                }
                //ads finished being called, determine if high impact ad is present
                if (!info.high_impact && UserManager.getLoginStatus() == 'loggedIn' && Utils.getNested(window.site_vars, 'flags', 'expired_credit_card_modal')) {
                    var userAccount = UserManager.getAccount('firefly');
                    if (userAccount) {
                        userAccount.getUserInfo().done(_.bind(function(user) {
                            if (this.destroyed) {
                                return;
                            }
                            this.checkCreditCardStatus(user);
                        }, this));
                    }
                }
            }, this));

            this.userHasMarketAccess = false;
            this.logoutOverride = false;

            SiteNavDropdown.prototype.initialize.call(this, options);
        },

        /**
         * Update all '.userAuth-redirect' marked a tag URLs on the page with the current page's redirect URL. Updated to account for other QSPs existing and use the configuration settings for userAuth. 
         * @param {Event} e event
         */
        updateOnPageLoad: function(e) {
            // when cancelling, we go back to the current page
            var encodedPageURL = encodeURIComponent(window.location);
            // when succeeding, go back to the URL of the asset.  which might be the same but might not on the get-access page
            var encodedSuccessURL = encodeURIComponent(window.firefly_urls.onSuccessRedirectURL);
            var success_qsp =  this.loginData.success_qsp;
            var cancel_qsp = this.loginData.cancel_qsp;
            var logout_qsp = this.logoutData.return_qsp;
            var newQSP = '?' + success_qsp + '=' + encodedSuccessURL + '&' + cancel_qsp + '=' + encodedPageURL + '&' + logout_qsp + '=' + encodedSuccessURL ;
            
            $(".userAuth-redirect").each(function(index, link) {
                var append = "";
                var splitUrl;
                var qspList;
                var $link = $(link);
                var url = $link.attr('href');
                // this regex will match on query strings that start with the success or cancel qsp as defined by the site's config
                var hasSuccessCancelQSPs = new RegExp('^'+success_qsp+'|^'+cancel_qsp+'|^'+logout_qsp);

                if (_.isString(url)) {
                    // split the url into an array based on '?' and '&' characters
                    splitUrl = url.split(/[\?\&]/);
                    qspList = splitUrl.splice(1);
                    
                    if (qspList.length > 0) {
                        qspList = _.filter(qspList, function(param){ 
                            if(!hasSuccessCancelQSPs.test(param)){
                                return param;
                            }
                        });
                        append = '&' + qspList.join('&');
                    }
                    $link.attr('href', splitUrl[0] + newQSP + append);
                }
            });

            //Separate redirect URL used specifically for the account activation/lookup static page
            $(".userActivate-redirect").each(function(index, link) {
                var $link = $(link);
                var url = $link.attr('href');

                //Append old QSPs to the end, if they exist
                var splitUrl = url.split(/[\?\&]/);
                var oldQSP = splitUrl.slice(1).join('&');
                newQSP = '?onSuccessRedirectURL=' + encodedSuccessURL;
                $link.attr('href', splitUrl[0] + newQSP + '&' + oldQSP);
            });
        },

        showLoggedInStatus: function(accountName, loginStatus, userData) {
            if (accountName !== 'firefly') {
                return;
            }
            if (this.$navSpan) {
                this.$navSpan.addClass('loaded');
            }
            if (loginStatus === 'loggedIn') {
                this.showLoggedInUser(userData);
            } else if (loginStatus !== 'loggingIn') {
                this.showLoggedOutUser();
            }
        },

        /**
         * Checks to see if the user has an expired credit card or has failed their last payment
         */
        checkCreditCardStatus: function(user) {
            if(!user) {
                return false;
            }

            //if 24 hours haven't eclipsed the local timestamp, halt
            var date = new Date();
            if(date.getTime() < parseInt(localStorage.getItem('expired-credit-card')) + 86400000) {
                return false;
            }

            var market_id = Utils.getNested(window.site_vars, 'market_id'),
                user_licenses = user.licenses,
                market_license;

            _.each(user_licenses, function(marketId) {
                if(marketId.marketId == market_id) {
                    market_license = marketId;
                }
            });

            if(this._verifyExpiredLicense(market_license)) {
                this._setupExpiredCardSubview(market_license.licenseId);
            }
        },

         /**
         * Click insider in the user nav item.
         */
        onClickInsider: function() {
            PubSub.trigger('uotrack', "myaccount|insider");
        },

        _verifyExpiredLicense: function(license) {
            var date = new Date();

            if(!license) {
                return false;
            }

            if(!license.creditCardExpirationMonth || !license.creditCardExpirationYear) {
                return false;
            }

            if(license.lastPaymentFailed) {
                return true;
            }

            if(date.getFullYear() > parseInt(license.creditCardExpirationYear, 10)) {
                return true;
            }

            if((date.getFullYear() == parseInt(license.creditCardExpirationYear, 10) && (date.getMonth() + 1) > license.creditCardExpirationMonth)) {
                return true;
            }

            //check if the 15th of the month
            if((date.getFullYear() >= parseInt(license.creditCardExpirationYear, 10) && (date.getMonth() + 1) >= license.creditCardExpirationMonth) && date.getDate() >= 15) {
                return true;
            }
            
            return false;
        },

        _setupExpiredCardSubview: function(licenseId) {
            this.subviews.expiredcard = new ExpiredCard({
                licenseId: licenseId
            });
        },

        /**
         * Shows the user header nav item in the logged out state.
         */
        showLoggedOutUser: function() {
            this.$siteNavDropdown.removeClass('authenticated subscribed');
            this.$name.html('');
        },

        /**
         * Shows the user header nav item in the logged in state.
         */
        showLoggedInUser: function(userInfo) {
            var userAccount = UserManager.getAccount('firefly');
            this.$siteNavDropdown.addClass('authenticated');
            if (userInfo.hasMarketAccess) {
                this.userHasMarketAccess = true;
                this.$siteNavDropdown.addClass('subscribed');

                //check user license type for enewspaper nav link
                if (userInfo.userLicenseType !== 'paid_subscriber_basic') {
                    $('.ff-enewspaper', this.$siteNavDropdown).removeClass('hidden');
                }
            }

            this.$name.text(userAccount.getWelcomeName());
            if (userAccount.getUserPhoto()){
                this.$navSpan.css({"background-image": "url("+userAccount.getUserPhoto()+")"}).addClass('no-after');
            }
        },

        destroy: function() {
            SiteNavDropdown.prototype.destroy.call(this);
        }
    });

    return FireflyDropdown;

});

