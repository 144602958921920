/**
 * @fileoverview Marketplace Carousel
 * @author ahorner@gannett.com, bsmith12@gannett.com
 */
define('modules/marketplace-carousel',[
        'jquery',
        'underscore',
        'baseview',
        'utils',
        'form/dropdown',
        'form/radio-button',
        'modules/partner/sponsor-logo-ad',
        'modules/global/alert'
    ],
    function ($,
              _,
              BaseView,
              Utils,
              SelectDropdown,
              RadioButton,
              SponsorLogoAd,
              Alert) {

        var MarketplaceCarousel = BaseView.extend({

            events: {
                'click .mpcms-item': 'suspenderItemClick'
            },

            initialize: function (options) {
                BaseView.prototype.initialize.call(this);
                this.subviews.homesView = new HomesView({el: $(".mpcm-homes")});
                this.subviews.businessesView = new BusinessesView({el: $(".mpcm-businesses")});
                this.subviews.circularsView = new CircularsView({el: $(".mpcm-circulars")});
                this.subviews.jobsView = new JobsView({el: $(".mpcm-jobs")});
                this.subviews.carsView = new CarsView({el: $(".mpcm-cars")});

                // Match suspender order so we can relate suspender to subviews
                this.subview_array = [
                    this.subviews.homesView,
                    this.subviews.businessesView,
                    this.subviews.circularsView,
                    this.subviews.jobsView,
                    this.subviews.carsView,
                ];

                // Select a random element from the suspender container
                var randomModule = this.$(_.shuffle(this.$(".mpcms-item"))[0]);
                this.animateSelectModule(randomModule);
            },

            suspenderItemClick: function (ev) {
                var elem = this.$(ev.currentTarget);
                this.$(".mpcms-item-selected").removeClass("mpcms-item-selected");
                this.animateSelectModule(elem);
            },

            animateSelectModule: function (suspenderEl) {
                suspenderEl.addClass("mpcms-item-selected");
                var itemMktType = suspenderEl.attr('data-mkt-type');
                Utils.lazyLoadImage($('.mpcm-' + itemMktType + ' .mpcm-visible-items img'));
                var itemWidth = this.$(".mpcm-primary").width();
                this.$(".mpcm-container").css({
                    left: (-itemWidth * suspenderEl.index()) + "px"
                });
                if (this.subview_array[suspenderEl.index()]) {
                    this.subview_array[suspenderEl.index()].init_ad();
                }
            }

        });

        var MarketplaceSubview = BaseView.extend({

            init_ad: function() {
                if (!this.ad_placed) {
                    this.subviews.sponsoredAdView = new SponsorLogoAd(
                        {el: this.$(".mpcm-sponsored-by-ad")}
                    );
                    this.ad_placed = true;
                }
            }

        });

        var HomesView = MarketplaceSubview.extend({
            events: {
                'click .mpcm-hf-search': 'search'
            },

            initialize: function (options) {
                BaseView.prototype.initialize.call(this);
                this.el = options.el;
                this.actionPlacester = ["",""];
                this.$priceFilterPane = this.$('.psfm-hf-price-filter-pane');
                this.$priceOptions = this.$('.psfm-hf-form-price-options');
                this.$priceLabel = this.$('.psfm-hf-form-price-label');

                var dropdowns = {
                    minBed: new SelectDropdown({
                        el: this.$(".mpcm-hf-beds")
                    }),
                    minBath: new SelectDropdown({
                        el: this.$(".mpcm-hf-baths")
                    }),
                    propertyType: new SelectDropdown({
                        el: this.$(".mpcm-hf-type")
                    }),
                    minPrice: new SelectDropdown({
                        el: this.$(".mpcm-hf-min-price")
                    }),
                    maxPrice: new SelectDropdown({
                        el: this.$(".mpcm-hf-max-price")
                    })
                };

                _.extend(this.subviews, dropdowns);
                var randomElements = this.$(_.shuffle(this.$('.mpcm-home-listing'))).slice(0,3);
                $(randomElements).removeClass('mpcm-home-listing-hidden').addClass('mpcm-visible-items');
            },

            search: function (e) {
                  var area = this.$(".mpcm-input").val().toLowerCase();
                  if (area.match(/(\d{5}(?:-\d{4})?)/)) {
                      // Matches if a zip/zip+4 code is provided in the area query, such as 10001, 10001-2334.
                      // We check for this first as this is the most authoritative information that can be provided.
                      // Zip code is more specific than just city/state.
                      this.actionPlacester[0] = "zip=";
                      this.actionPlacester[1] = area.match(/(\d{5}(?:-\d{4})?)/)[1];
                  }
                  else if (area.trim().match(/^([^,]+),\s*(\w{2})$/)) {
                      // Matches a city and two letter state, such as "New York, NY" and uses just the city
                      var areaArray = area.trim().match(/^([^,]+),\s*(\w{2})$/);
                      this.actionPlacester[0] = "locality=";
                      this.actionPlacester[1] = encodeURIComponent((areaArray[1].charAt(0).toUpperCase() + areaArray[1].slice(1)));
                  }
                  else if (Number.isInteger(area)) {
                      // Matches if the only information provided is a zip code.
                      this.actionPlacester[0] = "zip=";
                      this.actionPlacester[1] = area;
                  }
                  else if (area.length === 0) {
                      // Used if there is no input query.  Other search parameters are still possible.
                      this.actionPlacester[0] = "text_search=";
                      this.actionPlacester[1] = "";
                  }
                  else {
                      // Fallback.  If query does not match anything, perform a generic search on Placester.
                      this.actionPlacester[0] = "text_search=";
                      this.actionPlacester[1] = encodeURIComponent(area);
                  }

                  this.actionPlacester[2] = "&min_beds=" + $('.mpcm-hf-beds').val();
                  this.actionPlacester[3] = "&min_baths=" + $('.mpcm-hf-baths').val();
                  this.actionPlacester[4] = "&min_price=" + $('.mpcm-hf-min-price').val();
                  this.actionPlacester[5] = "&max_price=" + $('.mpcm-hf-max-price').val();

                  var attributeSearch = _.reduce(this.actionPlacester, function(memo, num) { return memo + num; });
                  var addons = 'view=gallery&purchase_types=buy&';
                  $('.mpcm-hf-search').attr("href", $(".mpcm-hf-search-form").attr("action") + "?" + addons + attributeSearch);
            }

        });

        var BusinessesView = MarketplaceSubview.extend({

            events: {
                'click .mpcm-bd-search': 'search'
            },

            initialize: function (options) {
                BaseView.prototype.initialize.call(this);
                this.el = options.el;

                var randomElements = $(_.shuffle($('.mpcm-bd-featured-item'))).slice(0,3);
                $(randomElements).removeClass('mpcm-bd-featured-hidden').addClass('mpcm-visible-items');

                var dropdowns = {
                    category: new SelectDropdown({
                        el: this.$(".mpcm-businesses-category"),
                        onSelect: this.onCategoryChanged
                    })
                };

                _.extend(this.subviews, dropdowns);
            },

            onCategoryChanged: function (value, display, selected_item) {
                $('.mpcm-bd-category-link').data('track-label', 'marketplacebusiness-businesscategory_' + value);
                $('.mpcm-bd-category-link').attr('href', 'http://local.' + this.el.data('base-url') +
                    '/all/' + value
                );
                $('.mpcm-bd-category-link').click();
            },

            search: function (e) {
                $('.mpcm-bd-search-form').submit();
            }

        });

        var CircularsView = MarketplaceSubview.extend({

            events: {
                'click .mpcm-ci-search-button': 'search'
            },

            initialize: function (options) {
                BaseView.prototype.initialize.call(this);
                this.el = options.el;

                var randomElements = $(_.shuffle($('.mpcm-ci-featured-item'))).slice(0,3);
                $(randomElements).removeClass('mpcm-ci-featured-hidden').addClass('mpcm-visible-items');

                var dropdowns = {
                    category: new SelectDropdown({
                        el: this.$(".mpcm-circulars-category"),
                        onSelect: this.onCategoryChanged.bind(this)
                    })
                };

                _.extend(this.subviews, dropdowns);
            },

            onCategoryChanged: function (value, display, selected_item) {
                this.search_category = value;
            },

            search: function(e) {
                var base_url = $(".mpcm-circulars-category").data('base-url');
                if (this.search_category) {
                    $('.mpcm-ci-search-button').attr('href', 'http://findnsave.' + base_url + '/find/?category=' +
                        this.search_category + '&q=' +
                        $('.mpcm-ci-keywords').val() + '&ac=marketplace_circulars-api'
                    );
                } else {
                    $('.mpcm-ci-search-button').attr('href', 'http://findnsave.' + base_url + '/find/?q=' +
                        $('.mpcm-ci-keywords').val() + '&ac=marketplace_circulars-api'
                    );
                }
            }

        });

        var JobsView = MarketplaceSubview.extend({

            events: {
                'click .mpcm-jobs-search-button': 'search'
            },

            initialize: function (options) {
                BaseView.prototype.initialize.call(this);
                var randomElements = $(_.shuffle($('.mpcm-jobs-item'))).slice(0,3);
                $(randomElements).removeClass('mpcm-jobs-hidden').addClass('mpcm-visible-items');
            },

            slugify: function(str){
                return str.toString().trim().toLowerCase().replace(/(\s+|[^\w\-]|\-\-+)/g, '-');
            },

            search: function(e) {
                var url = "";
                var wrapperElement = document.getElementById("jobs-search-wrapper");
                if (wrapperElement) {
                    url = wrapperElement.getAttribute("data-url");
                }

                var keywordElement = document.getElementById("jobs-search-keyword");
                if (keywordElement) {
                    var keyword = this.slugify(keywordElement.value.toLowerCase());
                    if (keyword) {
                        url += keyword;
                    }
                }

                url += "-jobs-in-";

                var locationElement = document.getElementById("jobs-search-location");
                if (locationElement) {
                    var location = this.slugify(locationElement.value.toLowerCase().replace(",", ""));
                    if (location) {
                        url += location;
                    }
                }

                window.open(url, "_blank");
            }

        });

        var CarsView = MarketplaceSubview.extend({

            events: {
                'click .mpcm-cars-search-button': 'search',
                'click .mpcm-cars-sell-button': 'sell'
            },

            initialize: function (options) {
                BaseView.prototype.initialize.call(this);
                this.el = options.el;

                var randomElements = $(_.shuffle($('.mpcm-cars-item'))).slice(0,3);
                $(randomElements).removeClass('mpcm-cars-hidden').addClass('mpcm-visible-items');

                _.extend(this.subviews, {
                    make: new SelectDropdown({
                        el: this.$(".mpcm-cars-makes"),
                        onSelect: _.bind(this.onMakeChanged, this)
                    }),
                    model: new SelectDropdown({
                        el: this.$(".mpcm-cars-models"),
                    }),
                    maxprice: new SelectDropdown({
                        el: this.$(".mpcm-cars-maxprice"),
                    }),
                    radius: new SelectDropdown({
                        el: this.$(".mpcm-cars-radius"),
                    })
                });

                var radioInputs = this.$('.mpcm-cars-stock-type').find('.ui-radio'),
                    radioViews = [];

                _.each(radioInputs, function(el, index){
                    radioViews[index] = new RadioButton({
                        el: $(el)
                    });
                });

                $.getJSON('/marketplace-carousel/cars_makes.json', _.bind(function (data) {
                    this.carMakesModels = data;
                    this.updateSelectList(this.subviews.make, "All Makes", this.carMakesModels);
                }, this));
            },

            onMakeChanged: function (dataValue, displayValue, selectedItem) {
                var make = _.findWhere(this.carMakesModels, {value: String(dataValue)}),
                    models = (make === null) ? [] : make.models;
                    //Clears out the model list if 'All Makes' selected
                this.updateSelectList(this.subviews.model, "All Models", models);
            },

            updateSelectList: function (subview, defaultText, arr) {
                var defaultOptions = "<option value>" + defaultText + "</option>";
                subview.$el.html(defaultOptions);

                _.forEach(arr, function (item) {
                    subview.$el.append($("<option></option>").attr("value", item.value).text(item.name));
                });

                subview.refresh(subview.$el.html());
            },

            search: function(e) {
                var destination = "https://www.cars.com/for-sale/searchresults.action/?" +
                        "searchSource=ADVANCED",
                    checked = $('input[name=mpcm-cars-stock-type]:checked', '.mpcm-cars-search-form').val(),
                    make = $(".mpcm-cars-makes").val(),
                    model = $(".mpcm-cars-models").val(),
                    maxprice = $(".mpcm-cars-maxprice").val(),
                    radius = $(".mpcm-cars-radius").val(),
                    zip = $(".mpcm-cars-zip").val();

                if (zip === '') {
                    Alert.showError('Please enter zip code');
                    return;
                }

                if (checked == 'new') {
                    destination += "&stkTypId=28880&stkTyp=N&tracktype=newcc";
                } else if (checked == 'used') {
                    destination += "&stkTypId=28881&stkTyp=U&tracktype=usedcc";
                } else if (checked == 'cpo') {
                    destination += "&stkTypId=28881&stkTyp=U&tracktype=usedcc&cpoId=28444&cpo=Y";
                }


                if (make != 'all') {
                    destination += "&mkId=" + make;
                }

                if (model != 'all') {
                    destination += "&mdId=" + model;
                }

                if (maxprice != 'all') {
                    destination += "&prMn=0&prMx=" + maxprice;
                }

                destination += "&rd=" + radius;
                destination += "&zc=" + zip;

                $('.mpcm-cars-search-button').attr('href',destination);
            }

        });

        return MarketplaceCarousel;
    }

);

