/**
 * @fileoverview story_series header view module.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('modules/story-series-promo',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'adPosition',
    'utils'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    AdPosition,
    Utils
) {
        var StorySeriesPromo = BaseView.extend({
            /**
             * Initialize view.
             */
            initialize: function() {
                BaseView.prototype.initialize.call(this);

                this.$window = Utils.get('win');
                this.$sponsorshipAd = this.$('.ssa-promo-rectangle-ad');
                this.$sponsorshipDynamicAd = this.$('.ssa-promo-ad');
                this.$banner = this.$('.ssa-banner-ad');
                this.$leaderboard = this.$('.ssa-leaderboard-ad');
                this.buildStaticSponsorshipAds();

                _.bindAll(this, '_handleResizeWindow');
                StateManager.registerResizeListener('ssa-promo', this._handleResizeWindow, 500, true);
            },

            _handleResizeWindow: function() {
                var isMobileView = this.$window.width() < 768,
                    $adPlacement = isMobileView ? this.$banner : this.$leaderboard;
                if (this.$lastAdPlacement !== $adPlacement) {
                    this.buildDynamicSponsorshipAds($adPlacement);
                }
                this.$lastAdPlacement = $adPlacement;
            },

            /**
             * Initializes the poster ad as soon as the page is loaded
            */
            buildStaticSponsorshipAds: function(){
                if (this.$sponsorshipAd.length) {
                    this.subviews.sponsorshipAd = new AdPosition({
                        el: this.$sponsorshipAd,
                        adPlacement: 'poster',
                        adSizes: [[300, 250], [300, 600]],
                        pageInfo: StateManager.getActivePageInfo()
                    });
                }
            },

            /**
             * Initializes the banner or poster ad any time the screen width changes
            */
            buildDynamicSponsorshipAds: function($adPlacement) {
                var data = $adPlacement.data();
                if(data && data.adPlacement && data.adSizes) {
                    if(this.subviews.sponsorshipDynamicAd) {
                        this.subviews.sponsorshipDynamicAd.destroy();
                        $adPlacement.empty();
                    }
                    this.subviews.sponsorshipDynamicAd = new AdPosition({
                        el: $adPlacement,
                        adPlacement: data.adPlacement,
                        adSizes: data.adSizes,
                        pageInfo: StateManager.getActivePageInfo()
                    });
                }
            },

            closeAd: function() {
                StateManager.unRegisterResizeListener('ssa-promo');
                BaseView.prototype.destroy.apply(this, arguments);
            },

            destroy: function(removeEl) {
                this.closeAd();
            }
        });

        return StorySeriesPromo;
    }
);
