/**
* @fileoverview JS functions for league index of teams.
* @author kris.steigerwald@gmail.com
**/
define('modules/sports/leagues',['jquery', 'underscore', 'backbone', 'utils', 'pubsub'],
function($, _, Backbone, Utils, PubSub) {
    var LeaguesView = Backbone.View.extend({
        el: '#section_sports',

        events: {
            'click #teams_toggle ul li a': 'onToggle',

        },

        initialize: function(path, sectionPath) {
            this.$teams = this.$('div.teams');
            this.$ncaaf = this.$('#ncaaf_div_selector');
        },

        onToggle: function(e) {
            var value = $(e.currentTarget).data('value'),
                toggle = $(e.currentTarget).data('toggle'),
                selector = (toggle == 'conf') ? 'div[data-conf="' + value + '"]' : 'div[data-div="' + value + '"]',
                heattrack = $(e.currentTarget).data('ht'),
                $group = $(selector);
                
            if(value === 'all')
                this.$teams.show();
            else {
                this.$teams.hide();
                $group.show();
            }

            if (heattrack) {
                PubSub.trigger('uotrack', heattrack);
            }
        },
        destroy: function() {
            this.undelegateEvents();
        }

    });

    return LeaguesView;
});

