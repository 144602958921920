/**
 * @fileoverview Line Graph (interactive) view.
 * @author Stan Wilson Jr, William Broza
 */
define('modules/interactives/line-chart',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'modules/interactives/interactive-base'
],
    function($, _, BaseView, Utils, InteractiveBase) {
        "use strict";
        var LineGraphView = InteractiveBase.extend({

            // Loop through each row to build the flot data array.
            getFlotData: function(dataset) {
                //TODO: Clean up on server side to normalize
                if (dataset === undefined) {
                    return null;
                }
                if (!_.isArray(dataset)) {
                    dataset = [dataset];
                }
                return _.map(dataset, function(item, index, dataset) {
                    var color =  this.mainColor;
                    if (dataset.length > 1) {
                        color = this.chartColors[index % this.chartColors.length];
                    }
                    return {
                        label: item.label || '',
                        color: color,
                        data: _.map(item.value, function(value, index) {
                            return [index, value];
                        }),
                        points: {
                            fillColor: color
                        }
                    };
                }, this);
            },

            _getMinValue: function(flotData) {
                var min = Number.MAX_VALUE, max = Number.MIN_VALUE;
                _.each(flotData, function(item) {
                    _.each(item.data, function(d) {
                        d = parseFloat(d[1]);
                        if (d < min) {
                            min = d;
                        }
                        if (d > max) {
                            max = d;
                        }
                    });
                });
                if (min === Number.MAX_VALUE) {
                    // we have no data points, wtf are we doing here?
                    min = max = 0;
                } else {
                    if (min > 0) {
                        min = 0;
                    }
                    var margin = (max - min) * 0.1;
                    max += margin;
                    if (min < 0) {
                        min -= margin;
                    }
                }

                return [min, max];
            },

            getFlotOptions: function() {
                var datasetLength = Utils.getNested(this.flotData, 0, 'data', 'length') || 0,
                    minMax = this._getMinValue(this.flotData),
                    flotOptions = InteractiveBase.prototype.getFlotOptions.apply(this, arguments);

                flotOptions.legend = {
                    show: false,
                    noColumns: 1
                };
                flotOptions.xaxis = {
                    ticks: this._createTicks(this.chartData.xaxisvalues || ''),
                    autoscaleMargin: 0.03,
                    position: 'bottom',
                    borderColor: 'rgb(237,236,236)'
                };
                flotOptions.yaxis = {
                    position: 'left',
                    labelHeight: 25,
                    borderColor: 'rgb(237,236,236)',
                    min: minMax[0],
                    max: minMax[1],
                    tickFormatter: this.numberWithCommas
                };
                flotOptions.series = {
                    lines: {
                        show: true
                    }
                };
                flotOptions.grid = {
                    borderWidth: 1,
                    borderColor: 'rgb(237,236,236)',
                    labelMargin: 5,
                    axisMargin: 0,
                    clickable: true,
                    hoverable: true,
                    autoHighlight: true
                };
                flotOptions.colors = this.colors;

                // Check for length of data
                if (datasetLength < 13) {
                    flotOptions.series.points = {
                        show: true,
                        radius: 5,
                        fill: true
                    };
                } else if (datasetLength > 13) {
                    flotOptions.series.points = {
                        show: false
                    };
                }
                return flotOptions;
            },

            _getHoverBody: function(obj) {
                return [this.getHoverPrefix(),
                    Utils.addCommas(Utils.getNested(obj, 'datapoint', '1')) || '',
                    this.getHoverSuffix()].join(' ');
            }
        });

        /**
         * Return view class.
         */
        return LineGraphView;
    });

