/**
 * @fileoverview Live Sports Stats Desktop
 * @author cbron@gannett.com (Caleb Bron)
 */

define('apps/live-sports-stats',[
        'jquery',
        'underscore',
        'base-app',
        'state',
        'pubsub',
        'adPosition'
    ],
    function(
        $,
        _,
        BaseApp,
        StateManager,
        PubSub,
        AdPosition
    ) {
        "use strict";
        /**
         * View class.
         */

        var LiveSportsStatsApp = BaseApp.extend({
            /**
            * Events
            */
            events: {
                'click .lssd-play-title' : 'showPlays'
            },

            /**
             * Initialize view.
             */
            initialize: function() {
                BaseApp.prototype.initialize.call(this);
                _.bindAll(this, 'checkGameOnDesktop');

                this.data_el = $('.lssd-gameon');
                this.$sponsorAd = $('.lssd-feature-rectangle-ad');
                this.liveCheckAllowed = true;
                this.buildAd();
                this.lssmGameOnPoll = setInterval(this.checkGameOnDesktop, 15000); //15 seconds
            },

            /**
             * Initializes the ad as soon as page is loaded
            */
            buildAd: function(){
                if (this.$sponsorAd.length) {
                    this.subviews.sponsorAd = new AdPosition({
                        el: this.$sponsorAd,
                        adPlacement: 'poster_front',
                        adSizes: [300, 250],
                        pageInfo: StateManager.getActivePageInfo(),
                    });
                }
                PubSub.trigger('advertising:defined');
            },

            /**
             * When a user clicks on a play period this opens or closes the panel
            */
            showPlays: function(e){
                if ($(e.currentTarget).parent().hasClass('lssd-play-closed')) {
                    $(e.currentTarget).next('.lssd-play-details').slideDown('fast', function () {
                        $(e.currentTarget).parent().removeClass('lssd-play-closed').addClass('lssd-play-open');
                    });
                } else {
                    $(e.currentTarget).next('.lssd-play-details').slideUp('fast', function () {
                        $(e.currentTarget).parent().removeClass('lssd-play-open').addClass('lssd-play-closed');
                    });
                }
             },

            /**
             * See if live update is necessary, and if so, execute it
            */
            checkGameOnDesktop: function() {
                if (!this.liveCheckAllowed) return;
                this.liveCheckAllowed = false;

                if (this.data_el.data('status') != 'post-event'){
                    $.ajax({
                        type: 'POST',
                        url: '/gameon/'+this.data_el.data('sport')+'/'+ this.data_el.data('event') +'/desktop_json/',
                        success: function(json){
                            this.injectHtml(json);
                        }.bind(this),
                        error: function(){
                        }.bind(this)
                    });
                }
            },

            /**
             * Given the partial's html, add into live page
             * @param {JSON} json
            */
            injectHtml: function(json){
                if (json.matchup){
                    $("#lssd-matchup").html(json.matchup);
                }
                if (json.stats){
                    $("#lssd-stats").html(json.stats);
                }
                if (json.plays){
                    this.populatePlays(json.plays);
                }
                if (json.data && json.data.event_status != 'post-event'){
                    this.liveCheckAllowed = true;
                }
            },

            /**
             * Gather current state of div (user may have opened a div or scrolled), inject html, then restore state.
             * For restoring, the scroll has to be last to account for height changes with open panels
             * @param {String} plays
            */
            populatePlays: function(plays){
                var scroll_position = $('.lssd-panel').scrollTop();
                var open_panels = [];
                $(".lssd-play-open").each(function(){ open_panels.push($(this).data('play-period')); });

                $("#lssd-plays").html(plays);

                $(".lssd-play-summary").removeClass('lssd-play-open').addClass('lssd-play-closed');
                $(open_panels).each(function(){
                    $(".lssd-plays").find("[data-play-period='" + this + "']").addClass('lssd-play-open').removeClass('lssd-play-closed');
                });
                $('.lssd-panel').scrollTop(scroll_position);
            },

        });

        /**
         * Return view class.
         */
        return LiveSportsStatsApp;
    }
);

