/* global define */
define('modules/videos/uw/uw-wrapper',[
    'jquery',
    'underscore',
    'baseview',
    'managers/routemanager',
    'modules/videos/uw/uw-sp',
    'modules/videos/pluto/pluto-utils',
    'managers/autoplay',
    'utils',
    'pubsub',
    'state'
],
function(
    $,
    _,
    BaseView,
    RouteManager,
    UwSP,
    PlutoUtils,
    AutoPlayManager,
    Utils,
    PubSub,
    StateManager
){
    'use strict';
    var UwWrapper = BaseView.extend({
        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);

            this.video = PlutoUtils.buildData(this.$('.ui-video-data'));
            this.placement = PlutoUtils.buildData(this.$('.ui-video-placement-data'));
            if (this.placement.enableUwPlayer) {
                this.uwIframe = this.$('.uw-iframe')[0];
            } else if (this.placement.enableSnowPlayer) {
                this.uwIframe = this.$('.snow-iframe')[0];
            }
            this.playerReady = $.Deferred();
            this.fullScreenEnabled = false;
            this.buffering = true;
            if (this.placement.extraAnalyticsEnabled) {
                PubSub.trigger('uotrack', 'videopageload');
            }
            // NOTE: start must be called first to initialize playback (not play)
            if (this.placement.enableUwPlayer) {
               this.messages = {
                    docked: 'universal-web-player-docked',
                    notDocked: 'universal-web-player-not-docked',
                    pause: 'universal-web-video-pause',
                    start: 'universal-web-video-start',
                    play: 'universal-web-video-play',
                    isUwKickOffReady: 'universal-web-ready-for-kickoff'
               };
            } else if (this.placement.enableSnowPlayer) {
                this.messages = {
                    docked: 'uw-docked',
                    notDocked: 'uw-undocked',
                    pause: 'uw-pause',
                    start: 'uw-play',
                    play: 'uw-play',
                    isUwKickOffReady: 'uw-ready?'
               };
            }

            _.bindAll(this, 'onPostMessage', 'startAutoPlay', 'isUwIframeLoaded');
            this.setupUwListeners();
            /**
             * Trigger video load if autoplay.
             * Default load triggered by user action
             */
            if (this.placement.uwAutoplay) {
                AutoPlayManager.register('pluto-video', this.startAutoPlay);
            }
            /**
             * Initialize scrolling player
             */
            if (this.placement.scrollingplayer) {
                 if (Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'docked')) {
                     this.sp = new UwSP({
                         el: this.$el,
                         video: this.video,
                         pause: _.bind(this.pause, this),
                         dockedStatus: _.bind(this.dockedStatus, this),
                         placement: this.placement,
                         buffering: _.bind(this.isBuffering, this),
                         pauseAfterBuffer: _.bind(this.pauseAfterBuffer, this)
                     });
                 }
            }

            // Keep checking to see if uw video element has been setup on the window then resolve promise
            this.uwIframeLoaded = setInterval(this.isUwIframeLoaded, 250);
            if (window._sf_async_config) {
                var uwIframeBaseurl = Utils.getNested(window.site_vars, 'html5video', 'uwIframe', 'baseUrl'),
                    siteBaseUrl = Utils.getNested(window.site_vars, 'base_url');
                window._sf_async_config.playerdomain = uwIframeBaseurl + '.' + siteBaseUrl || '';
            }

            if (this.isPlaylist()) {
                this.playerReady.done(_.bind(function() {
                    if (this.$('#uwSpin').length) this.$('#uwSpin')[0].hidden = true;
                }, this));
            }
        },

        isUwIframeLoaded: function () {
            this.messageUwPlayer(this.messages.isUwKickOffReady);
        },

        startAutoPlay: function () {
              return this.playerReady.done(_.bind(function() {
                  this.messageUwPlayer(this.messages.start);
                  return true;
              }, this));
        },
        setupUwListeners: function () {
            PlutoUtils.getPostMessage(true, this.onPostMessage);
        },
        removeUwListeners: function () {
            PlutoUtils.removeListener(this.onPostMessage);
        },
        pause: function () {
            this.messageUwPlayer(this.messages.pause);
        },
        pauseAfterBuffer: function () {
          this.forcedPaused = true;
        },
        onPostMessage: function (e) {
            if (this.uwIframe.src.indexOf(e.origin) !== -1) {
                var msg, id, _event;
                if (typeof e.data === 'string') {
                    if (this.placement.enableUwPlayer) {
                        if (e.data.indexOf('universal-web') !== -1) {
                            // sample event: "99831302/universal-web-video-pause"
                            msg = e.data.split('/');
                            id = msg[0];
                            _event = msg[1];
                        }
                    } else if (this.placement.enableSnowPlayer) {
                        if (e.data.indexOf('snow-player') !== -1) {
                            // sample event: "snow-player/99831302/{controller}/{state} or snow-player/99831302/video/paused"
                            msg = e.data.split('/');
                            id = msg[1];
                            _event = msg[2] + '-' + msg[3];
                        }
                    }
                    switch (_event) {
                        case 'universal-web-kickoff-ready':
                        case 'video-ready':
                            this.playerReady.resolve();
                            clearInterval(this.uwIframeLoaded);
                            break;
                        case 'universal-web-video-complete':
                        case 'video-done':
                            if (this.isThisVideo(id)) {
                                this.onEnd();
                            }
                            break;
                        case 'universal-web-ad-complete':
                        case 'preroll-done':
                            this.onPlay(id);
                            this.buffering = true;
                            break;
                        case 'universal-web-ad-started':
                        case 'preroll-started':
                            this.onPlay(id);
                            this.buffering = false;
                            break;
                        case 'universal-web-video-play':
                        case 'universal-web-ad-play':
                        case 'universal-web-video-playing':
                        case 'video-playing':
                        case 'preroll-playing':
                            if (this.isPlaylistFront()) StateManager.stopRefreshTimer();
                            if (this.placement.enableUwPauseOnScroll) {
                                PubSub.trigger('uotrack', 'uw-userplay');
                                if (this.sp.autopausedCalled && this.sp.playerIsScrolling) {
                                    this.sp.autopausedCalled = false;
                                    PubSub.trigger('uotrack', 'uw-dockedplayafterautopause');
                                }
                            }
                            this.onPlay(id);
                            this.buffering = false;
                            if (this.forcedPaused) {
                                this.pause();
                                this.forcedPaused = false;
                            }
                            break;
                        case 'universal-web-video-paused':
                        case 'universal-web-ad-paused':
                        case 'video-paused':
                        case 'preroll-paused':
                            if (this.isPlaylistFront()) StateManager.startRefreshTimer();
                            if (this.placement.enableUwPauseOnScroll) {
                                PubSub.trigger('uotrack', 'uw-userpause');
                            }
                            if (this.isThisVideo(id)) {
                                this.onPause();
                            }
                            break;
                        case 'universal-web-enter-fullscreen':
                        case 'video-enter-fullscreen':
                            this._onFullScreenEnter();
                            break;
                        case 'universal-web-exit-fullscreen':
                        case 'video-exit-fullscreen':
                            this._onFullScreenExit();
                            break;
                        default:
                            break;

                    }
                } else if (typeof e.data === 'object') {
                    // make sure we have normalized data in object for nextVideo
                    var data = e.data;
                    if (!data.title && !data.description) return;
                    // continuous play data
                    this.nextVideo = data;
                }
            }
        },
        isBuffering: function () {
             return this.buffering;
        },
        isThisVideo: function (id) {
            return this.video && id === this.video.id.toString();
        },
        onEnd: function () {
            if (this.isPlaylist()) return;
            if (this.sp) {
                this.sp._onEnd();
            }
            if (this.nextVideo) {
                if (this.placement.name === 'videoassetplayerhtml5' && this.nextVideo.canonicalUrl) {
                    this.nextVideo.url = this.nextVideo.canonicalUrl.replace(/^.*\/\/[^\/]+/, '');
                    var timeOut = Utils.getNested(window.site_vars,'html5video', 'uwIframe','taboolaTimeout');
                    // setting timeout for some Taboola calls to be fired before routing to next page
                    window.setTimeout(_.bind(function () {
                        RouteManager.goTo(this.nextVideo.url);
                    }, this), timeOut);
                } else if (this.placement.name.match(/smallarticle|immersiveplayer/gi)) {
                    // update description and credits for continuous play videos on stories
                    if (this.placement.name.indexOf('immersiveplayer') !== -1) {
                        var section = this.nextVideo.ssts.section;
                        $('.parent-label').text(section).attr('class', 'parent-label ' + section);
                        $('h1.cinematic-headline-div').text(this.nextVideo.title);
                    }
                    this.$el.parent().find('.video-desc').html(this.nextVideo.description +"<span class="+"credit"+">"+this.nextVideo.author+"</span>");
                }
            }
        },
        isPlaylist: function () {
            return this.$$('.uw-playlist-iframe').length;
        },
        isPlaylistFront: function () {
            return this.isPlaylist() && this.video.type === 'front';
        },
        isSnowPlayer: function () {
            return this.placement && this.placement.enableSnowPlayer;
        },
        /**
         * Pause this video if another uw video played elsewhere on the page
         */
        onPlay: function (id) {
            if (this.isPlaylist() && !this.isSnowPlayer()) return;
            if (!this.isThisVideo(id)) {
                this.pause();
            } else if (this.sp) {
                this.sp._onPlay();
            }
        },
        onPause: function () {
            if (this.isPlaylist()) return;
            if (this.sp) {
                this.sp._onPause();
            }
        },
        dockedStatus: function (docked) {
            if (docked) this.messageUwPlayer(this.messages.docked);
            else this.messageUwPlayer(this.messages.notDocked);
        },
        _onFullScreenEnter: function () {
            this.fullScreenEnabled = true;
            this.onFullScreenEnter();
        },
        _onFullScreenExit: function () {
            this.fullScreenEnabled = false;
            this.onFullScreenExit();
        },
        onFullScreenEnter: function () {
            // no op
        },
        onFullScreenExit: function () {
            // no op
        },
        messageUwPlayer: function (action) {
            if (!this.uwIframe.contentWindow) {
                this.uwIframe = this.$('.uw-iframe')[0];
            }
            PlutoUtils.setPostMessage(true, this.uwIframe.contentWindow, action);
        },
        /**
         * Destroys the view
         */
        destroy: function () {
            this.removeUwListeners();
            clearInterval(this.uwIframeLoaded);
            if (this.isPlaylistFront()) {
                this.pause();
                StateManager.startRefreshTimer();
            }
            BaseView.prototype.destroy.apply(this, arguments);
        }
    });

    return UwWrapper;
});

