require(['framework-config'], function(frameworkConfig) {
    // load default require.js config file
    require.config(frameworkConfig);
    require([
        'api/analytics',
        'abtest-manager',
        'template-manager',
        'site-manager',
        'state',
        'modules/global/header',
        'migrations'
    ],
    function(Analytics, AbTestManager, TemplateManager, SiteManager, StateManager, Header) {
        // start the site
        SiteManager.start(!window.chromeless && Header, window.site_vars.flags.enable_ajax);
    });
});

define("main", function(){});

