/**
 * @fileoverview HomeFinder Apartments
 * @author mguirat@gannett.com (Marouan BEN GUIRAT)
 */
define('modules/home-finder/home-search',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function(
    $,
    _,
    BaseView,
    Utils
    ) {
    "use strict";
    /**
     * View class.
     */
    var HomeFinder = BaseView.extend({
        events: {
        //DO NOT CHANGE THE EVENT FOCUS
        'focus .mpa-hf-form-price-input' : 'inputFocus',
        'click .mpa-hf-form-label' : 'generalDropdown',
        'click .mpa-hf-form-price-options .mpa-hf-form-option' : 'priceClick',
        'change .mpa-hf-form-price-input' : 'inputChange',
        'click .mpa-hf-filter-option' : 'criteriaClick',
        'click .mpa-hf-btn' : 'searchHomeFinder'
        },

        /**
         * Initialize view.
         */
        initialize: function(options) {
            this.actionHomeFinder = ["",""];
            this.$priceFilterPane = this.$('.mpa-hf-price-filter-pane');
            this.$priceOptions = this.$('.mpa-hf-form-price-options');
            this.$priceLabel = this.$('.mpa-hf-form-price-label');
            this.$container = this.$('.mpa-hf-filter-pane');

            $('body').on('mouseup.'+ this.cid, _.bind(function(event){
                var button = this.$('.mpa-hf-form-label'),
                    criteriaOption = this.$(".mpa-hf-form-option"),
                    inputPrice = this.$(".mpa-hf-form-price-input"),
                    areaInput = this.$(".mpa-hf-input-area");
                if(!button.is(event.target) && !criteriaOption.is(event.target) && !inputPrice.is(event.target) && !areaInput.is(event.target) && this.$container.is(":visible")){

                    if (!button.is($(event.target).parent())){
                        this.$container.toggleClass("mpa-closed", this.$container.is(":visible"));
                    }
                }
            },this));
        },

        searchHomeFinder: function() {
            var area = this.$(".mpa-hf-input-area").val().toLowerCase();

            if (area.match(/(\d{5}(?:-\d{4})?)/)){  
                // Matches if a zip/zip+4 code is provided in the area query, such as 10001, 10001-2334.
                // We check for this first as this is the most authoritative information that can be provided.
                // Zip code is more specific than just city/state.
                this.actionHomeFinder[0] = "zip-code/";
                this.actionHomeFinder[1] = area.match(/(\d{5}(?:-\d{4})?)/)[1] + "/";
            }else if (area.trim().match(/^([^,]+),\s*(\w{2})$/)){
                // Matches a city and two letter state, such as New York, NY.
                var areaArray = area.trim().match(/^([^,]+),\s*(\w{2})$/);
                this.actionHomeFinder[0] = areaArray[2].toUpperCase()+"/";
                this.actionHomeFinder[1] = (areaArray[1].charAt(0).toUpperCase() + areaArray[1].slice(1)).replace(/\s/,"-") + "/";
            }else if( this.isInt(area) ){
                // Matches if the only information provided is a zip code.
                this.actionHomeFinder[0] = "zip-code/";
                this.actionHomeFinder[1] = area + "/";
            }else if (area.length === 0) {
                // Used if there is no input query.  Other search parameters are still possible.
                this.actionHomeFinder[0] = "search/";
                this.actionHomeFinder[1] = "";
            }else {
                // Fallback.  If query does not match anything, perform a generic search on Homefinder.
                this.actionHomeFinder[0] = "search/?area=";
                this.actionHomeFinder[1] = area;
            }

            var attributSearch = _.reduce(this.actionHomeFinder, function(memo, num){ return memo + num; });

            this.$(".seach-form").attr("action", "//www.homefinder.com/"+attributSearch);

        },

        changeSearchAttribut: function(valueSelected, attribute) {
            if (!isNaN(valueSelected)){
                switch(attribute) {
                    case 'bed':
                        this.actionHomeFinder[2] = "min-bed-"+valueSelected+"/";
                        break;
                    case 'bath':
                        this.actionHomeFinder[3] = "min-bath-"+valueSelected+"/";
                        break;
                    case 'min_price':
                        this.actionHomeFinder[4] = "min-price-"+valueSelected+"/";
                        break;
                    case 'max_price':
                        this.actionHomeFinder[5] = "max-price-"+valueSelected+"/";
                        break;
                }
            }
        },

        generalDropdown: function(event) {
            if (!this.$container.not(".mpa-closed").is(event.currentTarget.nextElementSibling)){
                this.$container.not(".mpa-closed").addClass("mpa-closed");
            }
            $(event.currentTarget.nextElementSibling).toggleClass("mpa-closed");
            if ($(event.currentTarget).hasClass("mpa-hf-form-price-label")) {
                if(!this.$(".mpa-hf-form-dropdown-menu[data-price='max']").hasClass("mpa-closed")){
                    this.$(".mpa-hf-form-payment-min").focus();
                }
            }
        },

        inputFocus: function(event) {
            var targetLink      = $(event.currentTarget),
                priceTuple      = targetLink.attr("placeholder").toLowerCase();

            if(!this.$(".mpa-hf-form-dropdown-menu[data-price='"+priceTuple+"']").parent().is(":visible")){
                this.$priceOptions.toggleClass("mpa-closed");
            }
        },

        priceClick: function(event) {
            var targetLink     = $(event.currentTarget),
                item           = targetLink.parent(),
                parentItem     = item.parent(),
                priceTuple     = parentItem.data("price"),
                priceSelected  = item.data("value").replace(/,/g, "");

            this.priceLabelDisplay(priceTuple, priceSelected);
            this.changeSearchAttribut(priceSelected, priceTuple + "_price");
            this.$(".mpa-hf-form-payment-"+priceTuple).val(item.data("value"));

            if ($(".mpa-hf-form-payment-min").val().length > 0 && $(".mpa-hf-form-payment-max").val().length > 0) {
                this.$container.toggleClass("mpa-closed", this.$container.is(":visible"));
            }

        },

        criteriaClick: function(event) {
            var targetLink    = $(event.currentTarget),
                item          = targetLink.parent(),
                parentItem    = item.parent(),
                criteria      = parentItem.data("criteria");

            $(".mpa-hf-"+ criteria +"-select").html(item.text());
            this.$container.toggleClass("mpa-closed", this.$container.is(":visible"));
            this.changeSearchAttribut(item.data("value"), criteria);
        },

        inputChange: function(event) {
            var targetLink      = $(event.currentTarget),
                price           = targetLink.val().replace(/,/g, ""),
                priceTuple      = targetLink.attr("placeholder").toLowerCase();

            this.priceLabelDisplay(priceTuple, price);
            this.changeSearchAttribut(price, priceTuple + "_price");
        },

        priceLabelDisplay: function (priceTuple, price){
            var priceDisplay = "";

            this.$(".mpa-hf-form-price").data(priceTuple, price);

            if(this.$(".mpa-hf-form-price").data("min") !== 0 && this.$(".mpa-hf-form-price").data("max") !== 0){
                this.$(".mpa-hf-form-price-prepend, .mpa-hf-form-price-append").hide();

                priceDisplay = this.priceConvert($(".mpa-hf-form-price").data("min")) + " - " + this.priceConvert($(".mpa-hf-form-price").data("max"));

            }else{

                priceDisplay = this.priceConvert($(".mpa-hf-form-price").data(priceTuple));

                if($(".mpa-hf-form-price").data("min") === 0){
                    $(".mpa-hf-form-price-prepend").html("Up to ");
                }else{
                    $(".mpa-hf-form-price-append").html(" +");
                }
            }

             $(".mpa-hf-form-price").html(priceDisplay);

        },
        priceConvert:function(price){
            var priceDisplay = "$";

            if(price >= 1000) {
                priceDisplay += price.match(/^(\d.*)\d{3}$/)[1] + "K";
            } else {
                priceDisplay += price;
            }

            return priceDisplay;
        },

        isInt: function(value){
            if (isNaN(value)) {
                return false;
            }
            var valueFloat = parseFloat(value);

            return (valueFloat | 0) === valueFloat;
        },

        destroy: function(removeEl){
            $('body').off('.'+ this.cid);
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });

    /**
     * Return view class.
     */
    return HomeFinder;
}
);

