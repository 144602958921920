/* global define */
define('admanager',[
    'jquery',
    'underscore',
    'pubsub',
    'state',
    'utils',
    'user-manager',
    'managers/cachemanager',
    'partner/ad-slot',
    'modules/global/barrier',
    'adLogger',
    'abtest-manager',
    'cookie'
],
function(
    $,
    _,
    PubSub,
    StateManager,
    Utils,
    UserManager,
    CacheManager,
    AdSlot,
    Barrier,
    AdLogger,
    AbTestManager
) {
    'use strict';
    /**
     * @exports admanager
     */
    var AdManager = function(){
        this.initialize();
    };

    AdManager.prototype = {

        adSizes : {
            'pushdown' : [1200,615],
            'gravity' : [2560,1440],
            'elastic' : [1080,810],
            'heroflip' : [720,524],
            'fluid': 'fluid',
            'mediumrectangle' : [300,250],
            'halfpage' : [300,600],
            'portrait': [300,1050],
            'leaderboard' : [728,90],
            'generic': [1,2],
            'billboard': [970,250],
            'sponsor_logo': [100,30],
            'sponsor_logo_medium': [100,50]
        },
        defers: {},
        pageTargetingKeys: [],
        firstBids: true,

         /**
         * Kickoff
         */
        initialize :  function() {
            AdLogger.logInfo('AdManager:: AdManager initialize');

            if (this.isAdFreeExperience()) {
                AdLogger.logInfo('AdManager:: Ads Disabled');
                if (this.scrollUser) Utils.get('body').addClass('is-scroll-user');
                return;
            }

            this.pubSub = {
                'page:load': this._onPageLoad,
                'page:unload': this._onPageUnload,
                'page:beforeReveal': this._preFetchBids,
                'user:statuschange': this._onUserStatusChange,
                'activePageInfo:set': this._onActivePageInfoSet,
                'advertising:defined': this._setupAdSlots,
                'advertising:resolvedefer': this._resolveDefer
            };
            PubSub.attach(this.pubSub, this);

            _.bindAll(this, '_apsLoaded', '_fetchAPS', '_fetchIAS', '_fetchINX', '_fetchPrebid', '_apsBidBack', '_iasBack', '_inxBidBack', '_prebidBidBack', '_resolveDefer', '_preFetchBids', '_moatResponseHandler');
            window.adManager = this;

            this._resetSlots();

            // Set up GPT
            this._setupGoogletag();

            // Set up other 3rd parties
            this.apsLoaded = $.Deferred();
            window.pbjs = window.pbjs || {};
            window.pbjs.que = window.pbjs.que || [];
            window.headertag = window.headertag || {};
            window.headertag.cmd = window.headertag.cmd || [];
            _.each(['aps', 'ias', 'inx_universal', 'prebid', 'moatIntelligence'], _.bind(function(partner) {
                this._setupPartner(partner);
            }, this));
            this._preFetchBids($('body'));

            // Most Yield Intelligence ready listener
            window.moatYieldReady = this._moatResponseHandler;
        },

        stop: function() {
            PubSub.unattach(this.pubSub, this);
        },

        _onPageLoad: function(pageInfo){
            AdSlot.prototype.pageLoad();
        },

        _onPageUnload: function(){
            AdSlot.prototype.pageUnload();
            // Update our page correlator
            window.googletag.cmd.push(function() {
                AdLogger.logInfo('AdManager: Setting new correlator value');
                window.googletag.pubads().updateCorrelator();
            });
            clearTimeout(this.inxTO);
            clearTimeout(this.iasTO);
            this._resetSlots();
            this._resetPageTargeting();
        },

        _onActivePageInfoSet: function(pageInfo) {
            this._setPageTargeting(this._getPageTargeting(pageInfo));
            this._setExclusionTargeting(pageInfo);
            this._setSimpleTargeting();
        },

        isAdFreeExperience: function() {
            this.noAds = this.noAds || Utils.getUrlParam('no_ads');
            this.scrollUser = Utils.getNested(window, 'site_vars', 'ADS', 'scroll', 'enabled') && $.cookie('scroll0');
            this.hasPII = this.hasPII || Utils.checkForEmail(document.location.href);
            return this.noAds || this.scrollUser || this.hasPII;
        },

        _setupGoogletag: function () {
            window.googletag = window.googletag || {};
            window.googletag.cmd = window.googletag.cmd || [];
            Utils.loadScript('https://www.googletagservices.com/tag/js/gpt.js', 'googletag').then(this._onGoogletagLoaded);
        },

        /**
         * Configures the Google services
         * @private
         */
        _onGoogletagLoaded: function() {
            window.googletag.cmd.push(function() {
                var pubads = window.googletag.pubads();
                pubads.collapseEmptyDivs();
                pubads.enableSingleRequest();
                window.googletag.enableServices();
            });
        },

        _setupPartner: function (partner) {
            var partnerConfig;
            if(this.partnerEnabled(partner)) {
                partnerConfig = Utils.getNested(window.site_vars, 'ADS', partner);
                Utils.loadScript( partnerConfig.scriptUrl, partnerConfig.symbol).then(this['_' + partner + 'Loaded']);
            }
        },

        partnerEnabled: function(partner) {
            this[partner + 'IsEnabled'] = this[partner + 'IsEnabled'] || Utils.getNested(window.site_vars, 'ADS', partner, 'enabled') === true || Utils.getUrlParam(partner + '_debug');
            return this[partner + 'IsEnabled'];
        },

        _preFetchBids: function($context) {
            var pageInfo = StateManager.getActivePageInfo(),
                overrides = this._getOverrides();
            _.each($('.partner-placement[data-ad-sizes]', $context), function(el) {
                var $el = $(el),
                    data = $el.data(),
                    subSection = (pageInfo.cst) ? '/' + pageInfo.cst : '',
                    baseAdUnit = data.adPlacement + subSection,
                    adUnit = AdSlot.prototype.buildAdUnit(baseAdUnit, overrides),
                    slotId = data.slotId || _.uniqueId('ad-slot-' + adUnit.replace(/\//g, '-') + '-');

                // set the slotId on the el, so AdSlot can reference the same id when the slot is setup
                $el.data('slotId', slotId);
                AdSlot.prototype.configurePrebidSlot(adUnit, data.adSizes, slotId, $el);
                AdSlot.prototype.configureAPSSlot(data.adSizes, slotId, $el);
                AdSlot.prototype.configureINXSlot(data.adPlacement, slotId, $el);
                AdSlot.prototype.configureIASSlot(adUnit, data.adSizes, slotId, $el);
            });
            this._fetchPrebid();
            this._fetchAPS();
            this._fetchINX();
            this._fetchIAS();
        },

        _prebidLoaded: function () {
            window.pbjs.que.push(function() {
                var priceBrackets = {
                        buckets : [{
                            precision: 2,
                            min: 0,
                            max: 5,
                            increment: 0.01
                        },
                        {
                            precision: 2,
                            min: 5,
                            max: 10,
                            increment: 0.05
                        },
                        {
                            precision: 2,
                            min: 10,
                            max: 20,
                            increment: 0.10
                        },
                        {
                            precision: 2,
                            min: 20,
                            max: 50,
                            increment: 0.25
                        },
                        {
                            precision: 2,
                            min: 50,
                            max: 100,
                            increment: 1
                        }]
                    };

                // Set prebid config options
                window.pbjs.setConfig({
                    priceGranularity: priceBrackets,
                    enableSendAllBids: true,
                    userSync: {
                        iframeEnabled: true,
                        enabledBidders: ['openx']
                    }
                });
            });
        },

        _fetchPrebid: function(slotId) {
            var slots, threshold, winningOnly;

            if(this.partnerEnabled('prebid')) {
                if(slotId) {
                    slots = [slotId];
                } else {
                    threshold = Utils.getNested(window.site_vars, 'ADS', 'prebid', 'all_bids_cutoff') || 6;
                    slots = this.prebidSlots;
                    // Set if we should only send the winning bids back from a multi-slot auction
                    this.pbjsWinningOnly = this.prebidSlots.length > threshold;
                }

                window.pbjs.que.push(_.bind(function() {
                    window.pbjs.requestBids({
                        timeout: Utils.getNested(window.site_vars, 'ADS', 'prebid', 'timeout') || 1500,
                        adUnitCodes: slots,
                        bidsBackHandler: this._prebidBidBack
                    });

                    AdLogger.logInfo('AdManager:: Fetching slot prebid', slots);
                }, this));

                // Clear the slots after the first batched request
                this.prebidSlots = [];
                this.prebidSlots.push = this._fetchPrebid;
            } else {
                PubSub.trigger('advertising:resolvedefer', 'prebid');
            }
        },

        _prebidBidBack: function(event) {
            this.slotCreationBarrier.wait().done(_.bind(function() {
                window.googletag.cmd.push(_.bind(function () {
                    var slots = window.googletag.pubads().getSlots();
                    // Set targeting and resolve defers
                    if(this.pbjsWinningOnly) {
                        this.pbjsWinningOnly = false;
                        AdLogger.logInfo('AdManager:: Setting winning bid for Prebid');
                        this._setWinningBidOnly(slots);
                    } else {
                        AdLogger.logInfo('AdManager:: Setting all bids for Prebid');
                        this._setAllBids(slots);
                    }
                    PubSub.trigger('advertising:resolvedefer', 'prebid');
                    
                    if(window.newrelic) {
                        PubSub.trigger('advertising:prebidback', event);
                    }
                }, this));
            }, this));
        },

        _setWinningBidOnly: function(slots) {
            var passGeneric = Utils.getNested(window.site_vars, 'ADS', 'prebid', 'targeting', 'generic') || false;
            _.each(slots, function(slot) {
                var winningBid = (window.pbjs.getHighestCpmBids(slot.getSlotElementId()) || [])[0];
                if(winningBid) {
                    var code = winningBid.bidderCode;
                    _.each(winningBid.adserverTargeting, function(value, key) {
                        if(this._validPBKey(key, true)) {
                            if(passGeneric) {
                                slot.setTargeting(key, value);
                            }
                            slot.setTargeting(key + '_' + code, value);
                        }
                    }, this);
                }
            }, this);
        },

        _setAllBids: function(slots) {
            _.each(slots, function(slot) {
                var bids = window.pbjs.getAdserverTargetingForAdUnitCode(slot.getSlotElementId());
                _.each(bids, function(val, key) {
                    if(this._validPBKey(key, false)) {
                        slot.setTargeting(key, val);
                    }
                }, this);
            }, this);
        },

        _validPBKey: function(key, onlyGenericKeys) {
            var config = Utils.getNested(window.site_vars, 'ADS', 'prebid', 'targeting') || {},
                validKeys = config.keys || ['pb', 'adid', 'deal', 'size'],
                includeGeneric = (!!config.generic || onlyGenericKeys) ? '' : '_',
                re = new RegExp('_(' + validKeys.join('|') + ')' + includeGeneric);
            return (key.match(re) && key.match(re).length);
        },

        _apsLoaded: function () {
            var debugMode = Utils.getNested(window.site_vars, 'ADS', 'aps', 'debug') || 'disable',
                options = {
                    pubID: Utils.getNested(window.site_vars, 'ADS', 'aps', 'pubID'),
                    adServer: 'googletag',
                    bidTimeout: Utils.getNested(window.site_vars, 'ADS', 'aps', 'timeout') || 1500,
                    videoAdServer: 'DFP'
                };

            window.apstag.debug(debugMode);
            window.apstag.init(options);

            this.apsLoaded.resolve();
            // special logging just for a partner configuration
            if(Utils.getUrlParam('pb_slot_log')) {
                console.info('AMAZON APS INIT CONFIG', JSON.stringify(options, null, 4));
            }
        },

        _fetchAPS: function (slot) {
            var slots = (slot) ? [slot] : this.apsSlots;

            if(this.partnerEnabled('aps')) {
                this.apsLoaded.then(_.bind(function() {
                    if(window.apstag) {
                        window.apstag.fetchBids({
                            slots: slots
                        }, this._apsBidBack);

                        // Have any further push commands fire off a fetch with the individual slot
                        this.apsSlots.push = this._fetchAPS;
                    } else {
                        PubSub.trigger('advertising:resolvedefer', 'aps');
                        AdLogger.logInfo('AdManager:: APS not loaded');
                    }
                }, this));
            } else {
                PubSub.trigger('advertising:resolvedefer', 'aps');
            }
        },

        _apsBidBack: function (bids) {
            this.slotCreationBarrier.wait().done(function() {
                window.googletag.cmd.push(function () {
                    window.apstag.setDisplayBids();
                    AdLogger.logInfo('AdManager:: Setting APS targeting', bids);
                    PubSub.trigger('advertising:resolvedefer', 'aps');
                });
            });
        },

        _fetchIAS: function(slot) {
            var id = Utils.getNested(window.site_vars, 'ADS', 'ias', 'pubid'),
                slots = (slot) ? [slot] : this.iasSlots;
            if(id && this.partnerEnabled('ias')) {
                window.__iasPET = window.__iasPET || {};
                window.__iasPET.queue = window.__iasPET.queue || [];
                window.__iasPET.pubId = id;

                window.__iasPET.queue.push({
                    adSlots: slots,
                    dataHandler: this._iasBack
                });

                this.iasTO = setTimeout(function() {
                    PubSub.trigger('advertising:resolvedefer', 'ias');
                }, Utils.getNested(window.site_vars, 'ADS', 'ias', 'timeout') || 1000);

                this.iasSlots.push = this._fetchIAS;
            } else {
                PubSub.trigger('advertising:resolvedefer', 'ias');
            }
        },

        _iasBack: function() {
            this.slotCreationBarrier.wait().done(function() {
                window.googletag.cmd.push(function () {
                    window.__iasPET.setTargetingForGPT();
                    PubSub.trigger('advertising:resolvedefer', 'ias');
                });
            });
        },

        _fetchINX: function (slot, prefetch) {
            var slots = (slot) ? [slot] : this.inxSlots,
                timeout = Utils.getNested(window.site_vars, 'ADS', 'inx_universal', 'timeout') || 1000;
            if(this.partnerEnabled('inx_universal')) {
                // Map duplicate-free array of slot names to htSlotName objects
                window.headertag.cmd.push(_.bind(function () {
                    var inxSlots = _.chain(slots).pluck('name').uniq().map(function(name) { return { htSlotName: name }; }).value();
                    window.headertag.retrieveDemand(inxSlots, this._inxBidBack);
                }, this));
                this.inxTO = setTimeout(function() {
                    PubSub.trigger('advertising:resolvedefer', 'inx');
                },  timeout);
                this.inxSlots.push = this._fetchINX;
            } else {
                PubSub.trigger('advertising:resolvedefer', 'inx');
            }
        },

        _inxBidBack: function(demand) {
            // Wait till our slots are setup, if INX returned beforehand
            this.slotCreationBarrier.wait().done(_.bind(function() {
                if(demand) {
                    window.googletag.cmd.push(_.bind(function() {
                        var pubads = window.googletag.pubads(),
                            slots = pubads.getSlots();
                        if(demand.slot && slots.length) {
                            _.each(demand.slot, function(bids, slotName) {
                                var inxUnit = _.findWhere(this.inxSlots, {name: slotName});
                                if(inxUnit && inxUnit.slotId) {
                                    var gptSlot = _.find(slots, function(slot) {
                                        return Utils.getNested(slot, '__gannettSlot', 'slotId') === inxUnit.slotId;
                                    });
                                    if(bids.length && gptSlot) {
                                        _.each(bids, function(bid) {
                                            _.each(bid.targeting, function(value, key) {
                                                // Set the targeting on the slot
                                                gptSlot.setTargeting(key, value);
                                            });
                                        });
                                    }
                                }
                            }, this);
                        }
                        if(demand.page && demand.page.length) {
                            this._setPageTargeting(demand.page);
                        }
                    }, this));
                }

                PubSub.trigger('advertising:resolvedefer', 'inx');
            }, this));
        },

        _moatIntelligenceLoaded: function() {
            if(Utils.getUrlParam('moatIntelligence_debug') && window.moatPrebidApi) {
                window.moatPrebidApi.enableLogging();
            }
        },

        _moatResponseHandler: function() {
            if (window.moatPrebidApi) {
                if(typeof window.moatPrebidApi.slotDataAvailable === 'function' && window.moatPrebidApi.slotDataAvailable()) {
                    window.moatPrebidApi.setMoatTargetingForAllSlots();
                }
                if(typeof window.moatPrebidApi.pageDataAvailable === 'function' && window.moatPrebidApi.pageDataAvailable()) {
                    this._setPageTargeting(window.getMoatTargetingForPage());
                }
            }
        },

        _setPageTargeting: function(targeting) {
            window.googletag.cmd.push(_.bind(function() {
                var pubads = window.googletag.pubads();
                _.each(targeting, function(value, key) {
                    if (value || value === 0 || value === false) {
                        value = Utils.scrubEmail(value);
                        this.pageTargetingKeys.push(key);
                        pubads.setTargeting(key, value);
                    }
                }, this);
                AdLogger.logInfo('AdManager:: set page level targetting', targeting);
            }, this));
        },

        _resetPageTargeting: function() {
            window.googletag.cmd.push(_.bind(function() {
                this.pageTargetingKeys.forEach(function(key){
                    window.googletag.pubads().clearTargeting(key);
                });

                this.pageTargetingKeys = [];
            }, this));
        },

        /**
         * Gets the page level targeting object
         * @private
         */
        _getPageTargeting: function(pageInfo){
            var noValue = 'NA',
                getNested = Utils.getNested,
                siteName = getNested(window, 'site_vars', 'aws_site_name') || 'usat',
                cstParts = (getNested(pageInfo, 'cst') || '').split('/'),
                ssts = getNested(pageInfo, 'ssts') || '',
                sstsParts = ssts.split('/'),
                prevPvParams = pageInfo.prevPvParams || {},
                prevPageInfo = pageInfo.prevPageInfo || {},
                user = pageInfo.user || {},
                abTest = user.abTest || {},
                versionParts = (getNested(window, 'site_vars', 'version') || '0.0').split('.'),
                navigationtype = Utils.getUrlParam('navigationtype') || this._getNavigationType(pageInfo.refreshed),
                breaking = getNested(pageInfo, 'breaking'),
                alerttype = (breaking) ? 'breaking': (breaking === false) ? 'none': noValue,
                userInsights = this._getUserInsights(),
                doLookALike = (userInsights.doLookALike !== undefined) ? userInsights.doLookALike.toString() : noValue,
                buildType = (getNested(pageInfo, 'layout_type') == 'front-list-sidebar-page') ? 'dfr' : 'legacy desktop';

            return {
                aam_props: 'reimagine|desktop|' + pageInfo.contenttype, // Omniture props 26|4|16
                abTestId: abTest.id || noValue,
                abTestVariant: getNested(abTest, 'userVariant', 'id') || noValue,
                adlabel: this._getOverrides().adlabel,
                alerttype: alerttype,
                build: buildType,
                clickAbTestId: prevPvParams.abTestId || noValue,
                clickAbTestVariant: prevPvParams.abTestVariant || noValue,
                contentid: pageInfo.assetid,
                cst_section: cstParts[0] || noValue,
                cst_subsection: cstParts[1] || noValue,
                cst_topic: cstParts[2] || noValue,
                dolookalike: doLookALike,
                front: this._getLastFront(),
                ksg: (localStorage.getItem('kxgannett_segs') || '').split(','),
                kuid: (localStorage.getItem('kxgannett_user') || ''),
                navigationtype: navigationtype,
                origin: (pageInfo.origin || noValue).replace(/[\/\-_]/g,''),
                pageType: pageInfo.basePageType,
                prevAbTestId: getNested(prevPageInfo, 'user', 'abTest', 'id') || noValue,
                prevAbTestVariant: getNested(prevPageInfo, 'user', 'abTest', 'userVariant', 'id') || noValue,
                property: siteName,
                referrer: this._getTargetingReferrer(),
                series: pageInfo.series,
                sitepage: siteName + '/' + ssts,
                ss: this.ssInfo,
                ssts_section: sstsParts[0] || noValue,
                ssts_subsection: sstsParts[1] || noValue,
                ssts_topic: sstsParts[2] || noValue,
                ssts_subtopic: sstsParts[3] || noValue,
                topic: pageInfo.topic,
                userguid: $.cookie('gup_anonid') || '',
                utm_props: this._getUtm(),
                version_major: versionParts[0],
                version_minor: versionParts[1],
                version_patch: '0',
            };
        },

        /**
         * Sets the base exclusion targeting based on taxonomy and keywords
         * @private
         */
        _setExclusionTargeting: function(pageInfo) {
            var pageTags,
                keywords = pageInfo.seokeywords || '',
                taxonomy = pageInfo.taxonomykeywords || '',
                categories = Utils.getNested(window, 'site_vars', 'ADS', 'exclusion_categories') || [];

            keywords = (_.isArray(keywords)) ? keywords : keywords.split(',');
            taxonomy = (_.isArray(taxonomy)) ? taxonomy : taxonomy.split(',');
            pageTags = _.map(_.compact(_.union(keywords, taxonomy)), function(str) { return str.toLowerCase().trim(); });

            window.googletag.cmd.push(function() {
                var pubads = window.googletag.pubads();

                // Clear out any previous categories
                pubads.clearCategoryExclusions();

                categories.forEach(function(category) {
                    var tags = _.map(category.tags, function(tag) {
                            return tag.toLowerCase();
                        }),
                        matched = _.intersection(pageTags, tags);

                    if(!_.isEmpty(matched)) {
                        pubads.setCategoryExclusion(category.name);
                    }
                });
            });
        },

        _setSimpleTargeting: function() {
            var simpleExclusion = _.uniq(window.simpleExclusion || []),
                simpleTarget = _.uniq(window.simpleTarget || []),
                categoryvalue = simpleTarget.concat(simpleExclusion);

            window.googletag.cmd.push(_.bind(function() {
                var pubads = window.googletag.pubads(),
                    key = 'categoryvalue';

                simpleExclusion.forEach(function(tag) {
                    pubads.setCategoryExclusion(tag);
                });

                pubads.setTargeting(key, categoryvalue);
                this.pageTargetingKeys.push(key);
            }, this));
        },

        _getNavigationType: function(isAutoRefresh) {
            var type =  'external';
            if(isAutoRefresh) {
                type =  'autorefresh';
            } else if (StateManager.lastUrl || document.referrer.indexOf(Utils.getNested(window, 'site_vars', 'base_url')) !== -1) {
                type = 'internal';
            } else if (!document.referrer) {
                type =  'direct';
            }
            return type;
        },

        _getTargetingReferrer: function () {
            // Maps output from StateManager.getReferrer to ad targeting referrer
            var referrerMatches = StateManager.getReferrer().match(/^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i),
                referrer = referrerMatches && referrerMatches[1];
            if (referrer) {
                // Only return apex domain for referrer (e.g. 'facebook.com')
                referrer = referrer.split('.').slice(-2).join('.');
            } else {
                referrer = 'NA';
            }
            return referrer;
        },

        _getUserInsights: function() {
            var data = {};
            try {
                data = JSON.parse(window.localStorage.getItem('user_insights')) || {};
            } catch(e) {
                // user_insights not a json string
            }
            return data;
        },

        _getUtm: function () {
            var getParam = Utils.getUrlParam,
                source = getParam('utm_source') || '',
                medium = getParam('utm_medium') || '',
                campaign = getParam('utm_campaign') || '';
            return source + '|' + medium + '|' + campaign;
        },

        _getLastFront: function() {
            var front = CacheManager.getValue('last_front');
            // Only valid for the next view
            CacheManager.clearValue('last_front');
            return front;
        },

        _onUserStatusChange: function(accountName, status) {
            if (accountName === 'firefly') {
                if (status == 'loggedIn' || status == 'loggingIn') {
                    var coreUserPromise = UserManager.getCoreUserInfo();
                    coreUserPromise = coreUserPromise.then(_.bind(function(userData) {
                        if (userData.UserLicenseType) {
                            this.ssInfo = _.map(userData.UserLicenseType.split('_'), function(pair){
                                return pair.substring(0, 3);
                            }).join('_');

                            window.googletag.cmd.push(_.bind(function() {
                                window.googletag.pubads().setTargeting('ss', this.ssInfo);
                            }, this));
                        }
                    }, this));
                }
            }
        },

        _getOverrides: function() {
            /* jshint ignore:start */
            return this.overrides = this.overrides || {
                adlabel: Utils.getUrlParam('usatl') || '',
                account: Utils.getUrlParam('usatai'),
                property: Utils.getUrlParam('usatan')
            };
            /* jshint ignore:end */
        },

        /**
         * AD SLOT CREATION
         */

        /**
         * Builds an ad slot wrapper around google's ad slots
         * @param {String} adPlacement name of the dfp placement i.e. poster
         * @param {String} adUnit segmented adUnit name i.e. poster/news/politics
         * @param {Object} targeting key/value custom targeting map
         * @param {String[]} exclusion values
         * @param {String[]} rawSizes Ad sizes
         * @param {Object} adPosition Object that will handle the ad slot
         * @param {String} [slotType=out] google slot type, either 'out' or 'in'
         */
        getAdSlot: function(adPlacement, adUnit, targeting, exclusion, rawSizes, adPosition, slotType){
            var defer = $.Deferred(),
                options = Utils.getNested(adPosition, 'options') || {},
                adSizes = this._mapAdSizes(rawSizes),
                slot = options.slot && options.slot[0] || adPosition.el,
                slotConfig = {},
                suppressAdSizes = Utils.getNested(AbTestManager.getUserTestByTarget('ad-suppression'), 'userVariant', 'options', 'suppressAdSizes');

            if (this.isAdFreeExperience()) {
                return false;
            }

            // see if we need to suppress any ad placements (generally used for a/b testing)
            if (suppressAdSizes) {
                adSizes = this._suppressAdSizes(adPlacement, adSizes, suppressAdSizes);
            }

            // No ad sizes, we just return
            if(_.isEmpty(adSizes)) {
                return false;
            }

            // give the slot an id, if it doesn't have one yet
            if (!slot.id) {
                slot.id = _.uniqueId('ad-position-');
            }

            this.activeSlotStatistics.unknown++;
            this.slotsBarrier.add(defer, 3000, true);
            defer.done(_.bind(function() {
                // leave failures as unknown
                this.activeSlotStatistics.unknown--;
            }, this));

            slotConfig = {
                adPlacement: adPlacement,
                adUnit: adUnit,
                exclusion: exclusion,
                targeting: targeting,
                sizes: adSizes,
                type: slotType || 'in',
                position: $(slot),
                adPositionMap: {},
                lazy: !!options.lazy,
                deferred: options.deferred,
                onSlotEmpty: _.bind(function() {
                    this.activeSlotStatistics.noad++;
                    defer.resolve();
                }, this),
                onSlotRender: _.bind(function(adData, adType, adSize) {
                    if (!adType) {
                        this.activeSlotStatistics.iab++;
                    } else {
                        this.activeSlotStatistics.high_impact++;
                        var ecid = Utils.getNested(adData, 'dfp', 'ecid');
                        this.activeSlotStatistics.high_impact_info.push({
                            'adType': adType,
                            'cap' : adData.maxDisplayed,
                            'capExp' : parseInt(adData.leaveBehindExp, 10),
                            'ecid': ecid,
                            'state' : 'loading'
                        });
                    }
                    defer.resolve();
                }, this),
                defers: {
                    fetch: $.Deferred(),
                    batch: this.defers.batch
                },
                overrides: this._getOverrides()
            };

            // Create a unique key for each possible returned size
            _.each(adSizes, function(adSize) {
                var key = (adSize === 'fluid') ? '0x0' : (slotType === 'out') ? '1x1' : adSize[0] + 'x' + adSize[1];
                slotConfig.adPositionMap[key] = adPosition;
            });

            if(this.queuing) {
                AdLogger.logInfo('AdManager:: Queueing Ad Slot : ' + adPlacement, adSizes);
                this._queueSlot(slot, adSizes, targeting, exclusion, slotConfig);
            } else {
                AdLogger.logInfo('AdManager:: Creating Ad Slot', adPlacement, adSizes);
                this._createAdSlot(slotConfig);
            }
        },

        buildAdUnit: function(adUnit) {
            return AdSlot.prototype.buildAdUnit(adUnit, this.overrides);
        },

        _queueSlot: function(slot, adSizes, targeting, exclusion, slotConfig) {
            var thisSlot = this.queuedAdUnits[slot.id];
            if(thisSlot) {
                var sharedSlot = {
                        sizes: _.union(thisSlot.sizes, adSizes),
                        targeting: _.extend({}, thisSlot.targeting, targeting),
                        exclusion: _.union(thisSlot.exclusion, exclusion),
                        adPositionMap:  _.extend(thisSlot.adPositionMap, slotConfig.adPositionMap),
                    };
                // Add in additional sizes, targeting, adPositions to the existing slot
                slotConfig = _.extend({}, thisSlot, sharedSlot);
            }
            this.queuedAdUnits[slot.id] = slotConfig;
        },

        _createAdSlot: function(slotConfig) {
            var newSlot = new AdSlot(slotConfig);

            _.each(slotConfig.adPositionMap, function(adPosition) {
                //set the new adslot as a subview on each ad position
                adPosition.subviews.adSlot = newSlot;
            });
        },

        _setupAdSlots: function() {
            AdLogger.logInfo('AdManager:: Creating Queued Ad Slots', this.queuedAdUnits);

            _.each(this.queuedAdUnits, function(adUnit) {
                this.slotCreationBarrier.add(adUnit.defers.fetch, 500, true);
                this._createAdSlot(adUnit);
            }, this);
            this.queuing = false;
            this.defers.hold.resolve();
        },

        _mapAdSizes: function(adSizes) {
            if(adSizes === 'fluid' || (adSizes.length === 2 && _.isNumber(adSizes[0]))) {
                return [adSizes];
            } else if(_.isArray(adSizes)) {
                return adSizes.map(_.bind(function(size) {
                    if(_.isArray(size)) {
                        return size;
                    }
                    return this.adSizes[size];
                }, this));
            } else {
                // passed a string, just map it
                return [this.adSizes[adSizes]];
            }
        },

        /**
         * Resets the ad manager's slot variables
         * @private
         */
        _resetSlots: function() {
            // reset barriers
            _.each(['slotCreationBarrier', 'slotsBarrier', 'batchBarrier'], this._resetBarrier, this);
            _.each(['aps', 'ias', 'inx', 'prebid'], function(type) {
                var defer = $.Deferred();
                this.defers[type] = defer;
                this.batchBarrier.add(defer, false, true);

                // reset our slot arrays
                this[type + 'Slots'] = [];
            }, this);
            this.defers.batch = $.Deferred();
            this.defers.hold = $.Deferred();
            this.slotCreationBarrier.add(this.defers.hold, false, false);
            this.batchBarrier.wait().done(_.bind(function() {
                this.defers.batch.resolve();
            }, this));

            this.queuing = true;
            this.queuedAdUnits = {};
            this.activeSlotStatistics = {
                high_impact: 0,
                high_impact_info: [],
                iab: 0,
                noad: 0,
                unknown: 0
            };
        },

        _resetBarrier: function(barrierName) {
            if (this[barrierName]) {
                this[barrierName].cancel(true);
            } else {
                this[barrierName] = new Barrier();
            }
        },

        _resolveDefer: function(defer) {
            this.defers[defer].resolve();
        },

        /**
         * Returns a promise that will resolve when we are reasonably certain that all the ads have been
         * requested and delivered or the specified timeout has occurred.
         * @returns {Deferred} This deferred will resolve with a map of statistics. ex: { high_impact: 0, iab: 0, noad: 0, unknown: 0 }
         */
        getActiveSlotStatistics: function() {
            if(this.isAdFreeExperience()) {
                return $.Deferred().resolve({});
            } else {
                return this.slotsBarrier.wait().then(_.bind(function() {
                    var slotStatistics = this.activeSlotStatistics;
                    return $.Deferred(function(defer) {
                        _.defer(function() {
                            defer.resolve(slotStatistics);
                        });
                    });
                }, this));
            }
        },

        /**
         * Checks to see if there is an active ad suppression a/b test and, if so, suppresses the defined ad sizes
         * @param {String} adPlacement name of the dfp placement i.e. poster
         * @param {Array} adSizes mapped ad sizes
         * @param {Array<Object>} suppressAdSizes array of ad sizes (placeement and size) to suppress
         * @returns {Array}
         * @private
         */
        _suppressAdSizes: function(adPlacement, adSizes, suppressAdSizes) {
            if (_.isArray(suppressAdSizes) && _.isArray(adSizes)) {
                suppressAdSizes = _.filter(suppressAdSizes, function(suppressAdSize) {
                    return suppressAdSize.adPlacement === adPlacement;
                });
                _.each(suppressAdSizes, function (suppressAdSize) {
                    _.each(adSizes, function (adSize, i) {
                        if (_.isEqual(suppressAdSize.adSize, adSize)) {
                            adSizes.splice(i,1);
                        }
                    });
                });
            }
            return adSizes;
        },

        /**
         * High impact adunits rely on this function, just mapping it directly to adlogger
         */
        logDebug: AdLogger.logDebug

    };

    return new AdManager();
});

define('api/ads', ['jquery', 'admanager'], function($, AdManager) {
    'use strict';
    return {
        registerAd: function(domId, adUnit, adSizes, targetingObj) {
            var $el = typeof domId === 'string' ? $('#' + domId) : $(domId),
                adPlacement = adUnit.split('/')[0],
                adPosition = {
                    $el: $el,
                    render: function(adData, adType){},
                    noAd: function(){}
                };
            AdManager.getAdSlot(adPlacement, adUnit, targetingObj, [], adSizes, adPosition, 'in');
        }
    };
});

