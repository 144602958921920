define('template-manager',[
    'jquery',
    'underscore',
    'utils',
    'template-utils',
    'api/analytics',
    'pubsub',
    'state',
    'managers/requestmanager',
    'abtest-manager',
    'modules/global/taboola',
    'third_party_integrations/taboola/taboola-utils',
    'libs/moment/moment.min'
],
function(
    $,
    _,
    Utils,
    TemplateUtils,
    Analytics,
    PubSub,
    StateManager,
    RequestManager,
    AbTestManager,
    Taboola,
    TaboolaUtils,
    moment
) {
    'use strict';
    /**
     * Template Manager provides utils for front-end rendering of modules and asset replacement.
     * @exports managers/templatemanager
     * @author kefoster@gannett.com (Kevin Foster)
     */
    var TemplateManager = function() {
        _.bindAll(this, 'formatPongAsset');
        this.baseUrl = Utils.getNested(window.site_vars, 'base_url');
        this.assetTemplates = {};
        this._initialPageLoad();
    };
    TemplateManager.prototype = {
        selectors: {
            coEl: '.js-co-eligible',
            coModule: '.js-co-eligible-module',
            coAsset: '.js-co-eligible-asset:not(.js-sponsor-asset)',
            backfillAsset: '.js-backfill-asset',
            curatedAsset: '.js-curated-asset',
            parentCuratedAsset: '.js-parent-curated-asset',
            assetHeadline: '.js-asset-headline',
            assetLink: '.js-asset-link',
            assetImage: '.js-asset-image',
            assetSection: '.js-asset-section',
            assetTimestamp: '.js-asset-timestamp',
            assetDescription: '.js-asset-description',
            assetAuthor: '.js-asset-author',
            assetSource: '.js-asset-source',
            assetDisposable: '.js-asset-disposable',
            coAssetTemplate: '.js-co-asset-template'
        },
        /**
         * When TemplateManager initially fires up, we check the DOM for content override-eligible elements and process
         * them accordingly.  We don't want any subsequent content override calls to be processed until these initial
         * calls are completed, so we wrap them in a deferred object.
         * @private
         */
        _initialPageLoad: function() {
            this.initialPageLoadPromise = $.Deferred(_.bind(function(defer) {
                var $el = Utils.get('body'),
                    $coEls = $el.find(this.selectors.coEl);
                if ($coEls.length) {
                    try {
                        var pageInfo = StateManager.getActivePageInfo();
                        $.when.apply($, _.map($coEls, function(coEl) {
                            var $coEl = $(coEl);
                            return this._processContentOverride($coEl, pageInfo).then(function() {
                                $coEl.addClass('js-co-initial');
                            });
                        }, this)).then(function() {
                            defer.resolve();
                        }, function() {
                            defer.reject();
                        });
                    } catch(e) {
                        defer.reject();
                    }
                } else {
                    defer.resolve();
                }
            }, this)).promise();
        },
        /**
         * @param {jQuery} $el
         * @param {Object} pageInfo
         */
        getContentOverride: function($el, pageInfo) {
            this.initialPageLoadPromise.always(_.bind(function() {
                if (!$el.hasClass('js-co-failed') && !$el.hasClass('js-co-initial')) {
                    if (!$el.hasClass('js-co-processed')) {
                        this._processContentOverride($el, pageInfo);
                    } else if ($el.data('analyticsEvents')) {
                        this._fireAnalyticsEvents($el);
                    }
                }
                $el.removeClass('js-co-initial');
            }, this));
        },
        /**
         * Iterates over content override-eligible modules and if any content override configurations exist in the
         * site config, the content override-eligible assets are replaced with data from the data source designated
         * in the config.  In all cases, the placeholder class is removed from the modules and the images are
         * lazily-loaded.
         * @param {jQuery} $el
         * @param {Object} pageInfo
         * @returns {Deferred}
         * @private
         */
        _processContentOverride: function($el, pageInfo) {
            var $dfd = $.Deferred().resolve(),
                $eligibleModules = $el.find(this.selectors.coModule),
                abTest,
                coOptions = {},
                analyticsProviders = [],
                $coModules,
                $eligibleAssets;
            $el.addClass('js-co-processed');
            if (!$eligibleModules.length) {
                $dfd.reject();
            } else {
                abTest = Utils.getNested(pageInfo, 'user', 'abTest') || AbTestManager.getUserTestByTarget('content-override');
                coOptions = this.getContentOverrideOptions(abTest);
                $coModules = this.getContentOverrideModules($el, Utils.getNested(coOptions, 'targetModules')) || $eligibleModules;
                $eligibleAssets = $coModules.find(this.selectors.coAsset);
                // By default, we want to enable analytics for the configured feed provider
                if (coOptions.provider) {
                    analyticsProviders.push(coOptions.provider);
                }
                if (coOptions.analyticsProviders) {
                    analyticsProviders = _.filter(_.union(analyticsProviders, coOptions.analyticsProviders), function(p) { return !_.isUndefined(p); });
                }
                if (coOptions.provider) {
                    var $coAssets = $eligibleAssets,
                        startAtIndex = Utils.getNested(coOptions, 'startAtIndex'),
                        $neighboringAssets;
                    // Filter down the eligible asset set if a startAtIndex is defined.
                    if (startAtIndex) {
                        $coAssets = $eligibleAssets.slice(startAtIndex);
                    }
                    // Further filter down the eligible asset set if the backfillOnly config flag is enabled.
                    if (Utils.getNested(coOptions, 'backfillOnly')) {
                        $coAssets = $eligibleAssets.filter(this.selectors.backfillAsset);
                    }
                    $coAssets.addClass('js-co-asset');
                    // Flag any assets that are not queued for content override but reside within the content
                    // override-eligible module set for de-duping purposes.
                    $neighboringAssets = $eligibleAssets.not($coAssets).addClass('js-neighbor-asset');

                    if (!$eligibleAssets.length) {
                        $dfd.reject();
                    } else {
                        // Compile and cache asset templates if needed
                        _.each($coModules, function(module) {
                            var $assetTemplate = $(module).find(this.selectors.coAssetTemplate),
                                templateId = $assetTemplate.attr('data-co-template-id'),
                                templateHtml = $assetTemplate.html();
                            if (templateHtml && templateId && !(templateId in this.assetTemplates)) {
                                this.assetTemplates[templateId] = _.template(templateHtml);
                            }
                        }, this);
                        $dfd = this._processContentOverrideByProvider(coOptions.provider, coOptions, $eligibleAssets,
                            $neighboringAssets, analyticsProviders, pageInfo.ssts || "home", pageInfo.canonical ||
                            window.location.href);
                    }
                } else {
                    if (analyticsProviders.length === 0) {
                        $dfd.reject();
                    } else {
                        $dfd = this._processContentOverrideWithoutProvider($eligibleAssets, coOptions, analyticsProviders);
                    }
                }
            }
            return $dfd
                .done(_.bind(function() {
                    if (coOptions.abTestId && analyticsProviders.length) {
                        this._addAnalyticsEventsAndFire($el, analyticsProviders, {
                            abTestId: '' + coOptions.abTestId,
                            abTestVariant: coOptions.abTestVariant
                        });
                    }
                }, this))
                .fail(function() {
                    $el.addClass('js-co-failed');
                })
                .always(function () {
                    Utils.lazyLoadImage($eligibleModules.find('img'));
                    $eligibleModules.removeClass('placeholder');
                })
                .promise();
        },
        /**
         * Fetch content from provider and update the eligible assets in the provided set of assets.
         * @param {String} provider - Asset to perform content override on.
         * @param {Object} options - Content override options
         *      @param {String} options.abTestId
         *      @param {String} options.abTestVariant
         *      @param {String} options.widgetId
         *      @param {Number} options.timeout
         *      @param {String} options.sstsOverride
         *      @param {String} options.assetType
         * @param {jQuery} $assets - Collection of assets to be processed for content override.
         * @param {jQuery} $neighboringAssets - Assets that live within the content override-eligible modules but are
         *                 not to be replaced.  This is useful for preventing dupes.
         * @param {Array} [analyticsProviders]
         * @param {String} [ssts] - SSTS path to be passed along to providers
         * @param {String} [url] - URL path to be passed along to providers
         * @returns {Deferred}
         * @private
         */
        _processContentOverrideByProvider: function(provider, options, $assets, $neighboringAssets, analyticsProviders, ssts, url) {
            var $dfd = $.Deferred().resolve(),
                timeout = options.timeout || 10000,
                params = {},
                neighbors = TemplateUtils.getAssetIdsFromAssets($neighboringAssets),
                // Enable analytics by default for the current provider and any additional configured providers
                recsNeeded = $assets.length - $neighboringAssets.length;
            if (recsNeeded > 0) {
                switch (provider) {
                    case 'pong':
                        params = _.pick(options, ['days','metric']);
                        params.asset_type = options.assetType || 'text';
                        params.ssts = options.sstsOverride || ssts;
                        params.exclude = neighbors.join(',');
                        params.count = recsNeeded;
                        $dfd = RequestManager.fetchData('/modules/pong-stories.json?' + $.param(params),
                            {timeout: timeout}, false, false, false)
                            .then(_.bind(function (recs) {
                                return _.map(Utils.getNested(recs, 'assets'), this.formatPongAsset);
                            }, this));
                        break;
                    case 'solr':
                        var metric = options.metric || 'most-popular';
                        params = _.pick(options, ['days']);
                        params.assetTypes = options.assetType;
                        params.ssts = options.sstsOverride || ssts;
                        params.exclude = neighbors.join(',');
                        params.count = recsNeeded;
                        params.i = this.getImageDimensions($assets.find(this.selectors.assetImage));
                        $dfd = RequestManager.fetchData('/services/assets/' + metric + '/?' + $.param(params),
                            {timeout: timeout}, false, false, false)
                            .then(_.bind(function (recs) {
                                return _.map(recs, this.formatSolrAsset);
                            }, this));
                        break;
                    case 'taboola':
                        var taboola = new Taboola({isWidgetPresent: false}),
                            assetIdRegExp = /\/(\d*)(\/?)$/gi;
                        $dfd = taboola.getRelated(
                            null, // no callback fn (using promise method)
                            "section", // source type
                            options.assetType || 'text', // rec type
                            recsNeeded + 10, // always request more to account for filtering and de-duping,
                            url, // source.url
                            null, // source.id (rely on taboola.js detection)
                            options.placement, // placement
                            true // recVisible
                        ).then(_.bind(function(data) {
                                var assetId,
                                    i = 0;
                                return _.chain(data.list)
                                        .filter(_.bind(function (rec) {
                                            assetId = assetIdRegExp.exec(rec.id) || [];
                                            if (!_.contains(neighbors, assetId[1]) && i < recsNeeded) {
                                                if (this.filterTaboolaRec(rec)) {
                                                    i++;
                                                    return true;
                                                }
                                            }
                                        }, this))
                                        .map(this.formatTaboolaRec)
                                        .value();
                            }, this));
                        break;
                    default:
                    // Do nothing
                }
            }
            $dfd.done(_.bind(function(formattedRecs) {
                _.each($assets, function (asset) {
                    var $asset = $(asset),
                        // If a rec is needed, we remove the first item from the recs array and use it
                        context = $asset.hasClass('js-co-asset') && _.isArray(formattedRecs) ? formattedRecs.shift() : {},
                        abTest = _.pick(options, ["abTestId", "abTestVariant"]),
                        $module = $asset.closest(this.selectors.coModule),
                        assetPosition = $module.find(this.selectors.coAsset).index($asset) + 1;
                    if (!_.isEmpty(context)) {
                        context = this.setAssetContextDefaults(context);
                        context.position = assetPosition;
                        context.modulePosition = $module.index() + 1;
                    }
                    this.updateAsset(asset, context).then(function (args) {
                        /**
                         * {Object} args[0] - updated asset
                         * {jQuery} args[1] - link elements from updated asset
                         */
                        $(args[0]).addClass('js-co-asset-' + ((context) ? provider : 'default'));
                        _.each(args[1], function (link) {
                            var $link = $(link);
                            if (context && context.pixelUrl) {
                                $link.data(provider + '-click-pixel', context.pixelUrl);
                            }
                            Analytics.addAnalyticsToLink(link, analyticsProviders, _.extend(abTest, {
                                bucket: 'content-override',
                                pinType: ($asset.hasClass('js-backfill-asset')) ? 'backfill' : 'curated',
                                assetPosition: assetPosition,
                                navSource: $link.attr('data-track-label') || $link.attr('data-ht') || ""
                            }));
                        });
                    });
                }, this);
            }, this));
            return $dfd.promise();
        },
        /**
         * Process content override when a provider isn't specified - used for control or default variants.
         * @param {Array} $assets
         * @param {Object} options
         * @param {Array} analyticsProviders
         * @returns {Promise}
         * @private
         */
        _processContentOverrideWithoutProvider: function($assets, options, analyticsProviders) {
            return $.Deferred(_.bind(function(defer) {
                _.each($assets, function (asset) {
                    var $asset = $(asset),
                        abTest = _.pick(options, ["abTestId", "abTestVariant"]),
                        $module = $asset.closest(this.selectors.coModule),
                        assetPosition = $module.find(this.selectors.coAsset).index($asset) + 1,
                        $links = $asset.find(this.selectors.assetLink).addBack(this.selectors.assetLink);
                    $asset.addClass('js-co-asset-default');
                    _.each($links, function (link) {
                        var $link = $(link);
                        Analytics.addAnalyticsToLink(link, analyticsProviders, _.extend(abTest, {
                            bucket: 'content-override',
                            pinType: ($asset.hasClass('js-backfill-asset')) ? 'backfill' : 'curated',
                            assetPosition: assetPosition,
                            navSource: $link.attr('data-track-label') || $link.attr('data-ht') || ""
                        }));
                    });
                }, this);
                defer.resolve();
            }, this)).promise();
        },
        /**
         * Provides a collection of content override-eligible modules based on the given array.
         * @param {jQuery} $el
         * @param {Array<Object>} modules - List of module names and instance indexes.
         * @returns {jQuery} Modules to be used in content override processing.
         */
        getContentOverrideModules: function($el, modules) {
            if (!_.isEmpty(modules)) {
                var $coModules = $();
                _.each(modules, function(module) {
                    $coModules = $coModules
                        .add($el.find('.' + module.name + '-module' + this.selectors.coModule)
                            .eq(Utils.getNested(module, 'instanceIndex')
                        )
                    );
                }, this);
                return $coModules;
            }
        },
        /**
         * Update asset display and content with normalized context object.
         * @param {Object} asset - Asset to perform content override on.
         * @param {Object} context - Normalized context object to be used in content override.
         *      @param {Number} context.id
         *      @param {String} context.headline
         *      @param {String} context.shortHeadline
         *      @param {String} context.description
         *      @param {String} context.url
         *      @param {String} context.author
         *      @param {String} context.source
         *      @param {String} context.imgSrc
         *      @param {String} context.imgAlt
         *      @param {Object} context.crops
         *          @param {String} context.crops.16_9
         *          @param {String} context.crops.4_3
         *          @param {String} context.crops.1_1
         *          @param {String} context.crops.3_4
         *          @param {String} context.crops.9_16
         *      @param {Object} context.images
         *      @param {String} context.section - Section or category, e.g. "sports/nfl".
         *      @param {String} context.sectionDisplay - Section or category to be displayed, e.g. "NFL".
         *      @param {String} context.time - Date/time to be displayed, e.g. "3 min ago".
         *      @param {String} context.timestamp - Publish date/time.
         *      @param {Number} context.position - Asset position within the module.
         *      @param {Number} context.modulePosition - Module position.
         *      @param {String} context.type - Type of asset, e.g. "video"
         * @returns {Promise}
         */
        updateAsset: function(asset, context) {
            return $.Deferred(_.bind(function(defer) {
                var $asset = $(asset),
                    templateId = $asset.attr('data-co-template'),
                    usesTemplate = templateId && templateId in this.assetTemplates,
                    origAssetClasses = $asset.attr('class'),
                    $links = [];
                if (!_.isEmpty(context)) {
                    // If the asset has a template associated with it, attempt to render
                    if (usesTemplate) {
                        try {
                            $asset = $(this.assetTemplates[templateId](context)).replaceAll($asset);
                            // Carry over previously existing classes after template replacement
                            $asset.addClass(origAssetClasses);
                        } catch (e) {
                            console.warn(templateId + ' template failed to render for "' + context.headline + '"');
                            // If template fails to render, proceed as normal, falling back to dom updates
                            usesTemplate = false;
                        }
                    }
                    try {
                        $links = $asset.find(this.selectors.assetLink).addBack(this.selectors.assetLink);
                        // Update link(s)
                        _.each($links, function (link) {
                            this.updateAssetLink($(link), context.url, context.headline, context.section);
                        }, this);

                        // Swap in appropriately-cropped image (if available) and account for lazy loading
                        $asset
                            .find(this.selectors.assetImage)
                            .each(_.bind(function (idx, img) {
                                var $img = $(img);
                                if (context.imgSrc) {
                                    this.updateAssetImage($img, context.imgSrc, context.imgAlt, context.crops,
                                        context.images);
                                    Utils.lazyLoadImage($img);
                                } else {
                                    $img.remove();
                                }
                            }, this));

                        // Only perform these direct replacements when not using a template
                        if (!usesTemplate) {
                            $asset = this.updateAssetContent($asset, context.id, context.headline,
                                context.shortHeadline,  context.description, context.author, context.source,
                                context.time, context.section, context.sectionDisplay);
                        }

                        // Override completed
                        $asset.addClass('js-co-asset-processed');

                        defer.resolve([$asset, $links]);
                    } catch (e) {
                        defer.reject();
                    }
                } else {
                    $links = $asset.find(this.selectors.assetLink).addBack(this.selectors.assetLink);
                    defer.resolve([$asset, $links]);
                }
            }, this)).promise();
        },
        /**
         * Update asset content values directly with passed in values.
         * @param {Object} $asset - Asset to perform content override on.
         * @param {Number} id - Asset ID.
         * @param {String} headline - Headline.
         * @param {String} shortHeadline - Shortened version of the headline to be used when necessary.
         * @param {String} description - Long description.
         * @param {String} author - Author of the article.
         * @param {String} source - Publication source.
         * @param {String} time - Publish time to be displayed, e.g. "7 hours ago".
         * @param {String} section - Section to be used for themes, e.g. "sports/nba".
         * @param {String} sectionDisplay - Section to be displayed, e.g. "NBA".
         * @returns {Object} - Updated $asset
         */
        updateAssetContent: function($asset, id, headline, shortHeadline, description, author, source, time, section, sectionDisplay) {
            $asset
                .attr('data-asset-id', id)

                // Update section css theme class names
                .attr('class', _.bind(function(idx, css) {
                    return this.updateThemeClassNames(css, section);
                }, this))

                // Update section name and css theme class names
                .find(this.selectors.assetSection)
                .attr('class', _.bind(function(idx, css) {
                    return this.updateThemeClassNames(css, section);
                }, this))
                .html(sectionDisplay)
                .end()

                // Replace headlines using the shortened version where designated
                .find(this.selectors.assetHeadline)
                .each(function(idx, hl) {
                    var hed = headline,
                        $hl = $(hl);
                    if ($hl.hasClass('js-asset-headline-short') && shortHeadline) {
                        hed = shortHeadline;
                    }
                    $hl.html(hed).attr('title', headline);
                })
                .end()

                .find(this.selectors.assetAuthor)
                .html(author)
                .end()

                .find(this.selectors.assetSource)
                .html(source)
                .end()

                // Description and published time
                .find(this.selectors.assetDescription)
                .html(description)
                .end()

                .find(this.selectors.assetTimestamp)
                .html(time)
                .end()

                // Remove any content that isn't currently supported via client-side content replacement
                .find(this.selectors.assetDisposable)
                .remove();
            return $asset;
        },
        /**
         * Update asset image with crops and images from normalized context object.
         * @param {Object} $img - Image to update.
         * @param {String} imgSrc - Image source.
         * @param {String} alt - Normalized context object to be used in content override.
         * @param {Object} [crops] - Available image crops.
         * @param {Object} [images] - Available images.
         * @returns {Object} - Updated $img object.
         */
        updateAssetImage: function($img, imgSrc, alt, crops, images) {
            var resizedImage = Utils.getNested(images, $img.attr('width') + 'x' + $img.attr('height'), 'src');
            if (resizedImage) {
                imgSrc = resizedImage;
            } else {
                imgSrc = TemplateUtils.getCroppedImage(
                    imgSrc,
                    $img.attr('width'),
                    $img.attr('height'),
                    crops,
                    $img.attr('data-crop')
                );
            }
            $img.attr(($img.attr('data-src')) ? 'data-src' : 'src', imgSrc).attr('alt', alt);
            return $img;
        },
        /**
         * Update asset link with provided context.
         * @param {Object} $link - Link to update.
         * @param {String} url - Link destination.
         * @param {String} headline - Headline to be used for the link's title.
         * @param {String} section - Site section, e.g. "nba"
         * @returns {Object} - Updated $link object.
         */
        updateAssetLink: function($link, url, headline, section) {
            $link.attr({
                'href': url,
                'title': headline,
                'class': _.bind(function (idx, css) {
                    return this.updateThemeClassNames(css, section);
                }, this),
                'target': _.bind(function () {
                    return (url.indexOf('://') != -1 && url.indexOf(this.baseUrl) == -1) ? '_blank' : null;
                }, this)
            });
            return $link;
        },
        /**
         * Update css 'theme' class names based on section name, e.g. "sports-theme-bg".
         * @param {String} cssClasses - Space-delimited list of class names.
         * @param {String} section - Section to be used in class replacement, e.g. "life".
         * @returns {String}
         */
        updateThemeClassNames: function(cssClasses, section) {
            return cssClasses.replace(/(?:sponsor-)?(\w+)-theme-(\w+)/gi, section + '-theme-$2');
        },
        setAssetContextDefaults: function(context) {
            return _.defaults(context, {
                id: 0,
                headline: '',
                shortHeadline: '',
                description: '',
                url: '',
                author: '',
                source: '',
                imgSrc: '',
                imgAlt: '',
                crops: {},
                images: {},
                section: '',
                sectionDisplay: '',
                time: '',
                timestamp: '',
                position: 0,
                modulePosition: 0,
                type: ''
            });
        },
        /**
         * Returns the content override configuration from the site config.  Content override can be configured in the
         * site config as either an A/B test (priority) or as a permanent feature (contentOverride).
         * @param {Object} abTest
         * @returns {Object}
         */
        getContentOverrideOptions: function(abTest) {
            var abTestOptions = Utils.getNested(abTest, 'userVariant', 'options', 'contentOverride'),
                contentOverrides = Utils.getNested(window.site_vars, 'contentOverrides'),
                options = {};
            if (abTestOptions) {
                options = abTestOptions;
                options.abTestId = abTest.id;
                options.abTestVariant = abTest.userVariant.id;
            } else if (contentOverrides) {
                options = _.find(contentOverrides, function(co) {
                    try {
                        return new RegExp(co.urlRegex).test(window.location.pathname);
                    } catch (e) {
                        return false;
                    }
                });
            }
            return options;
        },
        /**
         * @param {Object} asset
         *      @param {String} asset.headline
         *      @param {String} asset.promoBrief
         *      @param {Object} asset.urls
         *            @param {String} asset.urls.longUrl
         *      @param {Object} asset.photo
         *            @param {Object} asset.photo.crops
         *                  @param {String} asset.photo.crops.front_thumb
         *      @param {Object} asset.ssts
         *            @param {String} asset.ssts.section
         *            @param {String} asset.ssts.subsection
         *            @param {String} asset.ssts.taxonomyEntityDisplayName
         *      @param {String} asset.datePublished
         *      @param {String} asset.content_type
         *      @param {Number} asset.id
         * @param {Number} index
         * @returns {Object}
         */
        formatContentLiteAsset: function(asset, index) {
            var sstsSection = Utils.getNested(asset, 'ssts', 'section'),
                sstsSubSection = Utils.getNested(asset, 'ssts', 'subsection'),
                ssts = sstsSection + ((sstsSubSection) ? "/" + sstsSubSection : ""),
                crops = Utils.getNested(asset, 'photo', 'crops') || {};
            return {
                headline: asset.headline,
                shortHeadline: asset.headline,
                description: asset.promoBrief,
                author: Utils.getNested(asset, 'byline', 'author'),
                url: TemplateUtils.relativizeUrl(Utils.getNested(asset, 'urls', 'longUrl')),
                imgSrc: crops.front_thumb || crops['1_1'],
                imgAlt: asset.promoBrief,
                crops: crops,
                section: sstsSection,
                sectionDisplay: TemplateUtils.getDisplaySection(ssts),
                time: moment(new Date(asset.datePublished)).fromNow(),
                timestamp: asset.datePublished,
                position: index,
                type: asset.content_type,
                id: asset.id
            };
        },
        formatPongAsset: function(asset, index) {
            return this.formatContentLiteAsset(asset, index);
        },
        formatSolrAsset: function(asset, index) {
            var images = asset.images || {},
                crops = images.crops || {};
            return {
                headline: asset.headline,
                shortHeadline: asset.headlineShort,
                description: asset.description,
                author: asset.author,
                url: asset.urlLocalized,
                imgSrc: crops.front_thumb || crops.videostill || images.original,
                imgAlt: images.caption || '',
                crops: crops,
                images: images,
                section: asset.sstsSection,
                sectionDisplay: asset.sstsDisplay,
                time: asset.datePublishedTimeSince,
                timestamp: asset.datePublished,
                position: index,
                type: asset.type,
                id: asset.id
            };
        },
        formatTaboolaRec: function(rec, index) {
            var ssts = rec.categories || [],
                imgUrl = Utils.getNested(rec, 'thumbnail', 0, 'url');
            if (imgUrl) {
                imgUrl = decodeURIComponent(imgUrl.substring(imgUrl.indexOf('/http') + 1));
            }
            return {
                headline: rec.name,
                description: rec.description,
                url: rec.id,
                imgSrc: imgUrl,
                imgAlt: rec.name,
                section: ssts[0],
                sectionDisplay: TemplateUtils.getDisplaySection(ssts.join("/")),
                time: moment(new Date(rec.created)).fromNow(),
                timestamp: rec.created,
                position: index,
                pixelUrl: rec.url,
                type: rec.type
            };
        },
        filterTaboolaRec: function(rec) {
            var imgUrl = Utils.getNested(rec, 'thumbnail', 0, 'url') || '',
                url = rec.id || '',
                isFuture = new Date(rec.created) > Date.now();
            imgUrl = (imgUrl) ? imgUrl.toString() : '';
            return url.match(/story|videos|picture-gallery/) && !imgUrl.match(/applogos|images\/logos/) && !isFuture;
        },
        /**
         * @param {Array} images
         * @returns {String}
         */
        getImageDimensions: function(images) {
            return _.chain(images).map(function(image) {
                if (image.hasAttribute('width') && image.hasAttribute('height')) {
                    return image.getAttribute('width') + 'x' + image.getAttribute('height');
                }
            }).uniq().value().join(',');
        },
        /**
         * Attach an analytics event to the provided $el's events queue.
         * @param {jQuery} $el - Dom element to append the tracker to.
         * @param {String|Array} providers - provider the
         * @param {Object.<String|Number>} params
         * @private
         */
        _addAnalyticsEvents: function($el, providers, params) {
            var events = $el.data('analytics-events') || {};
            if (_.isString(providers)) providers = [providers];
            _.each(providers, function(provider) {
                events[provider] = params;
            });
            $el.data('analytics-events', events);
        },
        /**
         * @param {jQuery} $el
         * @param {String|Array<String>} providers
         * @param {Object.<String|Number>} params
         * @private
         */
        _addAnalyticsEventsAndFire: function($el, providers, params) {
            if (_.isString(providers)) providers = [providers];
            _.each(providers, function(provider) {
                this._addAnalyticsEvents($el, provider, params);
                PubSub.trigger('analytics:event', provider, params);
            }, this);
        },
        /**
         * @param {jQuery} $el
         * @private
         */
        _fireAnalyticsEvents: function($el) {
            var events = $el.data('analytics-events') || {};
            _.each(events, function(params, provider) {
                PubSub.trigger('analytics:event', provider, params);
            });
        }
    };
    return new TemplateManager();
});

