define('apps/topics',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'state',
    'site-manager',
    'managers/cachemanager',
    'base-app',
    'adPosition',
    'partner/overlay-ad-fronts',
    'modules/global/brightcove-video',
    'partner/gravity-ad-article',
    'partner/pushdown-ad-article',
    'modules/partner/poster-ad-asset2',
    'partner/digital-wrap'
],
function(
    $,
    _,
    PubSub,
    Utils,
    StateManager,
    SiteManager,
    CacheManager,
    BaseApp,
    AdPosition,
    OverlayAdFront,
    Video,
    GravityAd,
    PushdownAd,
    PosterAd2,
    DigitalWrap
)
{
    'use strict';
    var Topics = BaseApp.extend({

        initialize: function(options){
            this.pubSub = {
                'topicgallery:open'  : this.gravityPause,
                'topicgallery:close' : this.gravityResume,
                'topicarticles:fetched' : this._handleFetchedArticles
            };
            BaseApp.prototype.initialize.call(this, options);
        },

        afterPageReveal: function(fromUrl, toUrl) {
            if(this.$el.hasClass('topics-page-automated')){
                this._initNewTopicPage();
            } else {
                this._initLegacyTopicPage();
            }

            StateManager.stopRefreshTimer();
            CacheManager.setValue('last_front', Utils.getNested(this, 'pageInfo', 'topic'), 0);
        },

        _initNewTopicPage: function() {
            this.$pageWrap = this.$('.tpa-page-wrap');
            this.setupAds();
        },

        _getHeaderOffset: function() {
            var header = SiteManager.getHeader();
            if (header) {
                return header.getExpandedHeight();
            } else {
                return 0;
            }
        },

        _initLegacyTopicPage: function() {
            // Pulled from stag-front.js
            var $highImpactEl = $('#ad-staging');
            this.isNative = this.$el.hasClass('stag-native');
            this.subviews.video = [];

            // Initialize each video as its own video instance.
            $('.stag.hero .video').each(_.bind(function(i, el) {
                var video = new Video({
                    // Use .video parent() because hero markup is different.
                    el: $(el).parent().get(0)
                });
                this.subviews.video.push(video);
            }, this));

            this.subviews.overlayAd = new OverlayAdFront({
                el: this.$('.partner-overlay'),
                slot: $highImpactEl,
                leaveBehindEl: this.$(!this.isNative ? '.partner-leavebehind' : '.stag-masthead-legal'),
                isCardBumped: false,
                isNative: this.isNative
            });

            // Generic Ad Call for PointRoll
            this.subviews.genericad = new AdPosition({
                el: $highImpactEl,
                slot: $highImpactEl,
                adSizes: ['generic'],
                adPlacement: 'high_impact',
                adType: 'generic'
            });

            if(Utils.getNested(window.site_vars, 'ADS', 'digital_wrap') || Utils.getUrlParam('dw_debug')) {
                this.subviews.DigitalWrap = new DigitalWrap({
                    el: $highImpactEl,
                    slot: $highImpactEl,
                    container : this.$('.stagfront-content'),
                    backgroundEl : $('#partner-content')
                });

                // Digital Wrap sponsor logo
                this.subviews.sponsorLogo = new AdPosition({
                    el: this.$('.partner-sponsor-logo'),
                    adType : 'Sponsor Logo',
                    adSizes : [800, 40],
                    adPlacement : 'sponsor_logo'
                });
            }

            PubSub.trigger('advertising:defined');
        },

        activateLoader: function() {
            window.scrollTo(0, 0);
            this.$el.html('<section class="tpa-page-loader"></section>');
        },

        deactivateLoader: function() {
            window.scrollTo(0, 0);
            this.$el.html('');
        },

        setupAds: function() {
            var $highImpactEl = this.$('.partner-pushdown-ad');
            // use the pushdown as the default ad position (shared with Gravity)
            this.subviews.pushdownAd = new PushdownAd({
                el: $highImpactEl,
                slot : $highImpactEl,
                cards: this.$pageWrap,
                cardInfo : {
                    adClass: "size-m",
                    adTargeting: "medium",
                    cardWidth: 1080
                }
            });

            this.subviews.gravityAd = new GravityAd({
                cards: this.$pageWrap,
                slot : $highImpactEl,
                contentSection : this.$pageWrap,
                el: this.$('.partner-gravity-ad')
            });

            this.subviews.ad = new AdPosition({
                el: this.$('.tpa-ad-sidebar-wrapper'),
                adSizes: ['mediumrectangle'],
                adPlacement: 'poster_front',
                adType: 'sidebar'
            });

            this.subviews.posterAd = new PosterAd2({
                el: this.$('.tpa-scroller-ad-sidebar-wrapper'),
                articleSelector: '.tpa-content-wrap',
                rightRailSelector: '.tpa-sidebar-wrap',
                disableRefresh: false,
                minTimeForAd: 15000
            });

            this.subviews.genericad = new AdPosition({
                el: $('#ad-staging'),
                slot : $highImpactEl,
                adSizes: ['generic'],
                adPlacement: 'high_impact',
                adType: 'generic'
            });

            if(Utils.getNested(window.site_vars, 'ADS', 'digital_wrap') || Utils.getUrlParam('dw_debug')) {

                // Digital Wrap background image
                this.subviews.DigitalWrap = new DigitalWrap({
                    el: $('#ad-staging'),
                    slot : $highImpactEl,
                    container : $('body'),
                    backgroundEl: $('body'),
                    offset: this._getHeaderOffset()
                });

                // Digital Wrap sponsor logo
                this.subviews.sponsorLogo = new AdPosition({
                    el: this.$('.partner-sponsor-logo'),
                    adType : 'Sponsor Logo',
                    adSizes : [800, 40],
                    adPlacement : 'sponsor_logo'
                });
            }

            PubSub.trigger('advertising:defined');
        },

        gravityPause: function() {
            if(this.subviews.gravityAd) {
                if(this.subviews.gravityAd.showingAd) {
                    this.subviews.gravityAd.subviews.adPosition.pauseAd();
                    this.subviews.gravityAd.$el.css('display', 'none');
                }
            }
        },

        gravityResume: function() {
            if(this.subviews.gravityAd) {
                if(this.subviews.gravityAd.showingAd) {
                    this.subviews.gravityAd.$el.css('display', 'block');
                    this.subviews.gravityAd.subviews.adPosition.resumeAd();
                }
            }
        },

        _handleFetchedArticles: function() {
            if(this.subviews.posterAd) {
                this.subviews.posterAd._setArticleDimensions();
            }
        }

    });
    return Topics;
});

