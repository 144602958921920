define('modules/videos/pluto/pluto-video',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'pubsub',
    'admanager',
    'modules/videos/pluto/pluto-ads',
    'modules/videos/pluto/pluto-share',
    'modules/videos/pluto/pluto-utils',
    'modules/videos/pluto/pluto-analytics',
    'modules/videos/pluto/pluto-embed'

],
    function (
    $,
    _,
    BaseView,
    Utils,
    PubSub,
    AdManager,
    PlutoAds,
    PlutoShare,
    PlutoUtils,
    PlutoAnalytics,
    PlutoEmbed
) {

    "use strict";


    var PlutoVideo = BaseView.extend(
        {
            events: {
                'click .js-pluto-title-overlay-play' : 'playVideo'
            },

            initialize: function (options) {

                options = $.extend({
                    accountId: Utils.getNested(window.site_vars, 'html5video', 'accountId'),
                    actionName: 'plutoVideoReport',
                    baseUrl: null,
                    checkBrowserTabInactivity: Utils.getNested(window.site_vars, 'html5video', 'checkBrowserTabInactivity'),
                    inactivityTimeout: Utils.getNested(window.site_vars, 'html5video', 'userInactivityTimeout'),
                    isRecommended: null,
                    liteAnalytics: null,
                    onAdEnd: null,
                    onAdPlay: null,
                    onAdVolumeChange: null,
                    onFullScreenChange: null,
                    onPlayback: null,
                    onVideoPause: null,
                    onVideoPlay: null,
                    onVolumeChange: null,
                    pageInfo: null,
                    playerId: Utils.getNested(window.site_vars, 'html5video', 'playerId'),
                    videoStart: this.options.videoStart,
                    isFirstPlaylistVideoMuted: this.options.isFirstPlaylistVideoMuted
                }, options);
                this.pubSub = {
                    'plutovideo:play': _.bind(this.videoIsPlaying, this),
                    'apsBidsBack': _.bind(this.apsBidsBack, this)
                };

                BaseView.prototype.initialize.call(this, options);

                _.bindAll(this, 'changeVideo', 'onEmbedClose', 'exitFullScreen', 'getPlutoAnalytics',
                    'getVideoCurrentTime', 'isPlaying', 'onAdEnd', 'onAdPause', 'onAdPlay', 'onAdVolumeChange',
                    'onContentPlayback', 'onFullScreenChange', 'onLoadStart', 'onTimeupdate', 'pauseVideo', 'playVideo',
                    'resetVideoState', 'shouldIncludeRecommendation', 'startAutoPlay', 'onEmbedShow', 'toggleVolume');

                this.sv = {};
                this.video = PlutoUtils.buildData(this.$('.ui-video-data'));
                this.placement = PlutoUtils.buildData(this.$('.ui-video-placement-data'));
                this.uwIframes = $('.uw-iframe');
                this.initPromise = $.Deferred();
                this.adCallMade = false;
                this.comscoreAdCallMade = false;
                this.pauseOnLoad = false;
                // Flag to track ad and video loading state
                this.buffering = true;
                this.irisEnabled = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'enabled');
                if (!this.options.accountId || !this.options.playerId) {
                    return;
                }
                PlutoAnalytics.initAnalytics(this.options.liteAnalytics);
                this.createVideoTag();

                if (!window.vjsBc){
                    this.requireBc();
                } else {
                    window.vjsBc(this.$('video')[0]);
                    this.setupPlayer();
                }

            },

            apsBidsBack: function (bids) {
                if (bids && window.videoA9Slots && this.placement.apsEnabled) {
                    var slot = _.filter(window.videoA9Slots, _.bind(function(slot){
                        return parseInt(slot.videoID) === parseInt(this.video.id);
                    }, this))[0];


                    for (var i = 0; i < bids.length; i++) {
                        if (bids[i].slotID === slot.slotID) {
                            this.bidSlot = bids[i];
                            if (this.sv.ads) this.sv.ads.setBid(this.bidSlot);
                            break;
                        }
                    }
                }
            },

            /**
             * Creates video tag with brightcove specific attributes
             */
            createVideoTag: function () {
                var video = $('<video />', {
                    'id': 'vjs_' + $.now(),
                    'data-account': this.options.accountId,
                    'data-player': this.options.playerId,
                    'data-video-id': (this.video.id) ? 'ref:' + this.video.id : this.video.brightcove_id,
                    'data-embed': 'default',
                    'class': 'video-js pluto-video',
                    'poster': this.$('.ui-pluto-still img').attr('src') || ''
                });
                this.$el.prepend(video);
            },


            /**
             * Add config path for player script
             * Brightcove recommends 30s timeout, default=7s
             * bc is returned from config path, define("bc", [], b)
             */
            requireBc: function () {
                var req = require.config({
                    'paths': {
                        'bc': '//players.brightcove.net/' + this.options.accountId + '/' + this.options.playerId + '_default/index.min'
                    },
                    timeout: 30
                });

                /**
                 * !window.vjsBC, use require method to load "bc" dependency
                 * assign to window for subsequent uses
                 */
                req(["bc"], _.bind(function(bc) {
                    window.vjsBc = bc;
                    this.setupPlayer();
                }, this));
            },


            /**
             * setup handle to to targer the video, add title,
             * event listeners and percent flags
             * call all subviews
             */
            setupPlayer: function () {
                var _id, videoEl = $(this.$('video')[0]);
                if (videoEl.hasClass('vjs-tech')) {
                    _id = videoEl.parent()[0].id;
                } else {
                    _id = videoEl.id;
                }
                //ipad does not allow continuous play, so turn it off
                if (window.videojs.IS_IPAD) {
                    this.placement.gannett_continuous_endslate = false;
                }
                this.vjsPlayer = window.videojs(_id).preload('metadata');
                if (!this.options.videoStart) {
                    this.options.videoStart = (this.placement.autoplay) ? 'auto-first' : 'user';
                }
                if ((this.placement.mutedAutoplay && this.placement.name.indexOf('playlist') === -1) || this.options.isFirstPlaylistVideoMuted) {
                    this.vjsPlayer.muted(true);
                }

                if (this.placement.ads) this.initAds();

                this.vjsPlayer.ready(_.bind(function(){
                    if (!this.hasListeners) this.setupEventListeners(this.vjsEvents);
                    this.$('.vjs-control-bar').append(
                        '<div class="vjs-video-title js-video-title"><span class="vjs-video-title-span">' + this.video.title + '</span></div>'
                    );
                }, this));

                this.$el.addClass('pluto-video-ready');

                this.vjsPlayer.options().inactivityTimeout = this.options.inactivityTimeout;

                this.videoViewCalled = false;
                this.fiftyPercent = false;
                this.ninetyFivePercent = false;

                if (this.placement.embed) {
                    this.subviews.embed = new PlutoEmbed({
                        el: this.$el,
                        addEmbedBtn: true,
                        analytics: PlutoAnalytics,
                        baseUrl: this.options.baseUrl,
                        onEmbedClose: this.onEmbedClose,
                        onEmbedShow: this.onEmbedShow,
                        siteDisplayName: this.options.siteDisplayName,
                        videoId: this.video.id
                    });
                }
                if (this.placement.share) {
                    if (this.video.short_url || this.video.full_url){
                        this.sv.share = this.subviews.plutoshare = this.initShare(this.$el, null, null);
                    }
                }
            },

            onEmbedShow: function() {
                this.resumePlay = this.isPlaying();
                this.exitFullScreen();
                this.pauseVideo();
                this.embedOpen = true;
            },

            onEmbedClose: function() {
                if (this.resumePlay) {
                    this.playVideo();
                }
                this.embedOpen = false;
            },

            /**
             * subview setup for ads
             */
            initAds: function () {
                this.sv.ads = this.subviews.plutoads = new PlutoAds({
                    adAccountId: Utils.getUrlParam('adaccountid'),
                    adAccountName: Utils.getUrlParam('adaccountname'),
                    adLabel: Utils.getUrlParam('adlabel'),
                    adPlacement: Utils.getUrlParam('adplacement') || 'preroll_video',
                    adUnit: this.options.adUnit,
                    cstObj: this.cstToObj(),
                    el: this.el,
                    ima3: Utils.getNested(window.site_vars, 'html5video', 'ima3') || this.options.ima3,
                    minimumAdLength: Utils.getNested(window.site_vars, 'html5video', 'ima3', 'minimumAdLength'),
                    onAdEnd: this.onAdEnd,
                    onAdPause: this.onAdPause,
                    onAdPlay: this.onAdPlay,
                    onVolumeChange: this.onAdVolumeChange,
                    pageInfo: this.options.pageInfo,
                    placementData: this.placement,
                    playVideo: this.playVideo,
                    sstsObj: this.sstsToObj(),
                    videoData: this.video,
                    videoStart: this.options.videoStart,
                    vjsPlayer: this.vjsPlayer,
                    bidSlot: this.bidSlot
                });
            },

            /**
             * subview setup for share
             */
            initShare: function (el, onShare, onClose) {
                return new PlutoShare({
                    analyticsEnabled: this.placement.analyticsEnabled,
                    analytics: PlutoAnalytics,
                    controlBarSelector: '.vjs-control-bar',
                    el: el,
                    exitFullScreen: this.exitFullScreen,
                    getPlutoAnalytics: this.getPlutoAnalytics,
                    isVideoPlaying: this.isPlaying,
                    onClose: onClose,
                    onShare: onShare,
                    pauseVideo : this.pauseVideo,
                    placementName: this.placement.name,
                    playVideo : this.playVideo,
                    siteDisplayName: this.options.siteDisplayName,
                    twitterAccount: this.options.twitterAccount,
                    videoData: this.video
                });
            },

            /* PlutoShare will use the existing Analytics object passed in options by Pluto-Video */
            getPlutoAnalytics: function() {
                return PlutoAnalytics || {}; // if plutoAnalytics is not initialized;
            },

            getTitle: function() {
              return this.video.title;
            },

            /**
             * Sets up the event listeners for a video player.
             * available events 'videojs.Html5.Events';
             */
            setupEventListeners: function () {
                this.hasListeners = true;
                this.vjsEvents = {
                    contentplayback: this.onContentPlayback,
                    fullscreenchange: this.onFullScreenChange,
                    loadedmetadata: this.onLoadedMetadata,
                    loadstart: this.onLoadStart,
                    ended: this.onEnded,
                    error: this.onPlaybackError,
                    pause: this.onPause,
                    play: this.onPlay,
                    timeupdate: this.onTimeupdate,
                    volumechange: this.onVolumeChange
                };

                _.each(this.vjsEvents, _.bind(function (value, prop) {
                    this.vjsPlayer.on(prop, _.bind(value, this));
                }, this));
            },

            cstToObj: function () {
                var cst = Utils.getNested(this.video,'cst') || '',
                    list = cst.split('/');
                return {
                    section: list[0] || '',
                    subsection: list[1] || '',
                    topic: list[2] || ''
                };
            },

            sstsToObj: function () {
                var ssts = Utils.getNested(this.video,'ssts') || '';
                // create object from string in brightcove's case
                if (typeof ssts === 'string'){
                    var list = ssts.split('/');
                    return {
                        section: list[0] || '',
                        subsection: list[1] || '',
                        topic: list[2] || '',
                        subtopic: list[3] || ''
                    };
                } else {
                    // return object from core
                    return ssts;
                }
            },

            onFullScreenChange: function() {
                if (this.options.onFullScreenChange) this.options.onFullScreenChange();
                if (this.isFullScreen() && !window.videoIsScrolling &&  PlutoAnalytics) PlutoAnalytics.trackVideoEvents('HTML5PlayerFullScreen');
            },

            onContentPlayback: function() {
                if (this.sv.ads) {
                    this.sv.ads.adOverlayReset();
                }
            },

            onLoadedMetadata: function() {
                this.textTracks = Utils.getNested(this.vjsPlayer,'mediainfo', 'textTracks') || [];
                if (this.textTracks.length) this.$('.vjs-captions-button').show();
                else this.$('.vjs-captions-button').hide();

                this.initPromise.resolve(this.vjsPlayer);
            },

            onLoadStart: function() {
                if (this.continuousPlay) {
                    //only call startAutoPlay on load start if continuous play is true
                    this.startAutoPlay();
                }
            },

            callForAd: function() {
                if (this.shouldGetAd()) {
                    this.adCallMade = true;
                    this.sv.ads.makeAdRequest(this.video);
                } else {
                    this.buffering = false;
                }
            },

            /**
             * Rules for getting an ad
             * @returns {boolean}
             */
            shouldGetAd: function() {
                return this.sv.ads && this.isValidLocation() && this.video.adsenabled && !this.adCallMade && window.noAds !== true;
            },


            /**
             * Check the url & parent url (if iframe) for email address
             *
             * @returns {boolean}
             */
            isValidLocation: function() {
                return !(Utils.checkForEmail(document.referrer) || Utils.checkForEmail(window.location.href));
            },

            /**
             * Removes event listeners for a video player module.
             * http://docs.videojs.com/docs/api/player.html#Methodsoff
             */
            removeEventListeners: function (events) {
                _.each(events, _.bind(function (value, prop) {
                    this.vjsPlayer.off(prop);
                }, this));
            },

            /**
             * Fired on video playback
             */
            onPlay: function (e) {
                if (this.isAdPlaying()) {
                    this.pauseVideo();
                }
                PubSub.trigger('plutovideo:play', this.cid);
                /**
                 * this.buffering is False when ad starts to play, or it errors out or no ad was requested -
                 * fail-safe condition when isPlaying isn't being triggered properly
                 */
                if(this.isPlaying() || !this.buffering) this.$el.removeClass('ui-pluto-reset');
                /*
                    Hide black placeholder that ios10 injects on iPad
                 */
                if (window.videojs.IS_IPAD) {
                    var emptyDiv = this.$el.children();
                    if (!emptyDiv[0].classList.length) emptyDiv[0].style.display = 'none';
                }
                this.$('.pluto-video').show();
                this.callForAd();
                if (this.options.onVideoPlay) this.options.onVideoPlay();
                if (this.options.onGalleryPlay ){
                    this.options.onGalleryPlay();
                }
                PlutoAnalytics.comscoreContentPlayback(this.video, this.vjsPlayer, this.placement.name);
            },

            onPlaybackError: function() {
                this.log(this.getVideoInfo(), 'videoError');
            },

            /**
             * Get information from player
             * @returns {Object} video & player information
             */
            getVideoInfo: function() {
                var options = this.vjsPlayer.options(),
                    info = {};

                // try/catch to be safe, never know with Brightcove
                try {
                    info = {
                        account: options['data-account'],
                        videoId: options['data-video-id'],
                        playerId: options['data-player'],
                        techOrder: options.techOrder.toString(),
                        type: this.vjsPlayer.currentType(),
                        src: this.vjsPlayer.currentSrc(),
                        videoProgress: this.vjsPlayer.currentTime()
                    };
                } catch (err) {
                    // just return empty object
                }
                return info;
            },

            /**
             * Only allow 1 video to play at a time
             * @param {String} cid - ID of the view that is playing
             */
            videoIsPlaying: function(cid) {
                if (cid !== this.cid) {
                    if (this.sv.ads && this.sv.ads.isAdInProgress()) {
                        this.sv.ads.pauseAd();
                    } else {
                        this.pauseVideo();
                    }
                    PlutoAnalytics.stopComscoreTracking();
                }
                // if uw iframes on page, trigger pause
                if (this.uwIframes.length) {
                    _.each(this.uwIframes, _.bind(function (value, index) {
                        PlutoUtils.setPostMessage(true, this.uwIframes[index].contentWindow, 'universal-web-video-pause');
                     }, this));
                }
            },

            /**
             * When a video is requested to be paused.
             */
            onPause: function () {
                if (this.options.onPause) this.options.onPause();
                PlutoAnalytics.stopComscoreTracking();
            },

            onVolumeChange: function(e) {
                if (this.options.onVolumeChange) {
                    this.options.onVolumeChange(this.vjsPlayer.muted());
                }
            },

            onAdVolumeChange: function(isMuted) {
                //mute video if ad was muted
                this.vjsPlayer.muted(isMuted);
                if (this.options.onAdVolumeChange) {
                    this.options.onAdVolumeChange(isMuted);
                }
            },

            /**
             * Fired when the current playback position has changed
             * * During playback this is fired every 15-250 milliseconds,
             * depending on the playback technology in use.
             */
            onTimeupdate: function (e) {
                var currentTime = this.vjsPlayer.currentTime(),
                    duration = this.vjsPlayer.duration(),
                    percent = Math.round(currentTime / duration * 100),
                    placementName;

                if (this.pauseOnLoad) {
                    this.pauseVideo();
                    this.buffering = false;
                    this.resetPauseFlag();
                }

                //only call option timeupdate once - this is really for when trueview ads are skipped
                //because other events (adEnded, videoPlay, etc.)  aren't fired
                if (this.isPlaying() && !this.timeUpdateCalled) {
                    this.onAdEnd();
                    this.timeUpdateCalled = true;
                }
                if (!this.videoViewCalled && percent >= 2 ) {
                    this.videoViewCalled = true;
                    if (window.videoIsScrolling){
                        placementName = "scrollinghtml5";
                    } else if (window.videoIsDocked) {
                        placementName = "dockedplayerhtml5";
                    } else {
                        placementName = this.placement.name;
                    }
                    if (this && PlutoAnalytics) {
                        PlutoAnalytics.trackVideoOnload(this.video, this.vjsPlayer, placementName);
                    }
                }
                if (!this.fiftyPercent && percent >= 50 && percent < 95) {
                    this.fiftyPercent = true;
                    if (this && PlutoAnalytics) {
                        PlutoAnalytics.trackVideoProgression("50%", this.video.url);
                    }
                }
                if (!this.ninetyFivePercent && percent >= 95 && percent <= 99) {
                    if (this && PlutoAnalytics) {
                        PlutoAnalytics.trackVideoProgression("95%", this.video.url);
                    }
                    this.ninetyFivePercent = true;
                    if (this.placement.gannett_continuous_endslate && this.options.isRecommended) {
                        if (this.shouldIncludeRecommendation) {
                            //get bid for next ad
                            if (this.placement.apsEnabled && this.bidSlot) PubSub.trigger('nextVideoPlay', [{ slotID: this.bidSlot.slotID, mediaType: 'video'}]);
                            this.options.getNextVideo();
                        }
                    }
                }
                if (percent === 100) {
                    this.onEnded();
                }
            },

            /**
             * onEnded: Fired when 100% of video plays - triggers continuous play so that it does not rely on BC event.
             */
            onEnded: _.debounce(function () {
                if (!this.onEndedFired) {
                    this.exitFullScreen();
                    //Resetting ad loading flag for the next video
                    if (this.placement.gannett_continuous_endslate && this.options.isRecommended) {
                        if (this.shouldIncludeRecommendation()) {
                            this.buffering = true;
                            if (!this.irisEnabled) this.options.playNextVideo();
                        }
                        this.comscoreAdCallMade = false;
                    } else {
                        this.resetVideoState();
                    }

                    if (this.options.onEnded) {
                        this.options.onEnded();
                    }

                    PlutoAnalytics.stopComscoreTracking();
                    this.onEndedFired = true;
                }
            }, 550),


            /**
             * Exit fullscreen mode
             */
            exitFullScreen: function() {
                if (this.isFullScreen()) {
                    this.vjsPlayer.exitFullscreen();
                }
            },

            isFullScreen: function() {
                return this.vjsPlayer.isFullscreen();
            },


            /**
            * Determines if the recommendation needs to be included or not.
            * @returns {boolean}
             */
            shouldIncludeRecommendation: function() {
                return this.options.pageInfo.section_name != 'sponsor-story' && Utils.getNested(this.video,'ssts','section') != 'sponsor-story';
            },

            /**
             * Gets the current video data.
             * @returns {object}
             */
            getVideoData: function () {
                return this.video;
            },

            /**
             * Gets the current video player data.
             * @returns {object}
             */
            getPlacementData: function () {
                return this.placement;
            },

            /**
             * Seeks the video to a particular time.
             */
            seekVideo: function (time) {
                if (this.vjsPlayer) {
                    this.vjsPlayer.currentTime(time);
                }
            },

            /**
             * pause either ad or video
             */
            pause: function() {
                if (this.isAdPlaying()) {
                    this.sv.ads.pauseAd();
                } else {
                    this.pauseVideo();
                }
            },

            /**
             * When a video is requested to be paused.
             */
            pauseVideo: function () {
                if (this.vjsPlayer && !this.vjsPlayer.paused()) {
                    this.vjsPlayer.pause();
                }
            },

            /**
             * When a video is requested to be played.
             */
            playVideo: function () {
                if (this.options.checkBrowserTabInactivity ? !Utils.isDocumentHidden() : true) {
                    if (this.options.onPlayback) this.options.onPlayback();
                    if (this.vjsPlayer) this.vjsPlayer.play();
                }
            },

            /**
             * Dynamically change video src to play a new video within the same page
             */
            changeVideo: function (video_data) {
                if (video_data) {
                    var newVideoId = 'ref:' + video_data.id;
                    this.videoViewCalled = false;
                    this.fiftyPercent = false;
                    this.ninetyFivePercent = false;
                    this.continuousPlay = true;
                    PlutoAnalytics.resetMyStreamingTag();
                    this.vjsPlayer.catalog.getVideo(newVideoId, _.bind(function (error, video) {
                        if (video) {
                            if (window.pbjs && (Utils.getNested(window.site_vars, 'html5video', 'prebid', 'enabled') || Utils.getUrlParam('pb_video_id'))) {
                                this.sv.ads.prebidPromise = this._getPrebid(video_data.id);
                            }
                            this.onEndedFired = false;
                            this.adCallMade = false;
                            this.video = video_data;
                            this.sv.ads.options.videoStart = 'auto-continuous';
                            this.sv.ads.options.pageInfo.series = video_data.series || "";
                            this.sv.ads.options.pageInfo.topic = video_data.topic || "";
                            this.initPromise = $.Deferred();

                            // Maintain previous player volume on continuousplay
                            if (this.playerVolume === 0) {
                                this.vjsPlayer.muted(true);
                                this.sv.ads.playerVolume = 0;
                            } else {
                                this.vjsPlayer.volume(this.playerVolume);
                                this.sv.ads.playerVolume = this.playerVolume; // adPlayer needs to be updated with player volume
                            }
                            this.vjsPlayer.catalog.load(video);
                            if (this.sv.share) {
                                this.sv.share.options.videoData = video_data;
                                this.sv.share.updateShareMetadata();
                            }
                            if (this.subviews.embed) {
                                this.subviews.embed.setIframeCode(false);
                                this.subviews.embed.updateVideoId(video_data.id);
                            }
                        } else {
                            this.log(error, 'continuousPlayError');
                            this.resetVideoState();
                        }
                    }, this));
                }
            },
            _getPrebid: function (id) {
                var prebidPromise = $.Deferred(),
                    code = 'video-' + id;
                window.pbjs.addAdUnits([{
                    code: code,
                    sizes: Utils.getNested(window.site_vars, 'html5video', 'prebid', 'size') || [920, 508],
                    mediaType: 'video',
                    bids: [
                        {
                            bidder: 'appnexusAst',
                            params: {
                                placementId: Utils.getUrlParam('pb_video_id') || Utils.getNested(window.site_vars, 'ADS', 'prebid', 'appNexus', 'videoPlacementId')
                            }
                        }
                    ]
                }]);
                window.pbjs.requestBids({
                    timeout: Utils.getNested(window.site_vars, 'ADS', 'prebid', 'timeout') || 800,
                    adUnitCodes: [code],
                    bidsBackHandler: _.bind(function (bid) {
                        window.videoPrebidBids = window.videoPrebidBids || {};
                        window.videoPrebidBids[code] = bid[code];
                        prebidPromise.resolve();
                    }, this)
                });
                return prebidPromise;
            },

            getAd: function() {
                return this.sv.ads;
            },

            onAdPlay: function() {
                this.buffering = false;
                //Pause the ad onAdPlay only when scrolling player is closed while ad loads
                window.setTimeout(_.bind(function () {
                    if (this.pauseOnLoad) {
                        this.pauseAd();
                        this.resetPauseFlag();
                    }
                }, this), 150);
                if (this.options.onAdPlay) this.options.onAdPlay();
                PubSub.trigger('plutovideo:play', this.cid);

                if (!this.comscoreAdCallMade ) {
                    var duration = (this.vjsPlayer.ima3.adPlayer.duration() * 1000),
                        id = this.video.id || this.vjsPlayer.mediainfo.reference_id;
                    PlutoAnalytics.stopComscoreTracking();
                    PlutoAnalytics.comscoreAdPlayback(id, duration);
                    this.comscoreAdCallMade = true;
                }
            },

            onAdEnd: function() {
                this.buffering = false;
                //make sure video progress bar is being shown
                this.getVideoProgressBar().show();
                if (this.options.onAdEnd) this.options.onAdEnd();
                PlutoAnalytics.stopComscoreTracking();
                if (!this.vjsPlayer.paused()) PlutoAnalytics.comscoreContentPlayback(this.video, this.vjsPlayer, this.placement.name);
            },

            onAdPause: function() {
                if (this.options.onPause) this.options.onPause();
            },

            pauseAd: function() {
                if (this.sv.ads) this.sv.ads.pauseAd();
            },

            /**
             * When a video is requested to be auto played
             */
            startAutoPlay: function () {
                return this.initPromise.done(_.bind(function() {
                    this.playVideo();
                }, this));
            },

            /**
             * Toggle mute
             * http://docs.brightcove.com/en/video-cloud/brightcove-player/reference/api/player.html#Methodsmuted
             */
            toggleVolume: function() {
                this.vjsPlayer.muted(!this.vjsPlayer.muted());
            },

            /**
             * Resets video to state before it was played.
             */
            resetVideoState: function () {
                this.vjsPlayer.pause().currentTime(0);
                this.$el.addClass('ui-pluto-reset');
                this.onEndedFired = false;
                this.videoViewCalled = false;
                this.fiftyPercent = false;
                this.ninetyFivePercent = false;
                this.adCallMade = false;
                PlutoAnalytics.stopComscoreTracking();
            },

            /**
             * Set flag to false when ad or video was requested to be paused
             * after scrolling player was closed during load
             */
            resetPauseFlag: function () {
                this.pauseOnLoad = false;
            },

            /**
             * Returns true if the video is playing, false otherwise
             * @returns {boolean} playing or paused
             */
            isPlaying: function() {
                return !this.vjsPlayer.paused() || this.isAdPlaying();
            },

            isAdPlaying: function() {
              return this.sv.ads && this.sv.ads.isAdPlaying();
            },

            currentAd: function(){
                return this.sv.ads.currentAd;
            },

            /**
             * Request the video to go full screen
             */
            goFullscreen: function() {
                this.vjsPlayer.requestFullscreen();
            },

            getVideoProgressBar: function() {
                if (!this.$videoControlBar || !this.$videoProgressBar) {
                    this.$videoControlBar = this.$('.vjs-control-bar:not(.vjs-ad-control-bar)');
                    this.$videoProgressBar = this.$videoControlBar.find('.vjs-progress-control');
                }
                return this.$videoProgressBar;
            },

            setVideoProgressBar: function($el) {
                $el.appendTo(this.$videoControlBar);
            },

            getAdProgressBar: function() {
                if (!this.$adControlBar || !this.$adProgressBar) {
                    this.$adControlBar = this.$('.vjs-ad-control-bar');
                    this.$adProgressBar = this.$adControlBar.find('.vjs-progress-control');
                }

                return this.$adProgressBar;
            },

            setAdProgressBar: function($el) {
                $el.appendTo(this.$adControlBar);
            },

            dimVideo: function(dim) {
                var opacity = dim ? 0.5 : 1;
                this.$("#" + this.vjsPlayer.id()).css('opacity', opacity);
            },

            getVideoRunTime: function() {
                return this.video.length;
            },

            getVideoCurrentTime: function() {
                var time;
                if (this.sv.ads && this.sv.ads.isAdInProgress()) {
                    time = this.vjsPlayer.ima3.adPlayer.currentTime();
                } else {
                    time = this.vjsPlayer.currentTime();
                }
                return time;
            },

            showShareOverlay: function() {
                if (this.sv.share) {
                    this.sv.share.showShareOverlay();
                }
            },

            isSeeking: function() {
                return this.vjsPlayer.seeking();
            },

            log: function(toLog, type) {
                if (Utils.getNested(this.placement, 'enableLogging') && Utils.logAction) {
                    Utils.logAction(this.options.actionName, {
                        'type': type,
                        'details': toLog,
                        'placement': this.placement.name,
                        'domain': Utils.getNested(window.site_vars, 'site_key'),
                        'siteCode': Utils.getNested(window.site_vars, 'site_code'),
                        'videoId': this.video.id
                    });
                }
            },

            /**
             * Destroys the view
             */
            destroy: function () {
                PlutoAnalytics.stopComscoreTracking();
                PlutoAnalytics.resetMyStreamingTag();
                if (this.hasListeners) this.removeEventListeners(this.vjsEvents);
                //remove chartbeat video listener if chartbeat enabled
                if(window._cbv) window._cbv.push(['removePlayer', this.vjsPlayer]);
                if (this.vjsPlayer) this.vjsPlayer.dispose();
                BaseView.prototype.destroy.apply(this, arguments);
                this.sv = {};
            }

        });

    /**
     * Return view class.
     */
    return PlutoVideo;
    }
);

