define('modules/longform/longform-poster-ad-bottom',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'state',
    'adPosition'
],

function (
$,
_,
BaseView,
Utils,
StateManager,
AdPosition
) {
    'use strict';
    /**
     * View class.
     */
    var LongformPosterAdBottom = BaseView.extend({

        // View element
        el: '.longform-poster-ad-bottom',

        initialize: function(options) {
            options = $.extend({
                adPlacement: 'poster',
                adSizes: ['mediumrectangle'],
                adType: 'generic',
                lazy: true
            }, options, {
                el: this.$(".poster-ad")
            });

            BaseView.prototype.initialize.call(this, options);

            if(!this.subviews.posterAd) {
                this.subviews.posterAd = new AdPosition(options);
            }
        }
        
    });

    return LongformPosterAdBottom;
});


