define('modules/global/taboola',[
    'jquery',
    'underscore',
    'utils',
    'state',
    'managers/cachemanager',
    'admanager'
],
function(
    $,
    _,
    Utils,
    StateManager,
    CacheManager,
    AdManager
) {
    'use strict';
    /**
     * @constructs modules/global/taboola
     * @author Chris Manning
     * @param {Object} options
     *     @param {String} [options.apiBase=http://api.taboola.com/1.1/json/usatodaydemo/] - base url for taboola api urls
     *     @param {String} [options.apiKey=90393b522c6937bd3b1e815ead8d189cea03650] - taboola api key
     *     @param {String} [options.widgetUrl=http://cdn.taboolasyndication.com/libtrc/usatodaydemo/loader.js] - url for the widget implementation of taboola
     *     @param {Boolean} [options.widget=false] - ability to request a widget version of taboola
     */
    var Taboola = function(options){
        _.bindAll(this, 'getWidget');
        var siteConfig = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Taboola') || {};
        this.options = $.extend({
            apiBase: siteConfig.apiBase + siteConfig.api_id,
            apiKey: siteConfig.apiKey,
            widgetUrl: '//cdn.taboola.com/libtrc/' + siteConfig.publisher_id + '/loader.js',
            isWidgetPresent: siteConfig.widget,
            taboola_enabled: siteConfig.ENABLED,
            isContinuousEndslate: false
        }, options);
        if (this.options.isWidgetPresent && !this.options.isContinuousEndslate) {
            Utils.loadScript(this.options.widgetUrl, '_taboola', this.getWidget);
        }
    };
    Taboola.prototype = {
        /**
         * Create 'taboola_user_session_id' in session storage using CacheManager
         * @param {String} sID - user.session sID
         */
        setUserTaboolaSession: function(sID) {
            CacheManager.setValue('taboola_user_session_id', sID);
        },

        /**
         * Return 'init' if nothing set or already set value
         */
        getUserTaboolaSession: function() {
            return CacheManager.getValue('taboola_user_session_id', 'init');
        },

        getSourceId: function(url) {
            var path = url ? $('<a>').attr('href', url)[0].pathname : window.location.pathname;
            return path.match(/^\/videos\//) ? StateManager.getActivePageInfo().assetid : path;
        },

        /**
         * Fires off an analytic request to taboola letting them known an item was clicked
         * @param {String} itemId
         * @param {String} itemType
         * @param {String} responseId
         */
        registerTaboolaClick: function(itemId, itemType, responseId) {
            new Image().src = this.options.apiBase + '/recommendations.notify-click' +
                '?app.type=desktop&app.apikey=' + this.options.apiKey + '&response.id=' + responseId +
                '&item.type=' + itemType + '&item.id=' + itemId + '&response.session=' + this.getUserTaboolaSession();
        },

        /**
         * Fires off an analytic request to let taboola know that the recommendations are now visible
         * @param {String|Number} responseId
         * @private
         */
        registerTaboolaVisible: function(responseId) {
            new Image().src = this.options.apiBase + '/recommendations.notify-visible' + '?app.type=desktop' +
                '&app.apikey=' + this.options.apiKey + '&response.id=' + responseId + '&response.session=' +
                this.getUserTaboolaSession();
        },

        /**
         * This callback is called when taboola data comes in
         * @callback modules/global/taboola~getRelatedCallback
         * @param {Object} data - taboola data
         */
        /**
         * Request to get taboola raw data
         * @param {Function} callback - called when the request to taboola succeeds
         * @param {String} [sourceType=video]
         * @param {String} [recType=video]
         * @param {Number} [count=6]
         * @param {String} [sourceUrl=window.location.href]
         * @param {Number} [sourceID=0]
         * @param {String} [placement]
         * @param {boolean} [recVisible=false if the recommendation is not immediately visible]
         * @param {Number} [imgWidth]
         * @param {Number} [imgHeight]
         */
        getRelated: function(callback, sourceType, recType, count, sourceUrl, sourceID, placement, recVisible, imgWidth, imgHeight) {
            var deferred = $.Deferred(),
                pageInfo,
                sourceTypeMap,
                sourceTypeRegex,
                contentType,
                svcUrl,
                params;
            if (this.options.taboola_enabled && !AdManager.isAdFreeExperience()) {
                pageInfo = StateManager.getActivePageInfo();
                // Map strings from page's 'contenttype' to taboola's 'source.type' strings
                sourceTypeMap = {
                    "video": "video",
                    "playlist": "video",
                    "brightcove": "video",
                    "text": "text",
                    "story": "text",
                    "blogs": "text",
                    "gallery": "photo",
                    "album": "photo",
                    "home": "home",
                    "section": "section",
                    "front": "section",
                    "topic": "section",
                    "blog index": "section",
                    "media view": "section"
                };
                sourceTypeRegex = _.keys(sourceTypeMap).join('|');
                contentType = Utils.getNested(pageInfo, 'contenttype') || '';
                svcUrl = this.options.apiBase + '/recommendations.get';
                params = JSON.parse(JSON.stringify({ // Remove undefined params
                    "output.callback": "define",
                    "app.type": "desktop",
                    "app.apikey": this.options.apiKey,
                    "source.id": sourceID || this.getSourceId() || 0,
                    "source.url": sourceUrl || Utils.getNested(pageInfo, 'canonical') || window.location.href,
                    "source.type": sourceType || sourceTypeMap[contentType.match(sourceTypeRegex)] || 'video',
                    "rec.type": recType || 'video',
                    "rec.visible": recVisible === false ? recVisible : true,
                    "rec.count": count || 3,
                    "source.placement": placement || '',
                    "user.session": this.getUserTaboolaSession(),
                    "rec.thumbnail.width": imgWidth,
                    "rec.thumbnail.height": imgHeight
                }));
                Utils.requireSingleUseScript(svcUrl + '?' + $.param(params))
                    .done(_.bind(function(data) {
                        Utils.set('taboolaResponseID', data.id);
                        if (!data.list) {
                            data.list = {};
                        }
                        this.setUserTaboolaSession(data.session);
                        if (params['rec.visible']) {
                            this.registerTaboolaVisible(data.id);
                        }
                        if (callback) {
                            callback(data);
                        } else {
                            deferred.resolve(data);
                        }
                    }, this)).fail(function(data){
                        data = {};
                        deferred.reject(data);
                    });
            } else {
                deferred.reject("Taboola not enabled for this site.");
            }
            return deferred.promise();
        },

        getWidget: function(_taboola) {
            if (this.options.taboola_enabled && !AdManager.isAdFreeExperience()) {
                var url = window.location.href;
                if(!window.taboolainitialized) {
                    _taboola.push({
                        notify: 'newPageLoad'
                    });
                    window.taboolainitialized =true;
                }
                _.each(this.options.widget, function(widget) {
                    _taboola.push({
                        mode: widget.mode,
                        container: widget.container,
                        placement: widget.placement,
                        target_type: widget.target_type,
                        url: widget.url
                    });
                });
                _taboola.push($.extend(
                    {
                        url: url
                    },
                    this.options.pagetypeWidget
                ));
            }
        }
    };
    return Taboola;
});

