/**
* @fileoverview JS function for Event Tool
* @author kklee@usatoday.com
*/
define('modules/sports/sports-event-tool',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'state',
    'utils',
    'baseview',
    'form/datepicker'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    StateManager,
    Utils,
    BaseView,
    DatePicker
) {
    var EventTools = BaseView.extend({

        events: {
            'click .event-tool-icon, .fixture-tool-icon': 'onEventToolLaunch',
            'click .event-tool-reset, .event-tool-insert': 'onEventToolAction'
        },

        initialize: function() {
            this.checkFFDQ = null;
            this.$datepicker_filter = $('.sp-filter-date-picker-wrap');

            BaseView.prototype.initialize.apply(this, arguments);
            _.bindAll(this, 'checkFileQueue', 'onEventToolAction');

            this.subviews.datepicker = new DatePicker({
                el: this.$('.sp-filter-date-picker-input'),
                buttonSelector: '.sp-filter-date-picker-btn',
                flyoutClass: 'sp-filter-date-picker-flyout',
                calendarPanelClass: 'ui-dark',
                dateFormat: 'yy-mm-dd',
                changeMonth: true,
                changeYear: true,
                yearRange: "-4:+1"
            });
        },

        checkFileQueue: function() {
            var url = this.check;
            StateManager.fetchHtml(url).done(_.bind(function(html) {
                if (html.find('.event-tool-result').length) {
                    this.btn.html(html.find('.event-tool-result'));
                    clearInterval(this.checkFFDQ);
                    window.opener.location.reload();
                } else {
                    console.log('Failed to load: ' + this.url);
                }
            }, this));
        },

        onEventToolAction: function(e) {
            this.btn = $(e.currentTarget);
            var url = this.btn.data('url');
            this.check = this.btn.data('check');
            StateManager.fetchHtml(url).done(_.bind(function(html) {
                if (html.find('.event-tool-result').length) {
                    this.btn.html(html.find('.event-tool-result'));
                    if (this.check) {
                        clearInterval(this.checkFFDQ);
                        this.checkFFDQ = setInterval(this.checkFileQueue, 10000);
                    } else {
                        window.opener.location.reload();
                    }
                } else {
                    console.log('Failed to load: ' + this.url);
                }
            }, this));
        },

        onEventToolLaunch: function(e) {
            console.log('launch tool');
            var btn = $(e.currentTarget);
            var url = btn.data('url');
            window.open(url, 'sports-event-tool', 'toolbar=0,location=0,status=0,menubar=0' +
                ',resizeable=1,scrollbars=1,left=10,top=10,width=600,height=900');
        },

        destroy: function() {
            clearInterval(this.checkFFDQ);
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });

    return EventTools;
});

