define('modules/fronts/most-popular',[
    'jquery',
    'underscore',
    'baseview',
    'modules/fronts/most-popular-primary',
    'modules/fronts/most-popular-sidebar'
],
function(
    $,
    _,
    BaseView,
    MostPopularPrimary,
    MostPopularSidebar
)
{
    'use strict';
    var MostPopular = BaseView.extend({

        /**
         * Initialize view.
         * @param {Object} options View options passed in during init.
         */
        initialize: function(options){

            this.subviews = {};

            if (options.layoutType === 'sidebar'){
                this.subviews.sidebar = new MostPopularSidebar(options);
            } else {
                this.subviews.primary = new MostPopularPrimary(options);
            }

            // call base class initialize
            BaseView.prototype.initialize.call(this, options);
        },

        renderCardInfo: function(currentCardInfo) {
            this.triggerEvent('renderCardInfo', currentCardInfo);
        }

    });

    return MostPopular;

});
