/**
 * @classdesc Our wrapper around gpt's ad slot, includes monitoring the slot and event capture
 */
define('partner/ad-slot',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'utils',
    'modules/global/barrier',
    'pubsub',
    'adLogger',
    'intersection-observer'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    Utils,
    Barrier,
    PubSub,
    AdLogger,
    IntersectionObserve
) {
    'use strict';
    /**
     * View class.
     */
    var AdSlot = BaseView.extend({
        className: 'ad-slot',
        dataId: 'test',

        displayed: false,
        visible: 'hidden',

        events: {
            'partnersetup': 'onHighImpactAdLoad'
        },
        initialized: false,

        pageUnload: function() {
            AdSlot.prototype.queue = [];
        },

        pageLoad: function() {
            // Defers invoking the function until the current call stack has cleared
            // Helps to properly batch subsequent page load ad requests into a single request
            _.defer(function() {
                // Request all ads on page at time of load
                if (_.isArray(AdSlot.prototype.queue)) {
                    AdSlot.prototype.queue.forEach(function(adSlot) {
                        adSlot._requestSlot();
                    });
                }

                // Request any slot after page load immediately
                AdSlot.prototype.queue = {
                    push: function(adSlot) {
                        adSlot._requestSlot();
                    }
                };
            });
        },

        initialize: function(options) {
            options = $.extend({
                exclusion: [], // Exclusion targeting
                targeting: {},
                sizes: [], // ad sizes for this slot
                type: 'in',
                lazy: Utils.getNested(window.site_vars, 'ADS', 'lazy', options.adPlacement, 'enabled'),
                autoRefresh: Utils.getNested(window.site_vars, 'ADS', 'auto_refresh', options.adPlacement, 'enabled'),
                onSlotRender: null,
                onSlotEmpty: null,
                onSlotMessage: null,
                position: null, // required!
                adPosition: null,
                defers: {},
                overrides: {},
            }, options, {
                adUnit: this.buildAdUnit(options.adUnit, options.overrides)
            });

            _.bindAll(this, '_onSafeFrameMsg', '_windowVisibilityChange', '_adSlotScrollListener', '_handleResizeWindow', '_resolveDefer', '_markPrebid');

            // One time setup for gpt event listeners
            if (!AdSlot.prototype.initialized){
                AdSlot.prototype.initialized = true;
                AdSlot.prototype.requests = 0;
                AdSlot.prototype.queue = AdSlot.prototype.queue || [];

                window.googletag.cmd.push(function() {
                    var pubads = window.googletag.pubads();
                    pubads.addEventListener('slotRenderEnded', function(event) {
                        var adSlot = event.slot.__gannettSlot;
                        if (!adSlot) {
                            // I don't know who you are, but you don't belong on our page
                            return;
                        }
                        adSlot._renderEnded(event, event.size);
                    });
                    pubads.addEventListener('impressionViewable', AdSlot.prototype._activeViewCheck);
                });
            }

            if (!this.$el.length || !options.position || !options.position.length || !options.position.parents('body').length) {
                AdLogger.logInfo('Ad Slot:: Unable to request ad because no valid dom element provided ' + options.adUnit, options);
                return;
            }

            options.position.append(this.$el);
            this.slotId = options.position.data('slotId') || _.uniqueId('ad-slot-' + options.adUnit.replace(/\//g, '-') + '-');

            this.viewabilityThreshold = Utils.getNested(window.site_var, 'ADS', 'viewability', 'paramount_threshold') || 0.1;

            this.pubSub = {
                'advertising:prebidback': this._markPrebid
            };
            BaseView.prototype.initialize.call(this, options);

            // Will build the slot
            this.refresh();
        },

        /**
         * @access private
         * Runs googletags command to define an ad slot. Then sets the targeting on this slot and triggers the start of party targeting fetching
         */
        _buildGptSlot: function() {
            var options = this.options,
                adUnit = options.adUnit,
                sizes = options.sizes;

            if (StateManager.getActivePageInfo().noadvertising) {
                AdLogger.logInfo('Ad Slot:: blocked because of noadvertising flag in pageinfo ' + this.slotId);
                return;
            }

            window.googletag.cmd.push(_.bind(function() {
                AdLogger.logInfo('Ad Slot:: Registering slot with google ' + this.slotId, adUnit, sizes, this.slotId);

                if(!options.lazy) {
                    // Non-lazy loaded ads are allowed to render immediately
                    this.el.id = this.slotId;
                }
                if(options.type === 'in') {
                    this.gptSlot = window.googletag.defineSlot(adUnit, sizes, this.slotId).addService(window.googletag.pubads());
                } else {
                    this.gptSlot = window.googletag.defineOutOfPageSlot(adUnit, this.slotId).addService(window.googletag.pubads());
                }
                this.gptSlot.__gannettSlot = this;

                // Set slot level targeting
                this.setTargeting();
                this._refreshSlot();
                if(options.lazy) {
                    this._addListeners();
                }
            }, this));
        },

        _setThirdPartyTargeting: function() {
            var options = this.options,
                adUnit = options.adUnit,
                sizes = options.sizes;

            if(options.type === 'in') {
                AdLogger.logInfo('Ad Slot:: Setting up third party targeting ' + this.slotId);
                this._setupIAS(adUnit, sizes);
                this._setupAPS(sizes);
                this._setupINX();
                this._setupPrebid(adUnit, sizes, this.slotId);
                AdLogger.logInfo('Ad Slot:: Done Setting up third party targeting ' + this.slotId);
            }
            options.defers.fetch.resolve();
        },

        _setupAPS: function (sizes) {
            var apsUnit = this.options.position.data('apsUnit');
            if(window.adManager.partnerEnabled('aps')) {
                if(!apsUnit) {
                    // First time we are configuring this slot
                    this.configureAPSSlot(sizes, this.slotId, this.options.position);
                } else if (this.displayed) {
                    // We've already displayed this unit and are refreshing
                    window.adManager.apsSlots.push(apsUnit);
                }
            } else {
                this._resolveDefer('aps');
            }
        },

        configureAPSSlot: function(sizes, slotId, $positionEl) {
            var apsUnit,
                acceptedSizes = Utils.getNested(window.site_vars, 'ADS', 'aps', 'sizes'),
                apsSizes = this._filterSizes(sizes, acceptedSizes);

            // Skip APS if no acceptable sizes are present
            if (!apsSizes.length) {
                return this._resolveDefer('aps');
            }

            apsUnit = {
                slotID: slotId,
                sizes: apsSizes
            };

            // special logging just for a partner configuration
            if(this.pbSlotLog) console.info("AMAZON APS SLOT CONFIG" , JSON.stringify(apsUnit, null, 4));
            window.adManager.apsSlots.push(apsUnit);
            $positionEl.data('apsUnit', apsUnit);
        },

        _filterSizes: function (sizeList, acceptedSizes) {
            var i, j, sizeString, acceptedString,
                outSizes = [];
            for (i = 0; i < sizeList.length; i++) {
                sizeString = JSON.stringify(sizeList[i]);
                for (j = 0; j < acceptedSizes.length; j++) {
                    acceptedString = JSON.stringify([acceptedSizes[j].width, acceptedSizes[j].height]);
                    if (sizeString === acceptedString) {
                        outSizes.push(sizeList[i]);
                    }
                }
            }
            return outSizes;
        },

        _setupIAS: function(adUnit, sizes) {
            var iasUnit = this.options.position.data('iasUnit');
            if(window.adManager.partnerEnabled('ias') && !iasUnit) {
                // First time we are configuring this slot
                this.configureIASSlot(adUnit, sizes, this.slotId, this.options.position);
            } else if (this.displayed) {
                // We've already displayed this unit and are refreshing
                window.adManager.iasSlots.push(iasUnit);
            } else {
                this._resolveDefer('ias');
            }
        },

        configureIASSlot: function(adUnit, sizes, slotId, $positionEl) {
            var iasUnit = {
                adSlotId: slotId,
                size: sizes[0],
                adUnitPath: adUnit
            };

            window.adManager.iasSlots.push(iasUnit);
            $positionEl.data('iasUnit', iasUnit);
        },

        _setupINX: function() {
            var position = this.options.position,
                inxUnit = position.data('inxUnit');

            if(window.adManager.partnerEnabled('inx_universal')) {
                if(!inxUnit) {
                    // First time we are configuring this slot
                    this.configureINXSlot(this.options.adPlacement, this.slotId, this.options.position);
                } else if (this.displayed) {
                    // We've already displayed this unit and are refreshing
                    window.adManager.inxSlots.push(inxUnit);
                } else {
                    this._resolveDefer('inx');
                }
            } else {
                this._resolveDefer('inx');
            }
        },

        configureINXSlot: function(placement, slotId, $positionEl) {
            var inxUnit = 'NA', config = Utils.getNested(window.site_vars, 'ADS', 'inx_universal') || {},
                slot = _.find(config.placements, function(inxSlot) {
                    return inxSlot.placement.indexOf(placement) !== -1;
                });

            if(slot && slot.slotName) {
                inxUnit = {
                    name: slot.slotName,
                    slotId: slotId
                };
                window.adManager.inxSlots.push(inxUnit);
            }
            $positionEl.data('inxUnit', inxUnit);
        },

        _setupPrebid: function(adUnit, sizes, slotId) {
            var position = this.options.position,
                pbjsBids = position.data('pbjs');
            if(window.adManager.partnerEnabled('prebid')) {
                if(pbjsBids === undefined) {
                    this.configurePrebidSlot(adUnit, sizes, slotId, this.options.position);
                } else if(pbjsBids > 0 && this.displayed) {
                    window.adManager.prebidSlots.push(slotId);
                }
            } else {
                return this._resolveDefer('prebid');
            }
        },

        configurePrebidSlot: function(adUnit, sizes, slotId, $positionEl) {
            var pbUnit, acceptedSizes,
                adConfig = Utils.getNested(window.site_vars, 'ADS', 'prebid') || {},
                location = (adUnit.indexOf('_btf') !== -1) ? 'btf' : 'atf',
                pageInfo = StateManager.getActivePageInfo() || {},
                section = pageInfo.section_name,
                sstsParts = (pageInfo.ssts || '').split('/');

            this.pbBids = [];
            this.pbDebug = Utils.getUrlParam('pb_debug');

            acceptedSizes = _.map(adConfig.sizes, function(sizeObj) {
                return [sizeObj.width, sizeObj.height];
            });

            sizes = _.filter(sizes, function(size) {
                return _.findWhere(acceptedSizes, size);
            });

            if(_.isEmpty(sizes)) {
                return this._resolveDefer('prebid');
            }

            this._setupAardvark(adConfig.aardvark || {}, sizes, section);
            this._setupAol(adConfig.aol || {}, sizes, adUnit);
            this._setupAppNexus(adConfig.appNexus || {}, sizes, section, adUnit);
            this._setupColossusssp(adConfig.colossusssp || {}, adUnit);
            this._setupConsumable(adConfig.consumable || {}, sizes);
            this._setupCriteo(adConfig.criteo || {}, sizes);
            this._setupIndex(adConfig.index || {}, adUnit, sizes);
            this._setupOpenX(adConfig.openX || {}, sizes, section);
            this._setupPubmatic(adConfig.pubMatic || {}, location, sizes);
            this._setupRubicon(adConfig.rubicon || {}, location, section, sstsParts, pageInfo.topic);
            this._setupSonobi(adConfig.sonobi || {}, adUnit);
            this._setupTripleLift(adConfig.triplelift || {}, sizes, adUnit);
            this._setupTrustx(adConfig.trustx || {}, sizes, adUnit);
            this._setupYieldbox(adConfig.yieldbot || {}, sizes, adUnit);

            // No bidders for this slot
            if(!this.pbBids.length) {
                return this._resolveDefer('prebid');
            }

            pbUnit = {
                code: slotId,
                mediaTypes: {
                    banner: {
                        sizes: sizes
                    }
                },
                bids: this.pbBids
            };

            window.pbjs = window.pbjs || {};
            window.pbjs.que = window.pbjs.que || [];
            window.pbjs.que.push(function() {
                window.pbjs.aliasBidder('ix', 'indexExchange');
                window.pbjs.addAdUnits(pbUnit);
            });

            // Denote that prebid has already been configured for this slot
            $positionEl.data('pbjs', this.pbBids.length);
            window.adManager.prebidSlots.push(slotId);

            // special logging just for a partner configuration
            if(this.pbSlotLog) console.info('PREBID SLOT CONFIG', JSON.stringify(pbUnit, null, 4));
            AdLogger.logInfo('Ad Slot:: Prebid configured  ' + slotId, pbUnit);
        },

        _setupAardvark: function(config, sizes, section) {
            var placement, matches;

            if (this._isEnabled('aardvark', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placement = _.where(config.placements, {size: sizeStr})[0];
                    if (placement && placement.ai && placement.sc){
                        this.pbBids.push({
                            bidder: 'aardvark',
                            params: {
                                ai: placement.ai,
                                sc: placement.sc,
                                categories: [section]
                            }
                        });
                    }
                }, this);
            }
        },

        _setupAol: function(config, sizes, adUnit) {
            var placement, matches;

            if (this._isEnabled('aol', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placement = _.where(config.placements, {size: sizeStr})[0];
                    if(placement) {
                        this.pbBids.push({
                            bidder: 'aol',
                            params: {
                                placement: placement.id,
                                network: config.network,
                                alias: adUnit,
                                server: config.server,
                                sizeId: placement.size,
                                bidFloor: config.bidfloor,
                            }
                        });
                    }
                }, this);
            }
        },

        _setupAppNexus: function(config, sizes, section, adUnit) {
            var placements, placementId, matches;

            if (this._isEnabled('appnexus', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placements = _.where(config.placements, {size: sizeStr})[0];
                    if(placements) {
                        // Get the section/ROS placement id based on this size
                        placementId = placements[section] || placements.ros;
                        if(placementId) {
                            this.pbBids.push({
                                bidder: 'appnexusAst',
                                params: {
                                    placementId: placementId,
                                    keywords: {
                                        'position': adUnit.split('/')[2]
                                    }
                                }
                            });
                        }
                    }
                }, this);
            }
        },

        _setupColossusssp: function(config, adUnit){
            var placement,
                key = adUnit.split('/')[2];

            if (this._isEnabled('colossusssp', config) && key) {
                // Use key to get placement
                placement =  Utils.getNested(config, 'placements', key);
                if(placement && placement.placement_id) {
                    this.pbBids.push({
                        bidder: 'colossusssp',
                        params: {
                            placement_id: placement.placement_id
                        }
                    });
                }
            }
        },

        _setupConsumable: function(config, sizes){
            var placement, matches;

            if (this._isEnabled('consumable', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placement = _.where(config.placements, {size: sizeStr})[0];
                    if(placement) {
                        this.pbBids.push({
                            bidder: 'consumable',
                            params: {
                                networkId: config.networkId,
                                siteId: config.siteId,
                                unitId: placement.unitId,
                                unitName: placement.unitName,
                                zoneIds: placement.zoneIds
                            }
                        });
                    }
                }, this);
            }
        },

        _setupCriteo: function(config, sizes) {
            var placement, matches;

            if (this._isEnabled('criteo', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placement = _.where(config.placements, {size: sizeStr})[0];
                    if(placement) {
                        this.pbBids.push({
                            bidder: 'criteo',
                            params: {
                                zoneId: placement.id
                            }
                        });
                    }
                }, this);
            }
        },

        _setupIndex: function(config, adUnit, sizes) {
            var placement,
                key = adUnit.split('/')[2];

            if (!window.adManager.partnerEnabled('inx_universal') && this._isEnabled('index', config) && key && !this.inxDebug) {
                placement = config.placements[key];
                if (placement) {
                    _.each(sizes, function (size) {
                        this.pbBids.push({
                            bidder: 'indexExchange',
                            params: {
                                siteId: placement.siteid,
                                size: [size[0], size[1]]
                            }
                        });
                    }, this);
                }
            }
        },

        _setupOpenX: function(config, sizes, section) {
            var placements, placementId, matches;

            if (this._isEnabled('openx', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placements = _.where(config.placements, {size: sizeStr})[0];
                    if(placements) {
                        // Get the section/ROS placement id based on this size
                        placementId = placements[section] || placements.ros;
                        if(placementId) {
                            this.pbBids.push({
                                bidder: 'openx',
                                params: {
                                    unit: placementId,
                                    delDomain: Utils.getNested(config, 'domain')
                                }
                            });
                        }
                    }
                }, this);
            }
        },

        _setupPubmatic: function(config, location, sizes) {
            var adSize = this._getMaxSize(sizes);

            if(!adSize) { return; }

            var adSizeString =  adSize[0] + 'x' + adSize[1],
                slot = location.toUpperCase() + '@' + adSizeString,
                rosSlot = 'ROS@' + adSizeString,
                slots = config.slots || [],
                adSlot = (slots.indexOf(slot) > -1) ? slot : (slots.indexOf(rosSlot) > -1) ? rosSlot : null;

            if (this._isEnabled('pubmatic', config) && adSlot) {
                this.pbBids.push({
                    bidder: 'pubmatic',
                    params: {
                        publisherId: config.account,
                        adSlot: adSlot
                    }
                });
            }
        },

        _setupRubicon: function(config, location, section, sstsParts, topics) {
            var subsection, topicArr,
                zoneId = Utils.getNested(config, 'zones', location, section) || config.zoneId;
            if (this._isEnabled('rubicon', config) && zoneId) {
                subsection = sstsParts[1] || '';
                topicArr = (topics) ? topics.split(',') : [];
                if(sstsParts[2]) topicArr.push(sstsParts[2]);
                this.pbBids.push({
                    bidder: 'rubicon',
                    params: {
                        accountId: config.account,
                        siteId: config.siteId,
                        zoneId: zoneId,
                        inventory: {
                            domain: [window.site_vars.base_url],
                            section: [section],
                            subsection: [subsection],
                            topic: topicArr
                        }
                    }
                });
            }
        },

        _setupSonobi: function(config, adUnit) {
            if (this._isEnabled('sonobi', config)) {
                this.pbBids.push({
                    bidder: 'sonobi',
                    params: {
                        ad_unit: adUnit
                    }
                });
            }
        },

        _setupTripleLift: function(config, sizes, adUnit) {
            var placement, matches,
                key = adUnit.split('/')[2];

            if (this._isEnabled('triplelift', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                // checks for "fluid" enabled placement, based on key
                if(_.findWhere(config.placements, { adunit: key})) {
                    matches.push('fluid');
                }
                _.each(matches, function(sizeStr) {
                    placement = _.where(config.placements, {size: sizeStr})[0];

                    if(placement) {
                        this.pbBids.push({
                            bidder: 'triplelift',
                            params: {
                                inventoryCode: placement.id
                            }
                        });
                    }
                }, this);
            }
        },

        _setupTrustx: function(config, sizes, adUnit) {
            var placement, matches,
                key = adUnit.split('/')[2],
                isPlacementEnabled = (config.enabledPlacements || []).indexOf(key) !== -1;

            if (this._isEnabled('trustx', config) && isPlacementEnabled) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placement = _.where(config.placements, {size: sizeStr})[0];
                    if (placement && placement.uid) {
                        this.pbBids.push({
                            bidder: 'trustx',
                            params: {
                                uid: placement.uid
                            }
                        });
                    }
                }, this);
            }
        },

        _setupYieldbox: function(config, sizes, adUnit) {
            var placement, matches;

            if (this._isEnabled('yieldbot', config)) {
                matches = this._getPlacementSizes(config.placements, sizes, config.multisize);

                _.each(matches, function(sizeStr) {
                    placement = _.where(config.placements, {size: sizeStr})[0];
                    if(placement) {
                        this.pbBids.push({
                            bidder: 'yieldbot',
                            params: {
                                psn: placement.id,
                                slot: placement.slot
                            }
                        });
                    }
                }, this);
            }
        },

        _isEnabled: function(bidder, bidderConfig) {
            return this.pbDebug === bidder || (!this.pbDebug && bidderConfig.enabled);
        },

        _getPlacementSizes: function(placements, sizes, multiSize) {
            var largestAdSize, allAdSizes = [], matches = [];

            _.each(placements, function(placement) {
                var match, size;
                if(placement.adunit) {
                    return;
                }
                size = _.map(placement.size.split('x'), function(str) { return parseInt(str, 10); });

                match = _.findWhere(sizes, size);
                if(match) matches.push(match);
            });

            // If no matches, bail
            if(_.isEmpty(matches)) return [];

            // Find the largest available size
            _.reduce(matches, function(memo, size) {
                var total = size[0] + size [1];
                allAdSizes.push(size[0] + 'x' + size[1]);
                if (total > memo) {
                    largestAdSize = size[0] + 'x' + size[1];
                    return total;
                }
                return memo;
            }, 0);
            return (multiSize) ? allAdSizes : [largestAdSize];
        },

        _getMaxSize: function(sizes) {
            var adSize;

            if(sizes.length > 1) {
                // Now we want to find the largest size, when there is multiple sizes, to bid on
                _.reduce(sizes, function(memo, size) {
                    if (_.isArray(size)) {
                        var total = size[0] + size [1];
                        if (total > memo) {
                            adSize = size;
                            return total;
                        }
                    }
                    return memo;
                }, 0);
            } else {
                adSize = sizes[0];
            }
            return adSize;
        },

        /**
         * @access public
         * Given an ad unit and optional custom targeting map, will build the full ad unit string
         * with either the current accountId and Name, =or the overridden ones.
         * If an ad label override is supplied, it'll be added to the targeting map
         * @param {String} adUnit name of the ad unit
         * @param {Object} overrides adunit override values provided by query params
         * @return {String}
         */
        buildAdUnit: function(adUnit, overrides){
            // Build the adUnit Path
            var getNested = Utils.getNested,
                adSettings = getNested(window.site_vars, 'ADS') || {},
                accountId = overrides.account || getNested(adSettings, 'DFP', 'ACCOUNTID'),
                accountName = overrides.property || getNested(adSettings, 'DFP', 'ACCOUNTNAME');

            // Concat and sanitize returned string
            return (accountId + '/' + accountName + '/' + adUnit).replace(/[^\w-*!<>:().\\\/]/g, '');
        },

        setTargeting: function(targeting) {
            var slotTargeting = targeting || this.options.targeting,
                gptSlot = this.gptSlot;

            if (gptSlot) {
                gptSlot.clearTargeting();
                this._setTargeting(gptSlot, slotTargeting);
                this._setExclusionTargeting(gptSlot, this.options.exclusion);
            }
        },

        _setTargeting: function(gptSlot, targeting) {
            var adUnit = Utils.getNested(this.options, 'adUnit') || '';

            // Set the rest of the targeting
            gptSlot.setTargeting('position', adUnit.split('/')[2] || 'NA');
            _.each(targeting, function(value, key) {
                if (value || value === 0 || value === false) {
                    value = Utils.scrubEmail(value);
                    gptSlot.setTargeting(key, value);
                }
            }, this);
        },

        _setExclusionTargeting: function(gptSlot, exclusion) {
            exclusion.forEach(function(value) {
                if (!_.isEmpty(value)) {
                    gptSlot.setCategoryExclusion(value);
                }
            }, this);
        },

        _unsetThirdPartyTargeting: function() {
            var keys = ['ix_indx_id', 'ix_indx_om'];

            if(this.displayed) {
                _.each(keys, function(key) {
                    this.gptSlot.clearTargeting(key);
                }, this);
            }
        },

        destroy: function() {
            if (this.gptSlot) {
                this.gptSlot.__gannettSlot = null;
            }
            if(window.pbjs) {
                // remove this unit from prebid
                window.pbjs.removeAdUnit(this.slotId);
            }

            // Unregister our State controlled global listeners
            StateManager.unRegisterVisibilityListener(this.slotId);
            StateManager.unRegisterScrollListener(this.slotId);
            StateManager.unRegisterResizeListener(this.slotId);

            this._clearIntersectionObservers();
            clearTimeout(this.refreshTimeout);

            window.removeEventListener("message", this._onSafeFrameMsg, false);
            this.destroyed = true;
            if(window.googletag) {
                window.googletag.cmd.push(_.bind(function() {
                    window.googletag.destroySlots([this.gptSlot]);                    
                }, this));
            }
            this.gptSlot = null;
            BaseView.prototype.destroy.call(this, true);
        },

        refresh: function() {
            if (this.destroyed || StateManager.getActivePageInfo().noadvertising) {
                return;
            }

            // Reset slot vars
            this.adData = null;
            this.needsRefresh = false;

            if (!this.gptSlot) {
                this._buildGptSlot();
            } else {
                this._refreshSlot();
            }
        },

        _refreshSlot: function() {
            if(this.options.type === 'in') {
                // We only need this for "in" slots, not for out of page slots
                PubSub.attach({'advertising:resolvedefer': this._resolveDefer}, this);
            }

            this._setupRequestBarrier();
            this._unsetThirdPartyTargeting();
            this._setThirdPartyTargeting();

            this.queue.push(this);
        },

        _setupRequestBarrier: function() {
            var options = this.options;

            this.requestBarrier = new Barrier();
            if(options.type === 'in') {
                // Loop thorugh our 3rd party defers
                _.each(['aps', 'ias', 'inx', 'prebid'], function(type) {
                    var defer = $.Deferred();
                    options.defers[type] = defer;
                    // timeout is not set here, resolving based on a timeout is taken care of by admanager
                    this.requestBarrier.add(defer, false, true);
                }, this);
                // Add in the batch barrier defer for the first round bidding
                this.requestBarrier.add(options.defers.batch, false, true);
                AdLogger.logInfo('Ad Slot:: Setting up request barrier ' + this.slotId);
            }

            if(options.lazy && !this.displayed) {
                options.defers.lazy = $.Deferred();
                this.requestBarrier.add(options.defers.lazy, false, false);
            }
            if(options.deferred) {
                this.requestBarrier.add(options.deferred, false, false);
            }
            options.defers.rendered = $.Deferred();
        },

        _resolveDefer: function(defer) {
            var defers = Utils.getNested(this.options, 'defers');
            if(defers && defers[defer]) {
                AdLogger.logInfo('Resolving Defer ' + defer + ' ' + this.slotId);
                defers[defer].resolve();
            }
        },

        _requestSlot: function(firstRequest){
            this.requestBarrier.wait().done(_.bind(function() {
                var pubads = window.googletag.pubads();
                if(this.displayed) {
                    // Refresh the slot
                    window.googletag.cmd.push(_.bind(function() {
                        AdLogger.logInfo('Ad Slot:: Refreshing slot ' + this.slotId, this.gptSlot);
                        window.googletag.pubads().refresh([this.gptSlot], {changeCorrelator: false});
                    }, this));
                } else {
                    // Set ID and call display on the slot
                    window.googletag.cmd.push(_.bind(function() {
                        AdLogger.logInfo('Ad Slot:: Displaying slot ' + this.slotId, this.gptSlot);
                        if(this.options.lazy) {
                            // only add the id to the slot right befroe display when lazy
                            this.el.id = this.slotId;
                        }
                        // Mark slot as displayed
                        this.displayed = true;
                        window.googletag.display(this.slotId);
                    }, this));

                    if(this.options.autoRefresh && !Utils.getUrlParam('navigationtype')) {
                        this._setupAutoRefresh();
                    }

                   
                }
                // Fire off NR Request metric
                AdSlot.prototype.requests++;
                this._mark('Request', this);
            }, this));
        },

        _renderEnded: function(event, size) {
            var options = this.options;
            if(event.isEmpty) {
                AdLogger.logInfo('Ad Slot:: No Ad Delivered ' + this.slotId, this.gptSlot);
                options.onSlotEmpty();
                // we don't have a size, so we will alert all positions in the slot we are empty
                _.each(options.adPositionMap, function(position) {
                    if(_.isFunction(position.onEmpty)) {
                        position.onEmpty();
                    }
                });
            } else {
                size = size || [];
                this.adPosition = options.adPositionMap[size[0] + 'x' + size[1]];

                if (this.gptSlot && !Utils.getNested(this, 'adPosition', 'options', 'legacyHighImpact')) {
                    options.onSlotRender(null, null, size);
                    this.adPosition.onRender(null, null, size);
                    if(_.isFunction(this.adPosition.onMessage) && this.displayed) {
                        this._setupSafeFrameMsg();
                    }
                }
            }

            if(this.displayed) {
                this._resolveDefer('rendered');
                this._mark('Render', this);
            }

            if(options.type === 'in') {
                PubSub.unattach({'advertising:resolvedefer': this._resolveDefer}, this);
            }
        },

        onHighImpactAdLoad: function(event, params) {
            var adData, adType,
                options = this.options;

            if (params && params.data) {
                adData = params.data;
            } else if (event.originalEvent && event.originalEvent.data) {
                adData = event.originalEvent.data;
            } else {
                AdLogger.logError('Ad Slot:: Found a high impact ad, can\'t find the data object from the iFrame ' + this.slotId, arguments);
                return;
            }

            if (!adData || !adData.adType) {
                AdLogger.logError('Ad Slot:: High impact event received, but invalid data object was given ' + this.slotId, adData);
                return;
            }

            this.adData = adData;

            this.options.defers.rendered.then(_.bind(function() {
                var adType = this._normalizeAdType(adData.adType);
                options.onSlotRender(adData, adType);
                this.adPosition.onRender(adData, adType);
            }, this));
        },

        _setupViewabilityObserver: function () {
            var options = {
                    root: null,
                    threshold: [this.viewabilityThreshold]
                };
            if (!this.slotObserver) {
                this.slotObserver = new IntersectionObserve(_.bind(function(entries) { this._viewabilityCheck(entries); }, this), options);
            }
            this.slotObserver.observe(this.el);
        },

        _viewabilityCheck: function (entries){
            _.each(entries, _.bind(function(entry) {
                var ratio = entry.intersectionRatio,
                    visible = (ratio >= this.viewabilityThreshold);
                this._slotVisibilityChange(visible);
            }, this));
        },

        _clearIntersectionObservers: function() {
            if (this.slotObserver) {
                this.slotObserver.unobserve(this.el);
            }
        },

        _slotVisibilityChange: function(visible) {
            var message = JSON.stringify({
                    gaMsg: true,
                    visible: visible
                });
            this._sendMsg(message);
        },

        _windowVisibilityChange: function(visible) {
            // If we are setup for safe frame messaging, and the visibilty has changed
            if(this.receivedData && this.windowIsVisible !== visible) {
                this._slotVisibilityChange(visible);
            }

            this.windowIsVisible = visible;

            if(this.needsRefresh) {
                this._autoRefresh();
            }

            this.lastEngagement = new Date().getTime();
        },

        _setupSafeFrameMsg: function() {
            var slot = this.$('div > iframe')[0],
                origin = document.location.origin,
                message = JSON.stringify({
                    gaMsg: true,
                    origin: origin
                });

            // No ad slot found, return
            if(!slot) return;
            this.slotWin = slot.contentWindow;

            if(Utils.getNested(slot, 'dataset', 'isSafeframe')) {
                window.addEventListener("message", this._onSafeFrameMsg, false);

                // Send a postmessage with our origin to the slot, so it can communicate back to us
                this.messageCount = 20;
                this._sendFirstMsg(message);
            }
        },

        _sendFirstMsg: function(message) {
            if(!this.receivedData) {
                this.messageCount--;
                if(this.messageCount > 0) {
                    setTimeout(_.bind(function() {
                        this._sendFirstMsg(message);
                    }, this), 100);
                }

                this._sendMsg(message);
            }
        },

        _sendMsg: function(message) {
            var locOrigin, slotOrigin;

            if(this.slotWin) {
                try {
                    locOrigin = Utils.getNested(this.slotWin, 'location', 'origin');
                    slotOrigin = (!locOrigin || locOrigin === 'null') ? window.location.protocol + '//tpc.googlesyndication.com' : locOrigin;
                } catch(err) {
                    slotOrigin = window.location.protocol + '//tpc.googlesyndication.com';
                }

                // AdLogger.logInfo("Sending message", message);
                this.slotWin.postMessage(message, slotOrigin);
            }
        },

        _onSafeFrameMsg: function(e) {
            // Check if the message is coming from a google iframe, and if we have the start of a JSON object
            if(e && typeof e.data === 'string' && e.data.charAt(0) === '{' && !this.receivedData) {
                try {
                    var data = JSON.parse(e.data);
                    if (data.adType) {
                        // We only expect to hear from them once to let us know what they are
                        window.removeEventListener("message", this._onSafeFrameMsg, false);
                        this.receivedData = true;
                        this.adPosition.onMessage(data);
                        this._setupViewabilityObserver();
                        StateManager.registerVisibilityListener(this.slotId, this._windowVisibilityChange, true);
                    }
                } catch(err) {
                    AdLogger.logInfo('Article Slot Failed to parse JSON data', this.slotId);
                }
            }
        },

        _activeViewCheck: function(event) {
            var adSlot = event.slot.__gannettSlot;
            if(adSlot) {
                adSlot._mark('Viewable', adSlot);
            }
        },

        _mark: function (label, slot) {
            var pageInfo = StateManager.getActivePageInfo(),
                options = this.options,
                adPlacement = options.adPlacement;

            if (window.newrelic && adPlacement) {
                window.newrelic.addPageAction('advertising' + label, {
                    request: AdSlot.prototype.requests,
                    position: adPlacement,
                    sticky: adPlacement === 'poster_scroll',
                    pagetype: pageInfo.basePageType || '',
                    section: pageInfo.section_name || '',
                    build: 'legacy desktop'
                });
            }
        },

        _markPrebid: function(event) {
            var responses = event[this.slotId],
                options = this.options,
                adPlacement = options.adPlacement;

            if (responses && responses.bids && responses.bids.length) {
                responses.bids.forEach(function(bid) {
                    var responseMS = parseInt(bid.timeToRespond, 10);

                    // Scope what we send to valid reponse times, and those that are more than 0
                    if (responseMS > 0 && responseMS <= 1500) {
                        window.newrelic.addPageAction('advertisingPrebid', {
                            adId: bid.adId,
                            bidder: bid.bidder,
                            responseTime: responseMS,
                            cpm: bid.cpm,
                            placement: adPlacement,
                            mediaType: 'display',
                            build: 'legacy desktop'
                        });
                    }
                });
            }
        },

        /**
         * @access private
         * Scroll listener function. Used to check the visibility of an ad slot for lazy rendering, or autorefresh purposes.
         */
        _adSlotScrollListener: function() {
            var lazyConfig;

            if(this.threshold === undefined){
                lazyConfig = Utils.getNested(window.site_vars, 'ADS', 'lazy') || {};
                this.threshold = Utils.getNested(lazyConfig, this.options.adPlacement, 'threshold') || lazyConfig.threshold || 0;
            }
            this.visible = this._checkVisible();

            if(!this.displayed && (this.visible === 'visible' || this.visible === 'dfpvisible')) {
                this._resolveDefer('lazy');

                if(!this.options.autoRefresh) {
                    // Don't need the scroll listener anymore
                    StateManager.unRegisterScrollListener(this.slotId);
                }
            }

            if(this.needsRefresh) {
                this._autoRefresh();
            }

            this.lastEngagement = new Date().getTime();
        },

        /**
         * @access private
         * Sets up autorefresh on this ad slot. Registers any related listeners, and starts the timeout.
         */
        _setupAutoRefresh: function() {
            var options = this.options,
                autoRefreshConfig = Utils.getNested(window.site_vars, 'ADS', 'auto_refresh') || {},
                type = Utils.getNested(autoRefreshConfig, options.adPlacement, 'type') || 'smart';

            this.refreshRate = Utils.getNested(autoRefreshConfig, options.adPlacement, 'refresh_rate') || autoRefreshConfig.refresh_rate || 30000;
            StateManager.registerVisibilityListener(this.slotId, this._windowVisibilityChange, true);

            if(!options.lazy && type === 'smart') {
                this._addListeners();
            }
            this.lastEngagement = new Date().getTime();
            this._startRefreshTO();
        },

        _autoRefresh: function() {
            var autoRefreshConfig = Utils.getNested(window.site_vars, 'ADS', 'auto_refresh') || {},
                inactive_timeout = autoRefreshConfig.inactive_timeout || 300000,
                type = Utils.getNested(autoRefreshConfig, this.options.adPlacement, 'type') || 'smart';

            this.options.targeting.navigationtype = 'autorefresh';
            this.setTargeting();

            /**
             * If we are smartly autorefreshing we check for
             * 1) Window/tab is "visible"
             * 2) Ad slot is at least 50% in view
             * 3) We've interacted with the page in the past 5 minutes
             *
             * If we are "simple" autorefreshing, there are no checks, just a timeout
             */
            if ((this.visible === 'dfpvisible' && this.windowIsVisible && this.lastEngagement >= new Date().getTime() - inactive_timeout) ||
                type === 'simple') {

                //setup next timeout for autorefresh
                this._startRefreshTO();

                this.refresh();
            } else {
                this.needsRefresh = true;
            }
        },

        _startRefreshTO: function() {
            this.refreshTimeout = setTimeout(_.bind(function() {
                this._autoRefresh();
            }, this), this.refreshRate);
        },

        /**
         * @access private
         * Checks whether an element is within, above, or below the current viewport within a specified threshold
         * @return {String} Whether the element is 'inview', 'dfpvisible', 'above', or 'below' relative to the current viewport.
         */
        _checkVisible: function() {
            var viewHeight = this.winHeight || this._getWindowHeight(), // Make sure we always have a windowHeight when we do a visability check
                rect = this.el.getBoundingClientRect();

            if (rect.width === 0 && rect.left === 0 && rect.top === 0) {
                return 'hidden';
            } else if(rect.bottom - this.threshold < 0) {
                return 'above';
            } else if (rect.top - viewHeight + this.threshold >= 0) {
                return 'below';
            } else if ((rect.top + 125 > 0 && rect.top + 125 < viewHeight) || rect.top - viewHeight > 125) {
                return 'dfpvisible';
            }
            return 'visible';
        },

        /**
         * @access private
         * Registers scroll and resize listener
         */
        _addListeners: function() {
            StateManager.registerResizeListener(this.slotId, this._handleResizeWindow, 500, true);
            StateManager.registerScrollListener(this.slotId, this._adSlotScrollListener, 100, true);
        },

        /**
         * @access private
         * Set the winHeight as a property of the adslot
         */
        _handleResizeWindow: function() {
            this.winHeight = this._getWindowHeight();
        },

        /**
         * @access private
         * Returns the window's current height
         * @return {Number} window's current height
         */
        _getWindowHeight: function() {
            return Math.max(document.documentElement.clientHeight, window.innerHeight);
        },

        /**
         * @access private
         * Takes an adtype string and returns a lowercase string
         * @param  {string} adType ad type string recieved from the high impact ad unit
         * @return {string|null}        normalized ad type string
         */
        _normalizeAdType: function(adType) {
            if (!adType) {
                return null;
            } else {
                return adType.toLowerCase().replace(/[^a-z]/, '');
            }
        },

    });
    return AdSlot;
});

