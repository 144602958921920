/**
 * @fileoverview longform/longform chapter navigation
 * @author Matthew Briede, bminton@gannett.com (Brandon Minton)
 */
define('modules/longform/longform-navigation',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'state',
    'site-manager',
    'utils'
],
function(
    $,
    _,
    BaseView,
    PubSub,
    StateManager,
    SiteManager,
    Utils
) {
    var LongformNavigation = BaseView.extend({

        // View element
        el: '.longform-navigation-wrapper',

        // Events
        events: {
            'click .longform-navigation-icon, .longform-navigation-text' : 'onClickNavigation',
            'click .longform-navigation-chapter-up' : 'onClickChapterUp',
            'click .longform-navigation-chapter-down' : 'onClickChapterDown',
            'click .longform-navigation-chapter': 'onChapterClick'
        },

        /**
         * Initialize view.
         */
        initialize: function (options) {
            this.$win = Utils.get('win');

            this.pubSub = {
                'closeChaptersFlyout': this.closeChaptersFlyout
            };

            //Flyouts
            this.$flyoutWindow = this.$('.longform-navigation-flyout');

            //Buttons
            this.$upBtn = $('.longform-navigation-chapter-up');
            this.$downBtn = $('.longform-navigation-chapter-down');

            this.$topGrad = $('.longform-navigation-chapter-grad-top');
            this.$botGrad = $('.longform-navigation-chapter-grad-bot');

            //Chapters
            this.$chapterNav = this.$('.longform-navigation-chapter');
            this.$chapterItemWrap = this.$('.longform-navigation-chapter-item-wrap');
            this.$chapterOverflow = this.$('.longform-navigation-chapter-overflow-wrap');
            this.chapterCount = 0;
            this.chapterOverflowPos = 0;
            this.chapterHeights = [];
            this.calcHeightBool = false;

            //Setup Chapter Nav & Overflows
            this.availableVertSpace = this.getAvailableVerticalSpace();

            this.flyoutOptions = {
                'flyout': this.$flyoutWindow,
                'icon': 'longform-navigation',
                'iconSpace': '105px',
                'closeMethod': 'closeChaptersFlyout'
            };

            BaseView.prototype.initialize.call(this, options);
        },

        closeChaptersFlyout: function(e) {
            this.animate(this.$flyoutWindow, 'right', '-300px', 200);
        },

        onClickNavigation: function() {
            if(!this.calcHeightBool) {
                this._setChapterWrapHeight();
            }
            PubSub.trigger('openFlyout', this.flyoutOptions);
        },

        onChapterClick: function(e) {
            $('html, body').animate({
                scrollTop: $(".chapter-marker:eq( " + $(e.currentTarget).index() + " )").offset().top - 60
            }, 1000);
        },

        _setChapterWrapHeight: function() {
            var space_needed = this.getChapterHeights();
            var space_avail = this.availableVertSpace;

            if(space_needed > space_avail) {
                this.$chapterItemWrap.height(space_avail);
            } else {
                this.$chapterItemWrap.height(space_needed);
                this.$chapterOverflow.css({"position": "inherit"});
                this.$upBtn.hide();
                this.$downBtn.hide();
                this.$topGrad.hide();
                this.$botGrad.hide();
            }
        },

        getAvailableVerticalSpace: function() {
            var header = SiteManager.getHeader(),
                headerHeight = 0;
            if (header) {
                headerHeight = header.getCollapsedHeight();
            }
            return this.$win.height() - 320 - headerHeight;
        },

         getChapterHeights: function() {
            var that = this;
            var total = 0;
            this.$chapterNav.each(function(index) {
                that.chapterHeights.push($(this).height());
                total += $(this).height();
            });
            return total;
        },

        onClickChapterDown: function(e) {
            if((this.chapterOverflowPos + this.$chapterOverflow.height() - this.availableVertSpace) > 0) {
                this.$upBtn.css('opacity', '1');
                this.animate(this.$chapterOverflow, 'top', (this.chapterOverflowPos -= this.chapterHeights[this.chapterCount])+'px', 100, 'linear');
                this.chapterCount++;
                if((this.chapterOverflowPos + this.$chapterOverflow.height() - this.availableVertSpace) < 0) {
                    this.$downBtn.css('opacity', '.5');
                }
            }
        },

        onClickChapterUp: function(e) {
            if(this.chapterCount > 0) {
                this.$downBtn.css('opacity', '1');
                this.animate(this.$chapterOverflow, 'top', (this.chapterOverflowPos += this.chapterHeights[this.chapterCount])+'px', 100, 'linear');
                this.chapterCount--;
                if(this.chapterCount === 0) {
                    this.$upBtn.css('opacity', '.5');
                }
            }
        }
    });

    return LongformNavigation;
});

