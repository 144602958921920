define('modules/longform/longform-inline-share',[
    'jquery',
    'baseview',
    'pubsub'
],

function (
$,
BaseView,
PubSub
) {
    'use strict';

    var longformInlineShare = BaseView.extend({

        events: {
            'click .lf-share-item' : 'onSocialButtonClick'
        },

        onSocialButtonClick: function(e) {
            if ($(e.target).hasClass('lf-share-comments')) {
                PubSub.trigger('onClickShare', 'comments');
                e.preventDefault();
            } else if ($(e.target).hasClass('lf-share-email') || $(e.target).hasClass('js-lf2-share-email')) {
                PubSub.trigger('onClickShare', 'email');
                e.preventDefault();
            }
        }

    });


    return longformInlineShare;
});
