define('modules/ggm-jobs-search/ggm-jobs-search',[
    'jquery',
    'underscore',
    'baseview',
    'form/dropdown'
],
    function(
        $,
        _,
        BaseView,
        Dropdown
        ) {
        "use strict";
        /**
         * View class.
         */
 
        var JobsModule = BaseView.extend({
 
            // View element.
            events: {
                'submit': 'onFormSubmit'
            },
 
            /**
             * Initialize view.
             * @param {Object} options
             */
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);
                this._setupForm();
 
            },
            _setupForm: function(){
                this.subviews.dropdownProduct = new Dropdown({
                    el: this.$('.ggmmtjm-select')
                });
            }
           
        });
 
        /**
         * Return view class.
         */
        return JobsModule;
    }
);
