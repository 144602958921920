/**
* @author kklee@usatoday.com (Kent Lee)
* @fileoverview JS functionality for Staff Listing
*               contains reduced version of sports-datables and popup-details
*/

define('modules/tools/staff-listing',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'utils',
    'modules/scroller/vertical-scroll',
    'libs/jquery/plugins/jquery.dataTables'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    Utils,
    VerticalScroll
)  {
    var StaffListingView = BaseView.extend({

        events: {
            'mouseenter .staff-filter-dropdown': 'onHoverFilterDropdown',
            'mouseleave .staff-filter-dropdown': 'onHoverFilterDropdown',
            'click .staff-details-open': 'onDetailsOpen',
            'click .staff-details-close': 'onDetailsClose'
        },

        initialize: function() {
            BaseView.prototype.initialize.apply(this, arguments);

            this.$popupBox = this.$('.staff-details-box');
            this.$popupFilm = this.$('.staff-details-film');
            this.$popupTop = parseInt(this.$popupBox.css('top'), 10);

            var dataTableOptions = $.extend({}, $.fn.dataTable.defaults, {
                "bAutoWidth": false,
                "bFilter": false,
                "bDestroy": true,
                "bPaginate": false,
                "bInfo": false
            });

            this.$staffList = this.$('.staff-listing-table').dataTable(dataTableOptions);
        },

        onDetailsOpen: function(e) {
            e.preventDefault();
            this.openedOnce = true;
            this.$popupLink = $(e.currentTarget);
            var popupClass = this.$popupLink.data('class');
            var popupOffset = Utils.getScrollPosition();
            this.$popupBox.css('top', popupOffset);
            if (this.$popupBox.data('url') != this.$popupLink.data('url')) {
                // only AJAX load if the popup has changed from last call
                this.loadDetails();
            }
            this.$popupBox.addClass(popupClass);
            this.$popupFilm.show();
            this.$popupBox.show();
            $('.site-footer').css({'visibility': 'hidden'});
        },

        onDetailsClose: function(e) {
            if (!this.openedOnce) return;
            if (e) e.preventDefault();
            var popupClass = this.$popupLink.data('class');
            this.$popupBox.removeClass(popupClass);
            this.$popupBox.hide();
            this.$popupFilm.hide();
            this.$('.staff-details-data').addClass('staff-loading-details');
            $('.site-footer').css({'visibility': 'visible'});
        },

        loadDetails: function() {
            this.$('.staff-details-loaded').remove();
            var url = this.$popupLink.data('url');
            this.$popupBox.data('url', url);

            StateManager.fetchHtml(url).done(_.bind(function(html) {
                if (html.find('.staff-details-loaded').length) {
                    this.$('.staff-details-data').removeClass('staff-details-loading');
                    this.$('.staff-details-data').html(html);
                } else {
                    console.log('Unable to load details for: ' + url);
                }
            }, this));
        },

        onHoverFilterDropdown: function(e) {
            if (e.type === 'mouseenter') {
                var $dropdown = $(e.currentTarget);
                var $scrollWrap = $dropdown.find('.staff-filter-scroll-wrap');
                this.subviews.vscroll = new VerticalScroll({
                    el: $scrollWrap,
                    padding: 6
                });
            } else {
                if(this.subviews.vscroll) this.subviews.vscroll.destroy();
            }
        }

    });

    return StaffListingView;
});

