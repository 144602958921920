/**
 * @fileoverview Weather Seven Day view module.
 * @author mbriede@gannett.com (Matthew Briede)
 */
define('modules/weather/weather-seven-day',[
    'jquery',
    'underscore',
    'baseview',
    'state'
],
    function(
        $,
        _,
        BaseView,
        StateManager
        ) {
        "use strict";
        /**
         * View class.
         */

        var weatherSevenDay = BaseView.extend({

            /**
             * Initialize view.
             * @param {Object} options
             */
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);
            }
        });

        /**
         * Return view class.
         */
        return weatherSevenDay;
    }
);
