/**
 * @fileoverview Timeline (interactive) view.
 * @author cmanning@gannett.com (Chris Manning), mdkennedy@gannett.com (Mark Kennedy)
 */
define('modules/interactives/timeline',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'baseview',
    'modules/interactives/interactive-templates',
    'libs/jqueryui/draggable',
    'modules/global/brightcove-video',
    'touchswipe'
],
function(
    $,
    _,
    PubSub,
    Utils,
    BaseView,
    Template,
    Video
)
    {

        /**
         * View class.
         */
        var TimelineView = BaseView.extend({

            // Events.
            events: {
                'click .interactive-timeline-marker': 'goToMarker',
                'click .slide-nav.prev, .slide-nav.next': 'renderArrowClick'
            },

            /**
             * Initialize view.
             * @param {Object} options View options passed during init.
             */
            initialize: function(options) {
                // call base class initialize
                BaseView.prototype.initialize.call(this, options);
                _.bindAll(this, 'snapToMarker');

                //setup interactive template view
                this.subviews.template = new Template({
                    'el': this.$el,
                    'interactiveType' : "timeline",
                    'isFramed': options.isFramed,
                    'standAlone': options.standAlone
                });
                this.$leftArrow = this.subviews.template.$leftArrow;
                this.$rightArrow = this.subviews.template.$rightArrow;
                this.$slideContainer = this.subviews.template.$slideContainer;
                this.$slides = this.subviews.template.$slides;
                this.$viewport = this.subviews.template.$viewport;
                this.$timeslider = this.$(".interactive-timeline-timeslider");
                this.$timesliderBelt = this.$timeslider.find(".interactive-timeline-belt");
                this.$timesliderBuckle = this.$timeslider.find(".interactive-timeline-buckle");
                this.$timesliderLabelContainer = this.$timeslider.find('.interactive-timeline-labels');
                this.$timesliderLabels = this.$timesliderLabelContainer.find('.interactive-timeline-label');
                this.$contentBox = this.$slideContainer.find(".timeline-slide-content");
                this.$gridLines = this.$viewport.find(".grid-line");

                this.arrEventPositions = [];
                this.maxSlides = this.subviews.template.maxSlides;
                this.arrayPanelMax = 850; //max width of elements inside of stage + 24 pix for padding
                this.numPanelMinLefts = 6;
                this.numMarkerWidth = 9;
                this.strDateBegin = "";
                this.strDateEnd = "";
                this.currentSlideIndex = this.subviews.template.currentSlideIndex;

                var dragOptions = {
                    containment: ".buckle-box",
                    cursor: "move",
                    axis: "x",
                    stop: this.snapToMarker
                };
                if (Modernizr.touch) {
                    dragOptions.delay = 200;
                    this.$('.stage').swipe({
                        self: this,
                        swipe: function(e, direction, distance, duration, fingerCount, swipeOptions) {
                            if (direction === 'right' || direction === 'down') {
                                swipeOptions.self.previousSlide();
                            } else {
                                swipeOptions.self.nextSlide();
                            }
                        }
                    });
                    var self = this;
                    require(['touchpunch'], function(){
                        if (!self.destroyed) {
                            self.setup(dragOptions);
                        }
                    });
                } else {
                    this.setup(dragOptions);
                }
            },

            destroy: function(){
                this.$timesliderBuckle.draggable('destroy');
                BaseView.prototype.destroy.apply(this, arguments);
            },

            setup: function(dragOptions) {
                var timeSliderContent = "",
                    viewportData = this.$viewport.data() || {},
                    _this = this;
                this.strDateBegin = viewportData.graphstart;
                this.strDateEnd = viewportData.graphend;

                this.$slides.each(function(index) {
                    var percent = $(this).find('.timeline-slide-content').data().tlpercent;
                    var panelMax = parseInt(_this.arrayPanelMax, 10);
                    var numLeftPosition = Math.round( ( percent * panelMax ) + 27);
                    _this.arrEventPositions[index] = numLeftPosition;
                    timeSliderContent += "<div class=\"interactive-timeline-marker\" style=\"left:" + numLeftPosition + "px;\"></div>";
                });
                this.$timesliderBelt.append(timeSliderContent);
                this.maxEvents = this.arrEventPositions.length;
                this.setUpLabels();
                //move belt buckle to initial position
                this.goTo(0);
                this.$timesliderBuckle.draggable(dragOptions);
            },

            setUpLabels: function() {
                var dateStart = this.strDateBegin,
                    dateEnd = this.strDateEnd,
                    numTimeSpan = dateEnd - dateStart,
                    _this = this,
                    panelMax = _this.arrayPanelMax;
                // position labels
                this.$timesliderLabels.each(function() {
                    var labelDate = $(this).data().labelms,
                    numLeftPosition = Math.round((((labelDate - dateStart) / numTimeSpan) * ( panelMax )));
                    $(this).css('left', numLeftPosition);
                });
                // position gridlines
                this.$gridLines.each(function(index) {
                    var value = _this.$timesliderLabels.eq(index).position().left + 33 + 'px';
                    $(this).css('left', value);
                });
                this.$timesliderLabels.find(".interactive-timeline-time").fadeIn("slow");
            },
            
            
            goToMarker: function(e) {
                var index = this.$timesliderBelt.children('.interactive-timeline-marker').index(e.currentTarget);
                this.goTo(index);

                if (this.options.isFramed !== true) {
                    this.subviews.template.trackPageView();
                }
            },
            
            renderArrowClick: function(e) {
                if ($(e.currentTarget).is(this.$leftArrow)) {
                    this.previousSlide();
                } else {
                    this.nextSlide();
                }

                if (this.options.isFramed !== true) {
                    this.subviews.template.trackPageView();
                }
            },

            goTo: function(newSlideIndex){
                var numLeftPosition;
                this.currentSlideIndex = newSlideIndex;

                var currentPanelWidth = parseInt(this.$contentBox.eq(newSlideIndex).width(), 10) ;
                var maxLeft = this.arrayPanelMax - currentPanelWidth;

                //move timeslider buckle to current slide
                this.$timesliderBuckle.css("left", (this.arrEventPositions[newSlideIndex] - 19).toString() + "px");

                // calculate positions
                numLeftPosition = Math.round(this.arrEventPositions[newSlideIndex] + (this.numMarkerWidth / 2) - currentPanelWidth / 2);
                if (numLeftPosition > maxLeft) {
                    numLeftPosition = maxLeft;
                } else if (numLeftPosition < this.numPanelMinLefts) {
                    numLeftPosition = this.numPanelMinLefts;
                }

                //move indicator to correct position
                this.$slides.eq(newSlideIndex)
                    .find('.indicator')
                    .css("left", ( this.arrEventPositions[newSlideIndex] - 8 ) - numLeftPosition.toString() + "px");

                this.$slides.removeClass('active')
                    .eq(newSlideIndex)
                    .css("left", numLeftPosition.toString() + "px")
                    .addClass('active');

                //open all content links in new window
                this.$contentBox.find(".text a").attr("target", "_blank");

                this.subviews.template.goTo(newSlideIndex);

                //set current slide index
                this.currentSlideIndex = newSlideIndex;
            },

            previousSlide: function() {
                this.goTo(Math.max(this.currentSlideIndex-1, 0));
            },

            nextSlide: function() {
                this.goTo(Math.min(this.currentSlideIndex+1, this.maxSlides-1));
            },

            snapToMarker: function (e) {
                var numClosest = 850, numIndex, i, objScrubber = $(e.target),
                    numScrubberPosition = parseInt(objScrubber.css("left"), 10);
                for (i = 0; i < this.arrEventPositions.length; i++) {
                    if (Math.abs((numScrubberPosition + 19) - this.arrEventPositions[i]) < numClosest) {
                        numIndex = i;
                        numClosest = Math.abs((numScrubberPosition + 19) - this.arrEventPositions[i]);
                    }
                }

                if (this.options.isFramed !== true) {
                    this.subviews.template.trackPageView();
                }
                this.goTo(numIndex);
            }
        });

        /**
         * Return view class.
         */
        return TimelineView;
    }
);
