define('user-accounts/firefly-user-account',[
    'jquery',
    'underscore',
    'pubsub',
    'user-accounts/base-user-account',
    'user-manager',
    'utils',
    'third-party-apis/firefly/firefly',
    'cookie'
],
function(
    $,
    _,
    PubSub,
    BaseUserAccount,
    UserManager,
    Utils,
    Firefly
)
    {
        'use strict';
        var FireflyUserAccount = BaseUserAccount.extend({
            initialize: function(options){
                BaseUserAccount.prototype.initialize.call(this, options);

                this.user = {};

                UserManager.registerAccount(this);
                
                // try to auto-login
                UserManager.loginUser('firefly');
            },

            _handleGetCoreUserInfo: function() {
                return Firefly.getUserInfo()
                    .then(_.bind(function(userData) {
                        var atyponId = userData.fireflyUserId || userData.userId;
                        var coreUserInfo = {
                            "CoreUserId": Utils.getNested(userData, 'userService', 'coreUserId'),
                            "AtyponSessionKey": this.getUserToken(),
                            "GooglePlusId": undefined,
                            "AtyponId": atyponId,
                            "UserLicenseType": userData.userLicenseType,
                            "FaceBookId": undefined,
                            "FileMobileId": undefined
                        };

                        // append social provider IDs
                        var identities = Utils.getNested(userData, 'userService', 'identities') || [];
                        for (var i=0; i < identities.length; i++) {
                            var id = identities[i];
                            if (id.ProviderName === 'Facebook') {
                                coreUserInfo.FaceBookId = id.ProviderUserId;
                            } else if (id.ProviderName === 'Google') {
                                coreUserInfo.GooglePlusId = id.ProviderUserId;
                            }
                        }

                        return coreUserInfo;
                    }, this));
            },

            /**
             * Gets the firefly user info object
             * @returns {Deferred}
             */
            getUserInfo: function(){
                return Firefly.getUserInfo();
            },

            /**
             * Gets the user service attributes object
             * @returns {Deferred}
             */
            getUserAttributes: function(){
                return Firefly.getUserAttributes();
            },

            /**
             * Gets the userUtil user info object
             * @returns {Deferred}
             */
            getUserResponse: function() {
                return Firefly.getUserResponse();
            },

            /**
             * Returns the decoded firefly views cookie
             * @returns {Object}
             */
            getViewsCookie: function(){
                return Firefly.getFireflyViewsCookie() || {};
            },

            _handleLogin: function() {
                return Firefly.getUserInfo()
                    .then(
                        _.bind(function(userData) {
                            this.user = userData;
                            return userData;
                        }, this),
                        _.bind(function(){
                            this.logout();
                        }, this)
                    );
            },

            _handleLogout: function(){
                if (this.getUserToken()) {
                    return Firefly.logoutUser(this.sessionToken);
                } else {
                    return $.Deferred().resolve();
                }
            },
            getName: function(){
                return 'firefly';
            },

            getUserToken: function(){
                return $.cookie('sessionKey');
            },

            getUserId: function(){
                return this.user.userId;
            },

            getUserAvatar: function(){
                if (this.user.picture && this.user.picture.data) {
                    return this.user.picture.data.url;
                } else {
                    return "";
                }
            },

            getUserFirstName: function(){
                return this.user.firstName;
            },

            getUserLastName: function(){
                return this.user.lastName;
            },

            getWelcomeName: function(){
                return this.user.welcomeName;
            },

            getUserPhoto: function(){
                var userPhoto = _.find(this.user.attributes, function(obj){
                    return obj.attributeName === 'preferredPhoto';
                });
                if (userPhoto) {
                    return Utils.getNested(userPhoto, 'attributeValues', 0, 'attributeValue');
                }
            }
        });

        return new FireflyUserAccount();
    }
);
