/**
 * @fileoverview Search with trending content dropdown in the global navigation.
 * @author akothapall@gannett.com (Amarnath Kothapalli)
 *
 */
define('modules/header/site-nav-search-trending-module',[
        'jquery',
        'underscore',
        'baseview',
        'pubsub',
        'modules/header/site-nav-dropdown'
    ],
    function(
        $,
        _,
        BaseView,
        PubSub,
        SiteNavDropdownView
    ) {

        "use strict";
        var SearchDropdownTrendingView = SiteNavDropdownView.extend({

            // Events.
            events: function() {
                return _.extend({}, SiteNavDropdownView.prototype.events, {
                    'click .search-tr-module-trending-item': 'updateSearch',
                    'submit .search-tr-module-form': 'formSubmit'
                });
            },

            initialize: function(options) {
                this.searchForm = this.$('.search-tr-module-form');
                this.searchInput = this.$('.search-tr-module-input');
                this.searchTrending = this.$('.search-tr-module-trending');
                this.trackLabel = '';

                // call base class initialize
                SiteNavDropdownView.prototype.initialize.call(this, options);
            },

            updateSearch: function(e) {
                this.searchInput.val($(e.currentTarget).find('.search-tr-module-trending-item-tag-js').text());
                this.trackLabel = 'search|' + $(e.currentTarget).find('.search-tr-module-trending-item-tag-js').data('track-headline-var');

                this.searchForm.submit();
            },

            formSubmit: function(e) {
                if (this.trackLabel === '') {
                    this.trackLabel = 'search|' + this.searchInput.val();
                }

                PubSub.trigger('track', {
                    label: this.trackLabel
                });
            }

        });



        return SearchDropdownTrendingView;

    });
