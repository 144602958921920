/**
 * @fileoverview Member Guide view.
 * @author Ryan Copley (rcopley@gannett.com)
 */
define('apps/member-guide-social',[
        'jquery',
        'base-app',
        'site-manager'
    ],
    function(
        $,
        BaseApp,
        SiteManager
    ) {
        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var MemberGuideAppsView = BaseApp.extend({

            events : {
                'click .js-mga-icon-link' : 'showSocial'
            },

            showSocial : function(e){
                e.preventDefault();
                var networkType = $(e.currentTarget).data("network-type");
                this.$(".js-mga-icon-link").removeClass("mga-icon-active");
                this.$(".js-mga-page-panel").removeClass("mga-page-active");
                this.$(".js-mga-icon-link[data-network-type="+networkType+"]").addClass("mga-icon-active");
                this.$(".js-mga-page-panel[data-network-type="+networkType+"]").addClass("mga-page-active");                    

                var current_width = $(window).width();
                if(current_width < 670){
                    $("html, body").animate({
                        scrollTop: this.$(".mga-page-content").offset().top - 60 // the 60 is to negate the headers height
                    }, 500);
                }
            },
            /**
             * afterPageReveal view.
             */
            afterPageReveal: function() {
                $("body").addClass("mga-body mga-body-subpage");
                $(".js-mga-container").addClass("mga-body-subpage mga-section-inline");

            },
            beforeAppRemove: function() {
                $("body").removeClass("mga-body mga-body-subpage");
            }
        });

        /**
         * Return view class.
         */
        return MemberGuideAppsView;
    }
);

