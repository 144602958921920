/**
 * @fileoverview Arkadium view module.
 * @author brad.smith@azcentral.com
 */
define('modules/arkadium-promo',[
        'jquery',
        'underscore',
        'base-app'
    ],
    function(
        $,
        _,
        BaseApp
    ) {
        var ArkadiumPromoView = BaseApp.extend({
            initialize: function() {
                var randomElements = $('.arkadium-featured').get().sort(function(){ 
                    return Math.round(Math.random())-0.5;
                }).slice(0,2);
                $(randomElements).removeClass('arkadium-hidden');
            },
        });
        return ArkadiumPromoView;
    }
);

