define('third-party-apis/firefly/firefly',[
    'jquery',
    'underscore',
    'third-party-apis/base-third-party-api',
    'managers/requestmanager',
    'utils',
    'modules/global/userUtil',
    'base64'
],
function(
    $,
    _,
    BaseThirdPartyApi,
    RequestManager,
    Utils,
    UserUtil
)
    {
        'use strict';
        /**
         * Library to handle calls to/from Firefly for various service interactions.
         * @author Dan Moore <dcmoore@gannett.com>, Mark Kennedy <mdkennedy@gannett.com>
         */
        var Firefly = BaseThirdPartyApi.extend({

            /**
             * Gets information of the user who is currently logged in.
             * @returns {Deferred}
             */
            getUserInfo: function() {
                if (!this.userInfo || this.userInfo.state() === 'rejected') {
                    this.userInfo = this.getUserResponse().then(function(response, personalizedLinkData) {
                        var userInfo = response;
                        var userPath = Utils.getNested(window.site_vars, 'userAuthentication', 'user', 'response_user_path');
                        _.each(userPath, function(node){
                            userInfo = Utils.getNested(userInfo, node);
                        });
                        userInfo.userInfo = response;
                        userInfo.personalizedLinkData = personalizedLinkData;
                        if (response.meta.isAuthenticatedInCurrentContext && response.meta.status === 200) {
                            return userInfo;
                        } else {
                            return $.Deferred().reject("Not logged in.");
                        }
                    });
                }
                return this.userInfo;
            },

            /**
             * Gets attributes of the current user.
             * @returns {Deferred}
             */
            getUserAttributes: function() {
                if (!this.userAttributes) {
                    this.userAttributes = this.getUserResponse().then(function(response, personalizedLinkData, insightsData) {
                        if (response.meta.status === 200) {
                            // jira ticket GUP-898
                            var userAttributes = $.extend(
                                                {},
                                                Utils.getNested(response, 'response', 'user', 'profileAttributes'),
                                                Utils.getNested(response, 'response', 'attributes')
                                            );

                            return {userAttributes: userAttributes, personalizedLinkData: personalizedLinkData, insightsData: insightsData};
                        } else {
                            return $.Deferred().reject("Attribute retrieval failed.");
                        }
                    });
                }
                return this.userAttributes;
            },

            /**
             * Get current user info from GUP
             * @returns {Deferred}
             */
            getUserResponse: function() {
                if (!this.userUtil) {
                    this.userUtil = new UserUtil();
                }
                if (!this.userResponse) {
                    this.userResponse = this.userUtil.getUser();
                }
                return this.userResponse;
            },

            sanitizeFireflyViewsObject: function(viewsObj) {
                if (!_.isObject(viewsObj)) {
                    console.error('Firefly views cookie is not an object');
                    return false;
                }
                if (!viewsObj.viewCount || !viewsObj.viewThreshold || !viewsObj.viewedContent) {
                    console.error('Missing Firefly views object properties');
                    return false;
                }
                if (!_.isNumber(viewsObj.viewCount) || !_.isNumber(viewsObj.viewThreshold) || !_.isArray(viewsObj.viewedContent)) {
                    console.error('Wrong format in Firefly views object values');
                    return false;
                }
                if (viewsObj.viewCount < 1) {
                    viewsObj.viewCount = 1;
                }
                if (viewsObj.viewThreshold < 1) {
                    viewsObj.viewThreshold = 1;
                }
                if (viewsObj.viewCount > viewsObj.viewThreshold) {
                    viewsObj.viewThreshold = viewsObj.viewCount;
                }
                return viewsObj;
            },

            /**
             * Returns firefly_akamai_open base64-encoded JSON cookie as object or null on failure.
             */
            getFireflyViewsCookie: function() {
                // Sample views object format
                // var viewsObject = {
                //     "viewCount": 1,
                //     "viewThreshold": 6,
                //     "viewedContent": [ "0001", "0002" ]
                // };
                // $.cookie('firefly_akamai_open', btoa(JSON.stringify(viewsObject)), { path: '/'}); // replace with actual cookie once it's getting set.
                var viewsObj = null,
                    openCookie = $.cookie('firefly_akamai_open');

                if (openCookie) {
                    viewsObj = this._parseCookie(openCookie);
                    if (viewsObj) {
                        viewsObj = this.sanitizeFireflyViewsObject(viewsObj);
                        var viewsRemaining = viewsObj.viewThreshold - viewsObj.viewCount;
                        viewsObj.viewsRemaining = viewsRemaining;
                    }
                }

                return viewsObj;
            },

            _parseCookie: function(openCookie){
                try {
                    // First treat openCookie as base64-encoded, which it has been for quite some time
                    return JSON.parse(atob(openCookie));
                } catch (encoded_ex) {
                    // The cookie used to be plaintext encoded, so if treating it as base64-encoded above fails try converting it
                    // This can probably be removed now, but there could still be lingering plaintext cookies until the end of 2014
                    try {
                        return JSON.parse(openCookie);
                    } catch (plain_ex) {
                    }
                }
                return null;
            },

            /**
             * Gets user's logged in status.
             * @returns {*} the user's ID, a valid access token, a signed
             * request, and the time the access token and signed request each expire
             */
            getLoginStatus: function() {
                return this.getUserInfo();
            },

            /**
             * Logs out a user using Firefly's authentication.
             * NOT CURRENTLY OPERATIONAL, just pretends to work.
             * @returns {Deferred}
             */
            logoutUser: function(){
                this.userInfo = null;
                this.userAttributes = null;
                this.userResponse = null;
                return $.Deferred().resolve();
            }
        });

        return new Firefly();
    }
);

