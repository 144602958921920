/**
 * @fileoverview Gallery subview of carousel. In charge of the the stacked images and endslate
 * @author jmerrifiel@gannett.com (Jay Merrifield)
 */
define('modules/sports/sports-carousel-gallery',[
    'jquery',
    'underscore',
    'backbone',
    'baseview',
    'pubsub',
    'utils',
    'modules/global/taboola',
    'modules/carousel/gallery',
    'adPosition',
    'adLogger'
],
    function(
        $,
        _,
        Backbone,
        BaseView,
        PubSub,
        Utils,
        Taboola,
        CarouselGallery,
        AdPosition,
        AdLogger
    ) {

        /**
         * View class.
         */
        var SportsCarouselGallery = CarouselGallery.extend({

            initialize: function(options) {
                _.bindAll(this, '_initializeAds', 'startVisible');
                options = $.extend({galleryClass: '.front-galleries-primary'}, options);
                CarouselGallery.prototype.initialize.call(this, options);
            },

            _initializeAds: function() {
                if (!this.options.ads) {
                    return;
                }
                this.galleryData = this.$el.data() || {};
                var targeting = this.getAdTargeting();


            },

            startVisible: function() {
                if (this.options.isSideBarOpen() && this.options.sidebarAds()) {
                    return true;
                }
                return false;
            },

            refreshSponsorshipAd: function(snapshotId){
                //remove default behaviour, we'll call our own refresh on gallery change
                return;
            }

        });
        return SportsCarouselGallery;
    }
);
