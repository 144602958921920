/**
 * @fileoverview Base Chart (interactive) view.
 * @author William Broza
 */

define('modules/interactives/interactive-base',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'modules/interactives/interactive-ads',
    'libs/flot/jquery.flot'
],
    function($, _, BaseView, Utils, InteractiveAds) {
        "use strict";

        /**
         * View class.
         */
        var BaseChartView = BaseView.extend({

            events: {
                'plothover .chart-area': 'lazyHover'
            },

            /**
             * Initialize view for the bar graphs.
             * @param {Object} options View options passed during init.
             */
            initialize: function(options) {
                // Setup options.
                var ads = true;
                if (options.isFramed || this.$el.data('suppressad') === true) {
                    ads = false;
                }
                BaseView.prototype.initialize.call(this, options);

                this.initializeDom();
                this.flotData = this.getFlotData(this.datasetItems);
                if (!this.graph.length || this.flotData === null || this.flotData === undefined) {
                    return;
                }
                if (ads) {
                    this.subviews.interactiveAds = new InteractiveAds({
                        el: this.$('.interactive-sponsor')
                    });
                }
                this.redraw();
                this.$chartHover.css('color', this.mainColor);
            },

            redraw: function() {
                $.plot(
                    this.graph,
                    this.flotData,
                    this.getFlotOptions()
                );
                this.calculateBoundVal();
            },

            initializeDom: function() {
                this.chartData = JSON.parse(this.$('.chartData').html() || '{}');
                this.datasetItems = Utils.getNested(this.chartData, 'datasets', 'item') || [];
                this.chartColors = this.getChartColors();
                this.$chartHover = this.$('.chart-hover');
                this.$chartArea = this.$('.chart-area');
                this.graph = this.$(".graph");
                this.colorsArray = (this.$el.data('colors') || '').split('|');
                if (this.colorsArray.length >= 6) {
                    this.mainColor = this.colorsArray[2];
                } else if (this.colorsArray.length == 1) {
                    this.mainColor = this.colorsArray[0];
                }
            },

            getChartColors: function() {
                return (this.$el.data('colors') || 'rgb(100,100,100)').split('|');
            },

            numberWithCommas: function(x) {
                return x.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
            },

            /**
             * Builds the ticks array for the flot chart.
             * @param {String} tickData Pipe delimited string of labels for the ticks.
             * @return {Array} newArray
             * @private
             */
            _createTicks: function(tickData) {
                return _.map(tickData.split("|"), function(label, index) {
                    return [index, label];
                });
            },

            calculateBoundVal: function() {
                this.hoverLeftBoundVal = this.hoverLeftBound();
                this.hoverRightBoundVal = this.hoverRightBound();
                this.hoverTopBoundVal = this.hoverTopBound();
                this.hoverBottomBoundVal = this.hoverBottomBound();
            },

            hoverLeftBound: function() {
                return this.$el.width() / 2;
            },

            hoverRightBound: function() {
                return this.$el.width() / 2;
            },

            hoverTopBound: function() {
                return this.$el.height() / 5;
            },

            hoverBottomBound: function() {
                return this.$el.height() / 5;
            },

            getHoverPrefix: function() {
                return this.chartData.beforelabel || '';
            },

            getHoverSuffix: function() {
                return this.chartData.afterlabel || '';
            },

            _getHoverClass: function(pos) {
                if (pos.pageX > this.hoverRightBoundVal / 2 && pos.pageX < this.hoverLeftBoundVal / 2) {
                    if (pos.pageY < this.hoverTopBoundVal) {
                        return 'top';
                    } else if (pos.pageY > this.hoverBottomBoundVal) {
                        return 'bottom';
                    }
                } else if (pos.pageX < this.hoverLeftBoundVal) {
                    return 'middleleft';
                } else if (pos.pageX > this.hoverRightBoundVal) {
                    return 'middleright';
                } else {
                    return '';
                }
            },

            _getHoverPosition: function(relativeChartAreaPos, relativeContainerPos) {
                if (relativeContainerPos.pageX > this.hoverRightBoundVal / 2 && relativeContainerPos.pageX < this.hoverLeftBoundVal / 2) {
                    if (relativeContainerPos.pageY < this.hoverTopBoundVal) {
                        return {
                            top: relativeChartAreaPos.pageY,
                            left: relativeChartAreaPos.pageX - (this.$chartHover.outerWidth(true) / 2)
                        };
                    } else if (relativeContainerPos.pageY > this.hoverBottomBoundVal) {
                        return {
                            top: relativeChartAreaPos.pageY - this.$chartHover.outerHeight(true),
                            left: relativeChartAreaPos.pageX - (this.$chartHover.outerWidth(true) / 2)
                        };
                    }
                } else if (relativeContainerPos.pageX < this.hoverLeftBoundVal) {
                    return {
                        top: relativeChartAreaPos.pageY - (this.$chartHover.outerHeight(true) / 2),
                        left: relativeChartAreaPos.pageX
                    };
                } else if (relativeContainerPos.pageX > this.hoverRightBoundVal) {
                    return {
                        top: relativeChartAreaPos.pageY - (this.$chartHover.outerHeight(true) / 2),
                        left: relativeChartAreaPos.pageX - this.$chartHover.outerWidth(true)
                    };
                } else {
                    return null;
                }
            },

            /**
             * Called when the user hovers over the chart.
             */
            lazyHover: function() {
                if (!this.lazyDebounce) {
                    this.lazyDebounce = _.throttle(this._hover, 20);
                }
                this.lazyDebounce.apply(this, arguments);
            },

            /**
             * Builds and displays tool tip that appears when hovering over a chart.
             * @param {Object} event JavaScript event object.
             * @param {Object} pos JavaScript object with user coordinates.
             * @param {Object} obj Object containing information about the hovered bar.
             * @private
             */
            _hover: function(event, pos, obj) {
                if(obj && pos && event && obj.series) {
                    var relativeContainerPos = {
                            pageY: pos.pageY - this.$el.offset().top,
                            pageX: pos.pageX - this.$el.offset().left
                        },
                        relativeChartAreaPos = {
                            pageY: pos.pageY - this.$chartArea.offset().top,
                            pageX: pos.pageX - this.$chartArea.offset().left
                        },
                        hoverClass = this._getHoverClass(relativeContainerPos),
                        pointerClass = 'pointer-' + hoverClass.replace('middle', ''),
                        hoverLabel = this._getHoverLabel(obj) !== '' ? '<h3>' + this._getHoverLabel(obj) + '</h3>' : '',
                        content = '<div class="' + pointerClass + '"></div>' + hoverLabel + '<h4>' + this._getHoverBody(obj) + '</h4>',
                        hoverPosition = this._getHoverPosition(relativeChartAreaPos, relativeContainerPos);

                    this.$chartHover.show();
                    this.$chartHover.html(content);

                    if (hoverPosition && hoverClass) {
                        this.$chartHover.css(hoverPosition).attr('class', hoverClass + '-hover chart-hover');
                    } else {
                        this.$chartHover.hide();
                    }
                } else {
                    this.$chartHover.hide();
                }
            },

            _getHoverBody: function(obj) {
                var percent = Utils.addCommas('' + (parseFloat(Utils.getNested(obj, 'series', 'percent') || 0).toFixed(0)));
                return percent + '%';
            },

            _getHoverLabel: function(obj) {
                return obj.series.label;
            },

            /**
             * Builds default flot options
             * @return {Object} flotOptions The flot options required for the charts.
             */
            getFlotOptions: function() {
                return {
                    series: {
                    },
                    grid: {
                        hoverable: true,
                        clickable: true
                    },
                    legend: {
                        show: false
                    }
                };
            },

            // Loop through each row to build the flot data array.
            getFlotData: function(dataset) {
                return _.map(dataset, function(value, index, dataset) {
                    var color =  this.mainColor;
                    if (dataset.length > 1) {
                        color = this.chartColors[index % this.chartColors.length];
                    }
                    return {
                        label: Utils.getNested(value, 'label') || '',
                        data: parseFloat(value.value),
                        color: color
                    };
                }, this);
            },

            destroy: function(removeEl, paused) {
                var flot = (this.graph.data() || {}).plot;
                if (flot && flot.shutdown) {
                    flot.shutdown();
                }
                BaseView.prototype.destroy.call(this, removeEl, paused);
            }
        });

        /**
         * Return view class.
         */
        return BaseChartView;
    });

