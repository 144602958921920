/**
 * @fileoverview Overlay App (always has a dormant app below it, ie. Cards).
 * @author erik.kallevig@f-i.com (Erik Kallevig)
 */
/*global Modernizr, define, console, require */
define('apps/overlay/overlay',[
    'jquery',
    'underscore',
    'backbone',
    'utils',
    'site-manager',
    'apps/simple-overlay',
    'state',
    'admanager',
    'managers/requestmanager',
    'modules/partner/story-transition-ad',
    'modules/partner/article-inline-ad',
    'adPosition',
    'meteredAdPosition',
    'partner/gravity-ad-article',
    'partner/digital-wrap',
    'managers/routemanager',
    'pubsub'
],
function(
    $,
    _,
    Backbone,
    Utils,
    SiteManager,
    SimpleOverlay,
    StateManager,
    AdManager,
    RequestManager,
    StoryTransitionAd,
    ArticleInlineAd,
    AdPosition,
    MeteredAdPosition,
    GravityAd,
    DigitalWrap,
    RouteManager,
    PubSub
) {
    'use strict';

    /**
     * App class.
     */
    var OverlayApp = SimpleOverlay.extend({

        // View element.
        el: '#overlay',

        // Events.
        events: function() {
            return $.extend({}, SimpleOverlay.prototype.events, {
                'click .overlay-content-arrows': 'nextArticleClick'
            });
        },

        /**
         * Initialize the view.
         * @param {Object=} options Init options.
         */
        initialize: function(options) {
            options = $.extend({
                inbetweenAdTemplate: '<div class="partner-inbetween transition-wrap">' +
                                        '<div class="partner-inbetween-content partner-placement"></div>' +
                                    '</div>'
            }, options);
            this.init = false;
            this.$doc = Utils.get('doc');
            this.$body = Utils.get('body');
            this.$win = Utils.get('win');
            _.bindAll(this, 'keyboardEvent', 'beforeInbetweenAdRender', 'afterPageReveal', 'loadTeadsAd');

            this.currentStoryIndex = -1;
            this.storyCollection = [];
            this.animationIndex = 0;
            this.setupSourcePoint();

            // Call base class initialize
            SimpleOverlay.prototype.initialize.call(this, options);
        },

        resizeHandler: function() {
            SimpleOverlay.prototype.resizeHandler.apply(this, arguments);
            if (this.inbetweenAd) {
                this.inbetweenAd.resizeAd(980, this.winSize.height);
            }
            if (this.$inbetweenPartner) {
                this.$inbetweenPartner.css(this.winSize);
            }
        },

        afterPageReveal: function(fromUrl, toUrl, paused, ViewClass) {
            SimpleOverlay.prototype.afterPageReveal.apply(this, arguments);
            this.$doc.on('keyup.' + this.cid, this.keyboardEvent);

            // Initial page load, could be ajax, could not be.
            if (!this.init) {
                this.initializeDom();
                this.init = true;
                this.updateArrowLinks(true);
                // ad scenarios
                // 1: transition_referrer -> StoryTransitionAd()
                // 2: !Modernizr.history -> StoryTransitionAd()
                // 3: StoryTransitionAd.getCurrentMeterCount() === 0 -> StoryTransitionAd.triggerAd()
                this.subviews.storyTransitionAd = new StoryTransitionAd({
                    el: this.$('.partner-overlay')
                });
            } else {
                // ajax only possible, Modernizr.history === true
                // ad scenario 4, prepare inbetween ads
                this.renderInbetweenAd();
                this.updateArrowLinks();
            }

            if (fromUrl !== null) {
                this.triggerEvent('storyCollectionLoaded', this.getStoryCollection(), this.currentStoryIndex);
            } else if (Utils.flag('disable_overlay_preload')) {
                this.fetchStoryCollection().done(_.bind(function() {
                  this.updateArrowLinks(true);
                  this.triggerEvent('storyCollectionLoaded', this.getStoryCollection(), this.currentStoryIndex);
                }, this));
            }

            this.freeTrialCheck();

            // Load non-lazy placements
            this.loadAdPlacements();
        },

        loadAdPlacements: function() {
            var $highImpactEl = this.$('.partner-overlay-top-ad'),
                $spike = this.$('.partner-spike'),
                $wrap = this.$('.transition-wrap:first'),
                $spikeAdEl = this.$('.partner-spike'),
                pageInfo = StateManager.getActivePageInfo(),
                basePageType = pageInfo.basePageType,
                autoplayVideo = Utils.hasAutoplayVideo(pageInfo),
                isReviewed = (pageInfo && pageInfo.ssts) ? pageInfo.ssts.match('reviewedcom') : false,
                storyTopAdSection = (Utils.getNested(window.site_vars, 'ADS', 'story_top_ad', 'enabled_section') || '').split(','),
                comma_ssts = Utils.getNested(pageInfo, 'comma_ssts') || '',
                storyTopAd = Utils.getNested(window.site_vars, 'ADS', 'story_top_ad', 'enabled') || _.intersection(comma_ssts.split(','), storyTopAdSection).length > 0,
                playerTimeout = Utils.getNested(window.site_vars, 'ADS', 'partner_send2news', 'playerTimeout'),
                section_name = Utils.getNested(window.site_vars, 'ADS', 'partner_send2news', 'section_name'),
                subsection_name = Utils.getNested(window.site_vars, 'ADS', 'partner_send2news', 'subsection_name'),
                partnerVideo = this.$$('.partner-video'),
                oembedPlayer = this.$$('.story-oembed-sendtonews');

            //check to suppress breaking news on branded content pages
            this.header = SiteManager.getHeader();
            this.breakingbar = Utils.getNested(this.header, 'subviews', 'breakingbar');
            if (pageInfo && pageInfo.sponsor_story && this.breakingbar) {
                this.breakingbar.closeBreakingBar();
            }

            this.showSend2News = false;
            if (Utils.getNested(window.site_vars, 'ADS', 'partner_send2news', 'enabled') && !oembedPlayer.length) {
                this.showSend2News = (pageInfo.section_name && pageInfo.section_name.indexOf(section_name) !== -1) ||
                    (subsection_name && subsection_name.indexOf(comma_ssts.split(',')[1]) !== -1);
            }

            // don't load gravity or teads on sponsored series, or any series for that matter, or on roadblocks
            if(!pageInfo.sponsored_series && !pageInfo.sponsor_story && !pageInfo.series && pageInfo.ssts !== 'get-access') {
                if (storyTopAd && basePageType !== 'video-asset' && basePageType !== 'gallery-asset') {
                    this.subviews.billboardAd = new AdPosition({
                        el: $highImpactEl,
                        slot: $highImpactEl,
                        adPlacement : 'high_impact',
                        adSizes: Utils.getNested(window.site_vars, 'ADS', 'story_top_ad', 'sizes') || ['billboard', 'leaderboard', 'generic'],
                        adType: ['Billboard', 'Leaderbaord'],
                        onAdReady: function(adData, adType, adSize) {
                            var sizeClass = '',
                                height = (_.isArray(adSize)) ? adSize[1] : 0;

                            if (height > 1) {
                                switch (height) {
                                    case 90:
                                        sizeClass = 'is-leaderboard';
                                        break;
                                    case 250:
                                        sizeClass = 'is-billboard';
                                        break;
                                }
                                this.el.addClass(sizeClass).show();
                            }
                        }
                    });
                }

                if(!isReviewed && basePageType !== 'gallery-asset' && basePageType !== 'video-asset') {
                    this.subviews.gravityAd = new GravityAd({
                        el: this.$('.partner-gravity-ad'),
                        contentSection : this.$el,
                        slot: $highImpactEl
                    });
                }

                if(!pageInfo.sponsored_series && !pageInfo.sponsor_story && !pageInfo.series && !pageInfo.noadvertising && !isReviewed && $spikeAdEl.length) {
                    // setup a deferred object that will keep the inline ad from rendering if there is a gravity ad on page
                    var inlineDefer = $.Deferred();

                    // Article Inline Ad
                    this.subviews.inlineAd = new ArticleInlineAd({
                        el: $spikeAdEl,
                        adPlacement: 'native-article_link',
                        lazy: true,
                        deferred: inlineDefer
                    });


                    // Article Inline Ad should not appear on pages with Gravity, will only resolve the deferred if no gravity delivered
                    AdManager.getActiveSlotStatistics().done(_.bind(function(info) {
                        if (!_.findWhere(info.high_impact_info, {'adType': 'gravity'})) {
                            inlineDefer.resolve();
                        }
                    }, this));
                }

                if (partnerVideo.length && !(pageInfo.breaking === true && Utils.getNested(window.site_vars, 'ADS', 'breaking_news'))) {

                    // load in inform video ad if enabled and is unpublished. If inform fails to return, we call teads
                    if (!autoplayVideo && (pageInfo.asset_status && pageInfo.asset_status.toLowerCase() === "published") && !isReviewed) {
                        if (Utils.getNested(window.site_vars, 'ADS', 'partner_inform', 'enabled')) {
                            this.requirePartnerScript('inform').done(_.bind(function () {
                                var _informq = window._informq || [];
                                _informq.push(['embed']);
                                _informq.push(['hook', 'inform-placement-1/placementUnavailable', _.bind(this.loadTeadsAd, this)]);
                            }, this));
                        } else if (this.showSend2News) {
                            this.requirePartnerScript('send2news');
                            setTimeout(_.bind(function () {
                                if (!partnerVideo.length) {
                                    partnerVideo.remove();
                                    this.loadTeadsAd();
                                }
                            }, this), playerTimeout);
                        } else {
                            this.loadTeadsAd();
                        }
                    }
                }
            }


            // Digital wrap
            if(Utils.getNested(window.site_vars, 'ADS', 'digital_wrap') || Utils.getUrlParam('dw_debug')) {
                this.subviews.DigitalWrap = new DigitalWrap({
                    el: $highImpactEl,
                    slot : $highImpactEl,
                    container : $wrap,
                    backgroundEl : $wrap
                });

                this.subviews.sponsorLogo = new AdPosition({
                    el: this.$('.partner-sponsor-logo'),
                    adType : 'Sponsor Logo',
                    adSizes : [800, 40],
                    adPlacement : 'sponsor_logo'
                });
            }

            PubSub.trigger('advertising:defined');
        },

        loadTeadsAd: function() {
            var pageInfo = StateManager.getActivePageInfo();
            if (Utils.getNested(window.site_vars, 'ADS', 'teads_enabled')) {
                AdManager.getActiveSlotStatistics().done(_.bind(function(info) {
                    if (!_.findWhere(info.high_impact_info, {'adType': 'gravity'})) {
                        this.subviews.outstreamAd = new AdPosition({
                            el: this.$('.partner-outstream'),
                            adSizes: [1,1],
                            adPlacement: 'outstream_video',
                            adType: 'outstream',
                            pageInfo: pageInfo
                        });
                    }
                }, this));
            }
        },

        preloadPath: function(){
            // Determine which section front should load
            // behind depending on the story.
            StateManager.preloadPath(this.preloadedUrl).done(_.bind(function(){
                this.updateArrowLinks(true);
                this.triggerEvent('storyCollectionLoaded', this.getStoryCollection(), this.currentStoryIndex);
            }, this));
        },

        beforeInbetweenAdRender: function() {
            this.inbetweenAd.setInitialDimensions(980, this.winSize.height);
        },

        renderInbetweenAd: function(){
            var pageInfo = StateManager.getActivePageInfo();
            if (!this.$inbetweenPartner) {
                this.$inbetweenPartner = $(this.options.inbetweenAdTemplate);
                this.$el.append(this.$inbetweenPartner);
            } else if (!this.$inbetweenPartner.parent().length) {
                this.$el.append(this.$inbetweenPartner.hide());
            }

            if (!this.inbetweenAd) {
                this.inbetweenAd = new MeteredAdPosition({
                    el: this.$inbetweenPartner.find('.partner-inbetween-content'),
                    adPlacement: 'transition',
                    adSizes: [[1,1]],
                    beforeAdRender: this.beforeInbetweenAdRender,
                    rateMeterId: 'transition'
                });
            } else if (this.isShowingAd) {
                this.isShowingAd = false;
            }

            if (this.inbetweenAd.tickMeter(true)) {
                // check if gravity is currently on the page or we've hit a paywall roadblock
                // if so, we'll reset the meter instead of displaying
                AdManager.getActiveSlotStatistics().done(_.bind(function(info) {
                    if (_.findWhere(info.high_impact_info, {'adType': 'gravity'}) || pageInfo.ssts === 'get-access') {
                        this.inbetweenAd.resetMeter();
                    } else {
                        this.timeToShowAd = true;
                        this.inbetweenAd.refreshPosition();
                    }
                },this));
            }
        },

        keyboardEvent: function(e) {
            if (e.target !== this.$body[0] || window.pluto360Asset) {
                // we most likely are on an input field or there is a 360 video on page, skip it
                return;
            }
            switch(e.which) {
                case 37: // left
                    // trigger click event on left arrow
                    $('.overlay-content-arrows-previous-wrap').trigger('click');
                break;

                case 39: // right
                    // trigger click event on right arrow
                    $('.overlay-content-arrows-next-wrap').trigger('click');
                break;
            }
        },

        nextArticleClick: function(e) {
            var direction = 1,
                link = $(e.currentTarget),
                href = link.attr('href'),
                ad = this.inbetweenAd;
            if (link.hasClass('overlay-content-arrows-previous-wrap')) {
                direction = -1;
            }

            if(this.subviews && this.subviews.billboardAd) {
                this.subviews.billboardAd.hide();
            }

            if (!this.isShowingAd && this.timeToShowAd && ad.isAdReady()) {
                // we decrement the interval above, but don't reset it until we successfully show an ad so we need to tick it again
                ad.tickMeter();
                this.timeToShowAd = false;
                // time to show an ad, skip default behavior
                e.preventDefault();
                // TODO check with analytics to see what they want to track here
                // so the goal here is to update the browser url and internal variables to the next path,
                // but don't trigger state manager. This allows us to put ourselves inbetween two urls
                // so if the user decides to use the back arrow it'll take them to the correct place.
                if (RouteManager.goToInterstitual(href)) {
                    var collection = this.getStoryCollection();

                    // replace the back link with the article we just came from
                    link.siblings().remove();
                    this.arrowContainer.append(this.buildArrowDom(collection[this.currentStoryIndex], direction < 0 ? 'next' : 'previous'));
                    // we're between stories, set the story index appropriately
                    this.currentStoryIndex += (direction / 2);

                    ad.showAd(980, this.winSize.height);
                    return this.animateToContent(this.$inbetweenPartner, direction).done(_.bind(function() {
                        // async destruction test
                        if (ad) {
                            this.isShowingAd = true;
                            ad.playAd();
                        }
                    }, this));
                }
            }
        },

        initializeDom: function(){
            // Get template.
            this.arrowWrapTemplate = this.$('#overlay-arrow-wrap');

            // Cache container query.
            this.arrowContainer = this.$('.overlay-arrows');
        },

        destroyModules: function() {
            // Remove event handler for keyup
            this.$doc.off('.' + this.cid);
            this.$win.off('.' + this.cid);

            SimpleOverlay.prototype.destroyModules.apply(this, arguments);
         },

        beforeAppRemove: function(fromUrl, toUrl){
            if (this.inbetweenAd) {
                this.inbetweenAd.destroy();
                this.inbetweenAd = null;
            }
            SimpleOverlay.prototype.beforeAppRemove.apply(this, arguments);
        },

        /**
         * Update arrow links and toggle disabled state as necessary.
         */
        updateArrowLinks: function(initialLoad) {
            if (this.currentStoryIndex === -1){
                // make one last attempt to figure out what our index in incase something changed
                this.currentStoryIndex = this.getBestStoryIndex(window.location.pathname, initialLoad);
                if (this.currentStoryIndex === -1){
                    return;
                }
            }
            var collection = this.getStoryCollection(),
                leftArrow = this.buildArrowDom(collection[this.currentStoryIndex - 1], 'previous'),
                rightArrow = this.buildArrowDom(collection[this.currentStoryIndex + 1], 'next');
            this.arrowContainer.empty().append(leftArrow).append(rightArrow);
        },

        buildArrowDom: function(asset, direction) {
            var disabled = (!asset);
            if (!asset) {
                asset = {
                    disabled: 'disabled'
                };
            } else {
                asset.disabled = '';
            }
            asset.dir = direction;

            // Check for square photo.
            if (asset.photo && asset.photo.crops) {
                if (asset.photo.crops['1_1'] && asset.photo.crops['1_1'].indexOf('.jpg') !== -1) {
                    asset.image = asset.photo.crops['1_1'];
                }
            }
            if (!this.arrowWrapTemplate.length) {
                return $([]);
            }

            var arrow = $($.trim(_.template(this.arrowWrapTemplate.html(), asset)));
            if (disabled){
                if (this.isApple){
                    arrow.css({display: 'none'});
                }else{
                    arrow.css({'z-index': 1});
                }
            }else if (this.isApple){
                arrow.find('.preview').remove();
            }
            return arrow;
        },

        getBestStoryIndex: function(toUrl, initialLoad){
            if (toUrl && toUrl[0] !== '/'){
                toUrl = '/' + toUrl;
            }
            // there's a chance the same story could exist multiple times in the carousel
            // so we search based on the currentIndex
            var collection = this.getStoryCollection();
            if (!collection || collection.length === 0){
                return -1;
            }
            var min = -1, max = -1;
            var current = Math.round(this.currentStoryIndex); // we could be between stories
            if (current === -1){
                current = 0;
            }

            var i;
            for (i = current; i >= 0; i--){
                if (collection[i].links === toUrl){
                    min = i;
                    break;
                }
            }
            for (i = current; i < collection.length; i++){
                if (collection[i].links === toUrl){
                    max = i;
                    break;
                }
            }
            if (max === -1 && min === -1 && initialLoad){
                // Story not found. Add story to the beginning of
                // the preloaded front's collection.
                var story = {
                    "links": toUrl
                };
                this.storyCollection.unshift(story);
                return 0;
            }else if (max === -1){
                return min;
            }else if (min === -1){
                return max;
            }else{
                // we have a duplicate, find the article closests to the current
                if ((current - min) < (max - current)){
                    return min;
                }else{
                    return max;
                }
            }
        },

        fetchStoryCollection: function(section) {
            var previousFront = Utils.getSessionData('previousFront', {});
            section = section || previousFront.path || this.currentSection;
            if (!section) {
                return $.Deferred().reject();
            }
            section = (section === '/') ? "home" : section.replace(/^\/|\/$/g, '');
            return RequestManager.fetchData('/services/assets/collection/' + section, {}, false, false, false)
                .then(_.bind(function (assetCollection) {
                    this.storyCollection = _.filter(assetCollection, this._filterStoryCollection);
                }, this));
        },

        getStoryCollection: function(){
            if (this.storyCollection.length === 0) {
              var pageInfo = StateManager.getPreloadedPageInfo();
              var collection = pageInfo && pageInfo.asset_collection;
              if (collection) {
                  this.storyCollection = _.filter(collection, this._filterStoryCollection);
              }
            }
            return this.storyCollection;
        },

        /**
         * Filter stories based on headline, links, URL, and AppClass
         * @param {Object} story
         * @returns {Boolean}
         * @private
         */
        _filterStoryCollection: function (story) {
            var storyRouteInfo,
                storyAppClass,
                isSameApp;

            // let's check to make sure this one is in the same app
            if (story.links) {
                storyRouteInfo = RouteManager.getInfo(story.links);
                storyAppClass = storyRouteInfo && storyRouteInfo.app && storyRouteInfo.app.AppClass;
            }

            // Check it against the Overlay App
            isSameApp = storyAppClass === OverlayApp;

            return story.headline && story.links && !story.links.match('http') && isSameApp;
        },

        animateChangePagePreData: function(fromUrl, toUrl) {
            var direction, nextStoryIndex = this.getBestStoryIndex(toUrl);
            if (this.currentStoryIndex !== -1 && nextStoryIndex !== -1) {
                if (this.currentStoryIndex > nextStoryIndex) {
                    direction = 'left';
                } else {
                    direction = 'right';
                }
            }
            this.currentStoryIndex = nextStoryIndex;
            return this.animateToContent($(this.options.template).find('.transition-wrap'), direction);
        },
        animateToContent: function(newTransitionWrap, direction) {
            var winHeight = this.winSize.height,
                scrollPosition = Utils.getScrollPosition(),
                activeTransitionWrap = this.$('.transition-wrap:first');

            scrollPosition -= SiteManager.scrollTop(0);

            this.$el.css({position:'relative'});
            activeTransitionWrap.css({'overflow':'hidden'});
            this.prepareContentForTransition(activeTransitionWrap, scrollPosition, winHeight);

            newTransitionWrap.css({position: 'absolute', top: 0, display: 'block'});
            newTransitionWrap.attr('data-animation-index', ++this.animationIndex);
            this.prepareContentForTransition(newTransitionWrap, 0, winHeight);

            if(!newTransitionWrap.closest('body').length) {
                this.$el.prepend(newTransitionWrap);
            }

            if (this.header.isFixed()) {
                this.positionCloseButton('absolute', scrollPosition, activeTransitionWrap);
            }

            if (direction) {
                // aha, we know which direction to animate
                activeTransitionWrap.css({'left': '0%'});
                var activeDest, stagedDest = '0%';
                if (direction === 'left') {
                    newTransitionWrap.css('left', '-100%');
                    activeDest = '100%';
                } else {
                    newTransitionWrap.css('left', '100%');
                    activeDest = '-100%';
                }

                if(Utils.getNested(window.site_vars, 'flags', 'disable_overlay_animations')) {
                    activeTransitionWrap.css('left', activeDest);
                    newTransitionWrap.css('left', stagedDest);
                    this.postAnimate(newTransitionWrap, activeTransitionWrap);
                    return $.Deferred().resolve();
                } else {
                    return $.Deferred(_.bind(function(defer) {
                        var promise = $.when(this.animate(activeTransitionWrap, 'left', activeDest, 350, 'ease-in-out'),
                            this.animate(newTransitionWrap, 'left', stagedDest, 350, 'ease-in-out'));
                        promise.done(_.bind(function() {
                            this.postAnimate(newTransitionWrap, activeTransitionWrap);
                            defer.resolve();
                        }, this));
                    }, this)).promise();
                }
            } else {
                // No slideDirection specified, so fade/prev-story load required.
                // swap content neeeds the fadeIn to be position relative for height
                this.afterLoaderReveal();
                newTransitionWrap.css({position: 'relative'});
                return this.swapContent(activeTransitionWrap, newTransitionWrap);
            }
        },

        postAnimate: function(newTransitionWrap, activeTransitionWrap) {
            this.afterLoaderReveal();
            if (this.isShowingAd && this.inbetweenAd) {
                this.inbetweenAd.destroyAdPlacement();
            }
            activeTransitionWrap.remove();
            if (parseInt(newTransitionWrap.attr('data-animation-index'), 10) === this.animationIndex) {
                this.resetContentTransition(newTransitionWrap);
            }
        },

        prepareContentForTransition: function(transitionWrap, scrollPosition, winHeight){
            transitionWrap.css({height:winHeight});
            transitionWrap.children().css({'top': -1 * scrollPosition});
        },

        resetContentTransition: function(transitionWrap){
            transitionWrap.css({position:'relative'});
            this.$el.css({position:'', top: ''});
        },

        freeTrialCheck: function(){
            var pageUrl = window.location.href;
            if (pageUrl.indexOf('freeTrialComplete') > -1) {
                PubSub.trigger('freetrialtrack');
            }
        },

        setupSourcePoint: function() {
            // Sourcepoint messaging
            if (!window._sp_) {
                return;
            }
            if (Utils.getNested(window.site_vars, 'ADS', 'sourcepoint', 'onOverlay') && !window._sp_.displayed_message) {
                window._sp_.mms = window._sp_.mms || {};
                window._sp_.mms.cmd = window._sp_.mms.cmd || [];
                window._sp_.mms.cmd.push(function() {
                    window._sp_.mms.startMsg();
                });
            }
        },

        /**
         * Require Inform script
         * @returns {Deferred} a promise that resolves after it loads
         */
        requirePartnerScript: function(partnerName) {
            var requirePromise = $.Deferred();
            switch(partnerName) {
                case 'inform':
                    var req = require.config({
                        'paths': {
                            'partnerInform': Utils.getNested(window.site_vars, 'ADS', 'partner_inform', 'scriptUrl')
                        }
                    });

                    req(['partnerInform'], _.bind(function () {
                        requirePromise.resolve();
                    }, this));
                    break;
                case 'send2news':
                    var reqr = require.config({
                        'paths': {
                            'partnerSend2news': Utils.getNested(window.site_vars, 'ADS', 'partner_send2news', 'scriptUrl')
                        }
                    });

                    reqr(['partnerSend2news'], _.bind(function () {
                        requirePromise.resolve();
                    }, this));
                    break;
            }

            return requirePromise;
        }
    });

    /**
     * Return view class.
     */
    return OverlayApp;
});

