define('modules/longform/longform-series-bottom',[
    'jquery',
    'underscore',
    'baseview'
],

function (
$,
_,
BaseView
) {
    'use strict';
    /**
     * View class.
     */
    var LongformSeriesBottom = BaseView.extend({

        // View element
        el: '.longform-bottom-series',

        // Events
        events: {
            'click .series-bucket-prev' : 'doPrevTransition',
            'click .series-bucket-next' : 'doNextTransition'
        },

        /**
         * Initialize view.
         */
        initialize: function (options) {
            this.$seriesBucketSet = this.$(".series-bucket-item-set");
            this.$seriesBucketItem = this.$("div.series-bucket-item");
            this.$seriesBucketNext = this.$(".series-bucket-next");
            this.$seriesBucketPrev = this.$(".series-bucket-prev");
            this.$seriesBucketPrev.addClass("inactive-item");

            //Series module maintenance
            this.$bucketItemPadding = this.$seriesBucketItem.length * 10;
            this.$widthOfBucket = (this.$seriesBucketItem.width() * this.$seriesBucketItem.length) + this.$bucketItemPadding;
        },

        doNextTransition: function(e){
            //Make arrows unclickable during animation
            this.$seriesBucketNext.addClass("inactive-item");
            this.$seriesBucketPrev.addClass("inactive-item");

            var that = this;

            //Each image is 190px wide with 10px of padding. Scroll in increments of 200px.
            var $currentLeft = parseInt(this.$seriesBucketSet.css('left'), 10) - 200;

            if ((this.$widthOfBucket -(600 - this.$bucketItemPadding)) === Math.abs($currentLeft)){
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketPrev.removeClass("inactive-item");
                    });
            } else if((this.$widthOfBucket - 400) > Math.abs($currentLeft) ){
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketNext.removeClass("inactive-item");
                        that.$seriesBucketPrev.removeClass("inactive-item");
                    });
            }
        },

        doPrevTransition: function(e){
            //Make arrows unclickable during animation
            this.$seriesBucketNext.addClass("inactive-item");
            this.$seriesBucketPrev.addClass("inactive-item");

            var that = this;

            //Each image is 190px wide with 10px of padding. Scroll in increments of 200px.
            var $currentLeft = parseInt(this.$seriesBucketSet.css('left'), 10) + 200;

            if ($currentLeft === 0){
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketNext.removeClass("inactive-item");
                    });
            } else if ($currentLeft !== 200) {
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketPrev.removeClass("inactive-item");
                        that.$seriesBucketNext.removeClass("inactive-item");
                    });

            }
        }

    });

    return LongformSeriesBottom;
});
