/**
 * @fileoverview Insider view module.
 * @author rcopley@gannett.com (Ryan Copley) and Dave Burke (dburke2@gannett.com)
 */
define('modules/insider-teaser',[
        'jquery',
        'underscore',
        'baseview',
        'utils',
        'ui/generic-paginator'
    ],
    function(
        $,
        _,
        BaseView,
        Utils,
        GenericPaginatorView
    ) {

        'use strict';
        /**
         * View class.
         */

        var InsiderTeaser = BaseView.extend({
            /**
             * Initialize view.
             */
            initialize: function() {
                this.subviews = {};

                this.moveMe = this.$('.insider-teaser-items-wrap');
                this.viewport = this.moveMe.parent();

                _.bindAll(this, 'goTo');
                this.subviews.paginator = new GenericPaginatorView({
                    el: this.$('.paginator'),
                    onGoTo: this.goTo
                });
            },
            
            goTo: function(index) {
                var offsetBy = this.viewport.outerWidth(), targetOffset = offsetBy * index * -1;

                this.animate(this.moveMe, 'left', targetOffset+'px', 450, 'ease-in-out');

                Utils.lazyLoadImage(this.moveMe.find('img'));
            }
        });

        /**
         * Return view class.
         */
        return InsiderTeaser;
    }
);
