/**
* @fileoverview JS function for displaying popup details
* @author kklee@usatoday.com
*/
define('modules/sports/popup-details',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'state',
    'utils',
    'baseview',
    'modules/scroller/sidebar-scroll',
    'modules/sports/line-chart'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    StateManager,
    Utils,
    BaseView,
    SidebarScroll,
    LineGraph
) {
    var PopupDetails = BaseView.extend({

        el: '.interactive.sp-line-graph',

        // Events.
        events: {
            'click .sp-details-open': 'onDetailsOpen',
            'click .sp-details-close': 'onDetailsClose',
            'click .sp-filter-link.sp-popup-filter': 'onPopupFilter',
            'click .sp-graph-btn, .sp-table-btn': 'showGraph',
            'click .sp-conf-item': 'onConfSelect',
            'mouseenter .sp-head-item.teams-hover, .sp-hover-team-front-picker': 'onTeamsEnter',
            'mouseleave .sp-head-item.teams-hover, .sp-hover-team-front-picker': 'onTeamsLeave'
        },

        initialize: function() {
            BaseView.prototype.initialize.apply(this, arguments);
            _.bindAll(this, 'loadDetails', 'onDetailsClose');
            this.$popupBox = this.$('.sp-details-box');
            this.$popupFilm = this.$('.sp-details-film');
            this.$popupTop = parseInt(this.$popupBox.css('top'), 10);
        },

        onDetailsOpen: function(e) {
            e.preventDefault();
            this.openedOnce = true;
            this.$popupLink = $(e.currentTarget);
            var heattrack = this.$popupLink.data('ht');
            var popupClass = this.$popupLink.data('class');
            var popupOffset = Utils.getScrollPosition();
            this.$popupBox.css('top', popupOffset + 'px');
            if (this.$popupBox.data('url') != this.$popupLink.data('url')) {
                // only AJAX load if the popup has changed from last call
                this.loadDetails();
            }
            this.$popupBox.addClass(popupClass);
            this.$popupFilm.show();
            this.$popupBox.show();
            $('.site-footer').css({'visibility': 'hidden'});
        },

        onDetailsClose: function(e) {
            if (!this.openedOnce) return;
            if (e) e.preventDefault();
            var popupClass = this.$popupLink.data('class');
            this.$popupBox.removeClass(popupClass);
            this.$popupBox.hide();
            this.$popupFilm.hide();
            this.$('.sp-details-data').addClass('sp-loading-details');
            this.$revenuesSportsCharts = null;
            this.$expensesSportsCharts = null;
            $('.site-footer').css({'visibility': 'visible'});
        },

        loadDetails: function() {
            this.$('.sp-details-loaded').remove();
            var url = this.$popupLink.data('url');
            this.$popupBox.data('url', url);

            StateManager.fetchHtml(url).done(_.bind(function(html){
                if (this.subviews.vscroll)
                    this.subviews.vscroll.destroy();
                if (html.find('.sp-details-loaded').length) {
                    this.$('.sp-details-data').removeClass('sp-loading-details');
                    this.$('.sp-details-data').html(html);
                    if (this.subviews.vscroll)
                        this.subviews.vscroll.destroy();
                    this.subviews.vscroll = new SidebarScroll({
                        el: this.$('.sp-scrollable-details')
                    });
                } else {
                    console.log('Unable to load details for: ' + url);
                }
            }, this));

            // Hide header and close button on team-hover
            if (this.$popupLink.data('class').indexOf('hover') >= 0) {
                this.$popupBox.find('.sp-details-close').hide();
            } else {
                this.$popupBox.find('.sp-details-close').show();
            }
        },

        //Filter Finances (Revenues and Expenses) dropdown
        onPopupFilter: function(e) {
            var $targetLink = $(e.currentTarget),
                selectedProfileDiv,
                $selector,
                $targetDropdown = $targetLink.closest('.sp-filter-dropdown'),
                $targetScrollWrap = $targetDropdown.find('.sp-filter-scroll-wrap'),
                heattrack = $targetLink.data('ht'),
                financesFilter = $targetLink.data('value'),
                filterName = $targetLink.data('filter'),
                $iconSelected = this.$('.sp-finances-icon');
                this.$popupDiv = this.$('.sp-salaries-profile-popup');

            //disable all the div
            this.$popupDiv.removeClass('active').addClass('inactive');

            if ($iconSelected.hasClass('table-on')) {
                selectedProfileDiv = financesFilter+'table-on';
                $selector = this.$('div[data-profile="'+selectedProfileDiv+'"]');
                $selector.removeClass('inactive').addClass('active');
                heattrack = heattrack + 'table';
            } else if ($iconSelected.hasClass('graph-on')) {
                    selectedProfileDiv = financesFilter+'graph-on';
                    heattrack = heattrack + 'graph';
                    if (filterName == 'Revenues') {
                        if (!this.$revenuesSportsCharts) {
                            this.$revenuesSportsCharts = this.$('.interactive.revenues-line-graph');
                            this.subviews.lgraph = new LineGraph({
                                    el: this.$revenuesSportsCharts
                            });
                        }
                        this.$collegeRevenueGraph.removeClass('inactive').addClass('active');
                        this.$collegeExpensesGraph.removeClass('active').addClass('inactive');
                    } else if (filterName == 'Expenses'){
                        if (!this.$expensesSportsCharts) {
                            this.$expensesSportsCharts = this.$('.interactive.expenses-line-graph');
                            this.subviews.lgraph = new LineGraph({
                                    el: this.$expensesSportsCharts
                            });
                        }
                        this.$collegeRevenueGraph.removeClass('active').addClass('inactive');
                        this.$collegeExpensesGraph.removeClass('inactive').addClass('active');
                    }
                }

            // Update active filter text.
            this.$('.sp-filter-btn').text(filterName);  //assigning the value on the selected dropdown

            //  Collapse dropdown:
            $targetScrollWrap.hide();
            _.delay(function(){ $targetScrollWrap.removeAttr('style'); }, 50);

            // Hides selected, and unhides previously selected
            $targetLink.parent().addClass('selected').siblings().removeClass('selected');

            if (heattrack) {
                PubSub.trigger('uotrack', heattrack);
            }
        },

        showGraph: function (event) {
            var heattrack = $(event.currentTarget).data('ht');
            var icontoggle = $(event.currentTarget).data('icon-value'),
                targetedGraph = icontoggle + '-' + this.$('.sp-filter-btn').text().toLowerCase(),
                popupFilterText = this.$('.sp-filter-btn').text();
                this.$revenuesTableOn = this.$('.revenuestable-on');
                this.$expensesTableOn = this.$('.expensestable-on');

                this.$collegeRevenueGraph = this.$('.collegeRevenueGraph');
                this.$collegeExpensesGraph = this.$('.collegeExpensesGraph');

            //searching all the profile popup and turn all off
            this.$salariesProfilePopupDiv = this.$('.sp-salaries-profile-popup');
            this.$salariesProfilePopupDiv.removeClass('active').addClass('inactive');

            //turn on the selected graph from the icon and the value from dropdown
            var $finGraphSelected = this.$('div[data-icon="'+targetedGraph+'"]');
            $finGraphSelected.removeClass('inactive').addClass('active');

            //Table Icon
            if (icontoggle == 'sp-profile-table') {
                if (popupFilterText == 'Revenues') {
                    this.$revenuesTableOn.removeClass('inactive').addClass('active');
                    this.$expensesTableOn.removeClass('active').addClass('inactive');
                } else if (popupFilterText == 'Expenses') {
                    this.$expensesTableOn.removeClass('inactive').addClass('active');
                    this.$revenuesTableOn.removeClass('active').addClass('inactive');
                }
                this.$('.sp-table-btn.sp-finances-icon').removeClass('table-off').addClass('table-on');
                this.$('.sp-graph-btn.sp-finances-icon').removeClass('graph-on').addClass('graph-off');

            } else if (icontoggle == 'sp-profile-graph') {
                this.$('.sp-graph-btn').removeClass('graph-off').addClass('graph-on');
                this.$('.sp-table-btn').removeClass('table-on').addClass('table-off');
                if (popupFilterText == 'Revenues') {
                    if (!this.$revenuesSportsCharts) {
                        this.$revenuesSportsCharts = this.$('.interactive.revenues-line-graph');
                        this.subviews.lgraph = new LineGraph({
                                el: this.$revenuesSportsCharts
                        });
                    }

                    this.$collegeRevenueGraph.removeClass('inactive').addClass('active');
                    this.$collegeExpensesGraph.removeClass('active').addClass('inactive');
                } else {
                    if (popupFilterText == 'Expenses'){
                        if (!this.$expensesSportsCharts) {
                            this.$expensesSportsCharts = this.$('.interactive.expenses-line-graph');
                            this.subviews.lgraph = new LineGraph({
                                    el: this.$expensesSportsCharts
                            });
                        }
                        this.$('.coordExpenses').removeClass('coordExpenses').addClass('coord');
                        this.$collegeRevenueGraph.removeClass('active').addClass('inactive');
                        this.$collegeExpensesGraph.removeClass('inactive').addClass('active');
                    }
                }
            }

            heattrack = heattrack + popupFilterText;
            if (heattrack) {
                PubSub.trigger('uotrack', heattrack);
            }
        },

        onConfSelect: function(e){
            var $selectConf = $(e.currentTarget);
            var heattrack = $selectConf.data('ht');
            var $conf_value = $selectConf.data('value');
            var $div_value = $selectConf.data('div');

            this.$popupBox.find('.conf_team_box').removeClass('active');
            this.$popupBox.find('.sp-conf-item').removeClass('active');
            //this.$popupBox.find('.conf_team_box').removeClass('active');
            this.$('.'+$conf_value).addClass('active');
            $selectConf.addClass('active');

            if (heattrack) {
                PubSub.trigger('uotrack', heattrack);
            }
        },

        onTeamsEnter: function(e) {
            this.$teamLink = $(e.currentTarget);
            if (this.$teamLink.hasClass('teams-hover')) {
                this.$teamTab = this.$teamLink.find('.sp-head-link');
                this.$teamTab.addClass('active-box');
                this.openedOnce = true;
                this.$popupLink = this.$teamLink.find('.sp-details-open');
                var popupClass = this.$popupLink.data('class');
                if (this.$popupBox.data('url') != this.$popupLink.data('url')) {
                    this.loadDetails();
                }
                this.$popupBox.addClass(popupClass);
                this.$popupBox.show();
                $.datepicker.dpDiv.css({'display': 'none'});
            }
            $('.card-container.card-container-primary-suspender-sidebar-page').css({'z-index': '1'});
        },

        onTeamsLeave: function(e) {
            var boxTop = this.$popupBox.offset().top;
            var boxLeft = this.$popupBox.offset().left;
            var boxWide = this.$popupBox.outerWidth();
            var boxTall = this.$popupBox.outerHeight();
            if ((e.pageX < boxLeft) || (e.pageX > (boxLeft +boxWide)) ||
                    (e.pageY < boxTop) || (e.pageY > (boxTop + boxTall))) {
                this.onDetailsClose();
                this.$teamTab.removeClass('active-box');
                $('.card-container.card-container-primary-suspender-sidebar-page').css({'z-index': '0'});
            }
        },

        destroy: function() {
            this.onDetailsClose();
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });

    /**
     * Create object and return it.
     */
    return PopupDetails;
});

