/**
* @fileoverview More Teams College module.
* @author tjrivera@usatoday.com (Tommy Rivera)
*/
define('modules/sports/sports-more-teams-college',[
    'jquery',
    'underscore',
    'backbone',
    'baseview',
    'bxslider'
],
function(
    $,
    _,
    Backbone,
    BaseView
) {

    /**
     * View class.
     */
    var MoreTeamsCollege = BaseView.extend({
        initialize: function() {
            BaseView.prototype.initialize.call(this, this.options);
            _.bindAll(this, 'setup', 'update');
            this.setup();
        },

        /**
         * Sets up module
         */
        setup: function() {
            var sliderOptions = {
                startSlide: 0,
                slideWidth: 150,
                maxSlides: 7,
                minSlides: 4,
                nextSelector : '.sp-more-teams-next-arrow-college',
                prevSelector : '.sp-more-teams-prev-arrow-college',
                nextText: 'next',
                prevText: 'prev',
                infiniteLoop : false,
                hideControlOnEnd : true,
                pager : false };
            if ($('#sp-more-teams-slider').data('options')) {
                sliderOptions = $('#sp-more-teams-slider').data('options');
                if (sliderOptions) {
                    // this converts the string values to booleans and numbers
                    $.each(sliderOptions, function (key, val) {
                        if (sliderOptions[key] === "true") {
                            sliderOptions[key] = true;
                        } else if (sliderOptions[key] === "false") {
                            sliderOptions[key] = false;
                        } else if (!isNaN(sliderOptions[key])) {
                            sliderOptions[key] = parseInt(sliderOptions[key], 10);
                        }
                    });
                }
            }
            $('#sp-more-teams-slider').bxSlider(sliderOptions);
        },

        /**
         * Updates module contents
         */
        update: function(){
            this.setup();
        },

        destroy: function() {
            BaseView.prototype.destroy.apply(this, arguments);
        }

    });

    return MoreTeamsCollege;
});

