/**
 * @fileoverview longform/longform sponsorship series module
 * @author Nick Blakey, nblakey@gannett.com
 */
 define('modules/longform/longform-featured-series',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'adPosition',
    'state',
    'modules/longform/longform-sponsorship'
],

function (
$,
_,
BaseView,
Utils,
AdPosition,
StateManager,
LongformSponsorship
) {
    'use strict';
    /**
     * View class.
     */
    var Longform_sponsor_series = BaseView.extend({

        // View element
        el: '.longform-top-series-bucket',

        // Events
        events: {
            'click .top-series-bucket-prev' : 'doPrevTransition',
            'click .top-series-bucket-next' : 'doNextTransition'
        },
        
        /**
         * Initialize view.
         */
        initialize: function (options) {

            _.bindAll(this, 'onAdReady');

            this.$win = Utils.get('win');

            options = $.extend({
                adPlacement: 'poster',
                adSizes: ['mediumrectangle'],
                adType: 'sponsorship',
            }, options);

            this.$sponsorshipAd = this.$(".poster-ad");
            this.$seriesBucketSet = this.$(".top-series-bucket-item-set");
            this.$seriesBucketItem = this.$("div.top-series-bucket-item");
            this.$seriesBucketNext = this.$(".top-series-bucket-next");
            this.$seriesBucketPrev = this.$(".top-series-bucket-prev");
            this.$currentSeriesArticleArrow = this.$(".arrow-down");
            this.$seriesBucketPrev.addClass("inactive-item-prev");
            
            this.$seriesBucketWrapper = this.$(".top-series-bucket-wrapper");
            this.$seriesBucketArrow = this.$(".arrow-wrapper");
            this.$seriesBucketItemTitle = this.$(".top-series-bucket-item-title");
            this.$seriesBucketItemWrap = this.$(".top-series-bucket-item-wrapper");

            this.$bucketItemPadding = this.$seriesBucketItem.length * 40;
            this.$widthOfBucket = this._setSeriesSetWidth();
            this._setSeriesHeight();
            this._findCurrent();
            
            BaseView.prototype.initialize.call(this, options);

            //Add scroll arrows if there are more than 3 related stories
            if(this.$seriesBucketItem.length > 3) {
                this._calcNavArrowSpacing();
            }

            this._buildAd();
        },

        doNextTransition: function(e){
            //Make arrows unclickable during animation
            this.$seriesBucketNext.addClass("inactive-item-next");
            this.$seriesBucketPrev.addClass("inactive-item-prev");

            var that = this;

            //Grabbing current left and subtracting 300 
            var $currentLeft = parseInt(this.$seriesBucketSet.css('left'), 10) - 300;

            if ((this.$widthOfBucket - 900) === Math.abs($currentLeft)){
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketPrev.removeClass("inactive-item-prev");
                    });
            } else if((this.$widthOfBucket - 600) > Math.abs($currentLeft) ){
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketNext.removeClass("inactive-item-next");
                        that.$seriesBucketPrev.removeClass("inactive-item-prev");
                    });
            }
            
        },

        doPrevTransition: function(e) {
            //Make arrows unclickable during animation
            this.$seriesBucketPrev.addClass("inactive-item-prev");
            this.$seriesBucketNext.addClass("inactive-item-next");

            var that = this;

            //move series bucket nav items right
            var $currentLeft = parseInt(this.$seriesBucketSet.css('left'), 10) + 300; 

            if ($currentLeft === 0){          
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketNext.removeClass("inactive-item-next");
                    });
            } else if ($currentLeft !== 300) {
                this.animate(this.$seriesBucketSet,'left', $currentLeft, 300, 'ease-out')
                    .done(function(){
                        that.$seriesBucketPrev.removeClass("inactive-item-prev");
                        that.$seriesBucketNext.removeClass("inactive-item-next");
                    });
            } 
        },

        _calcNavArrowSpacing: function(e) {
            //Calculate space needed to display arrows on either side of series bucket
            this.$seriesBucketPrev.css('margin-left', ((this.$win.width() - 850) / 2) - 100);
            this.$seriesBucketNext.css('margin-right', ((this.$win.width() - 850) / 2) - 100);

            //Unhide arrows - prevents arrows from loading at edges of page and then jumping to desired location
            this.$seriesBucketPrev.removeClass("hidden");
            this.$seriesBucketNext.removeClass("hidden");
        },

        _setSeriesSetWidth: function() {
            var scrollWidth = (this.$seriesBucketItem.width() * this.$seriesBucketItem.length) + this.$bucketItemPadding;
            var itemSetWidth = scrollWidth;

            this.$seriesBucketSet.css('width', itemSetWidth);
            this.$seriesBucketSet.removeClass("hidden");

            return scrollWidth;
        },

        _setSeriesHeight: function() {
            var seriesBucketHeight = this.$seriesBucketWrapper.height();
            var seriesArrowTop = parseInt(this.$seriesBucketArrow.css("top"));
            var totalItems = this.$seriesBucketItem.length;
            var index = 0;

            this.$seriesBucketItemTitle.each(function() {
                var seriesItemTitleHeight = $(this).height();
                if (seriesItemTitleHeight > 195) {
                    return false;
                } else {
                    index += 1;
                }
            });

            if (index == totalItems) {
                seriesBucketHeight = seriesBucketHeight - 24;
                seriesArrowTop = seriesArrowTop - 24;
                this.$seriesBucketWrapper.css('height', seriesBucketHeight);
                this.$seriesBucketArrow.css('top', seriesArrowTop);
            }

        },

        _findCurrent: function() {
            var articleNum = 0;
            var seriesLength = this.$seriesBucketItem.length;
            var that = this;
            //If the series has more than 3 articles, find the current article and scroll the series navigation
            // so it is the first of 3 in view.
            if (seriesLength > 3) {
                this.$seriesBucketItemWrap.each( function() {
                    if ($(this).data("currentarticle") && (articleNum < (seriesLength - 3))) {
                        that.$seriesBucketSet.css('left', (articleNum * (-300)));
                        if (articleNum >= 1) {
                            that.$seriesBucketPrev.removeClass("inactive-item-prev");
                        }
                        return false;
                    }
                    else if ($(this).data("currentarticle") && ((seriesLength - articleNum) <= 3)) {
                        that.$seriesBucketSet.css('left', ((seriesLength - 3) * (-300)));
                        that.$seriesBucketNext.addClass("inactive-item-next");
                        that.$seriesBucketPrev.removeClass("inactive-item-prev");
                        return false;
                    }
                    articleNum += 1;
                });
            }
        },

        _buildAd: function() {
            if (this.$sponsorshipAd.length && !this.subviews.sponsorAd) {
                this.subviews.sponsorAd = new AdPosition({
                    el: this.$sponsorshipAd,
                    adSizes: this.options.adSizes,
                    adPlacement: this.options.adPlacement,
                    adType: this.options.adType,
                    onAdReady: this.onAdReady
                });
            }
        },

        onAdReady: function(adData, adType) {
            if(this.subviews.sponsorshipAd) {
                this.subviews.sponsorshipAd.destroy();
            }
            this.subviews.sponsorshipAd = new LongformSponsorship($.extend({
                el: StateManager.getActiveApp().$('.longform-top-series-bucket')
            }, adData));
        }

    });
    

    return Longform_sponsor_series;
});
