/**
 * @fileoverview Simple Ad View
 */
define('modules/partner/simple-ad',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'adPosition'
], function(
    $,
    _,
    BaseView,
    StateManager,
    AdPosition
) {
    'use strict';
    /**
     * View class.
     */
    var SimpleAd = BaseView.extend({

        /**
         * @classdesc Simple Ad, a subclass of {@link baseview}. This is basic ad view instantiantion
         * @constructs SimpleAd
         * @extends baseview
         * @param {Object} options backbone options object
         *    @param {Object.<String, String>} [options.adPlacement] - placement in DFP (e.g. high_impact)
         *    @param {Object.<String, String>} [options.adSizes] - sizes the ad can render (e.g. mediumrectangle)
         *    @param {PageInfo} [options.pageInfo] - alternative to options.targeting, will build targeting out of a pageInfo object
         *    @param {Function} [options.onAdReady] - custom callback to run when ad has signaled it is ready to run
         */
        initialize : function(options) {
            options = $.extend({
                adPlacement : 'poster_front',
                adSizes : 'mediumrectangle',
                pageInfo : StateManager.getActivePageInfo()
            }, options);

            BaseView.prototype.initialize.call(this, options);
        },

        /**
         * Used to initiate an ad position
         * @private
         */
        _initAds : function() {
            var options = this.options,
                adSizes = options.adSizes;
            if (adSizes && typeof adSizes === 'string') {
                adSizes = adSizes.split(',');
            }
            var adOptions = $.extend({
                el : this.$el,
                adSizes : adSizes
            }, options);

            this.subviews.simpleAd = new AdPosition(adOptions);
        }
    });

    /**
     * Return view class.
     */
    return SimpleAd;
});

