/**
 * @fileoverview Blog Promo Module 
 * @author Tyler Weiss <tweiss@gannett.com>
 */
define('modules/fronts/blog-promo',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'utils',
    'ui/generic-paginator',
    'modules/fronts/featured-content'
],
    function(
        $,
        _,
        BaseView,
        StateManager,
        Utils,
        GenericPaginatorView,
        FeaturedContent
        ) {

        "use strict";
        /**
         * View class.
         */
        var BlogPromo = BaseView.extend({

             // View element.
            el: '.blog-promo-module',

            // Events.
            events: {
                'click .blogs-show-all': 'showAll',
                'click .close-all-blogs': 'showAll'
            },

            /**
             * Initialize view.
             */
            initialize: function(options) {
                
                options = $.extend({
                    transition : {
                        interval : 15000
                    },
                    displayNum: 4
                }, options);

                BaseView.prototype.initialize.call(this, options);

                this.$numberBlogs = this.$('.blog-promo-content-wrap').length;
                if(this.$numberBlogs > 0) {
                    this.$seeAllBtnWrap = this.$('.blogs-show-all-wrap');
                    this.$closeAllBtn = this.$('.promo-button-container');
                    this.$allBlogsContainer = this.$('.show-all-blogs');
                    this.$blogChangeContainerHeight =  this.$('.inner-show-all-blogs').height()+80; // + the padding

                    this.subviews.featuredcontent =  new FeaturedContent(options);
                }else{
                    this.hide();
                }
            },

            /**
             * Show all the blogs content 
             */
            showAll: function(){
                if ( this.$allBlogsContainer.height() === 0 ) {
                    this.animate(this.$allBlogsContainer, 'height', this.$blogChangeContainerHeight,350,'ease-in-out').done(_.bind(function(){
                        this.$closeAllBtn.show();
                    },this));
                    this.$seeAllBtnWrap.addClass("active");
                } else {
                    this.$closeAllBtn.hide();
                    this.animate(this.$allBlogsContainer, 'height',0,350,'ease-in-out');
                    this.$seeAllBtnWrap.removeClass("active");  
                }
                
            }
        });

        /**
         * Return view class.
         */
        return BlogPromo;
    }
);
