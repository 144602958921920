/**
 * @fileoverview Leader Rotate view.
 * @author Kent Lee <kklee@usatoday.com>
 */
define('modules/sports/sports-leader-rotate',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {

    var LeaderRotateView = BaseView.extend({

        initialize: function(options) {
            $.extend(options, {
                fadeInterval: 2000,
                rotateInterval: 7000
            });
            BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'rotateLeaders');

            this.leaderRotation = setInterval(this.rotateLeaders, this.options.rotateInterval);
        },

        rotateLeaders: function() {
            var fadeInterval = this.options.fadeInterval;
            var leaders = this.$('.sp-leader-group');
            var active = this.$('.sp-leader-group.active');
            var max = this.$('.sp-leader-group').length - 1;
            var next = active.next();
            if (active.index('.sp-leader-group') == max) {
                next = leaders.first();
            }
            active.fadeOut(fadeInterval, function() {
                next.fadeIn(fadeInterval, function() {
                    active.removeClass('active');
                    next.addClass('active');
                });
            });
        },

        destroy: function() {
            clearInterval(this.leaderRotation);
            BaseView.prototype.destroy.call(this);
        }

    });

    return LeaderRotateView;
});
