/**
 * @fileoverview Health view module.
 * @author mbriede@gannett.com (Matthew Briede)
 */
define('modules/weather/weather-interactive-map',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'utils'
],
    function(
        $,
        _,
        BaseView,
        StateManager,
        Utils
        ) {
        "use strict";
        /**
         * View class.
         */

        var weatherInteractiveMapModule = BaseView.extend({

            // View element.
            el: '.weather-interactive-map-module-wrapper',

            /**
             * Initialize view.
             * @param {Object} options
             */
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);

                Utils.requireSingleUseScript("http://tessera.intellicast.com/Client/Javascript/wxmap.loader.min.js");
            }
        });

        /**
         * Return view class.
         */
        return weatherInteractiveMapModule;
    }
);

