define('apps/cards/layouts',[], function(){
   return {
        'primary-suspender-sidebar-page': [
            {
                name: 'large',
                adClass: 'size-l',
                adTargeting : 'large',
                sidebarOpen: true,
                sidebarAds: true,
                sidebarWidth: 340,
                belowHeroAd: false,
                belowTheFoldAd: true,
                cardWidth: 1180,
                windowWidth: 1250
            },
            {
                name: 'medium',
                adClass: 'size-m',
                adTargeting : 'medium',
                sidebarOpen: true,
                sidebarAds: false,
                sidebarWidth: 240,
                belowHeroAd: true,
                belowTheFoldAd: true,
                cardWidth: 1080,
                windowWidth: 1150
            },
            {
                name: 'small',
                adClass: 'size-s',
                adTargeting : 'small',
                sidebarOpen: false,
                sidebarAds: false,
                sidebarWidth: 240,
                belowHeroAd: true,
                belowTheFoldAd: true,
                cardWidth: 840,
                windowWidth: 980
            }
        ],
        'primary-flex-sidebar-page': [
            {
                name: '1320w',
                adClass: 'size-xl',
                adTargeting : 'xlarge',
                sidebarOpen: true,
                sidebarAds: true,
                sidebarWidth: 360,
                belowHeroAd: false,
                belowTheFoldAd: true,
                primaryFlexWidth: 960,
                cardWidth: 1320,
                windowWidth: 1320,
                layout: 'flexCard'
            },
            {
                name: '1080w',
                adClass: 'size-m',
                adTargeting : 'medium',
                sidebarOpen: true,
                sidebarAds: true,
                sidebarWidth: 360,
                belowHeroAd: false,
                belowTheFoldAd: true,
                primaryFlexWidth: 720,
                cardWidth: 1080,
                windowWidth: 1150,
                layout: 'flexCard'
            }
        ],
       'primary-flex-no-sidebar-page': [
            {
                name: '1080w',
                adClass: 'size-m',
                adTargeting : 'medium',
                sidebarOpen: true,
                sidebarAds: true,
                sidebarWidth: 360,
                belowHeroAd: false,
                belowTheFoldAd: true,
                primaryFlexWidth: 720,
                cardWidth: 1080,
                windowWidth: 1150,
                layout: 'flexCard'
            }
        ],
        'front-list-sidebar-page': [
            {
                name: '1080w',
                adClass: 'size-m',
                adTargeting : 'medium',
                sidebarOpen: true,
                sidebarAds: true,
                sidebarWidth: 352,
                belowHeroAd: true,
                belowTheFoldAd: true,
                cardWidth: 1080,
                windowWidth: 1150
            }
        ]
    };
});
