define('template-utils',[
    'jquery',
    'underscore',
    'utils'
],
function($, _, Utils) {
    "use strict";
    /**
     * Utils to assist in front-end rendering of assets.
     * @exports template-utils
     * @author kefoster@gannett.com (Kevin Foster)
     */
    var TemplateUtils = function() {
        this.relUrlExp = new RegExp("https?:\/\/(www\.)?(" + Utils.getNested(window.site_vars, 'base_url') + "|usatoday.com)");
        // Section display name mappings
        this.sectionNames = $.extend({
            tv: "TV",
            mmajunkie: "MMAjunkie",
            ftw: "For The Win",
            entertainthis: "Entertain This!",
            usatodaycollege: "USA TODAY College",
            roadwarriorvoices: "Road Warrior Voices"
        }, Utils.getNested(window, 'section_display_names'));
    };
    TemplateUtils.prototype = {
        /**
         * Translates section based on section display name mappings (ex: 'nba' => 'NBA')
         * Uses the subsection if available
         * @param {String} section - Section/subsection (ex: 'sports/nba')
         * @returns {String}
         */
        getDisplaySection: function(section) {
            if (section) {
                var secSplit = section.split("/");
                if (secSplit.length > 1) {
                    section = secSplit[1];
                } else {
                    section = secSplit[0];
                }
                if (section in this.sectionNames) {
                    section = this.sectionNames[section];
                }
                return section.replace(/-/g, ' ');
            } else {
                return '';
            }
        },
        /**
         * Chooses which image source to use based availability and desired crop size.
         * @param {String} imgSrc - Source of original image
         * @param {String} width - Desired crop width
         * @param {String} height - Desired crop height
         * @param {Object} [crops] - Image crop sources
         * @param {String} [cropSize] - Desired crop aspect ratio ('16_9', '4_3', '1_1', '3_4', '9_16')
         * @param {String} [transformKey] - Transform key
         * @returns {String} Updated image source
         */
        getCroppedImage: function(imgSrc, width, height, crops, cropSize, transformKey) {
            // If a minimogrify crop source is available for the desired crop
            if (_.isObject(crops) && cropSize) {
                if (crops[cropSize] &&
                        (
                            crops[cropSize].indexOf('www.gannett-cdn.com/-mm-/') !== -1 ||
                            crops[cropSize].indexOf('www.gannett-cdn.com/presto/') !== -1
                        )
                    ) {
                    return this.resizeImageFastly(crops[cropSize], width, height, transformKey);
                }
            }
            if (imgSrc) {
                if (imgSrc.indexOf('www.gannett-cdn.com/-mm-/') !== -1) {
                    return this.resizeImageFastly(imgSrc, width, height, transformKey);
                }
                if (imgSrc.indexOf('wordpress.com') !== -1) {
                    return this.cropImageWP(imgSrc, width, height);
                }
            }
            // Couldn't find a crop so return the original image
            return imgSrc;
        },
        /**
         * Provides a cropped version of a WordPress-hosted image.
         * @param {String} imgSrc - Image source to be cropped
         * @param {String} width - Desired crop width
         * @param {String} height - Desired crop height
         * @returns {String} Updated image source
         */
        cropImageWP: function(imgSrc, width, height) {
            return imgSrc.replace(/\?.*/, '') + '?w=' + width + '&h=' + height + '&crop=1';
        },
        /**
         * Provides a resized version of the passed-in image.
         * @param {String} imgSrc - Image source to be resized
         * @param {String} width - Desired image width
         * @param {String} height - Desired image height
         * @param {String} [transformKey] - Fastly image transform key
         * @returns {String} Updated image source
         */
        resizeImageFastly: function(imgSrc, width, height, transformKey) {
            var sep = imgSrc.indexOf('?') !== -1 ? "&" : "?";
            return imgSrc + sep + ((transformKey) ? transformKey : "width=" + width + "&height=" + height + "&fit=bounds");
        },
        /**
         * Iterates over a list of assets and returns an array of asset ids based on their data attributes.
         * @param {jQuery} [$assets]
         * @returns {Array<String>}
         */
        getAssetIdsFromAssets: function($assets) {
            return _.chain($assets)
                .map(function(asset) {
                    return $(asset).attr('data-wp-asset-id') || $(asset).attr('data-asset-id');
                }).filter(function(asset) {
                    return asset !== undefined;
                })
                .value();
        },
        /**
         * Truncates a string to the specified length without cutting off the last word
         * @param {String} str String to be truncated
         * @param {Number} len Maximum length of string
         * @param {String} [suffix="&hellip;"] optional string to append at the end of the truncated string
         * @returns {String}
         */
        smartTruncate: function(str, len, suffix) {
            if(str && len) {
                if(str.length <= len) {
                    return str;
                }
                suffix = suffix || "&hellip;";
                var lastSpace = str.lastIndexOf(' ', len - 1),
                    cutIndex = lastSpace > 0 ? lastSpace : len;
                return str.substr(0, cutIndex) + suffix;
            }
        },
        /**
         * @param {String} url
         * @param {RegExp} [urlRegExp]
         * @returns {String}
         */
        relativizeUrl: function(url, urlRegExp) {
            urlRegExp = urlRegExp || this.relUrlExp;
            try {
                url = url.replace(urlRegExp, '');
            } catch(e) {
                // no op
            }
            return url;
        }
    };
    return new TemplateUtils();
});

