/**
 * @fileoverview Live VPC suspender module.
 * @author mbriede@gannett.com (Matthew Briede)
 */
define('modules/live-newscasts/live-newscasts',[
        'jquery',
        'underscore',
        'baseview',
        'modules/global/livevideo'
    ],
    function(
        $,
        _,
        BaseView,
        LiveVideo
    ) {
        /**
         * View class for the live newscasts module.
         */
        var LiveNewscasts = BaseView.extend({

            // Events.
            events: {
                'click .newscast-item a': 'onLiveNewscastsClick'
            },

            /**
             * Live newscasts click handler.
             * @param {Event} e Hover event.
             */
            onLiveNewscastsClick: function(e) {
                var href = $(e.currentTarget).attr('data-livevideoassetid');

                if (href) {
                    var liveVideo = new LiveVideo({
                        'service': '/services/livenewscasts/'
                    });

                    liveVideo.loadLiveVideo(href);
                    return false;
                }
            }
        });
        return LiveNewscasts;
    }
);

