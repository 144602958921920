/**
 * @fileoverview HomeFinderAd view module.
 * @author mguirat@gannett.com (Marouan BEN GUIRAT)
 */
define('modules/home-finder/home-ads',[
        'jquery',
        'underscore',
        'baseview',
        'state',
        'adPosition'
    ],
    function(
        $,
        _,
        BaseView,
        StateManager,
        AdPosition
    ) {

        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var HomeFinderAd = BaseView.extend({
            initialize: function(options) {
                BaseView.prototype.initialize.call(this);
                this.layoutType = options.layoutType;
            },
            isSidebarModule : function () {
                return (this.layoutType === 'sidebar');
            },
            isPrimaryWellModule : function () {
                return (this.layoutType === 'primary');
            },
            /**
             * Initialize view.
             */
            renderedAd : false,
            renderAd : function (){
                if (!this.renderedAd) {
                    this.renderedAd = true;
                    this.subviews.ads = [];
                    this.$('.mpa-hf-ads').each(_.bind(function(index, value){
                        this.subviews.ads[index] = this.createAdPosition(value);
                    },this));
                }
            },

            destroyAd : function () {
                _.forEach(this.subviews.ads, function(ad){
                    ad.destroy();
                });
                this.subviews.ads = [];
            },
            createAdPosition: function (el){
                var adEl = $(el);
                var adtag = adEl.data('adtag');
                var adSize = (!adtag.search('poster_front')) ? [[300,250], [300, 600]] : [300, 135];
                return new AdPosition({
                    el: adEl,
                    adSizes: adSize,
                    adPlacement: adtag,
                 });
            },
            handleCardSizeChange: function(cardInfo) {
                if (this.isSidebarModule()){
                    if (cardInfo.sidebarAds === true){
                        this.renderAd();
                    }else{
                        this.destroyAd();
                    }
                }else if (this.isPrimaryWellModule()){
                    var primaryLayout = this.$(".mpa-hf-wrap");
                    if (cardInfo.sidebarOpen === true){
                        primaryLayout.addClass("mpa-closed");
                        this.destroyAd();                        
                    }else{
                        primaryLayout.removeClass("mpa-closed");
                        this.renderAd();
                    }
                }
            },

            onCardWidthChange: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            },
            renderCardInfo: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            }
        });

        /*
         * Return view class.
         */
        return HomeFinderAd;
    }
);


