/**
* @fileoverview JS functionality for sport datatables
* combining datatables components from sports-card, back-to-top, popup-details
*/
define('modules/sports/sports-datatables',[
    'jquery',
    'underscore',
    'backbone',
    'utils',
    'baseview',
    'pubsub',
    'libs/jquery/plugins/jquery.dataTables'
],
function(
    $,
    _,
    Backbone,
    Utils,
    BaseView,
    PubSub
)
    {

        var DatatablesView = BaseView.extend({

            el: '#section_sports',

            events: {
                'click .show-more': 'showMore',
                'click .button-add-content.stats': 'showMoreRows',
                'click .sorting_asc, .sorting_desc': 'removeDefault',
                'click .sp-stats-view-all': 'scrollToSection'
            },

            initialize: function() {
                BaseView.prototype.initialize.apply(this, arguments);
                // Initialize the scroller
                this.scrollEl = Utils.get('scrollEl');
                // Initialize the data table sorter.
                // Fix Standings Streak Filter [sort on negatives, but show absolute]
                $.extend($.fn.dataTableExt.oSort, {
                    "title-numeric-pre": function ( a ) {
                        // check for title attribute has integer
                        var m = a.match(/data-value="*(-?[0-9\.]+)/);
                        // could be 1.5 games back, treat nulls as zeros
                        return m !== null && m.length == 2 ? parseFloat(m[1]) : 0;
                    },
                    "title-numeric-asc": function ( a, b ) {
                        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
                    },
                    "title-numeric-desc": function ( a, b ) {
                        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
                    },
                    "formatted-num-pre": function ( a ) {
                        if (a==="-") {
                            a = 0;
                        } else {
                            if (a==="--") {
                                a = -1;
                            } else {
                                a = a.replace( /[^\d\-\.]/g, "" );
                            }
                        }
                        return parseFloat( a );
                    },
                    "formatted-num-asc": function ( a, b ) {
                        return a - b;
                    },
                    "formatted-num-desc": function ( a, b ) {
                        return b - a;
                    },
                    "currency-pre": function ( a ) {
                        a = (a==="$") ? 0 : a.replace( /[^\d\.]/g, "" );
                        return parseFloat( a );
                    },
                    "currency-asc": function ( a, b ) {
                        return a - b;
                    },
                    "currency-desc": function ( a, b ) {
                        return b - a;
                    },
                    "height-pre": function ( a ) {
                        var x = a.split('-');
                        a = (x[1] < 10) ? x[0]+'0'+x[1] : x[0]+x[1];
                        return parseInt(a, 10);
                    },
                    "height-asc": function ( a, b ) {
                        return a - b;
                    },
                    "height-desc": function ( a, b ) {
                        return b - a;
                    }
                });

                // Push all columns with data-sort="asc,desc" into list for $matchSorts below
                var $ascSorts = [];
                $('th.sort-header').each(function() {
                    if ($(this).data('sort') == 'asc,desc' || $(this).data('sort') == 'asc')
                        $ascSorts.push(this.innerHTML);
                });

                var defaultSettings = {
                    "bAutoWidth": false,
                    "bFilter": false,
                    "bDestroy": true,
                    "bPaginate": false,
                    "bInfo": false,
                    "aoColumnDefs": [ { "sType": "formatted-num", "aTargets": ["_all"] } ],
                    "aaSorting": []
                };
                var autoRankSettings = {
                    "fnDrawCallback": function(oSettings) {
                        // Need table results fully expanded for re-numbering the counter column (below)
                        this.$showMore = $('.show-more[data-category="'+this.attr('data-category')+'"]');
                        if (!this.$showMore.hasClass('active')) {
                            this.$showMore.trigger('click');
                        }
                        // Re-number the rank column if the table is sorted
                        var that = this;
                        if (oSettings.bSorted) {
                            // When the stats are sorted, the ranks need to be independently sorted
                            // The default is typically descending via column X and ascending for ranks (opposite)
                            var $asc = $(this).find('.sorting_asc');   // Is there a sorting_asc TH?
                            var $desc = $(this).find('.sorting_desc');   // Is there a sorting_desc TH?
                            var dataDirection = 'desc';   // Default 1 to n
                            // Special sports stats which get same directional sorting as the column sorted
                            var $matchSorts = [
                                'ERA',
                                'XYZ'
                                ];
                            if ($ascSorts.length > 0) $matchSorts = $ascSorts;
                            var sortType = 'opposite';   // Default sort for rank column compared to sorted column (opposite/same)
                            // Match sorted column to any values in our array for setting rank sort to the same direction
                            $.each($matchSorts, function(key, value) {
                                if ($asc.text() === value || $desc.text() === value) {
                                    sortType = 'same';
                                }
                            });
                            // Get the current sorting direction of the data column
                            if ($asc.length !== 0) {
                                dataDirection = 'asc';
                            } else {
                                dataDirection = 'desc';
                            }
                            // if th.data-filter, only display tr.hasClass
                            var $filter = '';
                            if ($asc.length > 0)
                                $filter = $asc.attr('data-filter');
                            if ($desc.length > 0)
                                $filter = $desc.attr('data-filter');

                            var prev_value;
                            var sort_value;
                            var offset = 1;

                            if ((dataDirection === 'asc' && sortType === 'same') || (dataDirection === 'desc' && sortType === 'opposite')) {
                                if ($filter) {
                                    this.$('tr.'+$filter, {"filter":"applied"}).each(function(i) {
                                        prev_value = sort_value;
                                        sort_value = $(this).find('.sorting_1').html();
                                        if (prev_value !== undefined && sort_value !== undefined) {
                                            if (prev_value === sort_value) {
                                                offset -= 1;
                                            } else {
                                                offset = 1;
                                            }
                                        }
                                        that.fnUpdate(i + offset, this, 0, false, false);
                                    });
                                } else {
                                    this.$('tr.page', {"filter":"applied"}).each(function(i) {
                                        prev_value = sort_value;
                                        sort_value = $(this).find('.sorting_1').html();
                                        if (prev_value !== undefined && sort_value !== undefined) {
                                            if (prev_value === sort_value) {
                                                offset -= 1;
                                            } else {
                                                offset = 1;
                                            }
                                        }
                                        that.fnUpdate(i + offset, this, 0, false, false);
                                    });
                                }
                            } else {
                                $.fn.reverse = [].reverse;
                                if ($filter) {
                                    this.$('tr.'+$filter, {"filter":"applied"}).reverse().each(function(i) {
                                        prev_value = sort_value;
                                        sort_value = $(this).find('.sorting_1').html();
                                        if (prev_value !== undefined && sort_value !== undefined) {
                                            if (prev_value === sort_value) {
                                                offset -= 1;
                                            } else {
                                                offset = 1;
                                            }
                                        }
                                        that.fnUpdate(i + offset, this, 0, false, false);
                                    });
                                } else {
                                    this.$('tr.page', {"filter":"applied"}).reverse().each(function(i) {
                                        prev_value = sort_value;
                                        sort_value = $(this).find('.sorting_1').html();
                                        if (prev_value !== undefined && sort_value !== undefined) {
                                            if (prev_value === sort_value) {
                                                offset -= 1;
                                            } else {
                                                offset = 1;
                                            }
                                        }
                                        that.fnUpdate(i + offset, this, 0, false, false);
                                    });
                                }
                            }
                            // Rebuild active/inactive rows, if show-more-rows utilized
                            var pageItems = this.$('tr.page').data('items-per-page');
                            var activeRows = this.$('tr.active').length;
                            if (activeRows === 0) {
                                if (pageItems) {
                                    activeRows = pageItems;
                                } else {
                                    activeRows = 25;
                                }
                            } else if (activeRows % pageItems > 0) {
                                activeRows = pageItems * (parseInt(activeRows / pageItems) + 1);
                            }
                            if (activeRows > 1) {
                                this.$('tr.page').removeClass('active').addClass('inactive');
                                var filterClass= '';
                                if ($filter)
                                    filterClass = '.' + $filter;
                                this.$('tr.page'+filterClass).each(function(i) {
                                    if (i < activeRows) {
                                        $(this).removeClass('inactive').addClass('active');
                                    }
                                });
                            }
                            // Reset button if disabling group and there are still inactive rows
                            if (!$filter && this.$('tr.inactive').length > 0) {
                                $('.button-add-content.stats').removeClass('inactive');
                            }
                        }
                    },
                    "aoColumnDefs": [
                        { "bSortable": false, "aTargets": [0] },
                        { "sType": "formatted-num", "aTargets": ["_all"] }
                    ]
                };
                this.$('.sort').each(function() {
                    var aoColumns = [];
                    var $t = $(this);
                    var $settings;
                    if ($t.hasClass('custom-sort')) {
                        $t.find('thead tr > th.sort-header').each(function (i) {
                            if ($(this).attr('data-sort') && $(this).attr('data-stype')) {
                                aoColumns[i] = {
                                    "sType": $(this).attr('data-stype'),
                                    "asSorting": $(this).attr('data-sort').split(',')
                                };
                            } else if ($(this).attr('data-stype')) {
                                aoColumns[i] = { "sType": $(this).attr('data-stype') };
                            } else {
                                aoColumns[i] = this.innerHTML === "STRK" || this.innerHTML === "DIFF" ? { "sType": "title-numeric" } : null;
                            }
                        });
                        if ($t.hasClass('no-autorank')) {
                            // Disable the automatic ranking first column
                            $settings = $.extend( true, $settings, defaultSettings, { "aoColumns": aoColumns });
                        } else {
                            // Extend our default settings, to include dynamic config
                            $settings = $.extend( true, $settings, defaultSettings, autoRankSettings, { "aoColumns": aoColumns });
                        }
                    } else {
                        $t.find('thead tr > th.header').each(function (i) {
                            aoColumns[i] = this.innerHTML === "STRK" || this.innerHTML === "DIFF" ? { "sType": "title-numeric" } : null;
                        });
                        if ($t.hasClass('no-autorank')) {
                            // Disable the automatic ranking first column
                            $settings = $.extend( true, $settings, defaultSettings);
                        } else {
                            // Extend our default settings, to include dynamic config
                            $settings = $.extend( true, $settings, defaultSettings, autoRankSettings);
                        }
                    }
                    $t.dataTable($settings);

                    // Rather than addClass sp-stats-default-sort, sort based on the table data-sort
                    var sortCol = $t.attr('data-sort');
                    if (sortCol) {
                        if (!$.isNumeric(sortCol)) {
                            sortCol = sortCol.toLowerCase();
                            $t.find('th.sort-header').each(function(i) {
                                if ($(this).data('name') && $(this).data('name').toLowerCase() === sortCol) {
                                    sortCol = i;
                                } else if ($(this).text().toLowerCase() === sortCol) {
                                    sortCol = i;
                                }
                            });
                        }
                        if ($.isNumeric(sortCol)) {
                            var sortDir = $t.find('th:eq('+sortCol+')').attr('data-sort').split(',')[0];
                            $t.dataTable().fnSort([[sortCol, sortDir]]);
                        }
                    }
                });
            },

            showMore: function(event) {
                //Which 'Show All' button did the user click on the stats page?
                var button = event.currentTarget;
                var category = $(button).attr('data-category');
                //Tie the button to the proper tabular data via the cleverly written data-category attribute which connects the two.
                var stats = $('table.'+category);
                //Set button text values
                var text = $(button).find('span.text').text() == 'All' ? 'Less' : 'All';
                //Show or hide the remaining rows in connected table.
                stats.find('tr.hide').toggle();
                //TO DO: Toggle button text.
                //var text = $(this).text() == 'Email' ? 'Close' : 'Email';
                $(button).find('span.text').text(text);
                if ($(button).hasClass('active')) {
                    $(button).removeClass('active');
                } else {
                    $(button).addClass('active');
                }
                // Prevent IE from following '#' in href which creates an unwanted ':visited' state
                return false;
            },

            showMoreRows: function(event) {
                if ($(event.target).hasClass('inactive')) return false;
                this.$button = $(event.currentTarget);
                this.$button.addClass('loading').text(this.$button.attr('data-loading'));
                this.$table = this.$button.attr('data-table');
                var $nextPage = this.$('table.' + this.$table + ' .page.active:last').next();
                if (!$nextPage) {
                    $nextPage = this.$('table.' + this.$table + ' .page:first');
                }
                var ditemnum = $nextPage.attr("data-itemnum");
                var ditemperpage = $nextPage.attr("data-items-per-page");
                var count = Math.round(ditemnum+ditemperpage);
                PubSub.trigger('uotrack', Utils.getSectionPath(window.location.pathname) + 'moredatatablerows'+count);
                this.transitionMoreRows($nextPage, ditemperpage);
                event.preventDefault();
            },

            transitionMoreRows: function($nextPage, numrows) {
                var $filter = '';
                this.$('table.' + this.$table + ' th.sort-header').each(function(i) {
                    if ($(this).hasClass('sorting_desc') || $(this).hasClass('sorting_asc')) {
                        $filter = $(this).attr('data-filter');
                    }
                });
                for (var r=0; r<numrows; r++) {
                    if ($filter) {
                        while (!$nextPage.hasClass($filter) && $nextPage.hasClass('page')) {
                            $nextPage = $nextPage.next();
                        }
                    }
                    if ($nextPage.attr('data-itemnum')) {
                        this.displayNextRow($nextPage);
                        $nextPage = $nextPage.next();
                    } else {
                        this.$button.addClass('inactive');
                        break;
                    }
                }
                if (this.$('.page.inactive').length <= 0) {
                    this.$button.addClass('inactive');
                }
                this.$button.removeClass('loading').text(this.$button.attr('data-default'));
                PubSub.trigger('showmore:datatablerows');
            },

            displayNextRow: function($nextPage) {
                if (Modernizr.csstransitions) {
                    $nextPage.removeClass('inactive').addClass('active');
                } else {
                    $nextPage.animate({
                        'max-height': '5000px'
                    }, 200, function() {
                        $(this).removeClass('inactive').addClass('active').css('max-height', '');
                    });
                }
            },

            removeDefault: function(event) {
                //Remove the class which shades the statistics columns by default on initial page load
                $(event.currentTarget).closest('table').find('.sp-stats-default-sort').removeClass('sp-stats-default-sort');
                $('tr.playoff_cut').removeClass('playoff_cut');
            },

            scrollToSection: function(event) {
                // Desired UI behavior: Jump to table, highlight column, and expand all results
                // Pattern: No longer a one-to-one ratio from leaders to tables, so the targeted table and column need to come from the link
                // Step 1: Pull table and column from link data
                var $viewAllLink = $(event.currentTarget),
                    $allStatsTables = this.$('table'),
                    statsTable = $viewAllLink.attr('data-table'),
                    $targetStatsTable,
                    statsTableColumn = $viewAllLink.attr('data-column'),
                    statsTableSortDirection = $viewAllLink.attr('data-sort');
                // Convert data to lowercase for safer testing
                if (typeof(statsTableColumn) === 'string') {
                    statsTableColumn = statsTableColumn.toLowerCase();
                }
                // Convert table value to array position
                $allStatsTables.each(function(i) {
                    if ($(this).attr('data-category') === statsTable) {
                        statsTable = i;
                    }
                });
                // Step 2: Jump to corresponding table
                var $scrollPosition = $viewAllLink,
                    $statSet = $('.panel-header h2');
                if ($('.panel-header h2').length > 1) {
                    $statSet = $statSet.eq(statsTable);
                }
                // ... get the offset of the current element
                $scrollPosition = $statSet.offset().top;
                // ... animate the page the distance of our object
                this.scrollEl.animate({'scrollTop': ($scrollPosition - 50)},700);
                // Step 3: Highlight corresponding column
                if ($allStatsTables.length > 1) {
                    $targetStatsTable = $allStatsTables.eq(statsTable);
                } else {
                    $targetStatsTable = $allStatsTables;
                }
                // Remove any default highlighting
                $targetStatsTable.find('.sp-stats-default-sort').removeClass('sp-stats-default-sort');
                // NEW: Change data-column attribute mapping to column index for sorting and/or highlighting
                // The addition of .header class eliminates the additional column spans which are also th tags
                // NOTE: If there are two columns with the same title, this code will currently handle just the first one
                $targetStatsTable.find('th.header').each(function(i) {
                    if ($(this).data('name') && $(this).data('name').toLowerCase() === statsTableColumn) {
                        statsTableColumn = i;
                    } else if ($(this).text().toLowerCase() === statsTableColumn) {
                        statsTableColumn = i;
                    }
                });
                // Set column for dataTable or non-sortable table
                if ($targetStatsTable.hasClass('dataTable')) {
                    // Set var here.  Setting outside of conditional makes bad things happen to non-sortable tables... and innocent puppies die.
                    var oTable = $targetStatsTable.dataTable();
                    if (statsTableSortDirection === 'asc') {
                        oTable.fnSort([[statsTableColumn,'asc']]);
                    } else {
                        oTable.fnSort([[statsTableColumn,'desc']]);
                    }
                } else {
                    // If the table isn't sortable we at least need to highlight the cells in the column
                    $targetStatsTable.find('tr').each(function() {
                        $(this).find('td').eq(statsTableColumn).addClass('sp-stats-default-sort');
                    });
                }
                // Prevent IE from following '#' in href which creates an unwanted ':visited' state
                return false;
            }

        });

        return DatatablesView;
    }
);

