/**
 * @fileoverview HomeCarousel Apartments
 * @author mguirat@gannett.com (Marouan BEN GUIRAT)
 */
define('modules/home-finder/home-carousel',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'ui/generic-paginator'
],
function(
    $,
    _,
    BaseView,
    Utils,
    GenericPaginatorView
    ) {
    "use strict";
    /**
     * View class.
     */
    var HomeCarousel = BaseView.extend({
        /**
        * Events
        */
        events: {
        'click .mpa-hf-ft-next-btn' : 'nextHouse',
        'click .mpa-hf-ft-previous-btn' : 'previousHouse'
        },

        /**
         * Initialize view.
         */
        initialize: function(options) {
            options = $.extend({
                auto : true,
                time : 5000
            }, options);

            this.$featuredHouses           = this.$(".mpa-hf-wrap");
            this.$houses           = this.$featuredHouses.find(".mpa-hf-ft-block");
            this.totalHouses       = this.$houses.length;
            this.$paginatorBullets = this.$(".paginator-indicator-bullet");
            this.$activePaginator  = this.$('.paginator-indicator-target');
            this.subviews          = {};
            this.indexSelector     = 0;
            this.homesPerSlide     = 3;
            this.carousel = {
                houses: [],
                width: [0]
            };

            _.bindAll(this, 'goTo', 'goToNextHome');
            this.subviews.paginator = new GenericPaginatorView({
                el: this.$('.paginator'),
                onGoTo: this.goTo
            });

            this.createCarousel(this.$houses);

            this.controlsCarousel();


            // call base class initialize
            BaseView.prototype.initialize.call(this, options);

        },

        nextHouse: function(){
            this._getSlideIn();
            this._fadeInSlide();
        },

        previousHouse: function(){
            this._getSlideOut();
            this._fadeInSlide();
        },

        createCarousel: function(houses){
            var _this      = this;
            var pagerWidth = 0;

            $.each(houses, function(index){
                _this.carousel.houses.push(this);

                pagerWidth += _this._getWidth($(this));

                if ((parseInt(index)+1) % _this.homesPerSlide === 0) {
                    _this.carousel.width.push(pagerWidth);
                }
            });

        },
        resetAutoRotate:function(){
            clearInterval(this.slideChangeInterval);
            this.slideChangeInterval = setInterval(this.goToNextHome,this.options.time);
        },

        goToNextHome: function(){
            this.indexSelector++;
            this.goTo(this.indexSelector);
        },

        goTo: function(index){

            if(index >= (this.totalHouses / this.homesPerSlide)) {
                index = 0;
            }
            else if(index < 0) {
                index = this.totalHouses-1;
            }
            this.indexSelector = index;

            this.$paginatorBullets.removeClass("active");
            this.$(".paginator-indicator-bullet").eq(this.indexSelector).addClass("active");

            this.controlsCarousel();

            this.animate(this.$featuredHouses, 'right', this.carousel.width[index], 200, 'ease-in-out');

        },

        _fadeInSlide: function(){

            this.goTo(this.indexSelector);
        },

        controlsCarousel:function(){
            var prevHome = $(".mpa-hf-ft-previous-btn"),
                nextHome = $(".mpa-hf-ft-next-btn");

            prevHome.toggleClass("mpa-closed", (this.indexSelector === 0));
            nextHome.toggleClass("mpa-closed", (this.indexSelector == this.$activePaginator.length-1));

            if (this.$houses.length > this.homesPerSlide )
                this.resetAutoRotate();
        },

        _getSlideIn: function(){
            this.indexSelector = this.$paginatorBullets.filter('.active').parent().next().index();
        },

        _getSlideOut: function(){
            this.indexSelector = this.$paginatorBullets.filter('.active').parent().prev().index();
        },

        _getWidth: function(el){
            return el.outerWidth();
        },

        destroy: function(removeEl){
            clearInterval(this.slideChangeInterval);
            // call base class destroy
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });

    /**
     * Return view class.
     */
    return HomeCarousel;
}
);

