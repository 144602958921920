define('modules/longform/longform-sponsorship',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {
    'use strict';
        var LongformSponsorshipView = BaseView.extend(
        /**
         * @lends partner/longform-sponsorship.prototype
         */
        {

            logoTemplate: '<div class="sponsored-by-text"><%= sponsoredByText %></div><div class="top-series-bucket-header-sponsor"><img src="<%= imageLogo %>" alt="<%= imageLogoAlt %>"></div>',

            /**
             * @classdesc Handles the asset sponsorship ad functionality.
             * @constructs partner/asset-sponsorship
             * @author Chad Shryock <dshryock@gannett.com>
             */
            initialize: function(options) {
                options = $.extend({
                    imageSkinLeft: null,
                    imageSkinRight: null,
                    clickThru: null,
                    imageLogo: null,
                    imageLogoAlt: null,
                    sponsoredByText: 'sponsored by:'
                }, options);

                // call base class initialize
                BaseView.prototype.initialize.call(this, options);

                this.$adWrapper = this.$('.top-series-bucket-header-sponsor-wrapper');
                this.$header = this.$('.top-series-bucket-header');

                this.render();
            },

            destroy: function(removeEl) {
                if (this.$logo){
                    this.$logo.remove();
                    this.$logo = null;
                }
                BaseView.prototype.destroy.call(this, removeEl);
            },

            render: function() {
                this.setupLogo();
                this.showBar();
            },

            setupLogo: function() {
                //Center sponsor logo vertically
                if(this.$header.height() > 30){
                    this.$adWrapper.css("margin-top", this.$header.height() / 2 - this.$adWrapper.height() / 2);
                }                                
                this.$logo = $(_.template(this.logoTemplate, this.options));
                this.$adWrapper.html(this.$logo);
            },

            showBar: function() {
                this.$adWrapper.removeClass('hidden');
                this.$header.removeClass('hidden');
            }
        });

        /**
         * Return view class.
         */
        return LongformSponsorshipView;
    }
);

