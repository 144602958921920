/**
 * @fileoverview Poll Daddy view overlay.
 * @author bminton@gannett.com (Brandon Minton)
 */
define('modules/polldaddy-poll',[
    'jquery',
    'underscore',
    'baseview',
    'state'
],
    function(
        $,
        _,
        BaseView,
        StateManager
        ) {
        "use strict";
        /**
         * View class.
         */

        var PollModule = BaseView.extend({

            // View element.
            el: '.polls-primary-wrapper',

            // Events.
            events: {
                'click .pmp-view-results': 'toggleLink',
                'click .pmp-answer-option': 'submitVote'
            },
            /**
             * Initialize view.
             * @param {Object} options
             */
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);

                // cached page elements
                this.$q_wrapper = this.$('.poll-question-wrapper');
                this.$a_wrapper = this.$('.poll-answer-wrapper');
                this.$question = this.$('.pmp-answer-option');
                this.$toggle = this.$('.pmp-view-results');
                this.$bars = this.$('.pmp-bar');
                this.poll_id = this.$question.data('poll');
                this.viewResults();
            },

            /**
             * Displays the poll results
             */
            viewResults: function(e) {
                if (this.getVoteValue() === String(this.poll_id)) {
                    this.$a_wrapper.addClass('pmp-active');
                    this.$q_wrapper.remove();
                    this.$toggle.remove();
                }
            },

            toggleLink: function(e) {
                if (this.$a_wrapper.hasClass('pmp-active')) {
                    this.$a_wrapper.removeClass('pmp-active');
                    this.$q_wrapper.addClass('pmp-active');
                } else {
                    this.$q_wrapper.removeClass('pmp-active');
                    this.$a_wrapper.addClass('pmp-active');
                }
            },

            /**
             * Visually increase the blue bar widths on the page
             * @param {Data} data is the JSON returned from the voting submit
             */
            incBarWidths: function(data) {
                var percents = [],
                        i = 0;

                _.each(data.answers.answer, function(answer) {
                    percents.push(answer.percent);
                });
                $.each(this.$bars, function() {
                    $(this).width(percents[i]+'%');
                    i++;
                });
            },

            /**
             * Actual vote function
             * @param {MouseEvent} e is the element triggering this function
             */
            submitVote: function(e) {
                e.preventDefault();
                var $target = $(e.target),
                    vote_id = $target.data('vote'),
                    poll_id = $target.data('poll'),
                    that = this;

                if (this.getVoteValue() !== String(poll_id)) { // the user hasn't voted yet...or maybe they cleared their cookies
                    StateManager.fetchData('/polls/post/' + poll_id + '/' + vote_id + '/').done(_.bind(function(data) {
                        this.setVoteValue(poll_id);
                        this.incBarWidths(data);
                        that.viewResults();
                    }, this));
                }
            },

            /**
             * Set vote cookie with poll id.
             * @param {String} poll_id
             */
            setVoteValue: function(poll_id) {
                localStorage.setItem('polldaddy_pollvote', poll_id);
            },

            /**
             * Get vote from storage and return a poll id.
             */
            getVoteValue: function() {
                return localStorage.getItem('polldaddy_pollvote');
            }
        });

        /**
         * Return view class.
         */
        return PollModule;
    }
);
