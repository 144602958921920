define('modules/videos/pluto/pluto-embed',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function(
    $,
    _,
    BaseView,
    Utils
) {
    'use strict';
    var PlutoEmbed = BaseView.extend(
        {
            events: {
                'click .js-pluto-embed': 'showOverlay',
                'click .js-close-embed-overlay': 'closeOverlay',
                'click .js-embed-autoplay': 'onAutoplayCheck'
            },

            initialize: function(options){
                options = $.extend({
                    addEmbedBtn: null,
                    analytics: null,
                    baseUrl: null,
                    onEmbedClose: null,
                    onEmbedShow: null,
                    placementName: null,
                    siteDisplayName: null,
                    videoId: null
                }, options);

                BaseView.prototype.initialize.call(this, options);
                this.updateVideoId(options.videoId);

                if (options.addEmbedBtn) {
                    this.updateControlBar();
                } else {
                    this.$('.js-pluto-embed').css('display', 'inline-block');
                }
            },

            closeOverlay: function(e) {
                e.preventDefault();
                this.hideOverlay();
                if (this.options.onEmbedClose) {
                    this.options.onEmbedClose();
                }
            },

            onAutoplayCheck: function(e) {
                var autoplay = $(e.currentTarget).is(':checked');
                this.options.analytics.trackVideoEvents(autoplay ? '360EmbedCheckAutoplay' : '360EmbedUncheckAutoplay');
                this.setIframeCode(autoplay).selectCode();
            },

            hideOverlay: function() {
                this.$$('.pluto-embed-overlay').hide();
            },

            updateVideoId: function(id) {
                if (id) this.videoId = id;
            },

            /**
             * Display embed code for the current video
             */
            showOverlay: function(e) {
                e.preventDefault();

                if (this.options.onEmbedShow) {
                    this.options.onEmbedShow();
                }

                this.$$('.pluto-embed-overlay').show().css('visibility', 'visible');
                this.options.analytics.trackVideoEvents('HTML5PlayerEmbed');
                this.setIframeCode(false).selectCode();
            },

            selectCode: function() {
                this.el.querySelector('.embed-overlay-direct-link').select();
            },

            setIframeCode: function(autoplay) {
                var siteUrl = this.options.baseUrl || Utils.getNested(window.site_vars, 'base_url'),
                    siteName = this.options.siteDisplayName || Utils.getNested(window.site_vars, 'display_name'),
                    url;

                if (siteUrl.indexOf("www.") === -1) {
                    siteUrl = "www." + siteUrl;
                }
                url = '//' + siteUrl + '/videos/embed/' + this.videoId + '/?fullsite=true';
                if (autoplay) url += '&autoplay=true';
                if (this.options.placementName) url += '&placement=' + this.options.placementName;

                var iFrameCode = '<iframe title="' + siteName.toUpperCase() + ' - Embed Player" width="540" height="304" frameborder="0" scrolling="no" ' +
                    'allowfullscreen="true" marginheight="0" marginwidth="0" src=' + url + '></iframe>';

                this.$('.embed-overlay-direct-link').val(iFrameCode);

                return this;
            },

            updateControlBar: function() {
                this.$('.vjs-control-bar').append(
                    '<div class="embed-button js-pluto-embed vjs-control" role="button"><span class="vjs-control-text"></span></div>'
                );
            },

            destroy: function(){
                this.hideOverlay();
                BaseView.prototype.destroy.call(this);
            }
        }
    );

    return PlutoEmbed;
});
