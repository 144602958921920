/**
 * @fileoverview CareerBuilder Contact form
 * @author brad.smith@azcentral.com
 */
define('apps/career-builder-contact',[
    'jquery',
    'underscore',
    'base-app',
    'utils',
    'state',
    'pubsub'
],
function(
    $,
    _,
    BaseApp,
    Utils,
    StateManager,
    PubSub
    ) {

    var CareerBuilderContact = BaseApp.extend({
        afterPageReveal: function () {
            // updates eVar99 with LDSN
            PubSub.trigger('group_page_view', 'LDSN');

            StateManager.stopRefreshTimer();
        },
        beforeAppRemove: function() {
            StateManager.startRefreshTimer();
        }
    });
    return CareerBuilderContact;
}
);

