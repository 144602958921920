/*global FB:true*/
/**
 * @fileoverview Share tools module for automated topics pages
 * @author Kevin Foster <kefoster@gannett.com>
 */
define('modules/topics/topics-share-tools',[
    'jquery',
    'underscore',
    'baseview',
    'third-party-apis/facebook/facebook'
],
function (
    $,
    _,
    BaseView,
    Facebook
) {

    /**
     * View class.
     */
        var TopicShareTools = BaseView.extend({

        events: {
            'click .tpa-social-icon-facebook': 'onClickFB'
        },

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {

            _.bindAll(this, 'fbShareCallback');
            BaseView.prototype.initialize.call(this, options);

        },

        onClickFB: function(e){

            var obj = {
                method : 'feed',
                caption: $(e.currentTarget).data('link'),
                link: $(e.currentTarget).data('link'),
                picture: $(e.currentTarget).data('image'),
                name: $(e.currentTarget).data('title'),
                description: $(e.currentTarget).data('description'),
                actions: [{name: $(e.currentTarget).data('link'), link: $(e.currentTarget).data('link')}]
            };
            Facebook.loadScript().done(_.bind(function(){
                FB.ui(obj, this.fbShareCallback);
            }, this));

        },

        fbShareCallback: function(response) {
            // do nothing
        }

    });

    /**
     * Return view class.
     */
    return TopicShareTools;
});

