/**
 * @fileoverview Trending Map Module 
 * @author Matthew Briede <mbriede@gannett.com>
 */
define('modules/trending-map/trending-map',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
    function(
        $,
        _,
        BaseView,
        Utils
        ) {

        "use strict";
        /**
         * View class.
         */
        var TrendingMapModule = BaseView.extend({

             // View element.
            el: '.tmfwm-wrapper',

            /**
             * Initialize view.
             */
            initialize: function(options) {
                _.bindAll(this, 'processScroll');
                
                this.$win = Utils.get('win');

                this.$marketBubbles = this.$('.tmfwm-plot.tmfwm-market-bubble');
                this.$trendingBubbles = this.$('.tmfwm-plot.tmfwm-trending-bubble');

                if(this.isMapVisible()) {
                    this.showMarketBubbles();
                } else {
                    this.$win.on('scroll.' + this.cid, _.throttle(this.processScroll, 32));
                }
            },

            showMarketBubbles: function() {
                var that = this;
                this.animate(this.$marketBubbles,'opacity', 1.00, 400, 'ease-out').done( function() {
                    that.showTrendingBubbles();
                });
                this.animate(this.$marketBubbles,'transform', 'scale(1)', 800, 'ease-out');
            },

            showTrendingBubbles: function() {
                this.animate(this.$trendingBubbles,'opacity', 1.00, 600, 'ease-out');
                this.animate(this.$trendingBubbles,'transform', 'scale(1)', 800, 'ease-out');
            },

            isMapVisible: function() {
                return Utils.getScrollPosition() >= (this.$el.offset().top - 200);
            },

            processScroll: function() {
                if (this.isMapVisible()) {
                    this.showMarketBubbles();
                    this.$win.off('.' + this.cid);
                }
            },

            destroy: function() {
                this.$win.off('.' + this.cid);
                BaseView.prototype.destroy.call(this, true);
            }
        });

        /**
         * Return view class.
         */
        return TrendingMapModule;
    }
);
