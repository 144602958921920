/* global define */
/**
 * @fileoverview 404 / 500 view.
 * @author Robert Huhn
 */
define('apps/404',[
    'jquery',
    'underscore',
    'backbone',
    'state',
    'managers/routemanager',
    'pubsub',
    'apps/simple-overlay',
    'modules/fronts/most-popular-primary',
    'adPosition'
],
function(
    $,
    _,
    Backbone,
    StateManager,
    RouteManager,
    PubSub,
    SimpleOverlay,
    MostPopular404,
    AdPosition
) {
    "use strict";
    /**
     * View class.
     */
    var ErrorView = SimpleOverlay.extend({

        initialize: function(options) {
            _.bindAll(this, 'afterPageReveal');

            // call base class initialize
            SimpleOverlay.prototype.initialize.call(this, options);

            var adSizes = this.$el.data('sizes');
            if (adSizes) {
                adSizes = adSizes.split(',');
            }

            this.subviews.upperLeaderboard = new AdPosition({
                el: this.$('.error-leaderboard-admodule.top .leaderboard-ad'),
                adType : 'leaderboard_atf',
                adSizes : adSizes || ['leaderboard', 'billboard'],
                adPlacement : 'leaderboard_atf/error'
            });

            this.subviews.lowerLeaderboard = new AdPosition({
                el: this.$('.error-leaderboard-admodule.bottom .leaderboard-ad'),
                adType : 'leaderboard',
                adSizes : adSizes || ['leaderboard', 'billboard'],
                adPlacement : 'leaderboard_atf/error'
            });
            PubSub.trigger('advertising:defined');

            if (this.options.ajax){
                // we're not a state-managed object
                this.render(this.options.htmlFrag);
                StateManager.registerFullScreenView(this);
                this.afterPageReveal();
            }
            this.subviews.primary = new MostPopular404({
               el: this.$('.mppm-wrap') 
            }, options);
        },

        /**
         * Close overlay.
         */
        close: function(e) {
            if (e && e.target !== e.currentTarget) {
                // trap clicks inside the transition wrap to prevent them from closing the overlay
                return;
            }
            if (this.options.ajax) {
                StateManager.clearFullScreenView(this);
                this.destroy(true);
            } else {
                RouteManager.goTo(StateManager.getPreloadedUrl() || '/');
            }
            if (e) {
                return false;
            }
        }
    });
    return ErrorView;

});

