/**
 * @fileoverview Adding support for Bounce Exchange integration into Autoplay manager 
 */
define('modules/stories/bounce-exchange',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'managers/autoplay'
],
function(
    $,
    _,
    BaseView,
    Utils,
    AutoPlayManager
) {


        /**
         * View class.
         */
        var BounceExchange = BaseView.extend({
            
            initialize: function(options) {
                this.bxEnabled = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'BX', 'autoplayEnabled') || false;
                window.bxHaltLoad = false;
                if (this.bxEnabled) {
                    window.bxHaltLoad = true;
                    AutoPlayManager.register('bounce-exchange', this.autoplay);
                }
            },

            autoplay: function() {
                window.bxAutoplayEnable();
                return true;
            },

            destroy: function() {
                delete window.bxHaltLoad;
                BaseView.prototype.destroy.call(this);
            }


        });

        return BounceExchange;
});

