define('modules/videos/pluto/pluto-utils',[
    'jquery',
    'underscore'
],
function (
    $,
    _
) {
    "use strict";

    var PlutoUtils = function() {};

    PlutoUtils.prototype = {
        /**
         * Builds the data passed from html element
         * @returns {Object}
         */
        buildData: function (dom) {
            var data = dom.html() || {};
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }
            return data;
        },

        /**
         * Send postMessage data
         * @param {boolean} postMessageEnabled - check flag before executing
         * @param {object} otherWindow - parent to iframe or iframe
         * @param {string|object} message - data to pass
         * @param {string} [targetOrigin] - optional hostname, default is all "*"
         */
        setPostMessage: function(postMessageEnabled, otherWindow, message, targetOrigin){
            if(postMessageEnabled && otherWindow){
               var origin =  targetOrigin ? targetOrigin : '*';
                otherWindow.postMessage( message , origin);
            }
        },

        /**
         * Listen for postMessage data
         * @param {boolean} postMessageEnabled - check flag before executing
         * @param {string} data - can be string, object or array
         */
        getPostMessage: function(postMessageEnabled, data ){
            if(postMessageEnabled) {
                window.addEventListener('message', data, false);
            }
        },

        /**
         * @param {object} target
         * @param {object} event - method with optional parameter
         */
        postMessageEvent: function(target, event){
             var func = event.data[0],
                 param = event.data[1] || '';
             if(typeof target[func] === 'function' ) target[func](param);
        },

        removeListener: function (func) {
            window.removeEventListener('message', func, false);
        }
    };


    return new PlutoUtils();
});

