/**
 * @fileoverview Sports Gallery Carousel module view.
 * @author ikenticus (extends front-galleries)
 */
define('modules/sports/sports-front-galleries',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'modules/stories/share-facebook',
    'modules/fronts/front-galleries',
    'modules/sports/sports-front-carousel',
    'ui/loader',
    'adPosition'
],
function(
    $,
    _,
    BaseView,
    PubSub,
    ShareFacebook,
    FrontGallery,
    SportsFrontCarousel,
    Loader,
    AdPosition
) {
    'use strict';

    var SportsFrontGalleryView = FrontGallery.extend({
        sponsorshipAdThrottled: false,

        events: function() {
            var parentEvents = FrontGallery.prototype.events;
            if (_.isFunction(parentEvents)) {
                parentEvents = parentEvents();
            }
            return _.extend({},parentEvents,{
                'click .util-bar-flyout-nav-btn': 'onClickFlyoutNavBtn',
                'click .gallery-sidebar-close': 'closeSidebar',
                'click .fullscreen': 'launchFullscreen',
                'click .sidebar-gallery-btn, .thumb-link': 'clickPhoto'
            });
        },

        initialize: function(options) {
            this.$primary = $('.sports-front-galleries-primary');
            this.$module = $('.sports-front-galleries-module');
            this.$closer = this.$('.gallery-sidebar-close');
            this.$sidebar = this.$('.gallery-sidebar-ad');
            this.$counter = $('.gallery-count');
            this.$toolbox = this.$('.gallery-sidebar-toolbox');
            this.$cardfilm = $('.front-card-film');
            this.$cardcolor = $('.card-suspender-color');
            this.$suspender = $('.sports-front-galleries-suspender');

            this.$('.front-gallery.snapshot-placeholder').remove();
            this.$galleries = this.$('.front-gallery');

            this.$navItems = this.$('.js-fgs-item');
            this.overrideTaboola = (this.$('.js-fg-nav-list').data('taboolaOverride')) ? true: false;
            this.currentGallery =  this.$('.fgpfm-gallery.front-gallery.selected').attr('id');

            this.$titles = this.$('.gallery-sidebar-title');
            this.$partnerSlot = $('.companion-galleries-partner-slot');
            this.$sidebarBtn = this.$('.sidebar-gallery-btn');

            _.bindAll(this, 'switchGallery', 'setupAd', 'refreshAd', 'sidebarAds', 'resetGallery', 'clickPhoto');

            // call base class initialize
            BaseView.prototype.initialize.call(this, {});

            PubSub.on('carousel:switchSlide', _.bind(function() {
                this.refreshAd();
            }, this));

            PubSub.on('gallery:hideSidebar', _.bind(function() {
                this.hideSidebar();
            }, this));

            this.subviews.loader = new Loader({
                el: this.$galleries.parent()
            });

            if (this.$galleries.find(".slide.active").length) {
                var $gallerySelected = this.$(".front-gallery.selected");
                var $frontGalleries = this.$(".front-gallery");
                this.subviews['gallery' + $frontGalleries.index($gallerySelected )] = new SportsFrontCarousel({
                    ads: true,
                    linkPath: this.$navItems.filter('.selected').find('a').attr('href'),
                    el: $gallerySelected.find('.galleries'),
                    fullScreen: true,
                    onSidebarAfterReveal: this.setupAd,
                    sidebarAds: this.sidebarAds
                });
                this.empty= false;
            } else {
                var href = this.$navItems.first().find("a").attr("href");
                if (typeof href !== 'undefined' && href.length > 1) {
                    this.fetchData(href, 0, this.switchGallery);
                }
            }

            this.$('.util-bar-flyout-section').hide();
            this.subviews.facebook = new ShareFacebook({
                el: this.$('.util-bar-flyout-share')
            });
        },

        _initializeAds: function() {
            if (this.$sponsorshipAd) {
                return;
            }
            this.$sponsorshipAd  = $('<div class="partner-placement poster" data-monetization-id="sp-gallery" data-monetization-sizes="mediumrectangle"></div>');
            this.$partnerSlot.append(this.$sponsorshipAd);
        },

        renderCardInfo: function(currentCardInfo) {
            this.currentCardInfo = currentCardInfo;
        },

        onCardWidthChange: function(newCardInfo) {
            this.currentCardInfo = newCardInfo;
            if (this.sidebarAds()) {
                this.setupAd();
                if (this.$sidebar.hasClass('active')) {
                    PubSub.trigger('carousel:cardfilmActive');
                }
            } else {
                this.teardownAd();
                if (this.$sidebar.hasClass('active')) {
                    PubSub.trigger('carousel:sidebarClosed');
                }
            }
        },

        sidebarAds: function() {
            if (!this.currentCardInfo) {
                return false;
            } else {
                return this.currentCardInfo.sidebarAds;
            }
        },

        switchGallery: function(html, index, path) {
            // Show chosen gallery
            var $gallery = this.$galleries.eq(index);
            this.$galleries.removeClass('selected');
            $gallery.addClass('selected');

            // Show chosen gallery toolbar
            var $toolbar = this.$toolbox.eq(index);
            if (this.$toolbox.length > 1) {
                this.$toolbox.removeClass('selected');
            }
            $toolbar.addClass('selected');
            // deactivate all social-media items when switching
            this.$('.util-bar-flyout-nav-btn').removeClass('active');

            // Show chosen gallery title
            var $title = this.$titles.eq(index);
            if (this.$titles.length > 1) {
                this.$titles.removeClass('selected');
            }
            $title.addClass('selected');

            // Show chosen gallery selected caption
            var sidebar = this.$('.gallery-sidebar-ad .caption');
            var caption = $gallery.find('.active .gallery-viewport-caption').html();
            this.resetSections();
            sidebar.html(caption);

            if (html) {
                $gallery.html(html);
                var counter = '<div class="gallery-count"><div>' +
                    $gallery.find('.thumb-item').length +
                    '</div><div class="line">Photos</div></div>';
                $gallery.append(counter);
                this.$counter = $('.gallery-count');
            }
            if (!this.subviews['gallery' + index]) {
                var carousel = this.subviews['gallery' + index] = new SportsFrontCarousel({
                    ads: true,
                    el: $gallery.find('.galleries'),
                    fullScreen: true,
                    linkPath: path,
                    onSidebarAfterReveal: this.setupAd,
                    sidebarAds: this.sidebarAds
                });
                if (!this.empty) {
                    this.trackGalleryChange(carousel);
                    this.empty = false;
                }
            } else {
                this.trackGalleryChange(this.subviews['gallery' + index]);
                if (this.subviews['gallery' + index].subviews.gallery && this.subviews['gallery' + index].subviews.gallery.refreshSportsSponsorshipAd){
                    this.subviews['gallery' + index].subviews.gallery.refreshSportsSponsorshipAd();
                }
                this.teardownAd();
                this.setupAd();
            }
            $gallery.css({opacity: 0});
            this.animate($gallery, 'opacity', 1, 200);
        },

        launchFullscreen: function() {
            // invoke the fullscreen button on the carousel/controls view
            $('.front-gallery.selected .fullscreen').click();
        },

        setupAd: function() {
            var index = this.$(".front-gallery").index(this.$(".front-gallery.selected"));
            if (this.sidebarAds()) {
                this.adSetup = true;
                this._initializeAds();

                // set up ad
                var targeting = {};
                if (this.subviews['gallery' + index].subviews.gallery && this.subviews['gallery' + index].subviews.gallery.getAdTargeting) {
                    targeting = this.subviews['gallery' + index].subviews.gallery.getAdTargeting();
                }
                var galleryData = this.$(".front-gallery.selected .galleries").data();

                this.subviews.sponsorshipAd = new AdPosition({
                    el: this.$sponsorshipAd,
                    adPlacement: 'poster_gallery_companion',
                    adSizes: ['mediumrectangle'],
                    targeting: targeting,
                    subSection: galleryData.cst
                });
            }
        },

        // the following 2 functions mimic web-standard-apps.git: utility-bar-module-share.js
        onClickFlyoutNavBtn: function(e) {
            var $btn = $(e.currentTarget);
            var moduleSection = $(e.currentTarget).data('share-method'),
                galleryTitle = $(e.currentTarget).data('share-title'),
                galleryLink = $(e.currentTarget).data('share-link'),
                shareTrack = 'SportsGallery' + moduleSection;

            PubSub.trigger('uotrack', shareTrack);
            if (moduleSection) {
                this.showSection($btn.parent().parent(), moduleSection);
            }
            if (!this.$closer.hasClass('active')) {
                this.$closer.addClass('active');
                this.$cardfilm.removeClass('inactive');
                this.$module.css('z-index', 501);
                this.$suspender.css('background-color', this.$cardcolor.css('background-color'));
            }
            if (moduleSection == 'facebook') {
                this.$('.util-bar-share-message-facebook').data('link', galleryLink);
                this.$('.util-bar-share-message-facebook').data('title', galleryTitle);
                this.resetSections();
                this.$('.gallery-sidebar-ad .caption').empty();
                this.subviews.facebook.onClickPostBtn(e);
                e.preventDefault();
            }
        },

        showSection: function(toolbox, sectionName) {
            this.resetSections();
            this.activeSection = sectionName;
            this.$('.util-bar-flyout-nav-btn-' + sectionName).addClass('active');
            //this.$('.util-bar-flyout-section-' + sectionName).show();
        },

        resetSections: function() {
            this.$('.util-bar-flyout-nav-btn').removeClass('active');
            this.$('.util-bar-flyout-pane-success').hide();
        },

        resetGallery: function() {
            this.resetSections();
            this.$primary.removeClass('active');
            this.$closer.removeClass('active');
            this.$cardfilm.addClass('inactive');
            this.$sidebar.removeClass('active');
            this.$module.css('z-index', 'auto');
        },

        closeSidebar: function(e) {
            if (e){
                e.preventDefault();
                e.stopPropagation();
            }
            var w = parseInt(this.$sidebar.outerWidth() + 1, 10);
            var slideAnimation = null;
            if (this.$sidebar.hasClass('active')) {
                PubSub.trigger('carousel:sidebarClosed');
                // deactivate all social-media items when closing
                this.$('.util-bar-flyout-nav-btn').removeClass('active');
                slideAnimation = this.animate(this.$sidebar, 'right', 0, 250, 'ease-in-out');
                slideAnimation.done(_.bind(function() {
                    this.resetGallery();
                }, this));
                this.teardownAd();
            }
            this.$sidebarBtn.addClass('active');
        },

        hideSidebar: function() {
            var w = parseInt(this.$sidebar.outerWidth() + 1, 10);
            if (this.$sidebar.hasClass('active')) {
                this.$('.util-bar-flyout-nav-btn').removeClass('active');
                this.$sidebar.css({'right': 0, 'transition': ''});
                this.resetGallery();
                this.teardownAd();
            }
        },

        refreshAd: function() {
            if (this.subviews.sponsorshipAd && this.subviews.sponsorshipAd.refreshPosition && !this.sponsorshipAdThrottled) {
                this.subviews.sponsorshipAd.refreshPosition();

                // Throttle the ad call to once every 2 seconds (on a click)
                this.sponsorshipAdThrottled = true;
                setTimeout(_.bind(function() {
                    this.sponsorshipAdThrottled = false;
                }, this), 2000);
            }
        },

        teardownAd: function() {
            if (this.subviews.sponsorshipAd && this.adSetup) {
                delete this.adSetup;
                this.$sponsorshipAd.empty();
                this.subviews.sponsorshipAd.destroy();
                delete this.subviews.sponsorshipAd;
            }
        },

        clickPhoto: function(e) {
            if(this.$sidebarBtn.hasClass('active')) {
                if (e){
                    e.preventDefault();
                    e.stopPropagation();
                }
                var $gallerySelected = this.$(".front-gallery.selected");
                var $frontGalleries = this.$(".front-gallery");
                this.subviews['gallery' + $frontGalleries.index($gallerySelected)].openSidebar();
                this.$closer.addClass('active');
                this.$sidebarBtn.removeClass('active');
               if (!this.$primary.hasClass('active')) {
                    this.$primary.addClass('active');
                    }
                this.$cardfilm.removeClass('inactive');
            }
        },

        destroy: function(removeEl) {
            PubSub.off('carousel:switchSlide');
            PubSub.off('gallery:hideSidebar');
            FrontGallery.prototype.destroy.call(this, removeEl);
        }

    });

    return SportsFrontGalleryView;

});
