define('third_party_integrations/pong/pong-utils',[
    'jquery',
    'underscore',
    'utils',
    'pubsub'
],
function(
    $,
    _,
    Utils,
    PubSub
){
    'use strict';
    /**
     * Pong utility lib (Pong is a Gannett-built analytics service; creator: Jesiah McCann).
     * @exports pong-utils
     * @author Erik Kallevig <ekallevig@gannett.com>
     */
    var PongUtils = function() {
        this.rootUrl = Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'PONG', 'url');
        this.pubSub = {
            'analytics:event:pong': this.logCustomEvent
        };
        this.start();
    };
    PongUtils.prototype = {
        start: function() {
            PubSub.attach(this.pubSub, this);
        },
        stop: function() {
            PubSub.unattach(this.pubSub, this);
        },
        logCustomEvent: function(params) {
            if (!_.isEmpty(params)) {
                if (params.eventType == 'abTestImpression' && !params.abTestFail) {
                    var mappedParams = {};
                    if (params.abTestId) mappedParams.abId = params.abTestId;
                    if (params.abTestVariant) mappedParams.abVar = params.abTestVariant;
                    this.logAbTestImpression(mappedParams);
                }
            }
        },
        logAbTestImpression: function(params) {
            var image = new Image();
            image.src = this.rootUrl + 'events?eventType=abImpr&' + $.param(params);
        }
    };
    return new PongUtils();
});

