/**
 * @fileoverview Generic element handling expired credit card modal. 
 */
define('modules/global/expired-card',[
    'jquery',
    'underscore',
    'baseview',
    'pubsub',
    'state',
    'utils'
],
function(
    $,
    _,
    BaseView,
    PubSub,
    StateManager,
    Utils
) {


        /**
         * View class.
         */
        var ExpiredCard = BaseView.extend({

            events:{
                'click .cc-close_modal_link, .cc-remind-later-link': 'close',
                'click .cc-payment-button': 'updatePaymentClick'
            },

            /**
             * Initialize the view.
             */
            initialize: function(options) {

                this.licenseId = options.licenseId || '';

                this.body = Utils.get('body');
                
                StateManager.registerFullScreenView(this);

                this.buildModal();
            },

            buildModal: function() {
                var _this = this;

                StateManager.fetchHtml('/services/expired-license/').done(function(r) {
                    _this.modal_html = r;
                    _this.body.append(r);
                    _this.m_uiModal = $("#cc_modal");
                    _this.m_uiModalBgrnd = $("#lightbox");

                    _this.m_uiModal.show();
                    _this.m_uiModalBgrnd.fadeIn(300);
                    _this.setElement('#cc_modal');
                });
            },

            updatePaymentClick: function(e) {
                e.preventDefault();
                Utils.openPopup((e.currentTarget.href + '?licenseId=' + this.licenseId), screen.width, screen.height);
                this.close();
            },

            close: function() {
                if(this.m_uiModal) {
                    this.setLocalTimestamp();
                    StateManager.clearFullScreenView(); 
                    var bgrnd = this.m_uiModalBgrnd;
                    this.destroy(true); 
                    this.m_uiModalBgrnd.fadeOut(300, function(){bgrnd.remove();});
                    this.m_uiModal = null;
                    this.m_uiModalBgrnd = null;
                    this.modal_html = null;
                }
            },

            setLocalTimestamp: function() {
                var date = new Date();
                localStorage['expired-credit-card'] = date.getTime();
            },

            destroy: function(removeEl) {
                if (this.modal_html) this.modal_html.remove();
                BaseView.prototype.destroy.call(this, removeEl);
            }
        });

        /**
         * Return view class.
         */
        return ExpiredCard;
});

