/**
 * @fileoverview Member Guide view.
 * @author Ryan Copley (rcopley@gannett.com)
 */
define('apps/member-guide-apps',[
        'jquery',
        'base-app',
        'site-manager'
    ],
    function(
        $,
        BaseApp,
        SiteManager
    ) {
        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var MemberGuideAppsView = BaseApp.extend({

            events : {
                'click .js-mga-apps-icon-link' : 'showApp'
            },

            showApp: function(e){
                e.preventDefault();
                var el = $(e.currentTarget);
                var appNumber = el.data("app-number");
                this.$(".js-mga-apps-icon-link").removeClass("mga-icon-active");

                this.$(".js-mga-panel[data-app-number]").removeClass("mga-active-app");
                this.$(".js-mga-apps-icon-link[data-app-number="+appNumber+"]").addClass("mga-icon-active");
                if (appNumber === 0){
                    this.$(".js-mga-panel[data-app-number]").addClass("mga-active-app");
                }else {
                    this.$(".js-mga-panel[data-app-number="+appNumber+"]").addClass("mga-active-app");                    
                }

                var current_width = $(window).width();
                if(current_width < 670){
                    $("html, body").animate({
                        scrollTop: this.$(".mga-page-content").offset().top - 60 // the 60 is to negate the headers height
                    }, 500);
                }
            },
            /**
             * afterPageReveal view.
             */
            afterPageReveal: function() {
                $("body").addClass("mga-body").addClass("mga-body-subpage");
                $(".js-mga-container").addClass("mga-body-subpage").addClass("mga-section-inline");

            },
            beforeAppRemove: function() {
                $("body").removeClass("mga-body");
            }
        });

        /**
         * Return view class.
         */
        return MemberGuideAppsView;
    }
);

