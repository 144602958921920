/**
 * @fileoverview Launches Opinionlab feedback form.
 * @author akothapall@gannett.com (Amarnath Kothapalli)
 *
 */

define('modules/feedback-opinionlabs/feedback-opinionlabs',[
    'jquery',
    'underscore',
    'utils',
    'pubsub',
    'third-party-apis/opinionlabs',
    'baseview',
    'state'
],
function(
    $,
    _,
    Utils,
    PubSub,
    OpinionLab,
    BaseView,
    StateManager
) {

"use strict";

/**
 * View class.
 */
var FeedbackOpinionlabs = BaseView.extend({

    // Events.
    events: {
        'click .fbol-link': 'launchOpinionLabModal',
    },

    /**
     * Initialize the view.
     */
    initialize: function(options) {
        _.bindAll(this, 'launchOpinionLabModal', '_handleRefreshTimer');
        this.$feedbacklink = $('.fbol-link').data("analytics");
        BaseView.prototype.initialize.call(this, options);
    },

    launchOpinionLabModal: function(e) {
        PubSub.trigger('track', {
            label: this.$feedbacklink
        });
        OpinionLab.launchModal(e);
        StateManager.stopRefreshTimer();
        //Check every 30 seconds for the Opinion Labs modal
        this.timer = setInterval(this._handleRefreshTimer, 30000);
    },

    _handleRefreshTimer: function() {
        if(!$('.oo_cc_wrapper').is(':visible')) {
            StateManager.startRefreshTimer();
            clearInterval(this.timer);
        }
    }
});


/**
 * Return view class.
 */
return FeedbackOpinionlabs;

});

