/**
 * @fileoverview Eat-And-Drink view.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('apps/eat-and-drink',[
        'jquery',
        'underscore',
        'base-app',
        'pubsub',
        'state',
        'user-accounts/firefly-user-account'
    ],
    function(
        $,
        _,
        BaseApp,
        PubSub,
        StateManager,
        FireflyUserAccount)
    {
        var EatAndDrinkApp = BaseApp.extend({
            initialize: function() {
                this.pubSub = {
                    'user:statuschange': this._loginStatus
                };

                BaseApp.prototype.initialize.call(this);

                this.$body = $(".eada-body");
            },

            _loginStatus: function(accountName, loginStatus, userData) {
                if (accountName !== 'firefly') {
                    return;
                }

                if (loginStatus === 'loggedIn' && userData.hasMarketAccess === true) {
                    this.$body.addClass('eada-page-member').removeClass('eada-page-acquisition');
                } else {
                    this.$body.removeClass('eada-page-member').addClass('eada-page-acquisition');
                }
            },

            afterPageReveal: function() {
                // updates eVar99 with LDSN
                PubSub.trigger('group_page_view', 'LDSN');
                // updates eVar66
                PubSub.trigger('product_name', 'Premium Memberships');

                // stop refresh timer
                StateManager.stopRefreshTimer();
            },

            beforeAppRemove: function() {
                this.$body.removeClass('eada-page-member').removeClass('eada-page-acquisition');

                StateManager.startRefreshTimer();
            }
        });

        return EatAndDrinkApp;
    }
);

