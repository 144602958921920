define('modules/videos/pluto/pluto-share',[
        'jquery',
        'underscore',
        'baseview',
        'utils'
    ],
    function (
        $,
        _,
        BaseView,
        Utils
    ) {
        "use strict";

        return BaseView.extend({

            events: {
                'click .pluto-share-button': 'shareVideo',
                'click .close-share-overlay': 'closeShareOverlay',
                'click .close-360-share': 'hideShareBar'
            },

            initialize: function (options) {
                options = $.extend({
                    analytics: null,
                    analyticsEnabled: null,
                    controlBarSelector: null,
                    exitFullScreen: null,
                    isVideoPlaying: null,
                    layout: null,  // Either 'minimal' or 'full'.
                    onShare: null,
                    onClose: null,
                    playVideo: null,
                    pauseVideo: null,
                    placementName: null,
                    siteDisplayName: null,
                    twitterAccount: null,
                    videoData: null
                }, options);

                BaseView.prototype.initialize.call(this, options);

                this.enabled = true;
                this.open = false;
                this.$overlay = this.$('.pluto-share-overlay');
                this.onLoadedMetaData(this.options.controlBarSelector);
                this.updateShareMetadata();
                this.layout = this.options.layout || 'full';
            },

            onLoadedMetaData: function (controlBarSelector) {
                if (controlBarSelector) {
                    this.$(controlBarSelector).append(
                        '<div class="pluto-share-button js-pluto-share vjs-control" role="button"><span class="vjs-control-text"></span></div>'
                    );
                }
            },

            updateShareMetadata: function() {
                var shareUrl = this.options.videoData.short_url || this.options.videoData.full_url,
                    title = encodeURI(this.options.videoData.title),
                    twitterAccount = this.options.twitterAccount || Utils.getNested(window.site_vars, 'twitter', 'default_account'),
                    siteDisplayName = this.options.siteDisplayName || Utils.getNested(window.site_vars, 'display_name'),
                    facebookShareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + shareUrl,
                    twitterShareUrl  = "https://twitter.com/intent/tweet?url=" + shareUrl + "&text=" + title + "&via=" + twitterAccount,
                    googleplusShareUrl = "//plus.google.com/share?url=" + shareUrl,
                    emailShareUrl = "mailto:?subject=" + title + "&body=Check out this video on " + siteDisplayName + ": " + shareUrl;
                this.$overlay.find('.share-overlay-direct-link').val(shareUrl);
                this.$overlay.find('.pluto-share-facebook-link').attr('href', facebookShareUrl);
                this.$overlay.find('.pluto-share-twitter-link').attr('href', twitterShareUrl);
                this.$overlay.find('.pluto-share-google-plus-link').attr('href', googleplusShareUrl);
                this.$overlay.find('.pluto-share-mail-link').attr('href', emailShareUrl);
            },

            shareVideo: function(e) {

                e.preventDefault();

                if (this.enabled && !this.open) {

                    this.resumePlay = this.options.isVideoPlaying ? this.options.isVideoPlaying() : false;

                    this.showShareOverlay();
                    if (this.options.onShare) this.options.onShare();
                    if (this.options.analyticsEnabled) {
                        // this.layout = 'full' is true only when Share is called by PlutoVideo. Otherwise this.layout = 'minimal'
                        if (this.layout === 'full') {
                            this.options.analytics.trackVideoEvents($(e.target).hasClass('js-pvb-share') ? 'ScrollingPlayerShare' : 'HTML5PlayerShare');
                        } else {
                            this.options.analytics.trackVideoEvents('360PlayerShare');
                        }
                    }

                    if (this.options.pauseVideo) this.options.pauseVideo();
                    if (this.options.exitFullScreen) this.options.exitFullScreen();
                }
            },

            showShareOverlay: function() {
                this.open = true;

                if (this.layout === 'minimal') {
                    this.$('.pluto-share-button').addClass('close-360-share');
                }

                this.$overlay.show().css('visibility', 'visible');
            },

            hideShareOverlay: function() {
                this.open = false;
                this.$overlay.hide();
            },

            disableShare: function() {
                this.enabled = false;
            },

            enableShare: function() {
                this.enabled = true;
            },

            hideShareBar: function () {
                this.open = false;
                this.$overlay.css('visibility', 'hidden');
                this.$('.pluto-share-button').removeClass('close-360-share');
            },

            closeShareOverlay: function(e) {
                e.preventDefault();

                this.hideShareOverlay();

                if (this.resumePlay && this.options.playVideo) {
                    this.options.playVideo();
                }

                if (this.options.onClose) {
                    this.options.onClose();
                }
            },

            destroy: function() {
                this.hideShareOverlay();
                BaseView.prototype.destroy.apply(this, arguments);
            }
        });
    }
);

