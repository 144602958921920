/**
* @fileoverview Coaches Ballots
* @author kybaker@usatoday.com (Kyle Baker)
*/
define('modules/sports/sports-ballots',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'state',
    'utils',
    'baseview',
    'modules/interactives/line-chart',
    'modules/sports/popup-details',
    'modules/scroller/horizontal-scroll',
    'modules/scroller/sidebar-scroll'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    StateManager,
    Utils,
    BaseView,
    LineGraph,
    PopupDetails,
    HorizontalScroll,
    SidebarScroll
) {
    var BallotDetails = PopupDetails.extend({

        el: '.card-container',
        
        events: {
            'click .ballot-key': 'onBallotKeyClick', //changes the right panel
            'mouseenter .ballot-matrix-row': 'onMatrixRowEnter',
            'mouseenter .ballot-matrix-column': 'onMatrixColumnHover',
            'mouseleave .ballot-matrix-column': 'onMatrixColumnHover',
            'click .sp-details-film': 'onDetailsClose',
            'click .sp-graph-btn, .sp-table-btn': 'showGraph',
            'mouseleave .ballot-matrix-scrollable': 'onBallotMatrixHover',
            'mouseenter .ballot-matrix-scrollable': 'onBallotMatrixHover'
        },

        initialize: function() {
            PopupDetails.prototype.initialize.call(this, this.options);
            this.$activeBallotLink = this.$('.coaches-ballot .active');
            this.$pickerValuesBox = this.$('.ballot-picker-values');

            _.bindAll(this, 'ballotScrollers');
            this.ballotScrollers();

            // Matrix View
            this.$activeMatrixRow = this.$('.coaches-ballot .active-row');
            this.$activeMatrixColumn = this.$('.coaches-ballot .active-columnn');
            this.$activeRanks = [];
            this.$activeVotes = [];
            
            window.onresize = function (e) {
                this.$('.sp-ballot-matrix-table').removeAttr("style");
            };
            this.$activeBallotLink.click();
        },

        ballotScrollers: function() {
            var _this = this;
            this.$('.sp-scrollable-details').each(function(i) {
                if (_this.subviews['ballots'+i])
                    _this.subviews['ballots'+i].destroy();
                _this.subviews['ballots'+i] = new SidebarScroll({
                    el: this
                });
            });
        },

        onBallotKeyClick: function(e){
            e.preventDefault();
            
            var heattrack = $(e.currentTarget).data('ht');
            var ballotKeyText = $(e.currentTarget).data('id');
            this.$pickerValuesBox.addClass("loading-panel");
            this.$activeBallotLink.removeClass("active");
            this.$activeBallotLink = $(e.currentTarget);
            
            var url = this.$activeBallotLink.data('url');
            StateManager.fetchHtml(url).done(_.bind(function(html){
                this.$('.ballot-picker-values').html(html);
                this.$pickerValuesBox.removeClass("loading-panel");
                this.ballotScrollers();
            }, this));
            
            this.$activeBallotLink.addClass("active");
            
            heattrack = heattrack + ballotKeyText;
            if (heattrack) {
                PubSub.trigger('uotrack', heattrack);
            }
        },

        onBallotMatrixHover: function(e) {
            if (e.type === 'mouseenter') {
                var $popupScroll = $(e.currentTarget);
                this.subviews.scroll = new HorizontalScroll({
                    el: $popupScroll,
                    delayScroll: false
                });
                if (this.$('.horizontal-scroll') && this.$('.horizontal-scroll').css('display') != "none")
                    this.$('.sp-ballot-matrix-table').css('margin-bottom', '7px');
            } else {
                if (this.subviews.scroll)
                    this.$('.sp-ballot-matrix-table').css('margin-bottom', '0px');
                    this.subviews.scroll.destroy();
            }
        },

        onMatrixRowEnter: function(e){
            e.preventDefault();
            //remove previous styles
            this.$activeMatrixRow.removeClass("active-row");
            $(this.$activeRanks).each(function(){
                $(this).removeClass('active-rank');
            });
            this.$activeRanks = [];
            //get the new targets, and assign styles
            this.$activeMatrixRow = $(e.currentTarget).parent();
            this.$activeMatrixRow.addClass("active-row");
            //get the data
            var rankings = this.$activeMatrixRow.data('votes');
            for (var r in rankings) {
                if (rankings[r]) {
                    $(rankings[r]).addClass('active-rank');
                    this.$activeRanks.push( $(rankings[r]) );
                }
            }
        },

        onMatrixColumnHover: function(e){
            e.preventDefault();
            this.$activeMatrixColumn = $(e.currentTarget);
            this.$activeMatrixColumn.toggleClass("active-column");
            
            $("." + this.$activeMatrixColumn.data('id')).each(function(){
                $(this).toggleClass("active-column");
            });
            
            if (e.type === 'mouseenter') {
                var ballot = this.$activeMatrixColumn.data('votes');
                for (var b in ballot) {
                    if (ballot[b]) {
                        $(ballot[b]).addClass('active-ballot');
                        $(ballot[b]).html(parseInt(b, 10)+1);
                        this.$activeVotes.push( $(ballot[b]) );
                    }
                }
            }
            else {
                //remove vote style
                $(this.$activeVotes).each(function(){
                    $(this).removeClass('active-ballot');
                    $(this).html("");
                });
                this.$activeVotes = [];
            }
        },

        showGraph: function (e) {
            var heattrack = $(e.currentTarget).data('ht');
            var icontoggle = $(e.currentTarget).data('icon-value'),
                targetedGraph = icontoggle + '-' + this.$('.sp-filter-btn').text().toLowerCase(),
                popupFilterText = this.$('.sp-subhead-name').text();
                this.$ballotWeeklyTable = this.$('.ballotWeeklyTable');
                this.$ballotWeeklyGraph = this.$('.ballotWeeklyGraph');

            //searching all the profile popup and turn all off
            this.$ballotWeeklyPopupDiv = this.$('.sp-ballot-weekly-popup');
            this.$ballotWeeklyPopupDiv.removeClass('active').addClass('inactive');

            //turn on the selected graph from the icon and the value from dropdown
            var $finGraphSelected = this.$('div[data-icon="'+targetedGraph+'"]');
            $finGraphSelected.removeClass('inactive').addClass('active');

            //Table Icon
            if (icontoggle == 'sp-profile-table') {
                this.$('.sp-table-btn.sp-ballot-weekly-icon').removeClass('table-off').addClass('table-on');
                this.$('.sp-graph-btn.sp-ballot-weekly-icon').removeClass('graph-on').addClass('graph-off');
                this.$ballotWeeklyTable.removeClass('inactive').addClass('active');
                this.$ballotWeeklyGraph.removeClass('active').addClass('inactive');
            }
            else if (icontoggle == 'sp-profile-graph') {
                this.$('.sp-graph-btn').removeClass('graph-off').addClass('graph-on');
                this.$('.sp-table-btn').removeClass('table-on').addClass('table-off');
                
                this.$ballotSportsCharts = this.$('.interactive.weekly-line-graph');
                this.subviews.lgraph = new LineGraph({
                    el: this.$ballotSportsCharts
                });
                this.$('.coordExpenses').removeClass('coordExpenses').addClass('coord');
                this.$ballotWeeklyTable.removeClass('active').addClass('inactive');
                this.$ballotWeeklyGraph.removeClass('inactive').addClass('active');
            }
            
            heattrack = heattrack + popupFilterText;
            if (heattrack) {
                PubSub.trigger('uotrack', heattrack);
            }
        }
    });
    return BallotDetails;
});

