define('blog/blog-front',[
    'jquery',
    'underscore',
    'backbone',
    'utils',
    'state',
    'site-manager',
    'pubsub',
    'base-app',
    'adPosition',
    'modules/partner/poster-ad-asset2',
    'partner/overlay-ad-fronts',
    'modules/global/brightcove-video'
],
function(
    $,
    _,
    Backbone,
    Utils,
    StateManager,
    SiteManager,
    PubSub,
    BaseApp,
    AdPosition,
    PosterAd2,
    OverlayAdFront,
    Video
) {

    'use strict';
        /**
         * View class.
         */
        var BlogView = BaseApp.extend({

            el: '.blt-content-container',

            afterPageReveal: function(fromUrl, toUrl) {

                if(this.$el.hasClass('blt-content-container')){
                    this._initNewBlogPage();
                } else {
                    this._initializeLegacyBlogPage();
                }

                StateManager.stopRefreshTimer();
            },

            _initNewBlogPage: function() {
                // Generic Ad Call for PointRoll
                this.subviews.sidebarAd = new AdPosition({
                   el: this.$('.partner-sidebar-ad'),
                   adSizes: ['mediumrectangle'],
                   adPlacement: 'poster_front',
                   adType: 'sidebar'
                });

                this.subviews.posterAd = new PosterAd2({
                    el: this.$('.blog-poster-scroll-ad-wrapper'),
                    rightRailSelector: '.blt-sidebar',
                    disableRefresh: false,
                    minTimeForAd: 15000
                });

                PubSub.trigger('advertising:defined');
            },

            _initializeLegacyBlogPage: function() {
                var _this = this;

                this.isNative = this.$el.hasClass('stag-native');

                this.subviews.video = [];
                // Initialize each video as its own video instance.
                $('.stag.hero .video').each(function() {
                    var video = new Video({
                        // Use .video parent() because hero markup is different.
                        el: $(this).parent().get(0)
                    });
                    _this.subviews.video.push(video);
                });

                this.subviews.overlayAd = new OverlayAdFront({
                    el: this.$('.partner-overlay'),
                    leaveBehindEl: this.$(!this.isNative ? '.partner-leavebehind' : '.stag-masthead-logo'),
                    isCardBumped: false,
                    isNative: this.isNative,
                    getHeaderOffset: function() {
                        var header = SiteManager.getHeader();
                        if (header) {
                            return header.getExpandedHeight();
                        } else {
                            return 0;
                        }
                    }
                });

                // Generic Ad Call for PointRoll
                this.subviews.genericad = new AdPosition({
                    el: $('#ad-staging'),
                    adSizes: ['generic'],
                    adPlacement: 'high_impact',
                    adType: 'generic',
                    slot : this.$('.partner-billboard-ad')
                });

                PubSub.trigger('advertising:defined');
            }

        });

        /**
         * Return view class.
         */
        return BlogView;
    }
);

