
/**
 * @fileoverview Search dropdown in the global navigation.
 * @author zaudette@gannett.com (Zac Audette)
 *
 */

define('modules/header/site-nav-search-module',[
    'modules/header/site-nav-dropdown'
],
function(
    SiteNavDropdown
) {

"use strict";
var SearchDropdownView = SiteNavDropdown.extend({

    initialize: function(options) {
        SiteNavDropdown.prototype.initialize.call(this, options);
    }

});


return SearchDropdownView;

});

