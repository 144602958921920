/**
 * @fileoverview Adding support for CAM messaging integration into Autoplay manager 
 */
define('modules/stories/cam-messaging',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'managers/autoplay',
    'state',
    'third-party-apis/firefly/firefly',
    'user-manager'
],
function(
    $,
    _,
    BaseView,
    Utils,
    AutoPlayManager,
    StateManager,
    Firefly,
    UserManager
) {
    var CamMessaging = BaseView.extend({
        
        initialize: function(options) {

            this.messagingPromise = $.Deferred();

            AutoPlayManager.register('cam-messaging', _.bind(function() {
                var journeyConfigUrl = Utils.getNested(window.site_vars, "cam_messaging", "api_url");
                StateManager.fetchData(journeyConfigUrl + Utils.getNested(window.site_vars, "site_code"), {
                    data: {
                        apiKey: Utils.getNested(window.site_vars, "cam_messaging", "api_key")
                    }
                }).done(_.bind(function(response) {
                    this.buildMessagingIframe(response);
                }, this));
                return this.messagingPromise;
            }, this));
        },

        buildMessagingIframe: function(response) {
            var iframeWrapper = $('.cam-messaging-wrapper'),
                pageInfo = StateManager.getActivePageInfo(),
                viewsCookie = Firefly.getFireflyViewsCookie(),
                userHasMarketAccess = false,
                cps = 'remainingViews',
                isProtectedAsset = true,
                journeyData = {};

            UserManager.getAccount('firefly').getUserInfo().always(_.bind(function(userInfo) {
                if(userInfo.hasMarketAccess) {
                    userHasMarketAccess = true;
                }
            }, this));

            if(pageInfo.content_protection_state == "free" || pageInfo.content_protection_state === "") {
                cps = 'free';
                isProtectedAsset = false;
            }

            if(viewsCookie) {
                journeyData = Utils.getNested(response, "journeys", "platform", "desktop", "segment", "base", cps, viewsCookie.viewsRemaining.toString());
            }
            
            // Loop through each message in journeyData (if there is a journey configured) and create iframes for each
            if(!$.isEmptyObject(journeyData)) {
                for(var idx = 0; idx < journeyData.length; idx++) {
                    var conditions = Utils.getNested(journeyData[idx], "alert", "conditions");

                    if(conditions.isCAMEnabled === Utils.getNested(window.site_vars, "THIRDPARTYAPI", "FIREFLY", "ENABLED") && conditions.isProtectedAsset === isProtectedAsset && conditions.hasMarketAccess === userHasMarketAccess) {
                        var iframeSrc = Utils.getNested(journeyData[idx], "alert", "embed"),
                            iframeClass = Utils.getNested(journeyData[idx], "alert", "type"),
                            iframe = $('<iframe scrolling="no" frameborder="0"></iframe>'),
                            trackType = '|meterreminderpushup';

                        iframe.attr('src', iframeSrc + '?alertType=' + cps + '&platform=desktop&segment=base&return=' + encodeURIComponent(window.location.href) + '&event=' + encodeURIComponent(window.utag_data.contenttype + '|' + window.utag_data.ssts + trackType)).attr('class', 'cam-alert-' + iframeClass);
                        iframeWrapper.append(iframe);
                    }
                }
                iframeWrapper.removeClass('hidden');
            }
                
            this.messagingPromise.resolve();
        }
    });

    return CamMessaging;
});

