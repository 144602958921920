define('modules/videos/pluto/pluto-video-gallery',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'modules/carousel/gallery',
    'modules/videos/pluto/pluto-wrapper',
    'modules/carousel/thumbs',
    'ui/tooltip',
    'modules/videos/pluto/pluto-analytics',
    'modules/videos/pluto/pluto-scroll',
    'modules/videos/pluto/pluto-utils',
    'modules/videos/pluto/pluto-sp'
],
    function (
        $,
        _,
        BaseView,
        Utils,
        CarouselGallery,
        PlutoWrapper,
        CarouselThumbs,
        Tooltip,
        PlutoAnalytics,
        PlutoScroll,
        PlutoUtils,
        PlutoSP
    ) {
        'use strict';

        /**
         * @lends videos/video-gallery.prototype
         */

        var PlutoVideoGallery = BaseView.extend({

            events: {
                'click .js-pvg-prev': 'onClickPrevArrow',
                'click .js-pvg-next': 'onClickNextArrow',
                'mouseleave .js-pluto-gallery-viewport': 'galleryMouseOut',
                'mouseenter .js-pluto-gallery-viewport': 'galleryMouseIn',
                'click .js-pluto-title-overlay-play, .ui-pluto-still' : 'onClickPlayCarousel'
            },

            /**
             * @classdesc Handles the functionality of all carousels that contain brightcove video players
             * @constructs videos/video-gallery
             * @param {Object} options backbone options object
             *     @param {jQuery|Element|String} options.el - element or string of the video carousel container
             *     @param {string} [options.viewportSelector] - a selector representing the container that will serve as the carousel's viewport
             *     @param {string} [options.slidesContainerSelector] - a selector representing the container that contains nested slides
             *     @param {string} [options.slidesSelector] - a selector representing all slides nested inside of the slidesContainerSelector
             *     @param {int} [options.hoverTransition] - if set to non-zero, will cause hovering over thumbs to switch to that slide
             */
            initialize: function (options) {

                options = $.extend({
                    activeSelector: '.vgm-thumb-item.active',
                    frontCarousel: false,
                    galleryAutoPlay: true,
                    hoverTransition: 0,
                    initializeThumbs: true,
                    initScroll: true,
                    scrollerColor: 'light',
                    slidesContainerSelector: '.gallery-slides',
                    slidesSelector: '.gallery-slide',
                    thumbsSelector: '.vgm-thumbs',
                    titleOverlay: false,
                    tooltipOptions: null,
                    viewportSelector: '.gallery-viewport'
                }, options);

                _.bindAll(this, '_getOnEndedMethod', '_transitionSlides', 'goToNextVideo', 'initPlutoVideo', 'initSubView', 'isRecommended',
                    'onAdEnd', 'onAdPlay', 'onAdVolumeChange', 'onEnded', 'onFullScreenChange', 'onGalleryPlay', 'onPause', 'onVideoPlay',
                    'onVolumeChange', 'slideInterval', 'updateScrollView');

                this.$viewport = this.$(options.viewportSelector);
                this.$slidesContainer = this.$viewport.find(options.slidesContainerSelector);
                this.$slides = this.$slidesContainer.find(options.slidesSelector);
                this.$arrows = this.$('.js-pvg-prev, .js-pvg-next');
                this.$title = this.$('.video-gallery-page-title');
                this.subviewPrefix = 'galleryItem';
                this.$active = this.$(options.activeSelector);
                this.sv = {};

                var baseViewOptions = $.extend(options, {animations: { useCSSTransitions: false}});
                BaseView.prototype.initialize.call(this, baseViewOptions);

                this.index = this.$active.index();
                this.autoplayNext = false;

                this.subviews.gallery = new CarouselGallery({
                    el: this.$el,
                    slideTransition: 200,
                    ads: false,
                    index: 0,
                    lookAheadAmount: 3,
                    transitionSlides: this._transitionSlides
                });

                if(this.options.initializeThumbs){
                    this._initializeThumbs(options.hoverTransition, options.scrollerColor, options.thumbsSelector);
                    this._initializeTooltips(this.$('.vgm-thumb-box'));
                }

                this.goTo(this.index);
                if(!this.slideChangeInterval) this.slideInterval(true);
            },

            /**
             * Setup the Thumbnail view
             * @param {number} hoverTransition - if set to non-zero, will cause hovering over thumbs to switch to that slide
             * @param {string} scrollerColor - theme for scroller, 'light' or 'dark'
             * @param {string} selector - selector used for the view
             * @private
             */
            _initializeThumbs: function(hoverTransition, scrollerColor, selector) {
                this.subviews.thumbs = new CarouselThumbs({
                    el: (selector ? this.$(selector) : this.$el),
                    carousel: this,
                    hoverTransition: hoverTransition,
                    index: this.index,
                    scrollerColor: scrollerColor
                });
            },

            /**
             * Initialize the Tooltip view
             * @param {Object} $items - list of elements that will get a Tooltip
             * @private
             */
            _initializeTooltips: function ($items) {
                _.each($items, function (element, index) {
                    this._setupTooltipView(element, $(element).data('duration'), index);
                }, this);
            },

            /**
             * Setup the Tooltip view
             * @param {String} el - element that will get a tooltip wrapper
             * @param {String} tooltipText - text to be displayed in the tooltip
             * @private
             */
            _setupTooltipView: function (el, tooltipText, galleryView) {
                var options = $.extend({
                    el: el,
                    customContainerClass: 'vgm-ui-tooltip',
                    position: 'bottom',
                    text: tooltipText
                }, this.options.tooltipOptions);
                var subviewName = this.subviewPrefix + galleryView;
                if (!this.subviews[subviewName]) {
                    this.subviews[subviewName] = new Tooltip(options);
                }
            },

            _getOnEndedMethod: function(index) {
                 return _.bind(function () {
                     this.onEnded(index);
                 }, this);
             },

            onEnded: function(index) {
                this.videoStart = 'auto-continuous';
                var currentSlide = this.getActiveSlideIndex(),
                    nextSlideIndex = currentSlide + 1,
                    totalSlides = this.$slides.length,
                    maxSlideIndex = totalSlides - 1,
                    notLastSlide = nextSlideIndex <= maxSlideIndex,
                    galleryLoop = this.getPlacementData(index).galleryLoop,
                    loop = galleryLoop && (nextSlideIndex === totalSlides);

                if (this.getActiveSlideIndex() !== index) {
                    // video on a non-active slide has begun playing, so put in background state!
                    this.enterBackgroundState(index);
                }

                if (notLastSlide || loop) {
                    this.goToNextVideo();
                    if (loop) this.slideInterval(true);
                }
                if (galleryLoop) this.autoplayNext = notLastSlide;
            },

            /**
             * When a user clicks on previous arrow.
             * @param {MouseEvent} e The event
             */
            onClickPrevArrow: function (e) {
                e.preventDefault();
                this.autoplayNext = false;
                var index = this.getActiveSlideIndex() - 1,
                    trackEvent = 'videoplaylist|prev';
                this.goToIndex(index, trackEvent);
            },

            /**
             * When a user clicks on next arrow.
             * @param {MouseEvent} e The event
             */
            onClickNextArrow: function (e) {
                e.preventDefault();
                this.autoplayNext = false;
                var index = this.getActiveSlideIndex() + 1,
                    trackEvent = 'videoplaylist|next';
                this.goToIndex(index, trackEvent);
            },

            /**
             * When a thumbnail is clicked, change the selected video
             * @param {Number} index - index of the thumbnail selected
             */
            switchSlide: function (index) {
                var currentIndex = this.getActiveSlideIndex();
                if (currentIndex !== index) {
                    this.goToIndex(index, 'videoplaylist' + (index + 1));
                }
            },

            /**
             * Initiates the transition to a slide / new video and triggers the appropriate tracking event
             * @param {Number} index - index of the video that needs to be initialized
             * @param {string} trackEvent - trackinfo for the action that triggered this event
             */
            goToIndex: function(index, trackEvent) {
                this.videoStart = 'user';
                if(this.getPlutoVideo(index-1)) this.getPlutoVideo(index-1).pauseVideo();
                if(this.getPlacementData().galleryLoop && (index === this.$slides.length) && (trackEvent === 'videoplaylist|next')){
                    index = 0;
                }
              this.goTo(index).done(_.bind(function () {
                    this.startVideo(index);
                    this.triggerEvent('goToSlide', index);
                    PlutoAnalytics.trackVideoEvents(trackEvent);
                }, this));
            },

            /**
             * Starts a video on a slide.
             * @param {Number} index - The index number of the slide that the video is on.
             */
            startVideo: function (index, e) {
                var placementData = this.getPlacementData(index),
                    plutoVideo = this.getPlutoVideo(index),
                    autoplay = placementData.galleryAutoPlay,
                    clickEvent = (e && e.type === 'click');

                if (autoplay || this.autoplayNext || clickEvent) {
                    if (index === 0 && !clickEvent) this.videoStart = 'auto-first';
                    this.slideInterval();
                    if (!plutoVideo){
                        this.initSubView(index, e);
                    }
                    this.getPlutoVideo(index).initPromise.done(_.bind(function() {
                        this.getPlutoVideo(index).playVideo();
                    }, this));
                }
            },

            /**
             * Puts a video player on a slide in an sleep state to reinstate later.
             * @param {Number} index - The index of the slide
             */
            enterBackgroundState: function (index) {
                var playerSubview = this.getPlayerSubview(index);
                if (playerSubview) {
                    playerSubview.destroy();
                    delete this.subviews['video' + index];
                }
            },

            /**
             * Goes to a specific slide.
             * @param {Number} index - The slide index to go to
             */
            goTo: function (index) {
                var previousIndex = this.getActiveSlideIndex(),
                    playerVolume, isPlayerMuted;
                // Check previous player volume for all videos except first one
                if (index !== 0 && !(this.options.frontCarousel)) {
                    if (this.getPlayerSubview(previousIndex)) {
                        var player = this.getPlayerSubview(previousIndex).sv.video.vjsPlayer;
                            playerVolume = player.volume();
                            isPlayerMuted = player.muted();
                    }
                }
                this._activeSlide = index;
                this.initPlutoVideo(index);
                // set volume to previous player level for all videos except the first one
                if (index !== 0 && !(this.options.frontCarousel)) {
                    // by this time, this._activeSlide has been updated, and vjsPlayer for the new subview has not been defined yet.
                    if (typeof(previousIndex) !== "undefined" && (previousIndex !== this._activeSlide)) {
                        var vjsPlayer = this.getPlayerSubview(this._activeSlide).sv.video.vjsPlayer;
                        if (isPlayerMuted) {
                            vjsPlayer.muted(true);
                        } else {
                            vjsPlayer.volume(playerVolume);
                        }
                    }
                }
                this.updateArrows(index);
                this.$titleOverlay = this.$('.js-pluto-title-overlay')[index];
                this.$playButton = this.$('.js-pluto-title-overlay-play')[index];
                if(this.options.frontCarousel){
                    this.$slides.removeClass('active');
                    this.$slides.eq(index).addClass('active');
                }
                return this.subviews.gallery.goToSlide(index).done(_.bind(function () {
                    if (previousIndex !== undefined) {
                        this.enterBackgroundState(previousIndex);
                        if(this.$titleOverlay) $(this.$titleOverlay).show();
                        if(this.$playButton) $(this.$playButton).show();
                    }
                }, this));
            },

            initSubView: function(index, e){
                this.isFirstPlaylistVideoMuted = index === 0 && this.getPlacementData(index).mutedAutoplay;
                this.subviews['video' + index] = new PlutoWrapper({
                    el: this.$('.ui-pluto-video')[index],
                    videoStart: this.videoStart,
                    initializeScroll: false,
                    isRecommended: this.isRecommended(),
                    onAdEnd: this.onAdEnd,
                    onAdPlay: this.onAdPlay,
                    onAdVolumeChange: this.onAdVolumeChange,
                    onEnded: this._getOnEndedMethod(index),
                    onFullScreenChange: this.onFullScreenChange,
                    onGalleryPlay: this.onGalleryPlay,
                    onPause: this.onPause,
                    onPlayback: this.onPlayback,
                    onVideoPlay: this.onVideoPlay,
                    onVolumeChange: this.onVolumeChange,
                    userPlayed: (e && e.type === "click"),
                    isFirstPlaylistVideoMuted: this.isFirstPlaylistVideoMuted
                });
            },

            initPlutoVideo: function(index){
                // setup subview if video element exists
                var subview = this.subviews['video' + index],
                    $el = this.$slides.eq(index).find('.ui-pluto-video'),
                    placementData = this.getPlacementData(index);

                if(this.options.frontCarousel) {
                    PlutoAnalytics.initAnalytics();
                }
                if (placementData.galleryAutoPlay && !subview && index !== undefined && $el.length) {
                    this.initSubView(index);
                    var plutoVideo = this.getPlutoVideo(index),
                        videoData = this.getVideoData(index);
                    if (this.options.initScroll && placementData.scrollingplayer){
                        if (Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'docked')) {
                            this.sv.sp = this.subviews.sp = new PlutoSP({
                                el: $el,
                                plutoVideo: plutoVideo
                            });
                        } else {
                            plutoVideo.initPromise.done(_.bind(function(videoPlayer) {
                                this.subviews.plutoScroll = new PlutoScroll({
                                    el: $el,
                                    placementData: placementData,
                                    plutoVideo: plutoVideo,
                                    videoData: videoData,
                                    videoPlayer: videoPlayer
                                });
                                this.sv.scroll = this.subviews.plutoScroll;
                                return this.sv.scroll;
                            }, this));
                        }

                        this.options.initScroll = false;
                    } else {
                        if(plutoVideo){
                            plutoVideo.initPromise.done(_.bind(function(videoPlayer) {
                                if (this.sv.scroll) {
                                    this.updateScrollView(index, videoPlayer);
                                    if (window.videoIsScrolling) {
                                        this.sv.scroll.slideVideoOut();
                                    }
                                    return this.sv.scroll;
                                } else if (this.sv.sp) {
                                    this.updateSpView(index);
                                }
                            }, this));
                        }
                    }
                } else {
                    this.$('.ui-pluto-video').eq(index).addClass('ui-pluto-reset');
                }
            },

            updateScrollView: function(index, currentVideoPlayer) {
                var plutoVideo = this.subviews['video' + index].sv.video;
                this.sv.scroll.options = {
                    el : this.$slides.eq(index).find('.ui-pluto-video'),
                    plutoVideo : plutoVideo,
                    videoData : plutoVideo.video,
                    videoPlayer : currentVideoPlayer
                };

                this.sv.scroll.subviews.videoBar.sv.share.undelegateEvents();
                this.sv.scroll.updateVideoBar(plutoVideo);
                this.sv.scroll.updateVideoBarTitle(plutoVideo.video.title);
                this.sv.scroll.subviews.videoBar.updateShare();
            },

            updateSpView: function(index) {
                this.sv.sp.updateVideo(this.subviews['video' + index].sv.video);
            },

            onVolumeChange: function(muted) {
                if (this.sv.scroll) {
                    this.sv.scroll.onVolumeChange(muted);
                }
            },

            onAdVolumeChange: function(isMuted) {
                if (this.sv.scroll) {
                    this.sv.scroll.onVolumeChange(isMuted);
                }
            },

            onAdPlay: function() {
                if (this.sv.scroll) {
                    this.sv.scroll.onAdPlay();
                } else if (this.sv.sp) {
                    this.sv.sp.showAdText();
                }

            },

            onAdEnd: function() {
                if (this.sv.scroll) {
                    this.sv.scroll.onAdEnd();
                } else if (this.sv.sp) {
                    this.sv.sp.updateTitle(this.getPlutoVideo(this.getActiveSlideIndex()).getTitle());
                }
            },

            onFullScreenChange: function() {
                if (this.sv.scroll) this.sv.scroll.onFullScreenChange();
            },

            onPause: function() {
                if (this.sv.scroll) this.sv.scroll.onPause();
                this.autoplayNext = false;
            },

            onVideoPlay: function() {
                if (this.sv.scroll) {
                    this.sv.scroll.onVideoPlay();
                } else if (this.sv.sp) {
                    this.sv.sp.updateTitle(this.getPlutoVideo(this.getActiveSlideIndex()).getTitle());
                }
            },

            isRecommended: function() {
                var currentSlideIndex = this.getActiveSlideIndex(),
                    nextSlideIndex = currentSlideIndex + 1,
                    maxSlideIndex = this.$slides.length - 1;
                return (nextSlideIndex > maxSlideIndex);
            },

            onGalleryPlay: function(){
                if(this.$titleOverlay) $(this.$titleOverlay).hide();
                if(this.$playButton) $(this.$playButton).hide();
                this.slideInterval();
            },

            goToNextVideo: function() {
                var nextSlideIndex = this.getActiveSlideIndex() + 1,
                    placementData = this.getPlacementData(),
                    galleryLoop = placementData.galleryLoop;
                if(galleryLoop && nextSlideIndex === this.$slides.length){
                    nextSlideIndex = 0;
                }
                this.goTo(nextSlideIndex).done(_.bind(function () {
                    this.startVideo(nextSlideIndex);
                    this.triggerEvent('goToSlide', nextSlideIndex);
                }, this));
            },

            /**
             * Shows the new slide and hides the other one.
             * @param {Number} index
             * @private
             */
            _transitionSlides: function(index) {
                var offsetBy = this.$viewport.outerWidth(),
                    targetOffset = offsetBy * index * -1,
                    $container = this.$slidesContainer;
                return this.animate($container, 'left', targetOffset + 'px', 250, 'easeInOutQuad');
            },

            /**
             * Gets the subview of a video player on a slide.
             * @param {Number} index The index of the slide that contains the subview
             * @returns {Deferred}
             */
            getPlayerSubview: function (index) {
                index = (index || index === 0) ? index : this.getActiveSlideIndex();
                return this.subviews['video' + index];
            },

            /**
             * Get video data from template
             */
            getVideoData: function (index) {
                index = (index) ? index : this.getActiveSlideIndex();
                return PlutoUtils.buildData($(this.$slides[index]).find('.ui-video-data'));
            },

            /**
             * Get placement data from template
             */
            getPlacementData: function (index) {
                index = (index) ? index : this.getActiveSlideIndex();
                return PlutoUtils.buildData($(this.$slides[index]).find('.ui-video-placement-data'));
            },

            /**
             * return plutoVideo by index
             */
            getPlutoVideo: function (index) {
                var subview = this.getPlayerSubview(index),
                    plutoVideo = subview ? subview.sv.video : false;
                return plutoVideo;
            },

            /**
             * When the video play button or video still is clicked.
             * @param {MouseEvent} e - Event
             * @param {Number} index - The slide index where the play button/video still was clicked.
             */
            onClickPlayVideo: function(e, index) {
                this.startVideo(index);
            },

            /**
             * For Carousel videos, initialize after click
             */
            onClickPlayCarousel: function(e){
                var index = this.getActiveSlideIndex();
                this.startVideo(index, e);
            },

            /**
             * Gets the currently active slide index.
             * @returns {*|SendStream}
             */
            getActiveSlideIndex: function () {
                return this._activeSlide;
            },

            /**
             * Shows and hides the next/prev arrows based on the index provided.
             * @param {Number} currentIndex
             */
            updateArrows: function (currentIndex) {
                var sel,
                    activeClass = 'gallery-nav-active',
                    placementData = this.getPlacementData(),
                    galleryLoop = placementData.galleryLoop ? placementData.galleryLoop : false;
                if (this.$slides.length <= 1) {
                    return;
                }
                this.$arrows.addClass(activeClass);
                if (currentIndex === 0) {
                    sel = 'prev';
                } else if (currentIndex === this.$slides.length - 1) {
                    sel = 'next';
                }
                this.$arrows.filter('.js-pvg-' + sel).removeClass(activeClass);
                if(galleryLoop && sel == 'next') this.$arrows.filter('.js-pvg-' + sel).addClass(activeClass);
            },

            showPlayOverlay: function(){
                if(this.getPlacementData().titleOverlay){
                    if(this.$titleOverlay) $(this.$titleOverlay).show();
                }
                if(this.$playButton) $(this.$playButton).show();
            },

            slideInterval: function(set){
                var autoAdvance = this.getPlacementData().galleryAutoAdvanceTime;
                if(autoAdvance) {
                    if (set){
                        this.slideChangeInterval = setInterval(this.goToNextVideo, autoAdvance);
                    } else {
                        clearInterval(this.slideChangeInterval);
                        this.slideChangeInterval = false;
                    }
                }
            },

            galleryMouseIn: function(e) {
                this.slideInterval();
            },

            galleryMouseOut: function(e) {
                var index = this.getActiveSlideIndex(),
                    plutoVideo = this.getPlutoVideo(index),
                    userPlayed = (this.getPlayerSubview(index)) ? this.getPlayerSubview(index).options.userPlayed : false;
                if (userPlayed || (plutoVideo && (plutoVideo.isPlaying || plutoVideo.vjsPlayer.paused()))) {
                    this.slideInterval();
                }else{
                    this.slideInterval(true);
                }
            },

            /**
             * Destroys the view
             */
            destroy: function () {
                this.slideInterval();
                this.showPlayOverlay();
                BaseView.prototype.destroy.apply(this, arguments);
                this.sv = {};
            }
        });

        /**
         * Return view class.
         */
        return PlutoVideoGallery;
    }
);

