/**
 * @fileoverview Rentals 2x1 view module.
 * @author rcopley@gannett.com (Ryan Copley), cbron@azcentral.com (Caleb Bron)
*/
define('modules/rentals-listing-row',[
        'jquery',
        'baseview',
        'state',
        'adPosition'
    ],
    function(
        $,
        BaseView,
        StateManager,
        AdPosition
    ) {

        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var RentalsListingRow = BaseView.extend({
            /**
             * Initialize view.
             */
            events: {
                'click .js-rlpm-more-apts': 'scrollDownToMoreApartments'
            },
            initialize: function() {
                BaseView.prototype.initialize.call(this);

                // Initialize the ad positions for just this module
                var elOne = this.$(".js-rlpm-ad-pos")[0];
                var elTwo = this.$(".js-rlpm-ad-pos")[1];
                if (elOne){
                    this.subviews.adPositionOne = this.createAdPosition(elOne);
                }
                if (elTwo){
                    this.subviews.adPositionTwo = this.createAdPosition(elTwo);
                }
            },

            scrollDownToMoreApartments : function(){
                $("html, body").animate({
                    scrollTop: this.$el.offset().top + this.$el.outerHeight(true) + 300
                }, 500);
            },

            handleCardSizeChange: function(cardInfo) {
                if (cardInfo.sidebarOpen === true){
                    this.$(".js-rlpm-more-apts").addClass("rlpm-more-apts-hide");
                }else{
                    this.$(".js-rlpm-more-apts").removeClass("rlpm-more-apts-hide");
                }
            },
            onCardWidthChange: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            },
            renderCardInfo: function(cardInfo) {
                this.handleCardSizeChange(cardInfo);
            },
            createAdPosition: function (el){
                return new AdPosition({
                    el: $(el),
                    adSizes: [330,330],
                    adPlacement: $(el).data('adtag'),
                    pageInfo: StateManager.getActivePageInfo()
                });
            }
        });

        /**
         * Return view class.
         */
        return RentalsListingRow;
    }
);

