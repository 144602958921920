/**
 * @fileoverview Pie Chart (interactive) view.
 * @author Stan Wilson Jr, William Broza
 */
define('modules/interactives/pie-chart',[
    'jquery',
    'underscore',
    'utils',
    'modules/interactives/interactive-base',
    'libs/flot/jquery.flot.usat.pie'
],
    function($, _, Utils, InteractiveBase) {
        "use strict";
        /**
         * View class.
         */
        var PieChartView = InteractiveBase.extend({

            isDonut: function() {
                return this.chartData.donutPresent === 'true';
            },

            _getHoverBody: function(obj) {
                if (this.isDonut()) {
                    return [this.getHoverPrefix(),
                        Utils.addCommas(Utils.getNested(obj, 'datapoint', 1, 0, 1)),
                        this.getHoverSuffix()].join(' ');
                } else {
                    return InteractiveBase.prototype._getHoverBody.apply(this, arguments);
                }
            },

            getFlotOptions: function() {
                var flotOptions = InteractiveBase.prototype.getFlotOptions.apply(this, arguments);
                flotOptions.series.pie = {
                    show: true,
                    stroke: {
                        color: '#fff',
                        width: 2
                    },
                    innerRadius: 0,
                    radius: 1
                };
                if (this.isDonut()) {
                    flotOptions.series.pie.innerRadius = 0.48;
                }
                return flotOptions;
            },

            hoverLeftBound: function() {
                if (this.isDonut()) {
                    return this.graph.width() / 3;
                } else {
                    return this.graph.width() / 2;
                }
            },

            hoverRightBound: function() {
                if (this.isDonut()) {
                    return (this.graph.width() / 3) * 2;
                } else {
                    return this.graph.width() / 2;
                }
            }
        });

        /**
         * Return view class.
         */
        return PieChartView;
    });

