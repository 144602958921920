/**
 * @fileoverview Countdown Timer
 * @author Brandon Minton <bminton@gannett.com> tweiss@gannett.com (Tyler Weiss)
 */
define('modules/fronts/countdown-clock',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'adPosition'
],
    function(
        $,
        _,
        BaseView,
        StateManager,
        AdPosition
        ) {
        "use strict";
        /**
         * View class.
         */
        var CountDown = BaseView.extend({

            // instance variables
            timer: 0,
            seconds: 0,
            classes: {
                sponsor: 'countdown-clock-ad'
            },

            /**
             * Initialize view.
             */
            initialize: function(options) {

                options = $.extend({
                    adPlacement: 'sponsor_logo_countdown',
                    adSizes: [100,50],
                    countdownContainer: '.countdown-clock-container',
                    digitBoxes: '.countdown-timer-digit-container .digit',
                    digitContainer: '.countdown-timer-digit-container'
                }, options);

                this.options = options;
                this.$sponsorshipAd = this.$('.' + this.classes.sponsor);
                this.layoutType = this._getLayoutType();

                var getPrestoDate = $(options.countdownContainer).attr('data-type-event_date');                

                BaseView.prototype.initialize.call(this, options);

                this.seconds = Math.round(new Date(getPrestoDate).getTime() / 1000) - Math.round(new Date().getTime() / 1000);
                this.$digitBoxes = this.$(options.digitBoxes);
                this.$digitContainer = this.$(options.digitContainer);
                this.$primaryTimer = $('.card-primary-suspender.countdown-clock-module');
                this.$sidebarTimer = $('.card-sidebar.countdown-clock-module');

                this.counterStart();
                
                this.positionTimer();
            },

            _getLayoutType: function() {
                var layout = null;
                if(this.options.layoutType){
                    if (this.options.layoutType.indexOf('primary') !== -1) {
                        layout = 'primary';
                    } else if (this.options.layoutType === 'sidebar'){
                        layout = 'sidebar';
                    }
                }
                return layout;
            },

            /**
            * initially, we need to check if the timer is in the sidebar or not
            */
            positionTimer: function() {
                if(!this.$sidebarTimer.length) {
                    this.$primaryTimer.css('display','block');
                }
            },

            /**
             * Triggered from card.js
             * We place the initAds function here and not in initialize so that we only call an
             * ad once the card is ready to render modules in the sidebar and once we know if the
             * sidebar is open. On the homefront, we only show the sidebar ad when the sidebar is open
             * @param {Object} currentCardInfo provides state information about the card
             */
            renderCardInfo: function(currentCardInfo) {
                if (!currentCardInfo.sidebarOpen && this.layoutType === 'primary') {
                    this.$primaryTimer.show();
                    this._buildSponsorshipAd();
                } else if (currentCardInfo.sidebarOpen && this.layoutType === 'sidebar'){
                    this._buildSponsorshipAd();
                }
                this.currentCardInfo = currentCardInfo;
            },

            _buildSponsorshipAd: function(){

                if (this.$sponsorshipAd.length && !this.subviews.sponsorshipAd) {

                    this.subviews.sponsorshipAd = new AdPosition({
                        el: this.$sponsorshipAd,
                        adSizes: this.options.adSizes,
                        adPlacement: this.options.adPlacement,
                        adType: 'sponsor_logo',
                        pageInfo: StateManager.getActivePageInfo()
                    });
                }
            },

            /**
             * Used to call our private function to init the ad position if the sidebar opens
             * because while the sidebar is open we should show this ad.
             * Triggered from cards.js. Only on home page and for the sponsor_logo_front.
             */
            openSideBar : function() {
                if(this.currentCardInfo) {
                    this._buildSponsorshipAd();
                }
            },

            /**
             * Triggered from card.js
             * If the card width has changed and there is an ad placement, we need to destory the
             * ad placement because any size change at this point will be too small to handle the ad
             * @param {Object} currentCardInfo provides state information about the card
             */
            onCardWidthChange: function(currentCardInfo) {
                if (currentCardInfo.sidebarOpen === this.currentCardInfo.sidebarOpen) {
                    return;
                }
                this.currentCardInfo = currentCardInfo;
                if (currentCardInfo.sidebarOpen && this.$sidebarTimer.length) {
                    if (this.layoutType === 'primary') {
                        this.$primaryTimer.hide();
                    } else if (this.layoutType === 'sidebar') {
                        this._buildSponsorshipAd();
                    }
                } else {
                    if (this.layoutType === 'primary') {
                        this.$primaryTimer.show();
                        this._buildSponsorshipAd();
                    }
                }
            },

            pad: function (arr) {
                var newArr = [];
                for(var i = arr.length; i > 0; i--){
                    var num = (arr[arr.length - i] > 9) ? arr[arr.length - i] : '0' + arr[arr.length - i];
                    newArr.push(num.toString());
                }

                return newArr;
            },

            updateTime: function (time) {
                var days = Math.floor(time / (60 * 60 * 24)),
                    hours = Math.floor(time / (60 * 60)),
                    mins = Math.floor(time / 60),
                    secs = Math.floor(time),

                    dd = days,
                    hh = hours - days * 24,
                    mm = mins - hours * 60,
                    ss = secs - mins * 60,
                    timeIndex = 0,
                    $currentTimer = '',
                    $digitContainer = this.$digitContainer,
                    timeArr = this.pad([dd, hh, mm, ss]);


                    this.$digitBoxes.each(function(i, el) {
                        if (timeArr[timeIndex] !== undefined) {
                            var timeDigits = timeArr[timeIndex].split("");
                            if (i % 2 === 0) { 
                                $(el).text(timeDigits[0]);
                            } else { 
                                $(el).text(timeDigits[1]);
                            }
                            i++;
                            if (i % 2 === 0) {
                                timeIndex++;
                            }
                        } // any other countdown timer will be empty at this point
                    });
                    $currentTimer = $digitContainer.clone().first();
                    time--;

                // provide all empty countdown timers with the current timer
                $digitContainer.slice(1).html($currentTimer.html());
            },

            decrementCounter: function () {
                this.updateTime(this.seconds);
                if (this.seconds === 0 || isNaN(this.seconds)) {
                    this.counterStop();
                }
                this.seconds--;
            },

            counterStart: function () {
                clearInterval(this.timer);
                this.timer = setInterval(this.decrementCounter.bind(this), 1000);
            },

            counterStop: function () {
                clearInterval(this.timer);
            },

            destroy: function(e) {
                this.counterStop();

                //Remove view from DOM
                BaseView.prototype.destroy.call(this, e);
            }


        });

        /**
         * Return view class.
         */
        return CountDown;
    }
);
