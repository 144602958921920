/**
 * @fileoverview Twitter view module.
 * @author jheiner@usatoday.com (John Heiner)
 */
define('modules/twitter',[
    'jquery',
    'underscore',
    'state',
    'baseview',
    'modules/scroller/sidebar-scroll'
],
function(
    $,
    _,
    StateManager,
    BaseView,
    SidebarScroll
) {

    'use strict';
        /**
         * View class.
         */
        var TwitterView = BaseView.extend({

            /**
             * Initialize view.
             * @param {Object} options
             */
            initialize: function(options) {

                options = $.extend({
                    layoutType: null // set by state manager, could be sidebar, or primary, or primary-with-suspender,
                }, options);

                this.$twitterEl = this.$('.twm-wrapper');
                this.isFixed = this.$el.closest('.sidebar-fixed-content').length;

                // Call base class initialize.
                BaseView.prototype.initialize.call(this, options);
            },

            renderCardInfo: function(currentCardInfo) {
                if (this.options.layoutType === 'sidebar' && currentCardInfo.sidebarOpen) {
                    this.addScrollbar();
                }
            },
            openSideBar: function(){
                this.renderCardInfo({sidebarOpen: true});
            },

            destroy: function(removeEl) {
                if (removeEl) {
                    this.$twitterEl.empty();
                }
                BaseView.prototype.destroy.call(this, false);
            },

            /**
             * Update size when a sidebar ad shows up.
             */
            onSidebarScrollableHeightChange: function(fixedHeight, scrollableHeight) {
                if (this.options.layoutType === 'sidebar') {
                    var siblingHeight = _.reduce(this.$el.siblings('.card-sidebar'), function(total, el){return total + $(el).outerHeight();}, 0);
                    this.$el.css('height', (this.isFixed ? fixedHeight : scrollableHeight) - siblingHeight);
                    this.refreshScrollbar();
                }
            },

            refreshScrollbar: function() {
                if (this.subviews && this.subviews.scrollbar) {
                    this.subviews.scrollbar.refresh();
                }
                if (this.options.layoutType === 'sidebar' && !this.isFixed) {
                    StateManager.getActiveApp().triggerEvent('refreshSidebarScroll');
                }
            },

            /**
             * Add scrollbar.
             */
            addScrollbar: function() {
                this.subviews.scrollbar = new SidebarScroll({
                    el: this.$('.twitter-sidebar-content'),
                    color: 'light',
                    lockPageScroll: true,
                    fadeout: true
                });
            }

        });

        /**
         * Return view class.
         */
        return TwitterView;
    }
);

