define('modules/videos/video-init',[
    'jquery',
    'baseview',
    'modules/videos/brightcove-video',
    'modules/videos/pluto/pluto-wrapper',
    'modules/videos/uw/uw-wrapper'
],
function(
    $,
    BaseView,
    BrightcoveVideo,
    PlutoWrapper,
    UwWrapper
){
    /**
     *  View Class
     */
    var VideoWrapper = BaseView.extend({
        /**
         *  Initialize view
         */
        initialize: function(options) {
            //Call base class initialize
            BaseView.prototype.initialize.call(this, options);
            if (this.$el.hasClass('js-uw-iframe-video')) {
                this.subviews.videoview = new UwWrapper({el: this.$el});
            } else if (this.$el.hasClass('js-ui-pluto-video')) {
                this.subviews.videoview = new PlutoWrapper({el: this.$el});
            } else if (this.$el.hasClass('ui-video')){
                this.subviews.videoview = new BrightcoveVideo({el: this.$el});
            }
        }
    });

    return VideoWrapper;
});

