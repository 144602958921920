define('modules/videos/pluto/pluto-360',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'pubsub',
    'modules/videos/pluto/pluto-share',
    'modules/videos/pluto/pluto-utils',
    'modules/videos/pluto/pluto-endslate',
    'modules/videos/pluto/pluto-embed',
    'modules/videos/pluto/pluto-analytics'
],
function(
    $,
    _,
    BaseView,
    Utils,
    PubSub,
    PlutoShare,
    PlutoUtils,
    PlutoEndslate,
    PlutoEmbed,
    PlutoAnalytics
) {
    "use strict";
    var Pluto360 = BaseView.extend({
        /**
         * Check if the current device is ipad.
         * @returns {Boolean} if ipad (or iphone, but we only care about ipad here)
         */
        isiPad: function() {
            return this.isApple;
        },

        /**
         * Check if the current browser is Safari
         * 360 video is not supported in Safari without having the code & videos hosted on same server
         * @returns {Boolean} if the browser is Safari
         */
        isSafari: function() {
            return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;
        },

        /**
         * Check if the current browser is IE
         * 360 video is not supported in IE
         * @returns {Boolean} if the browser is IE
         */
        isIE: function() {
            return navigator.userAgent.indexOf('MSIE') > -1 || navigator.appVersion.indexOf('Trident/') > -1;
        },

        /**
         * Check if webgl is supported in current browser
         * @returns {Boolean} if webgl is supported
         */
        supportsWebgl: function() {
            var retVal = false;
            try {
                var canvas = document.createElement('canvas');
                retVal = (!!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl') )) ? true : false;
            } catch (e) {
                retVal = false;
            }
            return retVal;
        },

        /**
         * Check if we can play the 360 video in current environment
         * rules:
         *    iPad, Safari, and IE are not supported at all
         *    We also need to check if webgl is enabled for 360 to work
         * @returns {Boolean}
         */
        supports360: function() {
            if (!this.supported) this.supported = this.supportsWebgl() && !this.isiPad() && !this.isSafari() && !this.isIE();
            return this.supported;
        },

        /**
         * Setup 360 events for supported devices.
         * @returns {Object} events hash
         */
        events: function() {
            return this.getEvents(false);
        },

        /**
         * Get the appropriate events.
         * @param {Boolean} isEndslate - if getting events for the endslate
         * @returns {Object} events hash
         */
        getEvents: function(isEndslate) {
            var events;
            if (!this.supports360()) {
                events = {};
            }else if (isEndslate) {
                //only need replay & share button on endslate
                events = {
                    'click .js-360-replay': 'replay',
                    'click .js-pluto-360-share': 'onEndslateShare'
                };
            } else {
                events = {
                    'click .js-360-play, .js-big-play-360': 'onPlay',
                    'click .js-360-pause': 'pause',
                    'click .js-fullscreen-control': 'fullscreen',
                    'click .js-360-volume-toggle': 'toggleVolume',
                    'click .js-360-volume-max': 'changeVolume',
                    'click .js-360-instructions': 'showInstructions',
                    'click .js-360-close-instructions': 'closeInstructions',
                    'click .js-360-progress-wrap': 'onSeek',
                    'click .js-pluto-share-link': 'onShare',
                    'click .js-pluto-still': 'onPlay',
                    'mouseenter': 'onVideoFocus',
                    'mouseleave': 'onVideoBlur',
                    'mousedown': 'onMouseDown'
                };
            }

            return events;
        },

        onShare: function() {
            this.pause();
        },

        initialize: function(options) {
            options = $.extend(Utils.getNested(window.site_vars, 'html5video', 'three60'), options);

            if (!this.supports360()) {
                //360 is not supported on iPad, show app promo and exit
                this.showAppPromo();
                return;
            } else {
                _.bindAll(this, 'onEmbedClose', 'onEmbedShow', 'onEnd', 'onFullscreenChange', 'onMouseDown', 'onMouseMove', 'onMouseUp', 'postMessageEvent', 'replay', 'getAnalytics');
                this.pubSub = {
                    'plutovideo:play': _.bind(this.videoIsPlaying, this)
                };
                BaseView.prototype.initialize.call(this, options);

                window.pluto360Asset = true;

                this.setConstants();
                this.cacheElements();
                this.setupPlayer();
                this.addEventListeners();
                this.stillImg = this.$('.js-pluto-still');
                if (this.placement.enableStaticEndslate || !this.placement.enableLoop){
                    this.subviews.endslate = this.createEndslate();
                }
                var embed = {
                    el: this.$el,
                    addEmbedBtn: false,
                    baseUrl: this.options.baseUrl,
                    onEmbedClose: this.onEmbedClose,
                    onEmbedShow: this.onEmbedShow,
                    siteDisplayName: this.options.siteDisplayName,
                    videoId: this.video.id
                };

                if (this.placement.analyticsEnabled) {
                    this.Analytics = this.getAnalytics();
                    this.Analytics.initAnalytics(this.options.liteAnalytics).done(_.bind(function (analytics) {
                        if (this.placement.embed) {
                            embed.analytics = analytics;
                            this.subviews.embed = new PlutoEmbed(embed);
                        }

                        if (this.placement.share) {
                            this.subviews.plutoshare = this.initShare(this.$el, analytics);
                        }
                    }, this));
                } else {
                    if (this.placement.embed) {
                        this.subviews.embed = new PlutoEmbed(embed);
                    }
                    if (this.placement.share) {
                        this.subviews.plutoshare = this.initShare(this.$el, null);
                    }
                }


            }
        },

        /**
          * Initialize Analytics
          */
        getAnalytics: function() {
            return PlutoAnalytics;
        },

        /**
         * Setup constants.
         */
        setConstants: function() {
            this.video = PlutoUtils.buildData(this.$('.ui-video-data'));
            this.placement = PlutoUtils.buildData(this.$('.ui-video-placement-data'));
            this.controlAnimateSpeed = 500;
            this.dragging = false;
            this.controlsVisible = false;
            this.inactiveTimer = this.options.inactiveTimer;
            this.controlCollapseDelay = this.options.controlCollapseDelay;
            this.responsive = this.placement.responsive || false;
            this.enablePostMessage = this.placement.enablePostMessage || false;
            this.enableInstructions = typeof this.placement.enableInstructions !== 'undefined' ? this.placement.enableInstructions : true;
            this.enableInactiveTimer = typeof this.placement.enableInactiveTimer !== 'undefined' ? this.placement.enableInactiveTimer : true;
            this.muteOnStart = this.placement.muteOnStart || false;
            this.firstPlay = true;
        },

        /**
         * Cache 360 control elements.
         */
        cacheElements: function() {
            this.$currentTimeSlot = this.$('.js-pluto-360-current-time');
            this.$playProgress = this.$('.js-360-progress');
            this.$controls = this.$('.js-pluto-360-controls');
            this.$dockedMouse = this.$('.js-360-docked-mouse');
        },

        /**
         * Get, initialize, and render  360 player
         */
        setupPlayer: function() {
            this.requirePlayer().done(_.bind(function(player, hls) {
                this.player = this.initPlayer(player, hls);
                this.player.render();

                this.player.on('ended', this.onEnd);
                this.player.on('fullscreenchange', this.onFullscreenChange);
                if(this.muteOnStart) this.mute();
                if (this.autoplay()) {
                    this.play(true);
                }
            }, this));
        },

         postMessageEvent: function(event){
             PlutoUtils.postMessageEvent(this, event);
        },

        /**
         * Add mouse event listeners.
         * This helps us track user interaction with the 360 player.
         * window resize handles scaling video for responsive layout
         */
        addEventListeners: function() {
            this.$doc = Utils.get('document');
            this.$doc.on('mousemove.' + this.cid, this.onMouseMove);
            this.$doc.on('mouseup.' + this.cid, this.onMouseUp);

            PlutoUtils.getPostMessage(this.enablePostMessage, this.postMessageEvent);

            if(this.responsive){
                $(window).resize(_.throttle(_.bind(function(){
                    this.player.size(window.innerWidth, window.innerHeight);
                }, this), 250));
            }
        },

        /**
         * Create an endslate for this video
         */
        createEndslate: function() {
            return new PlutoEndslate({
                contentUrl: '/videos/get_endslate/'
            });
        },

        /**
         * Determine if video should autoplay
         * @returns {Boolean} True if video should autoplay (based on query string or placement config)
         */
        autoplay: function() {
            return Utils.getUrlParam('autoplay') || this.placement.autoplay;
        },

        /***
         * get video source in order url parameter / highest rendition / low rendition
         */
        generateSrc: function() {
            return Utils.getUrlParam('src') || this.video.highest_rendition || this.video.preview_url;
        },

        /***
         * Pause video when embed overlay is displayed
         */
        onEmbedShow: function() {
            this.resumePlay = this.player.state.isPlaying;
            this.pause();
            if (this.player.state.isFullscreen) this.fullscreen();
        },

        /**
         * Conditionally play video when embed overlay closes
         */
        onEmbedClose: function() {
            if (this.resumePlay) {
                this.play(false);
            }
        },

        /**
         * Replay the video
         */
        replay: function() {
            this.onLoadViewCalled = false;
            if (this.placement.analyticsEnabled) this.Analytics.trackVideoEvents('360Replay');
            this.twentyFivePercentCalled = false;
            this.fiftyPercentCalled = false;
            this.seventyFivePercentCalled = false;
            this.ninetyFivePercent = false;
            this.interactionCalled = false;
            this.$$('.pluto-360-control').css('opacity', 1);
            if (this.subviews.endslate) this.subviews.endslate.close();
            this.onEndslateShare();
            this.delegateEvents(this.getEvents(false));
            this.play(false);
            this.firstPlay = false;
            if(!this.placement.enableLoop) PlutoUtils.setPostMessage(this.enablePostMessage, parent, 'replay');
        },

        /**
         * Show the app promo. Only for use on unsupported devices.
         */
        showAppPromo: function() {
            this.$('.js-360-app-promo').show();
        },

        /**
         * Initialize a 360 player
         * @param {Object} Player - object with 360 player constructor
         * @returns {Object} a new 360 player
         */
        initPlayer: function(Player, Hls) {
            var options = {
                autoplay: false,
                crossorigin: true,
                fov: this.options.fov,
                height: this.responsive ? window.innerHeight : this.placement.height,
                isClickable: false,
                loop:  false,
                muted: false,
                preload: true,
                resizable: this.responsive,
                src: this.generateSrc(),
                volume: 0.5,
                width: this.responsive ? window.innerWidth : this.placement.width
            };
            if (this.hlsIncluded && Hls) options.loader = this.loader(Hls);
            return new Player(this.$('.js-pluto-360')[0], options);
        },

        loader: function (Hls) {
            return function (frame, src, video) {
                var hls = new Hls();

                hls.loadSource(src);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    hls.loadLevel = data.levels.length - 1;
                    if (frame.texture) {
                        frame.texture.needsUpdate = true;
                    }
                });
            };
        },

        /**
         * Require the 360 player
         * @returns {Deferred} a promise that resolves the player after it loads
         */
        requirePlayer: function() {
            var toReq = ['player'],
                reqPromise = $.Deferred(),
                req = require.config({
                    paths: {
                        'player': this.options.playerUrl,
                        'hls': this.options.hlsSupport
                    }
                });

            if (Utils.getUrlParam('format') === 'hls') {
                this.hlsIncluded = true;
                toReq.push('hls');
            }

            req(toReq, _.bind(function() {
                var player = arguments[0];
                var hls = arguments[1] || null;
                reqPromise.resolve(player, hls);
            }, this));

            return reqPromise;
        },

        /**
         * Initialize a pluto-share instance
         * @param {jQuery} el - element of the share view
         * @param {jQuery} analytics - Analytics object
         * @returns {Object} a new PlutoShare
         */
        initShare: function (el, analytics) {
            return new PlutoShare({
                analytics: analytics,
                analyticsEnabled: this.placement.analyticsEnabled,
                controlBarSelector: '.js-pluto-360-share',
                el: el,
                layout: 'minimal',
                placementName: this.placement.name,
                siteDisplayName: this.options.siteDisplayName,
                twitterAccount: this.options.twitterAccount,
                videoData: this.video
            });
        },

        onMouseDown: function(e) {
            this.mouseDown = true;
        },

        onMouseMove: function(e) {
            if (this.mouseDown) {
                if (!this.interactionCalled) {
                    this.interactionCalled = true;
                    if (this.placement.analyticsEnabled) this.Analytics.trackVideoEvents('360Interaction');
                    PlutoUtils.setPostMessage(this.enablePostMessage, parent, 'interaction360');
                }

                this.dragging = true;
                e.preventDefault();
                if (this.subviews.endslate && !this.subviews.endslate.visible) {
                    //consider mousedown + mousemove as a drag
                    this.$dockedMouse.addClass('pluto-360-mouse-nav-hidden');
                }
            }
        },

        /**
         * Start inactivity timer on mouse up (after interaction finished)
         * @param {jQuery} e mouse up event
         */
        onMouseUp: function(e) {
            e.preventDefault();
            if (this.mouseDown && this.dragging) {
                this.dragging = false;
                if (this.inactivityTimeout) clearTimeout(this.inactivityTimeout);
                this.startInactiveTimer();
            }

            this.mouseDown = false;
        },

        startInactiveTimer: function() {
            if (!this.mouseShown) {
                if (this.inactivityTimeout) clearTimeout(this.inactivityTimeout);
                this.inactivityTimeout = setTimeout(_.bind(function() {
                    this.mouseShown = true;
                    this.$dockedMouse.removeClass('pluto-360-mouse-nav-hidden');
                }, this), this.inactiveTimer);
            }
        },

        onPlay: function() {
            this.stillImg.hide();
            this.play(true);
        },

        play: function(showInstructions) {
            PlutoUtils.setPostMessage(this.enablePostMessage, parent, 'play');
            this.fireViewCall();
            this.togglePlayControls(true);
            if (this.placement.analyticsEnabled) {
                this.Analytics.comscoreContentPlayback(this.video, this.player, this.placement.name);
            }
            if (this.enableInstructions && showInstructions && !this.instructionsAlreadyViewed()) {
                this.showEdOverlay(true);
            } else {
                this.startPlayback();
            }
        },

        /**
         * Call pluto-analytics one time per video for the play view call
         */
        fireViewCall: function() {
          if (!this.onLoadViewCalled) {
              this.onLoadViewCalled = true;
              if (this.placement.analyticsEnabled) this.Analytics.trackVideoOnload(this.video, this.player, this.placement.name);
          }
        },

        /**
         * Start video playback.
         * If first play start inactivity timer (we don't want pause/play resetting timer).
         */
        startPlayback: function() {
            PubSub.trigger('plutovideo:play', this.cid);
            this.player.play();
            if (this.firstPlay) {
                this.startInactiveTimer();
                this.firstPlay = false;
            }

            this.startProgressInterval();
        },

        onEnd: function() {
            if (this.player.state.isFullscreen) this.fullscreen();
            this.clearControlCollapseDelay();
            this.delegateEvents(this.getEvents(true));
            this.player.seek(0);
            if (this.subviews.endslate) {
                this.subviews.endslate.show();
            } else {
                this.stillImg.show();
                this.$$('.js-big-play-360').show();
            }
            this.onEndslateShare();
            //TODO: maybe ease the opacity out in css
            this.$$('.pluto-360-control').css('opacity', '.25');
            this.togglePlayControls(null);
            this.expandControls(0);
            if (this.placement.analyticsEnabled) {
                this.Analytics.stopComscoreTracking();
            }
            if(this.placement.enableLoop) this.replay();
        },

        showEdOverlay: function(autoclose) {
           this.$dockedMouse.addClass('pluto-360-mouse-nav-hidden');
            this.$$('.js-360-instructions-overlay').fadeIn(500, _.bind(function() {
                this.instructionsViewed();
                if (autoclose) {
                    setTimeout(_.bind(function() {
                        //autoclose only happens at beginning, so we want to play after closing instructions
                        this.resumePlay = true;
                        this.closeInstructions();
                    }, this), this.options.userEdDisplayDuration);
                }
            }, this));
        },

        pause: function() {
            this.clearProgressInterval();
            this.player.pause();
            this.togglePlayControls(false);
            PlutoUtils.setPostMessage(this.enablePostMessage, parent, 'pause');
            if (this.placement.analyticsEnabled) {
                this.Analytics.stopComscoreTracking();
            }
        },

        startProgressInterval: function() {
            this.updateCurrentTime(this.player.video.currentTime);
            this.updateProgressBar(this.player.video.currentTime);
            this.timeUpdateInterval = setInterval(_.bind(function() {
                this.updateCurrentTime(this.player.video.currentTime);
                this.updateProgressBar(this.player.video.currentTime);

                var percentPlayed = Math.round((this.player.video.currentTime / this.player.video.duration) * 100);
                if (!this.twentyFivePercentCalled && percentPlayed >= 25 && percentPlayed < 50){
                    this.twentyFivePercentCalled = true;
                    this.fireProgressAnalytic('25%', this.player.src());
                    PlutoUtils.setPostMessage(this.enablePostMessage, parent, '25');
                } else if (!this.fiftyPercentCalled && percentPlayed >= 50 && percentPlayed < 75) {
                    this.fiftyPercentCalled = true;
                    this.fireProgressAnalytic('50%', this.player.src());
                    PlutoUtils.setPostMessage(this.enablePostMessage, parent, '50');
                } else if (!this.seventyFivePercentCalled && percentPlayed >= 75 && percentPlayed < 95){
                    this.seventyFivePercentCalled = true;
                    this.fireProgressAnalytic('75%', this.player.src());
                    PlutoUtils.setPostMessage(this.enablePostMessage, parent, '75');
                } else if(!this.ninetyFivePercent && percentPlayed >= 95 && percentPlayed < 99){
                    this.ninetyFivePercent = true;
                    this.fireProgressAnalytic('95%', this.player.src());
                    PlutoUtils.setPostMessage(this.enablePostMessage, parent, '95');
                }
            }, this), 500);
        },

        fireProgressAnalytic: function(percent, url) {
            if (this.placement.analyticsEnabled) this.Analytics.trackVideoProgression(percent, url);
        },

        clearProgressInterval: function() {
            if (this.timeUpdateInterval) clearInterval(this.timeUpdateInterval);
        },

        onSeek: function(e) {
            this.clearProgressInterval();
            //ex. 100px progress bar; user clicks on 25% of that bar (thus 25px offsetX); 120 second video
            //(25 / 100) * 120 = 30 seconds
            this.player.seek((this.getClickOffset(e) / $(e.currentTarget).innerWidth()) * this.player.video.duration, true);
            this.startProgressInterval();
        },

        /**
         * Get X offset of click relative to event target
         * firefox uses layerx - http://stackoverflow.com/a/12704845
         * @param {jQuery} e click event
         */
        getClickOffset: function(e) {
            return e.offsetX === undefined ? e.originalEvent.layerX : e.offsetX;
        },

        /**
         * Update show/hide buttons
         * @param {Boolean} play - is video playing
         */
        togglePlayControls: function(play) {
            if (this.subviews.endslate && this.subviews.endslate.visible) {
                this.$$('.js-360-play').hide();
                this.$$('.js-360-pause').hide();
                this.$$('.js-360-replay').show();
                return;
            }
            if (play) {
                this.$$('.js-360-play').hide();
                this.$$('.js-big-play-360').hide();
                this.$$('.js-360-pause').show();
                this.$$('.js-360-replay').hide();
            } else {
                this.$$('.js-360-pause').hide();
                this.$$('.js-360-play').show();
                this.$$('.js-360-replay').hide();
            }
        },

        updateCurrentTime: function(time) {
            var minutes = Math.floor(time / 60),
                seconds = parseInt(time % 60),
                timeStamp = minutes + ':' + (seconds < 10 ? "0" : "") + seconds;

            this.$currentTimeSlot.html(timeStamp);
        },

        updateProgressBar: function(time) {
            var percentPlayed = (time / this.player.video.duration) * 100,
                //round the percentage to two decimals
                widthIncrement = Math.round(percentPlayed * 100) / 100;

            this.$playProgress.css('width', widthIncrement + '%');
        },

        /**
         * Request 360 player to go fullscreen
         */
        fullscreen: function() {
            if (this.placement.analyticsEnabled) this.Analytics.trackVideoEvents(this.player.state.isFullscreen ? '360CloseFullscreen' : '360Fullscreen');
            this.player.fullscreen(this.player.state.isFullscreen ? false : this.el);
        },

        onFullscreenChange: function() {
            if (this.player.state.isFullscreen) {
                this.$el.addClass('pluto-360-fullscreen');
            } else {
                this.$el.removeClass('pluto-360-fullscreen');
            }
        },

        /**
         * Clicking the volume button should mute the player
         */
        toggleVolume: function() {
            if (!this.player.video.muted) {
                this.mute();
            } else {
                this.unMute();
            }
        },

        mute: function(){
            this.player.mute(true);
            this.$$('.js-360-mute').show();
            this.$$('.js-360-volume').hide();
            this.$$('.js-360-volume-active').hide();
            if(this.player.state.isPlaying){
                PlutoUtils.setPostMessage(this.enablePostMessage, parent, 'mute');
            }
        },

        unMute: function(){
            this.player.mute(false);
            this.$$('.js-360-mute').hide();
            this.$$('.js-360-volume').show();
            this.$$('.js-360-volume-active').show();
            PlutoUtils.setPostMessage(this.enablePostMessage, parent, 'unmute');
        },



        /**
         * Change volume based on where user click in volume meter
         * @param {jQuery} e click event
         */
        changeVolume: function(e) {
            if (this.player.video.muted) this.toggleVolume();
            var meterClickOffset = this.getClickOffset(e);
            this.$$('.js-360-volume-active').css('width', meterClickOffset);
            //using innerWidth in case meter is resized via padding instead of width
            this.setVolume(meterClickOffset / this.$$('.js-360-volume-max').innerWidth());
        },

        setVolume: function(value){
            this.player.volume(value);
        },

        showInstructions: function() {
            if (this.placement.analyticsEnabled) this.Analytics.trackVideoEvents("360Instructions");
            if (this.subviews.plutoshare) this.subviews.plutoshare.hideShareBar();
            this.resumePlay = this.player.state.isPlaying;
            this.pause();
            this.showEdOverlay(false);
        },

        closeInstructions: function() {
            if (this.placement.analyticsEnabled) this.Analytics.trackVideoEvents("360CloseInstructions");
            this.hideInstructions().done(_.bind(function() {
                this.$$('.js-360-mouse').removeClass('pluto-360-mouse-navigation-docked');
                if (this.resumePlay) {
                    this.play(false);
                }
            }, this));
        },

        hideInstructions: function() {
            this.$$('.js-360-mouse').addClass('pluto-360-mouse-navigation-docked');
            this.$dockedMouse.removeClass('pluto-360-mouse-nav-hidden');
            return this.$$('.js-360-instructions-overlay').fadeOut(500).promise();
        },

        instructionsAlreadyViewed: function() {
            return Utils.getSessionData('360VideoInstructionsViewed', false);
        },

        instructionsViewed: function() {
            Utils.setSessionData('360VideoInstructionsViewed', true);
        },

        onVideoFocus: function(e) {
            this.clearControlCollapseDelay();

            if (!this.controlsVisible){
                this.controlsVisible = true;
                if (this.controlAnimationPromise) {
                    this.controlAnimationPromise.done(_.bind(function() {
                        this.expandControls(this.controlAnimateSpeed);
                    }, this));
                } else {
                    this.expandControls(this.controlAnimateSpeed);
                }
            }
        },

        onVideoBlur: function(e) {
            //reset timeout
            this.clearControlCollapseDelay();
            if (this.subviews.endslate && !this.subviews.endslate.visible) {
                //wait x seconds before closing controls
                this.collapseControlTimeout = setTimeout(_.bind(function() {
                    this.collapseControls();
                }, this), this.controlCollapseDelay);
            }
        },

        clearControlCollapseDelay: function() {
            if (this.collapseControlTimeout) clearTimeout(this.collapseControlTimeout);
        },

        expandControls: function(animateSpeed) {
            this.$controls.stop().show().animate({height: '33px'}, animateSpeed);
            this.$$('.pluto-360-progress-wrap-docked').css('opacity', 0);
            this.$dockedMouse.css({'bottom': '43px'});
        },

        collapseControls: function() {
            this.controlsVisible = false;
            this.controlAnimationPromise = $.Deferred();
            this.$dockedMouse.css({'bottom': '14px'});
            this.$$('.pluto-360-progress-wrap-docked').css('opacity', 1);
            this.$controls.animate({height: '0'}, this.controlAnimateSpeed, _.bind(function() {
                this.$controls.hide();
                this.controlAnimationPromise.resolve();
            }, this));
        },

        onEndslateShare: function() {
            if (this.placement.share) {
                if (this.subviews.plutoshare.open && this.subviews.endslate.visible) {
                    this.$$('.js-pluto-360-share').css('background', 'rgba(0,0,0,.55)');
                }
                else this.$$('.js-pluto-360-share').css('background', 'initial');
                }
        },

        videoIsPlaying: function(cid) {
            if (cid !== this.cid) {
               this.pause();
            }
        },

        destroy: function() {
             if (this.placement.analyticsEnabled) {
                 this.Analytics.stopComscoreTracking();
                 this.Analytics.resetMyStreamingTag();
            }
            if (this.supports360()) {
                this.$doc.off('.' + this.cid);
                if (this.player) this.player.destroy();
                clearInterval(this.timeUpdateInterval);
            }

            delete window.pluto360Asset;
            BaseView.prototype.destroy.apply(this, arguments);
        }
    });
    return Pluto360;
});

