define('site-manager',[
    'jquery',
    'underscore',
    'backbone',
    'utils',
    'pubsub',
    'state',
    'abtest-manager',
    'managers/routemanager',
    'managers/autoplay'
],
function(
    $,
    _,
    Backbone,
    Utils,
    PubSub,
    StateManager,
    AbTestManager,
    RouteManager,
    AutoPlayManager
)
{

    /**
     * Site-wide ajax handler, captures clicks and form submits and decides whether we can ajaxily load them
     * @exports managers/sitemanager
     * @author Chris Manning
     */
    var SiteManager = Backbone.View.extend(
        /**
         * @lends managers/sitemanager
         */
        {

        // View element.
        el: 'body',

        /**
         * Initialize view.
         */
        initialize: function() {
            // Event Proxy.
            window.appViewEventProxy = PubSub;
            window.Utils = Utils;
            window.siteManager = this;

            // Set global properties/lookups.
            this.DEBUG = Utils.get('DEBUG');
            this.$win = Utils.get('win');
            this.$top = Utils.get('scrollEl');
            this.pubSub = {
                'scrollTop': this.scrollTop
            };

            PubSub.attach(this.pubSub, this);
        },
        /**
         * Starts the site manager
         * @param {Object} Header header object we will init
         * @param {Boolean} [ajax=true] whether to turn on the ajax push state handler
         */
        start: function(Header, ajax) {
            // Responsive images plugin.
            this.$win.on('resize.' + this.cid, _.throttle(function(event) {
                PubSub.trigger('resize:app');
            }, 500)).trigger('resize');
            this.$el.on('click.' + this.cid, 'a,area', _.bind(this.triggerRoute, this));
            this.$el.on('submit.' + this.cid, 'form.ui-ajax-form', _.bind(this.triggerForm, this));

            // Initialize global header.
            if (Header) {
                window.header = this.header = new Header();
            }
            if (!ajax && StateManager.getActivePageInfo().texttype === 'front'){
                var prevFront = StateManager.getActivePageInfo();
                prevFront.path = window.location.pathname;
                Utils.setSessionData('previousFront', prevFront);
            }
            RouteManager.start(ajax || ajax === undefined); // ajax on.
            StateManager.start(this.header);
            AutoPlayManager.start();
        },

        /**
         * Returns an instance of the header
         */
        getHeader: function(){
            return this.header;
        },

        /**
         * Allows you to change the scrolltop with awareness of
         * the current state of the header, so you don't inadvertently
         * change from collapsed to expanded by scrolling to the top
         */
        scrollTop: function(topValue, force) {
            if (this.header) {
                return this.header.scrollTop(topValue, force);
            }
            return 0;
        },

        stop: function(){
            this.$el.off('.' + this.cid);
            this.$win.off('.' + this.cid);
            AutoPlayManager.stop();
            StateManager.stop();
            RouteManager.stop();
        },

        destroy: function(){
            this.undelegateEvents();
            if (window.header) {
                delete window.header;
            }
            delete window.appViewEventProxy;
            delete window.Utils;
            PubSub.unattach(this.pubSub, this);
        },

        /**
         * Handle global links.
         * @param {MouseEvent} e Link click event.
         */
        triggerRoute: function(e) {
            var $targetLink = $(e.currentTarget),
                href = $.trim($targetLink.attr('href'));
            if (e.isDefaultPrevented() || this._isMetaKey(e)) {
                PubSub.trigger('track', {event: e, url: href});
            } else if ($targetLink.attr('target')) {
                PubSub.trigger('track', {event: e, url: href});
                if (this._goPopup($targetLink, href)) {
                    e.preventDefault();
                }
            } else if (!href) {
                // anchor tracking, no navigation expected
                PubSub.trigger('track', {event: e});
                e.preventDefault();
            } else {
                e.preventDefault(); // RouteManager is taking over
                RouteManager.goTo(href, $targetLink.data('trackLabel') || $targetLink.data('uotrack') || $targetLink.data('ht'), e);
            }
        },

        /**
         * Checks to see if the click event shas any modifying that we would cause us to not process this event
         * @param {MouseEvent} e click event
         * @returns {Boolean}
         * @private
         */
        _isMetaKey: function(e) {
            return e.metaKey || e.ctrlKey || e.altKey || e.which == 2;
        },
        _goPopup: function($targetLink, href) {
            var target = $targetLink.attr('target');
            // treat target="_popup" (which is invalid HTML) and target=_blank with a data-popup attribute the same
            if (target === '_popup' || (target === '_blank' && $targetLink.data('popup') !== undefined)) {
                Utils.openPopup(href, $targetLink.data('popup-width'), $targetLink.data('popup-height'));
                return true;
            }
        },

        /**
         * Handle global search form submits.
         * @param {Event} e Link click event.
         */
        triggerForm: function(e){
            var $form = $(e.currentTarget),
                mappingData = $form.data('mapping'),
                templateObj = {},
                url = $form.attr('action');
            if (e.isDefaultPrevented()) {
                return;
            }
            if (mappingData) {
                $.each($form.serializeArray(), function(index, value){
                    templateObj[value.name] = value.value;
                });
                url += _.template(mappingData, templateObj);
            }
            e.preventDefault();
            RouteManager.goTo(url, $form.data('trackLabel') || $form.data('uotrack') || $form.data('ht'), e);
        }

    });

    /**
     * Return view class.
     */
    return new SiteManager();
});

