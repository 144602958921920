/**
 * @fileoverview Olympics Profile Overlay view
 * @author ikenticus
 */
define('apps/overlay/pages/olympics-profile-overlay',[
    'jquery',
    'underscore',
    'baseview',
    'modules/sports/popup-details'
],
function(
    $,
    _,
    BaseView,
    SportsPopupDetails
)
    {
        var OlympicsProfileView = BaseView.extend({
            
            initialize: function(options) {
                BaseView.prototype.initialize.call(this, options);
                this.subviews.popup = new SportsPopupDetails({
                    el: $('.olympics-profile')
                });

                var button = this.$('.sp-details-open');
                if (button.data('active')) {
                    button.click();
                }
            },
             
            destroy: function() {
                BaseView.prototype.destroy.call(this);
            }
            
        });

        return OlympicsProfileView;
    }
);
