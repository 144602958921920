define('partner/pushdown-ad',[
    'jquery',
    'underscore',
    'utils',
    'partner/high-impact-base',
    'partner/ad-utils',
    'adLogger',
    'pubsub',
],
function(
    $,
    _,
    Utils,
    HighImpactBase,
    AdUtils,
    AdLogger,
    PubSub
) {
    'use strict';
    var PushdownView = HighImpactBase.extend(
    /**
     * @lends partner/pushdown-ad.prototype
     */
    {

        events: {
            'click .partner-close': 'closeAdClick'
        },

        /**
         * @classdesc Pushdown ad type, this is a {@link partner/ad-position}
         * @constructs partner/pushdown-ad
         * @author Jay Merrifield <jmerrifiel@gannett.com>
         * @author Chad Shryock <cdshryock@gannett.com>
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector that contains both the pushdown and leave behind divs
         */
        initialize: function(options) {
            options = $.extend({
                adClasses: '',
                animations: {
                    vertical: {
                        duration: 350,
                        easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)'
                    }
                },
                ratio: {},
                triggerSpeed: 200
            }, options);

            _.bindAll(this, 'onAdReady', 'showAd', 'beforeAdRender');

            // call base class initialize
            HighImpactBase.prototype.initialize.call(this, options);

            this.$body = Utils.get('body');
            this.startBgColor = this.$body.css('background-color');
            this.$bigHeadline = this.$('.big-headline');
            this.$aboveCardContent = this.options.cards.find('.card-above-content');
            this.closeButton = this.$('.partner-close');
            this.noSidebar = this.$('.primary-flex-no-sidebar-page');

            this._createAd(['pushdown'], ['pushdown']);

            AdLogger.logInfo('Pushdown Advertisment initialized', this);
        },

        beforeAdRender: function(adInfo){
            var currentCardInfo = this.currentCardInfo;
            this.adData = adInfo;
            if (currentCardInfo) {
                this.onCardWidthChange(currentCardInfo);
            }
            HighImpactBase.prototype.beforeAdRender.apply(this, arguments);
        },

        destroy: function(removeEl) {
            // no need to cleanup ad, it gets cleaned up by the ad position
            if (this.showingAd) {
                this.closeAd(true);
            } else if (this.startBgColor) {
                AdUtils.clearBg(this.$body, this.startBgColor);
            }
            HighImpactBase.prototype.destroy.call(this, removeEl);
        },

        getAdHeight: function(cardWidth) {
            var ratio = this.adData.ratio;
            if(ratio && ratio.height && (ratio.width || ratio.width === 0)) {
                if(ratio.width === 0) {
                    return ratio.height;
                }

                return (ratio.height/ratio.width) * cardWidth;
            }
            //legacy pushdown height
            return 615;
        },

        onAdReady: function(adInfo) {
            if (adInfo.adType === 'Pushdown+') {
                this.persistBackground = true;
                AdUtils.changeBg(this.$body, adInfo.backgroundSolidColor, adInfo.backgroundRepeatingImage);
                this.$el.css({display: 'block', height: 0});
            }
            HighImpactBase.prototype.onAdReady.call(this, adInfo);
            //we should always have a leavebehind for pushdown, but just in case it was incorrectly scheduled we'll check
            if(this.subviews.adLeaveBehind) {
                this.subviews.adLeaveBehind.options.behavior.clearBgColor = false;
            }
        },

        showAd: function() {
            var pushdownHeight,
                duration = this.options.animations.vertical.duration,
                offsetHeight = this.getOffsetHeight(),
                $el = this.$el;
            HighImpactBase.prototype.showAd.call(this);
            this.$aboveCardContent.animate({opacity: 0}, 150).slideUp(duration-150);
            if (this.noSidebar) {
                this.closeButton.show();
            }
            // rising star ads have their own logic for revealing the ads
            $el.css({height: 0});
            pushdownHeight = this.getAdHeight(this.currentCardInfo.cardWidth) - offsetHeight;
            this.$bigHeadline.css('visibility', 'hidden');
            var afterShow = _.bind(function(){
                if (this.subviews.adPosition) {
                    $el.addClass('partner-pushdown-ad-open');
                    this.subviews.adPosition.playAd();
                }
            }, this);
            if (Utils.flag('disable_ad_animations')) {
                $el.css('height', pushdownHeight);
                if (offsetHeight) { $el.css('top', 0); }
                afterShow();
            } else {
                $.when( this.animate($el, 'height', pushdownHeight, duration),
                        (offsetHeight) ? this.animate($el, 'top', 0, duration) : null)
                    .done(afterShow);
            }
            this.$body.addClass('high-impact-ad-visible');
            AdUtils.changeBg(this.$body, this.adData.backgroundSolidColor, this.adData.backgroundRepeatingImage);
            PubSub.trigger('pushdownAd:showAd', this.options.triggerSpeed);
        },

        closeAdClick: function(e){
            e.preventDefault();
            this.closeAd();
            this.$aboveCardContent.slideDown(this.options.animations.vertical.duration-150).animate({opacity: 1}, 150);
        },

        getOffsetHeight: function(){
            return this.$bigHeadline.height() || 0;
        },

        closeAd: function(destroyAd) {
            var leaveBehind = this.subviews.adLeaveBehind,
                clearColor = leaveBehind.startBgColor || this.startBgColor,
                $el = this.$el,
                offsetHeight = this.getOffsetHeight(),
                duration = this.options.animations.vertical.duration;

            this.showingAd = false;
            this.subviews.adPosition.stopAd();
            var afterClose = _.bind(function() {
                this.$body.removeClass('high-impact-ad-visible');
                $el.removeClass('partner-pushdown-ad-open');
                this.$bigHeadline.css('visibility', 'visible');
                if (leaveBehind && !destroyAd) {
                    leaveBehind.show();
                }
            }, this);
            if (Utils.flag('disable_ad_animations')) {
                $el.css('height', 0);
                if (offsetHeight) {
                    $el.css('top', offsetHeight);
                }
                afterClose();
            } else {
                $.when( this.animate($el, 'height', 0, duration),
                        (offsetHeight) ? this.animate($el, 'top', offsetHeight + 'px', duration) : null)
                    .done(afterClose);
            }
            if (this.noSidebar) {
                this.closeButton.hide();
            }
            if (this.startBgColor && ((!this.persistBackground && !leaveBehind.startBgColor) || destroyAd)) {
                AdUtils.clearBg(this.$body, clearColor);
            }
            PubSub.trigger('pushdownAd:closeAd', this.options.triggerSpeed);
        },

        onCardWidthChange: function(newCardInfo) {
            if (!this.subviews.adPosition || !this.adData){
                return;
            }
            var adType = this.adData.adType,
                $el = this.$el,
                offsetHeight = this.getOffsetHeight(),
                pushdownHeight = this.getAdHeight(newCardInfo.cardWidth);
            this.currentCardInfo = newCardInfo;
            if (adType === 'Pushdown+' && !this.showingAd) {
                $el.css('top', offsetHeight);
            }
            $el.removeClass(this.options.adClasses).addClass(newCardInfo.adClass);
            this.subviews.adPosition.resizeAd(newCardInfo.cardWidth, pushdownHeight);

            //pushdown content down/pull up on card size change
            if (this.adData.ratio && this.showingAd){
                pushdownHeight =  pushdownHeight - offsetHeight;
                $el.css('height', pushdownHeight);
            }
        }

    });
    return PushdownView;

});

