 
/**
 * @fileoverview Member Guide Staff view.
 * @author Ryan Copley (rcopley@gannett.com)
 */
define('apps/contact-us-staff',[
        'jquery',
        'underscore',
        'base-app',
        'utils'
    ],
    function(
        $,
        _,
        BaseApp,
        Utils
    ) {
        /**
         * View class.
         */

        var ContactUsStaffApp = BaseApp.extend({
            events : {
                'click .js-cufwa-jumper' : 'jumpToGroup'
            },
            beforePageReveal: function() {
                var pageBody = Utils.get("body");
                if (!pageBody.hasClass("cufwa-body")){
                    pageBody.addClass("cufwa-body");
                }
            },
            beforeAppRemove: function() {
                $("body").removeClass("cufwa-body");
            },
            jumpToGroup : function(e){
                e.preventDefault();
                var anchorName = $(e.currentTarget).data('anchor');
                var anchor = this.$(".js-cufwa-jump[data-anchor='"+anchorName+"']");
                $("html, body").animate({
                    scrollTop: anchor.offset().top - 60 // the 60 is to negate the headers height
                }, 500);
            }
        });

        /**
         * Return app class.
         */
        return ContactUsStaffApp;
    }
);

