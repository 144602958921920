/**
* @fileoverview JS function for scrolling sports pages back to the top.
* @author webninjataylor@gmail.com (Taylor Johnson)
*/
define('modules/sports/sports-jumptotop',['jquery', 'underscore', 'backbone', 'pubsub', 'utils'],
    function($, _, Backbone, PubSub, Utils) {

        /**
         * View class.
         */
        var BackToTopView = Backbone.View.extend({

            // DOM element.
            el: '#section_sports',

            // Events.
            events: {
                'click .backtotop': 'backToTop'
            },

            initialize: function() {
                // Cache references to common elements/calculations.
                this.scrollEl = Utils.get('scrollEl');
            },

            backToTop: function(event) {
                this.scrollEl.animate({'scrollTop': 0},700);
                PubSub.trigger('uotrack', $(event.target).attr('data-ht'));
            },

            destroy: function() {
                this.undelegateEvents();
            }
        });

        /**
         * Create object and return it.
         */
        return BackToTopView;
    }
);

