/**
 * @fileoverview Member Guide view.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('apps/member-guide',[
        'jquery',
        'underscore',
        'state',
        'base-app',
        'site-manager',
        'pubsub'
    ],
    function(
        $,
        _,
        StateManager,
        BaseApp,
        SiteManager,
        PubSub
    ) {
        /**
         * View class.
         */
        //Used to restore the meta viewport when we destroy
        var MemberGuideView = BaseApp.extend({

            /**
             * Initialize App.
             */
            initialize: function() {
                // updates eVar99 with LDSN
                PubSub.trigger('group_page_view', 'LDSN');
                // updates eVar66
                PubSub.trigger('product_name', 'Member Guide');

                // call base class initialize
                BaseApp.prototype.initialize.call(this);
            },

            /**
             * afterPageReveal view.
             */
            afterPageReveal: function() {

                $("body").addClass("mga-body");
                PubSub.on("user:login", this.killLoginBanner, this);
            },

            killLoginBanner : function(userData, accountName){
                $(".mga-login-wrap").remove();
                $(".js-mga-subscribe-hide").remove();
            },
            beforeAppRemove: function() {
                $("body").removeClass("mga-body");
                PubSub.off("user:login", this.killLoginBanner, this);
            }
        });

        /**
         * Return view class.
         */
        return MemberGuideView;
    }
);

