/**
 * @fileoverview Gallery Carousel module view.
 * @author pkane, cwmanning
 */
define('modules/fronts/front-galleries',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'baseview',
    'utils',
    'modules/carousel/carousel',
    'ui/loader',
    'state',
    'modules/stories/share-facebook',
    'modules/sports/sports-front-carousel',
    'adPosition'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    BaseView,
    Utils,
    Carousel,
    Loader,
    StateManager,
    ShareFacebook,
    SportsFrontCarousel,
    AdPosition
) {
    'use strict';
        /**
         * View class.
         */
        var GalleryCarouselView = BaseView.extend({

            /*
             * Analytics must ignore a gallery "switch" which is not really a switch
             */
            empty: true,
            throttled: false,

            /**
             * Events
             */
            events: {
                'click .js-fg-link' : 'select',
                'click .util-bar-flyout-nav-btn': 'onClickFlyoutNavBtn',
                'click .gallery-sidebar-close': 'closeSidebar',
                'click .fullscreen': 'launchFullscreen',
                'click .sidebar-gallery-btn, .thumb-link': 'clickPhoto'
            },
            counter: 10,

            /**
             * Initialize view.
             * @param {Object} options View options passed in during init.
             */
            initialize: function(options) {
                this.$primary = $('.front-galleries-primary');
                this.$module = $('.front-galleries-module');
                this.$closer = this.$('.gallery-sidebar-close');
                this.$sidebar = this.$('.gallery-sidebar-ad');
                this.$counter = $('.gallery-count');
                this.$toolbox = this.$('.gallery-sidebar-toolbox');
                this.$cardfilm = $('.front-card-film');
                this.$cardcolor = $('.card-suspender-color');
                this.$suspender = $('.front-galleries-suspender');

                this.$galleries = this.$('.front-gallery');

                this.$navItems = this.$('.js-fgs-item');
                this.overrideTaboola = (this.$('.js-fg-nav-list').data('taboolaOverride')) ? true: false;
                this.currentGallery =  this.$('.fgpfm-gallery.front-gallery.selected').attr('id');

                this.$titles = this.$('.gallery-sidebar-title');
                this.$partnerSlot = $('.companion-galleries-partner-slot');
                this.$sidebarBtn = this.$('.sidebar-gallery-btn');

                if (this.$primary.length < 1) {
                    this.$primary = $('.sports-galleries-primary');
                }
                if (this.$suspender.length < 1) {
                    this.$suspender = $('.sports-galleries-suspender');
                }

                _.bindAll(this, 'switchGallery', 'setupAd', 'refreshAd', 'sidebarAds', 'resetGallery', 'clickPhoto', 'loading');

                // call base class initialize
                BaseView.prototype.initialize.call(this, {});

                var href;

                if(Utils.getNested(window.site_vars, 'flags', 'progressive_gallery_fronts')) {
                    PubSub.on('carousel:switchSlide', _.bind(function(e) {
                        this.refreshAd(e.context.className);
                    }, this));

                    PubSub.on('gallery:hideSidebar', _.bind(function() {
                        this.hideSidebar();
                    }, this));

                    if (this.$galleries.find(".slide.active").length) {
                        var $gallerySelected = this.$(".front-gallery.selected");
                        var $frontGalleries = this.$(".front-gallery");
                        this.subviews['gallery' + $frontGalleries.index($gallerySelected )] = new SportsFrontCarousel({
                            ads: true,
                            linkPath: this.$navItems.filter('.selected').find('a').attr('href'),
                            el: $gallerySelected.find('.galleries'),
                            fullScreen: true,
                            onSidebarAfterReveal: this.setupAd,
                            sidebarAds: this.sidebarAds
                        });
                        this.empty= false;
                    } else {
                        href = this.$navItems.first().find("a").attr("href");
                        if (typeof href !== 'undefined' && href.length > 1) {
                            this.fetchData(href, 0, this.switchGallery);
                        }
                    }

                    this.$('.util-bar-flyout-section').hide();
                    this.subviews.facebook = new ShareFacebook({
                        el: this.$('.util-bar-flyout-share')
                    });

                }
                else {
                    var endslateGalleries = [];
                    var currentGalleryId =  this.currentGallery;

                    // Get all of the ids needed for the endslate excluding the current
                    this.$('.fgpfm-nav-link').each(function(){
                            var galleryLinkId = $(this).data('id');
                            if(galleryLinkId && currentGalleryId != galleryLinkId){
                                endslateGalleries.push(galleryLinkId);
                            }
                        });

                    // For use in initializing the Carousel object
                    this.endslateGalleries =  endslateGalleries;

                    if (this.$galleries.find(".slide.active").length) {
                        var $gallery = this.$(".front-gallery.selected");
                        this._initializeCarousel($gallery, $gallery.index(), this.$navItems.filter('.selected').find('a').attr('href'));
                        this.empty= false;
                    } else {
                        href = this.$navItems.first().find("a").attr("href");
                        if (typeof href !== 'undefined' && href.length > 1) {
                            this.fetchData(href, 0, this.switchGallery);
                        }
                    }
                }


                this.subviews.loader = new Loader({
                    el: this.$galleries.parent()
                });


            },

            _initializeCarousel: function($el, index, link){
                var carousel = new Carousel({
                    ads: true,
                    linkPath: link,
                    el: $el.find('.galleries'),
                    fullScreen: true,
                    endslateOptions : {
                        overrideTaboola: this.overrideTaboola,
                        endslateGalleries: this.endslateGalleries,
                        currentGallery: this.currentGallery
                    }
                });
                this.subviews['gallery' + index] = carousel;
                return carousel;
            },
            /**
             * Fetch data from server via AJAX. Takes a path to fetch and a
             * callback to parse the data and initialize views.
             * @param {String} path The path to the ajax endpoint.
             * @param {Number} index The Gallery list item position.
             * @param {Function} callback Function to call when data is returned
             *      from server.
             */
            fetchData: function(path, index, callback) {
                var _this = this,
                // state manager failures return immediately without waiting for the promises to finish
                // so we need to track whether we're still loading after the animation is running
                    loading = true,
                    layoutType = this.$el.data('layout-type');

                var $gallery = this.$(".front-gallery.selected");
                if ($gallery.length){
                    $gallery.css({'opacity': 1});
                    this.animate($gallery, 'opacity', 0, 200).done(function(){
                        if (loading){
                            _this.loading(true);
                        }
                    });
                }
                if (layoutType) {
                    path = '/' + layoutType + path;
                }
                StateManager.fetchHtml('/front-gallery' + path, { headers: { 'x-no-nav': 'true' } })
                            .done(function(html){
                        callback(html, index,path);
                    }).always(function(){
                        loading = false;
                        _this.loading(false);
                    });
            },

            /**
             * Toggle loading presentation
             * @param {boolean} showLoading Whether or not to display loading.
             */
            loading: function(showLoading) {
                if (this.subviews.loader) {
                    if (showLoading) {
                        this.subviews.loader.show();
                    } else {
                        this.subviews.loader.hide();
                    }
                }
            },

            /**
             * Toggle selected class and load gallery data.
             * @param {Event} event View click event.
             */
            select: function(event) {
                var targetLink = $(event.currentTarget),
                    path = targetLink.attr('href'),
                    item = targetLink.parent(),
                    index = item.index(),
                    $gallery = this.$galleries.eq(index);

                if (path.indexOf('://') === -1) {
                    if ($gallery.find('.galleries').length > 0) {
                        this.switchGallery(null, index, path);
                    } else {
                        this.fetchData(path, index, this.switchGallery);
                    }

                    if (event) {
                        event.preventDefault();
                    }
                }

                // Highlight clicked navigation item.
                var $prevSelected = this.$navItems.filter('.selected'),
                    prevTheme = $prevSelected.attr('data-theme') || '',
                    newTheme = item.attr('data-theme') || '';
                $prevSelected.removeClass(prevTheme + ' selected');
                item.addClass(newTheme + ' selected');
            },

            // carousel is not issuing a slide:change event for our first slide
            // so... ask it to do so
            trackGalleryChange: function(carousel) {
                carousel._trackSlideChange();
            },

            _initializeAds: function() {
                if (this.$sponsorshipAd) {
                    return;
                }
                this.$sponsorshipAd  = $('<div class="partner-placement poster" data-monetization-id="sp-gallery" data-monetization-sizes="mediumrectangle"></div>');
                this.$partnerSlot.append(this.$sponsorshipAd);
            },

            renderCardInfo: function(currentCardInfo) {
                this.currentCardInfo = currentCardInfo;
            },
            /**
             * Refresh gallery thumbs scroller on card width change if it is a primary-flex layout type.
             */
            onCardWidthChange: function(newCardInfo) {
                if (!this.currentCardInfo || this.currentCardInfo.primaryFlexWidth !== newCardInfo.primaryFlexWidth) {
                    this.triggerEvent('viewportResize');
                }

                this.currentCardInfo = newCardInfo;
                if (this.sidebarAds()) {
                    this.setupAd();
                    if (this.$sidebar.hasClass('active')) {
                        PubSub.trigger('carousel:cardfilmActive');
                    }
                } else {
                    this.teardownAd();
                    if (this.$sidebar.hasClass('active')) {
                        PubSub.trigger('carousel:sidebarClosed');
                    }
                }
            },

            sidebarAds: function() {
                if (!this.currentCardInfo) {
                    return false;
                } else {
                    return this.currentCardInfo.sidebarAds;
                }
            },
            /**
             * Switch to selected gallery.
             * @param {String} html Returned from fetchData.
             * @param {Number} index The Gallery list item position.
             * @param {String} path url for the gallery associated with the html.
             */
            switchGallery: function(html, index, path) {
                var carousel,
                    $gallery = this.$galleries.eq(index),
                    layout = StateManager.getActivePageInfo().layout_type;

                if(Utils.getNested(window.site_vars, 'flags', 'progressive_gallery_fronts') && layout !== 'primary-flex-sidebar-page') {
                    // Show chosen gallery
                    this.$galleries.removeClass('selected');
                    $gallery.addClass('selected');

                    // Show chosen gallery toolbar
                    var $toolbar = this.$toolbox.eq(index);
                    if (this.$toolbox.length > 1) {
                        this.$toolbox.removeClass('selected');
                    }
                    $toolbar.addClass('selected');
                    // deactivate all social-media items when switching
                    this.$('.util-bar-flyout-nav-btn').removeClass('active');

                    // Show chosen gallery title
                    var $title = this.$titles.eq(index);
                    if (this.$titles.length > 1) {
                        this.$titles.removeClass('selected');
                    }
                    $title.addClass('selected');

                    // Show chosen gallery selected caption
                    var sidebar = this.$('.gallery-sidebar-ad .caption');
                    var caption = $gallery.find('.active .gallery-viewport-caption').html();
                    var title = $title.html();
                    this.resetSections();
                    sidebar.html(caption);

                    if (html) {
                        $gallery.html(html);
                        var counter = '<div class="gallery-count"><div>' +
                            $gallery.find('.thumb-item').length +
                            '</div><div class="line">Photos</div></div>';
                        $gallery.append(counter);
                        this.$counter = $('.gallery-count');
                        this._setCounterWidth($gallery);
                    }

                    if (!this.subviews['gallery' + index]) {
                        carousel = this.subviews['gallery' + index] = new SportsFrontCarousel({
                            ads: true,
                            el: $gallery.find('.galleries'),
                            fullScreen: true,
                            linkPath: path,
                            onSidebarAfterReveal: this.setupAd,
                            sidebarAds: this.sidebarAds
                        });
                        if (!this.empty) {
                            this.trackGalleryChange(carousel);
                            this.empty = false;
                        }
                    } else {
                        this.trackGalleryChange(this.subviews['gallery' + index]);
                        if (this.subviews['gallery' + index].subviews.gallery && this.subviews['gallery' + index].subviews.gallery.refreshSportsSponsorshipAd){
                            this.subviews['gallery' + index].subviews.gallery.refreshSportsSponsorshipAd();
                        }
                        this.teardownAd();
                        this.setupAd();
                    }
                    $gallery.css({opacity: 0});
                    this.animate($gallery, 'opacity', 1, 200);
                }
                else {
                    // Show chosen gallery.
                    this.$galleries.removeClass('selected');
                    $gallery.addClass('selected');

                    if (html) {
                        $gallery.html(html);
                    }
                    if (!this.subviews['gallery' + index]) {
                        carousel = this._initializeCarousel($gallery, index, path);
                        if (!this.empty) {
                            this.trackGalleryChange(carousel);
                        }
                    } else {
                        this.trackGalleryChange(this.subviews['gallery' + index]);
                    }
                    $gallery.css({opacity: 0});
                    this.animate($gallery, 'opacity', 1, 200);
                }
            },

            launchFullscreen: function() {
                // invoke the fullscreen button on the carousel/controls view
                $('.front-gallery.selected .fullscreen').click();
            },

            setupAd: function() {
                var index = this.$(".front-gallery").index(this.$(".front-gallery.selected"));
                if (this.sidebarAds()) {
                    this.adSetup = true;
                    this._initializeAds();

                    // set up ad
                    var targeting = {};
                    if (this.subviews['gallery' + index].subviews.gallery && this.subviews['gallery' + index].subviews.gallery.getAdTargeting) {
                        targeting = this.subviews['gallery' + index].subviews.gallery.getAdTargeting();
                    }
                    var galleryData = this.$(".front-gallery.selected .galleries").data();

                    this.subviews.sponsorshipAd = new AdPosition({
                        el: this.$sponsorshipAd,
                        adPlacement: 'poster_gallery_companion',
                        adSizes: ['mediumrectangle'],
                        targeting: targeting,
                        subSection: galleryData.cst,
                    });
                }
            },

            // the following 2 functions mimic web-standard-apps.git: utility-bar-module-share.js
            onClickFlyoutNavBtn: function(e) {
                var $btn = $(e.currentTarget);
                var moduleSection = $(e.currentTarget).data('share-method'),
                    galleryTitle = $(e.currentTarget).data('share-title'),
                    galleryLink = $(e.currentTarget).data('share-link'),
                    shareTrack = 'Gallery' + moduleSection;

                PubSub.trigger('uotrack', shareTrack);
                if (moduleSection) {
                    this.showSection($btn.parent().parent(), moduleSection);
                }
                if (!this.$closer.hasClass('active')) {
                    this.$closer.addClass('active');
                    this.$cardfilm.removeClass('inactive');
                    this.$module.css('z-index', 501);
                    this.$suspender.css('background-color', this.$cardcolor.css('background-color'));
                }
                if (moduleSection == 'facebook') {
                    this.$('.util-bar-share-message-facebook').data('link', galleryLink);
                    this.$('.util-bar-share-message-facebook').data('title', galleryTitle);
                    this.resetSections();
                    this.$('.gallery-sidebar-ad .caption').empty();
                    this.subviews.facebook.onClickPostBtn(e);
                    e.preventDefault();
                }
            },

            showSection: function(toolbox, sectionName) {
                this.resetSections();
                this.activeSection = sectionName;
                this.$('.util-bar-flyout-nav-btn-' + sectionName).addClass('active');
                //this.$('.util-bar-flyout-section-' + sectionName).show();
            },

            resetSections: function() {
                this.$('.util-bar-flyout-nav-btn').removeClass('active');
                this.$('.util-bar-flyout-pane-success').hide();
            },

            resetGallery: function() {
                this.resetSections();
                this.$primary.removeClass('active');
                this.$closer.removeClass('active');
                this.$cardfilm.addClass('inactive');
                this.$sidebar.removeClass('active');
                this.$module.css('z-index', 'auto');
                this._setCounterWidth(this.$module.find(".front-gallery.selected"));
            },

            closeSidebar: function(e) {
                if (e){
                    e.preventDefault();
                    e.stopPropagation();
                }
                var w = parseInt(this.$sidebar.outerWidth() + 1, 10);
                var slideAnimation = null;
                if (this.$sidebar.hasClass('active')) {
                    PubSub.trigger('carousel:sidebarClosed');
                    // deactivate all social-media items when closing
                    this.$('.util-bar-flyout-nav-btn').removeClass('active');
                    slideAnimation = this.animate(this.$sidebar, 'right', 0, 250, 'ease-in-out');
                    slideAnimation.done(_.bind(function() {
                        this.resetGallery();
                    }, this));
                    this.teardownAd();
                }
                this.$sidebarBtn.addClass('active');
            },

            hideSidebar: function() {
                var w = parseInt(this.$sidebar.outerWidth() + 1, 10);
                if (this.$sidebar.hasClass('active')) {
                    this.$('.util-bar-flyout-nav-btn').removeClass('active');
                    this.$sidebar.css({'right': 0, 'transition': ''});
                    this.resetGallery();
                    this.teardownAd();
                }
            },

            refreshAd: function(element) {
                if(element && element.indexOf("front-galleries-module") > -1) {
                    if (this.subviews.sponsorshipAd && this.subviews.sponsorshipAd.refreshPosition   && !this.throttled) {
                        this.subviews.sponsorshipAd.refreshPosition();

                         // Throttle the ad call to once every 2 seconds (on a click)
                        this.throttled = true;
                        setTimeout(_.bind(function() {
                            this.throttled = false;
                        }, this), 2000);
                    }
                }
            },

            teardownAd: function() {
                if (this.subviews.sponsorshipAd && this.adSetup) {
                    delete this.adSetup;
                    this.$sponsorshipAd.empty();
                    this.subviews.sponsorshipAd.destroy();
                    delete this.subviews.sponsorshipAd;
                }
            },

            clickPhoto: function(e) {
                if(this.$sidebarBtn.hasClass('active')) {
                    if (e){
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    var $gallerySelected = this.$(".front-gallery.selected");
                    var $frontGalleries = this.$(".front-gallery");
                    this.subviews['gallery' + $frontGalleries.index($gallerySelected)].openSidebar();
                    this.$closer.addClass('active');
                    this.$sidebarBtn.removeClass('active');
                   if (!this.$primary.hasClass('active')) {
                        this.$primary.addClass('active');
                        }
                    this.$cardfilm.removeClass('inactive');
                    PubSub.trigger('track', {
                      label: 'gallery caption flyout',
                      url: location.href,
                      event: e
                    });
                }
            },

            _setCounterWidth: function($gallery) {
                var imgWidth = $gallery.find('.slide.active .gallery-photo').width(),
                    leftDist = 40;
                if(!imgWidth) {
                    this.counter--;
                    if(this.counter) {
                        setTimeout(_.bind(function() {
                            this._setCounterWidth($gallery);
                        }, this), 100);
                    } else {
                        // Couldn't find the active image and it's width, so we'll default the gallery hover to 100% width
                        $gallery.find('.front-gallery-hover').css({width: '90%', left: leftDist });
                    }
                } else {
                    imgWidth = imgWidth-40;
                    if(imgWidth>470) { imgWidth=470; }
                    if(imgWidth<470) { leftDist = (720-imgWidth)/2; }
                    $gallery.find('.front-gallery-hover').css({width: imgWidth, left: leftDist });
                }
            }

        });

        /**
         * Return view class.
         */
        return GalleryCarouselView;
    }
);

