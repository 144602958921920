 /**
 * @fileoverview Before After (interactive) view.
 * @author stwilson@gannett.com (Stan Wilson Jr), mdkennedy@gannett.com (Mark Kennedy)
 */
define('modules/interactives/before-after',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'baseview',
    'modules/interactives/interactive-templates',
    'libs/jqueryui/draggable'
],
function(
    $,
    _,
    PubSub,
    Utils,
    BaseView,
    Template
) {

    /**
     * View class.
     */
    var BeforeAfterView = BaseView.extend({

        events: function() {
            var events = {
                'click .image-container': 'handleClick'
            };
            events[(Modernizr.touch ? 'touchstart' : 'click') + ' .before-after-caption-toggle'] = 'toggleCaption';
            return events;
        },

        /**
         * Initialize view.
         * @param {Object} options View options passed during init.
         */
        initialize: function(options) {
            options = $.extend({
                animateIntro: true,
                introDelay: 1000,
                introDuration: 1500,
                introPosition: 0.50,
                cursor: 'e-resize'
            }, options);
            BaseView.prototype.initialize.call(this, options);

            if (this.$el.length){
                //setup interactive template view
                this.subviews.template = new Template({
                    'el': this.$el,
                    'interactiveType' : "beforeandafter",
                    'isFramed': options.isFramed,
                    'standAlone': options.standAlone
                });
                this.subviews.template.goTo(this.subviews.template.currentSlideIndex);
                if (Modernizr.touch) {
                    require(['touchpunch'], _.bind(this._setup, this));
                } else {
                    this._setup();
                }
            }
        },

        destroy: function(){
            this.$('.before-after-drag-handle').draggable('destroy');
            BaseView.prototype.destroy.apply(this, arguments);
        },

        _setup: function(){
            if (!this.destroyed) {
                _.each(this.$(".image-container"), function(el, index) {
                    this._setupBeforeAfter($(el), index);
                }, this);
            }
        },

        toggleCaption : function(e){
            var captionButton = $(e.currentTarget),
                meta = captionButton.closest('.slide').find('.meta'),
                captionVisible = meta.is(':visible');
            meta.fadeToggle(function() {
                captionButton.text(captionVisible ? "Show Caption" : "Hide Caption");
            });
            if (!this.options.isFramed) {
                this.subviews.template.trackClick(captionVisible ? "beforeandafter_caption_off" : "beforeandafter_caption_on");
            }
        },
        handleClick: function(e){
            var $this = $(e.currentTarget),
                $dragWrapper = $('.before-after-drag-handle', $this),
                draggerOffset = $dragWrapper.width() / 2;
            this.subviews.template.trackFirstClick();
            var clickX = e.pageX - $this.offset().left;
            $('.before-after-drag-handle', $this).stop().css("left", (clickX - draggerOffset) + 'px');
            $('.before-after-before-wrapper', $this).stop().width(clickX + 'px');
        },

        _setupBeforeAfter: function($imgContainer, index) {
            var $this = $imgContainer,
                o = this.options,
                $dragHandle = $('.before-after-draw-handle-image', $this),
                handlerWidth = $dragHandle.width(),
                $dragWrapper = $('.before-after-drag-handle', $this),
                $beforeWrapper = $('.before-after-before-wrapper', $this),
                imgWidth = $beforeWrapper.width(),
                imgHeight = $beforeWrapper.height(),
                startPosition = imgWidth * o.introPosition;

            $dragHandle.css({
                cursor: o.cursor,
                top: (imgHeight/2)-($dragHandle.height()/2) + 'px'
            });

            function drag() {
                $beforeWrapper.width(parseInt($(this).css('left'), 10) + (handlerWidth / 2));
            }

            $dragWrapper.draggable({
                containment: $this,
                drag: drag,
                stop: drag,
                axis: "x"
            });

            if (o.animateIntro && index === 0) {
                this.animateIntro($beforeWrapper, $dragWrapper, imgWidth, startPosition);
            } else {
                $dragWrapper.css({
                    'left': startPosition - (handlerWidth/2) + 'px'
                });
                $beforeWrapper.width(startPosition);
            }
        },
        animateIntro: function($beforeWrapper, $dragWrapper, startPosition, finishPosition){
            var o = this.options,
                draggerOffset = $dragWrapper.width() / 2;
            $dragWrapper.css('left', (startPosition - draggerOffset) + 'px');
            setTimeout(function() {
                $dragWrapper.animate({
                    'left': (finishPosition - draggerOffset) + 'px'
                }, o.introDuration);
                $beforeWrapper.animate({
                    'width': finishPosition + 'px'
                }, o.introDuration);
            }, o.introDelay);
        }
    });

    /**
     * Return view class.
     */
    return BeforeAfterView;
});

