/**
 * @fileoverview eat-and-drink-header group view module.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('modules/eat-and-drink-header',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
) {
        var EatAndDrinkHeader = BaseView.extend({
            initialize: function() {
                this.pubSub = {
                    'user:statuschange': this._loginStatus
                };
                
                BaseView.prototype.initialize.call(this);
            },
            
            _loginStatus: function(accountName, loginStatus, userData) {
                if (accountName !== 'firefly') {
                    return;
                }

                var eadaLogin = this.$("#eada-login");
                var eadaSingup = this.$("#eada-signup");
                var eadaGreeting = this.$("#eada-greeting");
                
                if (loginStatus === 'loggedIn' && userData.hasMarketAccess === true) {
                    eadaLogin.addClass('eada-hidden');
                    eadaSingup.addClass('eada-hidden');
                    eadaGreeting.removeClass('eada-hidden').html("Welcome, " + userData.welcomeName + "!");
                } else {
                    eadaLogin.removeClass('eada-hidden');
                    eadaSingup.removeClass('eada-hidden');
                    eadaGreeting.addClass('eada-hidden');
                }
            }
        });

        return EatAndDrinkHeader;
    }
);
