/* global define */
define('modules/fronts/front-list-video',[
    'jquery',
    'underscore',
    'utils',
    'modules/videos/uw/uw-wrapper'
],
function(
    $,
    _,
    Utils,
    UwWrapper
)
{
    'use strict';
    var FrontListVideo = UwWrapper.extend({

        initialize: function(options) {
            UwWrapper.prototype.initialize.call(this, options);
            this.uwIframe = $([]);
            this.$videoContainer = this.$('.js-uw-iframe-video');
            this.$iframeWrapper = this.$('.js-iframe-wrap');
            this.options.videoId = this.$videoContainer.attr('data-video-id') || options.videoId;
            this.options.uwIframeSrc = this.$videoContainer.attr('data-iframe-src') || options.uwIframeSrc;
            this.options.snowPlayerEnabled = this.$videoContainer.attr('data-snow-enabled') || options.uwIframeSrc;
            if (this.options.videoId) {
                this.uwIframe = $('<iframe></iframe>')
                    .attr({
                        'class': 'uw-iframe',
                        'src': this.options.uwIframeSrc,
                        'name': this.options.snowPlayerEnabled ? 'snow-player/' + this.options.videoId : this.options.videoId,
                        'frameborder': 0,
                        'allowfullscreen': '',
                        'scrolling': 'no'
                    })
                    .ready(_.bind(function() {
                        this.$iframeWrapper.show();
                        this.playerReady.resolve();
                    }, this));
                this.$videoContainer.prepend(this.uwIframe);
                this.uwIframe = this.uwIframe[0];
            }
        },
        destroy: function () {
            this.$iframeWrapper.hide();
            this.uwIframe.remove();
            UwWrapper.prototype.destroy.apply(this, arguments);
        }
    });
    return FrontListVideo;
});

