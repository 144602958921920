define('modules/videos/pluto/pluto-sp',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'pubsub',
    'modules/videos/pluto/pluto-title-bar'
],
function(
    $,
    _,
    BaseView,
    Utils,
    PubSub,
    PlutoTitleBar
) {
    'use strict';
    var PlutoSP = BaseView.extend(
        {
            events: {},
            initialize: function(options){
                options = $.extend({
                    plutoVideo: null,
                    analytics: {
                        clickedClose: 'dockedplayerclosed',
                        collapsedOnScroll: 'dockedplayercollapsed',
                        open: 'dockedplayeropen'
                    }
                }, options);

                BaseView.prototype.initialize.call(this, options);

                if (Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'titleBar')) {
                    this.subviews.tb = new PlutoTitleBar({
                        el: this.$('.js-ptb'),
                        onClose: _.bind(this.onClose, this),
                        title: this.options.plutoVideo.getTitle()
                    });
                }

                this.$win = Utils.get('win');
                this.vidHeight = this.$el.height();
                this.vidWidth = this.$el.width();
                this.dockedHeight = Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'dockedHeight');
                this.dockedWidth = Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'dockedWidth');
                this.$placeholder = this.$el.prev('.js-video-placeholder');
                this.break = parseFloat(Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'scrollBreakpoint'));
                // If scrollBreakpoint is .75 the video will go into scrolling position when
                //user has scrolled through 75% of the video.
                this.nav = $('.site-header-inner-wrap');
                this.scrollBreakpoint = (this.vidHeight * this.break);

                this.adBlacklist = Utils.getNested(window.site_vars, 'html5video', 'scrollingPlayer', 'adBlacklist');
                this.playerIsScrolling = false;
                this.attachListeners();
            },

            attachListeners: function() {
                this.$win.on('scroll.' + this.cid, _.bind(_.throttle(this.onScroll, 100), this));

            },

            onClose: function() {
                PubSub.trigger('uotrack', this.options.analytics.clickedClose);
                this.playerIsScrolling = false;
                this.updateScrollingClass(false);
                this.resizePlayer(this.vidWidth, this.vidHeight);
                this.options.plutoVideo.pause();
                if (this.options.plutoVideo.buffering) {
                    this.options.plutoVideo.pauseOnLoad = true;
                }
                this.reset();
            },

            onScroll: function() {
                var scrollPos = this.$win.scrollTop();
                this.navOffset = this.nav.position().top + this.nav.height();
                if (scrollPos > this.$el.parent().offset().top + this.scrollBreakpoint - this.navOffset) {
                    if (!this.playerIsScrolling && this.options.plutoVideo.isPlaying() && !this.options.plutoVideo.pauseOnLoad) {
                        this.slideVideoOut();
                    }
                } else {
                    if (this.slidingIn) {
                        this.$el.css('bottom', ($(window).height() - (this.$placeholder.offset().top + this.vidHeight) + $(window).scrollTop()) - 10);
                    }
                    if (this.playerIsScrolling) {
                        this.slideVideoIn(false);
                    }
                }
            },

            reset: function() {
                this.updateScrollingClass(false);
                this.$el.css({'position': 'relative', 'top': '', 'left': '', 'right': '', 'bottom': '',
                    'height': this.vidHeight + 'px', 'width': this.vidWidth + 'px', 'margin-bottom': '', 'z-index': ''});
            },

            resizePlayer: function(w, h) {
                this.options.plutoVideo.vjsPlayer.dimensions(w, h);
            },

            sendOut: function() {
                this.resizePlayer(this.dockedWidth, this.dockedHeight);
                this.$el.css({
                    'bottom': 0,
                    'height': this.dockedHeight,
                    'margin-bottom': 0,
                    'position': 'fixed',
                    'right': 0,
                    'width': this.dockedWidth,
                    'z-index': 99999
                });
            },

            showAdText: function() {
                if (this.subviews.tb) this.subviews.tb.showAdText();
            },

            slideVideoOut: function() {
                if (this.slideInPromise) {
                    this.slideInPromise.done(_.bind(function(){
                        this.moveItOut();
                    }, this));
                } else {
                    this.moveItOut();
                }
            },

            slideVideoIn: function() {
                this.slideInPromise = $.Deferred();

                this.slideOutPromise.done(_.bind(function() {
                    if (this.subviews.tb) this.subviews.tb.collapse();
                    this.playerIsScrolling = false;
                    this.slidingIn = true;
                    this.$el.css({
                        'bottom': ($(window).height() - (this.$placeholder.offset().top + this.vidHeight) + $(window).scrollTop()) - 10,
                        'right': $(window).width() - (this.$placeholder.offset().left + this.vidWidth),
                        'height': this.vidHeight,
                        'width': this.vidWidth,
                        'margin': ''
                    });
                    this.resizePlayer(this.vidWidth, this.vidHeight);
                    this.$el.one(this.getTransitionEndListener(), _.bind(function() {
                        this.slidingIn = false;
                        this.reset();
                        this.$el.off(this.getTransitionEndListener());
                        window.videoIsDocked = false;
                        PubSub.trigger('uotrack', this.options.analytics.collapsedOnScroll);
                        this.slideInPromise.resolve();
                    }, this));
                }, this));
            },

            moveItOut: function() {
                this.playerIsScrolling = true;
                this.slideOutPromise = $.Deferred();
                // we need to fix the player in the exact position it is currently in and then animate it down
                var offset = $(window).height() - this.vidHeight + (this.vidHeight - this.scrollBreakpoint) + this.navOffset;
                this.$el.css({
                    'bottom': offset,
                    'margin-bottom': 0,
                    'position': 'fixed',
                    'z-index': 99999,
                    'right': $(window).width() - (this.$el.offset().left + this.vidWidth)
                });
                if (this.subviews.tb) this.subviews.tb.show();
                this.updateScrollingClass(true);
                this.resizePlayer(this.dockedWidth, this.dockedHeight);
                this.$el.css({
                    'right': 0,
                    'bottom': 0,
                    'width': this.dockedWidth,
                    'height': this.dockedHeight
                });

                this.$el.one(this.getTransitionEndListener(), _.bind(function() {
                    this.$el.off(this.getTransitionEndListener());
                    window.videoIsDocked = true;
                    PubSub.trigger('uotrack', this.options.analytics.open);
                    this.slideOutPromise.resolve();
                }, this));
            },

            updateScrollingClass: function(add) {
                if (add && this.playerIsScrolling) this.$el.parent().addClass('playerIsScrolling');
                else this.$el.parent().removeClass('playerIsScrolling');
            },

            updateTitle: function(text) {
                if (this.subviews.tb) this.subviews.tb.setTitle(text);
            },

            /***
             * Update the scrolling video to show the provided PlutoVideo view
             * @param plutoVideo - a PlutoVideo view
             */
            updateVideo: function(plutoVideo) {
                this.reset();
                this.options = $.extend(this.options, {el: plutoVideo.el, plutoVideo: plutoVideo});
                this.setElement(plutoVideo.el);
                if (this.subviews.tb) this.subviews.tb.setEl(this.$('.js-ptb'));
                this.updateTitle(plutoVideo.getTitle());
                this.$placeholder = this.$el.prev('.js-video-placeholder');

                if (this.playerIsScrolling) {
                    this.sendOut();
                    if (this.subviews.tb) this.subviews.tb.show();
                    this.updateScrollingClass(this.playerIsScrolling);
                }
            },

            getTransitionEndListener: function() {
                if (this.transitionType) return this.transitionType;

                this.transitionType = 'transitionend';
                var transitions = {
                    'WebkitTransition': 'webkitTransitionEnd',
                    'OTransition': 'oTransitionEnd',
                    'MozTransition': 'transitionend',
                    'transition': 'transitionend'
                };

                for (var transition in transitions) {
                    if (this.el.style[transition] !== undefined) {
                        this.transitionType = transitions[transition];
                        break;
                    }
                }

                return this.transitionType;
            },

            destroy: function(){
                this.reset();
                BaseView.prototype.destroy.call(this);
            }
        }
    );

    return PlutoSP;
});
