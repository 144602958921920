define('modules/videos/pluto/iris-recommendation',[
    'jquery',
    'underscore',
    'baseview',
    'fwinfo',
    'managers/routemanager',
    'state',
    'utils'
],
function(
    $,
    _,
    BaseView,
    FwInfo,
    RouteManager,
    StateManager,
    Utils
) {
    'use strict';

    var IrisRecommendation = BaseView.extend({

        initialize: function (options) {
            options = $.extend({
                id: null,
                placementName: null,
                playRecommendedVideo: null,
                resetVideoState: null,
                videoId: null,
                vjsPlayer: null
            }, options);

            BaseView.prototype.initialize.call(this, options);
            _.bindAll(this, 'currentDuration', 'currentTime', 'playNext');

            this.irisOptions = {
                settings: {
                    client_token: Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'client_token'),
                    number: Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'number'),
                    platform: "Brightcove",
                    platform_id: this.options.videoId,
                    player_id: this.options.id,
                    ssl: true
                },
                iris_buttons: {
                    thumbs_up: Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'thumbs_up'),
                    thumbs_down: Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'thumbs_down'),
                    skip_forward: Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'skip_forward'),
                    skip_back: Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'skip_back')
                }
            };

            this.texttype = StateManager.getActivePageInfo().texttype || 'story';
            this.pageType = StateManager.getActivePageInfo().basePageType || '';

            this.initPlugin();
        },

        currentDuration: function () {
            return this.options.vjsPlayer.duration() || 0;
        },

        currentTime: function () {
            try{
                return this.options.vjsPlayer.currentTime();
            } catch (err) {
                console.log(err);
                return 0;
            }
        },

        initPlugin: function () {
            this.requireScript().done(_.bind(function () {
                this.irisEngine = window.initializeIrisPlugin(this.irisOptions);
                this.irisEngine.registerFunction("currentDuration", this.currentDuration);
                this.irisEngine.registerFunction("currentTime", this.currentTime);
                this.irisEngine.registerFunction("onAdEnd", this.onAdEnd());
                this.irisEngine.registerFunction("onAdPlay", this.onAdPlay());
                this.irisEngine.registerFunction("play", this.playNext);
            }, this));
        },

        logError: function (details, videoID) {
            if (Utils.logAction) {
                Utils.logAction('IrisRecommendation', {
                    'type': 'recommendationError',
                    'details': details,
                    'placement': this.options.placementName,
                    'domain': Utils.getNested(window.site_vars, 'site_key'),
                    'siteCode': Utils.getNested(window.site_vars, 'site_code'),
                    'videoId': videoID ? videoID : ''
                });
            }
        },

        onAdEnd: function () {
            this.irisEngine.emit("AD_END", true);
            this.$$('.ib-ctrl').css("display", "block");
        },

        onAdPlay: function () {
            this.irisEngine.emit("AD_START");
        },

        playNext: function (asset) {
            var next_video_url = '';
            this.response = {};
            if (asset && asset.referenceId) {
                StateManager.fetchData('/videos/services/' + asset.referenceId).done(_.bind(function (response) {
                    this.response = response;
                    if (this.response) {
                        FwInfo.setsessionvalue('clickName', 'iris-autoplay');
                        if (this.pageType === 'video-cinematic' || (['video', 'video-playlist', 'brightcove-video-playlist'].indexOf(this.texttype) !== -1)) {
                            if (this.pageType === 'video-cinematic') next_video_url = "/media/cinematic/video/" + asset.referenceId;
                            else {
                                next_video_url = response.url.replace(/^.*\/\/[^\/]+/, '');
                            }
                            RouteManager.goTo(next_video_url);
                        } else {
                            this.options.playRecommendedVideo(this.response);
                        }
                    }
                }, this)).fail(_.bind(function () {
                    this.logError('Fetch data error', asset.referenceId);
                }, this));
            } else {
                this.logError('Empty recommendation');
                this.options.resetVideoState();
            }
        },

        requireScript: function () {
            var requirePromise = $.Deferred();
            var req = require.config({
                'paths': {
                    'irisRecommendation': Utils.getNested(window.site_vars, 'THIRDPARTYAPI', 'Iris', 'script_url')
                }
            });
            req(['irisRecommendation'], _.bind(function () {
                requirePromise.resolve();
            }, this));

            return requirePromise;
        }
    });
    return IrisRecommendation;
});
