define('modules/partner/branded-content-tile',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'adPosition',
    'adLogger'
], function(
    $,
    _,
    BaseView,
    Utils,
    AdPosition,
    AdLogger
) {
    'use strict';

    var BrandedContentTile = BaseView.extend({

        receivedData: false,
        count: 20,
        setup: false,

        initialize: function(options) {
            var cardSize = (options.layout === 'flexCard') ? [155, 224] : [230, 250];

            BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_onTileReady', '_onSafeFrameMsg', '_setupEvergreen');

            this.$placeholder = this.$el.parent().children('.partner-tile-placeholder');

            // Branded Content Tile
            this.subviews.adPosition = new AdPosition({
                el: options.el,
                adType : 'Branded Content Tile',
                adSizes : [cardSize, [2, 3], 'fluid'],
                adPlacement : 'native-front_tile',
                onAdReady: this._onTileReady,
                onNoAd: this._setupEvergreen
            });

            // If adblock, setup evergreen
            if(window._sp_ && typeof window._sp_.checkState === 'function') {
                window._sp_.checkState(_.bind(function(adblocked) {
                    if(adblocked) {
                        this._setupEvergreen();
                    }
                }, this));
            }

            window.addEventListener("message", this._onSafeFrameMsg, false);

            // Nuclear option, if sourcepoint fails to let us know ads are blocked (Ghostry)
            // of if DFP takes way to long we'll trigger evergreen
            this.evergreenTO = setTimeout(this._setupEvergreen, (Utils.getNested(window.site_vars, 'ADS', 'branded_content_tile', 'timeout') || 6000));
        },

        _onTileReady: function() {
            var slot = this.$('.ad-slot > div > iframe')[0],
                slotWin = slot.contentWindow,
                origin = document.location.origin,
                message = JSON.stringify({
                    bcTile: true,
                    origin: origin
                });
            clearTimeout(this.evergreenTO);

            AdLogger.logInfo("Sending Message to Branded Content Tile Ad Slot", message);

            // Send a postmessage with our origin to the slot, so it can commicate back to us
            try {
                // Should error if this is a cross domain ad slot (from google)
                var slotOrigin = Utils.getNested(slotWin, 'location', 'origin');
                this.msgInt = setInterval(_.bind(function() {
                    this._sendMsg(slotWin, message, slotOrigin);
                }, this), 100);
            } catch(err) {
                this.msgInt = setInterval(_.bind(function() {
                    this._sendMsg(slotWin, message);
                }, this), 100);
            }
        },

        _sendMsg: function(slotWin, message, slotOrigin) {
            this.count--;
            if(!this.count || this.receivedData) {
                clearInterval(this.msgInt);
            }
            slotOrigin = (!slotOrigin || slotOrigin === 'null') ? window.location.protocol + '//tpc.googlesyndication.com' : slotOrigin;
            slotWin.postMessage(message, slotOrigin);
        },

        _onSafeFrameMsg: function(e) {
            // Check if the message is coming from a google iframe, and if we have the start of a JSON object
            if(e && _.isString(e.data) && e.data.charAt(0) === '{' && !this.receivedData) {
                try {
                    var data = JSON.parse(e.data);
                    if(data.ad_type === 'tile') {
                        this.receivedData = true;
                        AdLogger.logInfo('Branded Content Tile Data:', data);
                        if(!data.section) data.section = 'sponsor-story';
                        // remove any origin from url so it's relative
                        data.url = data.url.replace(/^.+\:\/\/[^\/]+/, '');
                        this._setupTile(data);
                    }
                } catch(err) {
                    AdLogger.logInfo("Branded Content Tile Failed to parse JSON data");
                    // TODO: Fallback gets added here
                }
            }
        },

        _setupEvergreen: function() {
            var evergreen = Utils.getNested(window.site_vars, 'ADS', 'branded_content_tile', 'evergreen'),
                template = '<li class="hgpm-item partner-tile" itemscope="" itemtype="https://schema.org/<%= section %>Article" data-asset-position="9" data-asset-id="<%= asset_id %>">' +
                        '<a itemprop="url" class="hgpm-link hgpm-image-link hgpm-link-9 hgpm-link-col-first <%= section %>-theme-bg-hover <%= section %>-theme-border-hover js-asset-link" href="<%= url %>" data-track-label="hedgrid_9" data-asset-id="<%= asset_id %>">' +
                            '<span class="hgpm-grid-wrap js-grid-wrap">' +
                                '<span class="js-asset-section theme-bg-ssts-label hgsm-ssts-label-top-left placeholder-bg <%= section %>-theme-bg"><%= section_title %> <%= sponsor %></span>' +
                                '<span class="hgpm-image-gradient"></span>' +
                                '<div class="hgpfm-image js-asset-image" itemprop="image" data-crop="1_1" style="background-image:url(<%= image %>)"></div>' +
                                '<span class="hgpm-image-hed-wrap hgpm-image-text-hed-wrap">' +
                                    '<span class="hgpm-image-text-hed-icon js-asset-disposable"></span>' +
                                    '<p itemprop="headline" class="hgpm-image-hed js-asset-headline placeholder-bg "><%= headline %></p>' +
                                '</span>' +
                            '</span>' +
                            '<span class="hgpm-list-wrap js-list-wrap">' +
                                '<span class="hgpm-list-image-wrap">' +
                                    '<div class="hgpm-list-image js-asset-image" itemprop="thumbnailUrl" data-crop="16_9" style="background-image:url(<%= image %>)"></div>' +
                                    '<span class="hgpm-image-text-hed-icon js-asset-disposable"></span>' +
                                '</span>' +
                                '<span class="hgpm-list-text ">' +
                                    '<span itemprop="headline" class="hgpm-list-hed js-asset-headline placeholder-bg"><%= headline %></span>' +
                                    '<span class="hgpm-back-listview-text js-asset-description placeholder-bg" data-fulltext="<%= promobrief %>"><%= promobrief %></span>' +
                                    '<ul class="hgpm-meta">' +
                                        '<li itemprop="articleSection" class="hgpm-section <%= section %>-theme-color ">' +
                                            '<span class="js-asset-section theme-bg-ssts-label hgpm-ssts-label-listview placeholder-bg <%= section %>-theme-color default-theme-color"><%= section_title %> <%= sponsor %></span>' +
                                        '</li>' +
                                    '</ul>' +
                                '</span>' +
                            '</span>' +
                        '</a>' +
                    '</li>',
                flexTemplate = '<a class="hgpfm-link hgpfm-image-link hgpfm-link-4 <%= section %>-theme-bg-hover <%= section %>-theme-border-hover js-asset-link partner-tile" href="<%= url %>" data-ht="hedgrid2_4" data-asset-position="4" data-asset-id="<%= asset_id %>">' +
                        '<span class="js-asset-section theme-bg-ssts-label hgsm-ssts-label-top-left <%= section %>-theme-bg"><%= section_title %> <%= sponsor %></span>' +
                        '<span class="hgpfm-image-gradient"></span>' +
                        '<div class="hgpfm-image-wrap">' +
                            '<div class="hgpfm-image js-asset-image placeholder-hide" style="background-image:url(<%= image %>)"></div>' +
                        '</div>' +
                        '<div class="hgpfm-image-hed-wrap hgpfm-image-text-hed-wrap">' +
                            '<span class="hgpfm-image-text-hed-icon js-asset-disposable"></span>' +
                            '<p class="hgpfm-image-hed js-asset-headline placeholder-hide"><%= headline %></p>' +
                        '</div>' +
                    '</a>';

            clearTimeout(this.evergreenTO);

            if(evergreen) {
                evergreen.template = (this.options.layout === 'flexCard') ? flexTemplate : template;
                this._setupTile(evergreen);
            }
        },

        _setupTile: function(data) {
            var tileTemplate = _.template(data.template),
                $tile = $(tileTemplate(data));

            if(!this.setup) {
                this.setup = true;
                // Add DFP click tracking pixel
                if (data.clickUrl) {
                    $tile.on('click', function() {
                        $("<img />").attr('src', data.clickUrl);
                    });
                }
                // Add third-party click tracking pixel
                if(data.click) {
                    $tile.on('click', function() {
                        if(/<[a-z][\s\S]*>/i.test(data.click)) {
                            // Hey we have a full tag
                            $(data.click);
                        } else {
                            // We should have just a url
                            $("<img />").attr('src', data.click);
                        }
                    });
                }
                this.$placeholder.hide().after($tile);
            }
        },

        /**
         * Clean up view.
         * Removes event handlers and element (optionally).
         * @param {boolean} removeEl option to also remove View from DOM.
         */
        destroy: function(removeEl) {
            // Remove our existing tile and show the placeholder
            this.$el.parent().children('.partner-tile, .mediavoice-native-ad').remove();
            this.$placeholder.show();

            window.removeEventListener("message", this._onSafeFrameMsg, false);
            clearInterval(this.msgInt);
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });
    return BrandedContentTile;
});

