/**
 * @fileoverview Insider view module.
 * @author rcopley@gannett.com (Ryan Copley) and Dave Burke (dburke2@gannett.com)
 */
define('modules/insider-promo',[
    'jquery',
    'underscore',
    'baseview',
    'user-manager',
    'pubsub'
],
function(
    $,
    _,
    BaseView,
    UserManager,
    PubSub
) {

        /**
         * View class.
         */
         //Used to restore the meta viewport when we destroy
        var InsiderPromoView = BaseView.extend({
            /**
             * Initialize view.
             */
            initialize: function() {
                // call base class initialize
                BaseView.prototype.initialize.call(this);
                // show the content specific to each login status
                this._toggleSubscribeContent();
                // add ellipsis if text exceeds container bounds
                this.addEllipsisToHeadline();
                // updates eVar66
                PubSub.trigger('product_name', 'Insider Lite');
            },
            addEllipsisToHeadline: function() {
                this.$('.fwi-front-fwifm-deal-module').each(function() {
                    var next_headline = $(this).find('.fwi-front-fwifm-deal-module-h1');
                    var headline_height =  next_headline.height();
                    while (next_headline[0].scrollHeight > headline_height) {
                        var text = next_headline.html().replace(/\W*\s(\S)*$/, '&hellip;');
                        next_headline.html(text);
                    }
                });
            },
            _toggleSubscribeContent: function() {
                var userAccount = UserManager.getAccount('firefly');
                if (userAccount) {
                    userAccount.getUserInfo().always(_.bind(function (userInfo) {
                        var insiderPromoSubscribe = this.$(".insider-promo-subscribe");
                        var insiderPromoWelcomeUser = this.$(".insider-promo-welcome-user");
                        var sectionHeaderWelcome = this.$(".fwi-front-fwifm-message-center-h2");
                        if (userInfo && userInfo !== "Not logged in." && userInfo.hasMarketAccess === true) {
                            var insiderDetailsView = this.$(".sam-hider");
                            var insiderDetailsBlockerView = this.$(".sam-unhider");

                            insiderDetailsView.css("display", "");
                            insiderDetailsBlockerView.css("display", "none");

                            insiderPromoSubscribe.removeClass('fwi-front-fwifm-insider-promo-visible').addClass('fwi-front-fwifm-insider-promo-hidden');
                            insiderPromoWelcomeUser.removeClass('fwi-front-fwifm-insider-promo-hidden').addClass('fwi-front-fwifm-insider-promo-visible').css("opacity", "0.0").animate({ opacity: 1.0 }, 1000);
                            sectionHeaderWelcome.html("Hi, " + userInfo.welcomeName + "!");

                        } else {
                            if (userInfo && userInfo !== 'Not logged in.' && userInfo.hasMarketAccess === false) {
                                // first button and title
                                this.$('.fwi-front-fwifm-first-btn-title').html('Gain full access to site content and enjoy exclusive deals and events!');
                                var onSuccessRedirectURL = encodeURIComponent(window.firefly_urls.onSuccessRedirectURL) + '&amp;gps-source=CPINSIDER';
                                this.$('.fwi-front-fwifm-first-btn').html('SUBSCRIBE').attr('href', window.firefly_urls.samSubscribeURL + '?onSuccessRedirectURL=' + onSuccessRedirectURL);

                                this.$('.fwi-front-fwifm-bottom-links').addClass('fwinsider-content-hidden');
                            }

                            insiderPromoSubscribe.removeClass('fwi-front-fwifm-insider-promo-hidden').addClass('fwi-front-fwifm-insider-promo-visible').css("opacity", "0.0").animate({ opacity: 1.0 }, 1000);
                            insiderPromoWelcomeUser.removeClass('fwi-front-fwifm-insider-promo-visible').addClass('fwi-front-fwifm-insider-promo-hidden');
                            sectionHeaderWelcome.html("");
                        }
                    }, this));
                }
            }

        });

        /**
         * Return view class.
         */
        return InsiderPromoView;
    }
);

