/*global grecaptcha*/
/**
 * @fileoverview Email share view.
 * @author Erik Kallevig <ekallevig@gannett.com>
 */
define('modules/stories/share-email',[
    'jquery',
    'underscore',
    'backbone',
    'pubsub',
    'baseview',
    'state',
    'utils',
    'form/input-field'
],
function(
    $,
    _,
    Backbone,
    PubSub,
    BaseView,
    StateManager,
    Utils,
    InputField
) {

    /**
     * View class.
     */
    var ShareEmail = BaseView.extend({

        // Events.
        events: {
            'click .recaptcha_option': 'onRecaptcha',
            'click .js-share-email-cancel-btn': 'onClickCancelBtn',
            'click .js-share-email-send-btn': 'onSubmitEmailForm'
        },

        /**
         * Initialize view.
         */
        initialize: function(options) {
            options = $.extend({
                uotrackName: 'UtilityBarShareEmail',
                message: '',
                theme: 'light'
            }, options);

            _.bindAll(this, 'initInputs', 'sendEmail');
            this.subviews = {};
            this.$recaptchaV2 = this.$('#recaptcha-share');
            this.v2_captcha_available = true;
            this.$emailForm = this.$('.js-share-email-form');
            this.$emailFormContainer = this.$('.js-share-email-form-container');
            this.$successPane = this.$('.js-share-email-form-success-pane');
            this.$shareTextarea = this.$('.js-share-email-form-textarea');
            this.$shareTextareaOldVal = this.$shareTextarea.val();

            if (this.$emailForm.length) {
                if (this.$recaptchaV2.length) {
                    this.initInputs();
                    this.initCaptcha();
                } else {
                    this.setRecaptcha();
                    Utils.requireSingleUseScript('https://www.google.com/recaptcha/api/challenge?k=6Lf7fuESAAAAAJ3_KMIDbkQySsEE0vMkLXUkq4eY')
                        .done(_.delay(this.initInputs, 300));
                }
            }

            BaseView.prototype.initialize.call(this, options);
        },

        /**
         * Create the input views
         */
        initInputs: function() {
            this.$inputs = this.$('.ui-text-input');

            _.each(this.$inputs, function($input, i) {
                if (this.subviews['input' + i]) {
                    this.subviews['input' + i].destroy();
                }
                this.subviews['input' + i] = new InputField({
                    el: $input
                });
            }, this);
        },

        /**
         * Create recaptcha box, script must be loaded first.
         */
        initCaptcha: function() {
            if (this.$recaptchaV2.is(":empty")) {
                if (this.$recaptchaV2.data('key')) {
                    grecaptcha.render('recaptcha-share', {
                        'sitekey' : this.$recaptchaV2.data('key'),
                        'callback' : this.sendEmail,
                        'theme': this.options.theme,
                        'size': 'invisible',
                        'badge': 'inline'
                    });
                } else {
                    this.v2_captcha_available = false;
                }
            }
        },

        /**
         * Remove any other recaptcha instances then insert recaptcha template to current view.
         * Can only have one recaptcha instance on the window at a time
         */
        setRecaptcha: function() {
            $('.recaptcha_widget').remove();

            var $recaptchaTemplate = $('#recaptcha_widget').html();
            this.$('.js-email-form-recaptcha-widget').html($recaptchaTemplate);

            //since we have to reuse the same captcha widget -
            // style the response field correctly for the share modal form
            if(this.$el.attr('id') === 'modalEmailForm') {
                $('#recaptcha_response_field').addClass('ui-light');
            }
        },

        /**
         * Submit handler for 'email' form.
         */
        onSubmitEmailForm: function(e) {
            e.preventDefault();
            if (this.isValid(this.$emailForm)) {
                if (this.$recaptchaV2.length) {
                    if (this.v2_captcha_available){
                        grecaptcha.execute();
                    } else {
                        this.sendEmail("v2_captcha_unavailable");
                    }
                } else {
                    this.sendEmail("v1");
                }
            }
        },

        /**
         * Callback from captcha, sends actual email
         */
        sendEmail: function(captcha_response) {
            this.$emailForm.children('input,textarea').removeClass('error');
            $.ajax({
                type: 'POST',
                url: this.$emailForm.attr('action'),
                data: this.$emailForm.serialize()
            }).done(_.bind(function(response) {
                    this.onSuccessfulSubmission();
                    // TODO: Add error checking when endpoint is fixed.
                    // TODO: Add stock text/link to email body.
                }, this)).fail(_.bind(function(response) {
                    this.onFailedSubmission();
                }, this));
        },

        /**
         *  Show success pane and hide/reset form for future use
         */
        onSuccessfulSubmission: function() {
            this.showSuccessPane();
            this.triggerEvent('clearInputValue');
            PubSub.trigger('uotrack', this.options.uotrackName);
            if (this.$recaptchaV2.length) {
                if (this.v2_captcha_available){
                    grecaptcha.reset();
                }
            } else {
                this.reloadRecaptcha();
            }
        },

        /**
         *  Show errors
         */
        onFailedSubmission: function() {
            if (this.$recaptchaV2.length) {
                this.$emailForm.children('input,textarea').addClass('error');
                if (this.v2_captcha_available){
                    grecaptcha.reset();
                }
            } else {
                this.$('#recaptcha_response_field').addClass('error');
                window.Recaptcha.reload();
            }
        },


        showSuccessPane: function() {
            this.hideEmailForm();
            this.$successPane.show();
        },

        /**
         * Show email form after hiding success pane if it isn't already hidden
         * Only used in longform
         */
        showEmailForm: function() {
            this.hideConfirmationPane();
            this.setupShareText();
            this.$el.show();
            this.$emailFormContainer.show();
        },

        /**
         * Sets the share text
         */
        setupShareText: function() {
            this.$shareTextarea.val(this.options.message + '\n' + this.options.postLink + '\n\n' + this.$shareTextareaOldVal);

            //textarea scrollbar won't appear when manually adding text this way, adjust height so scrollbar displays
            this.$shareTextarea.height(40);
        },

        /**
         * Hide the email form
         */
        hideEmailForm: function() {
            this.$emailFormContainer.hide();
        },

        /**
         * Hide success pane
         */
        hideConfirmationPane: function() {
            this.$successPane.hide();
        },

        /**
         * Validate email form.
         */
        isValid: function($form) {
            // TODO: Add validation for email addresses if possible.
            var valid = true;
            $form.children('input,textarea').removeClass('error').each(function(index, el) {
                var $el = $(el);
                if ($el.hasClass('required') && $el.val() === '') {
                    $el.addClass('error');
                    valid = false;
                }
            });
            return valid;
        },

        /**
         * Click handler for 'cancel' button.
         */
        onClickCancelBtn: function(e) {
            if (this.$el.attr('id') === 'modalEmailForm') {
                StateManager.getActiveApp().$('.social-share-modal-close-btn').trigger('click');
            } else {
                StateManager.getActiveApp().$('.js-share-email-btn').trigger('click');
            }
        },

        onRecaptcha: function(e) {
            var recaptchaOption = $(e.currentTarget).data('option');

            if(recaptchaOption === 'audio' || recaptchaOption === 'image') {
                this.switchRecaptchaType(recaptchaOption);
            } else if (recaptchaOption === 'help') {
                this.showRecaptchaHelp();
            } else {
                this.reloadRecaptcha();
            }
        },

        /**
         * Switch the recaptcha type
         * @param {String} type image or audio
         */
        switchRecaptchaType: function(type) {
            window.Recaptcha.switch_type(type);
        },

        /**
         * Show the recaptcha help popup
         */
        showRecaptchaHelp: function() {
            window.Recaptcha.showhelp();
        },

        /**
         * Reload recaptcha - retrieves a new image or audio sample
         */
        reloadRecaptcha: function() {
            window.Recaptcha.reload();
        }
    });

    return ShareEmail;
});

