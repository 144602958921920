/**
 * @fileoverview JobsSearch
 * @author zaudette@gannett.com (Zac Audette)
 */
define('modules/jobs-search/jobs-search',[
    'jquery',
    'underscore',
    'state',
    'baseview',
    'pubsub'
],
function(
    $,
    _,
    StateManager,
    BaseView,
    PubSub
    ) {
    "use strict";

    var JobsSearch = BaseView.extend({

        events: {
            'click .jobs-search-submit' : 'searchJobs',
            'submit .jobs-search-form' : 'searchJobs',
            'click .jsm-close-icon': 'closeSuggestions',
            'click .jsm-suggestions-list-item': 'selectSuggestion',
            'input .jobs-search-text-input': 'onInputChange'
        },

        initialize: function() {
            BaseView.prototype.initialize.call(this);
            this.form = document.getElementById("jobs-search-form");
            this.populateSuggestions = _.debounce(this.getSuggestions, 200);
        },

        searchJobs: function(event) {
            var url = this.form.getAttribute("data-url");

            var keyword = document.getElementById("jobs-search-keyword") ? document.getElementById("jobs-search-keyword").value : '';
            var location = document.getElementById("jobs-search-location") ? document.getElementById("jobs-search-location").value : '';

            this.encoded_keyword = this.encodeSearchTerm(keyword);
            this.encoded_location = this.encodeSearchTerm(location);

            url += 'q-' + this.encoded_keyword + '-l-' + this.encoded_location;

            var affiliateId = this.form.getAttribute("data-affiliateId");
            if (affiliateId) {
                url += '?affiliateid=' + affiliateId;
            }

            url += 'source=1&countryId=3';

            var radius = this.form.getAttribute("data-radius");
            if (radius) {
                url += '&radius=' + radius;
            }
            if (!keyword) {
                keyword = "n/a";
            }
             if (!location) {
                location = "n/a";
            }

            this.trackLabel = "thejobnetwork|findjobs|" + keyword + "|" + location;
            PubSub.trigger('track', {
                                label: this.trackLabel
                            });
            window.open(url, "_blank");
        },

        encodeSearchTerm: function(searchTerm){
            var stringReplacements = {
                    '/': '-or-',
                    '\\': '-or-',
                    '.': '-dot-',
                    '&': '-and-',
                    '+': '-plus-',
                    '%': '-perc-',
                    '?': '-ques-',
                    '#': '-hash-',
                    '$': '-dlr-',
                    '*': '-star-',
                    '!': '-mrk-',
                    '"': '-quat-',
                    '\'': '-apos-',
                    '<': '-lt-',
                    '>': '-gt-',
                    '|': '-pipe-',
                    ':': '-cln-'
                };
                var encodedSearchTerm = '';
                for (var i=0; i < searchTerm.length; i++) {
                    encodedSearchTerm = 'undefined' !== typeof stringReplacements[searchTerm[i]] ? encodedSearchTerm + stringReplacements[searchTerm[i]] : encodedSearchTerm + encodeURIComponent(searchTerm[i]);
                }
                return encodedSearchTerm;
        },

        onInputChange: function (e) {
            var searchTerm = e.target.value;

            if (searchTerm) {
                if (e.target.className === 'jobs-search-text-input jobs-search-keyword') {
                    this.populateSuggestions(searchTerm, 'Title', 'jsm-keywords');
                    $('.jsm-keyword-suggestions').removeClass('hide-suggestions');

                }
                if (e.target.className === 'jobs-search-text-input jobs-search-location') {
                    this.populateSuggestions(searchTerm, 'Location', 'jsm-locations');
                    $('.jsm-location-suggestions').removeClass('hide-suggestions');
                }
            }
            else {
                if (e.target.className === 'jobs-search-text-input jobs-search-keyword') {
                    $('.jsm-keyword-suggestions').addClass('hide-suggestions');
                }
                if (e.target.className === 'jobs-search-text-input jobs-search-location') {
                    $('.jsm-location-suggestions').addClass('hide-suggestions');
                }
            }
        },

        closeSuggestions: function () {
            this.$('.jsm-search-suggestions').addClass('hidden');
        },

        selectSuggestion: function (e) {
            var keywordElement = document.getElementById("jobs-search-keyword");
            var locationElement = document.getElementById("jobs-search-location");

            if(e.target.parentNode.className === 'jsm-suggestions-list jsm-keywords') {
                keywordElement.value = e.target.textContent;
                $('.jsm-keyword-suggestions').addClass('hidden');
            }

            if(e.target.parentNode.className === 'jsm-suggestions-list jsm-locations') {
                locationElement.value = e.target.textContent;
                $('.jsm-location-suggestions').addClass('hidden');
            }
        },

        getSuggestions: function (searchTerm, type, elementId) {
             StateManager.fetchData(null, {
                url: ('/services/jobs/suggestions/' + type + '/' + searchTerm)
             }).done(function (data) {
                    var el = document.getElementById(elementId);
                    while (el.firstChild) {
                        el.removeChild(el.firstChild);
                    }
                    var suggestions = data.Data;
                    if (suggestions) {
                        suggestions.forEach(function(suggestion){
                            var listItem = document.createElement('li');
                            listItem.innerText = suggestion[type];
                            listItem.className = 'jsm-suggestions-list-item';
                            document.getElementById(elementId).appendChild(listItem);
                        });
                        if (elementId === 'jsm-keywords') {
                            $('.jsm-keyword-suggestions').removeClass('hidden');
                        }
                        else if (elementId === 'jsm-locations') {
                            $('.jsm-location-suggestions').removeClass('hidden');
                        }
                    }
                    if (!el.firstChild){
                        $('.jsm-search-suggestions').addClass('hidden');
                    }
             }, this);
        }
    });

    return JobsSearch;
}
);

