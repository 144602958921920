/* global define */
/* global grecaptcha */
define('modules/newsletter-signup-condensed/newsletter-signup-condensed',[
        'jquery',
        'underscore',
        'baseview',
        'state',
        'user-manager',
        'pubsub',
        'managers/cachemanager'
    ],
    function(
        $,
        _,
        BaseView,
        StateManager,
        UserManager,
        PubSub,
        CacheManager
    ) {
        'use strict';
        var NewsletterSignupCondensed = BaseView.extend({

            validEmail: function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            events: {
                'submit': 'onFormSubmit',
                'input .nscm-email': 'validateEmail'
            },

            /**
             * Initialize view.
             */
            initialize: function(options) {
                // caching selectors
                this.$nlTitle = this.$('.nscm-title');
                this.$nlForm = this.$('.nscm-form');
                this.$nlFormAction = this.$nlForm.attr('action');
                this.$emailField = this.$('.nscm-email');
                this.$businessUnitCode = this.$nlForm.data("business-unit");
                this.$nlSubmit = this.$('.nscm-submit');
                this.$ssts = this.$nlSubmit.data("ssts");
                this.$nlMsgCard = this.$('.nscm-message-card');
                this.$nlCardSuccess = this.$('.nscm-message-card .success');
                this.$nlCardFailure = this.$('.nscm-message-card .fail');

                this.userAccount = UserManager.getAccount('firefly');
                if (this.userAccount) {
                    this.userAccount.getUserInfo().done(_.bind(function(userInfo) {
                        if (userInfo.email) {
                            this.$currentUserEmail = userInfo.email;
                            this.$emailField.val(this.$currentUserEmail);
                            this.$emailField.removeClass("invalid").addClass("valid");
                            this.newsletterLocal = CacheManager.getValue(this.$currentUserEmail + '_newsletter', null, 1);

                            if (this.newsletterLocal) {
                                var userAlreadySubscribed = false;
                                $.each(this.newsletterLocal.subscriptions, function(i,listCode) {
                                     if (!$('.newsletter-' + listCode)) {
                                        userAlreadySubscribed = true;
                                    }
                                });
                                if (!userAlreadySubscribed){
                                    $('.nscm-container').show();
                                }

                            } else {
                                CacheManager.clearValue(this.$currentUserEmail + '_newsletter');
                                StateManager.fetchData(null, {
                                    url: ('/services/gemsapi/getuser/' + userInfo.email + '/' + this.$businessUnitCode)
                                }).done(function(response) {
                                    var userAlreadySubscribed = false;
                                    if (response.success === true && response.subscriptions) {
                                        $.each(response.subscriptions, function(i,listCode) {
                                            if (!$('.newsletter-' + listCode)) {
                                                userAlreadySubscribed = true;
                                            }
                                        });
                                        if (!userAlreadySubscribed){
                                            $('.nscm-container').show();
                                        }
                                    }

                                });
                            }
                        }
                    }, this));
                    this.userAccount.getUserInfo().fail(function() {
                        $('.nscm-container').show();
                    }, this);
                }

            },

            setSubscriptionsLocal: function(email, subscriptions) {
                var localStorageObject = {
                    'subscriptions': subscriptions
                };
                CacheManager.setValue(email + '_newsletter', localStorageObject, 10);
            },

            onFormSubmit: function(e) {
                e.preventDefault();
                this.validateEmail();
                if (this.validEmail(this.$emailField.val())) {
                    var newsletter_choices = [];
                    newsletter_choices.push({
                        id: this.$nlForm.data("newsletter-id"),
                        signup: true
                    });

                    var newsletter_choices_json = JSON.stringify(newsletter_choices);
                    this.$emailField.removeClass("invalid").removeClass("valid").addClass("updating");
                    this.$emailField.prop('disabled', true);

                    var logged_in = false;
                    if (this.userAccount) {
                        this.userAccount.getUserInfo().always(_.bind(function(userInfo) {
                            if (userInfo && userInfo !== "Not logged in.") {
                                logged_in = true;
                            }
                        }, this));
                    }
                    StateManager.fetchData(null, {
                        type: "POST",
                        url: this.$nlFormAction + this.$emailField.val() + '/' + this.$businessUnitCode + '/',
                        data: {
                            logged_in: logged_in,
                            newsletter_choices: newsletter_choices_json
                        }
                    }).done(_.bind(function(response) {
                        this.$emailField.removeClass('updating');
                        this.$nlForm.hide();
                        this.$nlMsgCard.show();
                        if (response.success === true) {
                            this.$nlMsgCard.removeClass('fail').addClass('success');
                            this.$nlCardFailure.hide();
                            this.$nlCardSuccess.show();
                            this.$ssts = this.$ssts.replace(/,/g, ":");
                            this.trackLabel = "newsletterSignUp|";
                            var choices_array = [this.$nlForm.data("newsletter-id")];
                            this.setSubscriptionsLocal(this.$currentUserEmail, choices_array);
                            
                            PubSub.trigger('track', {
                                label: this.trackLabel
                            });

                        } else {
                            this.$nlMsgCard.removeClass("success").addClass('fail');
                            this.$nlCardFailure.show();
                            this.$nlCardSuccess.hide();
                        }
                    }, this));
                }
            },

            validateEmail: function() {
                if (!this.$emailField.val() || !this.validEmail(this.$emailField.val())) {
                    this.$emailField.removeClass("valid").addClass("invalid");
                } else {
                    this.$emailField.removeClass("invalid").addClass("valid");
                }
            }
        });

        /**
         * Return view class.
         */
        return NewsletterSignupCondensed;
    });
