define('modules/fronts/most-popular-sidebar',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'state'
],
function(
    $,
    _,
    BaseView,
    Utils,
    StateManager
)
{
    'use strict';
    var MostPopularSidebar = BaseView.extend({
        renderCardInfo: function(currentCardInfo) {
            this.currentCardInfo = currentCardInfo;
            if (currentCardInfo.sidebarOpen){
                this.loadLazyImages();
            }
        },
        onCardWidthChange: function(currentCardInfo){
            var sidebarWidthChanged = currentCardInfo.sidebarWidth !== this.currentCardInfo.sidebarWidth;
            this.currentCardInfo = currentCardInfo;
            if (sidebarWidthChanged){
                this.loadLazyImages();
            }
        },
        onOpenSidebar: function() {
            this.loadLazyImages();
        },
        loadLazyImages: function(){
            Utils.lazyLoadImage(this.$('img'), 'data-src-' + this.currentCardInfo.sidebarWidth, true, this.hideImage, this.showImage);
        },
        hideImage: function(img) {
            img.parent().css('height', img.next().outerHeight());
            StateManager.getActiveApp().triggerEvent('refreshSidebarScroll');
        },
        showImage: function(img) {
            img.css('visibility', 'visible');
            StateManager.getActiveApp().triggerEvent('refreshSidebarScroll');
        }
    });
    return MostPopularSidebar;
});

