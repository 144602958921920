define('partner/overlay-ad-fronts',[
    'jquery',
    'underscore',
    'partner/overlay-ad-base',
    'adPosition',
    'partner/leavebehind',
    'adLogger'
],
function(
    $,
    _,
    OverlayAdBase,
    AdPosition,
    LeaveBehind,
    AdLogger
) {
    'use strict';
    var OverlaySharedAd = OverlayAdBase.extend(
    /**
     * @lends partner/overlay-ad-fronts.prototype
     */
    {
        /**
         * @classdesc Shared overlay ad with leave behind, defaults targeting to high_impact ad placement and elastic size
         * subclass of {@link partner/overlay-ad-base}
         * @constructs partner/overlay-ad-fronts
         * @author Jay Merrifield <jmerrifiel@gannett.com>
         * @extends partner/overlay-ad-base
         * @param {Object} options backbone options object
         *     @param {jQuery|Element|String} options.el - element or string selector to attach to
         *     @param {Array<String>} [options.adSizes=['elastic']] - adSize targeting
         *     @param {String} [options.adType=elastic] - adType targeting
         *     @param {String} [options.adPlacement=high_impact] - AdPlacement targeting
         *     @param {Boolean} [options.isCardBumped=true] - Used for specifying if the leave behind is compact or not
         *     @param {Boolean} [options.isNative=false] - Used to signal the leave behind to assume that the interactive takeover is the sponsor logo
         */
        initialize: function(options) {
            options = $.extend({
                leaveBehindEl: $('.partner-leavebehind'),
                adSizes: ['elastic'],
                adType: 'elastic',
                adPlacement: 'high_impact',
                isCardBumped: true,
                isNative: false
            }, options);

            _.bindAll(this, 'onAdReady', 'showAd');

            // call base class initialize
            OverlayAdBase.prototype.initialize.call(this, options);

            this.loadAdPlacement();

            AdLogger.logInfo('Partner Shared Overlay initialized', this);
        },

        /**
         * closes the overlay and destroys the ad
         */
        destroy: function(removeEl) {
            var slot = this.options.slot;
            if(slot) {
                slot.removeClass('show-slot');
            }
            OverlayAdBase.prototype.destroy.call(this, removeEl);
        },

        loadAdPlacement: function() {
            var options = $.extend(this.options, {
                    el: this.$placement,
                    onAdReady: this.onAdReady,
                    beforeAdRender: this.onResize,
                    legacyHighImpact: true
                });
            this.subviews.ad = new AdPosition(options);
        },

        onAdReady: function(adInfo) {
            var slot = this.options.slot;
            //need to have the ad slot visible on the page in order for DFP to track visibile impressions
            if(slot) {
                slot.addClass('show-slot');
            }

            if ((adInfo.leaveBehindImage || ((adInfo.frameBackground || adInfo.frameColor) && adInfo.frameSliver)) && adInfo.leaveBehindText && this.options.leaveBehindEl) {
                this.subviews.adLeaveBehind = new LeaveBehind({
                    el: this.options.leaveBehindEl,
                    cards : this.options.cards,
                    onShowAd: this.showAd,
                    isCompact: this.options.isCardBumped,
                    isNative: this.options.isNative,
                    maxDisplayed: parseInt(adInfo.maxDisplayed, 10),
                    behavior : {
                        clearBgColor : false,
                        hideframe : false,
                        hidebrand : false
                    }
                });
                this.subviews.adLeaveBehind.render(this.subviews.ad, adInfo);
            } else {
                this.showAd();
            }
        },
        close: function(animate, destroy) {
            if (this.subviews.adLeaveBehind && !destroy) {
                this.subviews.adLeaveBehind.show();
            }
            OverlayAdBase.prototype.close.apply(this, arguments);
        }
    });

    return OverlaySharedAd;
});

