/**
 * @fileoverview Election Widget
 * @author cbron@gannett.com (Caleb Bron)
 */
define('modules/election-widget/election-widget',[
        'jquery',
        'underscore',
        'baseview',
        'ui/generic-paginator'
    ],
    function(
        $,
        _,
        BaseView,
        GenericPaginatorView
    ) {
        "use strict";
        /**
         * View class.
         */

        var ElectionWidget = BaseView.extend({
            /**
            * Events
            */
            events: {
                'click .paginator-indicator-bullet' : 'playSlides'
            },

            /**
             * Initialize view.
             */
            initialize: function() {

                BaseView.prototype.initialize.call(this);

                this.indexSelector         = 0;
                this.max_races             = 9;
                this.interval_milliseconds = 7000;
                this.container             = this.$('.pewm-items-container-flex, .pewm-items-container').eq(0);
                this.isFlex                = 0;
                if (this.container && this.container.attr('class')){
                    this.isFlex = this.container.attr('class').indexOf('flex') > -1 ? 1 : 0;
                }
                this.fetchData();

                _.bindAll(this, 'autoScroll', 'goTo');
            },

            /**
             * Scrolling functions
             */

            playSlides: function(){
                if (this.slideChangeInterval){
                    clearInterval(this.slideChangeInterval);
                }
                this.slideChangeInterval = setInterval(_.bind(this.autoScroll, this), this.interval_milliseconds);
            },

            goTo: function(index) {
                this.indexSelector = index;
                var offsetBy = this.viewport.outerWidth();
                var targetOffset = offsetBy * index * -1;
                this.animate(this.items_wrap, 'left', targetOffset+'px', offsetBy, 'ease-in-out');
            },

            autoScroll: function(){
                this.indexSelector++;
                if(this.indexSelector == this.totalSlides){
                    this.indexSelector = 0;
                }
                this.goTo(this.indexSelector);
                this.$paginatorBullets.removeClass("active");
                this.$paginatorBullets.eq(this.indexSelector).addClass("active");
            },

            /**
             * Fetch and populate functions
             */

            fetchData: function(){
                $.ajax({
                    type: 'GET',
                    url: '/election-widget/widget-html.json',
                    data: {
                        'flex': this.isFlex,
                        'key': this.container.data('key'),
                        'ts': Math.floor(Date.now() / 60000)
                    },
                    success: function(data){
                        this.populateData(data);
                    }.bind(this),
                    error: function(){
                        this.noData();
                    }.bind(this)
                });
            },

            noData: function(){
                var noDataStr = '<div class="pewm-items-wrap-flex"><div class="pewm-item-flex pewm-no-results-flex"><span class="pewm-no-results-text-flex">No Available Results</span></div></div>';                
                if (this.isFlex){
                    this.container.html(noDataStr);
                }else{
                    this.container.html(noDataStr.replace(/-flex/g, ""));
                }
            },

            populateData: function(data){
                this.container.html(data.html);
                this.totalSlides = data.length;
                this.items_wrap  = this.$('.pewm-items-wrap, .pewm-items-wrap-flex').eq(0);
                this.viewport    = this.items_wrap.parent();

                if (data.length > 0) {
                    this.$paginatorBullets = this.$(".paginator-indicator-bullet");
                    this.subviews.paginator = new GenericPaginatorView({
                        el: this.$('.paginator'),
                        onGoTo: this.goTo
                    });
                    this.playSlides();
                }else{
                    this.noData();
                }
            },

            destroy: function(e) {
                clearInterval(this.slideChangeInterval);
                BaseView.prototype.destroy.call(this, e); //Remove view from DOM
            }

        });

        /**
         * Return view class.
         */
        return ElectionWidget;
    }
);

