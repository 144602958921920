/**
 * @fileoverview Top nav module.
 * @author ekallevig@gannett.com (Erik Kallevig)
 */

define('modules/global/header',[
    'jquery',
    'underscore',
    'base-app',
    'pubsub',
    'utils',
    'state',
    'user-manager',
    'managers/routemanager',
    'modules/global/breaking-news',
    'adPosition',
    'modules/header/site-nav-more-module',
    'modules/global/userUtil',
    'admanager',
    'abtest-manager'
],
function(
    $,
    _,
    BaseApp,
    PubSub,
    Utils,
    StateManager,
    UserManager,
    RouteManager,
    BreakingNews,
    AdPosition,
    SiteNavMoreModule,
    UserUtil,
    AdManager,
    AbTestManager
) {
    'use strict';

    /**
     * View class.
     */
    var Header = BaseApp.extend({

        // View element.
        el: '.site-header',

        // Events.
        events: {
            'click .site-nav-search-link': 'onClickNavSearchIcon',
            'click .site-masthead-search-close-btn': 'onClickSearchCloseBtn',
            'click .site-masthead-search-btn-div': 'onClickSearchBtnDiv',
            'click .pr-outer-wrap a': 'onClickAllLinksInPR',
            'mouseenter .site-nav-subscribe-alt-module': 'onHoverSubscribeAlt',
            'mouseenter .site-nav-salutation-module': 'onHoverSalutation'
        },

        // Constants.
        navHeaderHeight: 40,
        mastheadHeight: 80,
        fixedHeaderThreshold: 80,
        hoverDropdownDelay: 120,
        videoBarHeight: 156,
        TWO_COL_THRESHOLD: 5,
        SITE_NAV_HIDDEN_CLASS: 'site-nav-item-hidden',
        SITE_NAV_MORE_VISIBLE_CLASS: 'site-nav-more-dropdown-item-visible',

        /**
         * Initialize the view.
         */
        initialize: function() {
            // Cache references to common elements/calculations.
            this.isMulti = !!this.$el.attr('data-multi-header');
            this.isThirdParty = !!this.$el.attr('data-third-party');
            this.$win = Utils.get('win');
            this.$top = Utils.get('scrollEl');
            this.$siteHeaderInnerWrap = this.$('.site-header-inner-wrap');
            this.$siteMasthead = this.$('.site-masthead');
            this.$searchWrap = this.$('.site-masthead-search-wrap');
            this.$navLogo = this.$('.site-nav-logo-item');
            this.$navLogoLink = this.$('.site-nav-logo-link');
            this.$navMoreItem = this.$('.site-nav-more-item');
            this.$navLogoImg = this.$navLogo.find('.site-nav-logo-img');
            this.$searchCancelBtn = this.$('.site-masthead-search-close-btn');
            this.navLogoImgWidth = this.$navLogoImg.outerWidth(true);
            this.$specialOffersMastheadAd = this.$('.site-masthead-special-offers-ad');
            this.$subscribeWrap = this.$('.site-masthead-subscribe-wrap');
            this.$siteMoreDropdownItems = this.$('.site-nav-more-dropdown-item:not(' +
                '.site-nav-more-dropdown-item-hidden)');
            this.$searchInput = this.$$('.site-masthead-search-form-input');
            // We use the fixedWrap here so we get a good snapshot of either header
            // height with masthead before the breaking news bar comes in.
            this.navHeight = this.$siteHeaderInnerWrap.outerHeight();
            this.minHeight = this.$el.outerHeight();
            this.loginState = 'pending';

            this.smallerLogo = this.$('.site-nav-logo-img');
            this.prTag = this.$('.pr-outer-wrap');
            this.prodCdn = this.prTag.attr("data-prod-cdn");
            this.dataSiteKey = this.prTag.attr("data-site-key");
            this.largeLogo = this.$('.logo-link').find('img');
            this.smallLogo = this.$('.site-nav-logo-img');

            this.subscribeMastheadDisabled = this._isSubscribeMastheadDisabled();

            // Keep 'this' reference to view in scroll.
            _.bindAll(this,
                'onBreakingNewsChange',
                'onFixingScroll',
                'onClosingScroll',
                '_handleOnAdReady',
                '_onResizeWindow',
                '_onNanoReady');

            // Show/hide masthead speeds.
            this.slideSpeed = 200;
            Utils.set('headerHeight', this.navHeight);
            this.slideEasing = 'ease-out';

            var open = this.$el.data('open'),
                fixed = this.$el.data('fixed'),
                defaultOpen = this.$el.data('default-open') || false,
                defaultFixed = this.$el.data('default-fixed') || false;

            if (open === undefined) {
                open = defaultOpen;
            }
            if (fixed === undefined) {
                fixed = defaultFixed;
            }

            // this is the default state set by the html
            this.state = {
                open: open,
                defaultOpen: defaultOpen,
                fixed: fixed,
                defaultFixed: defaultFixed,
                hasFixingScroller: false,
                hasClosingScroller: false
            };

            this.pubSub = {
                'user:statuschange': this._loginStatus,
                'page:load': this._onPageLoad,
                'header:open': this.navAutoFitItems,
                'header:closed': this.navAutoFitItems,
                'breakingbar:opening': this.hideNano,
                'breakingbar:closing': this.showNano
            };

            if (AdManager.isAdFreeExperience()) {
                document.cookie = "__bXd=true";
            }

            BaseApp.prototype.initialize.apply(this, arguments);

            this.start();

            // set js event handlers
            if (!fixed) {
                this._setState({
                    hasFixingScroller: true
                });
                if (Utils.getScrollPosition()) {
                    // we're already scrolled down before we were inited,
                    // make certain the header is correct
                    this.onFixingScroll();
                }
            }
        },

        start: function(){

            if (this.subscribeMastheadDisabled) {
                this.$subscribeWrap.removeClass('site-masthead-subscribe-wrap-visible');
            } else {
                this.$subscribeWrap.addClass('site-masthead-subscribe-wrap-visible');
            }

            this.loadHeaderModules();

            if(Utils.getNested(window.site_vars, 'userAuthentication', 'personal_link', 'enabled')) {
                this.subviews.userUtil = new UserUtil();
            }

            this.subviews.breakingbar = new BreakingNews({
                el: this.$('.bnm-container'),
                slideSpeed: this.slideSpeed,
                onBreakingNewsChange: this.onBreakingNewsChange
            });

            // Init more dropdown
            this.moreLayout = '1col';
            if (!this.isThirdParty) {
                this.subviews.moreModule = new SiteNavMoreModule({
                    el: this.$navMoreItem[0]
                });
            }

            // Kick off nav autofit processes
            var throttledResize = _.throttle(this._onResizeWindow, 500);
            this.$win.on('resize.' + this.cid, throttledResize);
            this.navAutoFitStart();
        },

        /**
         * Set up collections of nav items and calculate individual item widths to use
         * later in calculating how many items can fit in a given nav width.
         */
        navAutoFitStart: function() {
            this.$siteNavDynamicPrimaryItems = this.$('.site-nav-primary-item:not(' +
                '.site-nav-more-item,' +
                '.site-nav-logo-item,' +
                '.site-nav-always-visible-item,' +
                '.site-nav-spacer-item)');
            this.$siteNavAlwaysVisibleItems =
                this.$('.site-nav-always-visible-item,.site-nav-secondary-item,.site-nav-post-primary-item');
            this.siteNavDynamicItemsWidths =
                this.getNavGroupWidths(this.$siteNavDynamicPrimaryItems);
            this.siteNavAlwaysVisibleItemsWidths =
                this.getNavGroupWidths(this.$siteNavAlwaysVisibleItems);
            this.siteNavMoreItemWidth = this.$navMoreItem.outerWidth();
            this.siteNavAlwaysVisibleItemsTotalWidth = _.reduce(
                this.siteNavAlwaysVisibleItemsWidths,
                function(memo, num){ return memo + num; },
                0);
            this.siteNavLogoWidth = this.navLogoImgWidth - 10;
            if (!this.isThirdParty) {
                this.hiddenPrimaryItemsCount = 0;
            }
            this._updateMastheadWidth();
            if (!this.navRevealed) {
                if (!this.isThirdParty && this.hiddenPrimaryItemsCount === 0) {
                    this.$navMoreItem.addClass(this.SITE_NAV_HIDDEN_CLASS);
                }
                this.$('.site-nav-item').addClass('site-nav-item-visible');
                this.navRevealed = true;
            }
        },

        /**
         * Use the calculated widths of each nav item to figure out how many can fit
         * inside the masthead width constraint. If there's too many to fit,
         * hide the overflow items in the 'More' dropdown.
         */
        navAutoFitItems: function() {

            // Factor in the nav logo width if the masthead is collapsed.
            var logoWidth = this.state.open ? 0 : this.siteNavLogoWidth;

            // We don't show the more dropdown for cobrand navs.
            var siteNavMoreItemWidth = this.isThirdParty ? 0 : this.siteNavMoreItemWidth;
            var newNavWidth = this.siteNavAlwaysVisibleItemsTotalWidth + logoWidth;
            var firstHiddenNavItemIndex = this.siteNavDynamicItemsWidths.length;
            if (!this.firstHiddenNavItemIndex) {
                this.firstHiddenNavItemIndex = firstHiddenNavItemIndex;
            }

            // Determine the index of the first item we must hide.
            // Start by adding widths one by one and comparing with masthead width.
            for (var widthIndex = 0; widthIndex<this.siteNavDynamicItemsWidths.length; widthIndex++) {
                var widthToAdd = this.siteNavDynamicItemsWidths[widthIndex];
                if (newNavWidth + widthToAdd > this.mastheadWidth) {

                    // We need the 'more' dropdown -- first check if just
                    // adding the more item will make the nav fit.
                    if (newNavWidth + siteNavMoreItemWidth <= this.mastheadWidth) {
                        firstHiddenNavItemIndex = widthIndex;
                        break;
                    }

                    // Still not fitting, start removing items until it fits with
                    // the 'more' item added in calculation.
                    for (var revItemIndex = widthIndex - 1; revItemIndex >= 0; revItemIndex--) {
                        var widthToRemove = this.siteNavDynamicItemsWidths[revItemIndex];
                        if (newNavWidth + siteNavMoreItemWidth - widthToRemove <= this.mastheadWidth) {
                            firstHiddenNavItemIndex = revItemIndex;
                            break;
                        } else {
                            newNavWidth -= widthToRemove;
                        }
                    }
                    break;
                } else {
                    newNavWidth += widthToAdd;
                }
            }
            if (firstHiddenNavItemIndex === this.firstHiddenNavItemIndex) {
                return;
            }
            var isHidingItems = firstHiddenNavItemIndex < this.firstHiddenNavItemIndex;
            var startSliceIndex = isHidingItems ? firstHiddenNavItemIndex : this.firstHiddenNavItemIndex;
            var endSliceIndex = isHidingItems ? this.firstHiddenNavItemIndex : firstHiddenNavItemIndex;
            this.togglePrimaryNavItems(firstHiddenNavItemIndex, startSliceIndex, endSliceIndex);
            this.toggleMoreNavItems(firstHiddenNavItemIndex, startSliceIndex, endSliceIndex);
            this.firstHiddenNavItemIndex = firstHiddenNavItemIndex;
        },

        /**
         * Hide/show primary nav items
         */
        togglePrimaryNavItems: function(firstHiddenNavItemIndex, startIndex, endIndex) {
            this.$siteNavDynamicPrimaryItems.slice(startIndex,
                endIndex).toggleClass(this.SITE_NAV_HIDDEN_CLASS);
        },

        /**
         * Hide/show more dropdwon and items within it
         */
        toggleMoreNavItems: function(firstHiddenNavItemIndex, startIndex, endIndex) {
           if (!this.isThirdParty) {
                var isHidingItems = firstHiddenNavItemIndex < this.firstHiddenNavItemIndex;
                var newHiddenPrimaryItemsCount = this.siteNavDynamicItemsWidths.length -
                    firstHiddenNavItemIndex;
                var isMoreDropdownToggling = this.willMoreDropdownAppear(newHiddenPrimaryItemsCount) ||
                    this.willMoreDropdownHide(newHiddenPrimaryItemsCount);

                // Hide/show items inside the dropdown
                this.$siteMoreDropdownItems.slice(startIndex,
                    endIndex).toggleClass(this.SITE_NAV_MORE_VISIBLE_CLASS);

                // Hide/show the dropdown itself
                if (isMoreDropdownToggling) {
                    var shouldHideDropdown = newHiddenPrimaryItemsCount === 0;
                    this.$navMoreItem.toggleClass(this.SITE_NAV_HIDDEN_CLASS, shouldHideDropdown);
                    this.allPrimaryNavItemsShown = !isHidingItems;
                }
                this.hiddenPrimaryItemsCount = newHiddenPrimaryItemsCount;
                this._updateMoreColumnLayout();
            }
        },

        willMoreDropdownAppear: function(newHiddenPrimaryItemsCount) {
            return newHiddenPrimaryItemsCount === 0 && this.hiddenPrimaryItemsCount > 0;
        },

        willMoreDropdownHide: function(newHiddenPrimaryItemsCount) {
            return this.hiddenPrimaryItemsCount === 0 && newHiddenPrimaryItemsCount > 0;
        },

        /**
         * Take an array of elements and return an array of their widths.
         * @param {Array} $group Array of jquery objects.
         */
        getNavGroupWidths: function($group) {
            return _.map($group, function(el) {
                return $(el).outerWidth();
            });
        },

        /**
         * Calculate masthead width and if it's changed, auto fit nav items
         * if needed.
         * @private
         */
        _updateMastheadWidth: function() {
            var newMastheadWidth = this.$siteMasthead.outerWidth();
            if (newMastheadWidth === this.mastheadWidth) {
                return;
            }
            var changeDirection = newMastheadWidth > this.mastheadWidth ? 'wider' : 'narrower';
            this.mastheadWidth = newMastheadWidth;
            if (changeDirection === 'wider' && this.allPrimaryNavItemsShown) {
                return;
            } else {
                this.navAutoFitItems();
            }
        },

        /**
         * Converts the layout of the more dropdown between 1 column or 2 column
         * depending on the number of nav items that are currently visible in the more
         * dropdown.
         *
         * We use the 1column layout for a smaller number of items, and then the 2 column
         * layout for larger numbers to avoid a super tall single column dropdown in cases
         * of navs with lots of items.
         * @private
         */
        _updateMoreColumnLayout: function() {
            if (this.isThirdParty) {
                return;
            }
            if (this.hiddenPrimaryItemsCount > this.TWO_COL_THRESHOLD && this.moreLayout != '2col') {
                this.subviews.moreModule.convertColumnLayout('2col');
                this.moreLayout = '2col';
            } else if (this.moreLayout != '1col') {
                this.subviews.moreModule.convertColumnLayout('1col');
                this.moreLayout = '1col';
            }
        },

        /**
         * Fires on PubSub event page:load
         * @private
         */
        _onPageLoad: function(pageInfo){
            if(window.adsEnabled !== undefined && !this.subscribeMastheadDisabled) {
                this._buildSpecialOffersMastheadAd();
            }
            this._setupNanoBar(pageInfo);
            this._onResizeWindow();
        },

        _setupNanoBar: function(pageInfo) {
            var hasFluid = Utils.getNested(window.site_vars, 'ADS', 'consumerSales', 'nano', 'fluid'),
                adSizes = (hasFluid) ? [[1025,80], 'fluid']: [[1025,80]];
            // Exclude unit on branded content or longform
            if (pageInfo && !(pageInfo.sponsor_story || pageInfo.basePageType === 'story-longform') && !this.subviews.nanoAd) {
                this.subviews.nanoAd = new AdPosition({
                    el: this.$('.partner-nanobar-ad'),
                    adSizes: adSizes,
                    adPlacement: 'consumer_sales-nano_bar',
                    onAdReady: this._onNanoReady,
                });
            }
        },

        _onNanoReady: function(){
            this.hasNano = true;
            this.showNano();
        },

        showNano: function() {
            if(this.hasNano) {
                this.$el.addClass('show-partner-nanobar');
            }
        },

        hideNano: function() {
            if(this.hasNano) {
                this.$el.removeClass('show-partner-nanobar');
            }
        },

        /**
         * Resize handler: update masthead width (and recalc nav width if needed)
         * @private
         */
        _onResizeWindow: function(){
            this._updateMastheadWidth();
        },

        _buildSpecialOffersMastheadAd: function(){
            if(this.$specialOffersMastheadAd.length) {
                var numViewsLeft = StateManager.getActivePageInfo().numPageViewsLeft,
                    targeting;

                var userAccount = UserManager.getAccount('firefly');

                if(numViewsLeft !== undefined){
                    targeting = {
                        'pwvr': (numViewsLeft > 0) ? numViewsLeft : "0"
                    };
                }
                if(!this.subviews.specialOffersMastheadAd) {
                    if(userAccount){
                        userAccount.getUserInfo().done(_.bind(function (firefly) {
                            if(!firefly.hasMarketAccess){
                                    this._applyMastheadDirectPositionAd(targeting);
                            }
                        }, this)).fail(_.bind(function() {
                                this._applyMastheadDirectPositionAd(targeting);
                        }, this));
                    }else {
                        this._applyMastheadDirectPositionAd(targeting);
                    }

                } else if(targeting) {
                    this.subviews.specialOffersMastheadAd.setTargeting(targeting);
                    this.subviews.specialOffersMastheadAd.refreshPosition();
                }
            }
        },

        _applyMastheadDirectPositionAd: function(targeting) {
            this.subviews.specialOffersMastheadAd = new AdPosition({
                el: this.$specialOffersMastheadAd,
                adSizes: [122,50],
                adPlacement: 'consumer_sales-masthead',
                onAdReady: this._handleOnAdReady,
                targeting: targeting
            });
        },

        _handleOnAdReady: function(){
            this.$subscribeWrap.remove();
            this.$specialOffersMastheadAd.addClass('site-masthead-subscribe-wrap-visible');
            this.subviews.specialOffersMastheadAd.showAd();
        },

        destroy: function() {
            this._unregisterFixingScroller();
            this.$win.off('.' + this.cid);
            clearTimeout(this.headerTransparencyTimeout);
            BaseApp.prototype.destroy.call(this);
        },

        _loginStatus: function(accountName, loginStatus, userData) {
            if (accountName !== 'firefly') {
                return;
            }

            var subscribeAltNavItem = this.$('.site-nav-subscribe-alt-item');
            var subscribeAltNavLink = this.$('.site-nav-subscribe-alt-link');
            var salutationNavItem = this.$('.site-nav-salutation-item');
            var salutationNavLink = this.$('.site-nav-salutation-link');

            this.loginState = (loginStatus === 'loggedIn') ? 'registered' : 'notregistered';

            // Define data-track-label for subscribe and salutation linis
            subscribeAltNavLink.attr('data-track-label', 'site-nav-subscribe-alt-module|' + this.loginState);
            salutationNavLink.attr('data-track-label', 'site-nav-salutation-module|' + this.loginState + '|hover|' + salutationNavLink.text());

            // Set salutation text
            var salutationText = (this.loginState === 'registered') ? 'Hi, ' + (userData.welcomeName || userData.email) : 'Sign In';
            this.$('.site-nav-salutation-span').html(salutationText);

            // Set the default href if not defined already
            if (!subscribeAltNavLink.attr('href')) {
                var onSuccessRedirectURL = encodeURIComponent(window.firefly_urls.onSuccessRedirectURL);
                subscribeAltNavLink.attr('href', window.firefly_urls.samSubscribeURL + '?onSuccessRedirectURL=' + onSuccessRedirectURL);
            }

            // Subscribe links
            if (loginStatus === 'loggedOut' || !userData || (userData && !userData.hasMarketAccess)) {
                if (!this.subscribeMastheadDisabled) {
                    this.$subscribeWrap.addClass('site-masthead-subscribe-wrap-visible');
                }
                subscribeAltNavItem.addClass('site-nav-subscribe-alt-item-visible');
            } else {
                this.$subscribeWrap.removeClass('site-masthead-subscribe-wrap-visible');
                subscribeAltNavItem.removeClass('site-nav-subscribe-alt-link-visible');
            }
            salutationNavItem.addClass('site-nav-salutation-item-visible');
        },

        isFixedAndClosed: function(stateObj) {
            return !stateObj.open && !!stateObj.fixed && !stateObj.hasFixingScroller && !stateObj.hasClosingScroller;
        },

        _setState: function(stateObj){
            var pubSubEvent = '', animate,
                listenerDeferred = new $.Deferred();
            _.each(stateObj, function(val, key){
                if (this.state[key] !== val) {
                    if (key === 'fixed') {
                        if (val) {
                            this.$siteHeaderInnerWrap.addClass('site-header-inner-wrap-fixed');
                            pubSubEvent += ' header:fixed';
                        } else {
                            this.$siteHeaderInnerWrap.removeClass('site-header-inner-wrap-fixed');
                            pubSubEvent += ' header:unfixed';
                        }
                    } else if (key === 'open') {
                        // we animate if the header is currently fixed and is staying fixed
                        // or the fixed scroller is changing (ie, we've hit the fixed threshold
                        animate =   (this.state.fixed === true && (stateObj.fixed === undefined || stateObj.fixed === true)) ||
                                    (stateObj.hasFixingScroller !== undefined && this.state.hasFixingScroller !== stateObj.hasFixingScroller);
                        this._setMasthead(val, animate, listenerDeferred);
                        pubSubEvent += val ? ' header:open' : ' header:closed';
                    } else if (key === 'hasFixingScroller') {
                        if (val) {
                            this._registerFixingScroller();
                        } else {
                            this._unregisterFixingScroller();
                        }
                    } else if (key === 'hasClosingScroller') {
                        if (val) {
                            listenerDeferred.then(_.bind(function() {
                                this._registerClosingScroller();
                            }, this));
                        } else {
                            this._unregisterClosingScroller();
                        }
                    } else {
                        console.warn('Unknown state key: ' + key);
                    }
                }
            }, this);
            this.listenerTimeout = setTimeout(function() {
                // resolve this if for some reason we don't open the header but want to add the scroll listener
                listenerDeferred.resolve();
            }, (this.slideSpeed + 50));
            this._checkTransition(this.state, stateObj);
            _.extend(this.state, stateObj);
            if (pubSubEvent) {
                PubSub.trigger(pubSubEvent);
            }
        },

        _checkTransition: function(fromState, toState){
            // fill in any gaps in to toState with what's in the fromState
            toState = $.extend({}, fromState, toState);
            var fromFixedAndClosed = this.isFixedAndClosed(fromState),
                toFixedAndClosed = this.isFixedAndClosed(toState),
                animate = fromState.open !== toState.open;
            if (fromFixedAndClosed !== toFixedAndClosed) {
                if (toFixedAndClosed) {
                    this._transitionToCloseFixed(animate);
                } else {
                    this._transitionFromCloseFixed(animate);
                }
            }
        },

        _transitionToCloseFixed: function(animate) {
            this.navHeight = this.navHeaderHeight;
            this.updateHeaderMinHeight(animate);
        },

        _transitionFromCloseFixed: function(animate) {
            this.navHeight = this.navHeaderHeight + this.mastheadHeight;
            this.updateHeaderMinHeight(animate);
        },

        /*************************************************
         * Begin Fixing Scrollers
         *************************************************/

        _registerFixingScroller: function() {
            if (this.isMulti) {
                return;
            }
            if (!this.hasFixingScroller) {
                this.hasFixingScroller = true;
                this.$win.on('scroll.headerFixingScroller', _.throttle(this.onFixingScroll, 20));
            } else {
                console.warn('Attempting to register headerFixingScroller twice');
            }
        },
        _unregisterFixingScroller: function() {
            this.hasFixingScroller = false;
            this.$win.off('.headerFixingScroller');
        },

        onFixingScroll: function() {
            var fixed = Utils.getScrollPosition() >= this.mastheadHeight;
            if (fixed !== this.state.fixed) {
                // either we're open and unfixed, or closed and fixed
                this._setState({
                    open: !fixed,
                    fixed: fixed
                });
            }
        },

        /*************************************************
         * Begin Closing Scrollers
         *************************************************/

        _registerClosingScroller: function() {
            if (this.isApple || this.isMulti) {
                return;
            }
            if (!this.hasClosingScroller) {
                this.hasClosingScroller = true;
                this.$win.on('scroll.headerClosingScroller', _.throttle(this.onClosingScroll, 20));
            } else {
                console.warn('Attempting to register headerFixingScroller twice');
            }
        },
        _unregisterClosingScroller: function() {
            this.hasClosingScroller = false;
            this.$win.off('.headerClosingScroller');
        },

        onClosingScroll: function(){
            this._setState({
                open: false,
                fixed: true,
                hasClosingScroller: false
            });
            if(this.transparencySetup && !this.headerIsTransparent) {
                this.toggleTransparency(true);
            }
        },

        /**
         * Initialize header modules via the js_modules array from django's
         * nav service parsing.
         */
        loadHeaderModules: function() {
            // Load primary and secondary nav modules
            this.$('.headermodules').each(_.bind(function(i, el) {
                try {
                    var jsModules = JSON.parse($(el).html()).js_modules || [];
                    this.buildModules(jsModules);
                } catch (e) {
                    console.error("Failure loading header modules", e);
                }
            }, this));
        },

        /**
         * Click handler for nav search button (not the masthead search form input button).
         * @param {Event} e Click event.
         */
        onClickNavSearchIcon: function(e) {
            e.preventDefault();

            if(Utils.getNested(window.site_vars, 'search', 'ENABLED')) {
                this.$searchInput.focus();
                if (!this.state.open) {
                    this._setState({
                        open: true,
                        fixed: true,
                        hasClosingScroller: true
                    });
                    this.$searchCancelBtn.addClass('site-masthead-search-close-btn-visible');
                    if(this.transparencySetup && this.headerIsTransparent) {
                        this.toggleTransparency(false);
                    }
                }
            }
        },

        scrollTop: function(topValue, force) {
            if (!force) {
                if (!this.state.open) {
                    if (this.state.hasFixingScroller && topValue < this.fixedHeaderThreshold) {
                        topValue = this.fixedHeaderThreshold;
                    }
                } else {
                    var currentScrollTop = Utils.getScrollPosition();
                    if (topValue < this.fixedHeaderThreshold && currentScrollTop < this.fixedHeaderThreshold) {
                        // skip scroll top when we're open, but not completely scrolled top
                        return currentScrollTop;
                    }
                }
            }
            this.$top.scrollTop(topValue);
            return topValue;
        },

        isFixed: function() {
            return this.state.fixed;
        },

        isOpen: function() {
            return this.state.open;
        },

        setOpenFixed: function(saveState) {
            if (saveState) {
                this.lastState = $.extend({}, this.state);
            }
            this._setState({
                open: true,
                fixed: true,
                hasFixingScroller: false,
                hasClosingScroller: false
            });
        },

        getFixedHeight: function() {
            if (this.isFixedAndClosed(this.state)) {
                return this.minHeight;
            } else if (window.videoIsScrolling) {
                return this.videoBarHeight;
            } else {
                return this.minHeight - this.mastheadHeight;
            }
        },


        /**
         * We want to keep the current state, but enable or disable the fixing scroll handler
         * @param {Boolean} enabled
         */
        setFixingScroller: function(enabled) {
            if (enabled) {
                this._setState({
                    hasFixingScroller: true,
                    hasClosingScroller: false
                });
            } else {
                this._setState({
                    hasFixingScroller: false,
                    hasClosingScroller: false
                });
            }
        },
        setClosedFixed: function(saveState) {
            if (saveState) {
                this.lastState = $.extend({}, this.state);
            }
            this._setState({
                open: false,
                fixed: true,
                hasFixingScroller: false,
                hasClosingScroller: false
            });
        },

        restoreLastState: function() {
            if (this.lastState) {
                this._setState(this.lastState);
                this.lastState = null;
            }
        },

        restoreDefaultState: function() {
            if (this.state.defaultFixed) {
                this._setState({
                    open: this.state.defaultOpen,
                    fixed: this.state.defaultFixed,
                    hasFixingScroller: !this.state.defaultFixed,
                    hasClosingScroller: false
                });
            } else {
                var fixed = Utils.getScrollPosition() >= this.mastheadHeight;
                this._setState({
                    open: !fixed,
                    fixed: fixed,
                    hasFixingScroller: true,
                    hasClosingScroller: false
                });
            }
        },

        onClickSearchBtnDiv: function(e) {
            $(e.currentTarget).parents('form').trigger('submit');
        },

        /**
         * Close search results view
         */
        onClickSearchCloseBtn: function() {
            if (StateManager.getActiveApp().isSearchPage) {
                RouteManager.goTo('/');
            } else {
                this._setState({
                    open: false,
                    fixed: true,
                    hasClosingScroller: false
                });
                if(this.transparencySetup && !this.headerIsTransparent) {
                    this.toggleTransparency(true);
                }
            }
        },

        _collapseMasthead: function(animate, deferred){
            if (animate) {
                this.animate(this.$searchWrap, 'height', 0, this.slideSpeed, this.slideEasing).always(_.bind(function(){
                    if (this.$searchCancelBtn.length) {
                        this.$searchCancelBtn.removeClass('site-masthead-search-close-btn-visible');
                    }
                    if(deferred) deferred.resolve();
                }, this));
            } else {
                this.$searchWrap.css('height', 0);
                if(deferred) deferred.resolve();
            }
            this.toggleNavLogo(true, true);
        },

        _expandMasthead: function(animate, deferred){
            if (animate) {
                this.animate(this.$searchWrap, 'height', this.mastheadHeight + 'px', this.slideSpeed, this.slideEasing)
                    .done(_.bind(function() {
                        this.$searchInput.focus();
                        if(deferred) deferred.resolve();
                    }, this));
            } else {
                this.$searchWrap.css('height', this.mastheadHeight);
                if(deferred) deferred.resolve();
            }
            this.toggleNavLogo(true, false);
        },

        _setMasthead: function(open, animate, deferred){
            if (open) {
                this._expandMasthead(animate, deferred);
            } else {
                this._collapseMasthead(animate, deferred);
            }
        },

        getExpandedHeight: function() {
            return this.minHeight;
        },

        getScrollOffset: function() {
            if (this.isFixed()) {
                if (this.minHeight != this.navHeaderHeight) {
                    return this.minHeight - this.navHeaderHeight;
                } else {
                    return this.minHeight;
                }
            } else {
                return 0;
            }
        },

        getFixedThreshold: function() {
            return this.fixedHeaderThreshold;
        },

        getCollapsedHeight: function(){
            return this.navHeaderHeight;
        },

        getCurrentHeight: function() {
            return this.navHeight;
        },

        /**
         * Toggle logo (with slide animation).
         * @param {Boolean} animate Whether to animate closing.
         * @param {Boolean} visible Whether to force a logo to be collapsed.
         */
        toggleNavLogo: function(animate, visible) {
            var targetOpacity = visible ? 1 : 0;
            var targetWidth = visible ? "100%" : 0;
            this.$navLogoImg.css('opacity', targetOpacity);
            this.$navLogoLink.css('width', targetWidth);
        },

        /**
         * Set header min-height.
         * @param {boolean=} animate Whether to animate height change.
         */
        updateHeaderMinHeight: function(animate) {
            this.minHeight = this.navHeight + (this.subviews.breakingbar ? this.subviews.breakingbar.getHeight() : 0);
            if (animate) {
                this.animate(this.$el, 'min-height', this.minHeight, this.slideSpeed);
            } else {
                this.$el.css('min-height', this.minHeight);
            }
        },

        onBreakingNewsChange: function(open){
            this.updateHeaderMinHeight();
            if (open) {
                PubSub.trigger('breakingbar:before:open', this.minHeight, this.options.slideSpeed);
            } else {
                PubSub.trigger('breakingbar:before:close', this.minHeight, this.options.slideSpeed);
            }
        },

        /**
         * Update the top navigation active item.
         * @param {String} path Path of the currently active section.
         */
        updateNavigation: function(path) {
            var pathSegments, navItem, query,
                activeSpanSel = 'site-nav-active-span',
                activeItemSel = 'site-nav-active-item';

            // Remove current active class.
            this.$('.' + activeSpanSel).removeClass(activeSpanSel);
            this.$('.' + activeItemSel).removeClass(activeItemSel);

            path = path || 'home';
            query = path.indexOf('?');
            if (query !== -1){
                path = path.substring(0, query);
            }
            if (path[0] === '/') {
                path = path.substring(1);
            }
            if (path[path.length - 1] === '/') {
                path = path.substring(0, path.length - 1);
            }
            path = path.replace(/[^_a-zA-Z0-9\-/]/g, '');
            pathSegments = (path ? path.split('/') : ['home']);
            while (pathSegments.length) {
                navItem = this.$('.site-nav-' + pathSegments.join('-') + '-item');
                if (navItem.length) {
                    break;
                }
                pathSegments = pathSegments.splice(0, pathSegments.length - 1);
            }
            var section = pathSegments.join('-');
            PubSub.trigger('update:navigation', section);
            if (navItem && navItem.length) {
                this.$('.site-nav-' + section + '-span').addClass(activeSpanSel);
                navItem.addClass(activeItemSel);
            }
        },

        /**
         * Set up header to toggle transparency smoothly on modern broswers
         */
        setupTransparency: function() {
            this.siteHeaderInnerWrapBg = this.$siteHeaderInnerWrap.css('background');
            this.transparencySetup = true;
        },

        /**
         * Unset transition to toggle transparency smoothly on modern broswers
         */
        destroyTransparency: function() {
            this.$el.removeClass('transparent-header');
            this.transparencySetup = false;
            if(this.listenerTimeout) clearTimeout(this.listenerTimeout);

            // There is a 350ms css3 animation on the el's background, the we'll put back the inline style
            this.headerTransparencyTimeout = _.delay(_.bind(function() {
                this.$siteHeaderInnerWrap.css('background', this.siteHeaderInnerWrapBg);
            }, this), 350);
        },

        /**
         * Set header to transparent.
         * @param {Boolean} transparent Whether toggle transparency on/off.
         */
         toggleTransparency: function(transparent) {
            if(!this.transparencySetup) {
                this.setupTransparency();
            }
            if(transparent) {
                this.$el.addClass('transparent-header');
                this.headerIsTransparent = true;
            } else {
                this.$el.removeClass('transparent-header');
                this.headerIsTransparent = false;
            }
         },

         onClickAllLinksInPR: function() {
             var originalLargeLogoSrc = this.largeLogo.attr("data-new-src");
             var originalSmallLogo = this.prodCdn + "/sites/" + this.dataSiteKey + "/images/site-nav-logo@2x.png";
             window.setTimeout(_.bind(function(){
                this.largeLogo.attr('src', "");
                this.largeLogo.attr('src', originalLargeLogoSrc);
                this.smallLogo.attr('src', originalSmallLogo);
             }, this),300);
             //recalculate container width for smaller logo
             if(Utils.getScrollPosition() >= this.mastheadHeight)
             {
                this.toggleNavLogo(true, true);
             }
            else{
               this.toggleNavLogo(false,false);
             }
         },

         _isSubscribeMastheadDisabled: function() {
            return Utils.getNested(AbTestManager.getUserTestByTarget('header'), 'userVariant', 'options', 'hideMastheadSpecialOffer') || false;
         },

         onHoverSubscribeAlt: function(e) {
             var elText = this.$(e.currentTarget).text();
             PubSub.trigger('uotrack', 'site-nav-subscribe-alt-module|' + this.loginState + '|hover|' + elText);
         },

         onHoverSalutation: function(e) {
             var linkText = this.$(e.currentTarget).find('.site-nav-salutation-span').html();
             PubSub.trigger('uotrack', 'site-nav-salutation-module|' + this.loginState + '|hover|' + linkText);
         }
    });

    /**
     * Return view class.
     */
    return Header;

});

