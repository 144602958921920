/**
 * @fileoverview Right Now Weather view module.
 * @author mbriede@gannett.com (Matthew Briede)
 */
define('modules/right-now-weather/right-now-weather',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'adPosition'
],
function(
    $,
    _,
    BaseView,
    Utils,
    AdPosition
) {
    "use strict";
    /**
     * View class.
     */

    var RightNowWeather = BaseView.extend({

        classes: {
            sponsor: 'right-now-weather-sponsorship-ad',
            poster: 'poster-ad-primary'
        },

        initialize: function(options) {
            this.$sponsorshipAd = this.$('.' + this.classes.sponsor);
            this.$posterAd = this.$('.' + this.classes.poster);
            this.layoutType = this._getLayoutType();
            BaseView.prototype.initialize.call(this, options);
        },

        _getLayoutType: function(){
            var layout = null;
            if(this.options.layoutType) {
                if (this.options.layoutType === 'sidebar') {
                    layout = 'sidebar';
                } else {
                    layout = 'primary';
                }
            }
            return layout;
        },

        /**
         * Triggered from card.js
         * We place the initAds function here and not in initialize so that we only call an
         * ad once the card is ready to render modules in the sidebar and once we know if the
         * sidebar is open. On the homefront, we only show the sidebar ad when the sidebar is open
         * @param {Object} currentCardInfo provides state information about the card
         */
        renderCardInfo: function(currentCardInfo) {
            if (this.layoutType === 'primary') {
                if (currentCardInfo.sidebarWidth < 340) {
                    this.show();
                    this._buildPosterAd();
                    this._buildSponsorshipAd();
                }
            } else if (this.layoutType === 'sidebar'){
                if (currentCardInfo.sidebarWidth >= 340) {
                    this.show();
                    this._buildSponsorshipAd();
                }
            }
            this.currentCardInfo = currentCardInfo;
        },

        _buildSponsorshipAd: function(){

            if (this.$sponsorshipAd.length && !this.subviews.sponsorshipAd) {

                this.subviews.sponsorshipAd = new AdPosition({
                    el: this.$sponsorshipAd,
                    adSizes: 'sponsor_logo_medium',
                    adPlacement: 'sponsor_logo_front',
                    adType: 'sponsor_logo'
                });
            }
        },

        _buildPosterAd: function(){

            if (this.$posterAd.length && !this.subviews.posterAd) {

                this.subviews.posterAd = new AdPosition({
                    el: this.$posterAd,
                    adSizes: 'mediumrectangle',
                    adPlacement: 'poster_front',
                    adType: 'poster'
                });
            }
        },

        /**
         * Triggered from card.js
         * If the card width has changed and there is an ad placement, we need to destory the
         * ad placement because any size change at this point will be too small to handle the ad
         * @param {Object} currentCardInfo provides state information about the card
         */
        onCardWidthChange: function(currentCardInfo) {
            if(currentCardInfo.sidebarOpen) {
                if(currentCardInfo.sidebarWidth === 340) {
                    if (this.layoutType === 'primary') {
                        this.hide();
                    } else {
                        this.show();
                        if (!this.subviews.sponsorshipAd) {
                            this._buildSponsorshipAd();
                        }
                    }
                } else {
                    if (this.layoutType === 'primary') {
                        this.show();
                        if (!this.subviews.posterAd) {
                            this._buildPosterAd();
                        }
                    } else {
                        this.hide();
                    }
                }
            } else {
                if (this.layoutType === 'primary') {
                    this.show();
                    if (!this.subviews.posterAd) {
                        this._buildPosterAd();
                    }
                }
            }
        }
    });

    /**
     * Return view class.
     */
    return RightNowWeather;
});

