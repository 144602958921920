/**
 * @fileoverview Sports Video View
 * @author ikenticus (extends global video)
 */
define('modules/sports/sports-data-video',[
    'jquery',
    'underscore',
    'baseview',
    'modules/global/brightcove-video'
],
function(
    $,
    _,
    BaseView,
    VideoView
) {
    'use strict';

    var SportsDataVideoView = VideoView.extend({

            events: function() {
                var parentEvents = VideoView.prototype.events;
                if(_.isFunction(parentEvents)){
                    parentEvents = parentEvents();
                }
                return _.extend({},parentEvents, {
                    'click .videoStillPlay': 'swapImageForVideo'
                });
            },

            initialize: function(options) {
                // alter the utility bar class to prevent it from auto-scrolling
                this.$('.util-bar-primary-modules').addClass('util-bar-primary-modules-topic');
                this.$('.util-bar-primary-modules-topic').removeClass('util-bar-primary-modules');

                this.$playing = false;
                if (this.$('.videoStillPlay').data('autoplay') === true) {
                    options = $.extend({
                        autostart: true
                    }, options);
                    this.$playing = true;
                }
                VideoView.prototype.initialize.call(this, options);
            },

            onCardWidthChange: function(currentCardInfo){
                this.currentCardInfo = currentCardInfo;
                var size = currentCardInfo.name;
                this.$('.still').removeClass('active');
                this.$('.still.' + size).addClass('active');

                if (this.$playing) {
                    var width = this.$('.still.active').width();
                    var height = this.$('.still.active').data('video-height');
                    var video = this.$('.videoObject object');
                    video.attr('width', width);
                    video.attr('height', height);
                    this.$('.still').removeClass('active');
                }
            },

            renderCardInfo: function(currentCardInfo) {
                this.currentCardInfo = currentCardInfo;
                var size = currentCardInfo.name;
                var width = this.$('.still.' + size).width();
                var height = this.$('.still.' + size).data('video-height');
                var video = this.$('.videoObject object');
                this.$('.still').removeClass('active');
                if (this.$('.videoStillPlay').data('autoplay') === true) {
                    this.$('.videoStillPlay').css('display', 'none');
                    video.find('[name="width"]').val(width);
                    video.find('[name="height"]').val(height);
                    video.attr('width', width);
                    video.attr('height', height);
                } else {
                    this.$('.still.' + size).addClass('active');
                }
            },

            swapImageForVideo: function(e) {
                console.log('swap');
                var target = $(e.currentTarget);
                var width = this.$('.still.active').width(); 
                var height = this.$('.still.active').data('video-height');
                this.$('.videoObject param').each(function() {
                    if ($(this).attr('name') == 'width') {
                        $(this).attr('value', width);
                    } else if ($(this).attr('name') == 'height') {
                        $(this).attr('value', height);
                    }
                });
                this.$playing = true;
                this.$('.still').removeClass('active');
                VideoView.prototype.swapImageForVideo.call(this, e);
            },

            destroy: function(removeEl) {
                VideoView.prototype.destroy.call(this, false);
            }

        });

        return SportsDataVideoView;
    }
);
