define('managers/trafficcop',['jquery', 'underscore'],
    function($, _) {
        /**
         * Traffic Cop that will gate dom changing ajax events until animations are complete.
         * The advantage of this is that multiple animations could all be happening, and ajax requests will
         * stall until an appropriate window is available to render it's content
         * @exports managers/trafficcop
         * @author jmerrifiel@gannett.com (Jay Merrifield)
         */
        var TrafficCop = function(){
            this.DEBUG = true;
            this.defaultTimeout = 2000; // ms
            this.minTimeout = 500; // ms
            this.activeRequests = [];
            this.activeAnimations = [];
            this.animationCompletion = null;
        };
        TrafficCop.prototype = {
            /**
             * Adds an ajax request to the traffic manager. No ajax requests will complete (success or error)
             * until all animations have completed.
             * @param {Deferred} ajaxPromise promise object representing an ajax request
             * @return {Deferred} promise object that will fire when all animations are done
             */
            addRequest: function(ajaxPromise){
                var resultPromise = {
                    ajaxPromise: ajaxPromise,
                    gate: $.Deferred(),
                    result: null
                };
                this.activeRequests.push(resultPromise);
                ajaxPromise.always(_.bind(function(requestResult){
                    if (this.activeAnimations.length) {
                        resultPromise.result = requestResult;
                    } else {
                        if (ajaxPromise.state() === 'resolved') {
                            resultPromise.gate.resolve(requestResult);
                        } else {
                            resultPromise.gate.reject(requestResult);
                        }
                        this._cleanup();
                    }
                }, this));
                // we need to filter the results, cause we're not returning the correct promise
                var gate = resultPromise.gate.promise();
                gate.original = ajaxPromise;
                gate.abort = ajaxPromise.abort;
                return gate;
            },
            /**
             * Adds an animation to the traffic cop. No requests will complete until all animations
             * finish. The function will return a global completion promise that will fire when
             * all animations are done
             * @param {Deferred} promise representing an animation that will eventually complete
             * @param {jQuery} [el] element that we are animating
             * @param {String} [property] property we are animating
             * @param {String|Number} [value] value we are animating to
             * @param {Number} [timeMs] time for animation
             * @return {Deferred} representing when all animations are done
             */
            addAnimation: function(promise, el, property, value, timeMs){
                // why are you wasting my time?
                if (!promise || promise.state() !== 'pending'){
                    return promise;
                }
                if (!this.animationCompletion){
                    this.animationCompletion = $.Deferred();
                }
                var waitTime = Math.max(timeMs * 2, this.minTimeout) || this.defaultTimeout,
                    timeout = setTimeout(_.bind(function(){
                        console.warn('ANIMATION did NOT resolve within ' + waitTime + ' ms, releasing barrier ' + this._getAnimationPropertyDescription(property, value), el);
                        this._resolveAnimation(promise);
                    }, this), waitTime);

                this.activeAnimations.push(promise);
                promise.always(_.bind(function(){
                    clearTimeout(timeout);
                    if (!this._resolveAnimation(promise)) {
                        console.warn('animation finished after being released ' + this._getAnimationPropertyDescription(property, value), el);
                    }
                }, this));
                return this.animationCompletion.promise();
            },
            /**
             * Gets the current animation completion promise so we can delay destructive calls between animations
             * @return {Deferred} that will resolve when it's safe to make a destructive call
             */
            getAnimationCompletion: function() {
                if (this.animationCompletion) {
                    return this.animationCompletion.promise();
                } else {
                    return $.Deferred().resolve();
                }
            },
            _getAnimationPropertyDescription: function(property, value){
                if (property){
                    return 'on property ' + property + ':' + value;
                }else{
                    return 'on unregistered element';
                }
            },
            /**
             * Removes the animation promise from the active animations and resolves all the request promises if it
             * was the last animation
             * @param {Deferred} promise
             * @returns {Boolean} true if the animation was still active, false if it timed out
             * @private
             */
            _resolveAnimation: function(promise) {
                var index = $.inArray(promise, this.activeAnimations);
                if (index !== -1) {
                    this.activeAnimations.splice(index, 1);
                    if (this.activeAnimations.length === 0) {
                        // trigger completion events
                        this._openGate();
                    }
                    return true;
                } else {
                    return false;
                }
            },
            /**
             * Opens the gates for finished requests to resolve or reject appropriately.
             * Also resolves the animation promise.
             * @private
             */
            _openGate: function() {
                // we do this because the animation completion and the triggerEvents()
                // might register another animation which we want to generate a new animation
                // completion deferred
                var completion = this.animationCompletion;
                this.animationCompletion = null;
                _.each(this.activeRequests, function(itm){
                    var state = itm.ajaxPromise.state();
                    if (state === 'resolved'){
                        itm.gate.resolve(itm.result);
                    }else if (state === 'rejected'){
                        itm.gate.reject(itm.result);
                    }
                });
                this._cleanup();
                completion.resolve();
            },
            _cleanup: function(){
                this.activeRequests = _.reject(this.activeRequests, function(itm){
                    return itm.gate.state() !== 'pending';
                });
            }
        };
        return new TrafficCop();
    }
);
