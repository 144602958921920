/**
* @fileoverview Manages sports filters.
* @author erik.kallevig@f-i.com (Erik Kallevig)
*/
define('modules/sports/sports-filters',[
    'jquery',
    'underscore',
    'baseview',
    'state',
    'utils',
    'modules/scroller/vertical-scroll',
    'form/datepicker'
],
function(
    $,
    _,
    BaseView,
    StateManager,
    Utils,
    VerticalScroll,
    DatePicker
) {

    /**
     * View class.
     */
    var SportsFilters = BaseView.extend({

        // DOM element.
        el: '.sp-filter-bar-wrap',

        // Events.
        events: {
            'mouseenter .sp-filter-dropdown': 'onHoverFilterDropdown',
            'mouseleave .sp-filter-dropdown': 'onHoverFilterDropdown',
            'click .sp-filter-item': 'confSelect'
        },

        /**
         * Initialize the view.
         */
        initialize: function() {

            // Calls super method.
            BaseView.prototype.initialize.apply(this, arguments);

            this.$datepicker_filter = $('.sp-filter-date-picker-wrap');
            this.$daysAvailable = this.$datepicker_filter.data('gamedates');
            _.bindAll(this, 'getDays', 'checkAvailableDates');

            // Init datepicker.
            this.subviews.datepicker = new DatePicker({
                el: this.$('.sp-filter-date-picker-input'),
                buttonSelector: '.sp-filter-date-picker-btn',
                flyoutClass: 'sp-filter-date-picker-flyout',
                calendarPanelClass: 'ui-dark',
                dateFormat: 'yy / mm / dd',
                changeMonth: true,
                changeYear: true,
                onChangeMonthYear: this.getDays,
                beforeShowDay: this.checkAvailableDates,
                yearRange: "-4:+1"
            });
        },

        checkAvailableDates: function(thisdate){
            var $return = false;
            if (typeof this.$daysAvailable === "undefined")
                return [true, "available"];
            var $returnclass = "unavailable";
            var date_array = this.$daysAvailable.toString().split(",");
            var checkdate = $.datepicker.formatDate('d', thisdate);
            for(var i = 0; i < date_array.length; i++)
                {
                    if(date_array[i].toString() == checkdate.toString()) {
                        $return = true;
                        $returnclass = "available";
                    }
                }
            return [$return, $returnclass];
        },

        getDays: function(year, month, day) {
            if (typeof this.$daysAvailable === "undefined")
                return;
            var league_name = this.$datepicker_filter.data('league-name');
            var pagetype = this.$datepicker_filter.data('pagetype');
            var filter = this.$datepicker_filter.data('filter');
            if (filter) {
                filter = filter + '/';
            }
            var url = 'sports/'+ league_name +'/'+ pagetype +'/' + year +'/'+ month + '/1/date-picker/'+ filter;
            StateManager.fetchHtml(url).done(_.bind(function(html){
                this.$daysAvailable = $(html).data('gamedates');
                $('.ui-date-picker').datepicker('refresh');
            }, this));
        },

        confSelect: function(e){
            var $targetLink =  $(e.currentTarget),
                $targetDropdown = $targetLink.closest('.sp-filter-dropdown'),
                $dropdownBtn = $targetDropdown.find('.sp-filter-btn'),
                selection = $targetLink.text(); 
            $dropdownBtn.text(selection); 
        },

        /**
         * Hover handler for filter dropdowns.
         * @param {Event} e Hover event.
         */
        onHoverFilterDropdown: function(e) {
            if (e.type === 'mouseenter') {
                var $dropdown = $(e.currentTarget);
                var $scrollWrap = $dropdown.find('.sp-filter-scroll-wrap');
                this.subviews.vscroll = new VerticalScroll({
                    el: $scrollWrap,
                    padding: 6
                });
            } else {
                if(this.subviews.vscroll) this.subviews.vscroll.destroy();
            }
        }

    });

    return SportsFilters;
});

