define('modules/newsletter-signup-sidebar/newsletter-signup-sidebar',[
        'jquery',
        'underscore',
        'baseview',
        'state',
        'user-manager',
        'pubsub',
        'managers/cachemanager'
    ],
    function(
        $,
        _,
        BaseView,
        StateManager,
        UserManager,
        PubSub,
        CacheManager
    ) {
        'use strict';
        var NewsletterSignupSidebar = BaseView.extend({

            validEmail: function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            events: {
                'submit': 'onFormSubmit',
                'blur .nssm-email-input': 'validateEmail'
            },

            /**
             * Initialize view.
             */
            initialize: function(options) {
                // caching selectors
                this.$nlForm = this.$('.nssm-form');
                this.$nlFormAction = this.$nlForm.attr('action');
                this.$emailField = this.$('.nssm-email-input');
                this.$submit = this.$('.nssm-submit');
                this.$submitVal = this.$submit.val();
                this.$nlEmailFailure = this.$('.nssm-email-invalid-msg');

                this.userAccount = UserManager.getAccount('firefly');
                if (this.userAccount) {
                    this.userAccount.getUserInfo().done(_.bind(function(userInfo) {
                        if (userInfo.email) {
                            this.$currentUserEmail = userInfo.email;
                            this.$emailField.val(this.$currentUserEmail);
                        }
                    }, this));
                }
            },

            onFormSubmit: function(e) {
                e.preventDefault();
                this.validateEmail();
                if (this.validEmail(this.$emailField.val())) {
                    this.$submit.addClass("disabled");
                    this.$submit.val("");
                    var newsletter_choices = [];
                    newsletter_choices.push({
                        listCode: this.$nlForm.data("newsletter-id"),
                        isSubscribed: true
                    });

                    var request_json = {
                            request: {
                                email: this.$emailField.val().toString(),
                                newsletters: newsletter_choices
                            }
                        };

                    StateManager.fetchData(this.$nlFormAction, {
                        type: "POST",
                        withCredentials: true,
                        data: JSON.stringify(request_json)
                    }).done(_.bind(function(response) {
                        if (response.meta.status == 200) {
                            this.$nlForm.hide();
                            $('.nssm-success-msg').show();
                            this.trackLabel = window.utag_data.ssts + "|sidebar-newsletter-signup|successsignup";
                        } else {
                            this.$submit.removeClass("disabled");
                            this.$submit.val(this.$submitVal);
                            $('.nssm-default-title').hide();
                            $('.nssm-failure-title').show();
                            $('.nssm-default-description').hide();
                            $('.nssm-failure-description').show();
                            this.trackLabel = window.utag_data.ssts + "|sidebar-newsletter-signup|errorunabletoprocess";
                        }

                       PubSub.trigger('newslettersignupcta', this.trackLabel);
                    }, this)).fail(_.bind(function() {
                        this.$submit.removeClass("disabled");
                        this.$submit.val(this.$submitVal);
                        $('.nssm-default-title').hide();
                        $('.nssm-failure-title').show();
                        $('.nssm-default-description').hide();
                        $('.nssm-failure-description').show();
                        this.trackLabel = window.utag_data.ssts + "|sidebar-newsletter-signup|errorunabletoprocess";

                        PubSub.trigger('newslettersignupcta', this.trackLabel);
                    }, this));
                } else {
                    PubSub.trigger('newslettersignupcta', window.utag_data.ssts + "|sidebar-newsletter-signup|errorinvalidemail");
                }
            },

            validateEmail: function() {
                if (!this.$emailField.val() || !this.validEmail(this.$emailField.val())) {
                    this.$emailField.addClass("email-invalid");
                    this.$nlEmailFailure.show();
                } else {
                    this.$emailField.removeClass("email-invalid");
                    this.$nlEmailFailure.hide();
                }
            }
        });

        /**
         * Return view class.
         */
        return NewsletterSignupSidebar;
    });

