/**
 * @fileoverview Companion Ad View
 */
define('modules/partner/companion-ad',[
    'jquery',
    'underscore',
    'modules/partner/simple-ad'
],
function(
    $,
    _,
    SimpleAd
) {
    'use strict';
    /**
     * View class.
     */
    var CompanionAd = SimpleAd.extend({

        /**
         * @classdesc Companion Ad, a subclass of {@link SimpleAd}. A carousel companion ad
         * @constructs CompanionAd
         * @extends SimpleAd
         * @param {Object} options backbone options object
         *    @param {Object.<String, String>} [options.adPlacement] - placement in DFP (e.g. high_impact)
         *    @param {Object.<String, String>} [options.adSizes] - sizes the ad can render (e.g. mediumrectangle)
         *    @param {PageInfo} [options.pageInfo] - alternative to options.targeting, will build targeting out of a pageInfo object
         *    @param {Function} [options.onAdReady] - custom callback to run when ad has signaled it is ready to run
         *    @param {Boolean} [options.isShared] - Indicates whether this is a shared ad or not
         */
        initialize: function(options) {
            options = $.extend({
                adPlacement: 'poster_gallery_companion'
            }, options);
            
            SimpleAd.prototype.initialize.call(this, options);
            
            this._initAds();
            this.refreshTime = new Date().getTime();
        },
        
        refresh: function() {
            this.subviews.simpleAd.refreshPosition();
            this.refreshTime = new Date().getTime();
        }
        

    });

    /**
     * Return view class.
     */
    return CompanionAd;
});

