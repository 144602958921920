/* global define, console */
/**
 * @fileoverview Hero module view.
 */
define('modules/fronts/hero',['jquery',
    'underscore',
    'backbone',
    'baseview',
    'pubsub',
    'utils',
    'state',
    'admanager',
    'modules/videos/brightcove-video',
    'modules/videos/pluto/pluto-video',
    'modules/carousel/carousel',
    'adPosition',
    'partner/leavebehind',
    'modules/fronts/primary-poster-ad',
    'modules/fronts/front-list-video',
    'uiPageTurn',
    'uiFlip'
],
function(
    $,
    _,
    Backbone,
    BaseView,
    PubSub,
    Utils,
    StateManager,
    AdManager,
    Brightcove,
    PlutoVideo,
    Carousel,
    AdPosition,
    LeaveBehind,
    PrimaryPosterAd,
    FrontListVideo
    )
    {
        'use strict';
        /**
         * View class.
         */
        var HeroView = BaseView.extend({

            // Events.
            events: {
                'click .slide, .hero-asset-open' : 'open',
                'click .carousel-thumbs > li' : 'openTile',
                'click .hero-turn-trigger, .hero-popular .hero-page-trigger' : 'toggleFrontBack',
                'click .partner-close': 'closeAdClick',
                'click .hero-headline-video-close': 'toggleFrontVideo',
                'click .js-vid-play-link': 'onClickPlay',
                'click .js-video-wrap': '_onFrontListVideoClick'
            },

            destroy: function(removeEl) {
                if(this.$slot) {
                    this.$slot.removeClass('show-slot');
                }

                if (this.adShowing) {
                    this.closeAd(true);
                }
                BaseView.prototype.destroy.call(this, removeEl);
            },

            initialize: function(options){
                // remember that the durations are the duration for each stage of the animation
                // (0 -> 90, -90 -> 0)
                // so a complete animation is 2x duration
                options = $.extend(true, {animate: {duration: 200},
                    popularCount: this.$el.data('popular-count') || 6,
                    shadow: {
                        opacity: '.4',
                        duration: 200,
                        delay: 0
                    }
                }, options);

                if (this.$el.hasClass('front-list-hero')) {

                    this.$activeVideos = $([]);
                    this.pubSub = {
                        'exited:view:flhmvideo': this.onInlineVideoExitView
                    };
                    BaseView.prototype.initialize.call(this, options);

                } else {

                    BaseView.prototype.initialize.call(this, options);

                    _.bindAll(this, 'onVideoShow', 'onVideoHide', 'onAdReady', 'showHeroAd', 'beforeAdRender', 'belowHeroAdReady', 'onVideoStatusChange', 'onClickPlay', 'onPlutoVideoReset', 'onPlutoVideoPlay', '_onFrontListVideoClick');

                    this.visibleFace = 'front';

                    this.$openSidebar = $(".open-sidebar");
                    this.mostPopularLoaded = false;
                    this.isPageTurn = this.$el.hasClass('hero-page-turn');
                    if (this.isPageTurn) {
                        this.pageTurn = {
                            'frontIndex': this.$('.hero-page-front').index(),
                            'backIndex': this.$('.hero-page-back').index(),
                            'adIndex': this.$('.hero-page-ad').index(),
                            'videoIndex': this.$('.hero-page-video').index()
                        };
                        this.$el.uiPageTurn({
                            perspective: '1400px'
                        });
                    } else {
                        this.$el.uiFlip({
                            perspective: '1400px'
                        });
                    }
                    this.adShowing = false;

                    this.intervalSpeed = this.$el.data('interval');

                    // Initialize the hero module's carousel when needed.
                    if (this.$el.hasClass('carousel')) {
                        this.subviews.heroCarousel = new Carousel({
                            el: this.$el,
                            ads: false,
                            hoverTransition: 200,
                            thumbsSelector: '.carousel-thumbs',
                            rotate: true,
                            rotateInterval: this.intervalSpeed,
                            autostart: true
                        });
                    }
                    // Initialize each video as its own video instance.
                    this.subviews.video = [];

                    this.$('.ui-pluto-video').each(_.bind(function () {
                        var video = new PlutoVideo({
                            el: this.$('.ui-pluto-video')[0],
                            pageInfo: StateManager.getActivePageInfo(),
                            adUnit: AdManager.buildAdUnit('preroll_video'),
                            siteDisplayName: Utils.getNested(window.site_vars, 'display_name'),
                            twitterAccount: Utils.getNested(window.site_vars, 'twitter', 'default_account'),
                            onVideoPlay: this.onPlutoVideoPlay,
                            onEnded: this.onPlutoVideoReset
                        });

                        if (this.$el.closest('.hero-page-video').length) {
                            this.subviews.heroVideo = video;
                        } else {
                            this.subviews.video.push(video);
                            this.PlutoVideo = video;
                        }
                        this.onPlutoVideoReset();
                    }, this));

                    this.$('.ui-video').each(_.bind(function (idx, el) {
                        var $videoEl = $(el),
                            video = new Brightcove({
                                // Use .video parent() because hero markup is different.
                                el: $videoEl.parent(),
                                onVideoShow: this.onVideoShow,
                                onVideoHide: this.onVideoHide,
                                onStatusChange: this.onVideoStatusChange,
                                onClickPlay: this.onClickPlay
                            });
                        if ($videoEl.closest('.hero-page-video').length) {
                            this.subviews.heroVideo = video;
                        } else {
                            this.subviews.video.push(video);
                        }
                    }, this));

                    // Initialize the poster_front ad, if there is one
                    var posterAdPrimaryEl = this.$('.poster-ad-primary');
                    if (posterAdPrimaryEl.length > 0) {
                        this.subviews.posterAdPrimary = new PrimaryPosterAd({
                            el: posterAdPrimaryEl
                        });
                    }

                    this.$('.partner-heroflip-ad').each(_.bind(function (idx, el) {
                        if (idx > 0) {
                            console.error('why are there multiple ad placements for this hero?');
                            return;
                        }
                        var activeApp = StateManager.getActiveApp();

                        this.$slot = activeApp.$('.partner-billboard-ad');

                        if (activeApp._hasBigHeadline && !activeApp._hasBigHeadline()) {
                            this.subviews.ad = new AdPosition({
                                el: el,
                                adSizes: ['heroflip'],
                                adPlacement: 'high_impact',
                                adType: 'heroflip',
                                beforeAdRender: this.beforeAdRender,
                                onAdReady: this.onAdReady,
                                slot: this.$slot,
                                legacyHighImpact: true
                            });
                        }
                    }, this));
                    if (this.$('.popular').length) {
                        var popularUrl = '/services/most-popular/hero/' + Utils.getSectionPath(window.location.pathname) + '/' + this.options.popularCount + '/';
                        StateManager.fetchHtml(popularUrl).done(_.bind(function (htmlFrag) {
                            this.$('.popular .ui-placer').html(htmlFrag);
                            this.$('.hero-turn-trigger').fadeIn(500).addClass('ready');
                            this.mostPopularLoaded = true;
                        }, this));
                    }
                }
            },

            renderCardInfo: function(currentCardInfo) {
                this.updateTrackingLabels(currentCardInfo);
                this.belowHeroModule = this.$el.next('.below-hero-ad-module');
                if (this.belowHeroModule.length) {
                    if (!currentCardInfo.belowHeroAd) {
                        // clear out the width/height/display if we're coming back from an overlay to the card and the width has changed
                        this.belowHeroModule.hide();
                    } else {
                        var adOptions = {
                            el: this.belowHeroModule.find('.below-hero-ad'),
                            adSizes: ['mediumrectangle'],
                            adPlacement: 'poster_front',
                            adType: 'below_hero',
                            onAdReady: this.belowHeroAdReady
                        };
                        this.subviews.belowHeroAd = new AdPosition(adOptions);
                    }
                }
            },

            belowHeroAdReady: function() {
                var adBackfillUrl = '/services/most-popular/headline-ad/' + Utils.getSectionPath(window.location.pathname) + '/2/';
                StateManager.fetchHtml(adBackfillUrl).done(_.bind(function(htmlFrag){
                    this.belowHeroModule.find('.below-hero-popular').html(htmlFrag);
                    this.belowHeroModule.show();
                    this.subviews.belowHeroAd.playAd();
                }, this));
            },

            beforeAdRender: function() {
                var $hero = this.subviews.ad.$el,
                    height = $hero.height(),
                    width = $hero.width();
                this.subviews.ad.setInitialDimensions(width, height);
            },

            onAdReady: function(adInfo) {
                if(this.$slot) {
                    this.$slot.addClass('show-slot');
                }

                if ((adInfo.leaveBehindImage || ((adInfo.frameBackground || adInfo.frameColor) && adInfo.frameSliver)) && adInfo.leaveBehindText) {
                    this.createAdLeaveBehind(this.subviews.ad, adInfo);
                } else {
                    // no leave behind, no close button
                    this.showHeroAd();
                }
            },
            createAdLeaveBehind: function(ad, adInfo) {
                var activeApp = StateManager.getActiveApp();
                this.subviews.adLeaveBehind = new LeaveBehind({
                    el: StateManager.getActiveApp().$('.partner-leavebehind'),
                    onShowAd: this.showHeroAd,
                    cards : this.$el.closest('.card-wrap'),
                    isCompact: activeApp.isCurrentCardBumped && activeApp.isCurrentCardBumped(),
                    maxDisplayed: parseInt(adInfo.maxDisplayed, 10),
                    behavior : {
                        clearBgColor : false,
                        hideframe : false,
                        hidebrand : true,
                        initShow : true
                    }
                });
                this.subviews.adLeaveBehind.render(ad, adInfo);
            },
            showHeroAd: function() {
                this.adShowing = true;
                StateManager.getActiveApp().triggerEvent('onHeroAdOpen');
                this.subviews.ad.show();
                if (this.PlutoVideo){
                    var player = this.PlutoVideo.vjsPlayer;
                    if (!player.paused()) {
                        player.pause();
                    }
                }
                return this.triggerAnimation(_.bind(function(){
                    var promise;
                    if (this.isPageTurn) {
                        promise = this.$el.uiPageTurn('goToPage', this.pageTurn.adIndex, false, this.$('.partner-placement iframe').length);
                    } else {
                        this.$el.css('background', 'transparent');
                        promise = this.$el.uiFlip('flipForward');
                    }
                    promise.done(_.bind(function(){
                        this.subviews.ad.playAd();
                    }, this));
                    return promise;
                }, this));
            },

            /**
             * close ad click event
             * @param {Event} e click event
             */
            closeAdClick: function(e){
                e.preventDefault();
                this.closeAd();
            },
            closeAd: function(immediate){
                this.adShowing = false;
                this.subviews.ad.stopAd();
                this.triggerAnimation(_.bind(function(){
                    var promise;
                    if (this.isPageTurn){
                        if (this.visibleFace === 'back') {
                            promise = this.$el.uiPageTurn('goToPage', this.pageTurn.backIndex, immediate, this.$('.partner-placement iframe').length);
                        } else {
                            promise = this.$el.uiPageTurn('goToPage', this.pageTurn.frontIndex, immediate, this.$('.partner-placement iframe').length);
                        }
                    } else {
                        promise = this.$el.uiFlip('flipBackward', immediate);
                    }
                    return promise;
                }, this)).done(_.bind(function(){
                    StateManager.getActiveApp().triggerEvent('onHeroAdClose');
                    if (this.subviews.adLeaveBehind){
                        this.subviews.adLeaveBehind.show();
                    }
                }, this));
                return false;
            },

            triggerAnimation: function(action){
                return StateManager.registerAnimation(action());
            },

            onVideoShow: function(){
                //remove "right now" tag that covers video player
                this.$openSidebar.addClass("hidden");
                this.$('.hero-turn-trigger').hide();
            },

            onVideoHide: function(){
                //remove "right now" tag that covers video player
                this.$openSidebar.removeClass("hidden");
                if (this.mostPopularLoaded) {
                    this.$('.hero-turn-trigger').show();
                }
            },

            onVideoStatusChange: function(e) {
                if (e.type === 'mediaComplete') {
                    this.resetVideo();
                } else if (e.type === 'mediaPlay') {
                    // ad should be complete, display label again
                    this.hideLiveVideoLabel();
                }
            },

            hideLiveVideoLabel: function(){
                var liveVideoLabel = this.$('.js-live-video-label');
                if (liveVideoLabel) {
                    liveVideoLabel.show();
                }
            },

            onPlutoVideoReset: function(){
                $(this.el).find('.js-hide-during-vid-play').show();
            },

            onPlutoVideoPlay: function(){
                this.hideLiveVideoLabel();
            },

            onClickPlay: function(e) {
                // don't display the label during an ad
                var liveVideoLabel = this.$('.js-live-video-label');
                if (liveVideoLabel) {
                    liveVideoLabel.hide();
                }
                this.$('.js-hide-during-vid-play').hide();
                this.subviews.video[0].playVideo();
            },

            /**
             * Reset the hero video player and display the title and play button
             */
            resetVideo: function() {
                var video = this.subviews.video[0];
                video.showStill();

                this.$('.js-hide-during-vid-play').show();
            },

            /**
             * Open anchor.
             * @param {Event} event View click event.
             */
            open: function(event) {
                var $target = $(event.target),
                    $currentTarget = $(event.currentTarget),
                    contentType = $currentTarget.data('content-type') || '';
                if (this.subviews.heroVideo && ($target.hasClass('videoStillPlay') || contentType === 'video')) {
                    // Check to see if the target is the play button or a video.
                    this.toggleFrontVideo(event);
                    event.preventDefault();
                } else if (!$target.closest('a').length) {
                    $currentTarget.find('.hero-story > h1 > a').get(0).click();
                    event.preventDefault();
                }
            },

            openTile: function(event) {
                var index = $(event.currentTarget).index();
                this.$('.slide:eq(' + index + ') .hero-story > h1 > a').click();
            },

            /**
             * Toggle between the front and back views.
             * @param {Event} event View click event.
             */
            toggleFrontBack: function(event) {
                if (!this.mostPopularLoaded) {
                    return false;
                }

                // Get the indiex of the current page.
                this.triggerAnimation(_.bind(function() {
                    if (this.visibleFace !== 'back') {
                        // Switch to the back page.
                        PubSub.trigger('uotrack', 'mostpopularopen');
                        return this.goToBack();
                    } else {
                        // Switch to the front page.
                        PubSub.trigger('uotrack', 'mostpopularclose');
                        return this.goToFront();
                    }
                }, this));
                return false;
            },

            goToFront: function(){
                this.visibleFace = 'front';
                return this.$el.uiPageTurn('goToPage', this.pageTurn.frontIndex);
            },

            goToBack: function() {
                this.visibleFace = 'back';
                return this.$el.uiPageTurn('goToPage', this.pageTurn.backIndex);
            },
            goToVideo: function() {
                this.visibleFace = 'video';
                return this.$el.uiPageTurn('goToPage', this.pageTurn.videoIndex).done(_.bind(function(){
                    this.subviews.heroVideo.playVideo();
                }, this));
            },

            onCardWidthChange: function(newCardInfo) {
                this.updateTrackingLabels(newCardInfo);
            },

            updateTrackingLabels: function(cardInfo) {
                if (!this.$moduleWrap) {
                    this.$moduleWrap = this.$el.closest('.hero-module');
                }
                if (!this.variant && this.$moduleWrap.data('variant')) {
                    this.variant = this.$moduleWrap.data('variant');
                }
                if (this.variant === '4uphp') {
                    if (!this.trackPrefix) {
                        this.trackPrefix = this.$moduleWrap.find('.hfwmm-list').data('track-prefix');
                    }
                    if (!this.$flexListItems) {
                        this.$flexListItems = this.$moduleWrap.find('.hfwmm-secondary-link,.hfwmm-tertiary-link');
                    }
                    if (cardInfo.cardWidth === 1320) {
                        _.each(this.$flexListItems, function(el, i) {
                            var $el = $(el);
                            var index = i > 2 ? i - 3 + 1 : i + 1;
                            $el.data('ht', this.trackPrefix + $el.data('track-display-type') + index);
                        },this);
                    } else {
                        _.each(this.$flexListItems, function(el, i) {
                            var $el = $(el);
                            $el.data('ht', this.trackPrefix + 'stack' + (i+1));
                        },this);
                    }
                }
            },

            /**
             * Toggles between the hero headline pack and playing the
             * inline video. Requires first position to have video.
             * @param {Event} event View click event.
             */
            toggleFrontVideo: function(event) {
                if (!this.subviews.heroVideo) {
                    return false;
                }

                this.triggerAnimation(_.bind(function() {
                    if (this.visibleFace !== 'video') {
                        // Switch to the video page.
                        PubSub.trigger('uotrack', 'headlinevideoopen');
                        this.$('.hero-headline-video-close').show();
                        this.subviews.heroVideo.show();
                        return this.goToVideo();
                    } else {
                        // Switch to the front page.
                        this.subviews.heroVideo.onVideoStatusChange(event);
                        this.subviews.heroVideo.hide();
                        PubSub.trigger('uotrack', 'headlinevideoclose');
                        this.$('.hero-headline-video-close').hide();
                        return this.goToFront();
                    }
                }, this));
                return false;
            },

            _onFrontListVideoClick: function(e) {
                e.preventDefault();
                var $el = $(e.currentTarget),
                    videoId = $el.find('.js-uw-iframe-video').attr('data-video-id'),
                    subViewId = 'flhm-video-' + videoId;
                if (videoId && !this.subviews[subViewId]) {
                    PubSub.trigger('track', {
                        label: $el.attr('data-track-label')
                    });
                    this.$activeVideos = this.$activeVideos.add($el);
                    this.subviews[subViewId] = new FrontListVideo({
                        el: $el
                    });
                    if (StateManager.observer) {
                        $el.addClass('js-llc js-llp').attr('data-name', 'flhmvideo');
                        StateManager.observer.observe(e.currentTarget);
                    }
                }
            },

            onInlineVideoExitView: function(el) {
                this._destroyInlineVideoIframe(el);
            },

            _destroyInlineVideoIframe: function(el) {
                var $el = $(el),
                    videoId = $el.find('.js-uw-iframe-video').attr('data-video-id'),
                    subViewId = 'flhm-video-' + videoId;
                if (this.subviews[subViewId] && !this.subviews[subViewId].fullScreenEnabled) {
                    this.$activeVideos = this.$activeVideos.not($el);
                    this.subviews[subViewId].destroy();
                    delete this.subviews[subViewId];
                    if (StateManager.observer) {
                        $el.removeClass('js-llc js-llp');
                        StateManager.observer.unobserve(el);
                    }
                }
            }
        });

        /**
         * Return view class.
         */
        return HeroView;
    }
);

