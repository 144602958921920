/**
 * @fileoverview Storefront view.
 * @author Dave Burke (dburke2@gannett.com)
 */
define('apps/storefront',[
        'jquery',
        'base-app'
    ],
    function(
        $,
        BaseApp)
    {
        var StorefrontApp = BaseApp.extend({
            initialize: function() {
                BaseApp.prototype.initialize.call(this);
            },

            afterPageReveal: function() {
                $("body").addClass("sfa-body");
            },

            beforeAppRemove: function() {
                $("body").removeClass("sfa-body");
            }
        });

        return StorefrontApp;
    }
);

