/*global FB:true*/
define('third-party-apis/facebook/facebook',[
    'jquery',
    'underscore',
    'third-party-apis/base-third-party-api'
],
    function ($, _, BaseThirdPartyApi) {
        'use strict';
        /**
         * Library to handle calls to/from Facebook for various service interactions.
         * @author Mark Kennedy <mdkennedy@gannett.com>
         */
        var Facebook = BaseThirdPartyApi.extend({

            initialize: function () {
                this._config = this.getApiSiteConfig('FACEBOOK');
                if (this._config) {
                    this._appid = this._config.APPID;
                    this._channelUrl = window.location.protocol + '//' + window.location.host + window.site_static_url + 'html/channel.html';
                }

                BaseThirdPartyApi.prototype.initialize.call(this);
            },

            /**
             * Loads Facebook script.
             * @private
             * @returns {*}
             */
            _handleLoadScript: function () {
                if (!this._facebookPromise) {
                    this._facebookPromise = $.Deferred(_.bind(function (defer) {
                        if (!this._config.ENABLED) {
                            defer.reject();
                            return;
                        }
                        $.ajax('//connect.facebook.net/en_US/sdk.js',
                            {
                                cache: true,
                                data: {},
                                dataType: 'script'
                            }).done(_.bind(function () {
                                // even if the script loads, ad/script blockers may prevent FB from becoming available
                                // if it's not available, fail the load so no future scripts fire/crash
                                if (FB) {
                                    this._initializeFacebook();
                                    defer.resolve(FB);
                                } else {
                                    defer.reject();
                                }
                            }, this)).fail(function () {
                                defer.reject();
                            });
                    }, this)).promise();
                }
                return this._facebookPromise;
            },

            /**
             * Initializes Facebook's global var that will be used when making calls.
             * @private
             */
            _initializeFacebook: function () {
                FB.init({
                    appId: this._appid,
                    version: "v2.3",
                    channelUrl: this._channelUrl,
                    status: true,
                    cookie: true,  // yes we want to use cookies
                    xfbml: false  // don't auto parse xfbml tags
                });
            },

            /**
             * Gets the default set of user permissions for Facebook.
             * @returns {*}
             */
            getUserPermissions: function () {
                return this._config.PERMISSIONS;
            }
        });

        return new Facebook();
    }
);

