define('partner/leavebehind',[
    'jquery',
    'underscore',
    'baseview',
    'adLogger',
    'utils',
    'managers/cachemanager',
    'partner/ad-utils',
    'state'
],
function(
    $,
    _,
    BaseView,
    AdLogger,
    Utils,
    CacheManager,
    AdUtils,
    StateManager
) {
    "use strict";
        /**
         * View class.
         */
        var LeaveBehindAd = BaseView.extend(
        /**
         * @lends partner/leavebehind.prototype
         */
        {
            events: {
                'click .js-partner-lb-click': 'onClick'
            },
            /**
             * @classdesc Leavebehind helper class, this is used with closable ads to allow them to be brought back to life
             * @constructs partner/leavebehind
             * @author Jay Merrifield <jmerrifiel@gannett.com>
             * @author Jordan MAnwaring <jmanwaring@gannett.com>
             * @param {Object} options backbone options object
             *     @param {jQuery|Element|String} options.el - element or string selector where the leave behind will be added
             *     @param {String} [options.template] - Underscore template used to generate the leave behind
             *     @param {String} [options.imageUrl] - Url for the image, used with the default template
             *     @param {String} [options.altText] - Alt text for the image, used with the default template
             *     @param {Boolean} [options.isCompact=true] - Specifies whether to add the "compact" class to the leave behind
             *     @param {Boolean} [options.isNative=false] - Used to signal the leave behind to assume that the interactive takeover is the sponsor logo
             *     @param {Object} [options.behavior={}] - determines the how and when of showing the leave behind frame
             *     @param {Boolean} [options.behavior.clearBgColor=false] - whether to clear the background color on close
             *     @param {Boolean} [options.behavior.hideframe=false] - hides the frame when the ad is open
             *     @param {Boolean} [options.behavior.hidebrand=false] - hides the brand sliver while the ad is open
             *     @param {Boolean} [options.behavior.initShow=false] - whether to show the leave behind frame on first view
             */
            initialize: function(options) {
                options = $.extend({
                    onShowAd: null,
                    isCompact: true,
                    isNative: false,
                    behavior: {}
                }, options);

                // call base class initialize
                BaseView.prototype.initialize.call(this, options);

                if (!this.$el.prop('id')) {
                    this.$el.prop('id', _.uniqueId('ad-position-'));
                }

                this.$body = Utils.get('body');
                this.startBgColor = this.$body.css('background-color');
                this.$clickArea = this.$('.js-partner-lb-click');

                // Tell me about it
                AdLogger.logInfo('LeaveBehind initialized', this);
            },

            /**
             * Clean up the view (no argument).
             */
            destroy: function(removeEl) {
                this.hide();
                if (this.startBgColor) {
                    AdUtils.clearBg(this.$body, this.startBgColor);
                }
                this.$('img').remove();
                if(this.$frame) {
                    this.$frame.remove();
                }
                this.$el.removeClass('partner-lb-frame');
                this.$clickArea.find('.partner-lb-click-arrow').remove();
                if (this.options.cards && this.addedSpace) {
                    this.options.cards.animate({paddingTop: "-=10px"}, 350, function() {
                        $(this).css('padding-top', '');
                    });
                }
                this.$body.removeClass('partner-background');
                BaseView.prototype.destroy.call(this, false);
            },

            render: function(adObj, adData){
                var leavebehindBarrier = new $.Deferred();
                this.adData = adData;
                if(adData.frameSliver) {
                    // new leavebehind
                    this._setupFrame(adData, leavebehindBarrier);
                } else if (this.options.isNative) {
                    leavebehindBarrier.resolve();
                } else {
                    this._setupLb(adData, leavebehindBarrier);
                }

                leavebehindBarrier.then(_.bind(function() {
                    this._showAdOrLb(adObj, adData);
                }, this));

                return leavebehindBarrier;
            },

            _setupLb: function(adData, leavebehindBarrier){
                var $el = this.$el;
                if (this.options.isCompact){
                    $el.addClass('compact');
                }else{
                    $el.removeClass('compact');
                }
                $el.append($('<img>').attr({
                    class: 'leavebehind-image',
                    src: adData.leaveBehindImage,
                    alt: adData.leaveBehindText
                }));
                leavebehindBarrier.resolve();
            },

            _setupFrame: function(adData, leavebehindBarrier){
                this.$branding = $('<img>').attr({
                    src: adData.frameSliver,
                    alt: adData.leaveBehindText,
                    class: 'lb-brand'
                });
                if (adData.frameArrowColor) {
                    if (adData.frameArrowColor !== 'None') {
                        this.$clickArea.prepend('<svg class="partner-lb-click-arrow" fill="' + adData.frameArrowColor + '" x="0px" y="0px" viewBox="0 0 26 26" enable-background="new 0 0 26 26"><path fill-rule="evenodd" clip-rule="evenodd" d="M13,0.5C6.1,0.5,0.5,6.1,0.5,13c0,6.9,5.6,12.5,12.5,12.5S25.5,19.9,25.5,13C25.5,6.1,19.9,0.5,13,0.5z M19,12l-5.3,5.3c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.6-0.3L7.1,12c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.6,0.3l4.7,4.7l4.7-4.7c0.4-0.4,0.9-0.4,1.3,0C19.3,11,19.3,11.6,19,12z" /></svg>');
                    }
                }
                if(adData.frameBackground) {
                    $('<img>')
                        .load(_.bind(function(el) {
                            // remove img to prevent memory leaks
                            $(el.currentTarget).remove();
                            this.$frame = $('<div>')
                                .css('background-image', 'url("' + adData.frameBackground + '")')
                                .addClass('lb-frame');
                            StateManager.getActiveApp().$el.prepend(this.$frame);
                            this.$body.addClass('partner-background');
                            leavebehindBarrier.resolve();
                        }, this))
                        .attr('src', adData.frameBackground);
                } else {
                    this.$frame = $([]);
                    leavebehindBarrier.resolve();
                }
                this.$el.append(this.$branding).addClass('partner-lb-frame');
            },
            shouldShowAd: function(adData) {
                var maxDisplayed = this.options.maxDisplayed || 1,
                    adState = this.getAdState(adData);
                return adState.displayed < maxDisplayed;
            },
            getStateKey: function(adData) {
                return 'dfpc_' + adData.dfp.ecid;
            },
            getAdState: function(adData) {
                return CacheManager.getValue(this.getStateKey(adData), {
                    displayed: 0
                }, this.getExpirationMinutes(adData.leaveBehindExp));
            },
            getExpirationMinutes: function(leaveBehindExp) {
                var dfpExpiration = parseInt(leaveBehindExp, 10);
                return (isNaN(dfpExpiration)) ? Utils.getNested(window.site_vars, 'ADS', 'LEAVEBEHIND_EXPIRATION_MINUTES') : dfpExpiration;
            },
            incrementAdViewCount: function(adData) {
                var adState = this.getAdState(adData);
                adState.displayed += 1;
                CacheManager.setValue(this.getStateKey(adData), adState, this.getExpirationMinutes(adData.leaveBehindExp));
            },
            _showAdOrLb: function(adObj, adData) {
                var options = this.options;
                if (this.shouldShowAd(adData)) {
                    this.incrementAdViewCount(adData);
                    if (options.onShowAd) {
                        options.onShowAd();
                    }
                    if (Utils.getNested(options, 'behavior', 'initShow') && this.$frame) {
                        this.$frame.fadeIn(350);
                        AdUtils.changeBg(this.$body, adData.frameColor);
                    }
                } else {
                    // show the leave behind
                    this.show();
                    // set up tracking of the leave behind instead of the actual ad
                    adObj.trackAd(this.$el);
                    // track leavebehind with 3rd party pixel
                    this.track(adData.leaveBehindTracking);
                }
            },

            show: function() {
                if (this.options.isNative || !this.adData) {
                    return;
                }
                BaseView.prototype.show.call(this);

                if(this.adData.frameSliver) {
                    var cards = this.options.cards;
                    // adjust card padding
                    if(cards && !this.addedSpace) {
                        if (Utils.flag('disable_ad_animations')) {
                            cards.css('paddingTop', parseInt(cards.css('paddingTop')) + 20);
                        } else {
                            cards.animate({paddingTop: "+=10px"}, 350);
                        }
                        this.addedSpace = true;
                    }
                    this.$el.show();
                    this.$branding.fadeIn(350);
                    this.$frame.fadeIn(350);
                    AdUtils.changeBg(this.$body, this.adData.frameColor);
                }
                this.$body.addClass('leavebehind-visible');
            },

            hide: function(trackExpand) {
                var behavior = this.options.behavior,
                    cards = this.options.cards,
                    adData = this.adData;

                if (this.options.isNative || !adData) {
                    return;
                }

                if(!adData.frameSliver) {
                    BaseView.prototype.hide.call(this);
                }

                if(behavior.hidebrand && this.$branding) {
                    // adjust card padding back for pushdowns
                    if(cards && (adData.adType === 'Pushdown' || adData.adType === 'Pushdown+')) {
                        if (Utils.flag('disable_ad_animations')) {
                            cards.css('paddingTop', parseInt(cards.css('paddingTop')) - 20);
                        } else {
                            cards.animate({paddingTop: "-=10px"}, 350);
                        }
                        this.addedSpace = false;
                    }
                    this.$branding.fadeOut(350, _.bind(function() {
                        this.$el.hide();
                    },this));
                }

                if (behavior.hideframe && this.$frame) {
                    this.$frame.fadeOut(350);
                }

                if(this.startBgColor && behavior.clearBgColor) {
                    AdUtils.clearBg(this.$body, this.startBgColor);
                }

                //track re-expansion click
                if(trackExpand) {
                    this.track(adData.reexpandTracking);
                }
                this.$body.removeClass('leavebehind-visible');
            },

            onClick: function(){
                this.hide(true);

                if (this.options.onShowAd){
                    this.options.onShowAd(true);
                }
                return false;
            },

            track: function(urls) {
                var i,
                    $head = $('head');
                if(urls) {
                    for (i = 0; i < urls.length; ++i) {
                        var url = urls[i];
                        if((url.match(/^(https:\/\/).*?\.js($|#|\?)/gi) || []).length) {
                            $head.append('<script src="' + url + '"></scr' + 'ipt>');
                        } else {
                            (new Image()).src = url;
                        }
                    }
                }
            }

        });

        /**
         * Return view class.
         */
        return LeaveBehindAd;
    }
);

