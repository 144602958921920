/**
 * @fileoverview eat-and-drink-newsletter group view module.
 * @author Dave Burke (dburke2@gannett.com)
 */

define('modules/eat-and-drink-newsletter',[
        'jquery',
        'underscore',
        'state',
        'baseview'
    ],
    function(
        $,
        _,
        StateManager,
        BaseView
    ) {

        /**
         * View class.
         */
        var FeaturedNewsletterSignup = BaseView.extend({
            // Events. 
            events: {
                'submit .eada-newsletter-form': 'onFormSubmit'
            },

            validEmail: function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            onFormSubmit: function (e) {
                e.preventDefault();

                var newsletterForm = this.$('.eada-newsletter-form'),
                    messageSuccess = this.$('.eada-newsletter-status-success'),
                    messageError = this.$('.eada-newsletter-status-error'),
                    messageErrorTxt = this.$('.eada-newsletter-status-error-message'),
                    messageUpdating = this.$('.eada-newsletter-status-updating'),
                    newsletterId = newsletterForm.data("newsletter-id"),
                    businessUnit = newsletterForm.data("business-unit"),
                    emailValue = this.$('.eada-newsletter-email').val();

                // validate
                if(!emailValue || !this.validEmail(emailValue) ) {
                    if (!emailValue || !this.validEmail(emailValue)) {
                        messageErrorTxt.html("Please enter a valid email address.");
                    }
                    messageSuccess.addClass('eada-hidden');
                    messageError.removeClass('eada-hidden');
                    messageUpdating.addClass('eada-hidden');
                } else {
                    messageSuccess.addClass('eada-hidden');
                    messageError.addClass('eada-hidden');
                    messageUpdating.removeClass('eada-hidden');

                    var newsletter_choices_json = JSON.stringify(
                        [
                            {
                                id: newsletterId,
                                signup: true
                            }
                        ]
                    );

                    StateManager.fetchData(null,
                        {
                            type: "POST",
                            url: newsletterForm.attr('action') + emailValue + '/' + businessUnit + '/',
                            data: {
                                newsletter_choices: newsletter_choices_json
                            }
                        }
                    ).done(_.bind(function(response) {
                        messageUpdating.addClass('eada-hidden');

                        if (response.success === true) {
                            this.$('.eada-newsletter-fieldset').addClass('eada-hidden');
                            messageSuccess.removeClass('eada-hidden');
                        } else {
                            messageErrorTxt.html(response.message);
                            messageError.removeClass('eada-hidden');
                        }
                    }, this));
                }
            }
            
        });

        /**
         * Return view class.
         */
        return FeaturedNewsletterSignup;
    }
);

