/**
 * @fileoverview eat-and-drink-subscriptions group view module.
 * @author Dave Burke (dburke2@gannett.com)
 */

define('modules/eat-and-drink-form',[
        'jquery',
        'underscore',
        'state',
        'baseview'
    ],
    function(
        $,
        _,
        StateManager,
        BaseView
    ) {

        /**
         * View class.
         */
        var EatAndDrinkForm = BaseView.extend({

            // Events.
            events: {
                'submit .eada_subscribe_form': 'onFormSubmit'
            },

            // source: WSA/newsletters/static/js/modules/newsletter-signup/newsletter-signup.js
            validEmail: function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            onFormSubmit: function (e) {
                e.preventDefault();

                var subscribeForm = this.$('.eada_subscribe_form'),
                    subscribeFormButton = this.$('.eada-free-submit'),
                    messageSuccess = this.$('.eada_subscribe_success'),
                    messageError = this.$('.eada_subscribe_error'),
                    eada_subscribe_name = $.trim(this.$('.eada_subscribe_name').val()),
                    eada_subscribe_email = $.trim(this.$('.eada_subscribe_email').val()),
                    eada_subscribe_street = $.trim(this.$('.eada_subscribe_street').val()),
                    eada_subscribe_city = $.trim(this.$('.eada_subscribe_city').val()),
                    eada_subscribe_zip = $.trim(this.$('.eada_subscribe_zip').val());

                // validate
                if (!eada_subscribe_name || !eada_subscribe_email || !eada_subscribe_street || !eada_subscribe_city || !eada_subscribe_zip) {
                    messageError.html("Please complete all fields.").removeClass('eada-hidden');
                } else if(!this.validEmail(eada_subscribe_email) ) {
                    messageError.html("Please enter a valid email address.").removeClass('eada-hidden');
                } else if (isNaN(eada_subscribe_zip) || eada_subscribe_zip.length != 5) {
                    messageError.html("Please enter a numeric value for Zip.").removeClass('eada-hidden');
                } else {
                    subscribeFormButton.prop("disabled", true);
                    messageError.addClass('eada-hidden');
                    messageSuccess.addClass('eada-hidden');

                    var subscribe_form_json = JSON.stringify(
                        {
                            name: eada_subscribe_name,
                            email: eada_subscribe_email,
                            street: eada_subscribe_street,
                            city: eada_subscribe_city,
                            zip: eada_subscribe_zip
                        }
                    );

                    StateManager.fetchData(null,
                        {
                            type: "POST",
                            url: subscribeForm.attr('action') ,
                            data: {
                                subscribe_form_json: subscribe_form_json
                            }
                        }
                    ).done(_.bind(function(response) {
                        if (response.success === true) {
                            var subscribeFormFieldset = this.$('.eada_subscribe_form_fields');
                            subscribeFormFieldset.find('input').each(function() {
                                $(this).prop("disabled", true).addClass('eada-disabled');
                            });
                            messageSuccess.removeClass('eada-hidden');
                            subscribeFormButton.addClass('eada-hidden');
                        } else {
                            messageError.removeClass('eada-hidden').html("We're sorry, there was a problem sending your subscription request. To register, please send your details to " + subscribeForm.data('support'));
                            subscribeFormButton.prop("disabled", false);
                        }
                    }, this));
                }
            }
        });

        /**
         * Return view class.
         */
        return EatAndDrinkForm;
    }
);

