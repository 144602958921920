/* global Reuters:true */
/**
 * @fileoverview Olympics Widgets view
 * @author ikenticus
 */
define('apps/overlay/pages/olympics-widgets',[
    'jquery',
    'underscore',
    'baseview'
],
function(
    $,
    _,
    BaseView
)
    {
        var OlympicsWidgetsView = BaseView.extend({

            events: {
                'click .schedule-norm': 'reduceSchedule',
                'click .schedule-wide': 'expandSchedule',
                'click .widget-switch-block': 'switchWidget'
            },

            initialize: function(options) {
                $.extend(options, {
                    numberRetries: 12,
                    refreshInterval: 5000
                }, options);

                BaseView.prototype.initialize.call(this, options);

                _.bindAll(this, 'checkWidgetResults');

                this.$widget = this.$('.olympics-primary-widget');
                if (!this.$widget.length) {
                    // if primary not found, it must be a sidebar widget
                    this.$widget = this.$('.olympics-sidebar-widget');
                }

                this.createWidget();
            },

            reduceSchedule: function(e) {
                var target = $(e.currentTarget);
                if (target.hasClass('schedule-norm')) {
                    $('.card-primary-wrapper').css('width', '840px');
                    $('.card-full-width .sidebar').show();
                    target.addClass('schedule-wide');
                    target.removeClass('schedule-norm');
                    target.html('Full Width Schedule');
                }
            },

            expandSchedule: function(e) {
                var target = $(e.currentTarget);
                if (target.hasClass('schedule-wide')) {
                    $('.card-full-width .sidebar').hide();
                    $('.card-primary-wrapper').css('width', '100%');
                    target.addClass('schedule-norm');
                    target.removeClass('schedule-wide');
                    target.html('Display Sidebar');
                }
            },

            createWidget: function() {
                var filters = ['athlete', 'country', 'day', 'discipline', 'event'];
                var widget = this.$widget;
                this.$widget.find('.widget-wrapper').each(function() {
                    var wrapper = $(this);
                    var params = {"el": "#" + wrapper.attr('id'), "highlight": "USA" };
                    // loop through the filters and add to params if defined
                    for (var f = 0; f < filters.length; ++f) {
                        if (wrapper.data(filters[f])) {
                            params[filters[f]] = wrapper.data(filters[f]).toString();
                        } else if (widget.data(filters[f])) {
                            params[filters[f]] = widget.data(filters[f]).toString();
                        }
                    }
                    console.log(params);
                    switch($(this).data('widget')) {
                        case 'medals':
                            params.sort = 'total';
                            if (widget.data('country') || widget.data('discipline')) {
                                new Reuters.Olympics.View.MedalTable(params);
                            } else {
                                new Reuters.Olympics.View.MedalCount(params);
                            }
                            break;
                        case 'schedule':
                            new Reuters.Olympics.View.Schedule(params);
                            break;
                        case 'results':
                            new Reuters.Olympics.View.Results(params);
                            break;
                    }
                });

                this.numRetries = this.options.numberRetries;
                //this.checkWidgetResults();
            },

            checkWidgetResults: function() {
                var rightrail = this.$('.rightrail.widget');

                // primary widget empty results
                if (this.$('.olympics-primary-widget')) {
                    var validData = false;
                    var emptyWidget = this.$('.olympics-primary-widget .widget-empty');
                    this.$('.olympics-primary-widget .widget-wrapper').each(function() {
                        var wrapper = $(this);
                        if (wrapper.html().length === 0 || wrapper.html().indexOf('error') > 0 || wrapper.html().indexOf('no results') > 0) {
                            wrapper.hide();
                        } else {
                            wrapper.show();
                            validData = true;
                        }
                    });
                    if (validData || this.numRetries > 1) {
                        emptyWidget.hide();
                    } else {
                        emptyWidget.show();
                    }
                }

                // sidebar widget empty results
                this.$('.olympics-sidebar-widget').each(function() {
                    var widget = $(this);
                    var emptyModule = true;
                    var module = widget.closest('.card-sidebar');
                    widget.find('.widget-wrapper').each(function() {
                        var wrapper = $(this);
                        if (wrapper.html().length === 0 || wrapper.html().indexOf('error') > 0 || wrapper.html().indexOf('no results') > 0) {
                            if (!wrapper.hasClass('toggle')) {
                                wrapper.hide(); // do not hide for toggle cases like results widgets
                            }
                        } else {
                            if (!wrapper.hasClass('toggle')) {
                                wrapper.show(); // do not show for toggle cases like results widgets
                            }
                            emptyModule = false;
                        }
                    });
                    if (emptyModule) {
                        module.hide();
                        rightrail.hide();
                    } else {
                        module.show();
                        rightrail.show();
                    }

                    var maxRows = widget.data('max-rows');
                    var maxLabels = widget.data('max-labels');
                    var maxPhases = widget.data('max-phases');

                    if (maxRows) {

                        // limit schedule widget rows displayed
                        $(this).find('.scheduleUnit').each(function(i) {
                            if (i >= maxRows) {
                                $(this).hide();
                            }
                        });

                        // limit results widget rows displayed per table
                        $(this).find('.resultsTable').each(function() {
                            $(this).find('.label').each(function(i) {
                                if (i > maxLabels) {
                                    var emptyRow = $(this).closest('tr');
                                    if (!emptyRow.hasClass('resultRow')) {
                                        emptyRow.hide();
                                    }
                                }
                            });
                            $(this).find('.resultRow').each(function(i) {
                                if (i >= maxRows) {
                                    $(this).hide();
                                }
                            });
                        });

                        /*
                        // limit results widget phases displayed per result
                        $(this).find('.sidebar-results').each(function() {
                            $(this).find('.phase.selected').each(function(i) {
                                if (i > maxPhases) {
                                    $(this).hide();
                                }
                            });
                            $(this).find('.phase').each(function(i) {
                                var phase = $(this);
                                if (!phase.hasClass('selected')) {
                                    phase.hide();
                                }
                            });
                        });
                        */
                    }

                });

                // removing the height constraint from Reuters to avoid scrolling
                this.$('.reutersOlympicsWidget .widgetBody').removeClass('constrainHeight');

                this.numRetries--;
                if (this.numRetries > 0) {
                    this.widgetTimer = setTimeout(this.checkWidgetResults, this.options.refreshInterval);
                }
            },

            switchWidget: function(e) {
                var widgets = this.$('.widget-switch-results .widget-wrapper');
                var buttons = this.$('.widget-switch-block');
                var target = $(e.currentTarget);
                var index = buttons.index(target);

                widgets.addClass('hide');
                widgets.eq(index).removeClass('hide');
                buttons.removeClass('selected');
                target.addClass('selected');
            },

            destroy: function() {
                this.numRetries = 0;
                if (this.widgetTimer) {
                    clearTimeout(this.widgetTimer);
                }
                BaseView.prototype.destroy.call(this);
            }
            
        });

        return OlympicsWidgetsView;
    }
);
