/**
 * @fileoverview User dropdown module (in the top nav).
 * @author ekallevig@gannett.com (Erik Kallevig), mdkennedy@gannett.com (Mark Kennedy)
 */
 
define('modules/users/site-nav-user-module',[
    'jquery',
    'underscore',
    'pubsub',
    'utils',
    'modules/header/site-nav-dropdown',
    'user-manager'
],
function(
    $,
    _,
    PubSub,
    Utils,
    SiteNavDropdown,
    UserManager
) {
    'use strict';

    /**
     * View class.
     */
    var UserDropdown = SiteNavDropdown.extend({

        ALT_ACTIVE_CLASS: 'site-nav-visible-alt-span',
        WRAP_ACTIVE_CLASS: 'site-nav-user-dropdown-wrap-active',

        events: {
            'click .site-nav-user-login-btn': 'onClickLogin',
            'click .site-nav-user-logout-btn': 'onClickLogout'
        },

        initialize: function(options) {

            this.pubSub = {
                'user:login': this.showLoggedInUser,
                'user:logout': this.showLoggedOutUser
            };
            _.bindAll(this, '_handleDropdownPanels', 'showLoggedInUser', 'showLoggedOutUser');

            this.events = _.extend({},SiteNavDropdown.prototype.events,this.events);

            // cache vars
            this.$loginWrap = this.$('.site-nav-user-logged-in-wrap');
            this.$logoutWrap = this.$('.site-nav-user-logged-out-wrap');
            this.$siteNavAltSpan = this.$('.site-nav-alt-span');
            this.$avatarContainer = this.$('.site-nav-user-avatar-span');
            this.$userDisplayName = this.$('.site-nav-user-display-name');
            this.$avatar = this.$('.site-nav-user-avatar-image');

            SiteNavDropdown.prototype.initialize.call(this, options);

            this._handleDropdownPanels();

        },

        /**
         * Shows dropdown panel wrap depending on the user's logged in/logged out state.
         * @private
         */
        _handleDropdownPanels: function() {
            if (this.isUserLoggedIn) {
                this.$loginWrap.addClass(this.WRAP_ACTIVE_CLASS);
                this.$logoutWrap.removeClass(this.WRAP_ACTIVE_CLASS);
            } else {
                this.$loginWrap.removeClass(this.WRAP_ACTIVE_CLASS);
                this.$logoutWrap.addClass(this.WRAP_ACTIVE_CLASS);
            }
        },

        /**
         * Shows the user header nav item in the logged out state.
         */
        showLoggedOutUser: function() {
            this.isUserLoggedIn = false;
            this._teardownLoggedInMarkup();
            this._handleDropdownPanels();
        },

        /**
         * Shows the user header nav item in the logged in state.
         */
        showLoggedInUser: function(){
            this._setupLoggedInMarkup(UserManager.getAccount());
            this.isUserLoggedIn = true;
            this._handleDropdownPanels();
        },

        /**
         * Sets up the markup for a logged in state.
         * @param {Object} userAccount An object containing the currently logged in user account
         * @private
         */
        _setupLoggedInMarkup: function(userAccount) {
            var fullName = userAccount.getUserFirstName() + ' ' + userAccount.getUserLastName();
            this.$avatar.attr('src', userAccount.getUserAvatar());
            this.$avatar.attr('alt', fullName);
            this.$siteNavAltSpan.addClass(this.ALT_ACTIVE_CLASS);
            this.$userDisplayName.html(fullName);
        },

        /**
         * Removes appropriate markup for logged in state.
         * @private
         */
        _teardownLoggedInMarkup: function() {
            this.$avatar.attr('src', '');
            this.$avatar.attr('alt', '');
            this.$siteNavAltSpan.removeClass(this.ALT_ACTIVE_CLASS);
            this.$userDisplayName.html('');
        },

        /**
         * Click logout in the user nav item.
         */
        onClickLogout: function() {
            this.showLoggedOutUser();
            UserManager.logoutUser().done(function(){
                PubSub.trigger('uotrack', 'headerprofilelogout');
            });
        },

        /**
         * Click handler for user nav item.
         */
        onClickLogin: function(e) {
            var userAccount = $(e.target).data('user-account');
            UserManager.loginUser(userAccount).done(function(){
                PubSub.trigger('uotrack', 'headerprofilelogin');
            });
        },

        /**
         * Destroys the view.
         */
        destroy: function(removeEl) {
            this._teardownLoggedInMarkup();
            SiteNavDropdown.prototype.destroy.call(this, removeEl);
        }
    });

    return UserDropdown;

});

