define('modules/partner/article-inline-ad',[
    'jquery',
    'underscore',
    'baseview',
    'utils',
    'adPosition',
    'adLogger',
    'state'
], function(
    $,
    _,
    BaseView,
    Utils,
    AdPosition,
    AdLogger,
    StateManager
) {
    'use strict';

    var ArticleInlineAd = BaseView.extend({

        receivedData: false,
        count: 20,

        initialize: function(options) {
            BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_onSpikeReady', '_setupSpike', '_onSlotEmpty', '_onMessage');

            // Paramount Ad Article
            this.subviews.adPosition = new AdPosition({
                el: this.$el,
                adType: 'Article Inline Ad',
                adPlacement: options.adPlacement,
                adSizes: ['fluid', [3,3]],
                lazy: true,
                onAdReady: this._onSpikeReady,
                onNoAd: this._onSlotEmpty,
                onMessage: this._onMessage
            });
        },

        _onMessage: function(data) {
            if (data.adType === 'spike') {
                this.receivedData = true;
                AdLogger.logInfo('Branded Content Spike Data Received', data);
                // remove any origin from url so it's relative
                data.url = data.url.replace(/^.+\:\/\/[^\/]+/, '');
                this._setupSpike(data);
            } else if (data.adType === 'paramount') {
                this.receivedData = true;
                AdLogger.logInfo('Paramount Data Received', data);
                this.$el.addClass('ad-gray-border ad-notice ad-paramount-inline');
            }
        },

        _setupSpike: function(data) {
            var $template = _.template(data.template)(data);

            this.$el.hide().after($template);
        },

        _onSpikeReady: function(adData, adType, adSize) {
            // Check for paramount ad
            if(_.isArray(adSize) && adSize[0] === 0 && adSize[1] === 0) {
                this.$el.addClass('ad-gray-border ad-notice ad-paramount-inline');
            }
            // Need to show the slot
            this.subviews.adPosition.show();
        },

        _onSlotEmpty: function () {
            // Outstream should be loaded in this slot if:
            //     1. No inline unit was returned
            //     2. Outstream is not already on the page
            var pageInfo = StateManager.getActivePageInfo(),
                hasAutoplayVideo = Utils.hasAutoplayVideo(pageInfo),
                outstreamOnPage = $('.partner-outstream').length;
            if (!outstreamOnPage && !hasAutoplayVideo) {
                this.el.classList.add('partner-outstream');
                this.subviews.outstreamAd = new AdPosition({
                    el: this.el,
                    adSizes: [1,1],
                    adPlacement: 'outstream_video',
                    adType: 'outstream',
                    pageInfo: pageInfo
                });
            }
        },

        /**
         * Clean up view.
         * Removes event handlers and element (optionally).
         * @param {boolean} removeEl option to also remove View from DOM.
         */
        destroy: function(removeEl) {
            // this.$win.off('.' + this.cid);
            clearInterval(this.msgInt);
            BaseView.prototype.destroy.call(this, removeEl);
        }
    });
    return ArticleInlineAd;
});

