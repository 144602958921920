define('modules/stories/utility-bar-flyout',[
    'jquery',
    'underscore',
    'backbone',
    'baseview',
    'pubsub',
    'utils',
    'managers/routemanager',
    'site-manager',
    'modules/scroller/vertical-scroll',
    'state'
],
function(
    $,
    _,
    Backbone,
    BaseView,
    PubSub,
    Utils,
    RouteManager,
    SiteManager,
    VerticalScroll,
    StateManager
) {
    var UtilityBarFlyout = BaseView.extend({

        events: {
            'click .util-bar-flyout-close-ribbon': 'close',
            'click': 'onClickFlyout'
        },

        initialize: function(options) {
            _.bindAll(this, 'onBodyClick', 'onWindowResize');
            this.$body = Utils.get('body');
            this.$win = Utils.get('win');
            this.$flyout = this.$('.util-bar-flyout');
            this.pubSub = this.pubSub || {};
            this.pubSub['breakingbar:after:open'] = this.onWindowResize;
            BaseView.prototype.initialize.call(this, options);
        },

        onWindowResize: function() {
            var availableVerticalSpace = this.getAvailableVerticalSpace();
            this.updateFlyoutHeight(availableVerticalSpace);
            if (!this.options.noFlyoutScroll) {
                this.updateFlyoutScroller();
            }
        },

        getAvailableVerticalSpace: function() {
            var header = SiteManager.getHeader(),
                headerHeight = 0;
            if (header && header.isFixed() === true) {
                headerHeight = header.getCollapsedHeight();
            } else if (header && header.isFixed() === false) {
                headerHeight = header.getCurrentHeight();
            }
            return this.$win.height() - headerHeight;
        },

        getContentHeight: function() {
            return this.$scrollableContent.outerHeight();
        },

        updateFlyoutHeight: function(height) {
            this.$flyout.css('height', height);
        },

         //Sets up the proper height of the panel.

        updateFlyoutScroller: function() {
            if (this.subviews.vscroll) {
                this.subviews.vscroll.refresh();
            } else {
                // Initialize the Vertical Scroll
                this.subviews.vscroll = new VerticalScroll({
                    el: this.$flyout
                });
            }
        },

        open: function(sectionName) {
            this.$scrollableContent = this.$('.scrollable-content').eq(0);
            this.$('.util-bar-btn').removeClass('active');
            this.$btn = sectionName ? this.$('.util-bar-btn-' + sectionName) : this.$('.util-bar-btn');
            this.$btn.addClass('active');
            this.$body.on('click.' + this.cid, this.onBodyClick);

            // Must manually attach this click handler because recaptcha
            // widget has awful innerHtml replacement, which destroys reference
            // to target's parents during bubble. Manual click attachment avoids
            // 'delegate' search when bubbling, so click still gets triggered.
            // this.$flyout.on('click.' + this.cid, this.onClickFlyout);
            this.$win.on('resize.' + this.cid, _.throttle(this.onWindowResize, 50));
            this.$flyout
                .css({
                    'visibility': 'visible'
                })
                .addClass('open');
            if (sectionName) {
                if (sectionName == 'share') {
                    this.showSection('facebook');
                } else {
                    this.showSection(sectionName);
                }
            }
            this.onWindowResize();

            if (sectionName) {
                PubSub.trigger('uotrack', 'utilitybar' + sectionName + 'open');
            }
        },

        updateURL: function(e, url) {
            if (e.hasAttribute('href') && e.href != '#'){
                try {
                    var currentLink = e.href.split('?url=');
                    e.href = currentLink[0] + '?url=' + url + currentLink[1].match(/&.*$/);
                } catch (err) {
                    console.log(e.href + '\n' + err + '\nfailed video url');
                }
            }
        },

        close: function() {
            var storyShareUrl = StateManager.getActivePageInfo().share_url;
            _.each(
                $("[data-share-method]"), function(e){
                    this.updateURL(e, storyShareUrl);
                }, this
            );
            _.each(
                $("[data-module-name]"), function(e){
                    this.updateURL(e, storyShareUrl);
                }, this
            );
            var sidebarMessageText = $('.email-message');
            if (sidebarMessageText.length > 0) {
                sidebarMessageText.val(sidebarMessageText.html().split(/http:\/\/.+\//)[0] + storyShareUrl);
            }
            this.destroy();
        },

        onBodyClick: function(e) {
            var $target = $(e.target);
            if (!e.isDefaultPrevented() || RouteManager.isAjax($target.attr('href'))) {
                this.close();
            }
        },

        /**
         * Stop click propagation inside the flyout (to avoid closing it)
         * unless it's a valid internal link or target for a new window.
         */
        onClickFlyout: function(e) {
            var $target = $(e.target);
            if (!$target.closest('a').attr('href')) {
                e.preventDefault();
            }
        },

        /**
         * Clean up view.
         * Removes event handlers and element (optionally).
         * @param {boolean} removeEl option to also remove View from DOM.
         */
        destroy: function(removeEl) {
            this.$body.off('.' + this.cid);
            // this.$flyout.off('click.' + this.cid);
            this.$win.off('.' + this.cid);
            if (this.$btn) {
                this.$btn.removeClass('active');
            }
            this.$flyout
                .css({
                    'visibility': 'hidden'
                })
                .removeClass('open');
            BaseView.prototype.destroy.call(this, removeEl);
            if (this.options.onDestroy) this.options.onDestroy(this);
        }
    });
    /**
     * Return view class.
     */
    return UtilityBarFlyout;
});

