/* global define, console */
define('abtest-manager',[
    'jquery',
    'underscore',
    'utils',
    'pubsub'
    ],
function(
    $,
    _,
    Utils,
    PubSub
) {
    "use strict";
    /**
     * AB Test Manager manages the segmentation slots used for A/B testing.
     * Slots are randomly assigned from 1 to 100, allowing segmentation between specific
     * subsets of the audience for testing purposes. For example, testing only 10% of
     * users will be accomplished putting test slots 1-5 in Test A, and 6-10 in Test B.
     * @exports managers/abtestmanager
     * @author kefoster@gannett.com (Kevin Foster)
     * @author ekallevig@gannett.com (Erik Kallevig)
     */
    var AbTestManager = function() {
        this.userTestSlot = 0;
        this.slotKey = 'ab_test_slot';
        this.$body = Utils.get('body');
    };
    AbTestManager.prototype = {
        start: function(pageInfo) {
            this.pubSub = {
                'activePageInfo:set': this._onActivePageInfoSet
            };
            PubSub.attach(this.pubSub, this);
            this.userTestSlot = this._getUserTestSlot();
            this.initialPath = window.location.pathname;
            this.processPageInfo(pageInfo);
        },
        stop: function() {
            PubSub.unattach(this.pubSub, this);
            this._removeCustomVariantCode();
            this._clearUserTestSlot();
            this.userTestSlot = 0;
            this.tests = [];
        },
        processPageInfo: function(pageInfo) {
            pageInfo = pageInfo || {};
            this._processTests(pageInfo);
            pageInfo.user = pageInfo.user || {};
            pageInfo.user.abTestSlot = this.userTestSlot;
            pageInfo.user.abTest = this.getUserTest();
        },
        /**
         * @param {Object} pageInfo
         * @private
         */
        _onActivePageInfoSet: function(pageInfo) {
            if (Utils.getNested(pageInfo, 'routePath') !== this.initialPath) {
                this.processPageInfo(pageInfo);
            } else {
                this.initialPath = null;
            }
        },
        /**
         * Returns all tests in site's config (regardless of date range).
         * @returns {Array<Object>}
         */
        getTests: function() {
            return this.tests;
        },
        /**
         * Returns current tests (ie. tests with date ranges that include current date).
         * @returns {Array<Object>}
         */
        getCurrentTests: function() {
            return this.currentTests;
        },
        /**
         * Returns test object by test id.
         * @param {Number} testId
         * @returns {Object}
         */
        getTestById: function(testId) {
            return _.find(this.getTests(), function(test) {
                return test.id === testId;
            });
        },
        /**
         * Returns test object by variant id.
         * @param {String} variantId
         * @returns {Object}
         */
        getTestByVariant: function(variantId) {
            return _.find(this.getTests(), function(test) {
                return _.find(test.variants, function(variant) {
                    return variant.id === variantId;
                });
            });
        },
        /**
         * Return active user tests across all urls.
         * @returns {Array<Object>}
         */
        getUserTests: function() {
            return this.userTests;
        },
        /**
         * Return active user test for current url.
         * @returns {Object}
         */
        getUserTest: function() {
            return this.userTest;
        },
        /**
         * Return active user test for specific url.
         * NOTE: Use `getUserTest` if getting test for current url.
         * @returns {Object}
         */
        getUserTestByTargetAndPath: function(target, path) {
            return _.find(this.userTests, function(test) {
                if (test.testTarget === target) {
                    return new RegExp(test.urlRegex).test(path);
                }
            });
        },
        /**
         * Return active user test for current url with matching `testTarget`.
         * NOTE: Use `getUserTest` if getting test for current url.
         * @returns {Object}
         */
        getUserTestByTarget: function(target) {
            if (Utils.getNested(this.userTest, 'testTarget') === target) {
                return this.userTest;
            }
        },
        /**
         * Checks whether user slot is within variant traffic range.
         * @param {Object} variant object
         * @returns {Object}
         * @private
         */
        _isUserInVariantRange: function(variant) {
            return variant.trafficRange &&
                this.userTestSlot >= variant.trafficRange.start &&
                this.userTestSlot <= variant.trafficRange.end;
        },
        /**
         * From pageInfo or window.site_vars abtests array, calculates and stashes which tests are current and
         * which apply to the user.
         * @param {Object} pageInfo
         * @private
         */
        _processTests: function(pageInfo) {
            this.tests = pageInfo.abtests || Utils.getNested(window.site_vars, 'abtests') || [];
            this.currentTests = [];
            this.userTests = [];
            this.userTest = null;
            this._removeCustomVariantCode();
            _.each(this.tests, function(test) {
                var isTestCurrent = this._isTestCurrent(test);
                if (test.variantCoinFlip) {
                    // If coin-flip is enabled, we ignore the user slot/bucketing and randomly assign the variant
                    test.userVariant = this._getRandomVariant(test.variants);
                } else {
                    // Process variants
                    _.each(test.variants, function (variant) {
                        var isHeaderVariantMatch;
                        if (variant.id === pageInfo.headerAbVariant) {
                            isHeaderVariantMatch = test.isHeaderVariantTest = true;
                        }
                        // even if not "current", header variant has precedence
                        var isUserVariant = isHeaderVariantMatch || (isTestCurrent && this._isUserInVariantRange(variant));
                        if (isUserVariant) {
                            test.userVariant = variant;
                        }
                        if (variant.control === true) {
                            test.controlVariant = variant;
                        }
                    }, this);
                }

                // Set userTests array and current page test
                if (test.userVariant) {
                    // Extend variant options with default variant options
                    test.userVariant.options = $.extend(true, Utils.getNested(test, 'defaultVariantOptions'),
                        Utils.getNested(test.userVariant, 'options'));
                    this.userTests.push(test);
                    try {
                        if (test.isHeaderVariantTest || new RegExp(test.urlRegex).test(window.location.pathname)) {
                            this.userTest = test;
                        }
                    } catch(e) {
                        return false;
                    }
                    if (test.userVariant.customCode) {
                        this._processVariantCustomCode(test.userVariant.customCode);
                    }
                }
                if (isTestCurrent) {
                    this.currentTests.push(test);
                }

            }, this);
        },
        /**
         * Returns user's test slot number if one exists otherwise set one and return it.
         * @returns {Number}
         * @private
         */
        _getUserTestSlot: function() {
            return this._readUserTestSlot() || this._setUserTestSlot();
        },
        /**
         * Read the user's test slot number from localstorage if one exists.
         * @returns {Number}
         * @private
         */
        _readUserTestSlot: function() {
            var userTestSlot = 0;
            try {
                userTestSlot = JSON.parse(window.localStorage.getItem(this.slotKey));
            } catch(ex) {
                // catch Safari incognito errors
                console.error('Failed reading userTestSlot', (ex.stack || ex.stacktrace || ex.message));
            }
            return userTestSlot;
        },
        /**
         * Generate and save a random test slot number between 1 and 100 into local storage.
         * @returns {Number}
         * @private
         */
        _setUserTestSlot: function() {
            // Generate a random value from 1 to 100 for segmentation of users
            var userTestSlot = Math.floor(Math.random() * 100) + 1;
            try {
                window.localStorage.setItem(this.slotKey, JSON.stringify(userTestSlot));
            } catch (ex) {
                // catch Safari incognito errors
                console.error('Failed saving userTestSlot', (ex.stack || ex.stacktrace || ex.message));
            }
            return userTestSlot;
        },
        /**
         * Remove user's test slot number from local storage.
         * @private
         */
        _clearUserTestSlot: function() {
            try {
                window.localStorage.removeItem(this.slotKey);
            } catch(ex) {
                // catch Safari incognito errors
                console.error('Failed clearing userTestSlot', (ex.stack || ex.stacktrace || ex.message));
            }
        },
        /**
         * Returns whether current time is within the test's date range.
         * @param {Object} test
         * @returns {Boolean}
         * @private
         */
        _isTestCurrent: function(test) {
            var now = new Date();
            return new Date(test.endTime) > now && new Date(test.startTime) < now;
        },
        /**
         * Returns a random variant from the provided array.
         * @param {Array<Object>} variants
         * @returns {Object}
         * @private
         */
        _getRandomVariant: function(variants) {
            return variants[_.random(variants.length - 1)];
        },
        /**
         * Processes custom code associated with a test variant.
         * @param {Object} customCode
         * @private
         */
        _processVariantCustomCode: function(customCode) {
            customCode = customCode || {};
            if (customCode.css) {
                this._appendCustomCss(customCode.css);
            }
            if (customCode.js) {
                this._appendCustomJs(customCode.js);
            }
        },
        /**
         * Appends block of CSS to body.
         * @param {String} css
         * @private
         */
        _appendCustomCss: function(css) {
            if (css && _.isString(css)) {
                $('<style>')
                    .attr({
                        type: 'text/css',
                        class: 'abtest-custom'
                    })
                    .text(css)
                    .appendTo(this.$body);
            }
        },
        /**
         * Appends block of JS to body.
         * @param {String} js
         * @private
         */
        _appendCustomJs: function(js) {
            if (js && _.isString(js)) {
                $('<script>')
                    .attr({
                        type: 'text/javascript',
                        class: 'abtest-custom'
                    })
                    .text(js)
                    .appendTo(this.$body);
            }
        },
        _removeCustomVariantCode: function() {
            this.$body.find('> .abtest-custom').remove();
        }

    };
    window.abTestManager = new AbTestManager();
    return window.abTestManager;
});

