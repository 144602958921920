define('modules/longform/cover-photo-arrow',[
    'jquery',
    'underscore',
    'baseview',
    'utils'
],
function(
    $,
    _,
    BaseView,
    Utils
) {
    'use strict';

    var LongformCoverPhotoArrow = BaseView.extend({

        // Events
        events: {
            'click .longform-intro-arrow-wrap': 'scrollToFirstChapter'
        },

        /**
         * Initialize view.
         */
        initialize: function (options) {
            this.$bottomArrowWrap = this.$('.longform-intro-arrow-wrap');

            this._startArrowPulse();

            BaseView.prototype.initialize.call(this, options);
        },

        _startArrowPulse: function() {
            var that = this;
            this.animate(this.$bottomArrowWrap,'opacity', 0.25, 1500, 'ease-out').done( function() {
                if(!that.destroyed) {
                    that.animate(that.$bottomArrowWrap,'opacity', 1, 1500, 'ease-out').done( function() {
                        if(!that.destroyed) {
                            that._startArrowPulse();
                        }
                    });
                }
            });
        },

        scrollToFirstChapter: function(e) {
            e.preventDefault();
            var scrollTo, $firstChapter = $(".chapter-marker:eq(0)");

            if($firstChapter.length) {
                scrollTo = $firstChapter.offset().top - 60;
            } else {
                scrollTo = $('.longform-content-wrapper').offset().top - 60;
            }

            Utils.get('scrollEl').animate({
                scrollTop: scrollTo
            }, {
                step: function(step, tween) {
                    // Issue occurs where start is always set to 0 when gravity is on the page, this fixes it.
                    if(step === 0) {
                        tween.start = tween.now = $(window).scrollTop();
                    }
                },
                duration: 1000
            });
        }
    });

    return LongformCoverPhotoArrow;
});
